import { Alert } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { AlertType, IAlertBlock } from './types';

const AlertBlock: FC<IAlertBlock> = (props) => {
  const { content = '', wrapperClassName = '', alertClassName = '', type = AlertType.error } = props;

  return (
    <div className={classNames('alert-block', wrapperClassName)}>
      <Alert className={classNames('alert-block__alert', alertClassName)} type={type} message={content} />
    </div>
  );
};

export default AlertBlock;
