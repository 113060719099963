import React, { FC } from 'react';

const NotifyIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8828 17.4038C11.2282 17.7885 11.6715 17.9998 12.1315 17.9998H12.1322C12.5942 17.9998 13.0395 17.7885 13.3855 17.4032C13.5708 17.1985 13.8868 17.1818 14.0915 17.3665C14.2968 17.5512 14.3135 17.8678 14.1288 18.0725C13.5902 18.6705 12.8815 18.9998 12.1322 18.9998H12.1308C11.3835 18.9992 10.6762 18.6698 10.1395 18.0718C9.95482 17.8672 9.97148 17.5505 10.1768 17.3665C10.3822 17.1812 10.6982 17.1978 10.8828 17.4038ZM12.1647 4.6665C15.128 4.6665 17.1187 6.9745 17.1187 9.12984C17.1187 10.2385 17.4007 10.7085 17.7 11.2072C17.996 11.6992 18.3314 12.2578 18.3314 13.3138C18.0987 16.0118 15.282 16.2318 12.1647 16.2318C9.04735 16.2318 6.23002 16.0118 6.00001 13.3565C5.99802 12.2578 6.33335 11.6992 6.62935 11.2072L6.73385 11.0313C6.99113 10.5891 7.21068 10.1081 7.21068 9.12984C7.21068 6.9745 9.20135 4.6665 12.1647 4.6665ZM12.1647 5.6665C9.83468 5.6665 8.21068 7.49184 8.21068 9.12984C8.21068 10.5158 7.82602 11.1565 7.48602 11.7218C7.21335 12.1758 6.99802 12.5345 6.99802 13.3138C7.10935 14.5712 7.93935 15.2318 12.1647 15.2318C16.3667 15.2318 17.2227 14.5418 17.3334 13.2705C17.3314 12.5345 17.116 12.1758 16.8434 11.7218C16.5034 11.1565 16.1187 10.5158 16.1187 9.12984C16.1187 7.49184 14.4947 5.6665 12.1647 5.6665Z"
      fill="#1444F2"
    />
  </svg>
);

export default NotifyIcon;
