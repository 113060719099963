import React, { FC } from 'react';

const UserIcon: FC = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 44.8965C34.7891 44.8965 44.9082 34.7773 44.9082 22.4883C44.9082 10.2207 34.7676 0.0800781 22.4785 0.0800781C10.2109 0.0800781 0.0917969 10.2207 0.0917969 22.4883C0.0917969 34.7773 10.2109 44.8965 22.5 44.8965ZM22.5 25.9043C18.2246 25.8828 14.9375 22.2949 14.9375 17.6328C14.916 13.2285 18.2676 9.5332 22.5 9.5332C26.7109 9.5332 30.041 13.2285 30.041 17.6328C30.041 22.2949 26.7539 25.9473 22.5 25.9043ZM22.5 40.9648C17.7734 40.9648 12.5742 38.9883 9.37305 35.5078C11.9082 31.791 16.7207 29.6641 22.5 29.6641C28.2148 29.6641 33.0488 31.7266 35.627 35.5078C32.4043 38.9883 27.2051 40.9648 22.5 40.9648Z"
      fill="#1444F2"
    />
  </svg>
);

export default UserIcon;
