import { AccessPointType, IAccessPoint, IRelatedAccessPoint, ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import { findTreeNodeByKey, resolveTreeKey } from '../../../utils/tree';
import { getPointLayout } from '../../schemaCanvas/paper/paperItems/items';
import { EAccessPointDeviceCategory } from './accessPointModal/types';
import { initSelectObject } from './constans';
import { ISelectedSchemaEntity } from './types';

export const checkIsCallBlock = (point: IAccessPoint) =>
  point?.inputDevice?.deviceCategory?.id === EAccessPointDeviceCategory.callBlock;

const findAllLowerPoints = (nodes: ITreeNode[], res: IRelatedAccessPoint[]) => {
  nodes.forEach((node) => {
    node.accessPoints?.forEach((point) => {
      if (checkIsCallBlock(point)) {
        res.push({
          accessPointId: point.id || '',
          shortName: point.shortName,
          isAutoRelation: true,
        });
      }
    });
    findAllLowerPoints(node.childItems || [], res);
  });
};

export const recalculateRelatedPoints = (nodes: ITreeNode[]) => {
  nodes.forEach((node) => {
    const autoRelatedPoints: IRelatedAccessPoint[] = [];
    findAllLowerPoints(node.childItems || [], autoRelatedPoints);

    node.accessPoints?.forEach((point) => {
      const customRelatedPoints: IRelatedAccessPoint[] = [];
      point.relatedAccessPoints?.forEach((item) => !item.isAutoRelation && customRelatedPoints.push(item));
      if (checkIsCallBlock(point) && (autoRelatedPoints.length || customRelatedPoints.length)) {
        point.relatedAccessPoints = JSON.parse(JSON.stringify([...autoRelatedPoints, ...customRelatedPoints]));
        point.deviceType = AccessPointType.wicket;
      } else {
        point.relatedAccessPoints = [];
        point.deviceType = AccessPointType.door;
      }
      point.type = TreeNodeType.accessPoint;
      point.layout = getPointLayout(point, point.layout);
    });
    recalculateRelatedPoints(node.childItems || []);
  });
};

export const getNodeFullName = (node: ITreeNode | IAccessPoint) =>
  `${node.name} ${'prefix' in node ? node.prefix || '' : ''}`;

export const sortNodeChildren = (node: ITreeNode) => {
  if (node.childItems) {
    node.childItems.sort((a, b) => getNodeFullName(a).localeCompare(getNodeFullName(b)));
  }
  if (node.accessPoints) {
    node.accessPoints.sort((a, b) => getNodeFullName(a).localeCompare(getNodeFullName(b)));
  }
  node.childItems?.forEach((child) => sortNodeChildren(child));
};

export const onSelectedTreeNode = (originalKey: string, editableSchema: ITreeNode[]) => {
  let state: ISelectedSchemaEntity = { ...initSelectObject };
  if (originalKey) {
    if (originalKey === TreeNodeType.object) {
      state = { ...state, originalKey };
    } else if (originalKey === TreeNodeType.background) {
      state = { ...state, originalKey, type: TreeNodeType.background };
    } else {
      const keys = resolveTreeKey(originalKey);
      const { node, parentKeys, parent, nodeIndex } = findTreeNodeByKey(keys, editableSchema);
      if (node) {
        state = {
          originalKey,
          parents: parentKeys,
          object: node,
          type: keys[keys.length - 1].type,
          parent,
          nodeIndex,
        };
      }
    }
  }
  return state;
};

export const findNonRemovableDevices = (node: ITreeNode, devicesList: string[]) => {
  node.accessPoints?.forEach((item) => item.inputDevice?.camExternal && devicesList.push(item.shortName));
  node.cameras?.forEach((item) => item?.camExternal && devicesList.push(item.shortName));
  node.children?.forEach((item) => findNonRemovableDevices(item, devicesList));
};
