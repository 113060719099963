import { Avatar } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import UserIcon from '../../assets/svg/icons/user';
import { useAppSelector } from '../../hooks/hooks';
import { getHeaderTitle } from '../../store/selectors/header';
import ProfilePopover from '../profilePopover';
import NotificationsBlock from '../notificationsBlock';
import { getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import { IProfilePermission } from '../../typings/profile';

const Header: FC = () => {
  const selectedSideBarTab = useAppSelector(getHeaderTitle);

  const [profilePopoverIsOpen, setProfilePopoverIsOpen] = useState<boolean>(false);

  const handleOnChangeProfileIsOpen = useCallback((newOpen: boolean) => {
    setProfilePopoverIsOpen(newOpen);
  }, []);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.dispatcherPanel)) as IProfilePermission;

  return (
    <div className="header">
      <div className="header__container">
        <span className="header__title">{selectedSideBarTab}</span>
        <div className="header__nav">
          {permissions?.edit && <NotificationsBlock />}
          <ProfilePopover open={profilePopoverIsOpen} onOpenChange={handleOnChangeProfileIsOpen}>
            <Avatar alt="User avatar" icon={<UserIcon />} className="header__avatar" />
          </ProfilePopover>
        </div>
      </div>
    </div>
  );
};

export default Header;
