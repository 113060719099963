import { Checkbox, Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DrawContour from '../../../../assets/svg/icons/drawContour';
import UpArrow from '../../../../assets/svg/icons/upArrow';
import { TreeNodeType } from '../../../../typings/treeNode';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { ESchemaButtonTypes, ETypesOfCreation, EVisualizerModes, IButtonData, IShape } from '../types';
import { IButtonsSchema } from './types';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import { allowedShapesMap } from '../constans';
import InputSlider from '../../../ui/inputSlider';

const SchemaButtons: FC<IButtonsSchema> = ({
  buttons,
  checkboxes,
  onChangeCheckbox,
  displayedObjects,
  children,
  inOneLine = false,
}) => {
  const [isShowShapes, setShowShapes] = useState(false);

  const [activeButton, setActiveButton] = useState<IButtonData | null>(null);

  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  const showOrHideButtons = useCallback(
    (scroll = scrollElementRef.current) => {
      if (scroll) {
        if (scroll.offsetWidth < scroll.scrollWidth) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        } else {
          hideButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        }
      }
    },
    [hideButton, showButton]
  );

  const scrollEvent = useCallback(() => {
    const value = scrollElementRef.current?.scrollLeft || 0;
    const maxValue = scrollElementRef.current?.scrollWidth || 0;
    const width = scrollElementRef.current?.offsetWidth || 0;
    if (value > 50) {
      showButton(chevronLeftRef.current, chevronLefIsHidden);
    } else if (value < 50) {
      hideButton(chevronLeftRef.current, chevronLefIsHidden);
    }
    if (value + width >= maxValue - 20) {
      hideButton(chevronRightRef.current, chevronRightIsHidden);
    } else {
      showButton(chevronRightRef.current, chevronRightIsHidden);
    }
  }, [hideButton, showButton]);

  useEffect(() => {
    showOrHideButtons();
  }, [buttons, checkboxes]);

  useEffect(() => {
    if (scrollElementRef.current) {
      showOrHideButtons(scrollElementRef.current);
      scrollElementRef.current.onscroll = scrollEvent;
    }
  }, [scrollElementRef]);

  const scrollTo = useCallback(
    (toRight = true) =>
      () => {
        if (scrollElementRef.current) {
          scrollElementRef.current.scrollBy({ left: toRight ? 200 : -200, behavior: 'smooth' });
        }
      },
    []
  );

  const changeVisible = useCallback(
    (isVisible: boolean) => {
      if (isShowShapes) {
        setShowShapes(false);
      }
      if (!isVisible) {
        setActiveButton(null);
      }
    },
    [isShowShapes, setActiveButton, setShowShapes]
  );

  const onShowShapes = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setShowShapes(!isShowShapes);
    },
    [setShowShapes, isShowShapes]
  );

  const onSelectDrawType = useCallback(
    (shape: IShape | null = null) =>
      () => {
        if (activeButton) {
          activeButton.onStartCreate({
            status: EVisualizerModes.create,
            nodeType: activeButton.id,
            typeOfCreation: shape ? ETypesOfCreation.finishedFigure : ETypesOfCreation.freeContour,
            shape,
            editType: null,
          });
        }
      },
    [activeButton]
  );

  const renderShapes = useCallback(
    (id: TreeNodeType): ItemType | null =>
      isShowShapes
        ? {
            label: (
              <div className="schema-buttons__menu">
                {allowedShapesMap.get(id)?.map((shape, index) => (
                  <div
                    onClick={onSelectDrawType(shape)}
                    key={`shape-${index}`}
                    role="presentation"
                    className="schema-buttons__menu-item"
                  >
                    {shape.element}
                  </div>
                ))}
              </div>
            ),
            className: 'schema-buttons__container-menu',
            key: 'menu_shapes',
          }
        : null,
    [isShowShapes, onSelectDrawType]
  );

  const renderExtendedCreationMenu = useCallback(
    (id: TreeNodeType) => (
      <Menu
        className="schema-buttons__create-dropdown"
        items={[
          {
            label: (
              <div onClick={onSelectDrawType(null)} role="presentation" className="schema-buttons__menu-button">
                <div className="schema-buttons__menu-button-label">Произвольная фигура</div>
                <div className="schema-buttons__menu-button-icon">
                  <DrawContour />
                </div>
              </div>
            ),
            key: 'menu_arbitrary_shape',
            className: 'schema-buttons__create-dropdown-item',
          },
          {
            label: (
              <div onClick={onShowShapes} role="presentation" className="schema-buttons__menu-button">
                <div className="schema-buttons__menu-button-label">Готовая фигура</div>
                <div
                  className={classNames(
                    'schema-buttons__menu-button-icon',
                    isShowShapes && 'schema-buttons__menu-button-icon_rotate'
                  )}
                >
                  <UpArrow />
                </div>
              </div>
            ),
            key: 'menu_finished_shape',
            className: 'schema-buttons__create-dropdown-item',
          },
          renderShapes(id),
        ]}
      />
    ),
    [renderShapes, isShowShapes, onShowShapes, onSelectDrawType]
  );

  const renderExtendedCreationButton = useCallback(
    (button: IButtonData) =>
      (allowedShapesMap.get(button.id)?.length || 0) > 0 ? (
        <Dropdown
          key={button.key}
          overlay={renderExtendedCreationMenu(button.id)}
          trigger={['click']}
          onVisibleChange={changeVisible}
        >
          <Button onClick={() => setActiveButton(button)} type={ButtonType.outline} className="schema-buttons__button">
            {button.label}
            <span className="schema-buttons__button-icon">
              <UpArrow />
            </span>
          </Button>
        </Dropdown>
      ) : null,
    [changeVisible, renderExtendedCreationMenu]
  );

  const onChangeOpacity = useCallback(
    (name: string, button: IButtonData) => (val: number) => {
      if (button.value && button.onChange) {
        button.onChange({ ...button.value, [name]: val });
      }
    },
    []
  );

  const onPreventDefault = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const renderExtendedRangesMenu = useCallback(
    (button: IButtonData) => (
      <Menu
        className="schema-buttons__range-dropdown"
        items={[
          {
            label: (
              <div role="presentation" onClick={onPreventDefault}>
                <InputSlider
                  title="Прозрачность подложки"
                  onChange={onChangeOpacity('background', button)}
                  value={button.value.background}
                />
              </div>
            ),
            key: 'opacity-menu-background',
            className: 'schema-buttons__range-dropdown-item',
          },
          {
            label: (
              <div role="presentation" onClick={onPreventDefault}>
                <InputSlider
                  title="Прозрачность активной области"
                  onChange={onChangeOpacity('active', button)}
                  value={button.value.active}
                />
              </div>
            ),
            key: 'opacity-menu-active',
            className: 'schema-buttons__range-dropdown-item',
          },
          {
            label: (
              <div role="presentation" onClick={onPreventDefault}>
                <InputSlider
                  title="Прозрачность неактивной области"
                  onChange={onChangeOpacity('inactive', button)}
                  value={button.value.inactive}
                />
              </div>
            ),
            key: 'opacity-menu-inactive',
            className: 'schema-buttons__range-dropdown-item',
          },
        ]}
      />
    ),
    [onChangeOpacity, onPreventDefault]
  );

  const renderExtendedRangesButton = useCallback(
    (button: IButtonData) => (
      <Dropdown
        key={button.key}
        overlay={renderExtendedRangesMenu(button)}
        trigger={['click']}
        onVisibleChange={changeVisible}
      >
        <Button onClick={() => setActiveButton(button)} type={ButtonType.outline} className="schema-buttons__button">
          {button.label}
          <span className="schema-buttons__button-icon">
            <UpArrow />
          </span>
        </Button>
      </Dropdown>
    ),
    [changeVisible, renderExtendedRangesMenu]
  );

  const renderSlider = useCallback(
    (button: IButtonData) => <InputSlider title="Прозрачность" onChange={button.onChange} value={button.value} />,
    []
  );

  const renderDefaultButton = useCallback(
    (button: IButtonData) => (
      <Button
        disabled={button.isDisabled}
        key={button.key}
        type={ButtonType.outline}
        className="schema-buttons__button"
        onClick={button.onClick}
      >
        {button.label}
      </Button>
    ),
    []
  );

  const renderButtons = useMemo(
    () =>
      buttons &&
      buttons.map((button) => {
        let content = null;

        if (button) {
          switch (button.type) {
            case ESchemaButtonTypes.extendedCreation: {
              content = renderExtendedCreationButton(button);
              break;
            }
            case ESchemaButtonTypes.extendedRanges: {
              content = renderExtendedRangesButton(button);
              break;
            }
            case ESchemaButtonTypes.range: {
              content = renderSlider(button);
              break;
            }
            case ESchemaButtonTypes.default: {
              content = renderDefaultButton(button);
              break;
            }
            default: {
              content = null;
              break;
            }
          }
        }

        return content;
      }),
    [buttons, renderDefaultButton, renderExtendedCreationButton, renderExtendedRangesButton, renderSlider]
  );

  const renderCheckbox = useMemo(
    () => <Checkbox.Group options={checkboxes} value={displayedObjects} onChange={onChangeCheckbox} />,
    [checkboxes, displayedObjects, onChangeCheckbox]
  );

  return (
    <div className="schema-buttons">
      <div
        ref={chevronLeftRef}
        role="presentation"
        onClick={scrollTo(false)}
        className="tabs__tabs-button tabs__tabs-button_left tabs__tabs-button_hide"
      >
        <ChevronLeft />
      </div>
      <div
        ref={chevronRightRef}
        role="presentation"
        onClick={scrollTo()}
        className="tabs__tabs-button tabs__tabs-button_right tabs__tabs-button_hide"
      >
        <ChevronRight />
      </div>
      <div ref={scrollElementRef} className="schema-buttons__content default-scrollbar-override">
        <div className="schema-buttons__buttons">
          {children} {renderButtons} {inOneLine && checkboxes?.length ? renderCheckbox : null}
        </div>

        {!inOneLine && <div className="schema-buttons__checkboxes">{checkboxes?.length ? renderCheckbox : null}</div>}
      </div>
    </div>
  );
};

export default SchemaButtons;
