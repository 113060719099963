import React, { FC } from 'react';

const SearchFailIcon: FC = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0002 2H9.00023C6.2386 2 4 4.18263 4 6.87487V36.1248C4 38.817 6.2386 40.9997 9.00023 40.9997H30.9997C31.5521 40.9997 31.9998 40.5632 31.9998 40.0249C31.9998 39.4862 31.5521 39.0498 30.9997 39.0498H9.00023C7.3432 39.0498 6.00023 37.7401 6.00023 36.1248V6.87487C6.00023 5.25961 7.3432 3.94988 9.00023 3.94988H25.9999V10.775C25.9999 11.3133 26.4476 11.7497 27.0001 11.7497H33.9998V14.6747C33.9998 15.2134 34.4478 15.6498 34.9999 15.6498C35.5521 15.6498 36.0001 15.2134 36.0001 14.6747V10.775C36.0001 10.6428 35.9729 10.5168 35.924 10.4015C35.8755 10.2866 35.8028 10.1789 35.7071 10.0855L27.7072 2.28531C27.6111 2.19195 27.5006 2.12144 27.3828 2.07378C27.2649 2.02612 27.1357 2 27.0001 2L27.0002 2ZM32.5861 9.79987L28 5.32881V9.79987H32.5861ZM24.0001 37.1001C17.3726 37.1001 12.0001 31.8618 12.0001 25.4001C12.0001 18.9384 17.3726 13.7001 24.0001 13.7001C30.6275 13.7001 36.0001 18.9384 36.0001 25.4001C36.0001 28.28 34.9327 30.9168 33.1627 32.9551L39.7072 39.3353C40.0976 39.7162 40.0976 40.3335 39.7072 40.7145C39.3165 41.0952 38.6833 41.0952 38.2926 40.7145L31.7488 34.3337C29.6581 36.0593 26.9539 37.1001 24.0001 37.1001ZM24.0001 35.1498C29.5226 35.1498 33.9995 30.7846 33.9995 25.4004C33.9995 20.0156 29.5223 15.6502 24.0001 15.6502C18.4771 15.6502 13.9998 20.0154 13.9998 25.4004C13.9998 30.7849 18.477 35.1498 24.0001 35.1498ZM28.2426 30.9155C27.8522 30.5348 27.8522 29.9172 28.2426 29.5365C30.5857 27.252 30.5857 23.5482 28.2426 21.2633C27.8522 20.8827 27.8522 20.2654 28.2426 19.8847C28.633 19.5038 29.2664 19.5038 29.6569 19.8847C32.7811 22.9308 32.7811 27.8694 29.6569 30.9153C29.2665 31.2959 28.633 31.2959 28.2426 30.9153V30.9155Z"
    />
  </svg>
);

export default SearchFailIcon;
