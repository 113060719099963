import React, { FC } from 'react';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Modal from '../../../ui/modal';
import { IConfirmRemove } from './types';

const ConfirmRemoveModal: FC<IConfirmRemove> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Вы действительно хотите удалить ключ?"
      width={416}
      showCloseIcon
      wrapClassName="confirm-remove__wrapper"
      centered
      footer={
        <div className="confirm-remove__button-container">
          <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onOk}>
            Удалить
          </Button>
        </div>
      }
    />
  );
};

export default ConfirmRemoveModal;
