import Keycloak from 'keycloak-js';

const keycloak = new Keycloak('/keycloak.json');

const doLogin = keycloak.login;

const doLogout = keycloak.logout;

const getToken = () => keycloak.token;

const isLoggedIn = () => !!keycloak.token;

const updateToken = (successCallback = () => {}) => keycloak.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => keycloak.tokenParsed?.preferred_username;

const hasRole = (roles: string[]) => roles.some((role) => keycloak.hasRealmRole(role));

const initKeycloak = (onAuthenticatedCallback = () => {}) => {
  keycloak
    .init({
      onLoad: 'login-required',
    })
    .then((isAuthenticated) => {
      if (isAuthenticated) {
        onAuthenticatedCallback();
      } else {
        doLogin();
      }
    })
    .catch(() => {});
};

const AuthService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default AuthService;
