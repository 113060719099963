import React, { FC, useEffect, useRef } from 'react';
import FaqQuestion from '../ui/faqQuestion';
import { IFaqChapterComponent } from './types';

const FaqChapter: FC<IFaqChapterComponent> = (props) => {
  const { title = '', questions = [], onQuestionChangeOpen = () => {}, questionIdToOpen = '' } = props;

  const questionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.scrollIntoView();
    }
  }, []);

  return (
    <div className="faq-chapter">
      {title && <div className="faq-chapter__title">{title}</div>}
      <div className="faq-chapter__content">
        {questions.map((question) => (
          <div
            key={`faq-chapter-question-${question.id}`}
            className="faq-chapter__question"
            ref={questionIdToOpen === question.id ? questionRef : null}
          >
            <FaqQuestion
              question={question}
              defaultOpen={questionIdToOpen === question.id}
              onChangeOpen={onQuestionChangeOpen}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqChapter;
