import { TooltipPlacement } from 'antd/lib/tooltip';
import DeviceLog from '../../components/subscribersTabs/deviceLog';
import EquipmentTab from '../../components/subscribersTabs/equipmentTab';
import { ESubscribersTabsIds } from './types';
import SubscribersBaseTab from '../../components/subscribersTabs/baseTab';

const subscriberTabsTooltipProps = {
  placement: 'bottomRight' as TooltipPlacement,
  styles: { width: 120 },
};

export const subscribersTabs = [
  {
    id: ESubscribersTabsIds.basic,
    position: 0,
    title: 'Основное',
    component: SubscribersBaseTab,
  },
  {
    id: ESubscribersTabsIds.equipment,
    position: 1,
    title: 'Оборудование',
    component: EquipmentTab,
    notification:
      'Для перехода на вкладку "Оборудование" добавьте адрес абонента или переведите существующий адрес в статус "активен"',
    tooltipProps: subscriberTabsTooltipProps,
  },
  {
    id: ESubscribersTabsIds.accessPointLog,
    position: 2,
    title: 'Журнал',
    component: DeviceLog,
    notification: 'Для перехода на вкладку "Журнал" добавьте адрес абонента',
    tooltipProps: subscriberTabsTooltipProps,
  },
];
