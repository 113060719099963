import React, { FC, useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { IChangeLogoModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Message from '../../message';
import { useApi } from '../../../hooks/useApi';
import { putRequest } from '../../../api';
import { organizationUpdateLogoUrl } from '../../../constants/api';
import FileUploader from '../../ui/fileUploader';
import { onlyImgTypes } from '../../../constants/fileTypes';

const limit = 1048576;

const ChangeLogoModal: FC<IChangeLogoModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, requestCompany = () => {} } = props;

  const [img, setImg] = useState<File | null>(null);

  const { sendRequest: updateCompanyLogo, loading: logoLoading } = useApi(putRequest);

  const handleOnClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onSend = useCallback(async () => {
    if (img) {
      const data = new FormData();
      data.append('file', img);
      const resError = (await updateCompanyLogo(organizationUpdateLogoUrl(), data)) as AxiosError;
      if (resError?.response?.data) {
        Message.error({
          content: 'Не удалось загрузить логотип',
        });
      }
      onCancel();
      requestCompany();
    }
  }, [img, onCancel, requestCompany, updateCompanyLogo]);

  return (
    <Modal
      isOpen={isOpen}
      title="Загрузка логотипа"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      centered
      wrapClassName="change-logo-modal__wrapper"
      footer={
        <div className="change-logo-modal__button-container">
          <Button loading={logoLoading} type={ButtonType.secondary} size={ButtonSize.small} onClick={handleOnClose}>
            Отмена
          </Button>
          <Button loading={logoLoading} disabled={!img} size={ButtonSize.small} onClick={onSend}>
            Отправить
          </Button>
        </div>
      }
    >
      <div className="change-logo-modal">
        <FileUploader acceptFileTypes={onlyImgTypes} limit={limit} data={img} setImg={setImg} />
      </div>
    </Modal>
  );
};

export default ChangeLogoModal;
