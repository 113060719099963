import React, { FC, useCallback } from 'react';
import { IInput } from '../types';
import Input from '..';

const SerialNumberInput: FC<IInput> = (props) => {
  const { onChange = () => {}, value } = props;

  const onChangeInput = useCallback(
    (val: string) => {
      onChange(val.replace(/[^A-Za-z0-9]/gi, ''));
    },
    [onChange]
  );

  return <Input {...props} value={value} onChange={onChangeInput} />;
};

export default SerialNumberInput;
