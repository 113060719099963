export const zoomStep = 1.1;
export const minZoom = 0.05;
export const maxZoom = 14;

export const coefficientIncrease = 1.3;

export const accessPointMovePositionAfter = 3;
export const accessPointMinSize = 0.17;

export const labelMinSize = 0.3;

export const cornerDownSizeValue = 7;
export const cornerDownSizeMin = 3;
export const cornerUpSizeValue = 10;
export const cornerUpSizeMax = 13;

export const rotateDownSizeValue = 1;
export const rotateDownSizeMin = 0.4;
export const rotateUpSizeValue = 1;
export const rotateUpSizeMax = 1.6;
