import React, { FC } from 'react';

const CloseIcon: FC = () => (
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.853516 10.3618C0.536133 10.0508 0.548828 9.51123 0.847168 9.21289L4.63037 5.42334L0.847168 1.64014C0.548828 1.3418 0.54248 0.808594 0.853516 0.491211C1.16455 0.173828 1.7041 0.186523 2.00879 0.484863L5.79199 4.26807L9.56885 0.484863C9.87988 0.173828 10.4067 0.180176 10.7241 0.491211C11.0415 0.808594 11.0415 1.33545 10.7305 1.64648L6.95361 5.42334L10.7305 9.20654C11.0415 9.51758 11.0352 10.0444 10.7241 10.3618C10.4131 10.6792 9.87988 10.6792 9.56885 10.3682L5.79199 6.58496L2.00879 10.3682C1.7041 10.6665 1.1709 10.6792 0.853516 10.3618Z"
      fill="black"
    />
  </svg>
);

export default CloseIcon;
