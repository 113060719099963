import { EDispatcherDeviceStatus } from '../../../typings/dispatcherPanel';
import { ENotificationsTypes } from './types';

export const deviceStatusMap = new Map([
  [EDispatcherDeviceStatus.withErrors, 'Аварийное состояние'],
  [EDispatcherDeviceStatus.withWarning, 'Работает с некритичными проблемами'],
  [EDispatcherDeviceStatus.lost, 'Нет связи'],
]);

export const periodsMap = new Map([
  ['15', '15 мин'],
  ['30', '30 мин'],
  ['60', '1 час'],
  ['180', '3 часа'],
  ['360', '6 часов'],
  ['720', '12 часов'],
  ['1420', '24 часа'],
]);

export const notifyTypeMap = new Map([
  [ENotificationsTypes.all, 'Все'],
  [ENotificationsTypes.events, 'Статус'],
  [ENotificationsTypes.notification, 'Напоминание'],
]);
