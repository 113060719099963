import React, { FC } from 'react';

const ResizeToFullIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1" />
      <path
        d="M10.6531 21.8333C10.5127 21.8333 10.3965 21.7874 10.3047 21.6956C10.2129 21.6037 10.167 21.4876 10.167 21.3472V17.4583C10.167 17.3179 10.2129 17.2017 10.3047 17.1099C10.3965 17.0181 10.5127 16.9722 10.6531 16.9722C10.7935 16.9722 10.9097 17.0181 11.0015 17.1099C11.0933 17.2017 11.1392 17.3179 11.1392 17.4583V20.1805L20.1809 11.1388H17.4587C17.3182 11.1388 17.2021 11.0929 17.1103 11.0011C17.0185 10.9093 16.9725 10.7932 16.9725 10.6527C16.9725 10.5123 17.0185 10.3962 17.1103 10.3044C17.2021 10.2125 17.3182 10.1666 17.4587 10.1666H21.3475C21.488 10.1666 21.6041 10.2125 21.6959 10.3044C21.7877 10.3962 21.8337 10.5123 21.8337 10.6527V14.5416C21.8337 14.6821 21.7877 14.7982 21.6959 14.89C21.6041 14.9818 21.488 15.0277 21.3475 15.0277C21.2071 15.0277 21.091 14.9818 20.9992 14.89C20.9073 14.7982 20.8614 14.6821 20.8614 14.5416V11.8194L11.8198 20.8611H14.542C14.6824 20.8611 14.7986 20.907 14.8904 20.9988C14.9822 21.0906 15.0281 21.2067 15.0281 21.3472C15.0281 21.4876 14.9822 21.6037 14.8904 21.6956C14.7986 21.7874 14.6824 21.8333 14.542 21.8333H10.6531Z"
        fill="black"
      />
    </g>
  </svg>
);

export default ResizeToFullIcon;
