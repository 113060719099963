import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/es/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import CircleIcon from '../../../assets/svg/icons/circle';
import EditIcon from '../../../assets/svg/icons/edit';
import { IApiSortField } from '../../../typings/api';
import { ISubscriber } from '../../../typings/subscribers';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import Tooltip from '../../ui/tooltip';
import { ISubscribersTable } from './types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import { useAppSelector } from '../../../hooks/hooks';
import { checkIsAdmin } from '../../../store/selectors/profile';

const SubscribersTable: FC<ISubscribersTable> = (props) => {
  const {
    subscribers = [],
    sortOrders = [],
    onSort = () => {},
    loading = false,
    onEditRow = () => {},
    onClickRow = () => {},
    pagination = null,
    permission = {},
  } = props;

  const isAdmin = useAppSelector(checkIsAdmin);

  const handleOnChangeTable: TableProps<ISubscriber>['onChange'] = useCallback(
    (
      paginationConfig: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ISubscriber> | SorterResult<ISubscriber>[],
      extra: TableCurrentDataSource<ISubscriber>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<ISubscriber>[] = [];

        const addSortItemToSortResult = (item: SorterResult<ISubscriber>) => {
          if (item.order) {
            sortResults.push({
              field: item.field as keyof ISubscriber,
              descending: item.order === 'descend',
            });
          }
        };

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            addSortItemToSortResult(item);
          });
        } else {
          addSortItemToSortResult(sorter);
        }
        onSort(sortResults);
      }
    },
    [onSort]
  );

  const getColumnSortOrder = useCallback((sort: IApiSortField<ISubscriber> | null) => {
    if (sort) {
      return sort.descending ? 'descend' : 'ascend';
    }

    return null;
  }, []);

  const renderNameOrAddress = useCallback((record: ISubscriber, renderName: boolean) => {
    const addKey = renderName ? 'name' : 'address';

    return (
      <div role="presentation" className="subscribers-table__object-data-container">
        {record.subscriberObjects?.map((object) => {
          let isNameLonger = false;
          if (object.flats && object.flats.length <= 1 && object.flats[0]) {
            const flat = object.flats[0];
            const address = `${flat.buildingAddress || flat.address}${flat.number ? `, кв. ${flat.number}` : ''}`;
            isNameLonger = (object.name?.length || 0) > address.length;
          }
          return (
            <div
              className="subscribers-table__info-object"
              key={`subscribers-table-object-${object.objectId}-${addKey}`}
            >
              <div
                className={classNames('subscribers-table__info-object-name', {
                  'subscribers-table__info-object-name_hide': !renderName,
                  'subscribers-table__info-object-name_name-longer': isNameLonger,
                })}
              >
                {object.name}
              </div>
              <div
                className={classNames('subscribers-table__info-object-flats', {
                  'subscribers-table__info-object-flats_hide': renderName,
                  'subscribers-table__info-object-flats_name-longer': isNameLonger,
                })}
              >
                {object.flats?.map((flat) => (
                  <span key={`subscribers-table-object-apartment-${flat.flatId}-${addKey}`}>
                    {flat.buildingAddress || flat.address}
                    {flat.number ? `, кв. ${flat.number}` : ''}
                  </span>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const columns = useMemo<ColumnsType<ISubscriber>>(
    () =>
      [
        {
          title: 'Организация',
          dataIndex: 'companyName',
          key: 'companyName',
          hidden: !isAdmin,
          render: (_: any, record: ISubscriber) => <span role="presentation">{record.companyName}</span>,
        },
        {
          width: '18%',
          title: 'ФИО',
          dataIndex: 'lastName',
          key: 'lastName',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'lastName') || null) as SortOrder,
          render: (_: any, record: ISubscriber) => (
            <div role="presentation" className="subscribers-table__name-data-container">
              <Tooltip title={record.hasActiveApartment ? 'Активен' : 'Неактивен'} placement="bottom">
                <div
                  className={classNames('subscribers-table__status', {
                    'subscribers-table__status_active': record.hasActiveApartment,
                    'subscribers-table__status_inactive': !record.hasActiveApartment,
                  })}
                >
                  <CircleIcon />
                </div>
              </Tooltip>
              <span>{`${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`}</span>
            </div>
          ),
        },
        {
          width: '18%',
          title: 'Номер телефона',
          dataIndex: 'phone',
          key: 'phone',
          render: (_: any, record: ISubscriber) => <span role="presentation">+{record.phone}</span>,
        },
        {
          width: '18%',
          title: 'E–mail',
          dataIndex: 'email',
          key: 'email',
          render: (_: any, record: ISubscriber) => <span role="presentation">{record.email}</span>,
        },
        {
          width: '18%',
          title: 'Объект',
          dataIndex: 'subscriberObjects',
          key: 'subscriberObjects',
          render: (_: any, record: ISubscriber) => renderNameOrAddress(record, true),
        },
        {
          width: '18%',
          title: 'Адрес',
          dataIndex: 'address',
          key: 'address',
          render: (_: any, record: ISubscriber) => renderNameOrAddress(record, false),
        },
        {
          width: '10%',
          className: 'table__icon-cell',
          hidden: !permission.edit && !permission.delete,
          render: (record: ISubscriber) => (
            <div role="presentation" className="table__icon-container">
              {permission.edit && (
                <Tooltip title="Редактировать" placement="bottom">
                  <div
                    onClick={() => onEditRow(record.id || '', record)}
                    role="presentation"
                    className="subscribers-table__icon"
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [getColumnSortOrder, isAdmin, onEditRow, permission.delete, permission.edit, renderNameOrAddress, sortOrders]
  );

  const data = useMemo(
    () =>
      subscribers.map((subscriber) => ({
        ...subscriber,
        key: subscriber.id,
      })),
    [subscribers]
  );

  return (
    <div className="subscribers-table table">
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        onChange={handleOnChangeTable}
        locale={{
          emptyText: (
            <ErrorPlaceholder
              containerClassName="device-event-table__error-wrapper"
              text="По вашему запросу ничего не найдено"
              icon={<SearchFailIcon />}
            />
          ),
        }}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id || '', record),
        })}
        pagination={
          pagination
            ? {
                current: pagination.currentPage + 1,
                pageSize: pagination.pageSize,
                hideOnSinglePage: true,
                total: pagination.total,
                onChange: pagination.onChangePage,
                showSizeChanger: false,
                showQuickJumper: false,
                prevIcon: <ChevronLeft />,
                nextIcon: <ChevronRight />,
                className: 'table__pagination table__pagination_absolute',
              }
            : false
        }
      />
    </div>
  );
};

export default SubscribersTable;
