import { EDispatcherDeviceStatus, IDispatcherPanelColors } from '../../../typings/dispatcherPanel';
import { IInformationPanelStatus } from './types';

export const objectInfoDefaultStatuses: IInformationPanelStatus[] = [
  {
    id: EDispatcherDeviceStatus.active,
    colorCode: IDispatcherPanelColors.green,
    colorName: 'green',
    value: 0,
    label: 'работают исправно',
    fieldName: 'activeDeviceCount',
    objectNamesField: 'activeDeviceName',
    objectNames: [],
  },
  {
    id: EDispatcherDeviceStatus.withWarning,
    colorCode: IDispatcherPanelColors.yellow,
    colorName: 'yellow',
    value: 0,
    label: 'работают с некритичными проблемами',
    fieldName: 'withWarningsDeviceCount',
    objectNamesField: 'withWarningsDeviceName',
    objectNames: [],
  },
  {
    id: EDispatcherDeviceStatus.withErrors,
    colorCode: IDispatcherPanelColors.red,
    colorName: 'red',
    value: 0,
    label: 'аварийное состояние',
    fieldName: 'withErrorsDeviceCount',
    objectNamesField: 'withErrorsDeviceName',
    objectNames: [],
  },
  {
    id: EDispatcherDeviceStatus.withoutEvents,
    colorCode: IDispatcherPanelColors.blue,
    colorName: 'blue',
    value: 0,
    label: 'ввод в эксплуатацию',
    fieldName: 'withoutEventsDeviceCount',
    objectNamesField: 'withoutEventsDeviceName',
    objectNames: [],
  },
  {
    id: EDispatcherDeviceStatus.lost,
    colorCode: IDispatcherPanelColors.gray,
    colorName: 'gray',
    value: 0,
    label: 'нет связи',
    fieldName: 'lostDeviceCount',
    objectNamesField: 'lostDeviceName',
    objectNames: [],
  },
];
