import React, { FC, useCallback, useEffect, useState } from 'react';
import { ISubjectModal } from './types';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { useApi } from '../../../../hooks/useApi';
import { postRequest } from '../../../../api';
import { feedbackCategorySubjectBaseUrl } from '../../../../constants/api';
import { IInputValue, InputStatus, InputType, defaultRequiredValue } from '../../../ui/input/types';

const SubjectModal: FC<ISubjectModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, editItem = null, categoryId = '' } = props;

  const [name, setName] = useState<IInputValue>({ ...defaultRequiredValue });
  const [time, setTime] = useState<IInputValue>({ ...defaultRequiredValue });

  const { sendRequest: sendSubject, loading: sendSubjectLoading } = useApi(postRequest);

  const onChange = useCallback((setter: any) => (value: string) => setter({ ...defaultRequiredValue, value }), []);

  const onAccess = useCallback(async () => {
    const resError = (await sendSubject(feedbackCategorySubjectBaseUrl(), {
      name: name.value,
      id: editItem?.id,
      time: time.value,
      categoryId,
    })) as any;
    if (!resError?.response?.data) {
      onOk();
    } else if (resError?.response?.data?.errors) {
      setTime({ ...time, status: InputStatus.error, errorText: 'Проверьте правильность заполнения поля' });
    } else {
      setName({ ...name, status: InputStatus.error, errorText: 'Тема с таким названием уже существует' });
    }
  }, [categoryId, editItem?.id, name, onOk, sendSubject, time]);

  useEffect(() => {
    if (editItem && isOpen) {
      setName({ ...defaultRequiredValue, value: editItem.name });
      setTime({ ...defaultRequiredValue, value: editItem.time.toString() });
    } else {
      setName({ ...defaultRequiredValue });
      setTime({ ...defaultRequiredValue });
    }
  }, [editItem, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={!editItem ? 'Создание темы' : 'Редактирование темы'}
      width={410}
      showCloseIcon
      wrapClassName="faq-section-modal__wrapper"
      centered
      footer={
        <div className="faq-section-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel} disabled={sendSubjectLoading}>
            Отмена
          </Button>
          <Button
            size={ButtonSize.small}
            onClick={onAccess}
            disabled={sendSubjectLoading || !name.value.trim() || !time.value.trim()}
          >
            {!editItem ? 'Сохранить' : 'Редактировать'}
          </Button>
        </div>
      }
    >
      <Input
        title="Название темы"
        isRequired
        errorText={name.errorText}
        value={name.value}
        status={name.status}
        onChange={onChange(setName)}
        placeholder="Название темы"
        maxLength={40}
      />

      <Input
        title="Примерное время исполнения (часы)"
        isRequired
        errorText={time.errorText}
        status={time.status}
        value={time.value}
        onChange={onChange(setTime)}
        placeholder="Примерное время исполнения (часы)"
        inputType={InputType.number}
      />
    </Modal>
  );
};

export default SubjectModal;
