import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDisableNotificationsStatus } from '../../typings/notifications';

interface INotificationsState {
  isAllDisabled: boolean | null;
  isDisableExist: boolean | null;
  nextEvent: number | null;
}

const initialState: INotificationsState = {
  isAllDisabled: null,
  isDisableExist: null,
  nextEvent: null,
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setDisabledStatus: (state, action: PayloadAction<IDisableNotificationsStatus>) => {
      state.isAllDisabled = action.payload.isAllDisabled;
      state.isDisableExist = action.payload.isDisableExist;
      state.nextEvent = action.payload.nextEvent || null;
    },
  },
});

export const { setDisabledStatus } = notificationsSlice.actions;

export default notificationsSlice.reducer;
