export interface ICallCodeEditFlatsData {
  sectionId?: string;
  updateInBuildingCode: boolean;
  updateOutOfBuildingCode: boolean;
  updateWithPrefix: boolean;
  rewriteFlatCallCode: boolean;
  startFlatNumber: number;
  useFlatNumber: boolean;
  useCallCodesRange: boolean;
  startCallCode: string | null;
  endCallCode: string | null;
}

export interface ICallCodeClearData {
  sectionId?: string;
  clearInBuildingCodes: boolean;
  clearOutOfBuildingCodes: boolean;
}

export enum ICallCodeErrorType {
  notUniqInBuildingCode = 'NotUniqInBuildingCode',
  notUniqOutOfBuilding = 'NotUniqOutOfBuilding',
  flatNotFound = 'FlatNotFound',
  invalidCallCodeRange = 'InvalidCallCodeRange',
}
