import React, { FC } from 'react';

const DangerIcon: FC = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M41.9996 21.9998C41.9996 10.9722 33.0282 2 21.9998 2C10.9713 2 2 10.9722 2 21.9998C2 33.0273 10.9713 41.9996 21.9998 41.9996C33.0282 41.9996 41.9996 33.0273 41.9996 21.9998ZM21.9998 9.20617C29.0543 9.20617 34.7934 14.9455 34.7934 21.9998C34.7934 29.0541 29.0541 34.7934 21.9998 34.7934C14.9455 34.7934 9.20617 29.0541 9.20617 21.9998C9.20617 14.9455 14.9455 9.20617 21.9998 9.20617ZM22.4999 40.9871V35.7813C29.7082 35.5225 35.5221 29.7085 35.781 22.5002H40.9871C40.7245 32.5787 32.5786 40.725 22.4998 40.9866L22.4999 40.9871ZM40.9863 21.4998H35.7806C35.5218 14.2915 29.7078 8.47761 22.4995 8.21868V3.01294C32.5779 3.27521 40.7243 11.4211 40.9859 21.4993L40.9863 21.4998ZM21.499 3.01338V8.21913C14.2907 8.47791 8.47686 14.2919 8.21793 21.5002H3.01218C3.27446 11.4218 11.4203 3.27543 21.4986 3.01383L21.499 3.01338ZM3.01263 22.5007H8.21838C8.47716 29.709 14.2912 35.5228 21.4995 35.7818V40.9879C11.421 40.7253 3.27468 32.5794 3.01308 22.5006L3.01263 22.5007Z"
        fill="#F00000"
        stroke="#F00000"
      />
      <path d="M22 15V24" stroke="#F00000" strokeWidth="2" strokeLinecap="round" />
      <circle cx="22" cy="28" r="1" fill="#F00000" />
    </g>
  </svg>
);

export default DangerIcon;
