import { Moment } from 'moment';
import { ICompany, IObject, IPayment } from '../../typings/payments';
import { IPaymentApiParams, IPaymentsApiSettings } from '../../api/payments/types';

export interface IOrganizationsFilters {
  statusMap?: Map<any, string>;
  objects?: IObject[];
  apiSettings?: IPaymentsApiSettings<IPayment>;
  eventsApiParams?: IPaymentApiParams;
  isAdmin?: boolean | null;
  companies?: ICompany[];
  handleOnChangeDate?: (firstDate?: Moment, secondDate?: Moment) => void;
  handleOnSearch?: (val: string) => void;
  handleOnChangeSelect?: (key: string) => (val: string | number) => void;
  setValuesFromUrl?: (value: any) => void;
}

export enum EPaymentsFilters {
  companyId = 'companyId',
  objectId = 'objectId',
  status = 'status',
  date = 'date',
  search = 'search',
}
