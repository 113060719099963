import React, { FC, useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { ICategorySections } from './types';
import Scrollbar from '../../ui/scrollbar';
import PlusIcon from '../../../assets/svg/icons/plus';
import DragAndDropIcon from '../../../assets/svg/icons/dragAndDrop';
import EditIcon from '../../../assets/svg/icons/edit';
import TrashIcon from '../../../assets/svg/icons/trash';
import { useApi } from '../../../hooks/useApi';
import { deleteRequest, getRequest, postRequest } from '../../../api';
import {
  feedbackCategoryBaseIdUrl,
  getFeedbacksCategoryListUrl,
  feedbackCategorySortUrl,
} from '../../../constants/api';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, deleteModalWithFullName } from '../../ui/universalModal/config';
import Message from '../../message';
import CategoryModal from './categoryModal';
import { IFeedbackCategory } from '../../../typings/feedback';

const CategorySections: FC<ICategorySections> = ({
  blockDragAndDrop = false,
  activeSection = '',
  setActiveSection = () => {},
  isAdmin = false,
  permissions = {},
  search = '',
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editItem, setEditItem] = useState<IFeedbackCategory | null>(null);

  const { data: categories, sendRequest: getCategories } = useApi<IFeedbackCategory[]>(getRequest);

  const [currentCategories, setCurrentCategories] = useState<IFeedbackCategory[]>([]);

  const { sendRequest: deleteCategory } = useApi(deleteRequest);

  const { sendRequest: sortCategory } = useApi(postRequest);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const getData = useCallback(() => {
    getCategories(getFeedbacksCategoryListUrl(), {
      params: {
        search,
      },
    });
  }, [getCategories, search]);

  useEffect(() => {
    getData();
  }, [search]);

  useEffect(() => {
    setCurrentCategories(categories || []);
    if (categories?.length) {
      if (!categories.find((item) => item.id === activeSection)) {
        setActiveSection(categories[0].id);
      }
    } else {
      setActiveSection('');
    }
  }, [categories]);

  const reorder = useCallback((list: IFeedbackCategory[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      if (categories) {
        const newItems = reorder([...currentCategories], result.source.index, result.destination.index);
        setCurrentCategories(newItems);
        sortCategory(
          feedbackCategorySortUrl(),
          newItems.map((item, index) => ({ id: item.id, order: index }))
        );
      }
    },
    [currentCategories, reorder, categories, sortCategory]
  );

  const onItemClick = useCallback(
    (id: string) => () => {
      setActiveSection(id);
    },
    [setActiveSection]
  );

  const onCancel = useCallback(() => {
    setModalOpen(false);
    setEditItem(null);
  }, []);

  const onOk = useCallback(() => {
    Message.success({
      content: editItem ? 'Категория сохранена' : 'Новая категория создана',
    });
    onCancel();
    getData();
  }, [editItem, getData, onCancel]);

  const openModal = useCallback(
    (item: IFeedbackCategory | null = null) =>
      () => {
        setModalOpen(true);
        if (item) {
          setEditItem(item);
        }
      },
    []
  );

  const onDeleteSection = useCallback(
    (item: IFeedbackCategory) => () => {
      setConfirmData(
        deleteModalWithFullName(
          'Вы действительно хотите удалить данную категорию?',
          async () => {
            closeConfirm();
            await deleteCategory(feedbackCategoryBaseIdUrl(item.id));
            getData();
          },
          closeConfirm
        )
      );
    },
    [closeConfirm, deleteCategory, getData]
  );

  const renderSectionContent = useCallback(
    (item: IFeedbackCategory) => (
      <>
        {permissions.edit && currentCategories.length !== 1 && !blockDragAndDrop && (
          <div className="faq-section__item-dad">
            <DragAndDropIcon />
          </div>
        )}

        <div className="faq-section__item-label">{item.name}</div>
        <div className="faq-section__item-icons">
          {permissions.edit && (
            <div
              onClick={openModal(item)}
              role="presentation"
              className="faq-section__item-icon faq-section__item-icon_edit"
            >
              <EditIcon />
            </div>
          )}
          {permissions.delete && (
            <div
              onClick={onDeleteSection(item)}
              role="presentation"
              className="faq-section__item-icon faq-section__item-icon_trash"
            >
              <TrashIcon />
            </div>
          )}
        </div>
      </>
    ),
    [blockDragAndDrop, permissions, currentCategories.length, onDeleteSection, openModal]
  );

  return (
    <div className="faq-section">
      <CategoryModal isAdmin={isAdmin} isOpen={modalOpen} editItem={editItem} onCancel={onCancel} onOk={onOk} />

      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <div className="faq-section__title">
        <div className="faq-section__title-label">Категории</div>
        {permissions.create && (
          <div className="faq-section__title-icon" role="presentation" onClick={openModal(null)}>
            <PlusIcon />
          </div>
        )}
      </div>

      {currentCategories.length ? (
        <Scrollbar>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div className="faq-section__content" ref={provided.innerRef} {...provided.droppableProps}>
                  {currentCategories?.map((item, index) =>
                    !blockDragAndDrop ? (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided1) => (
                          <div
                            role="presentation"
                            onClick={onItemClick(item.id)}
                            className={classNames('faq-section__item', {
                              'faq-section__item_active': item.id === activeSection,
                              'faq-section__item_disabled': item.id !== activeSection,
                            })}
                            ref={provided1.innerRef}
                            {...provided1.dragHandleProps}
                            {...provided1.draggableProps}
                          >
                            {renderSectionContent(item)}
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <div
                        key={item.id}
                        role="presentation"
                        onClick={onItemClick(item.id)}
                        className={classNames('faq-section__item', {
                          'faq-section__item_active': item.id === activeSection,
                          'faq-section__item_disabled': item.id !== activeSection,
                        })}
                      >
                        {renderSectionContent(item)}
                      </div>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Scrollbar>
      ) : (
        <div className="faq-section__content faq-section__content_empty">Нет созданных категорий</div>
      )}
    </div>
  );
};

export default CategorySections;
