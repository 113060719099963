import { Moment } from 'moment';

export enum EDatePickerTimeType {
  hour = 'hour',
  minute = 'minute',
}

export enum EDatePickerRangeInfo {
  start = 'start',
  end = 'end',
}

export interface IDatePicker {
  textInfo?: string | React.ReactNode;
  showCalendarIcon?: boolean;
  title?: string;
  isRequired?: boolean;
  containerClassName?: string;
  titleClassName?: string;
  firstDate?: string | Date;
  secondDate?: string | Date;
  isError?: boolean;
  errorText?: string;
  errorTextClassName?: string;
  format?: string;
  onChange?: (firstDate?: Moment, secondDate?: Moment, firstDateString?: string, secondDateString?: string) => void;
  disabled?: boolean;
  placeholder?: string;
  showTime?: boolean;
  isDisabledStyle?: boolean;
  isCanUnlimited?: boolean;
  onlyFuture?: boolean;
  showClearIcon?: boolean;
}
