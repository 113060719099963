import { IHardwareParamNumberRange } from '../../../../../../../typings/systems/hardwareSettings';
import { emptyFieldErrorText } from '../types';
import { INumberRangeFieldError } from './types';

export const getNumberRangeFieldError = (
  numberRangeFieldValue: Omit<IHardwareParamNumberRange, 'defaultValue'>
): INumberRangeFieldError => {
  const result: INumberRangeFieldError = {};

  const { value } = numberRangeFieldValue;

  if (numberRangeFieldValue.isRequired && !value?.from && value?.from !== 0 && !value?.to && value?.to !== 0) {
    result.errorText = emptyFieldErrorText;
  } else if (value) {
    const {
      ToMaxValue: toMaxValue,
      ToMinValue: toMinValue,
      FromMaxValue: fromMaxValue,
      FromMinValue: fromMinValue,
    } = numberRangeFieldValue.restricts.restricts;

    if (numberRangeFieldValue.isRequired) {
      if (!value.from && value.from !== 0) {
        result.fromErrorText = emptyFieldErrorText;
      }
      if (!value.to && value.to !== 0) {
        result.toErrorText = emptyFieldErrorText;
      }
    }

    if (value.from || value.from === 0) {
      const fromMinIsError =
        (fromMinValue || fromMinValue === 0) && value.from < parseInt(fromMinValue?.toString(), 10);
      const fromMaxIsError =
        (fromMaxValue || fromMaxValue === 0) && value.from > parseInt(fromMaxValue?.toString(), 10);

      if (fromMinIsError && fromMaxIsError) {
        result.fromErrorText = `Введите число от ${fromMinValue} до ${fromMaxValue}`;
      } else if (fromMinIsError) {
        result.fromErrorText = `Введите число от ${fromMinValue}`;
      } else if (fromMaxIsError) {
        result.fromErrorText = `Введите число до ${fromMaxValue}`;
      }
    }

    if (value.to || value.to === 0) {
      const toMinIsError = (toMinValue || toMinValue === 0) && value.to < parseInt(toMinValue?.toString(), 10);
      const toMaxIsError = (toMaxValue || toMaxValue === 0) && value.to > parseInt(toMaxValue?.toString(), 10);
      if (toMinIsError && toMaxIsError) {
        result.toErrorText = `Введите число от ${toMinValue} до ${toMaxValue}`;
      } else if (toMinIsError) {
        result.toErrorText = `Введите число от ${toMinValue}`;
      } else if (toMaxIsError) {
        result.toErrorText = `Введите число до ${toMaxValue}`;
      }
    }
  }

  return result;
};
