import { AxiosRequestConfig } from 'axios';
import { IApiSettings } from '../../typings/api';
import { IFlat } from '../../typings/systems/flat';
import { axiosDelete, axiosPost, axiosPut } from '../axios';

export const getFlats = async <T>(url: string, settings: IApiSettings<T>): Promise<T> => {
  const result = await axiosPost(url, settings);
  return result.data;
};

export const addFlats = async (url: string, startNumber: number, endNumber: number) => {
  const result = await axiosPost(url, { startNumber, endNumber });
  return result.data;
};

export const addFlat = async (url: string, flatNumber: number) => {
  const result = await axiosPost(url, flatNumber);
  return result.data;
};

export const deleteFlat = async (url: string, config?: AxiosRequestConfig) => {
  const result = await axiosDelete(url, config);
  return result.data;
};

export const editFlat = async (url: string, flat: IFlat) => {
  const result = await axiosPut(url, flat);
  return result.data;
};
