import { IHardwareParamArrayNumberRange, IHardwareRange } from '../../../../../../../typings/systems/hardwareSettings';
import { emptyFieldErrorText } from '../types';
import { IArrayNumberRangeFieldError, IArrayNumberRangeFieldItemError } from './types';

export const getArrayNumberRangeFieldError = (
  arrayNumberRangeFieldValue: Omit<IHardwareParamArrayNumberRange, 'defaultValue'>
): IArrayNumberRangeFieldError => {
  const result: IArrayNumberRangeFieldError = { errorText: '', items: [] };

  if (arrayNumberRangeFieldValue.isRequired && !arrayNumberRangeFieldValue.value) {
    result.errorText = emptyFieldErrorText;
    return result;
  }

  arrayNumberRangeFieldValue.value?.forEach((item: IHardwareRange, index) => {
    const errorData: IArrayNumberRangeFieldItemError = {};

    if (arrayNumberRangeFieldValue.isRequired) {
      if (!item.from && item.from !== 0) {
        errorData.index = index;
        errorData.fromErrorText = 'Заполните или удалите диапазон';
      }

      if (!item.to && item.to !== 0) {
        errorData.index = index;
        errorData.toErrorText = 'Заполните или удалите диапазон';
      }
    }

    if (errorData.fromErrorText || errorData.toErrorText) {
      result.items?.push(errorData);
    }
  });

  const { value } = arrayNumberRangeFieldValue;
  if (value) {
    if (parseInt(arrayNumberRangeFieldValue.restricts.restricts.maxCount?.toString(), 10) < value.length) {
      result.errorText = `Максимальное количество значений: ${arrayNumberRangeFieldValue.restricts.restricts.maxCount}`;
    }
  }
  return result;
};
