import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useAppDispatch } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { dispatcherPanelEventUrl } from '../../../constants/api';
import { EDispatcherDeviceStatus, IDispatcherPanelDevice, fromStatusToName } from '../../../typings/dispatcherPanel';
import DeviceErrorsTable from '../../../components/deviceErrorsTable';
import ButtonLink from '../../../components/ui/buttonLink';
import { paths } from '../../../constants/paths';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';

const DeviceEventItem: FC = () => {
  const params = useParams();

  const dispatch = useAppDispatch();

  const { data: deviceLogs, sendRequest: getDeviceLogs } = useApi<IDispatcherPanelDevice>(getRequest);

  useEffect(() => {
    getDeviceLogs(dispatcherPanelEventUrl(params.deviceId || '', params.eventId || ''));
  }, [params.systemId]);

  useEffect(() => {
    if (deviceLogs) {
      dispatch(setHeaderTitle('Ошибки и предупреждения'));
    }
  }, [deviceLogs]);

  return (
    <>
      <CommonHead seo={{ title: 'Ошибки и предупреждения' }} />
      <div className="device-page">
        <div className="device-page__button-wrapper">
          <ButtonLink
            url={`${paths.dispatcherPanel}/${params.deviceId}?tabId=journal`}
            leftIcon={<ArrowBackIcon />}
            content="К оборудованию"
          />
        </div>
        <div className="device-page__title">{deviceLogs?.header}</div>
        <DeviceErrorsTable
          emptyText={fromStatusToName.get(deviceLogs?.status as EDispatcherDeviceStatus)}
          errors={deviceLogs?.errors}
          warnings={deviceLogs?.warnings}
        />
      </div>
    </>
  );
};

export default DeviceEventItem;
