import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IChangeAssignModal } from './types';
import { ISelectOption } from '../../ui/select/types';
import Select from '../../ui/select';
import { postRequest } from '../../../api';
import { feedbackStatusUrl, getUsersListUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { IApiResponse } from '../../../typings/api';
import { IUser } from '../../../typings/users';

const ChangeAssignModal: FC<IChangeAssignModal> = (props) => {
  const {
    isOpen = false,
    onCancel = () => {},
    defaultStatus = '',
    onOk = () => {},
    feedbackId,
    assignedUserEmailDefault = '',
    assignedUserFioDefault = '',
    assignedUserIdDefault = '',
  } = props;

  const { sendRequest: changeStatus, loading: changeStatusLoading } = useApi(postRequest);

  const { data: users, sendRequest: getUsersList, loading: usersLoading } = useApi<IApiResponse<IUser>>(postRequest);

  const [assignedUserEmail, setAssignedUserEmail] = useState<string>(assignedUserEmailDefault);

  const [assignedUserFio, setAssignedUserFio] = useState<string>(assignedUserFioDefault);

  const [assignedUserId, setAssignedUserId] = useState<string>(assignedUserIdDefault);

  const getUsers = useCallback(() => {
    getUsersList(getUsersListUrl(), { page: 0, pageSize: -1 }, { params: { count: 0 } });
  }, []);

  useEffect(() => {
    if (isOpen) {
      getUsers();
    }
  }, [isOpen]);

  const onSave = useCallback(async () => {
    const resError = (await changeStatus(feedbackStatusUrl(feedbackId || ''), {
      status: defaultStatus,
      assignedUserEmail,
      assignedUserFio,
      assignedUserId,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    }
  }, [assignedUserEmail, assignedUserFio, assignedUserId, changeStatus, defaultStatus, feedbackId, onOk]);

  const setUserData = useCallback(
    (id: string) => {
      setAssignedUserId(id);
      const user = users?.items?.find((item) => item.id === id);
      if (user) {
        setAssignedUserEmail(user.email);
        setAssignedUserFio(`${user.middleName} ${user.firstName} ${user.lastName}`);
      }
    },
    [users?.items]
  );

  const handleOnChangeUser = useCallback(
    (e: string | number) => {
      setUserData(e.toString());
    },
    [setUserData]
  );

  const usersSelectItems = useMemo(
    () =>
      users?.items?.map<ISelectOption>((item) => ({
        value: item.id,
        title: `${item.lastName} ${item.firstName}`,
      })),
    [users?.items]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Изменение исполнителя"
      width={416}
      showCloseIcon
      wrapClassName="change-priority__wrapper"
      centered
      footer={
        <div className="change-priority__button-container">
          <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onCancel} disabled={changeStatusLoading}>
            Отмена
          </Button>
          <Button disabled={changeStatusLoading} size={ButtonSize.small} onClick={onSave}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Select
        loading={usersLoading}
        title="Исполнитель"
        value={assignedUserId}
        onChange={handleOnChangeUser}
        options={usersSelectItems}
      />
    </Modal>
  );
};

export default ChangeAssignModal;
