import React, { FC } from 'react';

const VolumeIcon: FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#1444F2" strokeOpacity="0.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0025 20.4447C9.99874 22.1038 9.92471 24.3667 10.9511 25.2126C11.9085 26.0016 12.5823 25.7983 14.3303 25.9266C16.0794 26.0561 19.771 31.1978 22.6168 29.5714C24.0849 28.417 24.1941 25.9968 24.1941 20.4447C24.1941 14.8927 24.0849 12.4725 22.6168 11.318C19.771 9.69041 16.0794 14.8334 14.3303 14.9629C12.5823 15.0912 11.9085 14.8879 10.9511 15.6769C9.92471 16.5227 9.99874 18.7857 10.0025 20.4447Z"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.6729 15.4717C31.4421 18.5623 31.4421 22.3367 29.6729 25.4176"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VolumeIcon;
