import React, { FC, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { IDisablingPush } from './types';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { dispatcherPanelDisableNotificationsUrl, notifyDisabledUrl } from '../../../constants/api';
import DisablingPushForm from './disablingPushForm';
import { IDisablePushPeriod } from '../../../typings/dispatcherPanel';
import DisablingPushTable from './disablingPushTable';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Button from '../../ui/button';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import FilterIcon from '../../../assets/svg/icons/filter';
import { IDisableNotificationsStatus } from '../../../typings/notifications';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getDisabledStatus } from '../../../store/selectors/notifications';
import { setDisabledStatus } from '../../../store/slices/notifications';
import { calculateNextEvent } from '../../../functions';

const DisablingPush: FC<IDisablingPush> = ({ children, deviceId, objectId, className, permissions = {} }) => {
  const { data, sendRequest, loading } = useApi<IDisablePushPeriod[]>(getRequest);

  const { data: disableNotificationsStatus, sendRequest: getDisableNotifications } =
    useApi<IDisableNotificationsStatus>(getRequest);

  const disableData = useAppSelector(getDisabledStatus);

  const dispatch = useAppDispatch();

  const [isOpen, setOpen] = useState(false);

  const getData = useCallback(() => {
    getDisableNotifications(dispatcherPanelDisableNotificationsUrl());
    sendRequest(notifyDisabledUrl());
  }, [getDisableNotifications, sendRequest]);

  useEffect(() => {
    if (disableData.isAllDisabled !== null && disableData.isDisableExist !== null) {
      getData();
    }
  }, [disableData.isAllDisabled, disableData.isDisableExist]);

  useEffect(() => {
    if (isOpen && !loading) {
      getData();
    }
  }, [isOpen]);

  useEffect(() => {
    if (disableNotificationsStatus) {
      dispatch(setDisabledStatus({ ...disableNotificationsStatus, nextEvent: calculateNextEvent(data || []) }));
    }
  }, [disableNotificationsStatus, data]);

  return permissions?.edit ? (
    <div className={`disabling-push ${className || ''}`}>
      <div className="disabling-push__buttons">
        {children}
        <Button
          className={classNames('disabling-push__buttons-push', {
            'disabling-push__buttons-push_open': isOpen,
            'disabling-push__buttons-push_active': disableData.isAllDisabled || disableData.isDisableExist,
          })}
          type={ButtonType.outline}
          size={ButtonSize.small}
          onClick={() => setOpen(!isOpen)}
          leftIcon={<FilterIcon />}
          rightIcon={<ChevronDown />}
        >
          Отключить push-уведомления
        </Button>
      </div>
      {isOpen && (
        <div className="disabling-push__content">
          <div className="disabling-push__pseudo-block" />
          <DisablingPushForm defaultDeviceId={deviceId} defaultObjectId={objectId} getData={getData} />
          {data && data.length !== 0 && <DisablingPushTable requestData={getData} periods={data} loading={loading} />}
        </div>
      )}
    </div>
  ) : (
    <div className={className}>{children}</div>
  );
};

export default DisablingPush;
