import { IProfilePermission } from '../../../../../../typings/profile';
import { IHardwareParam, IHardwareSettings } from '../../../../../../typings/systems/hardwareSettings';

export interface ISettingsSection {
  settings: IHardwareSettings;
  wasSelected: boolean;
  changeSettings: (settings: IHardwareSettings) => void;
  permissions?: IProfilePermission;
}

export interface IParamField {
  index: number;
  object: IHardwareParam;
  wasSelected: boolean;
  changeParam: (index: number, param: IHardwareParam) => void;
  disabled?: boolean;
  isEditEnable?: boolean;
}

export interface IHardwareMapParam {
  object: IHardwareParam;
  index: number;
}

export interface IHardwareMapGroups {
  collection: IHardwareMapParam[];
  position: number;
}

export const emptyFieldErrorText = 'Поле должно быть заполнено';
