import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { editPathStyle, pseudoChildStyle } from '../../../../../constants/paper';
import { findObjectInGroups } from '../../common';
import {
  EPaperGroupTypes,
  EPaperHitResults,
  TPaperCircle,
  TPaperEvent,
  TPaperGroup,
  TPaperLayer,
  TPaperPath,
  TPaperPathItem,
  TPaperSegment,
} from '../../../types';
import { createCircle, getDefaultPath, getLayer, getPoint } from '../../paperItems/items';
import { ISegmentsPoint } from './types';
import { TreeNodeType } from '../../../../../typings/treeNode';
import { ECursors } from '../../paperItems/types';
import { changeVisibleGroup } from '../../../calculations';
import { ISchemaOpacity } from '../../../../systemTabs/schemaTab/types';

export class Editor {
  private controlPoints: TPaperCircle[] = [];

  private editablePath: TPaperPath | null = null;

  private parentGroup: TPaperGroup | null = null;

  private editableGroup: TPaperGroup;

  private setCursor: (cursor: ECursors) => void;

  private setPath: (path: TPaperPath, wasOpen?: boolean) => void;

  private setContextMenuItems: React.Dispatch<React.SetStateAction<ItemType[]>>;

  private childrenLimit: TPaperPathItem;

  private haveLimit = false;

  private editLayer: TPaperLayer;

  private opacityValues: ISchemaOpacity;

  constructor(
    group: TPaperGroup,
    opacity: ISchemaOpacity,
    setCursor: (cursor: ECursors) => void,
    setPath: (path: TPaperPath, wasOpen?: boolean) => void,
    setMenuItems: React.Dispatch<React.SetStateAction<ItemType[]>>
  ) {
    changeVisibleGroup(group, false, opacity);
    group.parent.locked = true;
    this.opacityValues = opacity;
    this.editableGroup = group;
    this.setCursor = setCursor;
    this.setPath = setPath;
    this.setContextMenuItems = setMenuItems;
    this.childrenLimit = getDefaultPath();
    this.editLayer = getLayer();
    this.startEdit();
  }

  private startEdit = () => {
    if (this.editableGroup.parent && this.editableGroup.parent.data.key !== TreeNodeType.object) {
      this.parentGroup = findObjectInGroups(this.editableGroup.parent);
    }
    const group = findObjectInGroups(this.editableGroup);
    if (group) {
      const path = group.children[0] as TPaperPath;
      this.editablePath = path.clone();
      this.setPath(this.editablePath);
      this.editablePath.set({
        ...editPathStyle,
        onMouseMove: this.onPathMouseMove,
        onMouseLeave: () => this.setCursor(ECursors.default),
        onClick: this.onPathClick(),
      });
      this.editablePath.opacity = 0.5;
      this.editLayer.addChild(this.editablePath);
      this.createChildrenGroup(this.editableGroup);
      this.getPointsWithSegments(this.editablePath.segments).forEach((item) => this.createPointOnPath(item));
    }
  };

  private createChildrenGroup = (group: TPaperGroup) => {
    group.children.forEach((child) => {
      if (child.data.groupType !== EPaperGroupTypes.object && child.data.type !== TreeNodeType.accessPoint) {
        this.haveLimit = true;
        const clone = this.getPseudoChild(child) as TPaperPath;
        const result = this.childrenLimit.unite(clone);
        this.childrenLimit.remove();
        this.childrenLimit = result;
        clone.remove();
      }
    });
    this.editLayer.addChild(this.childrenLimit);
    this.childrenLimit.opacity = 0.3;
    this.childrenLimit.bringToFront();
  };

  private getPseudoChild = (group: TPaperGroup) => {
    const groupPath = findObjectInGroups(group)?.children[0] as TPaperPath;

    if (groupPath) {
      const pseudoGroup = groupPath.clone();
      pseudoGroup.set(pseudoChildStyle);
      pseudoGroup.opacity = 0.3;
      this.editLayer.addChild(pseudoGroup);
      return pseudoGroup;
    }

    return null;
  };

  private getPointsWithSegments = (segments: TPaperSegment[]) => {
    const mapPoints: ISegmentsPoint[] = [];

    segments.forEach((segment) => {
      const { x, y } = segment.point;

      const index = mapPoints.findIndex((item) => item.point.x === x && item.point.y === y);

      if (index !== -1) {
        mapPoints[index].segments.push(segment);
      } else {
        mapPoints.push({ point: { x, y }, segments: [segment] });
      }
    });

    return mapPoints;
  };

  private onPathMouseMove = (e: TPaperEvent) => {
    const hitResult = this.editablePath?.hitTest(e.point);
    this.setCursor(hitResult && hitResult.type === EPaperHitResults.stroke ? ECursors.crosshair : ECursors.default);
  };

  private onPathClick = () => (e: TPaperEvent) => {
    const hitResult = this.editablePath?.hitTest(e.point);
    if (hitResult && hitResult.type === EPaperHitResults.stroke) {
      const segment = this.editablePath?.insert(hitResult.location.index + 1, e.point);
      if (segment) {
        this.createPointOnPath({ point: { x: segment.point.x, y: segment.point.y }, segments: [segment] });
      }
    }
  };

  private onDeletePointInPath = (point: TPaperCircle, data: ISegmentsPoint) => {
    this.controlPoints = this.controlPoints.filter((item) => item !== point);
    point.remove();
    data.segments.forEach((segment) => segment.remove());
    if (this.editablePath) {
      this.setPath(this.editablePath);
    }
    this.setContextMenuItems([]);
  };

  private createPointOnPath = (data: ISegmentsPoint) => {
    const point = createCircle(
      { center: getPoint(data.point.x, data.point.y), radius: 3, needLabel: false },
      {
        onMouseEnter: () => this.setCursor(ECursors.move),
        onMouseLeave: () => this.setCursor(ECursors.default),
      }
    );
    point.onMouseDrag = this.onPointDrag(point, data);
    point.onClick = this.onPointClick(point, data);

    point.bringToFront();
    this.controlPoints.push(point);
  };

  private onPointClick = (point: TPaperCircle, data: ISegmentsPoint) => (e: any) =>
    e.event.which === 3 &&
    this.controlPoints.length > 3 &&
    this.setContextMenuItems([
      {
        key: 'delete-point',
        label: 'Удалить',
        onClick: () => this.onDeletePointInPath(point, data),
      },
    ]);

  private onPointDrag = (point: TPaperCircle, data: ISegmentsPoint) => (e: TPaperEvent) => {
    e.stopPropagation();
    if (this.editablePath) {
      const newPosition = e.point;
      const oldPosition = point.position;

      if (this.parentGroup && !this.parentGroup.contains(newPosition)) {
        return;
      }

      if (this.haveLimit && !this.childrenLimit.contains(point.position) && this.childrenLimit.contains(newPosition)) {
        return;
      }

      const parentPath = this.parentGroup ? (findObjectInGroups(this.parentGroup)?.children[0] as TPaperPath) : null;

      const crossingsWithLimit = this.childrenLimit.getCrossings(this.editablePath);
      const crossingsWithParent = parentPath ? parentPath.getCrossings(this.editablePath) : [];

      point.position = newPosition;
      data.segments.forEach((segment) => (segment.point = point.position));

      const crossingsWithLimit2 = this.childrenLimit.getCrossings(this.editablePath);
      const crossingsWithParent2 = parentPath ? parentPath.getCrossings(this.editablePath) : [];

      if (
        crossingsWithLimit.length < crossingsWithLimit2.length ||
        crossingsWithParent.length < crossingsWithParent2.length
      ) {
        point.position = oldPosition;
        data.segments.forEach((segment) => (segment.point = oldPosition));
      }

      this.setPath(this.editablePath);
    }
  };

  resetProject = () => {
    this.editLayer.remove();
    changeVisibleGroup(this.editableGroup, true, this.opacityValues);
    this.editableGroup.parent.locked = false;
  };
}
