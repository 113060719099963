import React, { FC } from 'react';

const ShapeRectangleC: FC = () => (
  <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 3V54.5C0.5 55.6046 1.39543 56.5 2.5 56.5H17C18.1046 56.5 19 55.6046 19 54.5V39C19 37.8954 19.8954 37 21 37H37C38.1046 37 39 37.8954 39 39V54.5C39 55.6046 39.8954 56.5 41 56.5H54.5C55.6046 56.5 56.5 55.6046 56.5 54.5V3C56.5 1.89543 55.6046 1 54.5 1H2.5C1.39543 1 0.5 1.89543 0.5 3Z"
      stroke="#1444F2"
    />
  </svg>
);

export default ShapeRectangleC;
