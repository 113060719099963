import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { IExpandableBlock } from './types';
import ChevronDown from '../../../assets/svg/icons/chevronDown';

const ExpandableBlock: FC<IExpandableBlock> = (props) => {
  const { title = '', openDefault = true, wrapperClassName = '', children = null } = props;

  const [isOpen, setIsOpen] = useState(openDefault);

  return (
    <div className={`expandable-block ${wrapperClassName}`}>
      <div className="expandable-block__title" role="presentation" onClick={() => setIsOpen(!isOpen)}>
        <div className="expandable-block__title-text">{title}</div>
        <div
          className={classNames('expandable-block__title-arrow', {
            'expandable-block__title-arrow_up': isOpen,
          })}
        >
          <ChevronDown />
        </div>
      </div>
      {isOpen && children}
    </div>
  );
};

export default ExpandableBlock;
