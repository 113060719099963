import { Table } from 'antd';
import { ColumnType } from 'antd/es/table/interface';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { addAccessGroup } from '../../../../api/accessGroups';
import AddNodeIcon from '../../../../assets/svg/icons/addNote';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { addAccessGroupUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { ESystemTabsIds } from '../../../../pages/systems/item/types';
import { ECheckboxStatus, IAccessGroupHeader, IAccessGroupRow } from '../../../../typings/systems/accessGroups';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import Checkbox from '../../../ui/checkbox';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import Tooltip from '../../../ui/tooltip';
import { EErrorType } from '../types';
import { IAccessGroupsTable } from './types';
import { TreeNodeType } from '../../../../typings/treeNode';
import CheckListIcon from '../../../../assets/svg/icons/checkList';
import InfoIcon from '../../../../assets/svg/icons/info';

const AccessGroupsTable: FC<IAccessGroupsTable> = (props) => {
  const {
    data = null,
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    selectedNode = null,
    onEditRow = () => {},
    isSearch = false,
    onChangeTab = () => {},
    permissions = {},
    settings,
  } = props;

  const { sendRequest, loading: loadingRequest } = useApi(addAccessGroup);

  const tableRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  const showOrHideButtons = useCallback(
    (scroll = scrollElementRef.current) => {
      if (scroll && tableRef.current) {
        if (tableRef.current.offsetWidth < scroll.scrollWidth) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        } else {
          hideButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        }
      }
    },
    [hideButton, showButton]
  );

  const scrollEvent = useCallback(() => {
    const value = scrollElementRef.current?.scrollLeft || 0;
    const maxValue = scrollElementRef.current?.scrollWidth || 0;
    const width = scrollElementRef.current?.offsetWidth || 0;
    if (value > 50) {
      showButton(chevronLeftRef.current, chevronLefIsHidden);
    } else if (value < 50) {
      hideButton(chevronLeftRef.current, chevronLefIsHidden);
    }
    if (value + width >= maxValue - 20) {
      hideButton(chevronRightRef.current, chevronRightIsHidden);
    } else {
      showButton(chevronRightRef.current, chevronRightIsHidden);
    }
  }, [hideButton, showButton]);

  const calculatePositionLeftButton = useCallback(() => {
    if (tableRef.current && chevronLeftRef.current) {
      const item = tableRef.current.getElementsByClassName('ant-table-cell-fix-left-last')[0];
      if (item) {
        chevronLeftRef.current.style.transform = `translate(${item.scrollWidth}px, 0)`;
      }
    }
  }, []);

  useEffect(() => {
    if (!scrollElementRef.current && tableRef.current) {
      const element = tableRef.current.getElementsByClassName('ant-table')[0];
      if (element) {
        scrollElementRef.current = element as HTMLDivElement;
        showOrHideButtons(scrollElementRef.current);
        scrollElementRef.current.onscroll = scrollEvent;
      }
    }
  }, [tableRef, tableRef.current]);

  useEffect(() => {
    if (selectedNode) {
      showOrHideButtons();
      calculatePositionLeftButton();
    }
  }, [selectedNode, data]);

  const scrollTo = useCallback(
    (toRight = true) =>
      () => {
        if (scrollElementRef.current) {
          scrollElementRef.current.scrollBy({ left: toRight ? 200 : -200, behavior: 'smooth' });
        }
      },
    []
  );

  const handleOnChangeCheckbox = useCallback(
    (itemId: string | null, accessPointId: string | null, cameraId: string | null) => async (isEnabled: boolean) => {
      if (selectedNode) {
        await sendRequest(
          addAccessGroupUrl(selectedNode.itemId),
          {
            itemId,
            accessPointId,
            cameraId,
            isEnabled,
          },
          {
            params: {
              type: selectedNode.itemType,
            },
          }
        );
        onEditRow();
      }
    },
    [onEditRow, selectedNode, sendRequest]
  );

  const getColumns = useCallback((): ColumnType<IAccessGroupRow>[] => {
    if (data) {
      const columns: ColumnType<IAccessGroupRow>[] = [
        {
          title: 'Название',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left',
          render: (_: string, record: IAccessGroupRow) =>
            record.objectItemTypes === 'ObjectKey' ? (
              <div className="editable-table__data-container access-group-table__key">
                <div className="access-group-table__key-name">{record.keyValue}</div>
                <div className="access-group-table__key-alias">{record.keyAlias}</div>
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_name">{record.name}</div>
            ),
        },
      ];

      data.headers.forEach((header: IAccessGroupHeader, index: number) => {
        if (header.cameraId && !settings.camera) return;
        columns.push({
          title: (
            <div className="access-group-table__title">
              <div className="access-group-table__title-content">
                <div className="access-group-table__title-label">{header.shortName || header.name}</div>
                <Tooltip
                  className="access-group-table__tooltip"
                  title={
                    <div className="access-group-table__tooltip-text">
                      {Array.isArray(header.place)
                        ? header.place.map((item, i) => <span key={`tooltip-label-${index}-${i}`}>{item}</span>)
                        : header.place}
                    </div>
                  }
                  placement="bottomRight"
                >
                  <div role="presentation" className="access-group-table__icon">
                    <InfoIcon />
                  </div>
                </Tooltip>
              </div>

              <Checkbox
                className={header.check === ECheckboxStatus.partially ? 'access-group-table__title-checkbox-p' : ''}
                checked={header.check !== ECheckboxStatus.uncheck}
                valueKey="isDirectCall"
                onChange={handleOnChangeCheckbox(null, header.accessPointId, header.cameraId)}
                disabled={!permissions.edit}
              />
            </div>
          ),
          dataIndex: header.accessPointId || header.cameraId,
          key: `${header.accessPointId || header.cameraId}-${index}`,
          render: (_, record: IAccessGroupRow) => {
            let item;
            if (header.cameraId) {
              item = record.accessPointGroups.find((itemData) => itemData.cameraId === header.cameraId);
            } else {
              item = record.accessPointGroups.find((itemData) => itemData.accessPointId === header.accessPointId);
            }
            return (
              <div className="editable-table__data-container">
                {item ? (
                  <Checkbox
                    checked={item.isEnabled}
                    onChange={handleOnChangeCheckbox(item.itemId, item.accessPointId, item.cameraId)}
                    disabled={!permissions.edit || !record.isEdit}
                  />
                ) : (
                  <Checkbox checked={false} disabled={!permissions.edit} />
                )}
              </div>
            );
          },
        });
      });

      return columns;
    }
    return [];
  }, [data, permissions, handleOnChangeCheckbox]);

  const columns = useMemo(() => getColumns(), [getColumns]);

  const errorsData = useMemo(
    () => ({
      needKeys: (
        <ErrorPlaceholder
          text={
            <>
              <span>Нет элементов для настройки</span>
              {permissions.create && (
                <Button onClick={() => onChangeTab(ESystemTabsIds.objectKeys)} type={ButtonType.outline}>
                  Добавить ключи
                </Button>
              )}
            </>
          }
          icon={<AddNodeIcon />}
        />
      ),
      needPlan: (
        <ErrorPlaceholder
          text={
            <>
              <span>Нет элементов для настройки</span>
              {permissions.edit && (
                <Button onClick={() => onChangeTab(ESystemTabsIds.plan)} type={ButtonType.outline}>
                  Добавить на плане
                </Button>
              )}
            </>
          }
          icon={<AddNodeIcon />}
        />
      ),
      needFlats: (
        <ErrorPlaceholder
          text={
            <>
              <span>Нет элементов для настройки</span>
              {permissions.edit && (
                <Button onClick={() => onChangeTab(ESystemTabsIds.distributionFlats)} type={ButtonType.outline}>
                  Добавить квартиры
                </Button>
              )}
            </>
          }
          icon={<AddNodeIcon />}
        />
      ),
      needUpLvl: (
        <ErrorPlaceholder
          iconContainerClassName="access-group-table__icon-without-fill"
          text={
            <>
              <span>Для отображения групп доступа</span>
              <span>сначала настройте доступ на уровне выше</span>
            </>
          }
          icon={<CheckListIcon />}
        />
      ),
      default: <div />,
      search: <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />,
    }),
    [permissions, onChangeTab]
  );

  const currentError = useMemo(() => {
    let error = null;

    if (isSearch) {
      error = EErrorType.search;
    } else if (selectedNode) {
      if (!data?.headers.length) {
        if (selectedNode.itemType === TreeNodeType.object) {
          error = EErrorType.needPlan;
        } else {
          error = EErrorType.needUpLvl;
        }
      } else if (!data?.rows.items.length) {
        if (selectedNode.itemType === TreeNodeType.flat) {
          error = EErrorType.needKeys;
        } else if (selectedNode.itemType === TreeNodeType.section) {
          error = EErrorType.needFlats;
        } else {
          error = EErrorType.needPlan;
        }
      }
    } else {
      error = EErrorType.default;
    }
    return error;
  }, [isSearch, data, selectedNode]);

  const dataSource = useMemo(
    () =>
      columns.length > 1 && data
        ? data.rows.items.map((item, index: number) => ({
            ...item,
            key: `${item.id}-${index}`,
          }))
        : [],
    [data, columns]
  );

  return (
    <div ref={tableRef} className="editable-table access-group-table">
      <div
        ref={chevronLeftRef}
        role="presentation"
        onClick={scrollTo(false)}
        className="tabs__tabs-button tabs__tabs-button_left tabs__tabs-button_hide"
      >
        <ChevronLeft />
      </div>
      <div
        ref={chevronRightRef}
        role="presentation"
        onClick={scrollTo()}
        className="tabs__tabs-button tabs__tabs-button_right tabs__tabs-button_hide"
      >
        <ChevronRight />
      </div>
      <Table
        className="default-scrollbar-override"
        columns={
          data?.headers.length && data?.rows.items.length
            ? columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))
            : []
        }
        dataSource={dataSource}
        loading={{
          spinning: loading || loadingRequest,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        locale={{
          emptyText: <div className="access-group-table__error">{currentError && errorsData[currentError]}</div>,
        }}
        pagination={
          !currentError && {
            current: (data?.rows.page || currentPage) + 1,
            pageSize: data?.rows.pageSize || pageSize,
            hideOnSinglePage: true,
            total: data?.rows.totalCount || total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }
        }
      />
    </div>
  );
};

export default AccessGroupsTable;
