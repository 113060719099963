import React, { FC } from 'react';

const ErrorIcon: FC = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.5">
      <path
        d="M41.9998 21.9998C41.9998 10.9722 33.0285 2 22 2C10.9716 2 2.00024 10.9722 2.00024 21.9998C2.00024 33.0273 10.9716 41.9996 22 41.9996C33.0285 41.9996 41.9998 33.0273 41.9998 21.9998ZM22 9.20617C29.0546 9.20617 34.7936 14.9455 34.7936 21.9998C34.7936 29.0541 29.0543 34.7934 22 34.7934C14.9457 34.7934 9.20641 29.0541 9.20641 21.9998C9.20641 14.9455 14.9457 9.20617 22 9.20617ZM22.5002 40.9871V35.7813C29.7085 35.5225 35.5223 29.7085 35.7813 22.5002H40.9874C40.7247 32.5787 32.5789 40.725 22.5001 40.9866L22.5002 40.9871ZM40.9866 21.4998H35.7808C35.522 14.2915 29.708 8.47761 22.4997 8.21868V3.01294C32.5782 3.27521 40.7245 11.4211 40.9861 21.4993L40.9866 21.4998ZM21.4993 3.01338V8.21913C14.291 8.47791 8.4771 14.2919 8.21818 21.5002H3.01243C3.2747 11.4218 11.4206 3.27543 21.4988 3.01383L21.4993 3.01338ZM3.01288 22.5007H8.21862C8.47741 29.709 14.2914 35.5228 21.4997 35.7818V40.9879C11.4213 40.7253 3.27493 32.5794 3.01332 22.5006L3.01288 22.5007Z"
        fill="#F00000"
        stroke="#F00000"
      />
      <path d="M16 17L28 27.5" stroke="#F00000" strokeWidth="2" strokeLinecap="round" />
      <path d="M28 17L16 27.5" stroke="#F00000" strokeWidth="2" strokeLinecap="round" />
    </g>
  </svg>
);

export default ErrorIcon;
