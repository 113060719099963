import React, { FC } from 'react';

const EditIcon: FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7474 20.9428H21" stroke="#949494" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.78 4.29479C13.5557 3.36779 14.95 3.23186 15.8962 3.99173C15.9485 4.03296 17.6295 5.33879 17.6295 5.33879C18.669 5.96719 18.992 7.30311 18.3494 8.32259C18.3153 8.37718 8.81195 20.2645 8.81195 20.2645C8.49578 20.6589 8.01583 20.8918 7.50291 20.8973L3.86353 20.943L3.04353 17.4723C2.92866 16.9843 3.04353 16.4718 3.3597 16.0773L12.78 4.29479Z"
      stroke="#949494"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.0208 6.50098L16.4731 10.6881"
      stroke="#949494"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
