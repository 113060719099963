import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

import sidebarReducer from './slices/sidebar';
import headerReducer from './slices/header';
import profileReducer from './slices/profile';
import layoutReducer from './slices/layout';
import changesReducer from './slices/changes';
import notificationsReducer from './slices/notifications';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    header: headerReducer,
    sidebar: sidebarReducer,
    profile: profileReducer,
    layout: layoutReducer,
    changes: changesReducer,
    notifications: notificationsReducer,
  }),
  middleware: [routerMiddleware],
});

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
