export enum ECheckboxStatus {
  uncheck = 'Uncheck',
  check = 'Check',
  partially = 'Partially',
}
export interface IAccessGroupHeader {
  accessPointId: string;
  cameraId: string;
  name: string;
  place: string[];
  shortName: string;
  check: ECheckboxStatus;
}

export interface IAccessGroupItem {
  accessPointId: string;
  cameraId: string;
  isEnabled: boolean;
  itemId: string;
}

export interface IAccessGroupRow {
  accessPointGroups: IAccessGroupItem[];
  id: string;
  name: string;
  objectItemTypes: string;
  isEdit: boolean;
  keyValue?: string;
  keyAlias?: string;
}

export interface IAccessGroupRowData {
  page: number;
  pageSize: number;
  totalCount: number;
  items: IAccessGroupRow[];
}

export interface IAccessGroup {
  headers: IAccessGroupHeader[];
  rows: IAccessGroupRowData;
}
