import React, { FC } from 'react';

const ShowIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1079 8.03573C10.1079 9.19973 9.16391 10.1431 7.99991 10.1431C6.83591 10.1431 5.89258 9.19973 5.89258 8.03573C5.89258 6.87107 6.83591 5.92773 7.99991 5.92773C9.16391 5.92773 10.1079 6.87107 10.1079 8.03573Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99883 12.9035C10.5375 12.9035 12.8595 11.0781 14.1668 8.03548C12.8595 4.99281 10.5375 3.16748 7.99883 3.16748H8.0015C5.46283 3.16748 3.14083 4.99281 1.8335 8.03548C3.14083 11.0781 5.46283 12.9035 8.0015 12.9035H7.99883Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShowIcon;
