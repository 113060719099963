import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IButtonLink } from './types';

const ButtonLink: FC<IButtonLink> = (props) => {
  const {
    withCallback = false,
    onClick = () => {},
    url = '',
    content = null,
    leftIcon = null,
    rightIcon = null,
    wrapperClassName = '',
    iconClassName = '',
    contentClassName = '',
  } = props;

  const data = useMemo(
    () => (
      <>
        {leftIcon && <div className={classNames('button-link__icon', iconClassName)}>{leftIcon}</div>}
        {content && <div className={classNames('button-link__content', contentClassName)}>{content}</div>}
        {rightIcon && <div className={classNames('button-link__icon', iconClassName)}>{rightIcon}</div>}
      </>
    ),
    [content, contentClassName, iconClassName, leftIcon, rightIcon]
  );

  return withCallback ? (
    <div className="button-link" role="presentation" onClick={onClick}>
      {data}
    </div>
  ) : (
    <Link to={url} className={classNames('button-link', wrapperClassName)}>
      {data}
    </Link>
  );
};

export default ButtonLink;
