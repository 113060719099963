export interface ISubscriberApartment {
  flatId?: string;
  objectId?: string;
  objectName?: string;
  address?: string;
  number?: number;
  status?: string;
  isMain?: boolean;
  buildingId?: string;
  buildingAddress?: string;
  buildingName?: string;
  sipNumber?: string;
  fromDate?: string | null;
  toDate?: string | null;
  isConfirm?: boolean;
  isDisabled?: boolean;
  isError?: boolean;
}

export interface IMainFlatSubscriberData {
  isSuccess: boolean;
  message?: string;
  data?: {
    addressing: string;
    commentary: string;
    createDate: string;
    creator: string;
    email: string;
    firstName: string;
    hasActiveApartment: boolean;
    id: string;
    lastName: string;
    middleName: string;
    phone: string;
    subscriberObjects: [];
    updateDate: string;
    updater: string;
  };
}

export interface ISubscriberObject {
  objectId?: string;
  name?: string;
  flats?: ISubscriberApartment[];
}

export interface ISubscriberSubscription {
  flatId: string;
  nextPaymentDate: string;
  subscriberId: string;
  subscriptionId: string;
  tariff: string;
}

export interface ISubscriber {
  id?: string;
  addressing?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  email?: string;
  commentary?: string;
  creator?: string;
  updater?: string;
  createDate?: string;
  updateDate?: string;
  hasActiveApartment?: boolean;
  subscriberObjects?: ISubscriberObject[];
  subscriberApartments?: ISubscriberApartment[];
  isDeleted?: boolean;
  companyName?: string;
  hasEmailNotificationConsent?: boolean;
  hasPushNotificationConsent?: boolean;
  hasSmsNotificationConsent?: boolean;
}

export interface ISubscriberBuilding {
  buildingId?: string;
  address?: string;
}

export interface ISubscribersErrorValidate {
  id: number;
  isStatusEmpty: boolean;
  isStatusValid: boolean;
  isFirstNameEmpty: boolean;
  isFirstNameLengthValid: boolean;
  isLastNameEmpty: boolean;
  isLastNameLengthValid: boolean;
  isMiddleNameLengthValid: boolean;
  isPhoneEmpty: boolean;
  isPhoneLengthValid: boolean;
  isPhoneValid: boolean;
  isEmailEmpty: boolean;
  isEmailLengthValid: boolean;
  isEmailValid: boolean;
  isObjectEmpty: boolean;
  isObjectLengthValid: boolean;
  isObjectValid: boolean;
  isAddressEmpty: boolean;
  isAddressValid: boolean;
  isFlatNumberEmpty: boolean;
  isFlatNumberValid: boolean;
}

export interface ISubscribersErrorSub {
  rowId: number;
  firstName: string;
  lastName: string;
  middleName: string;
  status: string;
  phone: string;
  email: string;
  object: string;
  address: string;
  flatNumber: number;
  validationResult?: ISubscribersErrorValidate;
}

export interface ISubscribersErrorData {
  importRowCount: number;
  dublicateRowCount: number;
  errorRowCount: number;
  validationResult: ISubscribersErrorValidate[];
  subscribers: ISubscribersErrorSub[];
}

export enum SubscriberErrorCodes {
  validation = 'Validation',
  isMainFlatValidation = 'IsMainFlatValidation',
  isMainFlatDeleteValidation = 'IsMainFlatDeleteValidation',
  cancelPaidSubscription = 'CancelPaidSubscription',
  unknown = 'Unknown',
  isMainFlatChangeNotConfirmed = 'IsMainFlatChangeNotConfirmed',
  isStatusValidation = 'IsStatusValidation',
  isMainStatusValidation = 'IsMainStatusValidation',
}

export interface IMainFlatChangeNotConfirmed {
  flatId: string;
  address: string;
  flatNumber: string;
  subscriber: ISubscriber;
  message: string;
}
