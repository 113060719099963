import React, { FC } from 'react';
import { IFeedbackStatus } from './types';
import { mapFeedbackStatusToColor, mapFeedbackStatusToText } from '../../../typings/feedback';

const FeedbackStatus: FC<IFeedbackStatus> = (props) => {
  const { status, wrapClassName = '' } = props;

  return status ? (
    <div className={`feedback-status feedback-status_${mapFeedbackStatusToColor.get(status)} ${wrapClassName}`}>
      {mapFeedbackStatusToText.get(status)}
    </div>
  ) : null;
};

export default FeedbackStatus;
