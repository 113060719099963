import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IAddAppealModal, IAppearData, defaultAppearData } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Input from '../../ui/input';
import { useApi } from '../../../hooks/useApi';
import { getRequest, postRequest } from '../../../api';
import { EFieldTypes, IInputValue, InputStatus } from '../../ui/input/types';
import Select from '../../ui/select';
import { IApiParams, IApiResponse } from '../../../typings/api';
import { ISystem } from '../../../typings/systems/system';
import { getSystem } from '../../../api/systems';
import {
  feedbackMediaUrl,
  feedbackSubjectsListUrl,
  feedbackUrl,
  getFeedbacksCategoryListUrl,
  getObjectSystemsListUrl,
  getSubscriberBuildingsUrl,
  getSubscriberFlatsUrl,
  getSubscribersInObjectUrl,
} from '../../../constants/api';
import { ISelectOption } from '../../ui/select/types';
import { emptyFieldErrorText } from '../../systemTabs/hardwareSettingsTab/hardware/device/advancedOptions/types';
import {
  EFeedbackStatus,
  IFeedbackCategory,
  IFeedbackSubject,
  mapFeedbackPriorityToText,
} from '../../../typings/feedback';
import { ISubscriber, ISubscriberApartment, ISubscriberBuilding } from '../../../typings/subscribers';
import { getEntitiesForSubscriber, getSubscribers } from '../../../api/subscribers';
import FileUploader from '../../ui/fileUploader';
import Scrollbar from '../../ui/scrollbar';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import { videoAndImgTypes } from '../../../constants/fileTypes';

const limit = 5242880;

const AddAppealModal: FC<IAddAppealModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  const { sendRequest: createAppear, loading: createAppearLoading } = useApi(postRequest);

  const { sendRequest: addMediaInAppear, loading: addMediaInAppearLoading } = useApi(postRequest);

  const {
    data: buildings,
    sendRequest: sendRequestBuildings,
    loading: buildingsLoading,
  } = useApi<IApiResponse<ISubscriberBuilding>>(getEntitiesForSubscriber);

  const {
    data: flatsNumbers,
    sendRequest: sendRequestFlatsNumbers,
    loading: flatsNumbersLoading,
  } = useApi<IApiResponse<ISubscriberApartment>>(getEntitiesForSubscriber);
  const [flatNumbersApiParams, setFlatNumbersApiParams] = useState<
    { buildingId: string } & IApiParams<ISubscriberApartment>
  >({
    sort: [{ name: 'number' }],
    search: '',
    page: 0,
    count: 0,
    buildingId: '',
  });

  const [buildingApiParams, setBuildingApiParams] = useState<{ objectId: string } & IApiParams<ISubscriberBuilding>>({
    sort: [{ name: 'address' }],
    search: '',
    page: 0,
    count: 0,
    objectId: '',
  });

  const { data: subjects, sendRequest: getSubjects } = useApi<IFeedbackSubject[]>(getRequest);

  const [appearData, setAppearData] = useState<IAppearData>({ ...defaultAppearData });

  const [subscriberSearch, setSubscriberSearch] = useState('');
  const [addressSearch, setAddressSearch] = useState('');

  const [imgList, setImgList] = useState<File[] | null>([]);

  const [buildingSearch, setBuildingSearch] = useState<string>('');
  const [flatSearch, setFlatSearch] = useState<string>('');

  const [buildingId, setBuildingId] = useState<string>('');

  const {
    data: systems,
    sendRequest: sendSystemRequest,
    loading: systemsLoading,
  } = useApi<IApiResponse<ISystem>>(getSystem);

  const {
    data: categories,
    sendRequest: sendCategoriesRequest,
    loading: categoryLoading,
  } = useApi<IFeedbackCategory[]>(getRequest);

  const {
    data: subscribers,
    loading: subscribersLoading,
    sendRequest: sendSubscribersRequest,
  } = useApi<IApiResponse<ISubscriber>>(getSubscribers);

  useEffect(() => {
    if (isOpen) {
      sendSystemRequest(getObjectSystemsListUrl(), { params: { count: 0, isOnlyIpSeries: true } });
      sendCategoriesRequest(getFeedbacksCategoryListUrl());
      setAppearData({ ...defaultAppearData });
      setImgList([]);
    }
  }, [isOpen]);

  const requestBuildings = useCallback(
    async (objectId: string) => {
      const newBuildingApiParams = { ...buildingApiParams, objectId: objectId };
      await sendRequestBuildings(getSubscriberBuildingsUrl(), { params: newBuildingApiParams });
      setBuildingApiParams(newBuildingApiParams);
    },
    [buildingApiParams, sendRequestBuildings]
  );

  const requestFlatsNumbers = useCallback(
    async (id: string) => {
      const updatedFlatNumbersApiParams = { ...flatNumbersApiParams, buildingId: id };
      await sendRequestFlatsNumbers(getSubscriberFlatsUrl(), { params: updatedFlatNumbersApiParams });
      setFlatNumbersApiParams(updatedFlatNumbersApiParams);
    },
    [flatNumbersApiParams, sendRequestFlatsNumbers]
  );

  const requestSubscribers = useCallback(
    async (objectId: string, searchValue: string) => {
      await sendSubscribersRequest(getSubscribersInObjectUrl(objectId), {
        search: searchValue,
        filters: [],
        sort: '',
        sortFields: [],
        page: 0,
        count: 0,
        pageSize: -1,
      });
    },
    [sendSubscribersRequest]
  );

  const requestSubjects = useCallback(
    async (categoryId: string) => {
      await getSubjects(feedbackSubjectsListUrl(categoryId));
    },
    [getSubjects]
  );

  useEffect(() => {
    if (appearData.objectId.value) {
      requestSubscribers(appearData.objectId.value, subscriberSearch);
      setBuildingId('');
    }
  }, [appearData.objectId.value]);

  useEffect(() => {
    if (appearData.category.value) {
      requestSubjects(appearData.category.value);
    }
  }, [appearData.category.value]);

  const updateInvalidField = useCallback((data: IAppearData, name: string, input: IInputValue) => {
    data[name as keyof IAppearData] = {
      ...input,
      status: InputStatus.error,
      errorText: emptyFieldErrorText,
    };
    return false;
  }, []);

  const validateAppearData = useCallback(() => {
    let isValid = true;
    const appearDataCopy = { ...appearData };

    Object.keys(appearDataCopy).forEach((name) => {
      const input = appearDataCopy[name as keyof IAppearData];
      if (input && input.isRequired && (!input.value || !input.value.trim())) {
        isValid = updateInvalidField(appearDataCopy, name, input);
      }
    });

    if (
      appearData.subscriber.value ? !appearDataCopy.address.value || !appearDataCopy.address.value.trim() : !buildingId
    ) {
      isValid = updateInvalidField(appearDataCopy, 'address', appearDataCopy.address);
    }

    setAppearData(appearDataCopy);
    return isValid;
  }, [appearData, buildingId, updateInvalidField]);

  const onAccess = useCallback(async () => {
    if (validateAppearData()) {
      let addressNotes = null;
      if (!appearData.subscriber.value) {
        const building = buildings?.items.find((item) => item.buildingId === buildingId);
        const flat = flatsNumbers?.items.find((item) => item.flatId === appearData.address.value);
        addressNotes = `${building?.address}${flat?.number ? ` , кв.${flat?.number}` : ''}`;
      }
      const res = await createAppear(feedbackUrl(), {
        objectId: appearData.objectId.value,
        subscriberId: appearData.subscriber.value || null,
        flatId: appearData.address.value || null,
        addressNotes: addressNotes,
        priority: appearData.priority.value,
        categoryId: appearData.category.value,
        subjectId: appearData.subject.value,
        status: EFeedbackStatus.new,
        parts: [
          {
            text: appearData.message.value,
          },
        ],
      });
      if (!res?.response?.data) {
        if (imgList?.length) {
          const data = new FormData();
          imgList?.forEach((item, index) => data.append(`file${index + 1}`, item));
          await addMediaInAppear(feedbackMediaUrl(res), data);
        }

        onOk();
      }
    }
  }, [
    addMediaInAppear,
    appearData,
    buildingId,
    buildings?.items,
    createAppear,
    flatsNumbers?.items,
    imgList,
    onOk,
    validateAppearData,
  ]);

  const onChange = useCallback(
    (fieldName: string) => (value: string | number) => {
      const appearDataCopy = { ...appearData };

      const fieldData = appearDataCopy[fieldName as keyof IAppearData];

      if (fieldData) {
        appearDataCopy[fieldName as keyof IAppearData] = {
          ...fieldData,
          value: value.toString(),
          errorText: '',
          status: InputStatus.normal,
        };
        if (fieldName === 'objectId') {
          appearDataCopy.address.value = '';
          appearDataCopy.subscriber.value = '';
        }
        if (fieldName === 'subscriber') {
          appearDataCopy.address.value = '';
        }
        if (fieldName === 'category') {
          appearData.subject.value = '';
        }
      }

      setAppearData(appearDataCopy);
    },
    [appearData]
  );

  const onSearchSubscriber = useCallback(
    (searchValue: string) => {
      setSubscriberSearch(searchValue);
      requestSubscribers(appearData.objectId.value, searchValue);
    },
    [appearData.objectId.value, requestSubscribers]
  );

  const onSearchAddress = useCallback((searchValue: string) => {
    setAddressSearch(searchValue);
  }, []);

  const handleOnSearchBuilding = useCallback((value: string) => {
    setBuildingSearch(value);
  }, []);

  const handleOnChangeBuilding = useCallback(
    async (id: string | number) => {
      setBuildingSearch('');
      const newBuildingId = id.toString();
      await requestFlatsNumbers(newBuildingId);
      setBuildingId(newBuildingId);
    },
    [requestFlatsNumbers]
  );

  const handleOnSearchFlatsNumbers = useCallback((value: string) => {
    setFlatSearch(value);
  }, []);

  const onAddressClick = useCallback(() => {
    requestBuildings(appearData.objectId.value || '');
  }, [appearData.objectId.value, requestBuildings]);

  const onNumberClick = useCallback(() => {
    if (!flatsNumbers) {
      requestFlatsNumbers(appearData.objectId.value || '');
    }
  }, [appearData.objectId.value, flatsNumbers, requestFlatsNumbers]);

  const optionsInToMap = useCallback((map: Map<any, string>) => {
    const result: ISelectOption[] = [];
    map.forEach((item, key) =>
      result.push({
        value: key,
        title: item,
      })
    );
    return result;
  }, []);

  const priorityOptions = useMemo(() => optionsInToMap(mapFeedbackPriorityToText), [optionsInToMap]);

  const objectSelectItems = useMemo(
    () =>
      systems?.items?.map<ISelectOption>((system) => ({
        value: system.id || '',
        title: system.shortName || system.objectName || '',
      })),
    [systems?.items]
  );

  const categorySelectItems = useMemo(
    () =>
      categories?.map<ISelectOption>((item) => ({
        value: item.id,
        title: item.name,
      })),
    [categories]
  );

  const subjectSelectItems = useMemo(
    () =>
      subjects?.map<ISelectOption>((item) => ({
        value: item.id,
        title: item.name,
      })),
    [subjects]
  );

  const subscribersSelectItems = useMemo(
    () =>
      subscribers?.items?.map<ISelectOption>((item) => ({
        value: item.id || '',
        title: `${item.middleName} ${item.firstName} ${item.lastName}`,
      })),
    [subscribers?.items]
  );

  const addressSelectItems = useMemo(
    () =>
      appearData.subscriber.value
        ? subscribers?.items
            ?.find((item) => item.id === appearData.subscriber.value)
            ?.subscriberObjects?.find((item) => item.objectId === appearData.objectId.value)
            ?.flats?.map<ISelectOption>((item) => ({
              value: item.flatId || '',
              title: `${item.buildingAddress} ${item.number}`,
            }))
        : [],
    [appearData.objectId.value, appearData.subscriber.value, subscribers?.items]
  );

  const addressOptions = useMemo(
    () =>
      buildings?.items
        ?.filter((building) => building.address?.toLowerCase().includes(buildingSearch.toLowerCase()))
        .map<ISelectOption>((building) => ({
          value: building.buildingId || '',
          title: building.address || '',
        })),
    [buildingSearch, buildings]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Создание обращения"
      width={644}
      showCloseIcon
      wrapClassName="add-appeal-modal__wrapper"
      centered
      footer={
        <div className="add-appeal-modal__button-container">
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onCancel}
            disabled={createAppearLoading || addMediaInAppearLoading}
          >
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onAccess} disabled={createAppearLoading || addMediaInAppearLoading}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Scrollbar>
        <div className="add-appeal-modal__row">
          <Select
            showClear
            loading={systemsLoading}
            title="Объект"
            value={appearData.objectId.value}
            onChange={onChange('objectId')}
            options={objectSelectItems}
            isRequired={appearData.objectId.isRequired}
            isError={appearData.objectId.status === InputStatus.error}
            errorText={appearData.objectId.errorText}
          />
          <Select
            showClear
            title="Приоритет"
            value={appearData.priority.value}
            onChange={onChange('priority')}
            options={priorityOptions}
            isRequired={appearData.priority.isRequired}
            isError={appearData.priority.status === InputStatus.error}
            errorText={appearData.priority.errorText}
          />
        </div>
        <div className="add-appeal-modal__row">
          <Select
            showClear
            loading={categoryLoading}
            title="Категория"
            value={appearData.category.value}
            onChange={onChange('category')}
            options={categorySelectItems}
            isRequired={appearData.category.isRequired}
            isError={appearData.category.status === InputStatus.error}
            errorText={appearData.category.errorText}
          />
          <Select
            showClear
            title="Тема обращения"
            value={appearData.subject.value}
            onChange={onChange('subject')}
            disabled={!appearData.category.value}
            options={subjectSelectItems}
            isRequired={appearData.subject.isRequired}
            isError={appearData.subject.status === InputStatus.error}
            errorText={appearData.subject.errorText}
          />
        </div>
        <div className="add-appeal-modal__row">
          <Select
            searchWithDelay
            showClear
            searchValue={subscriberSearch}
            onSearch={onSearchSubscriber}
            showSearch
            loading={subscribersLoading}
            disabled={!appearData.objectId.value}
            title="Абонент"
            value={appearData.subscriber.value}
            onChange={onChange('subscriber')}
            options={subscribersSelectItems}
            isRequired={appearData.subscriber.isRequired}
            isError={appearData.subscriber.status === InputStatus.error}
            errorText={appearData.subscriber.errorText}
          />
        </div>
        <div className="add-appeal-modal__row">
          {appearData.subscriber.value ? (
            <Select
              showClear
              showSearch={!!appearData.subscriber.value}
              searchValue={addressSearch}
              onSearch={onSearchAddress}
              title="Адрес"
              placeholder="Выберите адрес"
              value={appearData.address.value}
              onChange={onChange('address')}
              options={addressSelectItems}
              isRequired
              isError={appearData.address.status === InputStatus.error}
              errorText={appearData.address.errorText}
            />
          ) : (
            <>
              <Select
                disabled={!appearData.objectId.value}
                title="Адрес"
                showArrow={false}
                showSearch
                loading={buildingsLoading}
                onSearch={handleOnSearchBuilding}
                searchValue={buildingSearch}
                placeholder="Адрес строения"
                value={buildingId}
                onChange={handleOnChangeBuilding}
                lengthLimit={false}
                containerClassName="add-appeal-modal__building-select"
                onClick={onAddressClick}
                options={addressOptions}
                isDisabledStyle
                isRequired
                isError={appearData.address.status === InputStatus.error}
                errorText={appearData.address.errorText}
              />
              <Select
                disabled={!appearData.objectId.value}
                title="Квартира"
                showArrow={false}
                placeholder="номер"
                showSearch
                onSearch={handleOnSearchFlatsNumbers}
                searchValue={flatSearch}
                value={appearData.address.value}
                loading={flatsNumbersLoading}
                onClick={onNumberClick}
                onChange={onChange('address')}
                containerClassName="add-appeal-modal__flat-select"
                options={flatsNumbers?.items
                  ?.filter((currFlat) => currFlat.number?.toString().toLowerCase().includes(flatSearch.toLowerCase()))
                  .map<ISelectOption>((currFlat) => ({
                    value: currFlat.flatId || '',
                    title: currFlat.number?.toString() || '',
                  }))}
                isDisabledStyle
                lengthLimit={false}
                notFoundContent={
                  <ErrorPlaceholder
                    text="По вашему запросу ничего не найдено"
                    containerClassName="subscriber-object-address__not-found"
                    textContainerClassName="subscriber-object-address__not-found-text"
                  />
                }
              />
            </>
          )}
        </div>
        <div className="add-appeal-modal__row">
          <Input
            title="Текст обращения"
            value={appearData.message.value}
            onChange={onChange('message')}
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={500}
            fieldType={EFieldTypes.textArea}
            isRequired={appearData.message.isRequired}
            status={appearData.message.status}
            errorText={appearData.message.errorText}
          />
        </div>
        <div className="add-appeal-modal__row">
          <FileUploader
            quantityLimit={5}
            limit={limit}
            isSingleFile={false}
            acceptFileTypes={videoAndImgTypes}
            data={imgList}
            setImgs={setImgList}
          />
        </div>
      </Scrollbar>
    </Modal>
  );
};

export default AddAppealModal;
