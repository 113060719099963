import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { ICategoryModalModal } from './types';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { useApi } from '../../../../hooks/useApi';
import { postRequest } from '../../../../api';
import { feedbackCategoryBaseUrl } from '../../../../constants/api';
import { InputStatus } from '../../../ui/input/types';

const CategoryModal: FC<ICategoryModalModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, editItem = null } = props;

  const { sendRequest: sendCategory, loading: sendCategoryLoading } = useApi(postRequest);

  const [name, setName] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const onAccess = useCallback(async () => {
    const resError = (await sendCategory(feedbackCategoryBaseUrl(), { name, id: editItem?.id })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    } else {
      setErrorMessage('Категория с таким названием уже существует');
    }
  }, [editItem?.id, name, onOk, sendCategory]);

  useEffect(() => {
    setErrorMessage('');
  }, [name]);

  useEffect(() => {
    if (editItem && isOpen) {
      setName(editItem.name);
    } else {
      setName('');
      setErrorMessage('');
    }
  }, [editItem, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={!editItem ? 'Создание новой категории' : 'Редактирование категории'}
      width={410}
      showCloseIcon
      wrapClassName="faq-section-modal__wrapper"
      centered
      footer={
        <div className="faq-section-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel} disabled={sendCategoryLoading}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onAccess} disabled={sendCategoryLoading || !name || !name.trim()}>
            {!editItem ? 'Сохранить' : 'Редактировать'}
          </Button>
        </div>
      }
    >
      <Input
        title="Название категории"
        isRequired
        errorText={errorMessage}
        value={name}
        onChange={setName}
        status={errorMessage ? InputStatus.error : InputStatus.normal}
        placeholder="Название категории"
        maxLength={40}
      />
    </Modal>
  );
};

export default CategoryModal;
