import { IApiSortField } from '../../../../typings/api';
import { IProfilePermission } from '../../../../typings/profile';
import { ICallCode } from '../../../../typings/systems/callCode';

export interface ICallCodesTable {
  isFiveThousandth?: boolean;
  callCodes?: ICallCode[];
  loading?: boolean;
  pageSize?: number;
  total?: number;
  onChangePage?: (page: number, pageSize: number) => void;
  currentPage?: number;
  systemId?: string;
  onEditRow?: () => {};
  onSort?: (sortResults: IApiSortField<ICallCode>[]) => void;
  sortOrders?: IApiSortField<ICallCode>[];
  isSearch?: boolean;
  permissions?: IProfilePermission;
}

export enum CallCodesTableEditableColumns {
  inBuildingCallCode = 'inBuildingCallCode',
  outOfBuildingCallCode = 'outOfBuildingCallCode',
}
