import { IApartmentApiParams, IApartmentApiSettings } from '../../../api/apartments/types';
import { IFlatsPageFlat } from '../../../typings/flat';

export interface IFlatPageFilters {
  setFlatsArr?: (arr: IFlatsPageFlat[]) => void;
  setEventsApiParams?: (data: IApartmentApiParams) => void;
  apiSettings?: IApartmentApiSettings<IFlatsPageFlat>;
  eventsApiParams?: IApartmentApiParams;
  requestData?: (settings?: IApartmentApiSettings<IFlatsPageFlat>, events?: IApartmentApiParams) => void;
  isAdmin?: boolean | null;
}

export enum EFlatFilters {
  companyId = 'companyId',
  objectId = 'objectId',
  buildingId = 'buildingId',
  sectionId = 'sectionId',
  payStatus = 'payStatus',
  search = 'search',
}
