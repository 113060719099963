import React, { FC } from 'react';

const ShapeRectangleE: FC = () => (
  <svg width="147" height="58" viewBox="0 0 147 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M62.0526 55H62.5526H62.0526ZM62.0526 27H61.5526H62.0526ZM25.8947 0.5H3V1.5H25.8947V0.5ZM60.0526 0.5H25.8947V1.5H60.0526V0.5ZM64.0526 0.5H60.0526V1.5H64.0526V0.5ZM82.9474 0.5H64.0526V1.5H82.9474V0.5ZM86.9474 0.5H82.9474V1.5H86.9474V0.5ZM86.9474 1.5H121.105V0.5H86.9474V1.5ZM121.105 1.5H144V0.5H121.105V1.5ZM144 1.5C144.828 1.5 145.5 2.17157 145.5 3H146.5C146.5 1.61929 145.381 0.5 144 0.5V1.5ZM145.5 3V55H146.5V3H145.5ZM145.5 55C145.5 55.8284 144.828 56.5 144 56.5V57.5C145.381 57.5 146.5 56.3807 146.5 55H145.5ZM144 56.5H125.105V57.5H144V56.5ZM125.105 56.5C124.277 56.5 123.605 55.8284 123.605 55H122.605C122.605 56.3807 123.725 57.5 125.105 57.5V56.5ZM123.605 55V27H122.605V55H123.605ZM123.605 27C123.605 25.6193 122.486 24.5 121.105 24.5V25.5C121.934 25.5 122.605 26.1716 122.605 27H123.605ZM121.105 24.5H104.026V25.5H121.105V24.5ZM104.026 24.5H86.9474V25.5H104.026V24.5ZM85.4474 27C85.4474 26.1716 86.1189 25.5 86.9474 25.5V24.5C85.5667 24.5 84.4474 25.6193 84.4474 27H85.4474ZM85.4474 55V27H84.4474V55H85.4474ZM82.9474 57.5C84.3281 57.5 85.4474 56.3807 85.4474 55H84.4474C84.4474 55.8284 83.7758 56.5 82.9474 56.5V57.5ZM64.0526 57.5H82.9474V56.5H64.0526V57.5ZM61.5526 55C61.5526 56.3807 62.6719 57.5 64.0526 57.5V56.5C63.2242 56.5 62.5526 55.8284 62.5526 55H61.5526ZM61.5526 27L61.5526 55H62.5526L62.5526 27H61.5526ZM60.0526 25.5C60.8811 25.5 61.5526 26.1716 61.5526 27H62.5526C62.5526 25.6193 61.4333 24.5 60.0526 24.5V25.5ZM42.9737 25.5H60.0526V24.5H42.9737V25.5ZM25.8947 25.5H42.9737V24.5H25.8947V25.5ZM24.3947 27C24.3947 26.1716 25.0663 25.5 25.8947 25.5V24.5C24.514 24.5 23.3947 25.6193 23.3947 27H24.3947ZM24.3947 55V27H23.3947V55H24.3947ZM21.8947 57.5C23.2754 57.5 24.3947 56.3807 24.3947 55H23.3947C23.3947 55.8284 22.7232 56.5 21.8947 56.5V57.5ZM3 57.5H21.8947V56.5H3V57.5ZM0.5 55C0.5 56.3807 1.61929 57.5 3 57.5V56.5C2.17157 56.5 1.5 55.8284 1.5 55H0.5ZM0.5 3V55H1.5V3H0.5ZM3 0.5C1.61929 0.5 0.5 1.61929 0.5 3H1.5C1.5 2.17157 2.17157 1.5 3 1.5V0.5Z"
      fill="#1444F2"
    />
  </svg>
);

export default ShapeRectangleE;
