import { IHardwareParamArray } from '../../../../../../../typings/systems/hardwareSettings';
import { emptyFieldErrorText } from '../types';
import { IArrayFieldError, IArrayFieldItemError } from './types';

export const getArrayFieldError = (arrayFieldValue: Omit<IHardwareParamArray, 'defaultValue'>): IArrayFieldError => {
  const result: IArrayFieldError = { errorText: '', items: [] };

  if (arrayFieldValue.isRequired && !arrayFieldValue.value) {
    result.errorText = emptyFieldErrorText;
    return result;
  }

  if (arrayFieldValue.value) {
    arrayFieldValue.value?.forEach((item: number | null, index) => {
      const errorData: IArrayFieldItemError = {};

      if (arrayFieldValue.isRequired && !item && item !== 0) {
        errorData.index = index;
        errorData.errorText = 'Заполните или удалите поле';
      }

      if (errorData.errorText) {
        result.items?.push(errorData);
      }
    });
  }

  const { value } = arrayFieldValue;
  if (value) {
    if (parseInt(arrayFieldValue.restricts.restricts.maxCount?.toString(), 10) < value.length) {
      result.errorText = `Максимальное количество значений: ${arrayFieldValue.restricts.restricts.maxCount}`;
    }
  }
  return result;
};
