import React, { FC } from 'react';

const NotifyFillIcon: FC = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.13161 16C7.67161 16 7.22827 15.7887 6.88294 15.404C6.69827 15.198 6.38227 15.1813 6.17694 15.3667C5.97161 15.5507 5.95494 15.8673 6.13961 16.072C6.67627 16.67 7.38361 16.9993 8.13094 17H8.13227C8.88161 17 9.59027 16.6707 10.1289 16.0727C10.3136 15.868 10.2969 15.5513 10.0916 15.3667C9.88694 15.182 9.57094 15.1987 9.38561 15.4033C9.03961 15.7887 8.59361 16 8.13161 16Z"
      fill="#1444F2"
    />
    <path
      d="M13.1188 7.12999C13.1188 4.97466 11.1281 2.66666 8.16481 2.66666C5.20147 2.66666 3.21081 4.97466 3.21081 7.12999C3.21081 8.10823 2.99126 8.58923 2.73397 9.03143L2.62947 9.20732C2.33347 9.69932 1.99814 10.258 2.00013 11.3567C2.23014 14.012 5.04747 14.232 8.16481 14.232C11.2821 14.232 14.0988 14.012 14.3315 11.314C14.3315 10.258 13.9961 9.69932 13.7001 9.20732C13.4008 8.70866 13.1188 8.23866 13.1188 7.12999Z"
      fill="#1444F2"
    />
  </svg>
);

export default NotifyFillIcon;
