import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { IFaqSections } from './types';
import Scrollbar from '../../ui/scrollbar';
import PlusIcon from '../../../assets/svg/icons/plus';
import DragAndDropIcon from '../../../assets/svg/icons/dragAndDrop';
import EditIcon from '../../../assets/svg/icons/edit';
import TrashIcon from '../../../assets/svg/icons/trash';
import FaqSectionModal from './faqSectionModal';
import { useApi } from '../../../hooks/useApi';
import { deleteRequest, getRequest, postRequest } from '../../../api';
import { deleteFAQSectionUrl, getSectionsFAQUrl, sortFAQSectionsUrl } from '../../../constants/api';
import { EFAQAdminTabs, IFAQSection } from '../../../typings/faq';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, deleteModalWithFullName } from '../../ui/universalModal/config';

import Message from '../../message';

const FaqSections: FC<IFaqSections> = ({
  blockDragAndDrop = false,
  isChangeMode = false,
  activeSection = '',
  setActiveSection = () => {},
  isAdmin = false,
  permissions = {},
  activeTab,
  apiSettings = {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [editItem, setEditItem] = useState<IFAQSection | null>(null);

  const { data: sections, sendRequest: getSections } = useApi<IFAQSection[]>(getRequest);

  const [currentSections, setCurrentSections] = useState<IFAQSection[]>([]);

  const { sendRequest: deleteSection } = useApi(deleteRequest);

  const { sendRequest: sortSections } = useApi(postRequest);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const getData = useCallback(() => {
    getSections(getSectionsFAQUrl(), {
      params: {
        search: apiSettings.search,
        isPlatform: isAdmin && activeTab === EFAQAdminTabs.platform,
        status: apiSettings.status,
        companyId: apiSettings.companyId,
        objectId: apiSettings.objectId,
      },
    });
  }, [activeTab, apiSettings, getSections, isAdmin]);

  useEffect(() => {
    getData();
  }, [apiSettings]);

  useEffect(() => {
    if (isAdmin) {
      getData();
    }
  }, [activeTab]);

  useEffect(() => {
    setCurrentSections(sections || []);
    if (sections?.length) {
      if (!sections.find((item) => item.id === activeSection)) {
        setActiveSection(sections[0].id);
      }
    } else {
      setActiveSection('');
    }
  }, [sections]);

  const reorder = useCallback((list: IFAQSection[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      if (sections && currentSections[result.destination.index].isEditable) {
        const newItems = reorder([...currentSections], result.source.index, result.destination.index);
        setCurrentSections(newItems);
        sortSections(
          sortFAQSectionsUrl(),
          newItems.map((item, index) => ({ id: item.id, order: index }))
        );
      }
    },
    [currentSections, reorder, sections, sortSections]
  );

  const onItemClick = useCallback(
    (id: string) => () => {
      if (!isChangeMode) {
        setActiveSection(id);
      }
    },
    [isChangeMode, setActiveSection]
  );

  const onCancel = useCallback(() => {
    setModalOpen(false);
    setEditItem(null);
  }, []);

  const onOk = useCallback(() => {
    onCancel();
    getData();
    Message.success({
      content: 'Новый раздел создан',
    });
  }, [getData, onCancel]);

  const openModal = useCallback(
    (item: IFAQSection | null = null) =>
      () => {
        setModalOpen(true);
        if (item) {
          setEditItem(item);
        }
      },
    []
  );

  const onDeleteSection = useCallback(
    (item: IFAQSection) => () => {
      setConfirmData(
        deleteModalWithFullName(
          'Вы действительно хотите удалить раздел? Все вопросы из раздела будут удалены.',
          async () => {
            closeConfirm();
            await deleteSection(deleteFAQSectionUrl(item.id));
            getData();
          },
          closeConfirm
        )
      );
    },
    [closeConfirm, deleteSection, getData]
  );

  const disableAll = useMemo(
    () => (isAdmin && activeTab === EFAQAdminTabs.all) || isChangeMode,
    [activeTab, isAdmin, isChangeMode]
  );

  const renderSectionContent = useCallback(
    (item: IFAQSection) => (
      <>
        {item.isEditable && permissions.edit && !disableAll && currentSections.length !== 1 && !blockDragAndDrop && (
          <div className="faq-section__item-dad">
            <DragAndDropIcon />
          </div>
        )}

        <div className="faq-section__item-label">{item.name}</div>
        {item.isEditable && !disableAll && (
          <div className="faq-section__item-icons">
            {permissions.edit && (
              <div
                onClick={openModal(item)}
                role="presentation"
                className="faq-section__item-icon faq-section__item-icon_edit"
              >
                <EditIcon />
              </div>
            )}
            {permissions.delete && (
              <div
                onClick={onDeleteSection(item)}
                role="presentation"
                className="faq-section__item-icon faq-section__item-icon_trash"
              >
                <TrashIcon />
              </div>
            )}
          </div>
        )}
      </>
    ),
    [blockDragAndDrop, permissions, currentSections.length, disableAll, onDeleteSection, openModal]
  );

  return (
    <div className={classNames('faq-section', { 'faq-section_short': isChangeMode })}>
      <FaqSectionModal isAdmin={isAdmin} isOpen={modalOpen} editItem={editItem} onCancel={onCancel} onOk={onOk} />

      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <div className="faq-section__title">
        <div className="faq-section__title-label">Разделы</div>
        {!disableAll && permissions.create && (
          <div className="faq-section__title-icon" role="presentation" onClick={openModal(null)}>
            <PlusIcon />
          </div>
        )}
      </div>

      <Scrollbar>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className="faq-section__content" ref={provided.innerRef} {...provided.droppableProps}>
                {currentSections?.map((item, index) =>
                  item.isEditable && !blockDragAndDrop ? (
                    <Draggable
                      isDragDisabled={!item.isEditable || disableAll}
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided1) => (
                        <div
                          role="presentation"
                          onClick={onItemClick(item.id)}
                          className={classNames('faq-section__item', {
                            'faq-section__item_active': item.id === activeSection,
                            'faq-section__item_disabled': isChangeMode && item.id !== activeSection,
                          })}
                          ref={provided1.innerRef}
                          {...provided1.dragHandleProps}
                          {...provided1.draggableProps}
                        >
                          {renderSectionContent(item)}
                        </div>
                      )}
                    </Draggable>
                  ) : (
                    <div
                      key={item.id}
                      role="presentation"
                      onClick={onItemClick(item.id)}
                      className={classNames('faq-section__item', {
                        'faq-section__item_active': item.id === activeSection,
                        'faq-section__item_disabled': isChangeMode && item.id !== activeSection,
                      })}
                    >
                      {renderSectionContent(item)}
                    </div>
                  )
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Scrollbar>
    </div>
  );
};

export default FaqSections;
