import RoleBaseTab from '../../../components/rolesTabs/baseTab';
import RoleUsersTab from '../../../components/rolesTabs/usersTab';
import { ERolesTabsIds } from '../types';

export const rolesTabs = [
  {
    id: ERolesTabsIds.basic,
    position: 0,
    title: 'Основное',
    component: RoleBaseTab,
  },
  {
    id: ERolesTabsIds.users,
    position: 1,
    title: 'Пользователи',
    component: RoleUsersTab,
  },
];
