import React, { FC } from 'react';

const SignOut: FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 4C10 4.27614 10.2239 4.5 10.5 4.5H18.5C19.3284 4.5 20 5.17157 20 6V19C20 19.8284 19.3284 20.5 18.5 20.5H10.5C10.2239 20.5 10 20.7239 10 21C10 21.2761 10.2239 21.5 10.5 21.5H18.5C19.8807 21.5 21 20.3807 21 19V6C21 4.61929 19.8807 3.5 18.5 3.5H10.5C10.2239 3.5 10 3.72386 10 4ZM7.85355 15.6464L4.70711 12.5H15.5C15.7761 12.5 16 12.2761 16 12C16 11.7239 15.7761 11.5 15.5 11.5H4.70711L7.85355 8.35355C8.04882 8.15829 8.04882 7.84171 7.85355 7.64645C7.65829 7.45118 7.34171 7.45118 7.14645 7.64645L3.14645 11.6464C2.95118 11.8417 2.95118 12.1583 3.14645 12.3536L7.14645 16.3536C7.34171 16.5488 7.65829 16.5488 7.85355 16.3536C8.04882 16.1583 8.04882 15.8417 7.85355 15.6464Z"
    />
  </svg>
);

export default SignOut;
