import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { IRolesTable } from './types';
import Scrollbar from '../../ui/scrollbar';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import { paths } from '../../../constants/paths';
import { IRole } from '../../../typings/roles';
import { dateDefaultFormat } from '../../../constants/date';
import TrashIcon from '../../../assets/svg/icons/trash';
import DeleteRoleModal from '../deleteRoleModal';

const RolesTable: FC<IRolesTable> = (props) => {
  const {
    permissions = {},
    roles = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    requestData = () => {},
    currentPage = 0,
    isSearch = false,
    isAdmin = false,
  } = props;

  const navigate = useNavigate();

  const [deleteRole, setDeleteRole] = useState<IRole | null>(null);

  const disabledMessage = useMemo(
    () => <div className="roles-table__message-disabled">недоступно для изменения</div>,
    []
  );

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`${paths.roles}/${id}`);
    },
    [navigate]
  );

  const handleOnDeleteRole = useCallback(
    (role: IRole) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setDeleteRole(role);
    },
    []
  );

  const chancelDeleteRole = useCallback(() => setDeleteRole(null), []);

  const onDeletedRole = useCallback(() => {
    requestData();
  }, [requestData]);

  const columns = useMemo<ColumnsType<IRole>>(
    () =>
      [
        {
          title: 'Роль',
          dataIndex: 'roles',
          key: 'roles',
          render: (_: any, record: IRole) => <div className="editable-table__data-container">{record.name}</div>,
        },
        {
          hidden: !isAdmin,
          title: 'Организация',
          render: (record: IRole) => <div className="editable-table__data-container">{record.companyName}</div>,
        },
        {
          title: 'Дата создания',
          dataIndex: 'createDate',
          key: 'createDate',
          render: (_: any, record: IRole) => (
            <div className="editable-table__data-container">
              {record.createDate ? moment(record.createDate || '').format(dateDefaultFormat) : '-'}
            </div>
          ),
        },
        {
          title: 'Дата изменения',
          dataIndex: 'updateDate',
          key: 'updateDate',
          render: (_: any, record: IRole) => (
            <div className="editable-table__data-container">
              {record.isReadOnly
                ? disabledMessage
                : record.updateDate
                ? moment(record.updateDate || '').format(dateDefaultFormat)
                : '-'}
            </div>
          ),
        },
        {
          title: 'Автор изменения',
          dataIndex: 'updaterName',
          key: 'updaterName',
          render: (_: any, record: IRole) => (
            <div className="editable-table__data-container">
              {record.isReadOnly ? disabledMessage : record.updaterName}
            </div>
          ),
        },
        {
          hidden: !permissions.delete,
          render: (record: IRole) => (
            <div className="editable-table__icon-container">
              {!record.isReadOnly && (
                <div onClick={handleOnDeleteRole(record)} role="presentation" className="editable-table__icon">
                  <TrashIcon />
                </div>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [isAdmin, permissions.delete, disabledMessage, handleOnDeleteRole]
  );

  const data = useMemo(
    () =>
      roles.map((item, index) => ({
        ...item,
        key: index,
      })),
    [roles]
  );

  return (
    <div className="roles-table editable-table">
      <DeleteRoleModal
        role={deleteRole}
        isOpen={!!deleteRole}
        onCancel={chancelDeleteRole}
        onDeletedRole={onDeletedRole}
      />
      <Scrollbar>
        <Table
          scroll={{ x: '100vh' }}
          onRow={(record) => ({
            onClick: () => onRowClick(record.id || ''),
          })}
          columns={columns}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default RolesTable;
