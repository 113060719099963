import React, { FC } from 'react';

const SettingsIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8066 7.62386L20.1842 6.54377C19.6576 5.62985 18.4907 5.31456 17.5755 5.83896V5.83896C17.1399 6.09559 16.6201 6.1684 16.1307 6.04133C15.6413 5.91427 15.2226 5.59776 14.9668 5.16162C14.8023 4.88439 14.7139 4.56864 14.7105 4.24628V4.24628C14.7254 3.72947 14.5304 3.22865 14.17 2.85791C13.8096 2.48718 13.3145 2.27811 12.7975 2.27832H11.5435C11.0369 2.27831 10.5513 2.48016 10.194 2.83919C9.83666 3.19822 9.63714 3.68484 9.63958 4.19136V4.19136C9.62457 5.23717 8.77245 6.07706 7.72654 6.07695C7.40418 6.0736 7.08843 5.98519 6.8112 5.82065V5.82065C5.89603 5.29626 4.72908 5.61154 4.20251 6.52546L3.53432 7.62386C3.00838 8.53664 3.31937 9.70285 4.22997 10.2326V10.2326C4.82187 10.5743 5.1865 11.2058 5.1865 11.8893C5.1865 12.5728 4.82187 13.2043 4.22997 13.5461V13.5461C3.32053 14.0722 3.0092 15.2356 3.53432 16.1456V16.1456L4.16589 17.2348C4.41262 17.68 4.82657 18.0085 5.31616 18.1477C5.80575 18.2868 6.33061 18.2251 6.77459 17.9763V17.9763C7.21105 17.7216 7.73116 17.6518 8.21931 17.7824C8.70746 17.9131 9.12321 18.2333 9.37413 18.6719C9.53867 18.9491 9.62708 19.2649 9.63043 19.5872V19.5872C9.63043 20.6438 10.4869 21.5003 11.5435 21.5003H12.7975C13.8505 21.5003 14.7055 20.6494 14.7105 19.5964V19.5964C14.7081 19.0883 14.9088 18.6003 15.2681 18.241C15.6274 17.8817 16.1154 17.6809 16.6236 17.6834C16.9451 17.692 17.2596 17.78 17.5389 17.9396V17.9396C18.4517 18.4656 19.6179 18.1546 20.1476 17.244V17.244L20.8066 16.1456C21.0617 15.7077 21.1317 15.1863 21.0012 14.6966C20.8706 14.207 20.5502 13.7896 20.111 13.5369V13.5369C19.6717 13.2842 19.3514 12.8668 19.2208 12.3772C19.0902 11.8875 19.1602 11.3661 19.4153 10.9282C19.5812 10.6386 19.8213 10.3984 20.111 10.2326V10.2326C21.0161 9.70314 21.3264 8.54374 20.8066 7.63301V7.63301V7.62386Z"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx="12.175" cy="11.8891" r="2.63616" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SettingsIcon;
