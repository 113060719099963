import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseBigIcon from '../../assets/svg/icons/closeBig';
import HamburgerIcon from '../../assets/svg/icons/hamburger';
import EltisLogo from '../../assets/svg/logos/eltisLogo';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { getProfileInStore, getProfilePermission } from '../../store/selectors/profile';
import {
  getClickedSidebarTab,
  getSelectedTabId,
  getSidebarCounts,
  getSidebarIsExpand,
  getTabs,
} from '../../store/selectors/sidebar';
import {
  ISidebarCounts,
  openSidebarItem,
  setClickedSidebarTab,
  setCount,
  setIsExpand,
  setSelectedTabId,
} from '../../store/slices/sidebar';
import { ESidebarItemIds, ISidebarItem } from '../../typings/sidebar';
import { filterEnableSidebarItemsByProfilePermissions, findSelectedSidebarItemIdByPathname } from '../../utils/sidebar';
import { SidebarItem } from './sidebarItem';
import { getRequest } from '../../api';
import { useApi } from '../../hooks/useApi';
import { dispatcherPanelErrorsCountUrl } from '../../constants/api';
import { getWasChange } from '../../store/selectors/changes';
import { setChange } from '../../store/slices/changes';

const Sidebar: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const items = useAppSelector(getTabs);
  const selectedTabId = useAppSelector(getSelectedTabId);
  const isExpanded = useAppSelector(getSidebarIsExpand);
  const profile = useAppSelector(getProfileInStore);
  const countsApp = useAppSelector(getSidebarCounts);

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.dispatcherPanel));

  const { data: countsRequest, sendRequest: getCounts } = useApi<number>(getRequest);

  const wasChange = useAppSelector(getWasChange);

  const navigate = useNavigate();

  useEffect(() => {
    const newSelectedTabId = findSelectedSidebarItemIdByPathname(items, location.pathname);

    if (permissions?.view && newSelectedTabId === ESidebarItemIds.dispatcherPanel) {
      getCounts(dispatcherPanelErrorsCountUrl());
    }
    if (clickedSidebarTab) {
      dispatch(setClickedSidebarTab(null));
    }
    if (wasChange) {
      dispatch(setChange(false));
    }

    dispatch(setSelectedTabId(newSelectedTabId));
    dispatch(openSidebarItem({ itemId: newSelectedTabId, isOpen: true }));
  }, [location]);

  useEffect(() => {
    if (countsRequest) {
      dispatch(setCount({ field: ESidebarItemIds.dispatcherPanel, value: countsRequest }));
    }
  }, [countsRequest]);

  const handleOnClickExpand = useCallback(() => {
    dispatch(setIsExpand(!isExpanded));
  }, [dispatch, isExpanded]);

  const handleOnChangeIsOpen = useCallback(
    (item: ISidebarItem, needExpand = true) => {
      if (needExpand) {
        dispatch(openSidebarItem({ itemId: item.id, isOpen: !isExpanded ? true : !item.isOpen }));
      }
      if (!wasChange) {
        if (item.link) {
          navigate(item.link);
        }
      } else if (item.link) {
        dispatch(setClickedSidebarTab(item.link));
      }
    },
    [dispatch, isExpanded, navigate, wasChange]
  );

  const filteredSidebarItems = useMemo(
    () => filterEnableSidebarItemsByProfilePermissions(items, profile.permissions || []),
    [items, profile.permissions]
  );

  const tabsItems = useMemo(
    () =>
      filteredSidebarItems.map((item: ISidebarItem) => {
        const sidebarItemIsSelected =
          item.id === selectedTabId || Boolean(item.categoryItems?.find((element) => element.id === selectedTabId));
        return (
          <React.Fragment key={`sidebar__tab-${item?.id}`}>
            <SidebarItem
              item={item}
              isSelected={sidebarItemIsSelected}
              isExpanded={isExpanded}
              onClick={() => handleOnChangeIsOpen(item)}
              showArrow={item.categoryItems && item.categoryItems.length > 0}
              isOpen={item.isOpen}
              count={countsApp[item.countField as keyof ISidebarCounts]}
            />
            {item.isOpen &&
              isExpanded &&
              item?.categoryItems?.map((category, index) => (
                <SidebarItem
                  item={category}
                  selectedTabId={selectedTabId}
                  isSelected={sidebarItemIsSelected}
                  isExpanded={isExpanded}
                  onClick={() => handleOnChangeIsOpen(category, false)}
                  key={`sidebar__tab-category-${category?.id}-${index}`}
                  isCategory
                />
              ))}
          </React.Fragment>
        );
      }),
    [countsApp, filteredSidebarItems, handleOnChangeIsOpen, isExpanded, selectedTabId]
  );

  return (
    <div
      className={classNames('sidebar', {
        sidebar_expanded: isExpanded,
      })}
    >
      <div
        className={classNames('sidebar__header', {
          sidebar__header_expanded: isExpanded,
        })}
      >
        <div
          role="presentation"
          onClick={handleOnClickExpand}
          className="sidebar__tab-logo sidebar__tab-logo_fill-white sidebar__expand-button"
        >
          {isExpanded ? <CloseBigIcon /> : <HamburgerIcon />}
        </div>
        {isExpanded && (
          <div className="sidebar__logo">
            <EltisLogo />
          </div>
        )}
      </div>
      <div className="sidebar__tabs-container default-scrollbar-override">{tabsItems}</div>
    </div>
  );
};

export default Sidebar;
