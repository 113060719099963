import { IApiSettings } from '../../typings/api';
import { ICallCode } from '../../typings/systems/callCode';

export const CALL_CODE_TABLE_COUNT = 10;

export const callCodeInitApiSettings: IApiSettings<ICallCode> = {
  sortFields: [
    {
      field: 'flatNumber',
      descending: false,
    },
  ],
  page: 0,
  count: CALL_CODE_TABLE_COUNT,
  search: '',
};
