import React, { FC, useState } from 'react';
import { IDeviceTabComponent } from '../types';
import Button from '../../ui/button';
import { ButtonType } from '../../ui/button/types';
import AddMessageModal from './addMessageModal';
import DeviceErrorsTable from '../../deviceErrorsTable';
import { EDispatcherDeviceStatus, fromStatusToName } from '../../../typings/dispatcherPanel';

const DeviceBaseTab: FC<IDeviceTabComponent> = (props) => {
  const { device, deviceId = '', mainInfo, showInfo, permissions, isAdmin = false } = props;

  const [addMessageModalIsOpen, setAddMessageModalOpen] = useState(false);

  return (
    <div className="flat-base-tab">
      <AddMessageModal
        isOpen={addMessageModalIsOpen}
        onCancel={() => setAddMessageModalOpen(false)}
        deviceId={deviceId}
        onOk={() => setAddMessageModalOpen(false)}
      />
      {showInfo && mainInfo}

      <div className="flat-base-tab__title">
        <div className="flat-base-tab__title-label">Список текущих ошибок и предупреждений</div>
        {!isAdmin && (
          <div className="flat-base-tab__title-btn">
            <Button
              type={ButtonType.outline}
              disabled={!permissions?.edit}
              onClick={() => setAddMessageModalOpen(true)}
            >
              Добавить сообщение
            </Button>
          </div>
        )}
      </div>
      <DeviceErrorsTable
        emptyText={fromStatusToName.get(device?.status as EDispatcherDeviceStatus)}
        errors={device?.errors}
        warnings={device?.warnings}
      />
    </div>
  );
};

export default DeviceBaseTab;
