import { ETariffVersions } from '../management/tariff';

export interface ICompany {
  companyId?: string;
  companyName?: string;
}

export interface IObject {
  objectId?: string;
  objectName?: string;
}

export enum EPaymentStatus {
  pending = 'Pending',
  succeeded = 'Succeeded',
  canceled = 'Canceled',
  overdue = 'Overdue',
  refunded = 'Refunded',
}

export const paymentStatusNamesMap = new Map<EPaymentStatus, string>([
  [EPaymentStatus.pending, 'В ожидании'],
  [EPaymentStatus.succeeded, 'Успешен'],
  [EPaymentStatus.canceled, 'Неуспешен'],
  [EPaymentStatus.overdue, 'Просрочен'],
  [EPaymentStatus.refunded, 'Возвращен'],
]);

export interface IPayment {
  address?: string;
  amount?: number;
  cardNumber?: string;
  companyName?: string;
  companyAmount?: number;
  companyId?: string;
  email?: string;
  fio?: string;
  monthInTariff?: number;
  nextPaymentDate?: string;
  objectName?: string;
  paymentCreateDate?: string;
  paymentId?: string;
  paymentStatus?: EPaymentStatus;
  paymentUpdateDate?: string;
  phone?: string;
  providerErrorDetail?: string;
  providerErrorStatusCode?: string;
  providerPaymentUrl?: string;
  subscriptionCreateDate?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  tariff?: ETariffVersions;
  refundAmount: 0;
}
