import React, { FC } from 'react';
import { IInstructionsItem } from '../../typings/help/instructions';
import InstructionQuestion from '../ui/instructionQuestion';
import { IInstructionsChapterComponent } from './types';

const InstructionsChapter: FC<IInstructionsChapterComponent> = (props) => {
  const { title = '', instructions = [] } = props;

  return (
    <div className="instructions-chapter">
      {title && <div className="instructions-chapter__title">{title}</div>}
      <div className="instructions-chapter__content">
        {instructions.map((item: IInstructionsItem) => (
          <div key={`instruction-chapter-item-${item.id}`} className="instructions-chapter__item">
            <InstructionQuestion instruction={item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstructionsChapter;
