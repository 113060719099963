import React, { FC } from 'react';

const ArrowRight: FC = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.5942 4.99989L5.29709 1.70279L6.7113 0.288574L12.4226 5.9999L6.7113 11.7112L5.29709 10.297L8.59421 6.99989H0V4.99989H8.5942Z"
      fill="black"
    />
  </svg>
);

export default ArrowRight;
