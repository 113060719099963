import { DataNode } from 'antd/es/tree';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import EditIcon from '../../../../assets/svg/icons/edit';
import TrashIcon from '../../../../assets/svg/icons/trash';
import { IAccessPoint, IAccessPointDevice, ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import {
  getDefaultExpandedNodes,
  renderDeviceTreeDataNodes,
  renderTreeDataNodes,
  TRenderAccessPointFunction,
  TRenderNodeFunction,
} from '../../../../utils/tree';
import DirectoryTree from '../../../ui/directoryTree';
import { ISchemaTree } from './types';
import ImgIcon from '../../../../assets/svg/icons/img';
import { EVisualizerModes } from '../types';
import { EDispatcherDeviceStatus, fromStatusToColor } from '../../../../typings/dispatcherPanel';
import AccessDoor from '../../../../assets/svg/icons/accessDoor';
import Tooltip from '../../../ui/tooltip';

const SchemaTree: FC<ISchemaTree> = ({
  mode,
  showBackground = true,
  schema,
  onEdit = () => {},
  onDelete = () => {},
  goToDevicePage = () => {},
  parents,
  permissions = {},
  settings = {},
  defaultExpandAll,
  titleName = 'План объекта',
  ...props
}) => {
  const renderNode: TRenderNodeFunction<ITreeNode> & TRenderNodeFunction<IAccessPoint> = useCallback(
    (node, key = '', deep = 0) => (
      <div id={key}>
        <div
          className={classNames('ant-tree-title-name ', {
            'ant-tree-title-name_bold':
              !deep && (node.type === TreeNodeType.building || node.type === TreeNodeType.restrictedArea),
          })}
        >
          {`${node.name} ${'prefix' in node ? node.prefix || '' : ''}`}
        </div>
        {permissions?.edit && (
          <div className="ant-tree-title-icons">
            <div
              role="presentation"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit(key);
              }}
            >
              <EditIcon />
            </div>
            <div
              role="presentation"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete(key);
              }}
            >
              <TrashIcon />
            </div>
          </div>
        )}
        {'address' in node && node.address && <div className="ant-tree-title-address">{node.address}</div>}
      </div>
    ),
    [onDelete, onEdit, permissions?.edit]
  );

  const renderAccessPoint: TRenderAccessPointFunction<IAccessPoint> = useCallback(
    (node, key, field) => {
      const fieldData = node[field as keyof IAccessPoint] as IAccessPointDevice;
      return (
        <div id={key}>
          <div
            className={`ant-tree-title-name dispatcher-panel__status dispatcher-panel__status_${
              fieldData?.status
                ? fromStatusToColor.get(fieldData?.status?.status as EDispatcherDeviceStatus) || 'blue'
                : 'blue'
            }`}
          >
            {`${node.name}${field === 'inputDevice' ? '_вход' : '_выход'}`}
            <Tooltip title="Перейти на страницу оборудования" placement="bottom">
              <div
                role="presentation"
                onClick={(e) => {
                  e.stopPropagation();
                  goToDevicePage(fieldData.id || '');
                }}
                className="ant-tree-title-name__icon"
              >
                <AccessDoor />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
    [goToDevicePage]
  );

  const treeData = useMemo<DataNode[]>(
    () => [
      ...(mode !== EVisualizerModes.deviceShow && showBackground
        ? [
            {
              title: (
                <div className="directory-tree__img-title-content">
                  <div className="directory-tree__img-title-icon">
                    <ImgIcon />
                  </div>
                  <div className="directory-tree__img-title-label">Подложка</div>
                </div>
              ),
              key: TreeNodeType.background,
              isLeaf: true,
              className: 'directory-tree__img-title',
            },
          ]
        : []),
      {
        title: titleName,
        key: TreeNodeType.object,
        isLeaf: schema.length === 0,
        children:
          mode === EVisualizerModes.deviceShow
            ? renderDeviceTreeDataNodes(schema, renderAccessPoint, renderNode)
            : renderTreeDataNodes(schema, renderNode, renderNode, renderNode, settings),
        className: 'ant-tree-root',
      },
    ],
    [mode, renderAccessPoint, renderNode, schema, settings, showBackground, titleName]
  );

  return (
    <DirectoryTree
      expandDefault={getDefaultExpandedNodes}
      parents={parents}
      needScrollToView
      treeData={treeData}
      defaultExpandAll={defaultExpandAll}
      {...props}
    />
  );
};

export default SchemaTree;
