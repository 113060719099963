import React, { FC, useCallback, useEffect, useState } from 'react';
import { IParamField } from '../types';
import InputSlider from '../../../../../../ui/inputSlider';
import Checkbox from '../../../../../../ui/checkbox';

const RangeField: FC<IParamField> = ({ object, changeParam, index, disabled = false }) => {
  const [isAuto, setIsAuto] = useState(false);

  useEffect(() => {
    if (!object.wasChange) {
      if (object.value === 'auto') {
        setIsAuto(true);
      } else if (!object.value) {
        object.value = object.defaultValue;
        changeParam(index, object);
      }
    }
  }, [object]);

  const onChange = useCallback(
    (value: number) => {
      object.value = value.toString();
      object.wasChange = true;
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  const onChangeCheckbox = useCallback(
    (value: boolean) => {
      object.wasChange = true;
      setIsAuto(value);
      if (value) object.value = 'auto';
      else object.value = '1';
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  return (
    <div className="cam-options-range">
      {object.autoAvailable && (
        <Checkbox disabled={disabled} label="авто" checked={isAuto} onChange={onChangeCheckbox} />
      )}

      <InputSlider
        min={Number(object.availableValues[0]) || 0}
        max={Number(object.availableValues[1]) || 100}
        title={object.name || ''}
        onChange={onChange}
        disabled={disabled || isAuto}
        isDisabledStyle={disabled || isAuto}
        value={object.value}
      />
    </div>
  );
};

export default RangeField;
