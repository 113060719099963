import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import { IDisablingPushTable } from './types';
import { IDisablePushPeriod } from '../../../../typings/dispatcherPanel';
import { deviceStatusMap, notifyTypeMap, periodsMap } from '../config';
import { ENotificationsTypes } from '../types';
import { dateDefaultFormat } from '../../../../constants/date';
import TrashIcon from '../../../../assets/svg/icons/trash';
import UniversalModal from '../../../ui/universalModal';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm, deleteModal } from '../../../ui/universalModal/config';
import { useApi } from '../../../../hooks/useApi';
import { deleteRequest } from '../../../../api';
import { notifyDisabledDeleteUrl } from '../../../../constants/api';
import Scrollbar from '../../../ui/scrollbar';

const DisablingPushTable: FC<IDisablingPushTable> = (props) => {
  const { permissions = {}, loading = false, periods, requestData = () => {} } = props;

  const { sendRequest: deletePeriod } = useApi(deleteRequest);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const tryToDelete = useCallback(
    (record: IDisablePushPeriod) => () => {
      setConfirmData(
        deleteModal(
          'этот период отключения push-уведомлений',
          async () => {
            closeConfirm();
            await deletePeriod(notifyDisabledDeleteUrl(record.id));
            requestData();
          },
          closeConfirm
        )
      );
    },
    [closeConfirm, deletePeriod, requestData]
  );

  const columns = useMemo<ColumnsType<IDisablePushPeriod>>(
    () => [
      {
        title: 'Объект',
        dataIndex: 'object',
        key: 'object',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__data-container">{record.objectName || 'Все'}</div>
        ),
      },
      {
        title: 'Tип уведомления',
        dataIndex: 'type',
        key: 'type',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__data-container">{notifyTypeMap.get(record.type as ENotificationsTypes)}</div>
        ),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__data-container">
            {record.deviceStatuses.map((item) => deviceStatusMap.get(item)).toString()}
          </div>
        ),
      },
      {
        title: 'Оборудование',
        dataIndex: 'devices',
        key: 'devices',
        width: '30%',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__data-container">
            {record.deviceIds
              .map((item) => item.name)
              .toString()
              .split(',')
              .join(', ')}
          </div>
        ),
      },
      {
        title: 'Период',
        dataIndex: 'period',
        key: 'period',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__data-container">{periodsMap.get(record.minutes.toString())}</div>
        ),
      },
      {
        title: 'Дата завершения',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__data-container">{moment(record.endDate || '').format(dateDefaultFormat)}</div>
        ),
      },
      {
        hidden: !permissions?.delete,
        dataIndex: 'delete',
        key: 'delete',
        render: (_: any, record: IDisablePushPeriod) => (
          <div className="editable-table__icon-container">
            <div onClick={tryToDelete(record)} role="presentation" className="editable-table__icon">
              <TrashIcon />
            </div>
          </div>
        ),
      },
    ],
    [permissions?.delete, tryToDelete]
  );

  const data = useMemo(
    () =>
      periods?.map((item, index) => ({
        ...item,
        key: index,
      })),
    [periods]
  );

  return (
    <div className="disabling-push-table editable-table">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="disabling-push-table__line" />
      <Table
        className="default-scrollbar-override"
        columns={columns}
        dataSource={data}
        scroll={{ x: '100vh' }}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        pagination={false}
      />
    </div>
  );
};

export default DisablingPushTable;
