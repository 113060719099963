import React, { FC } from 'react';

const ExtinctStartIcon: FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.9456 8.54675C10.7729 8.71408 10.6936 8.95608 10.7329 9.19341L11.3256 12.4734C11.3756 12.7514 11.2583 13.0327 11.0256 13.1934C10.7976 13.3601 10.4943 13.3801 10.2456 13.2467L7.29292 11.7067C7.19026 11.6521 7.07626 11.6227 6.95959 11.6194H6.77892C6.71626 11.6287 6.65493 11.6487 6.59893 11.6794L3.64559 13.2267C3.49959 13.3001 3.33426 13.3261 3.17226 13.3001C2.77759 13.2254 2.51426 12.8494 2.57893 12.4527L3.17226 9.17275C3.21159 8.93341 3.13226 8.69008 2.95959 8.52008L0.552258 6.18675C0.350925 5.99141 0.280925 5.69808 0.372925 5.43341C0.462258 5.16941 0.690258 4.97675 0.965592 4.93341L4.27892 4.45275C4.53093 4.42675 4.75226 4.27341 4.86559 4.04675L6.32559 1.05341C6.36026 0.986748 6.40493 0.925415 6.45893 0.873415L6.51892 0.826748C6.55026 0.792081 6.58626 0.763415 6.62626 0.740081L6.69893 0.713415L6.81226 0.666748H7.09293C7.34359 0.692748 7.56426 0.842748 7.67959 1.06675L9.15893 4.04675C9.26559 4.26475 9.47293 4.41608 9.71226 4.45275L13.0256 4.93341C13.3056 4.97341 13.5396 5.16675 13.6323 5.43341C13.7196 5.70075 13.6443 5.99408 13.4389 6.18675L10.9456 8.54675Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default ExtinctStartIcon;
