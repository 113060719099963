import React, { FC } from 'react';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Modal from '../../../ui/modal';
import { ICreateNotification } from './types';

const CreateNotificationModal: FC<ICreateNotification> = (props) => {
  const {
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    title = 'Запущен процесс генерации ключа. Следуйте указаниям в приложении для работы со считывателем',
    buttonText = 'Ок',
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={title}
      width={416}
      showCloseIcon
      wrapClassName="create-notification__wrapper"
      centered
      footer={
        <div className="create-notification__button-container">
          <Button size={ButtonSize.small} type={ButtonType.primary} onClick={onOk}>
            {buttonText}
          </Button>
        </div>
      }
    />
  );
};

export default CreateNotificationModal;
