import React, { FC } from 'react';
import CommonHead from '../../components/commonHead';

const Page404: FC = () => (
  <>
    <CommonHead seo={{ title: '404' }} />
    <div>404 Page not found</div>
  </>
);

export default Page404;
