import { IHardwareCameraParam } from '../../../../../../../typings/systems/hardwareSettings';
import { emptyFieldErrorText } from '../types';

export const getCameraNumberFieldError = (numberFieldValue: IHardwareCameraParam): string => {
  const value = Number(numberFieldValue.value);
  if (numberFieldValue.isRequired && !value && value !== 0) {
    return emptyFieldErrorText;
  }

  if (value) {
    const minValue = Number(numberFieldValue.availableValues[0]) || 0;
    const maxValue = Number(numberFieldValue.availableValues[1]) || 0;
    if (minValue || maxValue) {
      const minIsError = (minValue || minValue === 0) && value < parseInt(minValue?.toString(), 10);
      const maxIsError = (maxValue || maxValue === 0) && value > parseInt(maxValue?.toString(), 10);

      if (minIsError && maxIsError) {
        return `Введите число от ${minValue} до ${maxValue}`;
      }
      if (minIsError) {
        return `Введите число от ${minValue}`;
      }
      if (maxIsError) {
        return `Введите число до ${maxValue}`;
      }
    }
  }
  return '';
};
