import React, { FC } from 'react';

const InfoIcon: FC = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="#949494" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="7" fill="#F4F4F4" />
    <path d="M6 3H8V4.21008H6V3ZM6 5.20728H8V11H6V5.20728Z" fill="#949494" />
  </svg>
);

export default InfoIcon;
