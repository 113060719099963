import React, { FC } from 'react';

const GuideIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33325 5.99935C5.33325 4.1584 6.82564 2.66602 8.66658 2.66602H25.9999C26.3681 2.66602 26.6666 2.96449 26.6666 3.33268C26.6666 3.70087 26.3681 3.99935 25.9999 3.99935H8.66658C7.56202 3.99935 6.66659 4.89478 6.66659 5.99935C6.66659 7.10392 7.56202 7.99935 8.66658 7.99935H23.3333C25.1742 7.99935 26.6666 9.49173 26.6666 11.3327V25.9993C26.6666 27.8403 25.1742 29.3327 23.3333 29.3327H11.3333C8.01954 29.3327 5.33325 26.6464 5.33325 23.3327V5.99935ZM6.66658 23.3327C6.66658 25.91 8.75592 27.9993 11.3333 27.9993H23.3333C24.4378 27.9993 25.3333 27.1039 25.3333 25.9993V11.3327C25.3333 10.2281 24.4378 9.33268 23.3333 9.33268H8.66658C7.91618 9.33268 7.22369 9.08472 6.66658 8.66626V23.3327ZM7.99992 5.99935C7.99992 6.36754 8.2984 6.66602 8.66658 6.66602H25.9999C26.3681 6.66602 26.6666 6.36754 26.6666 5.99935C26.6666 5.63116 26.3681 5.33268 25.9999 5.33268H8.66658C8.2984 5.33268 7.99992 5.63116 7.99992 5.99935ZM10.6666 13.9993C10.6666 13.6312 10.9651 13.3327 11.3333 13.3327H20.6666C21.0348 13.3327 21.3333 13.6312 21.3333 13.9993V17.9993C21.3333 18.3675 21.0348 18.666 20.6666 18.666H11.3333C10.9651 18.666 10.6666 18.3675 10.6666 17.9993V13.9993ZM19.9999 17.3327H11.9999V14.666H19.9999V17.3327ZM11.3333 21.3327C10.9651 21.3327 10.6666 21.0342 10.6666 20.666C10.6666 20.2978 10.9651 19.9993 11.3333 19.9993H20.6666C21.0348 19.9993 21.3333 20.2978 21.3333 20.666C21.3333 21.0342 21.0348 21.3327 20.6666 21.3327H11.3333ZM10.6666 23.3327C10.6666 23.7009 10.9651 23.9993 11.3333 23.9993H16.6666C17.0348 23.9993 17.3333 23.7009 17.3333 23.3327C17.3333 22.9645 17.0348 22.666 16.6666 22.666H11.3333C10.9651 22.666 10.6666 22.9645 10.6666 23.3327Z"
      fill="white"
    />
  </svg>
);

export default GuideIcon;
