import React, { FC, useRef } from 'react';
import { IClusterComponent } from './types';
import { YMapMarker } from '..';

const ClusterComponent: FC<IClusterComponent> = ({ coordinates, features = [], onClick = () => () => {} }) => {
  const ref = useRef<any>();

  return (
    <YMapMarker
      ref={ref}
      onClick={onClick(coordinates)}
      key={`${features[0].id}-${features.length}`}
      coordinates={coordinates}
      source="clusterer-source"
      draggable={false}
    >
      <div className="cluster">
        <div className="cluster__content">
          <span className="cluster__text">{features.length}</span>
        </div>
      </div>
    </YMapMarker>
  );
};

export default ClusterComponent;
