import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { addSubscriberEntity, getEntitiesForSubscriber } from '../../../api/subscribers';
import {
  editSubscriberFlatEquipmentUrl,
  getCheckHiddenAccessPointsUrl,
  getSubscriberFlatEquipmentUrl,
  getSubscriberFlatsWithKeysUrl,
  validateSubscriberFlatEquipmentUrl,
} from '../../../constants/api';
import { paths } from '../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { ESystemTabsIds } from '../../../pages/systems/item/types';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { Status } from '../../../typings/status';
import { ISubscriberFlatWithKeys, ISubscriberKey, SubscriberKeyType } from '../../../typings/subscribers/equipment';
import { ITreeNode } from '../../../typings/treeNode';
import { sortFlatWithKeysByAddress } from '../../../utils/subscriber';
import { setDataNodePropsToTreeNodes } from '../../../utils/tree';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import NotificationModal from '../../ui/notificationModal';
import { ISubscriberTabComponent, SubscriberEditMode } from '../types';
import EquipmentData from './equipmentData';
import { getRequest } from '../../../api';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import { IInputValue, InputStatus, defaultNotRequiredValue } from '../../ui/input/types';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm } from '../../ui/universalModal/config';
import { ButtonType } from '../../ui/button/types';

const EquipmentTab: FC<ISubscriberTabComponent> = (props) => {
  const {
    subscriber,
    onSave = () => {},
    onNextTab = () => {},
    editMode = SubscriberEditMode.viewing,
    nextButtonText = 'Готово',
    wasChange = false,
    setWasChange = () => {},
    nextTabId = '',
    isActiveTab = false,
    resetNextTabId = () => {},
    onChangeDisableAllTabs = () => {},
  } = props;

  const navigate = useNavigate();

  const { sendRequest: validateUser, loading: validateLoading } = useApi(getRequest);

  const {
    data: flats,
    sendRequest: sendRequestFlats,
    loading: flatsLoading,
  } = useApi<ISubscriberFlatWithKeys[]>(getEntitiesForSubscriber, sortFlatWithKeysByAddress);
  const {
    data: flatEquipmentTree,
    sendRequest: sendRequestFlatEquipmentTree,
    loading: treeLoading,
  } = useApi<ITreeNode[]>(getEntitiesForSubscriber);
  const { sendRequest: sendRequestUpdateEquipment, loading } = useApi(addSubscriberEntity);
  const { data: isHiddenPoints, sendRequest: getHiddenPoints, loading: hiddenPointsLoading } = useApi(getRequest);
  const [activeFlatId, setActiveFlatId] = useState<string>('');
  const [selectedKey, setSelectedKey] = useState<ISubscriberKey | null>(null);
  const [tree, setTree] = useState<ITreeNode[]>([]);
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState<boolean>(false);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState<boolean>(false);
  const [nextActiveFlatId, setNextActiveFlatId] = useState<string>('');

  const [name, setName] = useState<IInputValue>(defaultNotRequiredValue);

  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.objects));

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const sortTree = useCallback((node: ITreeNode) => {
    if (node.childItems) {
      node.childItems.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
    if (node.accessPoints) {
      node.accessPoints.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
    node.childItems?.forEach((child) => sortTree(child));
  }, []);

  const setTreeAndSort = useCallback(
    (data: ITreeNode[]) => {
      data.forEach((node) => sortTree(node));
      setTree(data);
    },
    [sortTree]
  );

  const onChangeName = useCallback(
    (value: string | number) => {
      setName({ ...defaultNotRequiredValue, value: value?.toString() || '' });
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [setWasChange, wasChange]
  );

  useEffect(() => {
    if (selectedKey) {
      setName({ ...defaultNotRequiredValue, value: selectedKey?.keyAlias });
    }
  }, [selectedKey]);

  const onBlurName = useCallback(() => {
    if (!name.value || !name.value.trim())
      setName({ ...name, status: InputStatus.error, errorText: 'Поле обязательно для заполнения' });
  }, [name]);

  const requestFlatKeyTree = useCallback(
    async (flatId: string, key: ISubscriberKey) => {
      await sendRequestFlatEquipmentTree(getSubscriberFlatEquipmentUrl(flatId, key.id || ''), {
        params: { type: key.type, subscriberId: subscriber?.id },
      });
    },
    [sendRequestFlatEquipmentTree, subscriber?.id]
  );

  const changeSelectedKey = useCallback(
    async (newKey: ISubscriberKey, flatId: string) => {
      await requestFlatKeyTree(flatId, newKey);
      setSelectedKey(newKey);
    },
    [requestFlatKeyTree]
  );

  const requestFlats = useCallback(async () => {
    if (subscriber?.id) {
      await sendRequestFlats(getSubscriberFlatsWithKeysUrl(subscriber.id));
    }
  }, [sendRequestFlats, subscriber]);

  useEffect(() => {
    if (isActiveTab) {
      requestFlats();
    }
  }, [subscriber, editMode, isActiveTab]);

  useEffect(() => {
    if (flats && flats.length > 0) {
      const findFlat =
        flats.find((flat) => flat.flatId === activeFlatId && flat.status === Status.active) ||
        flats.find((flat) => flat.status === Status.active) ||
        flats[0];

      setActiveFlatId(findFlat.flatId || '');

      setWasChange(false);
      setTree([]);
      const findKey = findFlat.keys?.find((item) => item.isSelected);
      if (findKey) {
        changeSelectedKey(findKey, findFlat.flatId || '');
      } else {
        setTreeAndSort([]);
        setSelectedKey(null);
      }
    }
  }, [flats]);

  useEffect(() => {
    if (flatEquipmentTree) {
      setTreeAndSort(flatEquipmentTree);
    }
  }, [flatEquipmentTree]);

  useEffect(() => {
    if (activeFlatId) {
      getHiddenPoints(getCheckHiddenAccessPointsUrl(activeFlatId));
    }
  }, [activeFlatId]);

  const handleOnChangeActiveFlatId = useCallback(
    async (value: string, skip?: boolean) => {
      if (flats) {
        if (wasChange && !skip) {
          setNextActiveFlatId(value);
          setConfirmModalIsOpen(true);
        } else {
          setActiveFlatId(value);
          setNextActiveFlatId('');
          setWasChange(false);
          setConfirmModalIsOpen(false);
          await requestFlats();
        }
      }
    },
    [flats, wasChange, requestFlats, setWasChange]
  );

  const handleOnChangeSelectedKey = useCallback(
    async (value: string | number) => {
      if (activeFlatId && flats) {
        const findKey = flats.find((item) => item.flatId === activeFlatId)?.keys?.find((item) => item.id === value);
        if (findKey) {
          await changeSelectedKey(findKey, activeFlatId);
          setWasChange(!findKey.isSelected);
        }
      }
    },
    [activeFlatId, changeSelectedKey, flats, setWasChange]
  );

  const handleOnChangeTree = useCallback(
    (updatedTree: ITreeNode[]) => {
      setWasChange(true);
      setTreeAndSort(updatedTree);
    },
    [setWasChange, setTreeAndSort]
  );

  const handleOnClickButton = useCallback(async () => {
    if (subscriber?.id) {
      const validate = await validateUser(
        validateSubscriberFlatEquipmentUrl(activeFlatId, subscriber?.id, selectedKey?.id || '')
      );
      if (!validate?.response?.data) {
        setNotificationModalIsOpen(true);
      } else {
        setConfirmData({
          isOpen: true,
          description:
            'Выбранный ключ уже привязан к другому абоненту. При сохранении ключ будет отвязан от старого абонента',
          buttons: [
            {
              label: 'Отмена',
              type: ButtonType.primary,
              onClick: closeConfirm,
            },
            {
              label: 'Сохранить',
              type: ButtonType.secondary,
              onClick: () => setNotificationModalIsOpen(true),
            },
          ],
        });
      }
    }
  }, [activeFlatId, closeConfirm, selectedKey?.id, subscriber?.id, validateUser]);

  const saveEquipment = useCallback(async () => {
    if (activeFlatId && selectedKey?.id && selectedKey.type && tree.length > 0 && wasChange && subscriber?.id) {
      const clearedDataNodeTree = setDataNodePropsToTreeNodes(tree);
      return sendRequestUpdateEquipment(
        editSubscriberFlatEquipmentUrl(subscriber.id, activeFlatId, selectedKey.id),
        clearedDataNodeTree,
        { params: { type: selectedKey.type as string, keyAlias: name.value } }
      );
    }

    return false;
  }, [activeFlatId, selectedKey, tree, wasChange, subscriber, sendRequestUpdateEquipment, name]);

  const handleOnOkConfirmModal = useCallback(async () => {
    if (nextActiveFlatId || clickedSidebarTab) {
      onChangeDisableAllTabs(true);
      const resError = await saveEquipment();
      onChangeDisableAllTabs(false);
      if (!resError?.response?.data) {
        if (clickedSidebarTab) {
          setWasChange(false);
          navigate(clickedSidebarTab);
          dispatch(setClickedSidebarTab(null));
        } else {
          await handleOnChangeActiveFlatId(nextActiveFlatId, true);
        }
      }
    }
  }, [
    clickedSidebarTab,
    dispatch,
    handleOnChangeActiveFlatId,
    navigate,
    nextActiveFlatId,
    onChangeDisableAllTabs,
    saveEquipment,
    setWasChange,
  ]);

  const handleOnCancelConfirmModal = useCallback(async () => {
    if (clickedSidebarTab) {
      setWasChange(false);
      navigate(clickedSidebarTab);
      dispatch(setClickedSidebarTab(null));
    } else if (nextActiveFlatId) {
      await handleOnChangeActiveFlatId(nextActiveFlatId, true);
    }
  }, [clickedSidebarTab, dispatch, handleOnChangeActiveFlatId, navigate, nextActiveFlatId, setWasChange]);

  const handleOnCloseConfirmModal = useCallback(() => {
    setConfirmModalIsOpen(false);
    setNextActiveFlatId('');
  }, []);

  const handleOnClickButtonNotificationModal = useCallback(
    async (isSkip = false) => {
      if (subscriber) {
        if (!isSkip) {
          onChangeDisableAllTabs(true);
          await saveEquipment();
          onChangeDisableAllTabs(false);
        }
        setWasChange(false);
        setNextActiveFlatId('');
        setNotificationModalIsOpen(false);
        onSave(subscriber);
      }
    },
    [onChangeDisableAllTabs, onSave, saveEquipment, setWasChange, subscriber]
  );

  const handleOnCloseNotificationModal = useCallback(() => {
    setNotificationModalIsOpen(false);
  }, []);

  const handleOnClickButtonChangeTabModal = useCallback(
    async (isSkip = false) => {
      await handleOnClickButtonNotificationModal(isSkip);
      onNextTab(nextTabId);
    },
    [handleOnClickButtonNotificationModal, nextTabId, onNextTab]
  );

  const handleOnCloseTabModal = useCallback(() => {
    resetNextTabId();
  }, [resetNextTabId]);

  const goToSystemTab = useCallback(
    (tab: ESystemTabsIds, flat: ISubscriberFlatWithKeys) => {
      navigate({
        pathname: `${paths.systems}/${flat.objectId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: tab })}`,
      });
    },
    [navigate]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      setConfirmModalIsOpen(true);
    }
  }, [clickedSidebarTab]);

  const selectedKeyIsObject = useMemo(() => selectedKey?.type === SubscriberKeyType.object, [selectedKey?.type]);

  return (
    <div className="equipment-tab">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <NotificationModal
        title="Вы хотите сохранить изменения?"
        okButtonText="Да"
        cancelButtonText="Нет"
        isOpen={confirmModalIsOpen}
        onOk={handleOnOkConfirmModal}
        onCancel={handleOnCancelConfirmModal}
        onClose={handleOnCloseConfirmModal}
      />
      <NotificationModal
        title="Вы хотите сохранить изменения?"
        okButtonText="Да"
        cancelButtonText="Нет"
        isOpen={Boolean(nextTabId) && wasChange && isActiveTab}
        onOk={() => handleOnClickButtonChangeTabModal()}
        onCancel={() => handleOnClickButtonChangeTabModal(true)}
        onClose={handleOnCloseTabModal}
      />
      <NotificationModal
        title="Изменение настроек ключа"
        bodyText="Вы действительно хотите изменить и сохранить настройки ключа?"
        okButtonText="Сохранить"
        cancelButtonText="Отменить изменения"
        isOpen={notificationModalIsOpen}
        onOk={() => handleOnClickButtonNotificationModal()}
        onCancel={() => handleOnClickButtonNotificationModal(true)}
        onClose={handleOnCloseNotificationModal}
      />
      <TabsCustom
        activeTabKey={activeFlatId}
        onChangeActiveTab={handleOnChangeActiveFlatId}
        tabPaneContainerClassName="equipment-tab__tab-pane-container"
        tabPaneFieldTitleClassName="equipment-tab__tab-pane-text"
        loading={(!flats || flats.length === 0) && (flatsLoading || hiddenPointsLoading)}
        tabs={flats?.map<ICustomTab>((flat) => ({
          tabId: flat.flatId || '',
          disabled: flat.status !== Status.active,
          data: [
            { fieldTitle: 'Объект', fieldData: flat.name },
            { fieldTitle: 'Адрес', fieldData: flat.address },
          ],
          children: (
            <EquipmentData
              selectedKeyIsObject={selectedKeyIsObject}
              editMode={editMode}
              keys={flat.keys}
              selectedKey={selectedKey || undefined}
              onChangeSelectedKey={handleOnChangeSelectedKey}
              tree={tree}
              onChangeTree={handleOnChangeTree}
              goToSystemTab={(tab: ESystemTabsIds) => goToSystemTab(tab, flat)}
              loading={treeLoading || flatsLoading || loading}
              permissionObject={permissionObject}
              isHiddenPoints={isHiddenPoints}
              keyName={name}
              onChangeName={onChangeName}
              onBlurName={onBlurName}
            />
          ),
        }))}
      />
      {editMode !== SubscriberEditMode.viewing && (
        <TabNavButtons
          containerClassName="equipment-tab__button-container"
          buttons={tabNavButtonsDefault(
            { isHidden: true },
            { isHidden: true },
            {
              disabled:
                !wasChange || selectedKeyIsObject ? !name.value?.trim() || name.status === InputStatus.error : false,
              callBack: handleOnClickButton,
              classNameOption: 'equipment-tab__button-next',
              text: nextButtonText,
              loading: loading || validateLoading,
            }
          )}
        />
      )}
    </div>
  );
};

export default EquipmentTab;
