import { IFlatsPageFlat } from '../../typings/flat';
import { IProfilePermission } from '../../typings/profile';

export interface IFlatTabComponent {
  permissions?: IProfilePermission;
  flat?: IFlatsPageFlat;
  flatId?: string;
  onNextTab?: () => void;
  onChangeTab?: (id: string) => void;
  activeTabKey?: string;
  tabId?: string;
  nextTabId?: string;
  wasChange?: boolean;
  updateFlats?: () => void;
  isFiveThousandth?: boolean;
  setChange?: (value: boolean) => void;
  repeatChangeTab?: () => void;
  chancelChangeTab?: () => void;
  clickedTab?: string;
  isAdmin?: boolean | null;
}

export enum EFlatsFilters {
  subscriberIds = 'subscriberIds',
  deviceId = 'deviceId',
  keys = 'keys',
  date = 'date',
  type = 'type',
  status = 'status',
  search = 'search',
}
