import React from 'react';
import ReactDOM from 'react-dom/client';
import AuthService from './api/authService';
import App from './app';
import Message from './components/message';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const rootRender = () => root.render(<App />);

AuthService.initKeycloak(rootRender);

Message.initMessage();
