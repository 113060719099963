import React, { FC } from 'react';

const HamburgerIcon: FC = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.96649 8.46663C3.96649 7.73025 4.56345 7.1333 5.29983 7.1333H26.6332C27.3696 7.1333 27.9665 7.73025 27.9665 8.46663C27.9665 9.20301 27.3696 9.79997 26.6332 9.79997H5.29983C4.56345 9.79997 3.96649 9.20301 3.96649 8.46663ZM3.96649 16.4666C3.96649 15.7303 4.56345 15.1333 5.29983 15.1333H26.6332C27.3696 15.1333 27.9665 15.7303 27.9665 16.4666C27.9665 17.203 27.3696 17.8 26.6332 17.8H5.29983C4.56345 17.8 3.96649 17.203 3.96649 16.4666ZM3.96649 24.4666C3.96649 23.7303 4.56345 23.1333 5.29983 23.1333H26.6332C27.3696 23.1333 27.9665 23.7303 27.9665 24.4666C27.9665 25.203 27.3696 25.8 26.6332 25.8H5.29983C4.56345 25.8 3.96649 25.203 3.96649 24.4666Z"
    />
  </svg>
);

export default HamburgerIcon;
