import React, { FC } from 'react';

const FeedbackIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3335 7.33333C5.3335 5.49238 6.82588 4 8.66683 4H23.3335C25.1744 4 26.6668 5.49238 26.6668 7.33333V19.3333C26.6668 24.1198 22.7866 28 18.0002 28H8.66683C6.82588 28 5.3335 26.5076 5.3335 24.6667V7.33333ZM25.3335 7.33333V19.3333C25.3335 20.4379 24.4381 21.3333 23.3335 21.3333C21.4925 21.3333 20.0002 22.8257 20.0002 24.6667C20.0002 25.7712 19.1047 26.6667 18.0002 26.6667H8.66683C7.56226 26.6667 6.66683 25.7712 6.66683 24.6667V7.33333C6.66683 6.22876 7.56226 5.33333 8.66683 5.33333H23.3335C24.4381 5.33333 25.3335 6.22876 25.3335 7.33333ZM23.3335 22.6667C23.8083 22.6667 24.2599 22.5674 24.6688 22.3885C23.937 23.9832 22.65 25.2701 21.0553 26.0019C21.2342 25.5931 21.3335 25.1415 21.3335 24.6667C21.3335 23.5621 22.2289 22.6667 23.3335 22.6667ZM10.6668 11.3333C10.6668 11.7015 10.9653 12 11.3335 12H20.6668C21.035 12 21.3335 11.7015 21.3335 11.3333C21.3335 10.9651 21.035 10.6667 20.6668 10.6667H11.3335C10.9653 10.6667 10.6668 10.9651 10.6668 11.3333ZM11.3335 16C10.9653 16 10.6668 15.7015 10.6668 15.3333C10.6668 14.9651 10.9653 14.6667 11.3335 14.6667H20.6668C21.035 14.6667 21.3335 14.9651 21.3335 15.3333C21.3335 15.7015 21.035 16 20.6668 16H11.3335ZM10.6668 19.3333C10.6668 19.7015 10.9653 20 11.3335 20H18.0002C18.3684 20 18.6668 19.7015 18.6668 19.3333C18.6668 18.9651 18.3684 18.6667 18.0002 18.6667H11.3335C10.9653 18.6667 10.6668 18.9651 10.6668 19.3333Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default FeedbackIcon;
