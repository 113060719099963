import React, { FC } from 'react';

const VolumeOffIcon: FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#949494" strokeOpacity="0.5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8246 16.6764L14.8743 25.6274C14.6417 25.4927 14.4213 25.407 14.2254 25.3947C12.5235 25.2723 11.8623 25.4682 10.9317 24.6723C9.92773 23.8152 10.0012 21.5254 10.0012 19.8478C10.0012 18.1703 9.92773 15.8805 10.9317 15.0233C11.8623 14.2274 12.5235 14.4356 14.2254 14.3009C15.9273 14.1662 19.527 8.97434 22.3064 10.6152C23.4328 11.5335 23.7389 13.2478 23.8246 16.6764Z"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.8256 22.5156C23.7766 26.2871 23.4828 28.1238 22.3073 29.0789C21.0217 29.8381 19.5647 29.1401 18.1934 28.1483"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.001 30.5L14.8741 25.6265L23.8244 16.6755L30.9994 9.5"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default VolumeOffIcon;
