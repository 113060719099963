import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/es/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useState, useMemo } from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { editFlat } from '../../../../api/flats';
import AddNodeIcon from '../../../../assets/svg/icons/addNote';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import EditIcon from '../../../../assets/svg/icons/edit';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { securityDeckFlatUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { IApiSortField } from '../../../../typings/api';
import { ISecurityDeck } from '../../../../typings/systems/flat';
import Checkbox from '../../../ui/checkbox';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import NotificationModal from '../../../ui/notificationModal';
import { ISecurityDecksTable } from './types';
import EditSquareIcon from '../../../../assets/svg/icons/editSquare';
import GroupEditModal from '../groupEditModal';
import Tooltip from '../../../ui/tooltip';
import SingleDatePicker from '../../../ui/singleDatePicker';
import { dateFormatOnlyTime } from '../../../../constants/date';
import InfoIcon from '../../../../assets/svg/icons/info';
import Message from '../../../message';

const SecurityDecksTable: FC<ISecurityDecksTable> = (props) => {
  const {
    flats = [],
    systemId = '',
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    buildingId = '',
    onEditFlat = () => {},
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    permissions = {},
    sections = [],
    handleOnOkModal = () => {},
    isFiveThousandth = false,
  } = props;

  const { sendRequest } = useApi(editFlat);
  const [editingRecord, setEditingRecord] = useState<ISecurityDeck | null>(null);
  const [notificationFlatModalIsOpen, setNotificationFlatModalIsOpen] = useState<boolean>(false);
  const [clickNumber, setClickNumber] = useState<number>(0);

  const [groupModalOpen, setGroupModalOpen] = useState(false);

  const [timeError, setTimeError] = useState(false);

  const handleOnChangeEditingRecord = useCallback(
    (value: any, fieldName: keyof ISecurityDeck) => {
      const updatedEditingRecord = { ...editingRecord };
      updatedEditingRecord[fieldName] = value;
      setEditingRecord(updatedEditingRecord as ISecurityDeck);
    },
    [editingRecord]
  );

  const isEditing = useCallback((record: ISecurityDeck) => record.id === editingRecord?.id, [editingRecord?.id]);

  const handleOnClickEdit = useCallback(
    (record: ISecurityDeck) => {
      if (!editingRecord) {
        setEditingRecord(record);
      }
    },
    [editingRecord]
  );

  const resetEdit = useCallback(() => {
    setEditingRecord(null);
    setClickNumber(0);
    setTimeError(false);
  }, []);

  const handleOnSaveEdit = useCallback(async () => {
    if (editingRecord) {
      const flat = flats.find((item) => item.id === editingRecord.id);
      if (flat && !lodash.isEqual({ ...flat, key: editingRecord.key }, editingRecord)) {
        const resError = await sendRequest(securityDeckFlatUrl(buildingId, editingRecord.sectionId), editingRecord);
        if (!resError) {
          onEditFlat();
          resetEdit();
          return true;
        }
        setClickNumber(clickNumber + 1);
        setTimeError(true);
      } else {
        resetEdit();
        return true;
      }
    }

    return false;
  }, [buildingId, clickNumber, editingRecord, flats, onEditFlat, resetEdit, sendRequest]);

  const handleOnChangeTable: TableProps<ISecurityDeck>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ISecurityDeck> | SorterResult<ISecurityDeck>[],
      extra: TableCurrentDataSource<ISecurityDeck>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<ISecurityDeck>[] = [];

        const addSortItemToSortResult = (item: SorterResult<ISecurityDeck>) => {
          if (item.order) {
            sortResults.push({
              field: item.field as keyof ISecurityDeck,
              descending: item.order === 'descend',
            });
          }
        };

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            addSortItemToSortResult(item);
          });
        } else {
          addSortItemToSortResult(sorter);
        }
        onSort(sortResults);
      }
    },
    [onSort]
  );

  const handleOnClickOutsideEditableRow = useCallback(async () => {
    const isSuccessEdit = await handleOnSaveEdit();
    if (!isSuccessEdit) {
      setNotificationFlatModalIsOpen(!!clickNumber);
    }
  }, [clickNumber, handleOnSaveEdit]);

  const handleOnClickOkInModal = useCallback(() => {
    setNotificationFlatModalIsOpen(false);
  }, []);

  const handleOnClickCancelInModal = useCallback(() => {
    setEditingRecord(null);
    setClickNumber(0);
    setNotificationFlatModalIsOpen(false);
  }, []);

  const getColumnSortOrder = useCallback((flatSort: IApiSortField<ISecurityDeck> | null) => {
    if (flatSort) {
      return flatSort.descending ? 'descend' : 'ascend';
    }

    return null;
  }, []);

  const columns = useMemo<ColumnsType<ISecurityDeck>>(
    () =>
      [
        {
          title: 'Подъезд',
          dataIndex: 'sectionName',
          key: 'sectionName',
          sorter: {
            multiple: 2,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'sectionName') || null) as SortOrder,
          render: (_: any, record: ISecurityDeck) => (
            <div className="editable-table__data-container">{record.sectionName}</div>
          ),
          width: '30%',
        },
        {
          title: '№ кв',
          dataIndex: 'number',
          key: 'number',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'number') || null) as SortOrder,
          render: (_: any, record: ISecurityDeck) => (
            <div className="editable-table__data-container">{record.number}</div>
          ),
          width: '10%',
        },
        {
          title: 'Перенаправление',
          dataIndex: 'isRedirectEnabled',
          key: 'isRedirectEnabled',
          render: (_: any, record: ISecurityDeck) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container">
                <Checkbox
                  checked={editingRecord.isRedirectEnabled}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'isRedirectEnabled')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_disabled">
                <Checkbox checked={record.isRedirectEnabled} />
              </div>
            ),
        },
        {
          title: (
            <div className="security-decks-table__info">
              Время смены дня
              <Tooltip
                title="Если поле не задано, то берется значение по умолчанию из блока настройки ППО"
                placement="bottom"
              >
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            </div>
          ),
          dataIndex: 'nightModeStart',
          key: 'nightModeStart',
          render: (_: any, record: ISecurityDeck) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <SingleDatePicker
                  format={dateFormatOnlyTime}
                  isDisabledStyle
                  type="time"
                  isRequired
                  isError={(clickNumber !== 0 && !editingRecord.nightModeStart) || timeError}
                  placeholder="-выберите-"
                  containerClassName="system-base-tab__picker"
                  dateValue={editingRecord.nightModeStart ? moment(editingRecord.nightModeStart, 'HH:mm') : null}
                  onChange={(value) =>
                    handleOnChangeEditingRecord(value?.format(dateFormatOnlyTime) || null, 'nightModeStart')
                  }
                />
              </div>
            ) : (
              <div
                className={`editable-table__data-container ${
                  record.isRedirectEnabled ? '' : 'editable-table__data-container_disabled'
                }`}
              >
                {record.nightModeStart}
              </div>
            ),
        },
        {
          title: (
            <div className="security-decks-table__info">
              Время смены ночи
              <Tooltip
                title="Если поле не задано, то берется значение по умолчанию из блока настройки ППО"
                placement="bottom"
              >
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            </div>
          ),
          dataIndex: 'nightModeEnd',
          key: 'nightModeEnd',
          render: (_: any, record: ISecurityDeck) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <SingleDatePicker
                  format={dateFormatOnlyTime}
                  isDisabledStyle
                  type="time"
                  isRequired
                  isError={(clickNumber !== 0 && !editingRecord.nightModeEnd) || timeError}
                  placeholder="-выберите-"
                  containerClassName="system-base-tab__picker"
                  dateValue={editingRecord.nightModeEnd ? moment(editingRecord.nightModeEnd, 'HH:mm') : null}
                  onChange={(value) =>
                    handleOnChangeEditingRecord(value?.format(dateFormatOnlyTime) || null, 'nightModeEnd')
                  }
                />
              </div>
            ) : (
              <div
                className={`editable-table__data-container ${
                  record.isRedirectEnabled ? '' : 'editable-table__data-container_disabled'
                }`}
              >
                {record.nightModeEnd}
              </div>
            ),
        },
        {
          title:
            permissions.edit && flats.length ? (
              <div className="editable-table__title-icon" role="presentation" onClick={() => setGroupModalOpen(true)}>
                <Tooltip title="Редактировать группу" placement="bottom">
                  <div>
                    <EditSquareIcon />
                  </div>
                </Tooltip>
              </div>
            ) : (
              ''
            ),
          hidden: !permissions.edit,
          render: (record: ISecurityDeck) => (
            <div className="editable-table__icon-container">
              {permissions.edit && isEditing(record) ? (
                <div
                  onClick={() => handleOnSaveEdit()}
                  role="presentation"
                  className="editable-table__icon editable-table__icon-active"
                >
                  <EditIcon />
                </div>
              ) : (
                <div onClick={() => handleOnClickEdit(record)} role="presentation" className="editable-table__icon">
                  <EditIcon />
                </div>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [
      clickNumber,
      editingRecord,
      flats.length,
      getColumnSortOrder,
      handleOnChangeEditingRecord,
      handleOnClickEdit,
      handleOnSaveEdit,
      isEditing,
      permissions.edit,
      sortOrders,
      timeError,
    ]
  );

  const data = useMemo(
    () =>
      flats.map((flat) => ({
        ...flat,
        key: flat.id,
      })),
    [flats]
  );

  return (
    <div className="editable-table security-decks-table">
      <GroupEditModal
        isSecurityDecks
        isFiveThousandth={isFiveThousandth}
        isOpen={groupModalOpen}
        onOk={() => handleOnOkModal(setGroupModalOpen)}
        onCancel={() => setGroupModalOpen(false)}
        sections={sections}
        systemId={systemId}
        buildingId={buildingId}
      />
      <NotificationModal
        isOpen={notificationFlatModalIsOpen}
        onCancel={handleOnClickCancelInModal}
        onOk={handleOnClickOkInModal}
        onClose={handleOnClickOkInModal}
      />
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        onChange={handleOnChangeTable}
        showSorterTooltip={false}
        rowClassName={(record) => (record.id === editingRecord?.id ? 'editable-table__row' : '')}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder
              text={
                <>
                  <span>Добавьте квартиры, чтобы сформировать</span>
                  <span>таблицу с настройками</span>
                </>
              }
              icon={<AddNodeIcon />}
            />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination',
        }}
      />
      <div
        role="presentation"
        onClick={handleOnClickOutsideEditableRow}
        className={classNames('editable-table__row-selected-drop', {
          'editable-table__row-selected-drop_visible': !!editingRecord,
        })}
      />
    </div>
  );
};

export default SecurityDecksTable;
