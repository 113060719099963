import { ReactNode } from 'react';
import HighPriorityIcon from '../../assets/svg/icons/highPriority';
import LowPriorityIcon from '../../assets/svg/icons/lowPriority';
import MediumPriorityIcon from '../../assets/svg/icons/mediumPriority';

export enum EFeedbackPriority {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}

export enum EHistoryStatus {
  changeCategory = 'ChangeCategory',
  changeSubject = 'ChangeSubject',
  changePriority = 'ChangePriority',
  changeStatus = 'ChangeStatus',
  setScore = 'SetScore',
  comment = 'Comment',
  changeAssignedUser = 'ChangeAssignedUser',
  new = 'New',
}

export enum EFeedbackStatus {
  new = 'New',
  inProgress = 'InProgress',
  assigned = 'Assigned',
  finished = 'Finished',
  done = 'Done',
  cancelled = 'Cancelled',
  reopened = 'Reopened',
  draft = 'Draft',
}

export interface IFeedback {
  assignedUserEmail: string;
  assignedUserFio: string;
  assignedUserId: string;
  category: string;
  createDate: string;
  displayId: number;
  id: string;
  priority: EFeedbackPriority;
  status: EFeedbackStatus;
  subject: string;
  subscriberEmail: string;
  subscriberFirstName: string;
  subscriberId: string;
  subscriberLastName: string;
  subscriberMiddleName: string;
  subscriberPhone: string;
  updateDate: string;
}

export interface IMediaData {
  extension: string;
  fileName: string;
  id: string;
  length: number;
  s3Id: string;
  url: string;
}

export interface IHistoryFeedback {
  after: string;
  before: string;
  score: number;
  comment: string;
  createTime: string;
  feedbackId: string;
  id: string;
  mediaUrls: IMediaData[];
  tag: string;
  type: EHistoryStatus;
  userEmail: string;
  userFIO: string;
  userId: string;
  feedbackPartId: string;
}

export interface IFeedbackPart {
  id: string;
  mediaUrls: IMediaData[];
  text: string;
  createDate: string;
}

export interface IFeedbackFullData {
  addressNotes: string;
  creator: string;
  category: string;
  categoryId: string;
  displayId: string;
  flatAddress: string;
  flatId: string;
  id: string;
  objectId: string;
  objectName: string;
  parts: IFeedbackPart[];
  subject: string;
  subjectId: string;
  subscriberEmail: string;
  subscriberFirstName: string;
  subscriberId: string;
  subscriberLastName: string;
  subscriberMiddleName: string;
  subscriberPhone: string;
  assignedUserEmail: string;
  assignedUserFio: string;
  assignedUserPhone: string;
  assignedUserId: string;
  createTime: string;
  priority: string;
  score: number;
  status: string;
  updateTime: string;
  executionTime: string;
}

export interface IFeedbackCategory {
  companyId: string;
  id: string;
  name: string;
  order: number;
}

export interface IFeedbackSubject {
  categoryId: string;
  companyId: string;
  id: string;
  name: string;
  order: number;
  time: number;
}

export const mapFeedbackPriorityToText = new Map<EFeedbackPriority, string>([
  [EFeedbackPriority.high, 'Высокий'],
  [EFeedbackPriority.medium, 'Средний'],
  [EFeedbackPriority.low, 'Низкий'],
]);

export const mapFeedbackPriorityToIcon = new Map<EFeedbackPriority, ReactNode>([
  [EFeedbackPriority.high, HighPriorityIcon({})],
  [EFeedbackPriority.medium, MediumPriorityIcon({})],
  [EFeedbackPriority.low, LowPriorityIcon({})],
]);

export const mapFeedbackSequenceStatuses = new Map<EFeedbackStatus, EFeedbackStatus[]>([
  [EFeedbackStatus.new, [EFeedbackStatus.cancelled, EFeedbackStatus.assigned]],
  [EFeedbackStatus.inProgress, [EFeedbackStatus.finished]],
  [EFeedbackStatus.assigned, [EFeedbackStatus.inProgress]],
  [EFeedbackStatus.finished, [EFeedbackStatus.done, EFeedbackStatus.assigned]],
  [EFeedbackStatus.done, []],
  [EFeedbackStatus.cancelled, []],
  [EFeedbackStatus.reopened, [EFeedbackStatus.new]],
]);

export const mapFeedbackStatusToText = new Map<EFeedbackStatus, string>([
  [EFeedbackStatus.new, 'Новое'],
  [EFeedbackStatus.inProgress, 'В работе'],
  [EFeedbackStatus.assigned, 'Назначено'],
  [EFeedbackStatus.done, 'Завершено'],
  [EFeedbackStatus.finished, 'Исполнено'],
  [EFeedbackStatus.cancelled, 'Отменено'],
  [EFeedbackStatus.reopened, 'Возобновлено'],
]);

export const mapFeedbackStatusToColor = new Map<EFeedbackStatus, string>([
  [EFeedbackStatus.reopened, 'blue'],
  [EFeedbackStatus.new, 'blue'],
  [EFeedbackStatus.inProgress, 'orange'],
  [EFeedbackStatus.assigned, 'orange'],
  [EFeedbackStatus.done, 'green'],
  [EFeedbackStatus.finished, 'red'],
  [EFeedbackStatus.cancelled, 'gray'],
]);

export const mapHistoryStatusToText = new Map<EHistoryStatus, string>([
  [EHistoryStatus.changeCategory, 'Смена категории'],
  [EHistoryStatus.changeSubject, 'Смена темы'],
  [EHistoryStatus.changePriority, 'Смена приоритета'],
  [EHistoryStatus.changeStatus, 'Смена статуса'],
  [EHistoryStatus.comment, 'Внутренний комментарий'],
  [EHistoryStatus.changeAssignedUser, 'Смена исполнителя'],
  [EHistoryStatus.setScore, 'Оценка'],
]);
