import React, { FC, useCallback, useEffect } from 'react';
import Checkbox from '../../../../../../ui/checkbox';
import { IBooleanField } from './types';

const BooleanField: FC<IBooleanField> = ({ object, changeParam, index, wasSelected, disabled = false }) => {
  useEffect(() => {
    if (!object.value && !object.wasChange && wasSelected) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (value: boolean) => {
      object.value = value;
      object.wasChange = true;
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  return (
    <Checkbox
      label={object.description || ''}
      checked={object.value}
      onChange={onChange}
      disabled={disabled}
      isDisabledStyle={disabled && object.readOnly}
    />
  );
};

export default BooleanField;
