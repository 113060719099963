export const shapeRectangle = [
  [0, 0],
  [60, 0],
  [60, 60],
  [0, 60],
];

export const shapeRectangleC = [
  [0, 0],
  [60, 0],
  [60, 60],
  [35, 60],
  [35, 35],
  [25, 35],
  [25, 60],
  [0, 60],
];

export const shapeR = [
  [0, 0],
  [60, 0],
  [60, 60],
  [35, 60],
  [35, 25],
  [0, 25],
];

export const shapeE = [
  [0, 0],
  [160, 0],
  [160, 60],
  [140, 60],
  [140, 20],
  [90, 20],
  [90, 60],
  [70, 60],
  [70, 20],
  [20, 20],
  [20, 60],
  [0, 60],
];
