import React, { FC, useCallback, useEffect, useRef } from 'react';
import { IRowData } from './types';
import EditIcon from '../../../../../assets/svg/icons/edit';
import CloseBigIcon from '../../../../../assets/svg/icons/closeBig';

const ObjectKeysRow: FC<IRowData> = (props) => {
  const { record = null, permissions = {}, setEditKey = () => {}, setDeleteKey = () => {} } = props;

  const rowRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  const showOrHideButtons = useCallback(
    (scroll = scrollElementRef.current) => {
      if (scroll && rowRef.current) {
        if (rowRef.current.offsetWidth < scroll.scrollWidth) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        } else {
          hideButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        }
      }
    },
    [hideButton, showButton]
  );

  useEffect(() => {
    showOrHideButtons();
  }, [record?.objectKeys]);

  const scrollEvent = useCallback(() => {
    const value = scrollElementRef.current?.scrollLeft || 0;
    const maxValue = scrollElementRef.current?.scrollWidth || 0;
    const width = scrollElementRef.current?.offsetWidth || 0;
    if (value > 50) {
      showButton(chevronLeftRef.current, chevronLefIsHidden);
    } else if (value < 50) {
      hideButton(chevronLeftRef.current, chevronLefIsHidden);
    }
    if (value + width >= maxValue - 20) {
      hideButton(chevronRightRef.current, chevronRightIsHidden);
    } else {
      showButton(chevronRightRef.current, chevronRightIsHidden);
    }
  }, [hideButton, showButton]);

  const scrollTo = useCallback(
    (toRight = true) =>
      () => {
        if (scrollElementRef.current) {
          scrollElementRef.current.scrollBy({ left: toRight ? 200 : -200, behavior: 'smooth' });
        }
      },
    []
  );

  useEffect(() => {
    if (!scrollElementRef.current && rowRef.current) {
      const chevronLeft = document.getElementById(`chevron-left-${record?.flatId}`) as HTMLDivElement;
      if (chevronLeft) {
        chevronLeftRef.current = chevronLeft;
        chevronLeftRef.current.onclick = scrollTo(false);
      }

      const chevronRight = document.getElementById(`chevron-right-${record?.flatId}`) as HTMLDivElement;
      if (chevronRight) {
        chevronRightRef.current = chevronRight;
        chevronRightRef.current.onclick = scrollTo();
      }

      const element = rowRef.current.parentNode;
      if (element) {
        scrollElementRef.current = element as HTMLDivElement;
        showOrHideButtons(scrollElementRef.current);
        scrollElementRef.current.onscroll = scrollEvent;
      }
    }
  }, [rowRef, rowRef.current]);

  return (
    <div ref={rowRef} className="editable-table__data-container object-keys-row">
      {record &&
        record.objectKeys.map((objectKey) => (
          <div className="object-keys-row__key" key={objectKey.id}>
            <div className="object-keys-row__key-content">
              <div className="object-keys-row__key-name">{objectKey.key}</div>
              <div className="object-keys-row__key-alias">{objectKey.keyAlias}</div>
            </div>
            <div className="object-keys-row__key-icons">
              {permissions.edit && (
                <span
                  className="object-keys-row__key-icon object-keys-row__key-icon_delete"
                  role="presentation"
                  onClick={() => setDeleteKey(objectKey)}
                >
                  <CloseBigIcon />
                </span>
              )}
              {permissions.edit && (
                <span
                  className="object-keys-row__key-icon object-keys-row__key-icon_edit"
                  role="presentation"
                  onClick={() => setEditKey(objectKey)}
                >
                  <EditIcon />
                </span>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ObjectKeysRow;
