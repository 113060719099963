import { IHardwareParamNumber } from '../../../../../../../typings/systems/hardwareSettings';
import { emptyFieldErrorText } from '../types';

export const getNumberFieldError = (numberFieldValue: Omit<IHardwareParamNumber, 'defaultValue'>): string => {
  if (numberFieldValue.isRequired && !numberFieldValue.value && numberFieldValue.value !== 0) {
    return emptyFieldErrorText;
  }

  const { value } = numberFieldValue;
  if (value) {
    const { minValue, maxValue } = numberFieldValue.restricts.restricts;
    const minIsError = (minValue || minValue === 0) && value < parseInt(minValue?.toString(), 10);
    const maxIsError = (maxValue || maxValue === 0) && value > parseInt(maxValue?.toString(), 10);

    if (minIsError && maxIsError) {
      return `Введите число от ${minValue} до ${maxValue}`;
    }
    if (minIsError) {
      return `Введите число от ${minValue}`;
    }
    if (maxIsError) {
      return `Введите число до ${maxValue}`;
    }
  }
  return '';
};
