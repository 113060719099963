import Paper from 'paper';
import { EPaperGroupTypes, IPathExtremes, TPaperGroup, TPaperPath, TPaperText } from '../types';
import { accessPointMinSize, accessPointMovePositionAfter, coefficientIncrease, labelMinSize } from '../zoomConfig';
import { TreeNodeType } from '../../../typings/treeNode';

export const findExtremes = (path: TPaperPath): IPathExtremes => {
  if (path) {
    const extremes: IPathExtremes = {
      maxX: path.segments[0].point.x,
      minX: path.segments[0].point.x,
      maxY: path.segments[0].point.y,
      minY: path.segments[0].point.y,
    };

    path.segments.forEach((segment) => {
      const { point } = segment;
      extremes.maxX = Math.max(extremes.maxX, point.x);
      extremes.minX = Math.min(extremes.minX, point.x);
      extremes.maxY = Math.max(extremes.maxY, point.y);
      extremes.minY = Math.min(extremes.minY, point.y);
    });

    return extremes;
  }
  return { maxX: 0, minX: 0, maxY: 0, minY: 0 };
};

export const findObjectInGroups = (group: TPaperGroup): TPaperGroup | null => {
  let foundGroup = null;
  if (group.data.groupType === EPaperGroupTypes.object) {
    foundGroup = group;
  } else if (group.children) {
    for (const child of group.children) {
      if (child.data.groupType === EPaperGroupTypes.object) {
        foundGroup = child;
        break;
      } else {
        foundGroup = findObjectInGroups(child);
        if (foundGroup) {
          break;
        }
      }
    }
  }

  return foundGroup;
};

export const updateAllChild = (group: TPaperGroup) => {
  if (group.data.groupType === EPaperGroupTypes.object) {
    group.data.updateObjectData();
  } else if (group.children) {
    group.children.forEach((child) => updateAllChild(child));
  }
};

export const checkIntersection = (parent: TPaperGroup, child: TPaperGroup) => {
  const parentPath = findObjectInGroups(parent)?.children[0] as TPaperPath;
  const childPath = findObjectInGroups(child)?.children[0] as TPaperPath;
  return parentPath && childPath && parentPath.intersects(childPath);
};

export const checkIntersectionForPath = (parent: TPaperGroup, path: TPaperPath) => {
  const parentPath = findObjectInGroups(parent)?.children[0] as TPaperPath;
  return parentPath && path && parentPath.intersects(path);
};

export const downScaleNumber = (num: number, zoom: number) =>
  (num * coefficientIncrease) / zoom > num ? num : (num * coefficientIncrease) / zoom;

export const getConvertedValue = (
  zoom: number,
  downSize: number,
  minDownSize: number,
  upSize: number,
  maxUpSize: number
) => {
  if (zoom > 1) {
    const downScaleValue = downScaleNumber(downSize, zoom);
    return downScaleValue < minDownSize ? minDownSize : downScaleValue;
  }
  const upScaleValue = upSize / zoom;
  return upScaleValue > maxUpSize ? maxUpSize : upScaleValue;
};

export const setAccessPointPosition = (point: TPaperGroup) => {
  if (
    Paper.view.zoom > accessPointMovePositionAfter &&
    point.bounds.center !== point.children[0].children[0].bounds.center
  ) {
    const offset = point.bounds.height - point.children[0].children[0].bounds.height;
    point.position = point.data.clone.children[0].children[0].position.clone().add(new Paper.Point(0, offset));
  } else {
    point.position = point.data.clone.position.clone();
  }
};

export const rescalePoint = (point: TPaperGroup, newZoom: number) => {
  if (point.data.type === TreeNodeType.accessPoint || point.data.type === TreeNodeType.camera) {
    const scaleValue =
      downScaleNumber(1, newZoom) < accessPointMinSize ? accessPointMinSize : downScaleNumber(1, newZoom);
    point.children[0].children[0].strokeWidth = downScaleNumber(2, newZoom);
    point.bounds = point.data.clone.bounds.clone();
    if (newZoom > 1) {
      point.scale(scaleValue);
    }
    setAccessPointPosition(point);
  }
};

export const rescaleLabel = (label: TPaperText, newZoom: number) => {
  const scaleValue = downScaleNumber(1, newZoom) < labelMinSize ? labelMinSize : downScaleNumber(1, newZoom);
  label.bounds = label.data.clone.bounds.clone();
  if (newZoom > 1) {
    label.scale(scaleValue);
  }
  label.position = label.data.clone.position.clone();
};
