import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IHeaderState {
  wasChange: boolean;
}

const initialState: IHeaderState = {
  wasChange: false,
};

export const headerSlice = createSlice({
  name: 'changes',
  initialState,
  reducers: {
    setChange: (state, action: PayloadAction<boolean>) => {
      state.wasChange = action.payload;
    },
  },
});

export const { setChange } = headerSlice.actions;

export default headerSlice.reducer;
