import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastOptions, toast } from 'react-toastify';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';
import { ISingleNotificationsComponent } from './types';
import { statusToColor } from '../types';
import { EDispatcherDeviceStatus } from '../../../typings/dispatcherPanel';

export const SingleNotificationComponent: FC<ISingleNotificationsComponent> = ({
  children,
  data,
  onCloseNotify = () => {},
  onClickNotify = () => {},
  showClose = true,
}) =>
  data ? (
    <div
      role="presentation"
      onClick={() => onClickNotify(data.stackId, data.id, false)}
      className="notify__message-content"
    >
      {showClose && (
        <div
          className="notify__message-close"
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            onCloseNotify(data.stackId, data.id, false);
          }}
        >
          <CloseBigIcon />
        </div>
      )}
      <div className="notify__message-title">{data.title}</div>
      <div className="notify__message-text">{data.text}</div>
      {children}
    </div>
  ) : null;

export const renderSingleNotify = (componentProps: ISingleNotificationsComponent, options: ToastOptions = {}) =>
  toast(() => <SingleNotificationComponent {...componentProps} />, {
    ...options,
    toastId: options.toastId || componentProps?.data?.id,
    autoClose: false,
    className: `notify__message notify__message_${statusToColor.get(
      componentProps.data?.status as EDispatcherDeviceStatus
    )} ${options.className || ''}`,
  });
