import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { IChangeMainInfoModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Input from '../../ui/input';
import { putRequest } from '../../../api';
import { useApi } from '../../../hooks/useApi';
import { organizationUpdateInfoUrl } from '../../../constants/api';
import { EFieldTypes } from '../../ui/input/types';
import Scrollbar from '../../ui/scrollbar';
import Message from '../../message';

const ChangeMainInfoModal: FC<IChangeMainInfoModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, company = null } = props;

  const { sendRequest: updateCompanyData, loading: updateLoading } = useApi(putRequest);

  const defaultText = useMemo(
    () => `У компании «${company?.companyName}» изменились реквизиты:`,
    [company?.companyName]
  );

  const [letter, setLetter] = useState<string>('');

  useEffect(() => {
    if (isOpen && company) {
      setLetter(defaultText);
    }
  }, [isOpen]);

  const handleOnClose = useCallback(() => onCancel(), [onCancel]);

  const handleOnSend = useCallback(async () => {
    if (company) {
      const resError = (await updateCompanyData(organizationUpdateInfoUrl(), {
        companyId: company?.id,
        message: letter,
      })) as AxiosError;
      if (!resError?.response?.data) {
        onCancel();
        Message.success({
          content: 'Заявка на редактирование данных организации отправлена',
        });
      }
    }
  }, [company, letter, onCancel, updateCompanyData]);

  return (
    <Modal
      isOpen={isOpen}
      title="Заявка на редактирование данных"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName="change-main-info__wrapper"
      footer={
        <div className="change-main-info__button-container">
          <Button loading={updateLoading} type={ButtonType.secondary} size={ButtonSize.small} onClick={handleOnClose}>
            Отмена
          </Button>
          <Button
            disabled={!letter || letter === defaultText}
            loading={updateLoading}
            size={ButtonSize.small}
            onClick={handleOnSend}
          >
            Отправить
          </Button>
        </div>
      }
    >
      <div className="change-main-info">
        <div className="change-main-info__title">
          Введите, пожалуйста, текст, который будет отправлен на электронную почту администратора платформы
        </div>
        <Scrollbar>
          <Input
            title="Текст письма"
            value={letter}
            onChange={setLetter}
            fieldType={EFieldTypes.textArea}
            autoSize={{ minRows: 5, maxRows: 5 }}
            maxLength={2000}
          />
        </Scrollbar>
      </div>
    </Modal>
  );
};

export default ChangeMainInfoModal;
