import { AxiosRequestConfig } from 'axios';
import { axiosGet, axiosPost } from '../axios';

export const getSystem = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const editSystem = async <T>(url: string, data: T) => {
  const result = await axiosPost(url, data);
  return result.data;
};
