import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import { ICategoryTableSubject } from './types';
import EditIcon from '../../../../assets/svg/icons/edit';
import TrashIcon from '../../../../assets/svg/icons/trash';
import DragAndDropIcon from '../../../../assets/svg/icons/dragAndDrop';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm, deleteModal } from '../../../ui/universalModal/config';
import { useApi } from '../../../../hooks/useApi';
import { deleteRequest } from '../../../../api';
import { feedbackCategorySubjectBaseIdUrl } from '../../../../constants/api';
import UniversalModal from '../../../ui/universalModal';

const CategoryTableSubject: FC<ICategoryTableSubject> = ({
  blockDragAndDrop = false,
  isLastElement = false,
  showIcon = true,
  subject = null,
  index = 0,
  setEditItem = () => {},
  requestSubjects = () => {},
  isSimpleMode = false,
  permissions,
}) => {
  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const { sendRequest: deleteSubject } = useApi(deleteRequest);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const onDeleteSubject = useCallback(() => {
    setConfirmData(
      deleteModal(
        'данную тему',
        async () => {
          closeConfirm();
          await deleteSubject(feedbackCategorySubjectBaseIdUrl(subject?.id || ''));
          requestSubjects();
        },
        closeConfirm
      )
    );
  }, [closeConfirm, deleteSubject, requestSubjects, subject?.id]);

  const renderQuestion = useCallback(
    () =>
      subject ? (
        <div
          className={classNames('category-table__tr', {
            'category-table__tr_last': isLastElement,
          })}
        >
          <div
            className={classNames('category-table__td', {
              'category-table__td_hide': !permissions?.edit || isSimpleMode,
            })}
          >
            {!blockDragAndDrop && showIcon && <DragAndDropIcon />}
          </div>
          <div className="category-table__td">{subject.name}</div>
          <div
            className={classNames('category-table__td', {
              'category-table__td_second': isSimpleMode || !(permissions?.edit || permissions?.delete),
            })}
          >
            {subject.time} ч
          </div>
          <div
            className={classNames('category-table__td', {
              'category-table__td_hide': isSimpleMode || !(permissions?.edit || permissions?.delete),
            })}
          >
            <div className="category-table__table-icons">
              {permissions?.edit && (
                <div
                  onClick={() => setEditItem(subject)}
                  role="presentation"
                  className="category-table__table-icon category-table__table-icon_edit"
                >
                  <EditIcon />
                </div>
              )}
              {permissions?.delete && (
                <div
                  onClick={onDeleteSubject}
                  role="presentation"
                  className="category-table__table-icon category-table__table-icon_trash"
                >
                  <TrashIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null,
    [
      subject,
      isLastElement,
      permissions?.edit,
      permissions?.delete,
      isSimpleMode,
      blockDragAndDrop,
      showIcon,
      onDeleteSubject,
      setEditItem,
    ]
  );

  if (subject)
    return (
      <>
        <UniversalModal data={confirmData} onClose={closeConfirm} />
        {blockDragAndDrop || isSimpleMode ? (
          renderQuestion()
        ) : (
          <Draggable key={subject.id} draggableId={subject.id || ''} index={index}>
            {(provided1) => (
              <div ref={provided1.innerRef} {...provided1.dragHandleProps} {...provided1.draggableProps}>
                {renderQuestion()}
              </div>
            )}
          </Draggable>
        )}
      </>
    );
  return null;
};

export default CategoryTableSubject;
