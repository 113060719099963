import { EFeedbackPriority } from '../../../typings/feedback';
import { IInputValue, defaultNotRequiredValue, defaultRequiredValue } from '../../ui/input/types';

export interface IAddAppealModal {
  isOpen?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}

export interface IAppearData {
  objectId: IInputValue;
  priority: IInputValue;
  category: IInputValue;
  subject: IInputValue;
  subscriber: IInputValue;
  address: IInputValue;
  message: IInputValue;
}

export const defaultAppearData: IAppearData = {
  objectId: { ...defaultRequiredValue },
  priority: { ...defaultRequiredValue, value: EFeedbackPriority.medium },
  category: { ...defaultRequiredValue },
  subject: { ...defaultRequiredValue },
  subscriber: { ...defaultNotRequiredValue },
  address: { ...defaultNotRequiredValue },
  message: { ...defaultRequiredValue },
};
