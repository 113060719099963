import React, { FC } from 'react';

const PaperClipIcon: FC = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2552 11.147C17.4502 10.9514 17.7668 10.951 17.9623 11.1459C18.1579 11.3409 18.1584 11.6575 17.9634 11.853L12.019 17.8157C10.4406 19.3988 7.87772 19.4027 6.2943 17.8241C4.71113 16.2409 4.71113 13.6808 6.29068 12.1012L12.5489 5.87707C13.7143 4.7181 15.5977 4.72067 16.7598 5.88283C17.9216 7.04462 17.9219 8.9282 16.7604 10.0903L10.4043 16.4497C9.65265 17.2018 8.43368 17.2021 7.6816 16.4504C6.92922 15.698 6.92714 14.4793 7.67664 13.7247L12.2385 9.13159C12.4331 8.93566 12.7497 8.93458 12.9456 9.12917C13.1416 9.32377 13.1426 9.64035 12.948 9.83628L8.38616 14.4294C8.02491 14.7931 8.02591 15.3805 8.38835 15.743C8.74998 16.1044 9.33578 16.1042 9.697 15.7428L16.0531 9.38338C16.8242 8.61183 16.8241 7.36128 16.0527 6.58993C15.2803 5.81755 14.0286 5.81584 13.2541 6.58611L7.00368 12.8024C5.80876 13.9973 5.80876 15.9244 6.99704 17.1126C8.19263 18.3046 10.1224 18.3017 11.3108 17.1096L17.2552 11.147Z"
      fill="#1444F2"
    />
  </svg>
);

export default PaperClipIcon;
