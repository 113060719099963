import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { IVolumeSettingsModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Checkbox from '../../ui/checkbox';
import { useApi } from '../../../hooks/useApi';
import { postRequest } from '../../../api';
import InputSlider from '../../ui/inputSlider';
import { userSettingsUrl } from '../../../constants/api';

const VolumeSettingsModal: FC<IVolumeSettingsModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, userSettings } = props;

  const { sendRequest: setSettings, loading: setSettingsLoading } = useApi(postRequest);

  const [volumeValue, setVolume] = useState<number>(100);
  const [disabledSound, setDisabledSound] = useState(false);

  useEffect(() => {
    if (isOpen && userSettings) {
      setVolume(userSettings.volume);
      setDisabledSound(userSettings.isVolumeDisabled);
    }
  }, [isOpen]);

  const onAccess = useCallback(async () => {
    const resError = (await setSettings(userSettingsUrl(), {
      volume: volumeValue,
      isVolumeDisabled: disabledSound,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    }
  }, [disabledSound, onOk, setSettings, volumeValue]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Настройка звука уведомлений"
      width={488}
      showCloseIcon
      wrapClassName="volume-settings-modal__wrapper"
      centered
      footer={
        <div className="volume-settings-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel} disabled={setSettingsLoading}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onAccess}>
            Применить
          </Button>
        </div>
      }
    >
      <div className="volume-settings-modal__slider">
        <InputSlider
          disabled={disabledSound}
          marks={{
            0: '0',
            10: ' ',
            20: '20',
            30: ' ',
            40: '40',
            50: ' ',
            60: '60',
            70: ' ',
            80: '80',
            90: ' ',
            100: '100',
          }}
          onChange={setVolume}
          value={volumeValue}
        />
      </div>
      <Checkbox label="Отключить звук полностью" checked={disabledSound} onChange={setDisabledSound} />
    </Modal>
  );
};

export default VolumeSettingsModal;
