import React, { FC } from 'react';

const CameraIcon: FC = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6806 21.0003C16.5295 21.0003 18.034 19.5257 18.0821 17.6885L21.1153 20.2161C21.6779 20.6849 22.3871 20.9417 23.1194 20.9417C24.8484 20.9417 26.25 19.5401 26.25 17.8111V10.5784C26.25 9.84605 25.9932 9.13687 25.5244 8.57427C24.4176 7.24604 22.4435 7.06659 21.1153 8.17344L18.0821 10.7011C18.034 8.8638 16.5295 7.38921 14.6806 7.38921H13.7401L12.567 5.04319C12.4518 4.81263 12.2161 4.66699 11.9583 4.66699H6.51389C6.13803 4.66699 5.83333 4.97169 5.83333 5.34755C5.83333 5.72341 6.13803 6.0281 6.51389 6.0281H11.5377L12.2183 7.38921H5.15278C3.27348 7.38921 1.75 8.91269 1.75 10.792V17.5975C1.75 19.4769 3.27348 21.0003 5.15278 21.0003H14.6806ZM21.9867 19.1704L18.0833 15.9177V12.4718L21.9867 9.21906C22.7374 8.59345 23.8532 8.69488 24.4788 9.44561C24.7438 9.76361 24.8889 10.1644 24.8889 10.5784V17.8111C24.8889 18.7884 24.0967 19.5806 23.1194 19.5806C22.7055 19.5806 22.3047 19.4354 21.9867 19.1704ZM16.7222 17.5975V10.792C16.7222 9.66441 15.8081 8.75033 14.6806 8.75033H13.3342C13.3247 8.75052 13.3152 8.75052 13.3058 8.75033H5.15278C4.0252 8.75033 3.11111 9.66441 3.11111 10.792V17.5975C3.11111 18.7251 4.0252 19.6392 5.15278 19.6392H14.6806C15.8081 19.6392 16.7222 18.7251 16.7222 17.5975ZM4.47222 10.792C4.47222 11.1679 4.77692 11.4725 5.15278 11.4725H9.23611C9.61197 11.4725 9.91667 11.1679 9.91667 10.792C9.91667 10.4161 9.61197 10.1114 9.23611 10.1114H5.15278C4.77692 10.1114 4.47222 10.4161 4.47222 10.792ZM9.23611 18.2781C8.86025 18.2781 8.55556 17.9734 8.55556 17.5975C8.55556 17.2217 8.86025 16.917 9.23611 16.917H10.5972C10.9731 16.917 11.2778 17.2217 11.2778 17.5975C11.2778 17.9734 10.9731 18.2781 10.5972 18.2781H9.23611ZM12.6389 17.5975C12.6389 17.9734 12.9436 18.2781 13.3194 18.2781H14.6806C15.0564 18.2781 15.3611 17.9734 15.3611 17.5975C15.3611 17.2217 15.0564 16.917 14.6806 16.917H13.3194C12.9436 16.917 12.6389 17.2217 12.6389 17.5975Z"
      fill="#1444F2"
    />
  </svg>
);

export default CameraIcon;
