import classNames from 'classnames';
import React, { FC } from 'react';
import { IWarningBlock } from './types';
import WarningIcon from '../../../assets/svg/icons/warningIcon';

const WarningBlock: FC<IWarningBlock> = (props) => {
  const { messages = [], wrapperClassName = '' } = props;

  return (
    <div className={classNames('warning-block', wrapperClassName)}>
      <div className="warning-block__icon">
        <WarningIcon />
      </div>
      <div className="warning-block__messages">
        {messages.map((item, index) => (
          <div className="warning-block__message" key={`info-${index}`}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default WarningBlock;
