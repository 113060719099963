import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useMemo } from 'react';
import moment from 'moment';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { ELoaderColor } from '../../../ui/loader/types';
import Loader from '../../../ui/loader';
import Scrollbar from '../../../ui/scrollbar';
import { IFlatsJournalTable } from './types';
import { IFlatEvent } from '../../../../typings/flat';
import { dateDefaultFormat } from '../../../../constants/date';

const FlatsJournalTable: FC<IFlatsJournalTable> = (props) => {
  const {
    journal = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    isSearch = false,
  } = props;

  const columns = useMemo<ColumnsType<IFlatEvent>>(
    () => [
      {
        title: 'Абонент',
        dataIndex: 'subscriber',
        key: 'subscriber',
        render: (_: any, record: IFlatEvent) => (
          <div className="editable-table__data-container">{`${record.lastName} ${record.firstName} ${record.middleName}`}</div>
        ),
      },
      {
        title: 'Оборудование',
        dataIndex: 'hard',
        key: 'hard',
        render: (_: any, record: IFlatEvent) => (
          <div className="editable-table__data-container">{record.deviceName}</div>
        ),
      },
      {
        title: 'Уникальный номер/Псевдоним ключа',
        dataIndex: 'key',
        key: 'key',
        render: (_: any, record: IFlatEvent) => (
          <div className="editable-table__data-container journal-table__key-container">
            <div className="journal-table__key-name">{record.keyValue}</div>
            <div className="journal-table__key-alias">{record.keyAlias}</div>
          </div>
        ),
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: (_: any, record: IFlatEvent) => (
          <div className="editable-table__data-container journal-table__date">
            {moment(record.date).format(dateDefaultFormat)}
          </div>
        ),
      },
      {
        title: 'Тип события',
        dataIndex: 'type',
        key: 'type',
        render: (_: any, record: IFlatEvent) => (
          <div className="editable-table__data-container">{record.typeDisplayName}</div>
        ),
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      journal?.map((item, index) => ({
        ...item,
        key: index,
      })),
    [journal]
  );

  return (
    <div className="journal-table editable-table">
      <Scrollbar>
        <Table
          columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          scroll={{ x: '100vh' }}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default FlatsJournalTable;
