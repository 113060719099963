import React, { FC } from 'react';

const CopyIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 14.501V6.52898C20.0084 6.39623 19.9642 6.25806 19.8536 6.14745L16.8536 3.14745C16.7429 3.03684 16.6048 2.99258 16.472 3.001H10.5C9.11929 3.001 8 4.12029 8 5.501V7.001H6.5C5.11929 7.001 4 8.12029 4 9.501V18.501C4 19.8817 5.11929 21.001 6.5 21.001H13.5C14.8807 21.001 16 19.8817 16 18.501V17.001H17.5C18.8807 17.001 20 15.8817 20 14.501ZM15 17.001V18.501C15 19.3294 14.3284 20.001 13.5 20.001H6.5C5.67157 20.001 5 19.3294 5 18.501V9.501C5 8.67257 5.67157 8.001 6.5 8.001H8V14.501C8 15.8817 9.11929 17.001 10.5 17.001H15ZM16 4.001H10.5C9.67157 4.001 9 4.67258 9 5.501V14.501C9 15.3294 9.67157 16.001 10.5 16.001H17.5C18.3284 16.001 19 15.3294 19 14.501V7.001H16.5C16.2239 7.001 16 6.77714 16 6.501V4.001ZM17 6.001H18.2929L17 4.70811V6.001ZM11 12.501C11 12.7771 11.2239 13.001 11.5 13.001H13.5C13.7761 13.001 14 12.7771 14 12.501C14 12.2249 13.7761 12.001 13.5 12.001H11.5C11.2239 12.001 11 12.2249 11 12.501ZM11.5 11.001C11.2239 11.001 11 10.7771 11 10.501C11 10.2249 11.2239 10.001 11.5 10.001H16.5C16.7761 10.001 17 10.2249 17 10.501C17 10.7771 16.7761 11.001 16.5 11.001H11.5ZM11 8.501C11 8.77714 11.2239 9.001 11.5 9.001H16.5C16.7761 9.001 17 8.77714 17 8.501C17 8.22486 16.7761 8.001 16.5 8.001H11.5C11.2239 8.001 11 8.22486 11 8.501Z"
      fill="#1444F2"
    />
  </svg>
);

export default CopyIcon;
