import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Modal from '../../../ui/modal';
import { IEditKeyModal } from './types';
import Input from '../../../ui/input';
import { IInputValue, InputStatus, defaultNotRequiredValue } from '../../../ui/input/types';

const EditKeyModal: FC<IEditKeyModal> = (props) => {
  const { isOpen = false, editKey, onCancel = () => {}, onOk = () => {} } = props;

  const [alias, setAlias] = useState<IInputValue>({ ...defaultNotRequiredValue });

  useEffect(() => {
    if (editKey) {
      setAlias({ ...defaultNotRequiredValue, value: editKey.keyAlias });
    }
  }, [editKey]);

  const onChange = useCallback((e: string) => {
    setAlias({ ...defaultNotRequiredValue, value: e });
  }, []);

  const onSave = useCallback(() => {
    if (alias.value && alias.value.trim()) {
      onOk(alias.value);
    } else {
      setAlias({ ...alias, errorText: 'Поле обязательно для заполнения', status: InputStatus.error });
    }
  }, [alias, onOk]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Изменить псевдоним ключа"
      width={416}
      showCloseIcon
      wrapClassName="edit-key-modal__wrapper"
      centered
      footer={
        <div className="edit-key-modal__button-container">
          <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onSave}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Input
        title="Псевдоним"
        isRequired
        errorText={alias.errorText}
        status={alias.status}
        value={alias.value}
        onChange={onChange}
        placeholder="Псевдоним"
        maxLength={25}
      />
    </Modal>
  );
};

export default EditKeyModal;
