import React, { FC } from 'react';

const ArrowBackIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0303 18.4697C12.3232 18.7626 12.3232 19.2374 12.0303 19.5303C11.7374 19.8232 11.2626 19.8232 10.9697 19.5303L3.96967 12.5303C3.67678 12.2374 3.67678 11.7626 3.96967 11.4697L10.9697 4.46967C11.2626 4.17678 11.7374 4.17678 12.0303 4.46967C12.3232 4.76256 12.3232 5.23744 12.0303 5.53033L6.31066 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H6.31066L12.0303 18.4697Z"
    />
  </svg>
);

export default ArrowBackIcon;
