import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getHelpEntity } from '../../../../api/help';
import ArrowBackIcon from '../../../../assets/svg/icons/arrowBack';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import CommonHead from '../../../../components/commonHead';
import ButtonLink from '../../../../components/ui/buttonLink';
import ErrorPlaceholder from '../../../../components/ui/errorPlaceholder';
import Loader from '../../../../components/ui/loader';
import { ELoaderColor } from '../../../../components/ui/loader/types';
import { getHelpFaqQuestion } from '../../../../constants/api';
import { paths } from '../../../../constants/paths';
import { useAppDispatch } from '../../../../hooks/hooks';
import { useApi } from '../../../../hooks/useApi';
import { setHeaderTitle } from '../../../../store/slices/header';
import { IFaqQuestion } from '../../../../typings/help/faq';

const FaqPageItem: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { data: question, sendRequest: requestQuestion, loading } = useApi<IFaqQuestion>(getHelpEntity);

  useEffect(() => {
    dispatch(setHeaderTitle('FAQ частые вопросы'));
  }, [dispatch]);

  useEffect(() => {
    const { questionId } = params;
    if (questionId) {
      requestQuestion(getHelpFaqQuestion(questionId));
    }
  }, []);

  return (
    <>
      <CommonHead seo={{ title: 'Частые вопросы (FAQ)' }} />
      <div className="help-faq-item">
        <div className="help-faq-item__back-button-wrapper">
          <ButtonLink url={paths.helpFaq} leftIcon={<ArrowBackIcon />} content="Список вопросов" />
        </div>
        {loading ? (
          <div className="help-faq-item__loader-wrapper">
            <Loader color={ELoaderColor.blue} />
          </div>
        ) : question ? (
          <>
            <span className="help-faq-item__title">{question?.question}</span>
            <div className="help-faq-item__body" dangerouslySetInnerHTML={{ __html: question?.answer || '' }} />
          </>
        ) : (
          <ErrorPlaceholder
            containerClassName="help-faq-item__error-wrapper"
            icon={<SearchFailIcon />}
            text="Вопрос не найден"
          />
        )}
      </div>
    </>
  );
};

export default FaqPageItem;
