import React, { FC, useCallback, useEffect, useState } from 'react';
import { IDeleteRoleModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import { deleteRequest, getRequest } from '../../../api';
import { useApi } from '../../../hooks/useApi';
import { deleteRoleUrl, roleUsersListUrl } from '../../../constants/api';

const DeleteRoleModal: FC<IDeleteRoleModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, role = null, onDeletedRole = () => {} } = props;

  const { data: users, sendRequest: getUsers } = useApi<[]>(getRequest);
  const { sendRequest: deleteRole, loading: deleteRoleLoading } = useApi<[]>(deleteRequest);

  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (isOpen && role) {
      getUsers(roleUsersListUrl(role.id));
    }
  }, [isOpen]);

  useEffect(() => {
    setCanDelete(!(users && users.length > 0));
  }, [users]);

  const handleOnClose = useCallback(() => onCancel(), [onCancel]);

  const handleOnDelete = useCallback(async () => {
    await deleteRole(deleteRoleUrl(role?.id || ''));
    onCancel();
    onDeletedRole();
  }, [deleteRole, onCancel, onDeletedRole, role]);

  return (
    <Modal
      isOpen={isOpen}
      title={
        canDelete
          ? 'Вы действительно хотите удалить роль?'
          : 'Вы не можете удалить роль, пока она назначена хотя бы одному пользователю'
      }
      centered
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName="change-main-info__wrapper"
      footer={
        <div className="change-main-info__button-container">
          {canDelete ? (
            <>
              <Button
                type={ButtonType.secondary}
                size={ButtonSize.small}
                onClick={handleOnClose}
                disabled={deleteRoleLoading}
              >
                Отмена
              </Button>
              <Button size={ButtonSize.small} onClick={handleOnDelete} disabled={deleteRoleLoading}>
                Удалить
              </Button>
            </>
          ) : (
            <Button size={ButtonSize.small} onClick={handleOnClose}>
              Ок
            </Button>
          )}
        </div>
      }
    />
  );
};

export default DeleteRoleModal;
