import React, { FC } from 'react';
import { IFeedbackPriority } from './types';
import { mapFeedbackPriorityToIcon, mapFeedbackPriorityToText } from '../../../typings/feedback';

const FeedbacksPriority: FC<IFeedbackPriority> = (props) => {
  const { priority, wrapClassName = '' } = props;

  return priority ? (
    <div className={`feedback-priority ${wrapClassName}`}>
      <div className="feedback-priority__icon">{mapFeedbackPriorityToIcon.get(priority)}</div>
      <div className="feedback-priority__label">{mapFeedbackPriorityToText.get(priority)}</div>
    </div>
  ) : null;
};

export default FeedbacksPriority;
