import { AxiosRequestConfig } from 'axios';
import { axiosGet, axiosPost } from '../axios';

export const getPaymentsData = async <T,>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const setPayments = async (url: string, data?: any, config?: AxiosRequestConfig) => {
  const result = await axiosPost(url, data, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};
