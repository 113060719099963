import DeviceBaseTab from '../../../components/deviceTabs/baseTab';
import DeviceJournalTab from '../../../components/deviceTabs/journal';
import DeviceNotificationsTab from '../../../components/deviceTabs/notifications';

import { EDeviceTabsIds } from './types';

export const deviceTabs = [
  {
    id: EDeviceTabsIds.basic,
    position: 0,
    title: 'Основное',
    component: DeviceBaseTab,
  },
  {
    id: EDeviceTabsIds.notifications,
    position: 0,
    title: 'Сообщения',
    component: DeviceNotificationsTab,
  },
  {
    id: EDeviceTabsIds.journal,
    position: 0,
    title: 'Журнал',
    component: DeviceJournalTab,
  },
];
