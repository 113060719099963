import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { getCallCodesErrors } from '../../../../api/callCode';
import { defaultResponseParser, useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Modal from '../../../ui/modal';
import { ICallCodesCheckModal, IInvalidBuilding, IInvalidCode } from './types';
import { gerCallCodesErrorsUrl } from '../../../../constants/api';
import TabsCustom from '../../../tabsCustom';
import { ICustomTab } from '../../../tabsCustom/types';
import CallCodesCheckTable from './callCodesCheckTable';

const CallCodesCheckModal: FC<ICallCodesCheckModal> = (props) => {
  const { systemId = '', isOpen = false, onClose = () => {}, buildings = [], isFiveThousandth } = props;

  const [invalidBuildings, setInvalidBuildings] = useState<IInvalidBuilding[]>([]);
  const [activeTabId, setActiveTabId] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(0);
  const [editRecord, setEditRecord] = useState<IInvalidCode | null>(null);

  const {
    data: invalidCodes,
    loading,
    sendRequest,
  } = useApi<IInvalidCode[]>(getCallCodesErrors, defaultResponseParser, false);

  useEffect(() => {
    if (isOpen) {
      sendRequest(gerCallCodesErrorsUrl(systemId));
    }
  }, [isOpen]);

  useEffect(() => {
    if (!loading && isOpen && invalidCodes && invalidCodes.length) {
      const newInvalidBuildings: IInvalidBuilding[] = [];

      invalidCodes.forEach((code) => {
        const invalidBuilding = newInvalidBuildings.find((object) => object.building.id === code.buildingId);
        if (invalidBuilding) {
          invalidBuilding.codes.push(code);
        } else {
          const building = buildings.find((object) => object.id === code.buildingId);
          if (building) {
            newInvalidBuildings.push({ building, codes: [code] });
          }
        }
      });

      if (newInvalidBuildings.length) {
        setActiveTabId(newInvalidBuildings[0].building.id);
      }
      setInvalidBuildings(newInvalidBuildings);
    }
  }, [loading]);

  const handleOnClose = useCallback(() => {
    if (!editRecord) {
      onClose();
    }
  }, [editRecord, onClose]);

  const handleOnChangeTablePage = useCallback((e: number) => {
    setCurrentPage(e - 1);
  }, []);

  const onChangeActiveTab = useCallback((id: string) => {
    setActiveTabId(id);
    setCurrentPage(0);
  }, []);

  const handleOnChangeCode = useCallback(
    (buildingIndex: number) => (index: number, data: IInvalidCode) => {
      const copyBuildings = [...invalidBuildings];
      copyBuildings[buildingIndex].codes[index] = data;
      setInvalidBuildings(copyBuildings);
    },
    [invalidBuildings]
  );

  const noErrorsFound = useMemo(
    () => !loading && (invalidCodes?.length === 0 || invalidCodes === null),
    [invalidCodes, loading]
  );

  const onEditRow = useCallback((data: IInvalidCode | null) => {
    setEditRecord(data);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      title={noErrorsFound ? 'Ошибок не найдено' : 'Ошибки кодов вызова'}
      width={noErrorsFound ? 400 : 840}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName={classNames('call-codes-check-modal__wrapper', {
        'call-codes-check-modal__wrapper-content': !noErrorsFound,
        'call-codes-check-modal__wrapper-no-errors': noErrorsFound,
      })}
      footer={
        <div className="call-codes-check-modal__button-container">
          <Button type={ButtonType.primary} size={ButtonSize.small} onClick={handleOnClose}>
            {noErrorsFound ? 'Ок' : 'Готово'}
          </Button>
        </div>
      }
    >
      <div className="call-codes-check-modal">
        {!noErrorsFound && (
          <TabsCustom
            activeTabKey={activeTabId}
            onChangeActiveTab={onChangeActiveTab}
            loading={loading}
            tabs={invalidBuildings?.map<ICustomTab>((data, index) => ({
              tabId: data.building.id,
              data: [
                { fieldTitle: 'Строение', fieldData: data.building.name },
                { fieldTitle: 'Код строения', fieldData: data.building.prefix },
                { fieldTitle: 'Кол-во подъездов', fieldData: data.building.sectionCount || '–' },
                { fieldTitle: 'Кол-во квартир', fieldData: data.building.flatCount || '–' },
              ],
              children:
                data.codes.length > 0 ? (
                  <CallCodesCheckTable
                    prefix={data.building.prefix}
                    handleOnChangeCode={handleOnChangeCode(index)}
                    callCodes={data.codes}
                    loading={loading}
                    pageSize={5}
                    isFiveThousandth={isFiveThousandth}
                    currentPage={currentPage}
                    onChangePage={handleOnChangeTablePage}
                    systemId={systemId}
                    onEditRow={onEditRow}
                    editRow={editRecord}
                  />
                ) : null,
            }))}
          />
        )}
      </div>
    </Modal>
  );
};

export default CallCodesCheckModal;
