export enum EPageQueryParams {
  prevPage = 'prevPage',
  prevPageId = 'pageId',
  prevTabId = 'prevTabId',
  tabId = 'tabId',
  needCheckErrors = 'needCheckErrors',
}

export interface IQueryParamsObject {
  prevPage?: string;
  prevPageId?: string;
  prevTabId?: string;
}
