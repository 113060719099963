import {
  activePointStyle,
  closedPathStyle,
  defaultPointStyle,
  pointInPathStyle,
  pseudoPathStyle,
  pseudoPathStyleError,
} from '../../../../../../constants/paper';
import { TreeNodeType } from '../../../../../../typings/treeNode';
import { getPointsGroup } from '../../../../calculations';
import { TPaperCircle, TPaperEvent, TPaperPath, TPaperPoint } from '../../../../types';
import { createCircles, getPath, getPseudoLine } from '../../../paperItems/items';
import { BaseCreator } from '..';
import { ECursors } from '../../../paperItems/types';
import { findObjectInGroups } from '../../../common';

export class FreeContour extends BaseCreator {
  protected pointUnderCursor: TPaperCircle | null = null;

  override start = () => {
    this.setCopyActiveGroup(this.group, this.contourClickOnCopyObject);
    this.currentPath = getPath();
    this.createContourPoints();
  };

  protected createContourPoints = () => {
    this.points = createCircles(getPointsGroup(this.group, TreeNodeType.section), {});
    this.points.forEach((point) => {
      point.onMouseEnter = () => {
        this.setCursor(ECursors.pointer);
        this.pointUnderCursor = point;
      };
      point.onMouseLeave = () => {
        this.setCursor(ECursors.default);
        this.pointUnderCursor = null;
      };
      point.onClick = this.onPointClick(point);
      point.data = { type: 'original' };
    });
  };

  protected setActivePoint = (point: TPaperCircle) => {
    if (this.activePoint) {
      this.activePoint.set(this.activePoint.data.inPath ? pointInPathStyle : defaultPointStyle);
    }
    this.activePoint = point;
    this.activePoint.set(activePointStyle);
  };

  protected deactivatePoint = (point = this.activePoint) => {
    if (point) {
      point.set(point.data.inPath ? pointInPathStyle : defaultPointStyle);
      if (this.pseudoObject) {
        this.pseudoObject.remove();
      }
    }
  };

  protected onDeletePointInPath = (point: TPaperCircle) => {
    if (this.currentPath) {
      this.currentPath.segments = this.currentPath.segments.filter(
        (segment) => !(segment.point.x === point.position.x && segment.point.y === point.position.y)
      );
      if (this.currentPath.segments.length < 3) {
        this.currentPath.closed = false;
      }
      this.setPath(this.currentPath);
      if (this.activePoint === point) {
        const lastSegment = this.currentPath.segments[this.currentPath.segments.length - 1];
        if (lastSegment) {
          const newActivePoint =
            this.points.find(
              (item) => item.position.x === lastSegment.point.x && item.position.y === lastSegment.point.y
            ) || null;
          if (newActivePoint) {
            this.setActivePoint(newActivePoint);
          }
        } else {
          this.activePoint = null;
        }
      }
    }
    if (this.pseudoObject) {
      this.pseudoObject.remove();
      this.pseudoObject = null;
    }
    if (point.data.type !== 'original') {
      this.points = this.points.filter((item) => item !== point);
      point.remove();
    } else {
      point.data.inPath = false;
      this.deactivatePoint(point);
    }
  };

  protected checkIsStart = (point: TPaperCircle) =>
    !!this.currentPath &&
    this.currentPath.segments[0].point.x === point.position.x &&
    this.currentPath.segments[0].point.y === point.position.y;

  protected addPointInPath = (point: TPaperCircle) => {
    if (this.currentPath) {
      if (this.currentPath.segments.length && this.checkIsStart(point)) {
        this.currentPath.insert(0, point.position);
      } else {
        this.currentPath.add(point.position);
      }
      point.set(pointInPathStyle);
      point.data.inPath = true;
    }
  };

  protected onPointClick = (point: TPaperCircle) => (e: any) => {
    if (e.event.which === 3 && point.data.inPath) {
      this.setMenuItems([
        {
          key: 'delete-point',
          label: 'Удалить',
          onClick: () => this.onDeletePointInPath(point),
        },
      ]);
    } else if (this.activePoint) {
      if (this.currentPath && this.canBeCreated) {
        if (this.pseudoObject) {
          this.pseudoObject.remove();
        }
        if (this.currentPath.segments.length === 0) {
          this.addPointInPath(this.activePoint);
        }
        if (this.currentPath.segments.length > 2 && this.checkIsStart(point)) {
          this.currentPath.closed = true;
          this.currentPath.add(point.position);
          this.currentPath.set(closedPathStyle);
        } else if (!point.data.inPath) {
          this.addPointInPath(point);
          this.setActivePoint(point);
        }
        this.setPath(this.currentPath, this.wasClosed);
        if (this.currentPath.closed) {
          this.deactivatePoint();
          this.wasClosed = true;
        }
      }
    } else {
      this.setActivePoint(point);
    }
  };

  protected contourClickOnCopyObject = (e: any) => {
    if (
      e.delta.x === 0 &&
      e.delta.y === 0 &&
      e.event.which === 1 &&
      (!this.pseudoObject || (this.pseudoObject && this.canBeCreated))
    ) {
      const circle = createCircles([e.point], {
        onMouseEnter: () => this.setCursor(ECursors.pointer),
        onMouseLeave: () => this.setCursor(ECursors.default),
      })[0];
      circle.onClick = this.onPointClick(circle);
      this.points.push(circle);
      this.onPointClick(circle)(e);
    }
  };

  protected checkCanCreate = (point: TPaperPoint) => {
    if (this.copyActiveGroup && this.pseudoObject) {
      this.canBeCreated = this.copyActiveGroup.contains(point);
      if (this.canBeCreated) {
        const parentPath = findObjectInGroups(this.copyActiveGroup)?.children[0] as TPaperPath;
        if (parentPath) {
          this.canBeCreated = parentPath.getCrossings(this.pseudoObject).length === 0;
        }
      }
    }
  };

  override mouseMove = (event: TPaperEvent) => {
    if (this.activePoint && !this.wasClosed) {
      if (this.pseudoObject) {
        this.pseudoObject.remove();
      }
      if (this.copyActiveGroup && !this.copyActiveGroup.data.blocked) {
        const point = this.pointUnderCursor ? this.pointUnderCursor.position : event.point;

        this.checkCanCreate(point);

        this.pseudoObject = getPseudoLine(
          this.activePoint.position,
          point,
          { onMouseEnter: () => this.setCursor(ECursors.crosshair) },
          this.canBeCreated ? pseudoPathStyle : pseudoPathStyleError
        );

        if (this.pointUnderCursor) {
          this.pointUnderCursor.bringToFront();
        }
      }
    }
  };
}
