import { Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import SearchFailIcon from '../../assets/svg/icons/searchFail';
import { ELoaderColor } from '../ui/loader/types';
import Loader from '../ui/loader';
import { IPaymentsTable } from './types';
import { IApiSortField } from '../../typings/api';
import { IPayment, paymentStatusNamesMap } from '../../typings/payments';
import { paths } from '../../constants/paths';
import { dateDefaultFormat } from '../../constants/date';
import Scrollbar from '../ui/scrollbar';
import { EPageQueryParams } from '../../typings/searchParams';

const PaymentsTable: FC<IPaymentsTable> = (props) => {
  const {
    path,
    pageId,
    tabId,
    redirectAbility = true,
    payments = [],
    isAdmin = false,
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    availableCols = [
      'companyName',
      'objectName',
      'address',
      'fio',
      'phone',
      'amount',
      'paymentCreateDate',
      'paymentStatus',
      'companyName',
    ],
  } = props;

  const navigate = useNavigate();

  const handleOnChangeTable: TableProps<IPayment>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IPayment> | SorterResult<IPayment>[],
      extra: TableCurrentDataSource<IPayment>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IPayment>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IPayment,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IPayment,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IPayment> | null) => (flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null),
    []
  );

  const onRowClick = useCallback(
    (id: string) => {
      if (redirectAbility) {
        navigate({
          pathname: `${paths.mpManagementPayments}/${id}`,
          search: `?${createSearchParams({
            [EPageQueryParams.prevPage]: path || '',
            [EPageQueryParams.prevPageId]: pageId || '',
            [EPageQueryParams.prevTabId]: tabId || '',
          })}`,
        });
      }
    },
    [navigate, pageId, path, redirectAbility, tabId]
  );

  const columns = useMemo<ColumnsType<IPayment>>(
    () =>
      [
        {
          hidden: !isAdmin,
          title: 'Организация',
          dataIndex: 'companyName',
          key: 'companyName',
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container">{record.companyName}</div>
          ),
        },
        {
          title: (
            <>
              ID <br /> транзакции
            </>
          ),
          dataIndex: 'paymentId',
          key: 'paymentId',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'paymentId') || null) as SortOrder,
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container">{record.paymentId}</div>
          ),
        },
        {
          title: 'Объект',
          dataIndex: 'objectName',
          key: 'objectName',
          sorter: {
            multiple: 2,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'objectName') || null) as SortOrder,
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container">{record.objectName}</div>
          ),
        },
        {
          title: 'Адрес',
          dataIndex: 'address',
          key: 'address',
          sorter: {
            multiple: 3,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'address') || null) as SortOrder,
          render: (_: any, record: IPayment) => <div className="editable-table__data-container">{record.address}</div>,
        },
        {
          title: 'ФИО',
          dataIndex: 'fio',
          key: 'fio',
          sorter: {
            multiple: 4,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'fio') || null) as SortOrder,
          render: (_: any, record: IPayment) => <div className="editable-table__data-container">{record.fio}</div>,
        },
        {
          title: 'Телефон',
          dataIndex: 'phone',
          key: 'phone',
          render: (_: any, record: IPayment) => <div className="editable-table__data-container">{record.phone}</div>,
        },
        {
          title: 'Сумма',
          dataIndex: 'amount',
          key: 'amount',
          sorter: {
            multiple: 5,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'amount') || null) as SortOrder,
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container">{record.amount?.toFixed(2)}</div>
          ),
        },
        {
          title: 'Дата создания',
          dataIndex: 'paymentCreateDate',
          key: 'paymentCreateDate',
          sorter: {
            multiple: 6,
          },
          sortOrder: getColumnSortOrder(
            sortOrders.find((item) => item.field === 'paymentCreateDate') || null
          ) as SortOrder,
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container">
              {record.paymentCreateDate ? moment(record.paymentCreateDate).format(dateDefaultFormat) : '-'}
            </div>
          ),
        },
        {
          title: 'Дата изменения',
          dataIndex: 'paymentUpdateDate',
          key: 'paymentUpdateDate',
          sorter: {
            multiple: 6,
          },
          sortOrder: getColumnSortOrder(
            sortOrders.find((item) => item.field === 'paymentUpdateDate') || null
          ) as SortOrder,
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container">
              {record.paymentUpdateDate ? moment(record.paymentUpdateDate).format(dateDefaultFormat) : '-'}
            </div>
          ),
        },
        {
          title: 'Метод оплаты',
          dataIndex: 'card',
          key: 'card',
          // sorter: {
          //   multiple: 5,
          // },
          // sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'card') || null) as SortOrder,
          render: (_: any, record: IPayment) => <div className="editable-table__data-container">Карта</div>,
        },
        {
          title: 'Статус',
          dataIndex: 'paymentStatus',
          key: 'paymentStatus',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'paymentStatus') || null) as SortOrder,
          render: (_: any, record: IPayment) => (
            <div className="editable-table__data-container ">
              <div className={`payments-table__status payments-table__status_${record.paymentStatus}`}>
                {record.paymentStatus && paymentStatusNamesMap.get(record.paymentStatus)}
              </div>
            </div>
          ),
        },
      ].filter((item) => !item.hidden && availableCols.includes(item.key)),
    [availableCols, getColumnSortOrder, isAdmin, sortOrders]
  );

  const data = useMemo(
    () =>
      payments.map((item, index) => ({
        ...item,
        key: index,
      })),
    [payments]
  );

  return (
    <div className="payments-table editable-table">
      <Scrollbar>
        <Table
          onRow={(record) => ({
            onClick: () => onRowClick(record.paymentId || ''),
          })}
          columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          scroll={{ x: '100vh' }}
          onChange={handleOnChangeTable}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default PaymentsTable;
