import { IMailingRecipient } from '../../typings/mailing';
import { IMailingRecipientApiSettings } from './types';

export const MAILING_TABLE_COUNT = 10;

export const getMailingRecipientsApiSettings = (
  defaultSortField: string
): IMailingRecipientApiSettings<IMailingRecipient> => ({
  sortFields: defaultSortField
    ? [
        {
          field: defaultSortField as keyof IMailingRecipient,
          descending: true,
        },
      ]
    : [],
  page: 0,
  pageSize: MAILING_TABLE_COUNT,
  search: '',
});
