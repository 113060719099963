import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/es/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { addKeyInObject, removeKeyInObject } from '../../../../api/objectKeys';
import AddNodeIcon from '../../../../assets/svg/icons/addNote';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import InfoIcon from '../../../../assets/svg/icons/info';
import PlusIcon from '../../../../assets/svg/icons/plus';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { createObjectKeyUrl, getObjectFlatKeyUrl, updateObjectKeyUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { IApiSortField } from '../../../../typings/api';
import { IObjectKey, IObjectKeysFlat } from '../../../../typings/systems/objectKeys';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Tooltip from '../../../ui/tooltip';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import ConfirmRemoveModal from '../confirmRemove';
import CreateNotificationModal from '../createNotification';
import { IObjectKeysTable } from './types';
import ObjectKeysRow from './objectKeysRow';
import EditKeyModal from '../editKeyModal';
import { putRequest } from '../../../../api';
import { ESystemDeviceMode } from '../../../../typings/systems/base';

const ObjectKeysTable: FC<IObjectKeysTable> = (props) => {
  const {
    objectKeys = [],
    system,
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    systemId = '',
    onUpdateData = () => {},
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    permissions = {},
    isValidSecurityKey = false,
    onRequestGenSecurityKey = () => {},
  } = props;

  const [securityKeysNeedModalIsOpen, setSecurityKeysNeedModalIsOpen] = useState<boolean>(false);
  const [securityKeysProcessModalIsOpen, setSecurityKeysProcessModalIsOpen] = useState<boolean>(false);
  const [createNotifyIsOpen, setCreateNotifyIsOpen] = useState<boolean>(false);

  const [deleteKey, setDeleteKey] = useState<IObjectKey | null>(null);
  const [editKey, setEditKey] = useState<IObjectKey | null>(null);

  const { sendRequest: createKey } = useApi(addKeyInObject);
  const { sendRequest: removeKey } = useApi(removeKeyInObject);
  const { sendRequest: updateKey } = useApi(putRequest);

  const handleOnChangeTable: TableProps<IObjectKeysFlat>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IObjectKeysFlat> | SorterResult<IObjectKeysFlat>[],
      extra: TableCurrentDataSource<IObjectKeysFlat>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IObjectKeysFlat>[] = [];

        const addSortItemToSortResult = (item: SorterResult<IObjectKeysFlat>) => {
          if (item.order) {
            sortResults.push({
              field: item.field as keyof IObjectKeysFlat,
              descending: item.order === 'descend',
            });
          }
        };

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            addSortItemToSortResult(item);
          });
        } else {
          addSortItemToSortResult(sorter);
        }
        onSort(sortResults);
      }
    },
    [onSort]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IObjectKeysFlat> | null) =>
      flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null,
    []
  );

  const addNewKey = useCallback(
    (record: IObjectKeysFlat) => async () => {
      if (system?.secureKeyMode === ESystemDeviceMode.EMF ? isValidSecurityKey : true) {
        setCreateNotifyIsOpen(true);
        await createKey(createObjectKeyUrl(systemId, record.flatId));
        onUpdateData();
      } else {
        setSecurityKeysNeedModalIsOpen(true);
      }
    },
    [createKey, isValidSecurityKey, onUpdateData, system?.secureKeyMode, systemId]
  );

  const onDeleteKey = useCallback(async () => {
    if (deleteKey) {
      await removeKey(getObjectFlatKeyUrl(systemId, deleteKey.id));
      setDeleteKey(null);
      onUpdateData();
    }
  }, [deleteKey, removeKey, systemId, onUpdateData]);

  const onEditKey = useCallback(
    async (keyAlias: string) => {
      if (editKey) {
        await updateKey(updateObjectKeyUrl(systemId), { ...editKey, keyAlias });
        setEditKey(null);
        onUpdateData();
      }
    },
    [editKey, updateKey, systemId, onUpdateData]
  );

  const handleOnCloseNotifyModal = useCallback(async () => {
    setCreateNotifyIsOpen(false);
    onUpdateData();
  }, [onUpdateData]);

  const handleOnCloseSecurityKeysProcessModal = useCallback(() => {
    setSecurityKeysProcessModalIsOpen(false);
    onUpdateData();
  }, [onUpdateData]);

  const handleOnOkSecurityKeysModal = useCallback(() => {
    setSecurityKeysNeedModalIsOpen(false);
    onRequestGenSecurityKey();
    setSecurityKeysProcessModalIsOpen(true);
  }, [onRequestGenSecurityKey]);

  const columns = useMemo<ColumnsType<IObjectKeysFlat>>(
    () =>
      [
        {
          title: 'Подъезд',
          dataIndex: 'sectionName',
          key: 'sectionName',
          width: '8%',
          sorter: {
            multiple: 2,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'sectionName') || null) as SortOrder,
          render: (_: any, record: IObjectKeysFlat) => (
            <div className="editable-table__data-container">{record.sectionName}</div>
          ),
        },
        {
          title: 'Номер квартиры',
          dataIndex: 'flatNumber',
          key: 'flatNumber',
          width: '8%',
          render: (_: any, record: IObjectKeysFlat) => (
            <div className="editable-table__data-container">{record.flatNumber}</div>
          ),
        },
        {
          title: 'Выделено ключей',
          dataIndex: 'allocatedKeys',
          key: 'allocatedKeys',
          width: '8%',
          render: (_: any, record: IObjectKeysFlat) => (
            <div className="editable-table__data-container">{record.allocatedKeys || ''}</div>
          ),
        },
        {
          title: 'Введено ключей',
          dataIndex: 'enteredKeys',
          key: 'enteredKeys',
          width: '8%',
          render: (_: any, record: IObjectKeysFlat) => (
            <div className="editable-table__data-container">
              <div
                className={classNames('object-keys__cell-custom', {
                  'object-keys__cell-custom_bold': record.enteredKeys > record.allocatedKeys,
                })}
              >
                {record.enteredKeys || '0'}
                {record.enteredKeys > record.allocatedKeys && (
                  <Tooltip
                    title="Введено больше ключей, чем выделено"
                    placement="bottom"
                    className="object-keys__tooltip"
                  >
                    <div className="object-keys__icon">
                      <InfoIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            </div>
          ),
        },
        {
          title: '',
          dataIndex: 'leftArrow',
          key: 'leftArrow',
          width: '1%',
          className: 'object-keys__arrow-col',
          render: (_: any, record: IObjectKeysFlat) => (
            <div
              id={`chevron-left-${record.flatId}`}
              className="tabs__tabs-button tabs__tabs-button_left tabs__tabs-button_hide"
            >
              <ChevronLeft />
            </div>
          ),
        },
        {
          title: (
            <>
              Уникальный номер ключа/ <br /> Псевдоним ключа
            </>
          ),
          dataIndex: 'objectKeys',
          key: 'objectKeys',
          width: '50%',
          className: 'default-scrollbar-override object-keys__keys-column',
          render: (_: any, record: IObjectKeysFlat) => (
            <ObjectKeysRow
              record={record}
              permissions={permissions}
              setEditKey={setEditKey}
              setDeleteKey={setDeleteKey}
            />
          ),
        },
        {
          width: '14%',
          hidden: !permissions.edit,
          render: (record: IObjectKeysFlat) => (
            <div className="object-keys__last-col">
              <div
                id={`chevron-right-${record.flatId}`}
                className="tabs__tabs-button tabs__tabs-button_right tabs__tabs-button_hide"
              >
                <ChevronRight />
              </div>
              <div className="object-keys__icon-container">
                <span onClick={addNewKey(record)} role="presentation">
                  <PlusIcon />
                </span>
              </div>
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [addNewKey, getColumnSortOrder, permissions, sortOrders]
  );

  const data = useMemo(
    () =>
      objectKeys.map((objectKey) => ({
        ...objectKey,
        key: objectKey.flatId,
      })),
    [objectKeys]
  );

  return (
    <div className="editable-table object-keys">
      <ConfirmRemoveModal isOpen={!!deleteKey} onOk={onDeleteKey} onCancel={() => setDeleteKey(null)} />
      <EditKeyModal editKey={editKey} isOpen={!!editKey} onOk={onEditKey} onCancel={() => setEditKey(null)} />
      <CreateNotificationModal
        title="Запущен процесс генерации ключа. Следуйте указаниям в приложении для работы со считывателем"
        isOpen={createNotifyIsOpen}
        onCancel={handleOnCloseNotifyModal}
        onOk={handleOnCloseNotifyModal}
      />
      <CreateNotificationModal
        title="Запущен процесс генерации ключей безопасности. Следуйте указаниям в приложении для работы со считывателем"
        isOpen={securityKeysProcessModalIsOpen}
        onCancel={handleOnCloseSecurityKeysProcessModal}
        onOk={handleOnCloseSecurityKeysProcessModal}
      />
      <CreateNotificationModal
        title="Для того, чтобы сгенерировать квартирные ключи, сначала сгенерируйте ключи безопасности."
        isOpen={securityKeysNeedModalIsOpen}
        onCancel={() => setSecurityKeysNeedModalIsOpen(false)}
        buttonText="Сгенерировать"
        onOk={handleOnOkSecurityKeysModal}
      />
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        onChange={handleOnChangeTable}
        showSorterTooltip={false}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder
              text={
                <>
                  <span>Добавьте квартиры, чтобы сформировать</span>
                  <span>таблицу с настройками</span>
                </>
              }
              icon={<AddNodeIcon />}
            />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination',
        }}
      />
    </div>
  );
};

export default ObjectKeysTable;
