import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import ArrowBackIcon from '../../../../assets/svg/icons/arrowBack';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import CommonHead from '../../../../components/commonHead';
import ButtonLink from '../../../../components/ui/buttonLink';
import ErrorPlaceholder from '../../../../components/ui/errorPlaceholder';
import Loader from '../../../../components/ui/loader';
import { ELoaderColor } from '../../../../components/ui/loader/types';
import { paths } from '../../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import { useApi } from '../../../../hooks/useApi';
import { setHeaderTitle } from '../../../../store/slices/header';
import { IPayment, paymentStatusNamesMap } from '../../../../typings/payments';
import { getPaymentsData } from '../../../../api/payments';
import { paymentUrl } from '../../../../constants/api';
import Button from '../../../../components/ui/button';
import { ButtonSize, ButtonType } from '../../../../components/ui/button/types';
import { dateDefaultFormat } from '../../../../constants/date';
import { getProfilePermission } from '../../../../store/selectors/profile';
import InformationBlock from '../../../../components/ui/informationBlock';
import { ESidebarItemIds } from '../../../../typings/sidebar';
import { EPageQueryParams, IQueryParamsObject } from '../../../../typings/searchParams';
import { getFullPath } from '../../../../functions';

const PaymentInfo: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data: payment, sendRequest: getPayment, loading: paymentLoading } = useApi<IPayment>(getPaymentsData);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.payments));

  const [queryParams, setQueryParams] = useState<IQueryParamsObject>({ prevPage: paths.mpManagementPayments });

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(setHeaderTitle('Информация о платеже'));
  }, [dispatch]);

  useEffect(() => {
    const { paymentId } = params;
    if (paymentId) {
      getPayment(paymentUrl(paymentId));
    }
    const prev = searchParams.get(EPageQueryParams.prevPage);
    if (prev) {
      setQueryParams({
        prevPage: prev || '',
        prevPageId: searchParams.get(EPageQueryParams.prevPageId) || '',
        prevTabId: searchParams.get(EPageQueryParams.prevTabId) || '',
      });
    }
  }, []);

  const goToSubscription = useCallback(
    (id: string) => () => {
      navigate({
        pathname: `${paths.mpManagementSubscriptions}/${id}`,
        search: `?${createSearchParams({
          [EPageQueryParams.prevPage]: paths.mpManagementPayments || '',
          [EPageQueryParams.prevPageId]: params.paymentId || '',
        })}`,
      });
    },
    [navigate, params.paymentId]
  );

  const backLabel = useMemo(
    () =>
      queryParams.prevPage === paths.mpManagementPayments
        ? 'Все платежи'
        : queryParams.prevPage === paths.mpManagementSubscriptions
        ? 'К подписке'
        : queryParams.prevPage === paths.flats
        ? 'К квартире'
        : '',
    [queryParams]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Информация о платеже' }} />
      <div className="payment">
        <div className="payment__back-button-wrapper">
          <ButtonLink url={getFullPath(queryParams)} leftIcon={<ArrowBackIcon />} content={backLabel} />
        </div>
        {paymentLoading ? (
          <div className="payment__loader-wrapper">
            <Loader color={ELoaderColor.blue} />
          </div>
        ) : payment ? (
          <div className="payment__content">
            <InformationBlock
              data={[
                [
                  {
                    title: 'Основные данные',
                    items: [
                      {
                        name: 'Организация',
                        value: payment?.companyName || '',
                      },
                      {
                        name: 'Объект',
                        value: payment?.objectName || '',
                      },
                      {
                        name: 'Адрес',
                        value: payment?.address || '',
                      },
                      {
                        name: 'ФИО',
                        value: payment?.fio || '',
                      },
                      {
                        name: 'Телефон',
                        value: payment?.phone || '',
                      },
                      {
                        name: 'E-mail',
                        value: payment?.email || '',
                      },
                    ],
                  },
                ],
              ]}
            />
            <InformationBlock
              data={[
                [
                  {
                    title: 'Информация о подписке',
                    items: [
                      {
                        name: 'Наименование',
                        value: `${payment.tariff} (${payment.monthInTariff} мес.)`,
                      },
                      {
                        name: 'Дата создания',
                        value: payment.subscriptionCreateDate
                          ? moment(payment.subscriptionCreateDate).format(dateDefaultFormat)
                          : '-',
                      },
                      {
                        name: 'Итоговая сумма',
                        value: payment.amount?.toFixed(2) || '',
                      },
                      {
                        name: 'Дата следующего платежа',
                        value: payment.nextPaymentDate
                          ? moment(payment.nextPaymentDate).format(dateDefaultFormat)
                          : '-',
                      },
                    ],
                  },
                ],
              ]}
            >
              {permissions?.view && (
                <Button
                  size={ButtonSize.small}
                  onClick={goToSubscription(payment.subscriptionId || '')}
                  type={ButtonType.outline}
                >
                  Подробнее о подписке
                </Button>
              )}
            </InformationBlock>

            <InformationBlock
              data={[
                [
                  {
                    title: 'Данные платежа',
                    items: [
                      {
                        name: 'ID транзакции',
                        value: payment?.paymentId || '',
                      },
                      {
                        name: 'Статус',
                        value: (payment.paymentStatus && paymentStatusNamesMap.get(payment.paymentStatus)) || '',
                      },
                      {
                        name: 'Сумма',
                        value: payment.amount?.toFixed(2) || '',
                      },
                      {
                        name: 'Сумма возврата',
                        value: payment.refundAmount.toFixed(2) || '',
                      },
                      {
                        name: 'Дата создания',
                        value: payment.paymentCreateDate
                          ? moment(payment.paymentCreateDate).format(dateDefaultFormat)
                          : '-',
                      },
                      {
                        name: 'Дата изменения',
                        value: payment.paymentUpdateDate
                          ? moment(payment.paymentUpdateDate).format(dateDefaultFormat)
                          : '-',
                      },
                      {
                        name: 'Метод оплаты',
                        value: 'Карта',
                      },
                      {
                        name: 'Реквизиты',
                        value: payment.cardNumber || '',
                      },
                      {
                        name: 'Код ошибки',
                        value: payment.providerErrorStatusCode || '',
                      },
                      {
                        name: 'Описание ошибки',
                        value: payment.providerErrorDetail || '',
                      },
                      {
                        name: 'Ссылка на оплату',
                        value: payment.providerPaymentUrl || '',
                      },
                    ],
                  },
                ],
              ]}
            />
          </div>
        ) : (
          <ErrorPlaceholder
            containerClassName="payment__error-wrapper"
            icon={<SearchFailIcon />}
            text="Платеж не найден"
          />
        )}
      </div>
    </>
  );
};

export default PaymentInfo;
