export const dateISOFormatNoTimeZone = 'YYYY-MM-DDTHH:mm:ss';

export const dateDefaultFormat = 'DD.MM.YYYY HH:mm';

export const dateFormatNoTime = 'DD.MM.YYYY';

export const dateFormatNoYear = 'DD.MM';

export const dateFormatNoMinute = 'DD.MM.YYYY HH:00';

export const dateFormatOnlyTime = 'HH:mm';
