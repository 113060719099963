import { IDispatcherPanelDevice } from '../../typings/dispatcherPanel';
import { IDispatcherPanelApiSettings } from './types';

export const DISPATCHER_TABLE_COUNT = 10;

export const getDispatcherPanelApiSettings = (): IDispatcherPanelApiSettings<IDispatcherPanelDevice> => ({
  sortFields: [],
  page: 0,
  pageSize: DISPATCHER_TABLE_COUNT,
  search: '',
});
