import { EMailingTypes } from '../../../../../typings/mailing';

export interface IRecipientsModal {
  mailingType?: EMailingTypes;
  isOpen?: boolean;
  onCancel?: () => void;
  saveData?: (objectList: string[], subscriberList: string[]) => void;
}

export enum ERecipientsTabs {
  objects = 'objects',
  subscribers = 'subscribers',
}
