import { Checkbox, Dropdown, Menu, Table, TableProps } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import moment from 'moment';
import classNames from 'classnames';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import { paths } from '../../../constants/paths';
import { IFeedbackTable } from './types';
import { IApiSortField } from '../../../typings/api';
import { EFeedbackPriority, EFeedbackStatus, IFeedback } from '../../../typings/feedback';
import { dateDefaultFormat } from '../../../constants/date';
import SettingsIcon from '../../../assets/svg/icons/settings';
import { useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import FeedbacksPriority from '../feedbackPriority';
import FeedbackStatus from '../feedbackStatus';
import CloseIcon from '../../../assets/svg/icons/close';
import { ISettingColumn } from '../../subscriptionsTable/types';

const FeedbacksTable: FC<IFeedbackTable> = (props) => {
  const {
    onSort = () => {},
    feedbacks = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    isSearch = false,
    sortOrders = [],
    showSetting = true,
  } = props;

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.feedback));
  const subscriberPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));

  const navigate = useNavigate();

  const items: ISettingColumn[] = useMemo(
    () => [
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: IFeedback) => <div className="editable-table__data-container">{record.displayId}</div>,
        show: true,
        key: 'displayId',
        label: 'ID',
        isSort: true,
        sortIndex: 0,
        width: '10%',
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: any) => {
          const isEmpty = !record.subscriberFirstName && !record.subscriberLastName;
          return (
            <div className="editable-table__data-container feedback-table__user">
              <div
                role="presentation"
                onClick={(e) => {
                  if (record.subscriberPermissions?.view && !isEmpty) {
                    e.stopPropagation();
                    navigate(`${paths.subscribers}/${record.subscriberId}`);
                  }
                }}
                className={`feedback-table__user-fio ${
                  record.subscriberPermissions?.view && !isEmpty ? 'feedback-table__user-fio_active' : ''
                }`}
              >
                {isEmpty ? '-' : `${record.subscriberFirstName || ''} ${record.subscriberLastName || ''}`}
              </div>
              <div className="feedback-table__user-phone">{record.subscriberPhone}</div>
            </div>
          );
        },
        show: true,
        key: 'subscriber',
        label: 'Абонент',
        isSort: false,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: IFeedback) => (
          <div className="editable-table__data-container">
            {moment(record.createDate || '').format(dateDefaultFormat)}
          </div>
        ),
        show: true,
        key: 'createDate',
        label: 'Дата создания',
        isSort: true,
        sortIndex: 1,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: IFeedback) => <div className="editable-table__data-container">{record.category}</div>,
        show: true,
        key: 'category',
        label: 'Категория',
        isSort: true,
        sortIndex: 2,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: IFeedback) => (
          <FeedbacksPriority
            priority={record.priority as EFeedbackPriority}
            wrapClassName="editable-table__data-container feedback-table__nowrap"
          />
        ),
        show: true,
        key: 'priority',
        label: 'Приоритет',
        isSort: true,
        sortIndex: 3,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: IFeedback) => (
          <div className="editable-table__data-container">
            <FeedbackStatus status={record.status as EFeedbackStatus} />
          </div>
        ),
        show: true,
        key: 'status',
        label: 'Статус',
        isSort: true,
        sortIndex: 4,
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        getData: (record: IFeedback) => (
          <div className="editable-table__data-container feedback-table__user">
            <div className="feedback-table__user-fio">{record.assignedUserFio}</div>
            <div className="feedback-table__user-email">{record.assignedUserEmail}</div>
          </div>
        ),
        show: true,
        key: 'assigned',
        label: 'Исполнитель',
        isSort: false,
      },
    ],
    [navigate, subscriberPermissions?.view]
  );

  const [settingsItems, setSettingsItems] = useState<ISettingColumn[]>(items);

  const onRowClick = useCallback(
    (id: string) => {
      if (permissions?.view) {
        navigate(`${paths.feedback}/${id}`);
      }
    },
    [navigate, permissions?.view]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IFeedback> | null) => (flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null),
    []
  );

  const handleOnChangeTable: TableProps<IFeedback>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IFeedback> | SorterResult<IFeedback>[],
      extra: TableCurrentDataSource<IFeedback>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IFeedback>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IFeedback,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IFeedback,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const columns = useMemo<ColumnsType<IFeedback>>(() => {
    const cols: any[] = [];
    settingsItems?.forEach((setting, index) => {
      if (setting.show) {
        cols.push({
          title: setting.label,
          dataIndex: setting.key,
          key: `${setting.key}-${index}`,
          width: setting.width || '',
          sorter: setting.isSort
            ? {
                multiple: setting.sortIndex,
              }
            : null,
          sortOrder: setting.isSort
            ? (getColumnSortOrder(sortOrders.find((item) => item.field === setting.key) || null) as SortOrder)
            : null,
          render: (_: any, record: IFeedback) => setting.getData(record),
        });
      }
    });
    return cols;
  }, [getColumnSortOrder, settingsItems, sortOrders]);

  const changeCheckBox = useCallback(
    (index: number) => (e: CheckboxChangeEvent) => {
      if (settingsItems) {
        const newSettings = [...settingsItems];
        newSettings[index].show = e.target.checked;
        setSettingsItems(newSettings);
      }
    },
    [settingsItems]
  );

  const menuItems = useMemo<ItemType[]>(
    () =>
      settingsItems?.map((item, index) => ({
        label:
          index === 0 ? (
            <>
              {item.label}
              <span role="presentation" onClick={() => setSettingsOpen(false)} className="close-icon">
                <CloseIcon />
              </span>
            </>
          ) : (
            item.label
          ),
        key: `menu-${item.key}`,
        icon: (
          <div>
            <Checkbox checked={item.show} onChange={changeCheckBox(index)} />
          </div>
        ),
      })) || [],
    [changeCheckBox, settingsItems]
  );

  const data = useMemo(
    () =>
      feedbacks.map((item, index) => ({
        ...item,
        key: index,
        subscriberPermissions,
      })),
    [feedbacks, subscriberPermissions]
  );

  const isHidden = useMemo(() => !!settingsItems.find((item) => !item.show), [settingsItems]);

  return (
    <div className="feedback-table editable-table">
      <div
        className={classNames('subscriptions-table__settings', {
          'subscriptions-table__settings_active': isSettingsOpen,
        })}
      >
        {showSetting && (
          <Dropdown visible={isSettingsOpen} overlay={<Menu className="subscriptions-table__menu" items={menuItems} />}>
            <span
              className={isHidden ? 'feedback-table__menu_active' : ''}
              role="presentation"
              onClick={() => setSettingsOpen(!isSettingsOpen)}
            >
              <SettingsIcon />
            </span>
          </Dropdown>
        )}
      </div>
      <Table
        onChange={handleOnChangeTable}
        onRow={(record) => ({
          onClick: () => onRowClick(record.id || ''),
        })}
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination dispatcher-panel-table_absolute',
        }}
      />
    </div>
  );
};

export default FeedbacksTable;
