export interface IFAQLevel {
  displayName: string;
  type: string;
}

export interface IFAQSection {
  id: string;
  isEditable: boolean;
  name: string;
  order: number;
  questionsNumber: number;
}

export interface IFAQObject {
  objectId: string;
  objectName: string;
}

export enum EFAQStatus {
  active = 'Active',
  inactive = 'NotActive',
}

export enum EFAQLvl {
  company = 'Company',
  platform = 'Platform',
}

export enum EFAQAdminTabs {
  all = 'all',
  platform = 'platform',
}

export interface IFAQQuestion {
  id?: string;
  question: string;
  answer: string;
  isAllObjects?: boolean;
  status: EFAQStatus;
  subscriberFaqQuestionObjects: IFAQObject[];
  createDate: string;
  level: EFAQLvl;
  order: number;
  updateDate: string;
}
