import React, { FC, useCallback, useState } from 'react';
import { addHelpEntity } from '../../../api/help';
import { createHelpFaqUserQuestion } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { IHelpUserQuestion } from '../../../typings/help';
import Message from '../../message';
import Button from '../button';
import Input from '../input';
import { IInputValue, InputStatus } from '../input/types';
import Select from '../select';
import { ISelectOption } from '../select/types';
import { IAskHelpQuestion } from './types';

const AskHelpQuestion: FC<IAskHelpQuestion> = (props) => {
  const { chapters, url = createHelpFaqUserQuestion() } = props;

  const { sendRequest: sendQuestion, loading } = useApi(addHelpEntity);

  const [selectedChapter, setSelectedChapter] = useState<{ value: string; isError: boolean }>({
    value: '',
    isError: false,
  });
  const [question, setQuestion] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
  });

  const handleOnClickButton = useCallback(async () => {
    if (!selectedChapter.value) {
      setSelectedChapter({ ...selectedChapter, isError: true });
      return;
    }

    if (!question.value || !question.value.trim()) {
      setQuestion({ ...question, status: InputStatus.error });
      return;
    }
    const newQuestion: IHelpUserQuestion = {
      sectionId: selectedChapter.value,
      question: question.value,
    };
    const resError = await sendQuestion(url, newQuestion);
    if (!resError) {
      setSelectedChapter({ value: '', isError: false });
      setQuestion({ value: '', status: InputStatus.normal });
      Message.success({
        content: 'Вопрос успешно создан',
      });
    } else {
      Message.error({
        content: 'Не удалось создать вопрос',
      });
    }
  }, [question, selectedChapter, sendQuestion, url]);

  return (
    <div className="ask-help-question">
      <span className="ask-help-question__title">Остались вопросы?</span>
      <span className="ask-help-question__sub-title">
        Если вы не нашли свой вопрос в списке, вы можете задать его ниже
      </span>
      <div className="ask-help-question__content">
        <div className="ask-help-question__select-wrapper">
          <Select
            title="Раздел"
            onChange={(value) => setSelectedChapter({ value: value.toString(), isError: false })}
            value={selectedChapter.value}
            isError={selectedChapter.isError}
            options={[
              ...(chapters?.map<ISelectOption>((item) => ({
                value: item.id || '',
                title: item.name || '',
              })) || []),
            ]}
          />
        </div>
        <div className="ask-help-question__input-wrapper">
          <Input
            title="Вопрос"
            value={question.value}
            status={question.status}
            maxLength={250}
            onChange={(value) => setQuestion({ value, status: InputStatus.normal, errorText: '' })}
            placeholder="Введите свой вопрос"
          />
        </div>
        <div className="ask-help-question__button-wrapper">
          <Button onClick={handleOnClickButton} className="ask-help-question__button" loading={loading}>
            Отправить
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AskHelpQuestion;
