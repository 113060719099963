import Paper, { Tool } from 'paper';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import {
  ISchemaCanvas,
  EEditTypes,
  TPaperEvent,
  TPaperGroup,
  TPaperToolEvent,
  TPaperPath,
  EPaperGroupTypes,
  TPaperLayer,
  TPaperText,
} from './types';
import { renderBackground, schemaAdapter } from './paper/paperItems/adapter';
import {
  findGroupById,
  changeSelected,
  onWheel,
  showOnlyOneGroup,
  onDragCenter,
  moveControls,
  mouseDragTool,
  changeVisibleGroup,
  convertOpacityValue,
} from './calculations';
import { TreeNodeType } from '../../typings/treeNode';
import ContextMenu from './contextMenu';
import { ETypesOfCreation, EVisualizerModes, ISelectedSchemaEntity } from '../systemTabs/schemaTab/types';
import { BaseCreator } from './paper/tools/creator';
import { FinishedShape } from './paper/tools/creator/finishedShape';
import { FreeContour } from './paper/tools/creator/freeContour';
import { getControls, repaintAccessPoints } from './paper/paperItems/items';
import { findObjectInGroups, rescaleLabel, rescalePoint, updateAllChild } from './paper/common';
import ResizeToFullIcon from '../../assets/svg/icons/resizeToFull';
import ResizeToDefaultIcon from '../../assets/svg/icons/resizeToDefault';
import { Editor } from './paper/tools/editor';
import { ECursors } from './paper/paperItems/types';
import { findNewElementInSchema } from './paper/paperItems/calculations';
import EditIcon from '../../assets/svg/icons/edit';
import TrashIcon from '../../assets/svg/icons/trash';
import HideIcon from '../../assets/svg/icons/hide';
import ShowIcon from '../../assets/svg/icons/show';

const SchemaCanvas: FC<ISchemaCanvas> = (props) => {
  const {
    opacityValues,
    background = null,
    schema,
    selectedNode,
    onSelectNode = () => {},
    setPath = () => {},
    currentMode,
    displayedObjects = [],
    haveBeenChanges = () => {},
    permissions = {},
    isFullScreen = false,
    onResizeTab = () => {},
    onEdit = () => {},
    onDelete = () => {},
    settings,
    isFiveThousandth,
  } = props;

  const schemaGroup = useRef<TPaperGroup | null>(null);
  const backgroundGroup = useRef<TPaperGroup | null>(null);
  const selectedGroup = useRef<TPaperGroup | null>(null);
  const visibleGroup = useRef<TPaperGroup | null>(null);

  const groupWithRelatedPoints = useRef<TPaperGroup | null>(null);

  const pointsLayer = useRef<TPaperLayer | null>(null);
  const camerasLayer = useRef<TPaperLayer | null>(null);
  const labelsLayer = useRef<TPaperLayer | null>(null);
  const schemaLayer = useRef<TPaperLayer | null>(null);
  const backgroundLayer = useRef<TPaperLayer | null>(null);

  const creator = useRef<BaseCreator | null>(null);
  const editor = useRef<Editor | null>(null);

  const controls = useRef<[TPaperPath, TPaperPath[], TPaperGroup] | null>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const activeCursor = useRef<ECursors>(ECursors.default);

  const [menuItems, setMenuItems] = useState<ItemType[]>([]);

  const xRef = useRef<HTMLSpanElement>(null);
  const yRef = useRef<HTMLSpanElement>(null);
  const zoomRef = useRef<HTMLDivElement>(null);

  const selectedNodeBeforeRerender = useRef<ISelectedSchemaEntity | null>(null);

  const showRelatedPoints = useRef(false);

  useEffect(() => () => onSelectNode(''), []);

  const setCursor = useCallback(
    (cursor: ECursors) => {
      const canvas = canvasRef.current;
      if (canvas) {
        activeCursor.current = cursor;
        canvas.style.cursor = cursor;
      }
    },
    [canvasRef]
  );

  const resetControls = useCallback(() => {
    if (controls.current && permissions.edit) {
      controls.current.forEach((item) => (item instanceof Array ? item.forEach((i) => i.remove()) : item.remove()));
      controls.current = null;
    }
  }, [permissions]);

  const onMouseDragTool = useCallback(
    (event: TPaperToolEvent, moveMode: EEditTypes | null, data?: any) => {
      if (moveMode && selectedGroup.current) {
        mouseDragTool(event, moveMode, selectedGroup.current, data);
        if (controls.current) {
          moveControls(selectedGroup.current, controls.current);
        }
      }
    },
    [selectedGroup]
  );

  const setMoveMode = useCallback(
    (mode: EEditTypes | null = null, data?: any) => {
      if (mode) {
        if (!Paper.tool) {
          Paper.tool = new Tool();
          Paper.tool.minDistance = 1;
        }
        Paper.tool.onMouseDrag = (event: TPaperToolEvent) => onMouseDragTool(event, mode, data);
      } else if (Paper.tool && selectedGroup.current) {
        Paper.tool.onMouseDrag = null;
        updateAllChild(selectedGroup.current);
      }
    },
    [onMouseDragTool]
  );

  const onSelect = useCallback(
    (group: TPaperGroup, selected = false) => {
      resetControls();
      changeSelected(group, selected);
      if (
        permissions.edit &&
        selected &&
        group.data.type !== TreeNodeType.accessPoint &&
        group.data.type !== TreeNodeType.camera
      ) {
        controls.current = getControls(group, setCursor, setMoveMode);
      }
    },
    [permissions, resetControls, setCursor, setMoveMode]
  );

  const resizeObjects = useCallback(
    (newZoom = Paper.view.zoom) => {
      if (currentMode.status === EVisualizerModes.default) {
        if (pointsLayer.current) {
          pointsLayer.current.children.forEach((point) => rescalePoint(point, newZoom));
        }

        if (camerasLayer.current) {
          camerasLayer.current.children.forEach((point) => rescalePoint(point, newZoom));
        }

        if (labelsLayer.current) {
          labelsLayer.current.children.forEach((label) => rescaleLabel(label as TPaperText, newZoom));
        }

        if (controls.current && selectedGroup.current) {
          resetControls();
          controls.current = getControls(selectedGroup.current, setCursor, setMoveMode, newZoom);
        }
      }
      if (creator.current) {
        creator.current?.scaleActiveGroup();
      }
      if (zoomRef.current) {
        zoomRef.current.innerText = `Масштаб: ${(Paper?.view?.zoom || 0).toFixed(2)}`;
      }
    },
    [currentMode, resetControls, setCursor, setMoveMode, zoomRef]
  );

  const updateVisibleGroup = useCallback(
    (group: TPaperGroup, hideAll = false, needResize = true) => {
      const canvas = canvasRef.current;

      if (canvas && group && schemaGroup.current) {
        let { key } = group.data;
        visibleGroup.current = group;
        group.opacity = convertOpacityValue(opacityValues?.active) || 0.95;
        if (group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera) {
          key = group.parent.data.key;
          visibleGroup.current = group.parent;
        }
        if (opacityValues) {
          showOnlyOneGroup(schemaGroup.current, key, opacityValues, hideAll, needResize);
        }

        resizeObjects();
      }
    },
    [opacityValues, resizeObjects]
  );

  const setSelectedGroup = useCallback((group: TPaperGroup | null) => {
    selectedGroup.current = group;
    Paper.project.activeLayer.data.activeGroup = group;
  }, []);

  const setGroupWithRelatedPoints = useCallback(
    (group: TPaperGroup | null = null) => {
      showRelatedPoints.current = !showRelatedPoints.current;
      if (groupWithRelatedPoints.current) {
        repaintAccessPoints(false, groupWithRelatedPoints.current.data.relatedAccessPoints || []);
        groupWithRelatedPoints.current = null;
      }
      if (showRelatedPoints.current) {
        if (group) {
          onSelectNode(TreeNodeType.object);
          groupWithRelatedPoints.current = group;
          repaintAccessPoints(true, group.data.relatedAccessPoints || []);
        } else {
          groupWithRelatedPoints.current = null;
        }
      }
    },
    [onSelectNode]
  );

  const selectGroup = useCallback(
    (group: TPaperGroup | null = null, e?: any) => {
      if (
        currentMode.status === EVisualizerModes.default &&
        group?.data.type === TreeNodeType.accessPoint &&
        e &&
        e.event.which === 3 &&
        !showRelatedPoints.current
      ) {
        let items: ItemType[] = [];
        if (permissions.edit) {
          items = [
            {
              key: 'edit-point',
              icon: <EditIcon />,
              label: 'Редактировать',
              onClick: () => onEdit(group?.data?.key),
            },
            {
              key: 'delete-point',
              icon: <TrashIcon />,
              label: 'Удалить',
              onClick: () => onDelete(group?.data?.key),
            },
          ];
        }
        if (
          group.data.relatedAccessPoints &&
          group.data.relatedAccessPoints.length &&
          settings?.wicket &&
          !isFiveThousandth
        ) {
          items.push({
            key: 'change-visible-related-point',
            icon: groupWithRelatedPoints.current ? <HideIcon /> : <ShowIcon />,
            label: `${showRelatedPoints.current ? 'Скрыть' : 'Показать'} связанные точки доступа`,
            onClick: () => setGroupWithRelatedPoints(group),
          });
        }
        setMenuItems(items);
      } else {
        setMenuItems([]);
      }
      if (selectedGroup.current) {
        onSelect(selectedGroup.current);
      }
      if (group) {
        onSelectNode(group.data.key);
        setSelectedGroup(group);
        onSelect(group, true);
      }
    },
    [
      currentMode.status,
      isFiveThousandth,
      onDelete,
      onEdit,
      onSelect,
      onSelectNode,
      permissions.edit,
      setGroupWithRelatedPoints,
      setSelectedGroup,
      settings?.wicket,
    ]
  );

  const selectedGroupHasChanged = useCallback(() => {
    if (selectedGroup.current && selectedNode && selectedGroup.current.data.key === selectedNode.originalKey) {
      return null;
    }
    if (selectedNodeBeforeRerender.current && selectedNodeBeforeRerender.current.originalKey) {
      selectedNodeBeforeRerender.current = null;
      return null;
    }
    if (schemaGroup.current && selectedNode) {
      const foundGroup = findGroupById(schemaGroup.current, selectedNode.originalKey);
      if (foundGroup) {
        changeSelected(schemaGroup.current, false);
        updateVisibleGroup(foundGroup);
        setSelectedGroup(foundGroup);
        resetControls();
      }

      if (backgroundLayer.current && schemaLayer.current) {
        if (selectedNode.type === TreeNodeType.background) {
          schemaLayer.current.locked = true;
          backgroundLayer.current.locked = false;
          backgroundLayer.current.bringToFront();
        } else {
          schemaLayer.current.locked = false;
          backgroundLayer.current.locked = true;
          backgroundLayer.current.sendToBack();
        }
      }
    }
  }, [resetControls, selectedNode, setSelectedGroup, updateVisibleGroup]);

  const doubleClickGroup = useCallback(
    (group: TPaperGroup | null = null) => {
      if (group && group.data.type !== TreeNodeType.accessPoint && group.data.type !== TreeNodeType.camera) {
        updateVisibleGroup(group);
      }
    },
    [updateVisibleGroup]
  );

  const createPaperProject = useCallback(
    (canvas: HTMLCanvasElement) => {
      if (opacityValues) {
        canvas.setAttribute('resize', 'true');
        Paper.setup(canvas);

        const renderFunctions = {
          setActiveGroup: selectGroup,
          doubleClickGroup,
          setCursor,
          haveBeenChanges,
          moveControls: () => moveControls(selectedGroup.current, controls.current),
        };

        const { backgroundG, backgroundL } = renderBackground(background, renderFunctions, permissions.edit);
        backgroundGroup.current = backgroundG;
        backgroundLayer.current = backgroundL;

        const { schemaG, schemaL, pointsL, labelsL, camerasL } = schemaAdapter(
          schema,
          opacityValues,
          renderFunctions,
          currentMode.status,
          permissions.edit,
          settings
        );

        schemaGroup.current = schemaG;
        pointsLayer.current = pointsL;
        labelsLayer.current = labelsL;
        schemaLayer.current = schemaL;
        camerasLayer.current = camerasL;

        setTimeout(() => {
          if (background && backgroundGroup.current) {
            if (!background.isRescaled) {
              if (schemaGroup.current?.children.length) {
                const xScale = schemaGroup.current.bounds.width / backgroundGroup.current.children[0].bounds.width;
                const yScale = schemaGroup.current.bounds.height / backgroundGroup.current.children[0].bounds.height;
                backgroundGroup.current.scale(Math.max(xScale, yScale));
                backgroundGroup.current.position = schemaGroup.current.position.clone();
              } else {
                backgroundGroup.current.scale(1);
                backgroundGroup.current.position = Paper.view.center.clone();
              }
            }

            backgroundGroup.current.visible = true;
            backgroundGroup.current.children[0].opacity = convertOpacityValue(opacityValues?.background);
            backgroundGroup.current.children[0].data.updateObjectData(false);
            background.isRescaled = true;
          }
        }, 50);

        // Skip every second event for smooth canvas movement without moving the group
        let dragFlag = false;
        Paper.view.onMouseDrag = (event: TPaperEvent) => (dragFlag = !dragFlag) && onDragCenter(event, setCursor);

        let moveFlag = false;
        Paper.view.onMouseMove = (e: any) => {
          if (moveFlag) {
            if (creator.current) {
              creator.current.mouseMove(e);
            }
          }
          if (e.event.shiftKey) {
            setCursor(ECursors.grab);
            e.stopPropagation();
          } else if (activeCursor.current === ECursors.grab) {
            setCursor(ECursors.default);
          }
          moveFlag = !moveFlag;
          if (xRef.current) {
            xRef.current.innerText = `x = ${Math.round(e.point.x)}`;
          }
          if (yRef.current) {
            yRef.current.innerText = `y = ${Math.round(e.point.y)}`;
          }
        };

        Paper.view.onClick = () => groupWithRelatedPoints.current && setGroupWithRelatedPoints();

        Paper.view.onMouseDown = () => currentMode.status !== EVisualizerModes.create && selectGroup(null);

        Paper.view.onMouseUp = () => setCursor(ECursors.default);
      }
    },
    [
      opacityValues,
      selectGroup,
      doubleClickGroup,
      setCursor,
      haveBeenChanges,
      background,
      permissions.edit,
      schema,
      currentMode.status,
      settings,
      setGroupWithRelatedPoints,
    ]
  );

  const changeVisibleMainLayers = useCallback((val: boolean) => {
    if (labelsLayer.current) {
      labelsLayer.current.visible = val;
    }
    if (pointsLayer.current) {
      pointsLayer.current.visible = val;
    }
    if (camerasLayer.current) {
      camerasLayer.current.visible = val;
    }
  }, []);

  const preparationForUsePaperTools = useCallback(() => {
    if (selectedGroup.current || schemaGroup.current) {
      changeVisibleMainLayers(false);
      const group = (selectedGroup.current || schemaGroup.current) as TPaperGroup;
      onSelect(group);
      return group;
    }
    return null;
  }, [changeVisibleMainLayers, onSelect]);

  const resetTools = (tools: React.MutableRefObject<Editor | BaseCreator | null>[]) => {
    tools.forEach((tool) => {
      if (tool.current) {
        tool.current.resetProject();
        tool.current = null;
      }
    });
  };

  useEffect(() => {
    switch (currentMode.status) {
      case EVisualizerModes.create: {
        const group = preparationForUsePaperTools();
        if (group && opacityValues) {
          if (currentMode.typeOfCreation === ETypesOfCreation.freeContour) {
            creator.current = new FreeContour(group, currentMode, opacityValues, setCursor, setPath, setMenuItems);
          } else {
            creator.current = new FinishedShape(group, currentMode, opacityValues, setCursor, setPath, setMenuItems);
          }
          updateVisibleGroup(group, false, false);
          creator.current.start();
        }
        break;
      }
      case EVisualizerModes.edit: {
        const group = preparationForUsePaperTools();
        if (group && opacityValues) {
          updateVisibleGroup(group.parent, false, false);
          editor.current = new Editor(group, opacityValues, setCursor, setPath, setMenuItems);
        }
        break;
      }
      case EVisualizerModes.deviceShow:
      case EVisualizerModes.default: {
        changeVisibleMainLayers(true);
        resetTools([editor, creator]);
        updateVisibleGroup((visibleGroup.current || selectedGroup.current || schemaGroup.current) as TPaperGroup);
        break;
      }
      default: {
        break;
      }
    }
  }, [currentMode]);

  useEffect(() => {
    if (backgroundGroup.current) {
      backgroundGroup.current.children[0].opacity = convertOpacityValue(opacityValues?.background);
    }
  }, [opacityValues?.background]);

  useEffect(() => {
    if (schemaGroup.current && opacityValues) {
      showOnlyOneGroup(schemaGroup.current, selectedGroup.current?.data.key, opacityValues, false, false);
      schemaGroup.current.opacity = 1;
    }
  }, [opacityValues?.active, opacityValues?.inactive]);

  const reRenderCanvas = useCallback(() => {
    groupWithRelatedPoints.current = null;

    const canvas = canvasRef.current;
    if (
      canvas &&
      schema &&
      (currentMode.status === EVisualizerModes.default || currentMode.status === EVisualizerModes.deviceShow)
    ) {
      if (Paper.projects.length === 0) {
        createPaperProject(canvas);
        if (schemaGroup.current) {
          updateVisibleGroup(schemaGroup.current);
        }
      } else {
        const { zoom, center } = Paper.view;
        resetControls();
        Paper.project.remove();
        createPaperProject(canvas);
        Paper.view.center = center;
        Paper.view.zoom = zoom;
        if (schemaGroup.current) {
          let key;
          if (selectedNode?.originalKey === TreeNodeType.background) {
            key = TreeNodeType.background;
          } else {
            const newGroup = findNewElementInSchema(schemaGroup.current);
            if (newGroup) {
              key = newGroup.data.key;
            } else {
              key = selectedGroup.current?.data.key || visibleGroup.current?.data.key;
            }
          }
          setSelectedGroup(null);
          selectedNodeBeforeRerender.current = selectedNode || null;
          if (key) {
            onSelectNode(key);
          }
          updateVisibleGroup(schemaGroup.current, false, false);
        }
      }
    }
  }, [
    createPaperProject,
    currentMode.status,
    onSelectNode,
    resetControls,
    schema,
    selectedNode,
    setSelectedGroup,
    updateVisibleGroup,
  ]);

  const onResize = useCallback(() => {
    onResizeTab();
    setTimeout(() => {
      if (canvasRef.current) {
        const { width: newWidth, height: newHeight } = canvasRef.current.getBoundingClientRect();
        const { width: oldWidth, height: oldHeight } = Paper.view.viewSize;
        if (newWidth !== oldWidth || newHeight !== oldHeight) {
          Paper.view.viewSize = new Paper.Size(newWidth, newHeight);
        }
      }
    }, 100);
  }, [onResizeTab]);

  useEffect(() => {
    reRenderCanvas();
  }, [schema, background?.img]);

  useEffect(() => {
    if (selectedNode) {
      selectedGroupHasChanged();
    }
  }, [selectedNode]);

  const changeVisibleChildren = useCallback(
    (group: TPaperGroup) => {
      group.children.forEach((child) => {
        if (child.data.groupType === EPaperGroupTypes.group) {
          const figure = findObjectInGroups(child);
          if (figure && opacityValues) {
            if (displayedObjects.indexOf(child.data.type) !== -1) {
              changeVisibleGroup(figure, true, opacityValues);
            } else {
              changeVisibleGroup(figure, false, opacityValues, true);
            }
          }
        }
      });
    },
    [displayedObjects, opacityValues]
  );

  const findBuildingsInArea = useCallback(
    (group: TPaperGroup) =>
      group.children.forEach((child) => child.data.type === TreeNodeType.building && changeVisibleChildren(child)),
    [changeVisibleChildren]
  );

  useEffect(() => {
    if (selectedGroup.current && visibleGroup.current && currentMode.status === EVisualizerModes.default) {
      if (selectedGroup.current.data.type === TreeNodeType.building) {
        changeVisibleChildren(selectedGroup.current);
      } else if (selectedGroup.current.data.type === TreeNodeType.restrictedArea) {
        findBuildingsInArea(selectedGroup.current);
      }
      if (selectedNode?.originalKey !== 'object') {
        if (pointsLayer.current) {
          pointsLayer.current.visible = displayedObjects && displayedObjects.includes(TreeNodeType.accessPoint);
        }
        if (camerasLayer.current) {
          camerasLayer.current.visible = displayedObjects && displayedObjects.includes(TreeNodeType.camera);
        }
      }
    }
  }, [selectedGroup.current, displayedObjects]);

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.addEventListener('wheel', onWheel(setCursor, resizeObjects), { passive: false });
      const canvas = canvasRef.current;

      return () => {
        canvas?.removeEventListener('wheel', onWheel(setCursor, resizeObjects));
      };
    }
  }, [canvasRef]);

  return (
    <div className="schema-canvas">
      <div className="schema-canvas__buttons-container">
        <div className="schema-canvas__button" onClick={onResize} role="presentation">
          {isFullScreen ? <ResizeToDefaultIcon /> : <ResizeToFullIcon />}
        </div>
      </div>
      <div ref={zoomRef} className="schema-canvas__zoom">
        Масштаб: 1
      </div>
      {/* <div className="schema-canvas__position">
        <span ref={xRef}>x: 0</span>
        <span ref={yRef}>y: 0</span>
      </div> */}
      <ContextMenu items={menuItems} selectedNode={selectedNode || null}>
        <canvas
          ref={canvasRef}
          id="schema-canvas"
          className="schema-canvas__canvas"
          onContextMenu={(e) => e.preventDefault()}
        />
      </ContextMenu>
    </div>
  );
};

export default SchemaCanvas;
