import React, { FC, useCallback, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { IMailingSendDateModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import SingleDatePicker from '../../ui/singleDatePicker';
import { dateDefaultFormat, dateISOFormatNoTimeZone } from '../../../constants/date';

const MailingSendDateModal: FC<IMailingSendDateModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  const [date, setDate] = useState<Moment | null>(moment);

  const onAccess = useCallback(() => {
    onOk(date ? moment.utc(date).format(dateISOFormatNoTimeZone) : '');
    onCancel();
  }, [date, onCancel, onOk]);

  useEffect(() => {
    if (isOpen) setDate(null);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Запланировать отправку"
      width={410}
      showCloseIcon
      wrapClassName="mailing-date-modal__wrapper"
      centered
      footer={
        <div className="mailing-date-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onAccess} disabled={!date}>
            Запланировать
          </Button>
        </div>
      }
    >
      <SingleDatePicker
        onlyThisMonth
        format={dateDefaultFormat}
        onlyFuture
        isDisabledStyle
        title="Время отправки"
        dateValue={date}
        placeholder="- Выберите -"
        onChange={setDate}
      />
    </Modal>
  );
};

export default MailingSendDateModal;
