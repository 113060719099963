import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { IHardwareRange } from '../../../../../../../typings/systems/hardwareSettings';
import PlusIcon from '../../../../../../../assets/svg/icons/plus';
import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import { IArrayNumberRangeField, IArrayNumberRangeFieldItemError } from './types';
import TrashLightIcon from '../../../../../../../assets/svg/icons/trashLight';

const ArrayNumberRangeField: FC<IArrayNumberRangeField> = ({
  object,
  changeParam,
  index,
  wasSelected,
  disabled = false,
  isEditEnable = false,
}) => {
  useEffect(() => {
    if (!object.value && !object.wasChange && wasSelected && Array.isArray(object.defaultValue)) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (i: number, name: string, value: string) => {
      if (object.errorData) {
        const errorItemIndex = object.errorData.findIndex((item: IArrayNumberRangeFieldItemError) => item.index === i);
        if (errorItemIndex !== -1) {
          object.errorData.splice(errorItemIndex, 1);
        }
        if (!(object.errorData.length > 0)) {
          object.isError = false;
        }
      } else {
        object.isError = false;
      }
      object.errorText = '';
      object.wasChange = true;
      if (object.value) {
        object.value[i][name as keyof IHardwareRange] = value === '' ? null : Number(value);
      }
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  const onAdd = useCallback(
    (i: number) => {
      if (disabled || object.readOnly) {
        return;
      }

      if (!object.value) {
        object.value = [];
      }
      if (
        (object.restricts.restricts.maxCount as number) > object.value.length ||
        !object.restricts.restricts.maxCount
      ) {
        object.value.splice(i + 1, 0, { to: null, from: null });
        object.wasChange = true;
        changeParam(index, object);
      }
    },
    [changeParam, disabled, index, object]
  );

  const onDelete = useCallback(
    (i: number) => {
      if (disabled || object.readOnly) {
        return;
      }

      object.value?.splice(i, 1);
      object.errorData = null;
      object.isError = false;
      object.errorText = '';
      object.wasChange = true;
      if (object.value?.length === 0) {
        object.value = null;
      }
      changeParam(index, object);
    },
    [changeParam, disabled, index, object]
  );

  const getError = useCallback(
    (i: number) => object.errorData?.find((errorItem: IArrayNumberRangeFieldItemError) => errorItem.index === i),
    [object.errorData]
  );

  const renderInputs = useCallback(
    (collection: IHardwareRange[]) =>
      collection.map((item, i) => {
        const error = getError(i);
        return (
          <div className="array-field__item" key={`array-${object.id}-field-${i}`}>
            <div className="array-range-field__inputs">
              <Input
                value={item?.from?.toString() || ''}
                onChange={(value) => onChange(i, 'from', value)}
                inputType={InputType.numbers}
                status={error?.fromErrorText ? InputStatus.error : InputStatus.normal}
                errorText={error?.fromErrorText || ''}
                maxLength={9}
                disabled={disabled}
                containerClassName={classNames({ 'array-field__input-disabled': object.readOnly })}
                isDisabledStyle={disabled && object.readOnly}
              />
              <Input
                value={item?.to?.toString() || ''}
                onChange={(value) => onChange(i, 'to', value)}
                inputType={InputType.numbers}
                status={error?.toErrorText ? InputStatus.error : InputStatus.normal}
                errorText={error?.toErrorText || ''}
                maxLength={9}
                disabled={disabled}
                containerClassName={classNames({ 'array-field__input-disabled': object.readOnly })}
                isDisabledStyle={disabled && object.readOnly}
              />
            </div>
            {isEditEnable && (
              <div className="array-field__item-icons">
                <div
                  className={classNames('array-field__item-icon', {
                    'array-field__item-icon_disabled':
                      object.restricts.restricts.maxCount === object.value?.length || object.readOnly,
                  })}
                  onClick={() => onAdd(i)}
                  role="presentation"
                >
                  <PlusIcon />
                </div>
                <div
                  className={classNames('array-field__item-icon', {
                    'array-field__item-icon_disabled': object.readOnly,
                  })}
                  onClick={() => onDelete(i)}
                  role="presentation"
                >
                  <TrashLightIcon />
                </div>
              </div>
            )}
          </div>
        );
      }),
    [
      disabled,
      getError,
      isEditEnable,
      object.id,
      object.readOnly,
      object.restricts.restricts.maxCount,
      object.value?.length,
      onAdd,
      onChange,
      onDelete,
    ]
  );

  const inputs = useMemo(
    () =>
      object.value && Array.isArray(object.value) && object.value.length ? (
        renderInputs(object.value)
      ) : (
        <div
          className={classNames('array-field__plus', {
            'array-field__plus_disabled': object.readOnly,
          })}
          onClick={() => onAdd(0)}
          role="presentation"
        >
          <PlusIcon />
        </div>
      ),
    [object.readOnly, object.value, onAdd, renderInputs]
  );

  return (
    <div className="array-field array-range-field">
      <div className={classNames('array-field__content', { 'array-field__content_error': object.isError })}>
        <div
          className={classNames('array-field__title', {
            'array-field__title_disabled': object.readOnly,
          })}
        >
          {object.description}
          {object.isRequired && <span className="input__title-required">*</span>}
        </div>
        <div className="array-field__items">{inputs}</div>
      </div>
      {object.isError && <span className="input__error">{object.errorText || ''}</span>}
    </div>
  );
};

export default ArrayNumberRangeField;
