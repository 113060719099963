import React, { FC } from 'react';

const ImgIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 6.5C2 5.11929 3.11929 4 4.5 4H19.5C20.8807 4 22 5.11929 22 6.5V17.5C22 18.8807 20.8807 20 19.5 20H4.5C3.11929 20 2 18.8807 2 17.5V15.5V6.5ZM3 15.7071V17.5C3 18.3284 3.67157 19 4.5 19H19.5C19.9907 19 20.4264 18.7644 20.7 18.4001C20.6814 18.3861 20.6634 18.3705 20.6464 18.3536L16.5 14.2071L13.8536 16.8536C13.6583 17.0488 13.3417 17.0488 13.1464 16.8536L7.5 11.2071L3 15.7071ZM21 17.2929L16.8536 13.1464C16.6583 12.9512 16.3417 12.9512 16.1464 13.1464L13.5 15.7929L7.85355 10.1464C7.65829 9.95118 7.34171 9.95118 7.14645 10.1464L3 14.2929V6.5C3 5.67157 3.67157 5 4.5 5H19.5C20.3284 5 21 5.67157 21 6.5V17.2929ZM18 7H16C15.4477 7 15 7.44772 15 8V10C15 10.5523 15.4477 11 16 11H18C18.5523 11 19 10.5523 19 10V8C19 7.44772 18.5523 7 18 7ZM16 8V10H18V8H16Z"
      fill="black"
    />
  </svg>
);

export default ImgIcon;
