import React, { FC, useCallback, useMemo, useState } from 'react';
import { Upload } from 'antd';
import { IFileUploader } from './types';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';
import { ELogoUploadErrors } from '../../organization/changeLogoModal/types';
import PaperClipIcon from '../../../assets/svg/icons/paperСlip';

const { Dragger } = Upload;

const FileUploader: FC<IFileUploader> = (props) => {
  const {
    acceptFileTypes = [],
    limit,
    isSingleFile = true,
    data,
    setImg = () => {},
    setImgs: setMigs = () => {},
    quantityLimit,
    altInfoText = '',
    showPreview = true,
    customError = '',
  } = props;

  const [error, setError] = useState<ELogoUploadErrors>(ELogoUploadErrors.default);

  const validateFile = useCallback(
    (file: File, length = 0) => {
      if (limit && file.size > limit) {
        setError(ELogoUploadErrors.size);
      } else if (!acceptFileTypes.includes(file.type)) {
        setError(ELogoUploadErrors.type);
      } else if (quantityLimit ? length + (data as any).length >= quantityLimit : false) {
        setError(ELogoUploadErrors.length);
      } else {
        setError(ELogoUploadErrors.default);
        return file;
      }
      return null;
    },
    [acceptFileTypes, data, limit, quantityLimit]
  );

  const beforeUpload = useCallback(
    (file: File, fileList: File[]) => {
      if (isSingleFile) {
        setImg(validateFile(file));
      } else {
        const list: File[] = [];
        for (const item of fileList) {
          const res = validateFile(item, list.length);
          if (res) list.push(item);
        }
        setMigs([...((data as File[]) || []), ...list]);
      }
    },
    [data, isSingleFile, setImg, setMigs, validateFile]
  );

  const onDrop = useCallback(() => {}, []);

  const onDeleteImg = useCallback(
    (index: number) => () => {
      if (isSingleFile) {
        setImg(null);
      } else {
        setMigs((data as File[]).filter((_, ind) => ind !== index));
      }
    },
    [data, isSingleFile, setImg, setMigs]
  );

  const acceptableFormats = useMemo(
    () => acceptFileTypes.map((item) => item.split('/')[1]).join(', '),
    [acceptFileTypes]
  );

  const limitInMb = useMemo(() => (limit || 0) / 1048576, [limit]);

  const errorBlock = useMemo(
    () =>
      customError ? (
        <div className="file-uploader__error">{customError}</div>
      ) : error === ELogoUploadErrors.size ? (
        <div className="file-uploader__error">Допустимый размер файла {limitInMb} МБ</div>
      ) : error === ELogoUploadErrors.type ? (
        <div className="file-uploader__error">Допустимые форматы: {acceptableFormats}</div>
      ) : error === ELogoUploadErrors.length ? (
        <div className="file-uploader__error">Нельзя загрузить более {quantityLimit} файлов</div>
      ) : null,
    [acceptableFormats, customError, error, limitInMb, quantityLimit]
  );

  const dragger = useMemo(
    () => (
      <Dragger
        accept={acceptFileTypes.join(', ')}
        multiple={!isSingleFile}
        className="file-uploader__download"
        customRequest={() => {}}
        onDrop={onDrop}
        beforeUpload={beforeUpload}
        maxCount={quantityLimit}
        disabled={quantityLimit && Array.isArray(data) ? quantityLimit <= data?.length : false}
      >
        <div className="file-uploader__download-message">Перетащите файл сюда или воспользуйтесь кнопкой</div>
        <div className="file-uploader__download-button">
          <PaperClipIcon />
          Загрузить
        </div>
      </Dragger>
    ),
    [acceptFileTypes, beforeUpload, data, isSingleFile, onDrop, quantityLimit]
  );

  const infoBlock = useMemo(
    () => (
      <>
        {errorBlock}
        <div className="file-uploader__info">
          Принимаются файлы {limit ? `размером до ${limitInMb} МБ` : ''} в следующих форматах: {acceptableFormats}
        </div>
      </>
    ),
    [acceptableFormats, errorBlock, limit, limitInMb]
  );

  const renderFile = useCallback(
    (file: File, index: number) => (
      <div key={`img-${index}`} className="file-uploader__img">
        {showPreview && (
          <div className="file-uploader__img-preview">
            {file.type.includes('video') ? (
              <video>
                <source src={URL.createObjectURL(file)} type="video/mp4" />
              </video>
            ) : (
              <img alt="Logo" src={URL.createObjectURL(file)} />
            )}
          </div>
        )}
        <div className="file-uploader__img-content">
          <div className="file-uploader__img-name">{file.name}</div>
          <div className="file-uploader__img-size">({Math.round(file.size / 1024)} КБ)</div>
        </div>
        <div className="file-uploader__img-icon" role="presentation" onClick={onDeleteImg(index)}>
          <CloseBigIcon />
        </div>
      </div>
    ),
    [onDeleteImg, showPreview]
  );

  return (
    <div className="file-uploader">
      {isSingleFile ? (
        <>
          {!data ? dragger : renderFile(data as File, 0)}
          {altInfoText || infoBlock}
        </>
      ) : (
        <>
          {dragger}
          {altInfoText || infoBlock}
          {data && (
            <div className="file-uploader__list">{(data as File[]).map((item, index) => renderFile(item, index))}</div>
          )}
        </>
      )}
    </div>
  );
};

export default FileUploader;
