import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { setHeaderTitle } from '../../../store/slices/header';
import CommonHead from '../../../components/commonHead';
import { paths } from '../../../constants/paths';
import { getEmptyRoleUrl, getRoleUrl } from '../../../constants/api';
import { IRole } from '../../../typings/roles';
import RoleCustomizationTable from '../../../components/roles/roleCustomizationTable';
import ButtonLink from '../../../components/ui/buttonLink';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import { rolesTabs } from './config';
import { ERolesTabsIds } from '../types';
import Button from '../../../components/ui/button';
import { ButtonType } from '../../../components/ui/button/types';
import DeleteRoleModal from '../../../components/roles/deleteRoleModal';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';

const RolePage: FC = () => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.roles));

  const [currentRole, setCurrentRole] = useState<IRole | null>(null);

  const { data: roleData, sendRequest: getRole, loading: roleLoading } = useApi<IRole>(getRequest);

  const navigate = useNavigate();

  const params = useParams();

  const [isCreate, setIsCreate] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [nextTabId, setNextTabId] = useState<string>('');

  const [deleteRoleOpen, setDeleteRoleOpen] = useState(false);

  const [activeTab, setActiveTab] = useState<ITab>(
    rolesTabs.find((tab) => tab.id === searchParams.get(EPageQueryParams.tabId)) || rolesTabs[0]
  );

  const [title, setTitle] = useState('');

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const getRoleData = useCallback(
    (id = '') => {
      getRole(id ? getRoleUrl(id) : getEmptyRoleUrl());
    },
    [getRole]
  );

  useEffect(() => {
    if (params.instructionId === 'create') {
      setIsCreate(true);
      getRoleData();
    } else {
      setIsCreate(false);
      getRoleData(params.instructionId);
      if (!searchParams.get(EPageQueryParams.tabId)) {
        searchParams.set(EPageQueryParams.tabId, ERolesTabsIds.basic);
        setSearchParams(searchParams);
      }
    }
  }, [params.instructionId]);

  useEffect(() => {
    setCurrentRole(roleData);
  }, [roleData]);

  useEffect(() => {
    const newTitle = isCreate ? 'Создание новой роли' : currentRole?.name || '';
    setTitle(newTitle);
    dispatch(setHeaderTitle(newTitle));
  }, [currentRole, dispatch, isCreate]);

  const onSaved = useCallback(
    (role: IRole) => {
      setCurrentRole(role);
      navigate(`${paths.roles}/${role.id}`);
    },
    [navigate]
  );

  const updateRole = useCallback((role: IRole) => {
    setCurrentRole(role);
    setWasChange(false);
  }, []);

  const handleOnChangeTab = useCallback(
    (key: string, change = wasChange) => {
      if (change) {
        setNextTabId(key);
      } else {
        const newActiveTab = rolesTabs.find((tab) => tab.id === key) || rolesTabs[0];
        setActiveTab(newActiveTab);
        if (!isCreate) {
          searchParams.set(EPageQueryParams.tabId, newActiveTab.id);
          setSearchParams(searchParams);
        }
        setNextTabId('');
      }
    },
    [isCreate, searchParams, setSearchParams, wasChange]
  );

  const onDelete = useCallback(() => {
    setDeleteRoleOpen(true);
  }, []);

  const chancelDeleteRole = useCallback(() => setDeleteRoleOpen(false), []);

  const onDeletedRole = useCallback(() => {
    navigate(paths.roles);
  }, [navigate]);

  const handleResetNextTabId = useCallback(
    (isSuccess = true) => {
      if (isSuccess) {
        if (nextTabId === 'back') {
          navigate(paths.roles);
        } else {
          handleOnChangeTab(nextTabId, false);
        }
        setWasChange(false);
      }
      setNextTabId('');
    },
    [handleOnChangeTab, navigate, nextTabId]
  );

  const goBack = useCallback(() => {
    if (wasChange) {
      setNextTabId('back');
    } else {
      navigate(paths.roles);
    }
  }, [navigate, wasChange]);

  return (
    <>
      <CommonHead seo={{ title }} />
      <DeleteRoleModal
        role={currentRole}
        isOpen={deleteRoleOpen}
        onCancel={chancelDeleteRole}
        onDeletedRole={onDeletedRole}
      />
      <div className="role-page">
        <div className="role-page__back-button-wrapper">
          <ButtonLink withCallback onClick={goBack} leftIcon={<ArrowBackIcon />} content="Список ролей" />
        </div>
        {isCreate ? (
          <RoleCustomizationTable
            nextTabId={nextTabId}
            isCreate
            onSaved={onSaved}
            roleLoading={roleLoading}
            permissions={permissions}
            role={currentRole}
            wasChange={wasChange}
            setWasChange={setWasChange}
            resetNextTabId={handleResetNextTabId}
          />
        ) : (
          <Tabs
            activeTabKey={activeTab.id}
            onChangeActiveTab={(key) => handleOnChangeTab(key)}
            tabsClassName="role-page__tabs"
            tabBarExtraContent={
              !roleData?.isReadOnly &&
              !isCreate && (
                <Button
                  disabled={!permissions?.delete}
                  className="role-page__delete-button"
                  onClick={onDelete}
                  type={ButtonType.tertiary}
                >
                  Удалить роль
                </Button>
              )
            }
            tabs={rolesTabs.map<ITab>((tab) => ({
              ...tab,
              children: (
                <tab.component
                  role={currentRole}
                  isCreate={isCreate}
                  wasChange={wasChange}
                  setWasChange={setWasChange}
                  updateRole={updateRole}
                  nextTabId={nextTabId}
                  resetNextTabId={handleResetNextTabId}
                  isActiveTab={activeTab.id === tab.id}
                  permissions={permissions}
                />
              ),
            }))}
          />
        )}
      </div>
    </>
  );
};

export default RolePage;
