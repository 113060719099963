import { Point } from 'paper/dist/paper-core';
import ReactDOMServer from 'react-dom/server';
import { TPaperEvent, TPaperGroup, TPaperLayer, TPaperPath, TPaperPoint, TPaperText } from '../../types';
import { checkIntersection, findObjectInGroups, updateAllChild } from '../common';
import { ECursors } from './types';
import { TreeNodeType } from '../../../../typings/treeNode';

export const getTopLeftPoint = (path: TPaperPath) => {
  let { point } = path.segments[0];
  path.segments.forEach(
    (segment) => point.y >= segment.point.y && point.x >= segment.point.x && (point = segment.point)
  );
  return new Point(point.x, point.y);
};

export const getBottomLeftPoint = (path: TPaperPath) => {
  let { point } = path.segments[0];
  path.segments.forEach(
    (segment) => point.y <= segment.point.y && point.x <= segment.point.x && (point = segment.point)
  );
  return new Point(point.x, point.y);
};

export const calculateLabelPositionCorner = (path: TPaperPath, label: TPaperText, parent: TPaperGroup) => {
  if (label.bounds.width + 10 > parent.bounds.width) {
    label.justification = 'left';
    label.position = parent.bounds.center;
    return;
  }
  const isSection = parent.data.type === TreeNodeType.section;
  const point = isSection ? getBottomLeftPoint(path) : getTopLeftPoint(path);
  label.position = new Point(point.x + (isSection ? -1 : 1) * 10, point.y + (isSection ? -15 : 15));
  label.justification = isSection ? 'right' : 'left';
};

export const checkJSON = (str: string) => {
  try {
    return !!JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export const getLineLength = ({ x: x1, y: y1 }: TPaperPoint, { x: x2, y: y2 }: TPaperPoint): number =>
  Math.round(Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2));

export const getStringFromJSX = (element: JSX.Element): string => ReactDOMServer.renderToStaticMarkup(element);

export const onMouseDown =
  (group: TPaperGroup, setActiveGroup: (group: TPaperGroup, e: TPaperEvent) => void) => (event: TPaperEvent) => {
    event.stopPropagation();
    setActiveGroup(group, event);
  };

export const checkDrag = (group: TPaperGroup, event: TPaperEvent): boolean => {
  if (group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera) {
    const parenGroup = findObjectInGroups(group.data.clone.parent);
    const point = findObjectInGroups(group);
    if (parenGroup && point) {
      if (parenGroup.children[0].contains(point?.children[0].bounds.center)) {
        return parenGroup.children[0].contains(point?.children[0].bounds.center.add(event.delta));
      }
      return true;
    }
    return false;
  }

  if (
    group.data.type === TreeNodeType.object ||
    group.parent.data.key === TreeNodeType.object ||
    group.data.type === TreeNodeType.background
  ) {
    return true;
  }

  const groupClone = group.clone();
  groupClone.position = group.position.add(event.delta);
  const result = !checkIntersection(group.parent, groupClone);
  groupClone.remove();
  return result;
};

export const onMouseDrag =
  (group: TPaperGroup, setCursor: (cursor: ECursors) => void, moveControls: () => void) => (e: any) => {
    if (!e.event.shiftKey) {
      e.stopPropagation();
      if (checkDrag(group, e)) {
        if (group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera) {
          group.data.clone.position = group.data.clone.position.add(e.delta);
        } else {
          group.position = group.position.add(e.delta);
        }
        setCursor(ECursors.grabbing);
        moveControls();
        updateAllChild(group);
      }
    }
  };

export const createClone = (group: TPaperGroup, parentGroup: TPaperGroup, layer: TPaperLayer) => {
  const clone = group.clone();
  layer.addChild(group);
  parentGroup.addChild(clone);
  group.data.clone = clone;
  clone.data.clone = group;
  clone.visible = false;
};

export const findNewElementInSchema = (group: TPaperGroup): TPaperGroup | null => {
  let newGroup = null;
  if (group && group.data.isNew) {
    newGroup =
      group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera ? group.parent : group;
    group.data.isNew = false;
    group.data.updateObjectData();
  }

  if (!newGroup && group.children) {
    for (const item of group.children) {
      const result = findNewElementInSchema(item);

      if (result) {
        newGroup = result;
        break;
      }
    }
  }

  return newGroup;
};
