import React, { FC } from 'react';

const CheckedIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="6.5" stroke="#47C80A" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7575 5.09995C11.013 5.27669 11.0768 5.62708 10.9001 5.88256L7.52764 10.7575C7.42465 10.9064 7.2563 10.9966 7.0753 10.9999C6.8943 11.0032 6.72278 10.9192 6.61443 10.7742L5.11191 8.76303C4.92597 8.51416 4.977 8.16168 5.22587 7.97575C5.47474 7.78982 5.82722 7.84084 6.01315 8.08971L7.04749 9.47418L9.9749 5.24253C10.1516 4.98705 10.502 4.92321 10.7575 5.09995Z"
      fill="#47C80A"
    />
  </svg>
);

export default CheckedIcon;
