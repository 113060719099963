import React, { FC } from 'react';

const CameraCircleActiveIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_26156_141238)">
      <circle cx="12" cy="12" r="11.6" stroke="#1444F2" strokeWidth="0.8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3889 16.0013C13.4454 16.0013 14.3051 15.1587 14.3327 14.1088L16.0659 15.5532C16.3874 15.8211 16.7926 15.9678 17.2111 15.9678C18.1991 15.9678 19 15.1669 19 14.1789V10.0459C19 9.62743 18.8533 9.22219 18.5854 8.9007C17.9529 8.14171 16.8249 8.03917 16.0659 8.67165L14.3327 10.116C14.3051 9.06615 13.4454 8.22352 12.3889 8.22352H11.8515L11.1812 6.88294C11.1153 6.75119 10.9806 6.66797 10.8333 6.66797H7.72222C7.50744 6.66797 7.33333 6.84208 7.33333 7.05686C7.33333 7.27163 7.50744 7.44575 7.72222 7.44575H10.593L10.9819 8.22352H6.94444C5.87056 8.22352 5 9.09408 5 10.168V14.0569C5 15.1307 5.87056 16.0013 6.94444 16.0013H12.3889ZM16.5638 14.9557L14.3333 13.0969V11.1279L16.5638 9.26915C16.9928 8.91166 17.6304 8.96962 17.9879 9.39861C18.1393 9.58032 18.2222 9.80937 18.2222 10.0459V14.1789C18.2222 14.7373 17.7695 15.19 17.2111 15.19C16.9746 15.19 16.7455 15.1071 16.5638 14.9557ZM13.5556 14.0569V10.168C13.5556 9.52364 13.0332 9.0013 12.3889 9.0013H11.6195C11.6141 9.00142 11.6087 9.00142 11.6033 9.0013H6.94444C6.30011 9.0013 5.77778 9.52364 5.77778 10.168V14.0569C5.77778 14.7012 6.30011 15.2235 6.94444 15.2235H12.3889C13.0332 15.2235 13.5556 14.7012 13.5556 14.0569ZM6.55556 10.168C6.55556 10.3827 6.72967 10.5569 6.94444 10.5569H9.27778C9.49256 10.5569 9.66667 10.3827 9.66667 10.168C9.66667 9.95319 9.49256 9.77908 9.27778 9.77908H6.94444C6.72967 9.77908 6.55556 9.95319 6.55556 10.168ZM9.27778 14.4457C9.063 14.4457 8.88889 14.2716 8.88889 14.0569C8.88889 13.8421 9.063 13.668 9.27778 13.668H10.0556C10.2703 13.668 10.4444 13.8421 10.4444 14.0569C10.4444 14.2716 10.2703 14.4457 10.0556 14.4457H9.27778ZM11.2222 14.0569C11.2222 14.2716 11.3963 14.4457 11.6111 14.4457H12.3889C12.6037 14.4457 12.7778 14.2716 12.7778 14.0569C12.7778 13.8421 12.6037 13.668 12.3889 13.668H11.6111C11.3963 13.668 11.2222 13.8421 11.2222 14.0569Z"
        fill="#1444F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_26156_141238">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CameraCircleActiveIcon;
