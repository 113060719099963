import { IPayment } from '../../typings/payments';
import { IPaymentsApiSettings } from './types';

export const PAYMENT_TABLE_COUNT = 10;

export const getPaymentApiSettings = (defaultSortField: string): IPaymentsApiSettings<IPayment> => ({
  sortFields: defaultSortField
    ? [
        {
          field: defaultSortField as keyof IPayment,
          descending: true,
        },
      ]
    : [],
  page: 0,
  pageSize: PAYMENT_TABLE_COUNT,
  search: '',
});
