import React, { FC } from 'react';
import { IEditableBlock } from './types';
import EditIcon from '../../../assets/svg/icons/edit';

const EditableBlock: FC<IEditableBlock> = (props) => {
  const { onClick = () => {}, wrapperClassName = '', children = null, disabled = false } = props;

  return (
    <div onClick={() => !disabled && onClick()} role="presentation" className={`editable-block ${wrapperClassName}`}>
      {children}
      {!disabled && (
        <div className="editable-block__icon">
          <EditIcon />
        </div>
      )}
    </div>
  );
};

export default EditableBlock;
