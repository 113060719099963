import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import { IDeleteRoleModal } from './types';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { getRequest, postRequest } from '../../../../api';
import { useApi } from '../../../../hooks/useApi';
import { roleUsersAvailableListUrl, roleUsersListUrl } from '../../../../constants/api';
import Scrollbar from '../../../ui/scrollbar';
import InputSearch from '../../../ui/inputSearch';
import { IRoleUser } from '../../../../typings/roles';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import Checkbox from '../../../ui/checkbox';

const AvailableUsersForRoleModal: FC<IDeleteRoleModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, role = null, onSave = () => {}, getName = () => '' } = props;

  const { data: users, sendRequest: getUsers, loading } = useApi<IRoleUser[]>(getRequest);
  const { sendRequest: addNewUsers, loading: addNewUsersLoading } = useApi<IRoleUser[]>(postRequest);

  const [searchValue, setSearchValue] = useState('');

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const getUsersList = useCallback(
    (search = searchValue) => {
      if (role) {
        getUsers(roleUsersAvailableListUrl(role.id), { params: { search } });
      }
    },
    [getUsers, role, searchValue]
  );

  useEffect(() => {
    if (isOpen && selectedUsers.length) {
      setSelectedUsers([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      getUsersList();
    }
  }, [isOpen]);

  const handleOnSearch = useCallback(
    (value: string) => {
      setSearchValue(value);
      getUsersList(value);
    },
    [getUsersList]
  );

  const onAdd = useCallback(async () => {
    const resError = (await addNewUsers(roleUsersListUrl(role?.id || ''), selectedUsers)) as AxiosError;
    if (!resError?.response?.data) {
      onCancel();
      onSave();
    }
  }, [addNewUsers, onCancel, onSave, role?.id, selectedUsers]);

  const onCheck = useCallback(
    (id: string) => (value: boolean) => {
      if (value) {
        setSelectedUsers([...selectedUsers, id]);
      } else {
        setSelectedUsers(selectedUsers.filter((item) => item !== id));
      }
    },
    [selectedUsers]
  );

  const columns = useMemo<ColumnsType<IRoleUser>>(
    () => [
      {
        dataIndex: 'checkboxes',
        key: 'checkboxes',
        render: (_: any, record: IRoleUser) => (
          <div className="editable-table__data-container">
            <Checkbox checked={!!selectedUsers.find((item) => item === record.id)} onChange={onCheck(record.id)} />
          </div>
        ),
      },
      {
        title: 'ФИО',
        dataIndex: 'fio',
        key: 'fio',
        render: (_: any, record: IRoleUser) => (
          <div className="editable-table__data-container role-users-tab__fio">
            <p>{getName(record)}</p>
          </div>
        ),
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        render: (_: any, record: IRoleUser) => <div className="editable-table__data-container">{record.email}</div>,
      },
    ],
    [getName, onCheck, selectedUsers]
  );

  const data = useMemo(
    () =>
      users?.map((item, index) => ({
        ...item,
        key: index,
      })) || [],
    [users]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Добавление пользователя"
      width={840}
      showCloseIcon
      wrapClassName="available-user-for-role__wrapper"
      centered
      footer={
        <div className="available-user-for-role__button-container">
          <Button type={ButtonType.outline} onClick={onCancel} disabled={addNewUsersLoading}>
            Отмена
          </Button>
          <Button onClick={onAdd} disabled={addNewUsersLoading || selectedUsers.length === 0}>
            Добавить
          </Button>
        </div>
      }
    >
      <InputSearch placeholder="Поиск по ФИО, e-mail" value={searchValue} onSearch={handleOnSearch} />
      <Scrollbar>
        <div className="available-user-for-role__content editable-table">
          <Table
            columns={columns}
            dataSource={data}
            loading={{
              spinning: loading,
              indicator: <Loader color={ELoaderColor.blue} />,
            }}
            showSorterTooltip={false}
            locale={{
              emptyText: searchValue ? (
                <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
              ) : (
                <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
              ),
            }}
            pagination={{
              pageSize: 10,
              hideOnSinglePage: true,
              total: users?.length,
              showSizeChanger: false,
              showQuickJumper: false,
              prevIcon: <ChevronLeft />,
              nextIcon: <ChevronRight />,
              className: 'editable-table__pagination',
            }}
          />
          {!!selectedUsers.length && (
            <div className="available-user-for-role__counter">
              Выбрано {selectedUsers.length} из {users?.length}
            </div>
          )}
        </div>
      </Scrollbar>
    </Modal>
  );
};

export default AvailableUsersForRoleModal;
