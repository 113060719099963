import React, { FC } from 'react';

const WarningIcon: FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="10" r="8" fill="white" />
    <path
      d="M9.99964 14C10.1865 14 10.3433 13.9368 10.47 13.8104C10.5967 13.6839 10.66 13.5273 10.66 13.3404C10.66 13.1535 10.5968 12.9967 10.4704 12.87C10.3439 12.7433 10.1873 12.68 10.0004 12.68C9.81345 12.68 9.65667 12.7432 9.53 12.8696C9.40333 12.9961 9.34 13.1527 9.34 13.3396C9.34 13.5265 9.40321 13.6833 9.52964 13.81C9.65608 13.9367 9.81275 14 9.99964 14ZM10.0635 10.94C10.2345 10.94 10.3767 10.8825 10.49 10.7675C10.6033 10.6525 10.66 10.51 10.66 10.34V6.48C10.66 6.31 10.6022 6.1675 10.4865 6.0525C10.3708 5.9375 10.2275 5.88 10.0565 5.88C9.8855 5.88 9.74333 5.9375 9.63 6.0525C9.51667 6.1675 9.46 6.31 9.46 6.48V10.34C9.46 10.51 9.51783 10.6525 9.6335 10.7675C9.74918 10.8825 9.89251 10.94 10.0635 10.94ZM10.0053 18C8.9022 18 7.86553 17.79 6.89532 17.37C5.92511 16.95 5.07667 16.3767 4.35 15.65C3.62333 14.9233 3.05 14.0744 2.63 13.1032C2.21 12.132 2 11.0943 2 9.99C2 8.88575 2.21 7.84802 2.63 6.87682C3.05 5.90561 3.62333 5.06 4.35 4.34C5.07667 3.62 5.92561 3.05 6.89682 2.63C7.86802 2.21 8.90575 2 10.01 2C11.1143 2 12.152 2.21 13.1232 2.63C14.0944 3.05 14.94 3.62 15.66 4.34C16.38 5.06 16.95 5.90667 17.37 6.88C17.79 7.85333 18 8.89156 18 9.99468C18 11.0978 17.79 12.1345 17.37 13.1047C16.95 14.0749 16.38 14.9221 15.66 15.6463C14.94 16.3705 14.0933 16.9439 13.12 17.3663C12.1467 17.7888 11.1084 18 10.0053 18Z"
      fill="#FFF2F2"
      stroke="#F00000"
      strokeWidth="0.8"
    />
  </svg>
);

export default WarningIcon;
