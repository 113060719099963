import React, { FC, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ISimpleTabs } from './types';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';

const SimpleTabs: FC<ISimpleTabs> = (props) => {
  const { tabs = [], onDelete = () => {}, disabled = false, scrollView = true } = props;

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  const showOrHideButtons = useCallback(
    (scroll = scrollElementRef.current) => {
      if (scroll && tabsRef.current) {
        if (tabsRef.current.offsetWidth < scroll.scrollWidth) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        } else {
          hideButton(chevronRightRef.current, chevronRightIsHidden);
          hideButton(chevronLeftRef.current, chevronLefIsHidden);
        }
      }
    },
    [hideButton, showButton]
  );

  const scrollEvent = useCallback(() => {
    const value = scrollElementRef.current?.scrollLeft || 0;
    const maxValue = scrollElementRef.current?.scrollWidth || 0;
    const width = scrollElementRef.current?.offsetWidth || 0;
    if (value > 50) {
      showButton(chevronLeftRef.current, chevronLefIsHidden);
    } else if (value < 50) {
      hideButton(chevronLeftRef.current, chevronLefIsHidden);
    }
    if (value + width >= maxValue - 20) {
      hideButton(chevronRightRef.current, chevronRightIsHidden);
    } else {
      showButton(chevronRightRef.current, chevronRightIsHidden);
    }
  }, [hideButton, showButton]);

  useEffect(() => {
    if (!scrollElementRef.current && tabsRef.current && scrollView) {
      scrollElementRef.current = tabsRef.current;
      showOrHideButtons(scrollElementRef.current);
      scrollElementRef.current.onscroll = scrollEvent;
    }
  }, [tabsRef, tabsRef.current]);

  useEffect(() => {
    if (tabs && scrollView) {
      showOrHideButtons();
    }
  }, [tabs]);

  const scrollTo = useCallback(
    (toRight = true) =>
      () => {
        if (scrollElementRef.current) {
          scrollElementRef.current.scrollBy({ left: toRight ? 200 : -200, behavior: 'smooth' });
        }
      },
    []
  );

  return (
    <div className="simple-tabs">
      {scrollView ? (
        <>
          <div
            ref={chevronLeftRef}
            role="presentation"
            onClick={scrollTo(false)}
            className="tabs__tabs-button tabs__tabs-button_left tabs__tabs-button_hide"
          >
            <ChevronLeft />
          </div>
          <div
            ref={chevronRightRef}
            role="presentation"
            onClick={scrollTo()}
            className="tabs__tabs-button tabs__tabs-button_right tabs__tabs-button_hide"
          >
            <ChevronRight />
          </div>
          <div ref={tabsRef} className="simple-tabs__scroll-container default-scrollbar-override">
            {tabs.map((item, index) => (
              <div
                key={`${item.id}-${index}`}
                className={classNames('simple-tabs__item', {
                  'simple-tabs__item_disabled': item.disabled,
                })}
              >
                <div className="simple-tabs__item-label">{item.name}</div>
                {!disabled && !item.disabled && (
                  <div role="presentation" onClick={() => onDelete(item.id)} className="simple-tabs__item-icon">
                    <CloseBigIcon />
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      ) : (
        <div ref={tabsRef} className="simple-tabs__container default-scrollbar-override">
          {tabs.map((item, index) => (
            <div
              key={`${item.id}-${index}`}
              className={classNames('simple-tabs__item', {
                'simple-tabs__item_disabled': item.disabled,
              })}
            >
              <div className="simple-tabs__item-label">{item.name}</div>
              {!disabled && !item.disabled && (
                <div role="presentation" onClick={() => onDelete(item.id)} className="simple-tabs__item-icon">
                  <CloseBigIcon />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SimpleTabs;
