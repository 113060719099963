import classNames from 'classnames';
import React, { FC, useCallback, useEffect } from 'react';
import { IHardwareRange } from '../../../../../../../typings/systems/hardwareSettings';

import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import { INumberRangeField } from './types';

const NumberRangeField: FC<INumberRangeField> = ({ object, changeParam, index, wasSelected, disabled = false }) => {
  useEffect(() => {
    if (!object.value && !object.wasChange && wasSelected) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (name: string, value: string) => {
      if (object.errorData && Object.keys(object.errorData).length) {
        if (name === 'from' && object.errorData.fromErrorText) {
          delete object.errorData.fromErrorText;
        }
        if (name === 'to' && object.errorData.toErrorText) {
          delete object.errorData.toErrorText;
        }
      }
      if (object.errorData && Object.keys(object.errorData).length === 0) {
        object.isError = false;
        object.errorText = '';
      }
      object.wasChange = true;
      if (!object.value) {
        object.value = { from: null, to: null };
      }
      object.value[name as keyof IHardwareRange] = value === '' ? null : Number(value);
      if (object.value.to === null && object.value.from === null) {
        object.value = null;
      }
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  return (
    <div className="array-field range-field">
      <div className={classNames('array-field__content', { 'array-field__content_error': object.isError })}>
        <div
          className={classNames('array-field__title', {
            'array-field__title_disabled': object.readOnly,
          })}
        >
          {object.description}
          {object.isRequired && <span className="input__title-required">*</span>}
        </div>
        <div className="array-field__item">
          <div className="range-field__inputs">
            <Input
              value={object.value?.from?.toString()}
              onChange={(value) => onChange('from', value)}
              inputType={InputType.numbers}
              status={object.errorText || object.errorData?.fromErrorText ? InputStatus.error : InputStatus.normal}
              errorText={object.errorData?.fromErrorText || ''}
              maxLength={9}
              disabled={disabled}
              containerClassName={classNames({ 'array-field__input-disabled': object.readOnly })}
              isDisabledStyle={disabled && object.readOnly}
            />
            <Input
              value={object.value?.to?.toString()}
              onChange={(value) => onChange('to', value)}
              inputType={InputType.numbers}
              status={object.errorText || object.errorData?.toErrorText ? InputStatus.error : InputStatus.normal}
              errorText={object.errorData?.toErrorText || ''}
              maxLength={9}
              disabled={disabled}
              containerClassName={classNames({ 'array-field__input-disabled': object.readOnly })}
              isDisabledStyle={disabled && object.readOnly}
            />
          </div>
        </div>
      </div>
      {object.isError && <span className="input__error">{object.errorText}</span>}
    </div>
  );
};

export default NumberRangeField;
