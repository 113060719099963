import { Popover } from 'antd';
import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router';
import AuthService from '../../api/authService';
import SignOut from '../../assets/svg/icons/signOut';
import { paths } from '../../constants/paths';
import { useAppSelector } from '../../hooks/hooks';
import { getProfileShortNameString } from '../../store/selectors/profile';
import Button from '../ui/button';
import { ButtonSize, ButtonType } from '../ui/button/types';
import { IProfilePopover } from './types';

const ProfilePopover: FC<IProfilePopover> = (props) => {
  const { children, open = false, onOpenChange = () => {} } = props;
  const userEmail = AuthService.getUsername();
  const userName = useAppSelector(getProfileShortNameString);
  const navigate = useNavigate();

  const handleOnClickSignOut = useCallback(() => {
    navigate(paths.default);
    AuthService.doLogout();
  }, [navigate]);

  return (
    <div className="profile-popover">
      <Popover
        visible={open}
        onVisibleChange={onOpenChange}
        overlayClassName="profile-popover__dropdown"
        showArrow={false}
        trigger="click"
        placement="bottomRight"
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        align={{ offset: [0, -6] }}
        content={
          <div className="profile-popover__content">
            <div className="profile-popover__info">
              {userName && <span className="profile-popover__name">{userName}</span>}
              <span className="profile-popover__text">{userEmail}</span>
            </div>
            <div className="profile-popover__button-container">
              <Button
                className="profile-popover__button-exit"
                onClick={handleOnClickSignOut}
                type={ButtonType.secondary}
                size={ButtonSize.small}
              >
                <div className="profile-popover__button-icon">
                  <SignOut />
                </div>
                Выход
              </Button>
            </div>
          </div>
        }
      >
        <div className="profile-popover__children-wrapper">{children}</div>
      </Popover>
    </div>
  );
};

export default ProfilePopover;
