import React, { FC } from 'react';

const PPOIcon: FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32381_167710)">
      <circle cx="12" cy="12.5" r="11.6" stroke="#949494" strokeWidth="0.8" />
      <g clipPath="url(#clip1_32381_167710)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9767 5C14.5216 6.61316 16.8207 7.37634 18.7939 7.19592C19.1385 14.1659 16.5645 18.2821 12.0031 20C7.59818 18.3921 4.99271 14.453 5.18595 7.09021C7.50224 7.21143 9.7747 6.71069 11.9767 5ZM10.2366 11.7753H10.4121V11.3687C10.4121 10.9183 10.5896 10.5079 10.8756 10.2097C11.1639 9.90918 11.5619 9.72253 12.0004 9.72253C12.4389 9.72253 12.8371 9.90918 13.1252 10.2097C13.4112 10.5079 13.5887 10.9183 13.5887 11.3687V11.7753H13.7642C13.9067 11.7753 14.0234 11.8918 14.0234 12.0344V14.7729C14.0234 14.9155 13.9067 15.0321 13.7642 15.0321H10.2366C10.094 15.0321 9.97746 14.9155 9.97746 14.7729V12.0344C9.97733 11.8918 10.094 11.7753 10.2366 11.7753ZM10.9029 11.7753H13.0978V11.3687C13.0978 11.0485 12.9732 10.7584 12.7724 10.549C12.5738 10.3418 12.3005 10.2133 12.0003 10.2133C11.7002 10.2133 11.4268 10.3419 11.2283 10.549C11.0274 10.7584 10.9029 11.0486 10.9029 11.3687V11.7753ZM11.7876 13.5792L11.508 14.3116H12.4926L12.2336 13.5692C12.398 13.4846 12.5104 13.3132 12.5104 13.1156C12.5104 12.8339 12.282 12.6055 12.0003 12.6055C11.7185 12.6055 11.4903 12.8339 11.4903 13.1156C11.4903 13.3213 11.6121 13.4985 11.7876 13.5792ZM11.9794 5.86121C14.2321 7.28906 16.2673 7.96472 18.0138 7.80493C18.3188 13.9745 16.0404 17.6182 12.003 19.1387C8.1038 17.7155 5.79728 14.2289 5.96842 7.7113C8.01884 7.81873 10.0303 7.37549 11.9794 5.86121Z"
          fill="#949494"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_32381_167710">
        <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
      </clipPath>
      <clipPath id="clip1_32381_167710">
        <rect width="13.6488" height="15" fill="white" transform="translate(5.17578 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default PPOIcon;
