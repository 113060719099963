export enum ELoaderColor {
  gray = 'gray',
  blue = 'blue',
  black = 'black',
  white = 'white',
  red = 'red',
  green = 'green',
  yellow = 'yellow',
}

export interface ILoader {
  color?: ELoaderColor;
  className?: string;
}
