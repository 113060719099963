import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import { IFaqTableQuestions } from './types';
import UpArrow from '../../../../assets/svg/icons/upArrow';
import EditIcon from '../../../../assets/svg/icons/edit';
import TrashIcon from '../../../../assets/svg/icons/trash';
import DragAndDropIcon from '../../../../assets/svg/icons/dragAndDrop';
import { EFAQLvl, EFAQStatus, IFAQQuestion } from '../../../../typings/faq';
import { dateDefaultFormat } from '../../../../constants/date';
import { IConfirmData } from '../../../ui/universalModal/types';
import { defaultConfirm, deleteModal } from '../../../ui/universalModal/config';
import { useApi } from '../../../../hooks/useApi';
import { deleteRequest } from '../../../../api';
import { deleteFAQQuestionUrl } from '../../../../constants/api';
import UniversalModal from '../../../ui/universalModal';
import FaqQuestionEdit from '../questionEdit';
import { FAQLevelsMap } from '../types';
import SelectMultiple from '../../../ui/selectMultiple';
import { selectAllOptionKey } from '../../../../constants/select';

const FaqTableQuestions: FC<IFaqTableQuestions> = ({
  blockDragAndDrop = false,
  isLastElement = false,
  isChangeMode = false,
  setIsChangeMode = () => {},
  showIcon = true,
  question = null,
  isAdmin = false,
  index = 0,
  activeSection = '',
  systems = [],
  requestQuestions = () => {},
  isCreate = false,
  isSimpleMode = false,
  permissions,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [isEdit, setEdit] = useState<boolean>(false);

  const { sendRequest: deleteQuestion } = useApi(deleteRequest);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const isEditable = useMemo(
    () => (question ? (isAdmin ? question.level === EFAQLvl.platform : question.level === EFAQLvl.company) : false),
    [isAdmin, question]
  );

  const onChangeEditStatus = useCallback(
    (val: boolean) => {
      setEdit(val);
      setIsChangeMode(val);
    },
    [setIsChangeMode]
  );

  useEffect(() => {
    if (isCreate) {
      onChangeEditStatus(false);
    }
  }, [isCreate]);

  const onDeleteQuestion = useCallback(
    (item: IFAQQuestion) => () => {
      setConfirmData(
        deleteModal(
          'вопрос',
          async () => {
            closeConfirm();
            if (isOpen) {
              setIsOpen(false);
            }
            if (isEdit) {
              onChangeEditStatus(false);
            }
            await deleteQuestion(deleteFAQQuestionUrl(item?.id || ''));
            requestQuestions();
          },
          closeConfirm
        )
      );
    },
    [closeConfirm, deleteQuestion, isEdit, isOpen, onChangeEditStatus, requestQuestions]
  );

  const onCreated = useCallback(() => {
    onChangeEditStatus(false);
    requestQuestions();
  }, [onChangeEditStatus, requestQuestions]);

  const onChancelCreate = useCallback(() => {
    onChangeEditStatus(false);
  }, [onChangeEditStatus]);

  const getSelectOptions = useCallback(
    (object: IFAQQuestion) =>
      object.subscriberFaqQuestionObjects && object.subscriberFaqQuestionObjects.length
        ? object.subscriberFaqQuestionObjects.map((item) => item.objectId)
        : ['all'],
    []
  );

  const objectOptions = useMemo(
    () => [
      { value: selectAllOptionKey, title: 'Все' },
      ...systems.map((item) => ({
        value: item.id || '',
        title: item.objectName || '',
      })),
    ],
    [systems]
  );

  const renderQuestion = useCallback(
    () =>
      question ? (
        isEdit ? (
          <FaqQuestionEdit
            permissions={permissions}
            showIcon={showIcon}
            isAdmin={isAdmin}
            editItem={question}
            activeSection={activeSection}
            systems={systems}
            onChancelCreate={onChancelCreate}
            onCreated={onCreated}
            onDelete={onDeleteQuestion(question)}
          />
        ) : (
          <>
            <div
              className={classNames('faq-table__tr', {
                'faq-table__tr_last': isLastElement,
                'faq-table__tr_disabled': !isEditable && !isSimpleMode,
              })}
            >
              <div
                className={classNames('faq-table__td', { 'faq-table__td_hide': !permissions?.edit || isSimpleMode })}
              >
                {!blockDragAndDrop && isEditable && !isChangeMode && showIcon && <DragAndDropIcon />}
              </div>
              <div className="faq-table__td">{FAQLevelsMap.get(question.level)}</div>
              <div className="faq-table__td">
                <div
                  className={classNames('faq-table-question', {
                    'faq-table-question_open': isOpen,
                  })}
                >
                  <div className="faq-table-question__title">
                    <div
                      className={classNames('faq-table-question__status', {
                        'faq-table-question__status_inactive': question.status === EFAQStatus.inactive,
                      })}
                    />
                    <div className="faq-table-question__label">{question.question}</div>
                    <div
                      className={classNames('faq-table-question__icon', {
                        'faq-table-question__icon_open': isOpen,
                      })}
                      onClick={() => setIsOpen((flag: boolean) => !flag)}
                      role="presentation"
                    >
                      <UpArrow />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames('faq-table__td', {
                  'faq-table__td_hide': isSimpleMode || !(permissions?.edit || permissions?.delete),
                })}
              >
                <div className="faq-table__table-icons">
                  {isEditable && !isChangeMode && (
                    <>
                      {permissions?.edit && (
                        <div
                          onClick={() => onChangeEditStatus(true)}
                          role="presentation"
                          className="faq-table__table-icon faq-table__table-icon_edit"
                        >
                          <EditIcon />
                        </div>
                      )}
                      {permissions?.delete && (
                        <div
                          onClick={onDeleteQuestion(question)}
                          role="presentation"
                          className="faq-table__table-icon faq-table__table-icon_trash"
                        >
                          <TrashIcon />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {isOpen && (
              <div
                className={classNames('faq-table__tr faq-table-question__answer', {
                  'faq-table__tr_disabled': !isEditable,
                })}
              >
                <div className={classNames('faq-table__td', { 'faq-table__td_hide': isSimpleMode })} />
                <div className="faq-table__td" />
                <div className="faq-table__td">
                  <div className="faq-table-question__answer-content">
                    <div className="faq-table-question__answer-text">{question.answer}</div>
                    <div className="faq-table-question__answer-select">
                      <SelectMultiple
                        isDisabledCheckboxes
                        isSelectedAllDefault
                        title="Объекты"
                        values={getSelectOptions(question)}
                        options={objectOptions}
                      />
                    </div>
                    <div className="faq-table-question__answer-date">
                      Изменен: {moment(question.updateDate || question.createDate).format(dateDefaultFormat)}
                    </div>
                  </div>
                </div>
                <div className={classNames('faq-table__td', { 'faq-table__td_hide': isSimpleMode })} />
              </div>
            )}
          </>
        )
      ) : null,
    [
      question,
      isEdit,
      permissions,
      showIcon,
      isAdmin,
      activeSection,
      systems,
      onChancelCreate,
      onCreated,
      onDeleteQuestion,
      isLastElement,
      isEditable,
      isSimpleMode,
      blockDragAndDrop,
      isChangeMode,
      isOpen,
      getSelectOptions,
      objectOptions,
      onChangeEditStatus,
    ]
  );

  if (question)
    return (
      <>
        <UniversalModal data={confirmData} onClose={closeConfirm} />
        {blockDragAndDrop || !isEditable || isOpen || isEdit || isSimpleMode ? (
          renderQuestion()
        ) : (
          <Draggable key={question.id} draggableId={question.id || ''} index={index}>
            {(provided1) => (
              <div ref={provided1.innerRef} {...provided1.dragHandleProps} {...provided1.draggableProps}>
                {renderQuestion()}
              </div>
            )}
          </Draggable>
        )}
      </>
    );
  return null;
};

export default FaqTableQuestions;
