import React, { FC, useCallback, useMemo } from 'react';
import { IContactsForSubscribers } from './types';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import { IBuildingContact } from '../../../../typings/organization';
import Select from '../../../ui/select';
import Input from '../../../ui/input';
import CloseIcon from '../../../../assets/svg/icons/close';
import MaskInput from '../../../ui/input/maskInput';
import { ISelectOption } from '../../../ui/select/types';
import { IInputValue, InputStatus } from '../../../ui/input/types';
import { IContactInput } from '../types';

const ContactsForSubscribers: FC<IContactsForSubscribers> = (props) => {
  const {
    contacts = [],
    objectId = '',
    buildings = [],
    permissions = {},
    updateContacts = () => {},
    onDeleteContact = () => () => {},
    getContact = () => null,
  } = props;

  const addContact = useCallback(() => {
    if (objectId) {
      const contact = getContact(true);
      if (contact) {
        updateContacts([contact, ...contacts]);
      }
    }
  }, [contacts, getContact, objectId, updateContacts]);

  const onChange = useCallback(
    (index: number, field: string) => (e: string | number) => {
      const newContacts = [...contacts];
      const contact = newContacts[index];
      if (contact) {
        const contactField = contact[field as keyof IBuildingContact] as IInputValue;
        contactField.value = e.toString();
        contactField.status = InputStatus.normal;
        contactField.errorText = '';
      }
      updateContacts(newContacts);
    },
    [contacts, updateContacts]
  );

  const buildingOptions = useMemo(
    () => buildings.filter((item) => !contacts.find((contact) => contact.buildingId.value === item.buildingId)) || [],
    [buildings, contacts]
  );

  const getBuildingOptions = useCallback(
    (contact: IContactInput): ISelectOption[] => {
      const result = [...buildingOptions];
      if (contact.buildingId) {
        const building = buildings.find((build) => build.buildingId === contact.buildingId.value);
        if (building) {
          result.push(building);
        }
      }
      return result.map((item) => {
        const building = buildings.find((build) => build.buildingId === item.buildingId);
        return {
          value: item.buildingId || '',
          title: building?.address || '',
        };
      });
    },
    [buildingOptions, buildings]
  );

  return (
    <div className="contacts-for-subscribers">
      <div className="contacts-for-subscribers__title">
        <div className="contacts-for-subscribers__title-label">
          Все контактные данные: {permissions.edit && <br />} адрес, график работы и номер телефона будут отображены в
          МП абонента
        </div>
        {permissions.edit && (buildings.length || 0) > (contacts?.length || 0) && (
          <Button onClick={addContact} type={ButtonType.outline}>
            Добавить контакт
          </Button>
        )}
      </div>
      <div className="contacts-for-subscribers__content">
        {contacts.map((contact, index) => (
          <div key={`contact-${contact.buildingId}-${index}`} className="contacts-for-subscribers__building">
            <div className="contacts-for-subscribers__building-index">{index + 1}.</div>
            <div className="contacts-for-subscribers__building-content">
              <div className="contacts-for-subscribers__building-select">
                <Select
                  title="Строение"
                  onChange={onChange(index, 'buildingId')}
                  isError={contact.buildingId.status === InputStatus.error}
                  errorText={contact.buildingId.errorText}
                  isRequired={contact.buildingId.isRequired}
                  value={contact.buildingId.value}
                  options={getBuildingOptions(contact)}
                  placeholder="Строение"
                  disabled={!permissions.edit}
                />
              </div>
              <div className="contacts-for-subscribers__building-inputs">
                <Input
                  title="Адрес нахождения"
                  placeholder="Адрес нахождения"
                  maxLength={100}
                  status={contact.address.status}
                  errorText={contact.address.errorText}
                  isRequired={contact.address.isRequired}
                  value={contact.address.value}
                  onChange={onChange(index, 'address')}
                  disabled={!permissions.edit}
                />
                <Input
                  title="График работы"
                  placeholder="График работы"
                  maxLength={100}
                  status={contact.schedule.status}
                  errorText={contact.schedule.errorText}
                  isRequired={contact.schedule.isRequired}
                  value={contact.schedule.value}
                  onChange={onChange(index, 'schedule')}
                  disabled={!permissions.edit}
                />
                <MaskInput
                  onChange={onChange(index, 'phone')}
                  title="Номер телефона"
                  placeholder="+7 (123) 456 78 89"
                  status={contact.phone.status}
                  errorText={contact.phone.errorText}
                  isRequired={contact.phone.isRequired}
                  value={contact.phone.value}
                  disabled={!permissions.edit}
                />
                {permissions.edit ? (
                  contact.isNew ? (
                    <div className="close-icon" role="presentation" onClick={onDeleteContact(index)}>
                      <CloseIcon />
                    </div>
                  ) : (
                    <Button onClick={onDeleteContact(index)} type={ButtonType.tertiary}>
                      Удалить контакт
                    </Button>
                  )
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactsForSubscribers;
