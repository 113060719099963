import React, { FC } from 'react';

const EmptyFAQList: FC = () => (
  <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M39.2738 12.3225H38.6287V2H6.37093V12.3225H5.72577C3.94699 12.3225 2.5 13.7695 2.5 15.5483V34.9029C2.5 36.6817 3.94699 38.1287 5.72577 38.1287H14.1128V40.7093H11.5322V41.9996H33.4674V40.7093H30.8868V38.1287H39.2738C41.0526 38.1287 42.4996 36.6817 42.4996 34.9029V15.5483C42.4996 13.7695 41.0526 12.3225 39.2738 12.3225ZM7.66124 8.45155H37.3384V29.0965H7.66124V8.45155ZM37.3384 3.29031V7.16124H7.66124V3.29031H37.3384ZM5.72577 13.6128H6.37093V30.3868H38.6287V13.6128H39.2738C40.341 13.6128 41.2093 14.4811 41.2093 15.5483V32.9674H3.79031V15.5483C3.79031 14.4811 4.65865 13.6128 5.72577 13.6128ZM29.5965 40.7093H15.4031V38.1287H29.5965V40.7093ZM39.2738 36.8384H5.72577C4.65865 36.8384 3.79031 35.97 3.79031 34.9029V34.2577H41.2093V34.9029C41.2093 35.97 40.341 36.8384 39.2738 36.8384Z"
      fill="#1444F2"
    />
    <path d="M8.95117 4.58008H10.2415V5.87039H8.95117V4.58008Z" fill="#1444F2" />
    <path d="M11.5332 4.58008H12.8235V5.87039H11.5332V4.58008Z" fill="#1444F2" />
    <path d="M14.1133 4.58008H15.4036V5.87039H14.1133V4.58008Z" fill="#1444F2" />
    <path
      d="M22.8423 11.0333H22.1571C20.2122 11.0333 18.6289 12.6165 18.6289 14.5617V15.5494H19.9192V14.5617C19.9192 13.3273 20.9229 12.3236 22.1574 12.3236H22.8425C24.0767 12.3236 25.0805 13.3273 25.0805 14.5617C25.0805 15.3436 24.6637 16.079 23.9935 16.481L21.8547 17.7647V21.3557H23.145V18.4951L24.658 17.5873C25.7147 16.9537 26.3708 15.7939 26.3708 14.5614C26.3708 12.6165 24.7875 11.0332 22.8423 11.0332V11.0333Z"
      fill="#1444F2"
    />
    <path
      d="M22.4999 22.6445C21.4328 22.6445 20.5645 23.5129 20.5645 24.58C20.5645 25.6471 21.4328 26.5155 22.4999 26.5155C23.567 26.5155 24.4354 25.6471 24.4354 24.58C24.4354 23.5129 23.567 22.6445 22.4999 22.6445ZM22.4999 25.2252C22.1445 25.2252 21.8548 24.9354 21.8548 24.58C21.8548 24.2246 22.1445 23.9348 22.4999 23.9348C22.8553 23.9348 23.1451 24.2246 23.1451 24.58C23.1451 24.9354 22.8553 25.2252 22.4999 25.2252Z"
      fill="#1444F2"
    />
    <path d="M8.95117 9.74219H17.3382V11.0325H8.95117V9.74219Z" fill="#1444F2" />
    <path d="M8.95117 12.3223H17.3382V13.6126H8.95117V12.3223Z" fill="#1444F2" />
    <path d="M8.95117 14.9023H17.3382V16.1927H8.95117V14.9023Z" fill="#1444F2" />
    <path d="M8.95117 17.4844H17.3382V18.7747H8.95117V17.4844Z" fill="#1444F2" />
    <path d="M8.95117 20.0645H17.3382V21.3548H8.95117V20.0645Z" fill="#1444F2" />
    <path d="M27.6621 16.1934H36.0491V17.4837H27.6621V16.1934Z" fill="#1444F2" />
    <path d="M27.6621 18.7734H36.0491V20.0637H27.6621V18.7734Z" fill="#1444F2" />
    <path d="M27.6621 21.3555H36.0491V22.6458H27.6621V21.3555Z" fill="#1444F2" />
    <path d="M27.6621 23.9355H36.0491V25.2259H27.6621V23.9355Z" fill="#1444F2" />
    <path d="M27.6621 26.5156H36.0491V27.8059H27.6621V26.5156Z" fill="#1444F2" />
  </svg>
);

export default EmptyFAQList;
