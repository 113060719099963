import classNames from 'classnames';
import React, { FC, ReactElement, useCallback, useMemo } from 'react';
import { ERestrictsType, IHardwareParam } from '../../../../../../typings/systems/hardwareSettings';
import ArrayField from './arrayField';
import ArrayNumberRangeField from './arrayNumberRangeField';
import BooleanField from './booleanField';
import NumberField from './numberField';
import NumberRangeField from './numberRangeField';
import StringField from './stringField';
import { IHardwareMapGroups, IHardwareMapParam, IParamField, ISettingsSection } from './types';

const SettingsSection: FC<ISettingsSection> = ({ settings, changeSettings, wasSelected, permissions = {} }) => {
  const inputsMap = useMemo(
    () =>
      new Map<ERestrictsType, React.FC<IParamField>>([
        [ERestrictsType.string, StringField],
        [ERestrictsType.number, NumberField],
        [ERestrictsType.boolean, BooleanField],
        [ERestrictsType.array, ArrayField],
        [ERestrictsType.arrayNumberRange, ArrayNumberRangeField],
        [ERestrictsType.numberRange, NumberRangeField],
      ]),
    []
  );

  const changeParam = useCallback(
    (index: number, param: IHardwareParam) => {
      if (settings && settings.accessPointTypeParams) {
        settings.accessPointTypeParams[index] = param;
        changeSettings({ ...settings });
      }
    },
    [changeSettings, settings]
  );

  const getGroupsArray = useCallback((data: IHardwareParam[]) => {
    const groups = new Map<string, IHardwareMapGroups>();

    data.forEach((item, index) => {
      const name = item.groupName || '';
      if (!name) {
        const object = groups.get('Другие настройки');
        if (object) {
          object.collection.push({ object: item, index });
        } else {
          groups.set('Другие настройки', { collection: [{ object: item, index }], position: Infinity });
        }
      } else if (groups.has(name)) {
        const object = groups.get(name);
        if (object) {
          object.collection.push({ object: item, index });
        }
      } else {
        groups.set(name, { collection: [{ object: item, index }], position: item.groupNameOrder || 0 });
      }
    });

    const groupsArray = Array.from(groups, ([name, value]) => ({ name, ...value }));

    groupsArray.forEach((item) => {
      item.collection.sort((prev, next) => (prev.object.order || 0) - (next.object.order || 0));
    });

    return groupsArray.sort((prev, next) => prev.position - next.position);
  }, []);

  const renderParam = useCallback(
    (item: IHardwareMapParam, i: number) => {
      const { object, index } = item;
      const { type } = object.restricts;
      let result: ReactElement | null | string = 'Неизвестный тип';
      if (inputsMap.has(type)) {
        const Component = inputsMap.get(type);
        if (Component) {
          result = (
            <div
              key={item.object.id || `input-${i}-${index}`}
              className={classNames('setting-section__group-cell', {
                'setting-section__group-cell_third-thirds':
                  type === ERestrictsType.array || type === ERestrictsType.arrayNumberRange,
                'setting-section__group-cell_two-thirds': type === ERestrictsType.numberRange,
              })}
            >
              <Component
                object={object}
                changeParam={changeParam}
                index={index}
                wasSelected={wasSelected}
                disabled={Boolean(!permissions?.edit) || object.readOnly}
                isEditEnable={permissions?.edit}
              />
            </div>
          );
        }
      }
      return result;
    },
    [changeParam, inputsMap, permissions, wasSelected]
  );

  const renderAdvancedOptions = useCallback(
    (data: IHardwareParam[]) =>
      getGroupsArray(data).map((group, index) => (
        <div key={`group-${index}`} className="setting-section__group">
          <div
            className={classNames('setting-section__group-title', {
              'setting-section__group-title_disabled': !group.collection.find((item) => !item.object.readOnly),
            })}
          >
            {group.name || `Группа ${index + 1}`}
          </div>
          <div className="setting-section__group-items">{group.collection.map((item, i) => renderParam(item, i))}</div>
        </div>
      )),
    [getGroupsArray, renderParam]
  );

  return <div className="setting-section">{renderAdvancedOptions(settings.accessPointTypeParams || [])}</div>;
};

export default SettingsSection;
