import classNames from 'classnames';
import React, { FC, useEffect, useRef } from 'react';
import { IStatusPopUpComponent } from './types';
import { EDispatcherDeviceStatus, fromStatusToColor } from '../../../typings/dispatcherPanel';

const StatusPopUpComponent: FC<IStatusPopUpComponent> = (props) => {
  const { wrapClassName = '', data = [], position, onClickPopUp = () => {}, onClosePopUp = () => {} } = props;

  const popUpRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && data.length && popUpRef.current && position) {
      popUpRef.current.style.transform = `translate(${position.x - 400}px, ${
        position.y - popUpRef.current.offsetHeight
      }px)`;
      popUpRef.current.style.display = 'block';
    } else if (popUpRef.current) {
      popUpRef.current.style.display = 'none';
    }
  }, [data, position]);

  return !data || data.length === 0 ? null : (
    <>
      <div ref={popUpRef} className={classNames('status-pop-up', wrapClassName)}>
        {data.map((item, index) => (
          <div
            role="presentation"
            onClick={() => onClickPopUp(index)}
            key={`${item.id}-${index}`}
            className="status-pop-up__item"
          >
            <div
              className={`status-pop-up__item-title dispatcher-panel__status dispatcher-panel__status_${fromStatusToColor.get(
                item.status as EDispatcherDeviceStatus
              )}`}
            >
              {item.title}
            </div>
            <div className="status-pop-up__item-text">{item.text}</div>
          </div>
        ))}
      </div>
      <div role="presentation" onClick={onClosePopUp} className="status-pop-up__background" />
    </>
  );
};

export default StatusPopUpComponent;
