import React, { FC } from 'react';

const SearchIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.5 15.5L19.5 19.5" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SearchIcon;
