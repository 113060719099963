import classNames from 'classnames';
import React, { FC } from 'react';
import { IInfoPopUpComponent } from './types';

const InfoPopUpComponent: FC<IInfoPopUpComponent> = (props) => {
  const { wrapClassName = '', data, statuses } = props;

  return !data ? null : (
    <div className={classNames('info-pop-up', wrapClassName)}>
      <div className="dispatcher-info-item__title-label">{data?.objectName}</div>
      <div className="dispatcher-info-item__list">
        {statuses?.map((status, index) => (
          <div
            key={`status-${status.id}-${index}`}
            className={`dispatcher-info-item__list-item dispatcher-panel__status dispatcher-panel__status_${status.colorName}`}
            role="presentation"
          >
            {status.value} {status.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoPopUpComponent;
