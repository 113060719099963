import { CheckboxChangeEvent } from 'antd/es/checkbox';
import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { Checkbox as CheckboxComponent } from 'antd';
import { ICheckbox } from './types';

const Checkbox: FC<ICheckbox> = (props) => {
  const {
    disabled = false,
    isDisabledStyle = false,
    defaultChecked = false,
    checked = false,
    label = '',
    onChange = () => {},
    valueKey,
    className = '',
    error = false,
  } = props;

  const handleOnChange = useCallback(
    (event: CheckboxChangeEvent) => {
      onChange(event.target.checked, valueKey);
    },
    [onChange, valueKey]
  );

  return (
    <CheckboxComponent
      checked={checked}
      disabled={disabled}
      defaultChecked={defaultChecked}
      onChange={handleOnChange}
      className={classNames('checkbox', className, {
        checkbox__checked: checked,
        'checkbox__label-disable': !label,
        checkbox__checked_disabled: isDisabledStyle,
        checkbox__error: error && !checked,
      })}
    >
      {label}
    </CheckboxComponent>
  );
};

export default Checkbox;
