import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { IProfilePermission, ISettings } from '../../typings/profile';
import { ITreeNode } from '../../typings/treeNode';
import {
  IVisualizerMode,
  ISelectedSchemaEntity,
  ISchemaBackground,
  ISchemaOpacity,
} from '../systemTabs/schemaTab/types';

export enum EPaperHitResults {
  stroke = 'stroke',
}

export enum EPaperGroupTypes {
  group = 'group',
  object = 'object',
}

export enum EEditTypes {
  rotate = 'rotate',
  rescale = 'rescale',
}

export interface IPathExtremes {
  maxX: number;
  minX: number;
  maxY: number;
  minY: number;
}

export interface ISchemaCanvas {
  opacityValues?: ISchemaOpacity;
  background?: ISchemaBackground;
  schema: ITreeNode[];
  selectedNode?: ISelectedSchemaEntity;
  onSelectNode?: (keyValue: string) => void;
  buttons?: ItemType[];
  settings?: ISettings;
  currentMode: IVisualizerMode;
  setPath?: (path: TPaperPath, wasOpen?: boolean) => void;
  displayedObjects?: CheckboxValueType[];
  haveBeenChanges?: () => void;
  permissions?: IProfilePermission;
  isFullScreen?: boolean;
  onResizeTab?: () => void;
  onEdit?: (key: string) => void;
  onDelete?: (key: string) => void;
  isFiveThousandth?: boolean;
}

export type TPaperGroup = paper.Item;

export type TPaperEvent = paper.MouseEvent;

export type TPaperPoint = paper.Point;

export type TPaperPath = paper.Path;

export type TPaperPathItem = paper.PathItem;

export type TPaperCircle = paper.Path.Circle;

export type TPaperShape = paper.Shape;

export type TPaperSegment = paper.Segment;

export type TPaperLine = paper.Path.Line;

export type TPaperText = paper.PointText;

export type TPaperToolEvent = paper.ToolEvent;

export type TPaperTool = paper.Tool;

export type TPaperLayer = paper.Layer;

export type TPaperRectangle = paper.Rectangle;
