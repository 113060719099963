import classNames from 'classnames';
import React, { FC } from 'react';
import { Modal as ModalComponent } from 'antd';
import CloseIcon from '../../../assets/svg/icons/close';
import { IModal } from './types';

const Modal: FC<IModal> = (props) => {
  const {
    isOpen = false,
    onOk = () => {},
    onCancel = () => {},
    title,
    children,
    footer,
    wrapClassName = '',
    width,
    showCloseIcon = true,
    centered = false,
  } = props;

  return (
    <ModalComponent
      title={title}
      visible={isOpen}
      onOk={onOk}
      onCancel={onCancel}
      closable={showCloseIcon}
      wrapClassName={classNames('modal', wrapClassName)}
      destroyOnClose
      centered={centered}
      footer={footer}
      width={width}
      closeIcon={<CloseIcon />}
    >
      {children}
    </ModalComponent>
  );
};

export default Modal;
