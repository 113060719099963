import React from 'react';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  outline = 'outline',
  tertiary = 'tertiary',
}

export enum ButtonSize {
  small = 'small',
  medium = 'medium',
}

export interface IButton {
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  loaderClassName?: string;
  disabled?: boolean;
  hidden?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  loading?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}
