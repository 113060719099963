import React, { FC } from 'react';

const SortIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.8397 20.1642V6.54639" stroke="#1444F2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M20.9173 16.0684L16.8395 20.165L12.7617 16.0684"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M6.91102 3.83301V17.4508" stroke="#1444F2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M2.8335 7.92919L6.91127 3.83252L10.9891 7.92919"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SortIcon;
