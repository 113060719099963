// Формулы https://eltispisrv03.f-software.ru:9192/pages/viewpage.action?pageId=13664312
// P – фиксированная доля Элтис в рублях
// O – сумма за тариф, которую выставляет Организация в рублях
// D – скидка в %, которую выставляет Организация за период
// M – количество месяцев
// E – комиссия за эквайринг в %, платит организация
// Т - сумма тех.обслуживания, которую установила Организация в рублях за месяц для всех тарифов

// Фиксированная стоимость
export const calculateFixedCost = (P: number, O: number, M: number, D: number, T: number) =>
  (O * M * (100 - D)) / 100 + (P + T) * M;

// Фиксированная стоимость для элтис
export const calculateFixedCostElts = (P: number, M: number) => P * M;

// Фиксированная стоимость для организации
export const calculateFixedCostOrg = (P: number, O: number, M: number, D: number, T: number, E: number) =>
  (calculateFixedCost(P, O, M, D, T) * (100 - E)) / 100 - calculateFixedCostElts(P, M);
