import { DataNode } from 'antd/es/tree';
import { Key } from 'antd/lib/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { getAccessGroupTreeKey, getTreeKey, resolveAccessGroupTreeKey } from '../../../../utils/tree';
import DirectoryTree from '../../../ui/directoryTree';
import { IAccessGroupsTree } from './types';

const AccessGroupsTree: FC<IAccessGroupsTree> = ({ data = [], selectedNode, ...props }) => {
  const expandDefault = (nodes: DataNode[], keys: Key[] = []): Key[] => {
    nodes.forEach((node) => {
      const type = resolveAccessGroupTreeKey(node.key.toString())[1];
      if (type === TreeNodeType.restrictedArea || type === TreeNodeType.object) {
        keys.push(node.key);
        expandDefault(node.children || [], keys);
      }
    });
    return keys;
  };

  const renderNode = (node: ITreeNode, key = '', deep = 0) => (
    <>
      <div
        className={classNames(
          'ant-tree-title-name',
          !deep &&
            (node.objectItemTypes === TreeNodeType.object ||
              node.objectItemTypes === TreeNodeType.building ||
              node.objectItemTypes === TreeNodeType.restrictedArea) &&
            'ant-tree-title-name_bold'
        )}
      >{`${node.name} ${'prefix' in node ? node.prefix || '' : ''}`}</div>
      {'address' in node && node.address && <div className="ant-tree-title-address">{node.address}</div>}
    </>
  );

  const renderTree = useCallback(
    (renderData: ITreeNode[], deep = 0, key = ''): DataNode[] =>
      renderData.map((node: ITreeNode, index: number) => {
        const defaultKey = getTreeKey(key, index, (node.objectItemTypes || '') as TreeNodeType);
        const newKey = getAccessGroupTreeKey(node.id || '', node.objectItemTypes || '', defaultKey);
        return {
          title: renderNode({ ...node }, newKey, deep),
          key: newKey,
          isLeaf: !(node.children && node.children.length > 0),
          children: renderTree(node.children || [], deep + 1, defaultKey),
        };
      }),
    []
  );

  const treeData = useMemo<DataNode[]>(() => renderTree(data), [data, renderTree]);

  return (
    <DirectoryTree
      expandDefault={expandDefault}
      selectedKeys={selectedNode ? [selectedNode?.originalKey] : []}
      treeData={treeData}
      {...props}
    />
  );
};

export default AccessGroupsTree;
