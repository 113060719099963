import { Button as ButtonComponent } from 'antd';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import Loader from '../loader';
import { ELoaderColor } from '../loader/types';
import { ButtonSize, ButtonType, IButton } from './types';

const Button: FC<IButton> = ({ children, ...props }) => {
  const {
    onClick = () => {},
    disabled = false,
    hidden = false,
    type = ButtonType.primary,
    size = ButtonSize.medium,
    className = '',
    loaderClassName = '',
    loading = false,
    leftIcon,
    rightIcon,
  } = props;

  const buttonLoaderColor = useMemo(() => {
    switch (type) {
      case ButtonType.primary:
        return ELoaderColor.white;
      case ButtonType.outline:
        return ELoaderColor.blue;
      case ButtonType.secondary:
        return ELoaderColor.black;
      case ButtonType.tertiary:
        return ELoaderColor.red;
      default:
        return ELoaderColor.blue;
    }
  }, [type]);

  return (
    <ButtonComponent
      onClick={onClick}
      disabled={disabled || loading}
      hidden={hidden}
      className={classNames('button', className, {
        button__primary: type === ButtonType.primary,
        button__secondary: type === ButtonType.secondary,
        button__outline: type === ButtonType.outline,
        button__tertiary: type === ButtonType.tertiary,
        'button__size-small': size === ButtonSize.small,
        'button__size-medium': size === ButtonSize.medium,
      })}
    >
      {leftIcon && (
        <div
          className={classNames('button__icon button__icon_left', {
            'button__icon-size-small': size === ButtonSize.small,
            'button__icon-size-medium': size === ButtonSize.medium,
          })}
        >
          {leftIcon}
        </div>
      )}
      {children}
      {loading ? (
        <div className={classNames('button__loader-container', loaderClassName)}>
          <Loader
            className={classNames({
              'button__icon-size-small': size === ButtonSize.small,
              'button__icon-size-medium': size === ButtonSize.medium,
            })}
            color={buttonLoaderColor}
          />
        </div>
      ) : (
        rightIcon && (
          <div
            className={classNames('button__icon button__icon_right', {
              'button__icon-size-small': size === ButtonSize.small,
              'button__icon-size-medium': size === ButtonSize.medium,
            })}
          >
            {rightIcon}
          </div>
        )
      )}
    </ButtonComponent>
  );
};

export default Button;
