import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { ITariffRecord } from './types';
import ChevronDown from '../../../assets/svg/icons/chevronDown';

const TariffsTableRecord: FC<ITariffRecord> = (props) => {
  const { name = '', description = '' } = props;
  const [arrowUp, setArrowPosition] = useState(true);

  return (
    <div className="tariff-table-record" role="presentation" onClick={() => setArrowPosition((flag) => !flag)}>
      <div className="tariff-table-record__content">
        <div className="tariff-table-record__name">{name}</div>
        <div className={classNames('tariff-table-record__arrow', { 'tariff-table-record__arrow_up': arrowUp })}>
          <ChevronDown />
        </div>
      </div>
      <div
        className={classNames('tariff-table-record__description', {
          'tariff-table-record__description_show': arrowUp,
        })}
      >
        {description}
      </div>
    </div>
  );
};

export default TariffsTableRecord;
