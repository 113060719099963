import React, { FC } from 'react';

const BurningStartIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9456 9.54675C11.7729 9.71408 11.6936 9.95608 11.7329 10.1934L12.3256 13.4734C12.3756 13.7514 12.2583 14.0327 12.0256 14.1934C11.7976 14.3601 11.4943 14.3801 11.2456 14.2467L8.29292 12.7067C8.19026 12.6521 8.07626 12.6227 7.95959 12.6194H7.77892C7.71626 12.6287 7.65493 12.6487 7.59893 12.6794L4.64559 14.2267C4.49959 14.3001 4.33426 14.3261 4.17226 14.3001C3.77759 14.2254 3.51426 13.8494 3.57893 13.4527L4.17226 10.1727C4.21159 9.93341 4.13226 9.69008 3.95959 9.52008L1.55226 7.18675C1.35093 6.99141 1.28093 6.69808 1.37293 6.43341C1.46226 6.16941 1.69026 5.97675 1.96559 5.93341L5.27892 5.45275C5.53093 5.42675 5.75226 5.27341 5.86559 5.04675L7.32559 2.05341C7.36026 1.98675 7.40493 1.92541 7.45893 1.87341L7.51892 1.82675C7.55026 1.79208 7.58626 1.76341 7.62626 1.74008L7.69893 1.71341L7.81226 1.66675H8.09293C8.34359 1.69275 8.56426 1.84275 8.67959 2.06675L10.1589 5.04675C10.2656 5.26475 10.4729 5.41608 10.7123 5.45275L14.0256 5.93341C14.3056 5.97341 14.5396 6.16675 14.6323 6.43341C14.7196 6.70075 14.6443 6.99408 14.4389 7.18675L11.9456 9.54675Z"
      fill="#FFB015"
    />
  </svg>
);

export default BurningStartIcon;
