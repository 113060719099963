import classNames from 'classnames';
import React, { FC } from 'react';
import { ITabSwitch } from './types';

const TabSwitch: FC<ITabSwitch> = (props) => {
  const { active = '', tabs = [], onChange = () => {} } = props;

  return (
    <div className="tab-switch">
      {tabs.map((item) => (
        <div
          key={item.id}
          role="presentation"
          onClick={() => onChange(item.id)}
          className={classNames('tab-switch__item', { 'tab-switch__item_active': active === item.id })}
        >
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default TabSwitch;
