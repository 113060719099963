import classNames from 'classnames';
import React, { FC } from 'react';
import { ELoaderColor, ILoader } from './types';

const Loader: FC<ILoader> = (props) => {
  const { color = ELoaderColor.gray, className = '' } = props;

  return (
    <div className={classNames('loader', className)}>
      <svg
        className={classNames('loader__content', {
          loader__content_gray: color === ELoaderColor.gray,
          loader__content_blue: color === ELoaderColor.blue,
          loader__content_black: color === ELoaderColor.black,
          loader__content_white: color === ELoaderColor.white,
          loader__content_red: color === ELoaderColor.red,
          loader__content_green: color === ELoaderColor.green,
          loader__content_yellow: color === ELoaderColor.yellow,
        })}
        viewBox="25 25 50 50"
        strokeWidth="4"
      >
        <circle cx="50" cy="50" r="20" />
      </svg>
    </div>
  );
};

export default Loader;
