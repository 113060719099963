import React, { FC } from 'react';

const MediumPriorityIcon: FC = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 8.41667C6.25 8.64679 6.34327 8.83333 6.45833 8.83333H13.5417C13.6567 8.83333 13.75 8.64679 13.75 8.41667C13.75 8.18655 13.6567 8 13.5417 8H6.45833C6.34327 8 6.25 8.18655 6.25 8.41667ZM6.25 12.5833C6.25 12.8135 6.34327 13 6.45833 13H13.5417C13.6567 13 13.75 12.8135 13.75 12.5833C13.75 12.3532 13.6567 12.1667 13.5417 12.1667H6.45833C6.34327 12.1667 6.25 12.3532 6.25 12.5833Z"
      fill="#F06500"
    />
  </svg>
);

export default MediumPriorityIcon;
