import React, { FC, useCallback, useMemo } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ISubscribersErrorsModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize } from '../../ui/button/types';
import { ISubscribersErrorSub } from '../../../typings/subscribers';
import Scrollbar from '../../ui/scrollbar';

const SubscribersErrorsModal: FC<ISubscribersErrorsModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, errorsData } = props;

  const handleOnClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const renderCell = useCallback(
    (data: string | number, isError: boolean, errorsList: string[]) => (
      <>
        <div className="upload-subscribers__cell">
          <div className="upload-subscribers__cell-data">{data}</div>
          {isError && (
            <div className="upload-subscribers__cell-errors">
              {errorsList.map((item, index) => (item ? <div key={`${data}-${index}`}>{item}</div> : null))}
            </div>
          )}
        </div>
        <div
          className={`upload-subscribers__cell-borders ${isError ? 'upload-subscribers__cell-borders_error' : ''}`}
        />
      </>
    ),
    []
  );

  const columns = useMemo<ColumnsType<ISubscribersErrorSub>>(
    () => [
      {
        title: '№',
        dataIndex: 'rowId',
        key: 'rowId',
        render: (_, record: ISubscribersErrorSub) => renderCell(record.rowId, false, []),
        width: '5%',
      },
      {
        title: 'Фамилия',
        dataIndex: 'lastName',
        key: 'lastName',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(
            record.lastName,
            record.validationResult?.isLastNameEmpty || !record.validationResult?.isLastNameLengthValid,
            [
              record.validationResult?.isLastNameEmpty ? 'Не заполнено обязательное поле' : '',
              !record.validationResult?.isLastNameEmpty && !record.validationResult?.isLastNameLengthValid
                ? 'Слишком длинная фамилия'
                : '',
            ]
          ),
        width: '10%',
      },
      {
        title: 'Имя',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(
            record.firstName,
            record.validationResult?.isFirstNameEmpty || !record.validationResult?.isFirstNameLengthValid,
            [
              record.validationResult?.isFirstNameEmpty ? 'Не заполнено обязательное поле' : '',
              !record.validationResult?.isFirstNameEmpty && !record.validationResult?.isFirstNameLengthValid
                ? 'Слишком длинное имя'
                : '',
            ]
          ),
        width: '10%',
      },
      {
        title: 'Отчество',
        dataIndex: 'middleName',
        key: 'middleName',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(record.middleName, !record.validationResult?.isMiddleNameLengthValid, [
            record.validationResult?.isMiddleNameLengthValid ? 'Слишком длинное отчество' : '',
          ]),
        width: '10%',
      },
      {
        title: 'Номер телефона',
        dataIndex: 'phone',
        key: 'phone',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(
            record.phone,
            record.validationResult?.isPhoneEmpty ||
              !record.validationResult?.isPhoneLengthValid ||
              !record.validationResult?.isPhoneValid,
            [
              record.validationResult?.isPhoneEmpty ? 'Не заполнено обязательное поле' : '',
              !record.validationResult?.isPhoneEmpty && !record.validationResult?.isPhoneValid
                ? 'Некорректный номер телефона'
                : '',
              !record.validationResult?.isPhoneEmpty && !record.validationResult?.isPhoneLengthValid
                ? 'Длина не соответствует номеру телефона'
                : '',
            ]
          ),
        width: '10%',
      },
      {
        title: 'E–mail',
        dataIndex: 'email',
        key: 'email',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(
            record.email,
            record.validationResult?.isEmailEmpty ||
              !record.validationResult?.isEmailLengthValid ||
              !record.validationResult?.isEmailValid,
            [
              record.validationResult?.isEmailEmpty ? 'Не заполнено обязательное поле' : '',
              !record.validationResult?.isEmailEmpty && !record.validationResult?.isEmailValid
                ? 'Некорректная почта'
                : '',
              !record.validationResult?.isEmailEmpty && !record.validationResult?.isEmailLengthValid
                ? 'Слишком длинная почта'
                : '',
            ]
          ),
        width: '10%',
      },
      {
        title: 'Объект',
        dataIndex: 'object',
        key: 'object',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(
            record.object,
            record.validationResult?.isObjectEmpty ||
              !record.validationResult?.isObjectLengthValid ||
              !record.validationResult?.isObjectValid,
            [
              record.validationResult?.isObjectEmpty ? 'Не заполнено обязательное поле' : '',
              !record.validationResult?.isObjectEmpty && !record.validationResult?.isObjectValid
                ? 'Некорректный объект'
                : '',
              !record.validationResult?.isObjectEmpty && !record.validationResult?.isObjectLengthValid
                ? 'Слишком длинное название объекта'
                : '',
            ]
          ),
        width: '10%',
      },
      {
        title: 'Адрес',
        dataIndex: 'address',
        key: 'address',
        render: (_, record: ISubscribersErrorSub) =>
          renderCell(
            `${record.address || ''}${record.flatNumber ? `, кв. ${record.flatNumber}` : ''}`,
            record.validationResult?.isAddressEmpty ||
              !record.validationResult?.isAddressValid ||
              record.validationResult?.isFlatNumberEmpty ||
              !record.validationResult?.isFlatNumberValid,
            [
              record.validationResult?.isAddressEmpty || record.validationResult?.isFlatNumberEmpty
                ? 'Не заполнено обязательное поле'
                : '',
              !record.validationResult?.isAddressEmpty && !record.validationResult?.isObjectValid
                ? 'Некорректный адрес'
                : '',
              !record.validationResult?.isFlatNumberEmpty && !record.validationResult?.isFlatNumberValid
                ? 'Номер квартиры не соответствует указанному адресу'
                : '',
            ]
          ),
        width: '25%',
      },
    ],
    [renderCell]
  );

  const dataSource = useMemo(() => {
    const data: ISubscribersErrorSub[] = [];

    errorsData?.subscribers.forEach((item) => {
      const validationResult = errorsData.validationResult.find((valid) => valid.id === item.rowId);
      if (validationResult) data.push({ ...item, validationResult });
    });

    return data;
  }, [errorsData]);

  return (
    <Modal
      isOpen={isOpen}
      title={errorsData?.errorRowCount === 0 ? 'Список абонентов успешно загружен' : 'Ошибки в файле загрузки'}
      width={errorsData?.errorRowCount ? 1368 : 416}
      onCancel={handleOnClose}
      showCloseIcon
      centered
      wrapClassName="upload-subscribers__wrapper"
      footer={
        <div className="upload-subscribers__button-container">
          <Button size={ButtonSize.small} onClick={onCancel}>
            Понятно
          </Button>
        </div>
      }
    >
      <div className="upload-subscribers">
        <div className="upload-subscribers__info">
          Cтрок успешно загружено {errorsData?.importRowCount}.
          {errorsData?.dublicateRowCount ? (
            <>
              <br /> Строк не загружено {errorsData?.dublicateRowCount}, т.к. по ним уже есть информация в АРМ.
            </>
          ) : (
            ''
          )}
          {errorsData?.errorRowCount ? (
            <>
              <br />
              Строк с ошибками {errorsData?.errorRowCount}, исправьте их и загрузите файл снова. Успешно загруженные
              ранее строки повторно добавлены не будут.
            </>
          ) : (
            ''
          )}
        </div>
        {!!errorsData?.errorRowCount && (
          <div className="editable-table default-scrollbar-override">
            <Scrollbar>
              <Table
                scroll={{ x: '100vh' }}
                columns={columns}
                dataSource={dataSource}
                showSorterTooltip={false}
                pagination={false}
              />
            </Scrollbar>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SubscribersErrorsModal;
