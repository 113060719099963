import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { IParamField } from '../types';
import Select from '../../../../../../ui/select';
import { ISelectOption } from '../../../../../../ui/select/types';

const SelectField: FC<IParamField> = ({ object, changeParam, index, disabled = false }) => {
  useEffect(() => {
    if (!object.value && !object.wasChange) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (value: string | number) => {
      object.value = value.toString();
      object.wasChange = true;
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  const options = useMemo(
    (): ISelectOption[] =>
      object.availableValues.map((item) => ({
        value: item,
        title: item,
      })),
    [object.availableValues]
  );

  return (
    <Select
      title={object.name || ''}
      value={object.value}
      onChange={onChange}
      options={options}
      disabled={disabled}
      isDisabledStyle={disabled}
    />
  );
};

export default SelectField;
