import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sidebar as sidebarItems } from '../../constants/sidebar';
import { ESidebarItemIds, ISidebarItem } from '../../typings/sidebar';
import { findSidebarItemById, openSidebarItems } from '../../utils/sidebar';

export interface ISidebarCounts {
  DispatcherPanel: number;
}

interface ISidebarState {
  selectedTabId: ESidebarItemIds;
  tabs: ISidebarItem[];
  isExpand: boolean;
  counts: ISidebarCounts;
  clickedSidebarTab: string | null;
}

const initialState: ISidebarState = {
  selectedTabId: ESidebarItemIds.objects,
  tabs: sidebarItems,
  isExpand: true,
  clickedSidebarTab: null,
  counts: {
    DispatcherPanel: 0,
  },
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSelectedTabId: (state, action: PayloadAction<ESidebarItemIds>) => {
      state.selectedTabId = action.payload;
    },
    setCount: (state, action: PayloadAction<{ field: string; value: number }>) => {
      state.counts[action.payload.field as keyof ISidebarCounts] = action.payload.value;
    },
    setTabs: (state, action: PayloadAction<ISidebarItem[]>) => {
      state.tabs = action.payload;
    },
    setClickedSidebarTab: (state, action: PayloadAction<string | null>) => {
      state.clickedSidebarTab = action.payload;
    },
    setIsExpand: (state, action: PayloadAction<boolean>) => {
      state.isExpand = action.payload;
    },
    openSidebarItem: (state, action: PayloadAction<{ itemId: ESidebarItemIds; isOpen: boolean }>) => {
      const { itemId, isOpen: newIsOpen } = action.payload;

      const findItem = findSidebarItemById(state.tabs, itemId);
      if (findItem) {
        if (!state.isExpand && findItem.categoryItems && findItem.categoryItems?.length > 0) {
          state.isExpand = true;
        }
        openSidebarItems(state.tabs, findItem.id, newIsOpen);
      }
    },
  },
});

export const { setSelectedTabId, setTabs, setIsExpand, openSidebarItem, setCount, setClickedSidebarTab } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
