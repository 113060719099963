import classNames from 'classnames';
import React, { FC } from 'react';
import Button from '../button';
import InputSearch from '../inputSearch';
import Select from '../select';
import { ISectionFlatsTools } from './types';

const SectionFlatsTools: FC<ISectionFlatsTools> = (props) => {
  const { selects = [], inputsSearch = [], buttons = [] } = props;

  return (
    <div className="section-flats-tools">
      {selects.map((item, index) => (
        <Select
          key={`section-flats-tools-select-${index}`}
          {...item}
          containerClassName={classNames(
            'section-flats-tools__item section-flats-tools__select',
            item.containerClassName
          )}
        />
      ))}
      {inputsSearch.map((item, index) => (
        <InputSearch
          key={`section-flats-tools-input-search-${index}`}
          {...item}
          containerClassName={classNames(
            'section-flats-tools__item section-flats-tools__search',
            item.containerClassName
          )}
        />
      ))}
      {buttons.map((item, index) => (
        <Button
          key={`section-flats-tools-button-${index}`}
          {...item}
          className={classNames('section-flats-tools__item section-flats-tools__button', item.className)}
        >
          {item.children}
        </Button>
      ))}
    </div>
  );
};

export default SectionFlatsTools;
