import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { LngLat, YMap as YMapType, YMapLocationRequest } from '@yandex/ymaps3-types';
import type { Feature } from '@yandex/ymaps3-clusterer';
import { IInformationPanel } from './types';
import { useApi } from '../../../hooks/useApi';
import { getRequestWithArrayInParams } from '../../../api';
import { dispatcherPanelObjectTreeUrl } from '../../../constants/api';
import { IDispatcherPanelObject } from '../../../typings/dispatcherPanel';
import {
  YMap,
  YMapClusterer,
  YMapControls,
  YMapDefaultFeaturesLayer,
  YMapDefaultSchemeLayer,
  YMapFeatureDataSource,
  YMapLayer,
  YMapZoomControl,
  clusterByGrid,
  YMapListener,
} from '../../yandexMap';
import ObjectPlaceMark from '../objectInfo/objectPlacemark';
import InformationPanelItem from '../objectInfo/informationPanelItem';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';
import ClusterComponent from '../../yandexMap/cluster';
import Message from '../../message';
import { defaultLocation } from '../../yandexMap/const';
import { selectAllOptionKey } from '../../../constants/select';
import { IDispatcherPanelApiParams } from '../../../api/dispatcherPanel/types';

const gridCluster: any = clusterByGrid;

const DispatcherPanelMap: FC<IInformationPanel> = ({ dispatcherPanelApiParams, isAdmin }) => {
  const { data, sendRequest } = useApi<IDispatcherPanelObject[]>(getRequestWithArrayInParams);

  const [activeObject, setActiveObject] = useState<IDispatcherPanelObject | null>(null);

  const yMap = useRef<YMapType | null>(null);

  const gridSizedMethod = useMemo(() => gridCluster({ gridSize: 64 }), []);

  const [location, setLocation] = useState<YMapLocationRequest>(defaultLocation);

  const needRescale = useRef<boolean>(true);

  const prevApiParams = useRef<IDispatcherPanelApiParams>();

  const updateLocation = useCallback((newLocation: YMapLocationRequest) => {
    setLocation(newLocation);
  }, []);

  const getData = useCallback(() => {
    sendRequest(dispatcherPanelObjectTreeUrl(), {
      params: {
        ...dispatcherPanelApiParams,
        statuses: dispatcherPanelApiParams?.statuses
          ? dispatcherPanelApiParams?.statuses[0] === selectAllOptionKey
            ? []
            : dispatcherPanelApiParams.statuses
          : [],
      },
    });

    if (prevApiParams.current)
      needRescale.current = JSON.stringify(prevApiParams.current) !== JSON.stringify(dispatcherPanelApiParams);
    prevApiParams.current = dispatcherPanelApiParams;
  }, [dispatcherPanelApiParams, sendRequest]);

  useEffect(() => {
    if (isAdmin && !dispatcherPanelApiParams?.companyId) {
      return;
    }
    getData();
  }, [dispatcherPanelApiParams]);

  const updateHandler = useCallback(
    (e: any) => {
      updateLocation({
        center: e.location.center,
        zoom: e.location.zoom,
      });
    },
    [updateLocation]
  );

  const recalculateBounds = useCallback(() => {
    if (data?.length) {
      const bounds = ymaps.util.bounds.fromPoints(
        data?.map((item) => [item.longitude, item.latitude]),
        { checkZoomRange: true, zoomMargin: 0 }
      );
      // const a = ymaps.util.bounds.getCenterAndZoom(bounds);
      updateLocation({
        ...location,
        bounds: ymaps.util.bounds.fromPoints(
          data?.map((item) => [item.longitude, item.latitude]),
          { checkZoomRange: true, margin: 10 }
        ),
      });
      needRescale.current = false;
    }
  }, [data, location, updateLocation]);

  useEffect(() => {
    if (needRescale.current) {
      recalculateBounds();
    }
  }, [data]);

  const clickOnCluster = useCallback(
    (coordinates: LngLat) => () => {
      if (yMap.current) {
        updateLocation({ center: coordinates, zoom: yMap.current.zoom + 3 });
      }
    },
    [updateLocation]
  );

  const renderMarker = useCallback(
    (feature: Feature) => {
      const object = data?.find((item) => feature.id === item.objectId);
      return object ? (
        <ObjectPlaceMark
          activePlaceMarkId={activeObject?.objectId || ''}
          onClick={() => setActiveObject(object)}
          key={object.objectId}
          item={object}
          dispatcherPanelApiParams={dispatcherPanelApiParams}
        />
      ) : (
        <div />
      );
    },
    [activeObject?.objectId, data, dispatcherPanelApiParams]
  );

  const renderCluster = useCallback(
    (coordinates: LngLat, features: Feature[]) => (
      <ClusterComponent coordinates={coordinates} features={features} onClick={clickOnCluster} />
    ),
    [clickOnCluster]
  );

  const filteredData = useMemo(
    () =>
      dispatcherPanelApiParams?.objectId
        ? data
        : data?.filter(
            (item) =>
              dispatcherPanelApiParams?.objectId !== item.objectId ||
              item.activeDeviceCount +
                item.lostDeviceCount +
                item.withErrorsDeviceCount +
                item.withWarningsDeviceCount +
                item.withoutEventsDeviceCount !==
                0
          ),
    [data, dispatcherPanelApiParams?.objectId]
  );

  const placeMarks = useMemo(
    (): Feature[] =>
      filteredData?.map((item) => ({
        type: 'Feature',
        id: item.objectId,
        geometry: { type: 'Point', coordinates: [item.longitude, item.latitude] },
      })) || [],
    [filteredData]
  );

  useEffect(() => {
    if (data && data?.length !== 0 && placeMarks.length === 0) {
      Message.success({ content: 'Нет объектов удовлетворяющих критерям поиска' });
    }
  }, [data, placeMarks]);

  return (
    <div className="dispatcher-map">
      {activeObject && (
        <>
          <div role="presentation" onClick={() => setActiveObject(null)} className="dispatcher-map__info-item-close">
            <CloseBigIcon />
          </div>
          <InformationPanelItem
            showPointsName
            wrapClassName="dispatcher-map__info-item"
            dispatcherPanelApiParams={dispatcherPanelApiParams}
            item={activeObject}
          />
        </>
      )}
      <YMap zoomRange={{ max: 18, min: 0 }} ref={(x) => (yMap.current = x)} location={location}>
        <YMapDefaultSchemeLayer />
        <YMapDefaultFeaturesLayer />
        <YMapFeatureDataSource id="clusterer-source" />
        <YMapLayer source="clusterer-source" type="markers" />
        <YMapControls position="right bottom" orientation="vertical">
          <YMapZoomControl />
        </YMapControls>
        <YMapClusterer marker={renderMarker} cluster={renderCluster} method={gridSizedMethod} features={placeMarks} />
        <YMapListener onUpdate={updateHandler} />
      </YMap>
    </div>
  );
};

export default DispatcherPanelMap;
