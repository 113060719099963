import { DataNode } from 'antd/es/tree';
import { Key } from 'antd/lib/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { getAccessGroupTreeKey, resolveAccessGroupTreeKey } from '../../../../utils/tree';
import DirectoryTree from '../../../ui/directoryTree';
import { IHardwareSettingsTree } from './types';
import CameraCircleIcon from '../../../../assets/svg/icons/cameraCircle';
import DoorCircleIcon from '../../../../assets/svg/icons/doorCircle';
import DoorCircleActiveIcon from '../../../../assets/svg/icons/doorCircleActive';
import CameraCircleActiveIcon from '../../../../assets/svg/icons/cameraCircleActive';
import CommutatorActiveIcon from '../../../../assets/svg/icons/commutatorActive';
import CommutatorIcon from '../../../../assets/svg/icons/commutator';
import PPOIcon from '../../../../assets/svg/icons/PPOIcon';
import PPOIconActive from '../../../../assets/svg/icons/PPOIconActive';

const HardwareSettingsTree: FC<IHardwareSettingsTree> = ({
  data = [],
  selectedNode,
  loading = false,
  invalidHardware = [],
  parents = [],
  selectedKeys,
  ...props
}) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const treeRef = useRef<HTMLDivElement | null>(null);

  const expandDefault = useCallback((nodes: DataNode[], keys: Key[] = []): Key[] => {
    nodes.forEach((node) => {
      const type = resolveAccessGroupTreeKey(node.key.toString())[1];
      if (type === TreeNodeType.restrictedArea || type === TreeNodeType.object) {
        keys.push(node.key);
        expandDefault(node.children || [], keys);
      }
    });
    return keys;
  }, []);

  const renderNode = useCallback(
    (node: ITreeNode, id = '', deep = 0) => (
      <div className="hardware-settings__tree-title" id={id}>
        <div
          className={classNames(
            'ant-tree-title-name',
            !deep && node.type !== TreeNodeType.accessPoint && 'ant-tree-title-name_bold'
          )}
        >
          {node.objectItemTypes === TreeNodeType.camera ? (
            <div className="hardware-settings__tree-icon">
              {id === selectedNode ? <CameraCircleActiveIcon /> : <CameraCircleIcon />}
            </div>
          ) : node.objectItemTypes === TreeNodeType.device || node.objectItemTypes === TreeNodeType.keyDevice ? (
            <div className="hardware-settings__tree-icon">
              {id === selectedNode ? <DoorCircleActiveIcon /> : <DoorCircleIcon />}
            </div>
          ) : node.objectItemTypes === TreeNodeType.commutator ? (
            <div className="hardware-settings__tree-icon">
              {id === selectedNode ? <CommutatorActiveIcon /> : <CommutatorIcon />}
            </div>
          ) : node.objectItemTypes === TreeNodeType.securityPostConsole ? (
            <div className="hardware-settings__tree-icon">{id === selectedNode ? <PPOIconActive /> : <PPOIcon />}</div>
          ) : null}
          <div className="hardware-settings__tree-label">
            {node.isNew && <div className="directory-tree__new-item">Новое</div>}
            {`${node.name} ${'prefix' in node ? node.prefix || '' : ''}`}
          </div>
        </div>
        {'address' in node && node.address && <div className="ant-tree-title-address">{node.address}</div>}
      </div>
    ),
    [selectedNode]
  );

  const renderTree = useCallback(
    (renderData: ITreeNode[], invalidKeys: string[] = [], parentsKeys: string[] = [], deep = 0): DataNode[] =>
      renderData.map((node: ITreeNode) => {
        const newKey = getAccessGroupTreeKey(node.id || '', node.objectItemTypes || '', '0');
        const isInvalid = !!(invalidHardware.length && invalidHardware.find((item) => item.id === node.id));
        if (isInvalid) {
          invalidKeys.push(...parentsKeys, newKey);
        }
        return {
          title: renderNode({ ...node }, newKey, deep),
          key: newKey,
          isLeaf: !(node.children && node.children.length > 0),
          children: renderTree(node.children || [], invalidKeys, [...parentsKeys, newKey], deep + 1),
          className: isInvalid ? 'directory-tree__invalid-node' : '',
        };
      }),
    [invalidHardware, renderNode]
  );

  const startRenderTree = useCallback(
    (renderData: ITreeNode[]) => {
      const invalidKeys: string[] = [];
      const result = renderTree(renderData, invalidKeys);
      setExpandedKeys(invalidKeys);
      return result;
    },
    [renderTree]
  );

  const treeData = useMemo<DataNode[]>(() => startRenderTree(data), [data, startRenderTree]);

  const newParents = useMemo(
    () => (expandedKeys.length ? [...parents, ...expandedKeys] : parents),
    [expandedKeys, parents]
  );

  return (
    <div ref={treeRef} className="hardware-settings__tree">
      <DirectoryTree
        needScrollToView
        defaultExpandAll
        selectedKeys={[selectedNode]}
        expandDefault={expandDefault}
        treeData={treeData}
        loading={loading}
        parents={newParents}
        {...props}
      />
    </div>
  );
};

export default HardwareSettingsTree;
