import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ERecipientsTabs, IRecipientsModal } from './types';
import Modal from '../../../../ui/modal';
import Button from '../../../../ui/button';
import { ButtonSize, ButtonType } from '../../../../ui/button/types';
import { useApi } from '../../../../../hooks/useApi';
import { getRequest } from '../../../../../api';
import Tabs from '../../../../tabs';
import { mailingObjectsUrl, mailingRecipientsUrl } from '../../../../../constants/api';
import { EMailingTypes, IMailingObject, IMailingSubscriber } from '../../../../../typings/mailing';
import SelectMultiple from '../../../../ui/selectMultiple';
import { selectAllOptionKey } from '../../../../../constants/select';
import { ISelectMultipleOption } from '../../../../ui/selectMultiple/types';
import SimpleTabs from '../../../../ui/simpleTabs';
import { ISimpleTab } from '../../../../ui/simpleTabs/types';
import Select from '../../../../ui/select';

const RecipientsModal: FC<IRecipientsModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, saveData = () => {}, mailingType } = props;

  const [activeTab, setActiveTab] = useState<string>(ERecipientsTabs.objects);

  const { data: objectListData, sendRequest: getObjectsList } = useApi<IMailingObject[]>(getRequest);

  const { data: subscribersListData, sendRequest: getSubscribersList } = useApi<IMailingSubscriber[]>(getRequest);

  const [subscribersList, setSubscribersList] = useState<string[]>([]);

  const [objectsList, setObjectsList] = useState<string[]>([]);

  const [search, setSearch] = useState('');

  const onAccess = useCallback(async () => {
    saveData(
      objectsList[0] === selectAllOptionKey ? objectListData?.map((item) => item.objectId) || [] : objectsList,
      subscribersList[0] === selectAllOptionKey
        ? subscribersListData?.map((item) => item.subscriberId) || []
        : subscribersList
    );
    onCancel();
  }, [objectListData, objectsList, onCancel, saveData, subscribersList, subscribersListData]);

  useEffect(() => {
    if (isOpen) {
      getObjectsList(mailingObjectsUrl());
      getSubscribersList(mailingRecipientsUrl(), { params: { type: mailingType } });
      setSubscribersList([]);
      setObjectsList([]);
    }
  }, [isOpen]);

  const onDeleteFromObjects = useCallback(
    (id: string) =>
      setObjectsList(
        objectsList[0] === selectAllOptionKey
          ? objectListData?.filter((item) => item.objectId !== id).map((item) => item.objectId) || []
          : objectsList.filter((item) => item !== id)
      ),
    [objectListData, objectsList]
  );

  const onDeleteFromSubscribers = useCallback(
    (id: string) =>
      setSubscribersList(
        subscribersList[0] === selectAllOptionKey
          ? subscribersListData?.filter((item) => item.subscriberId !== id).map((item) => item.subscriberId) || []
          : subscribersList.filter((item) => item !== id)
      ),
    [subscribersList, subscribersListData]
  );

  const onSetSubscribersList = useCallback(
    (val: string | number) => setSubscribersList([...subscribersList, val.toString()]),
    [subscribersList]
  );

  const devicesOptions = useMemo(
    () =>
      objectListData
        ? [
            { value: selectAllOptionKey, title: 'Все' },
            ...objectListData.map<ISelectMultipleOption>((object) => ({
              value: object.objectId || '',
              title: object.objectName || '',
            })),
          ]
        : [],
    [objectListData]
  );

  const subscribersOptions = useMemo(
    () =>
      subscribersListData
        ?.filter(
          (item) =>
            item.name?.toLowerCase().includes(search.toLowerCase()) ||
            (mailingType === EMailingTypes.email
              ? item.email?.toLowerCase().includes(search.toLowerCase())
              : item.phone?.toLowerCase().includes(search.toLowerCase()))
        )
        ?.filter((item) => !subscribersList.find((obj) => obj === item.subscriberId))
        ?.map<ISelectMultipleOption>((object) => ({
          value: object.subscriberId || '',
          title: `${object.name} ${mailingType === EMailingTypes.email ? object.email : object.phone}`,
          reactNodeTitle: (
            <div className="recipients-modal__select">
              <div className="recipients-modal__select-name">{object.name}</div>
              <div className="recipients-modal__select-email">
                {mailingType === EMailingTypes.email ? object.email : object.phone}
              </div>
            </div>
          ),
        })),
    [mailingType, search, subscribersList, subscribersListData]
  );

  const objectRolesTabs = useMemo(
    () =>
      objectsList[0] === selectAllOptionKey
        ? objectListData?.map((item) => ({
            id: item?.objectId,
            name: item?.objectName,
          }))
        : objectsList.map((item) => {
            const element = objectListData?.find((obj) => obj.objectId === item);
            return {
              id: element?.objectId,
              name: element?.objectName,
            } as ISimpleTab;
          }),
    [objectListData, objectsList]
  );

  const objectSubscribersTabs = useMemo(
    () =>
      subscribersList[0] === selectAllOptionKey
        ? subscribersListData?.map((item) => ({
            id: item?.subscriberId,
            name: item?.name,
          }))
        : subscribersList.map((item) => {
            const element = subscribersListData?.find((obj) => obj.subscriberId === item);
            return {
              id: element?.subscriberId,
              name: element?.name,
            } as ISimpleTab;
          }),
    [subscribersList, subscribersListData]
  );

  const renderObjectSelector = useMemo(
    () => (
      <>
        <SelectMultiple
          showSearch
          title="Объекты"
          values={objectsList}
          options={devicesOptions}
          onChange={setObjectsList}
        />
        <div className="recipients-modal__info">В список получателей будут добавлены все абоненты данных объектов</div>
        <SimpleTabs scrollView={false} tabs={objectRolesTabs} onDelete={onDeleteFromObjects} />
      </>
    ),
    [devicesOptions, objectRolesTabs, objectsList, onDeleteFromObjects]
  );

  const renderSubscriberSelector = useMemo(
    () => (
      <>
        <Select
          onSearch={setSearch}
          searchValue={search}
          showSearch
          title="Абонент"
          placeholder={mailingType === EMailingTypes.email ? 'Поиск по ФИО, E-mail' : 'Поиск по ФИО, номеру телефона'}
          options={subscribersOptions}
          onChange={onSetSubscribersList}
        />
        <div className="recipients-modal__info">
          Воспользуйтесь поиском по абонентам, чтобы найти и добавить абонента к рассылке
        </div>
        <SimpleTabs scrollView={false} tabs={objectSubscribersTabs} onDelete={onDeleteFromSubscribers} />
      </>
    ),
    [mailingType, objectSubscribersTabs, onDeleteFromSubscribers, onSetSubscribersList, search, subscribersOptions]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Добавить получателей"
      width={416}
      showCloseIcon
      wrapClassName="recipients-modal__wrapper"
      centered
      footer={
        <div className="recipients-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
            Отмена
          </Button>
          <Button
            size={ButtonSize.small}
            onClick={onAccess}
            disabled={subscribersList.length === 0 && objectsList.length === 0}
          >
            Добавить
          </Button>
        </div>
      }
    >
      <Tabs
        activeTabKey={activeTab}
        onChangeActiveTab={setActiveTab}
        tabsClassName="recipients-modal__tabs"
        tabs={[
          {
            id: ERecipientsTabs.objects,
            title: 'По объектам',
            position: 0,
            children: renderObjectSelector,
          },
          {
            id: ERecipientsTabs.subscribers,
            title: 'По именам',
            position: 1,
            children: renderSubscriberSelector,
          },
        ]}
      />
    </Modal>
  );
};

export default RecipientsModal;
