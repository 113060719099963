import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { IPieChart } from './types';

const PieChart: FC<IPieChart> = (props) => {
  const { label, className = '', segments = [], onClick, onMouseEnter, onMouseLeave } = props;

  const pieRef = useRef<HTMLDivElement | null>(null);

  const getGradient = useCallback(() => {
    let summ = 0;

    const result = segments
      .map((segment) => {
        const str = `${segment.color} ${summ}% ${summ + segment.value}%`;
        summ += segment.value;
        return str;
      })
      .toString();

    if (!summ) {
      return '#949494 0 100%';
    }

    return result;
  }, [segments]);

  useEffect(() => {
    if (pieRef.current) {
      pieRef.current.style.backgroundImage = `conic-gradient(${getGradient()})`;
    }
  }, [segments]);

  return (
    <div
      role="presentation"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={pieRef}
      className={classNames('pie-chart', className)}
    >
      <div className="pie-chart__number">
        <span className="pie-chart__number-label">{label}</span>
      </div>
    </div>
  );
};

export default PieChart;
