import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSystem } from '../../../api/systems';
import { getObjectBuildingsWithSectionsUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { ISystemBuilding } from '../../../typings/systems/building';
import { sortBuildingsByName } from '../../../utils/object';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import { ISystemTabComponent } from '../types';
import SectionCallCodes from './sectionCallCodes';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Button from '../../ui/button';
import CallCodesCheckModal from './callCodeCheckModal';
import { EPageQueryParams } from '../../../typings/searchParams';

const CallCodesTab: FC<ISystemTabComponent> = (props) => {
  const {
    systemId = '',
    onNextTab = () => {},
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    nextTabIsAvailable,
    permissions = {},
    isFiveThousandth,
  } = props;

  const [activeTabId, setActiveTabId] = useState<string>('');
  const { data: buildings, sendRequest, loading } = useApi<ISystemBuilding[]>(getSystem, sortBuildingsByName);

  const [checkModalIsOpen, setModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const updateBuildings = useCallback(() => {
    if (systemId) {
      sendRequest(getObjectBuildingsWithSectionsUrl(systemId));
    }
  }, [sendRequest, systemId]);

  const resetActiveTabId = useCallback((currBuildings: ISystemBuilding[]) => {
    setActiveTabId(currBuildings.find((item) => item.flatCount > 0)?.id || '');
  }, []);

  useEffect(() => {
    if (searchParams.get(EPageQueryParams.needCheckErrors) && tabId === activeTabKey) {
      setModalOpen(true);
      searchParams.delete(EPageQueryParams.needCheckErrors);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (tabId === activeTabKey) {
      updateBuildings();
    }
  }, [activeTabKey, tabId]);

  useEffect(() => {
    if (buildings) {
      if (!activeTabId || (activeTabId && (buildings.find((item) => item.id === activeTabId)?.flatCount || 0) < 1)) {
        resetActiveTabId(buildings);
      }
    }
  }, [buildings]);

  const handleCheckErrors = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onCloseModal = useCallback(() => {
    setModalOpen(false);
    updateBuildings();
  }, [updateBuildings]);

  const tabs = useMemo(
    () =>
      buildings && !loading
        ? buildings.map<ICustomTab>((building) => ({
            tabId: building.id,
            disabled: !(building.flatCount > 0),
            data: [
              { fieldTitle: 'Строение', fieldData: building.name },
              { fieldTitle: 'Код строения', fieldData: building.prefix },
              { fieldTitle: 'Кол-во подъездов', fieldData: building.sectionCount || '–' },
              { fieldTitle: 'Кол-во квартир', fieldData: building.flatCount || '–' },
            ],
            children:
              building.flatCount > 0 ? (
                <SectionCallCodes
                  isFiveThousandth={isFiveThousandth}
                  updateAvailableTabs={updateAvailableTabs}
                  systemId={systemId}
                  buildingId={building.id}
                  sections={building.sections}
                  permissions={permissions}
                />
              ) : null,
          }))
        : [],
    [buildings, isFiveThousandth, loading, permissions, systemId, updateAvailableTabs]
  );

  return (
    <div className="call-codes-tab">
      <CallCodesCheckModal
        isFiveThousandth={isFiveThousandth}
        buildings={buildings || []}
        systemId={systemId}
        isOpen={checkModalIsOpen}
        onClose={onCloseModal}
      />
      {tabId === activeTabKey && (
        <>
          {permissions.edit && (
            <div className="call-codes-tab__check-button">
              <Button onClick={handleCheckErrors} type={ButtonType.outline} size={ButtonSize.small}>
                Проверить ошибки
              </Button>
            </div>
          )}
          <TabsCustom activeTabKey={activeTabId} onChangeActiveTab={setActiveTabId} loading={loading} tabs={tabs} />
        </>
      )}
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { isHidden: true },
          { isHidden: true },
          {
            isHidden: !permissions.edit,
            callBack: onNextTab,
            classNameOption: 'call-codes-tab__button-next',
            disabled: !nextTabIsAvailable,
          }
        )}
      />
    </div>
  );
};

export default CallCodesTab;
