import React, { FC } from 'react';

const PlusIcon: FC = () => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 6.82715V14.1535" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.6666 10.4904H7.33331" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6857 0.5H6.31429C3.04762 0.5 1 2.81208 1 6.08516V14.9148C1 18.1879 3.0381 20.5 6.31429 20.5H15.6857C18.9619 20.5 21 18.1879 21 14.9148V6.08516C21 2.81208 18.9619 0.5 15.6857 0.5Z"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
