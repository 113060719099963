import React, { FC } from 'react';
import { IInformationBlock } from './types';

const InformationBlock: FC<IInformationBlock> = (props) => {
  const { data = [], wrapperClassName = '', children = null } = props;

  return (
    <div className={`information-block ${wrapperClassName}`}>
      <div className="information-block__content">
        {data.map((column, colIndex) => (
          <div key={`information-col-${colIndex}`} className="information-block__col">
            {column.map((section, sectionIndex) => (
              <div className="information-block__section" key={`information-col${colIndex}-section-${sectionIndex}`}>
                {section.title && <div className="information-block__section-title">{section.title}</div>}
                <div className="information-block__section-items">
                  {section.items.map((item, itemIndex) => (
                    <div
                      className="information-block__section-item"
                      key={`information-col${colIndex}-section-${sectionIndex}-item-${itemIndex}`}
                    >
                      <div className="information-block__section-item-name">{item.name}</div>
                      <div className="information-block__section-item-value">{item.value}</div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      {children && <div className="information-block__children">{children}</div>}
    </div>
  );
};

export default InformationBlock;
