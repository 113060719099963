import React, { FC, useCallback, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastOptions, toast } from 'react-toastify';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';
import { INotificationsStackComponent } from './types';
import { SingleNotificationComponent } from '../singleNotification';
import { INotification } from '../../notificationsBlock/types';
import { statusToColor } from '../types';
import { ReminderNotificationComponent } from '../reminderNotification';
import { EDispatcherDeviceStatus } from '../../../typings/dispatcherPanel';

const NotificationsStackComponent: FC<INotificationsStackComponent> = (props) => {
  const { data, onClickNotify = () => {}, onCloseNotify = () => {}, onCloseStack = () => {} } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [notifications, setNotifications] = useState<INotification[]>([]);

  useEffect(() => {
    if (data?.notifications) {
      if (data.notifications.length === 1 && !isOpen) {
        setIsOpen(true);
      }
      setNotifications(data.notifications);
    }
  }, [data?.notifications]);

  const onItemClick = useCallback(
    (stackId: string, notifyId: string, index: number) => {
      if (!isOpen && index === 0) {
        setIsOpen(true);
      } else {
        onClickNotify(stackId, notifyId, true);
      }
    },
    [isOpen, onClickNotify]
  );

  const onDeleteNotify = useCallback(
    (stackId: string, notifyId: string, index: number) => {
      const newNotifications: INotification[] = JSON.parse(JSON.stringify(notifications));
      newNotifications[index].className = 'notify__stack-item_hide';
      setNotifications(newNotifications);
      setTimeout(() => onCloseNotify(stackId, notifyId, true), 500);
      if (notifications.length <= 1) {
        onCloseStack(stackId);
      }
    },
    [notifications, onCloseNotify, onCloseStack]
  );

  return data ? (
    <div className={`notify__stack-wrapper ${isOpen ? '' : 'notify__stack_close'}`}>
      {notifications.length > 1 && (
        <div className="notify__stack-buttons">
          <div className="notify__stack-buttons-show" role="presentation" onClick={() => setIsOpen(!isOpen)}>
            <div className="notify__stack-buttons-show-arrow">
              <ChevronDown />
            </div>
            <div className="notify__stack-buttons-show-label">Показать меньше</div>
          </div>
          <div className="notify__stack-buttons-close" role="presentation" onClick={() => onCloseStack(data.id)}>
            <CloseBigIcon />
          </div>
        </div>
      )}
      {notifications.map((item, index) => (
        <div
          key={item.id}
          className={`notify__stack-item ${item.className || ''} ${index === 0 ? 'notify__stack-item_first' : ''}`}
        >
          <div
            className={`notify__message ${
              item.isReminder ? '' : `notify__message_${statusToColor.get(data?.status as EDispatcherDeviceStatus)}`
            }`}
          >
            {item.isReminder ? (
              <ReminderNotificationComponent
                showClose={isOpen ? true : !!index}
                data={item}
                onClickNotify={() => onItemClick(item.stackId, item.id, index)}
                onCloseNotify={() => onDeleteNotify(item.stackId, item.id, index)}
              />
            ) : (
              <SingleNotificationComponent
                showClose={isOpen ? true : !!index}
                data={item}
                onClickNotify={() => onItemClick(item.stackId, item.id, index)}
                onCloseNotify={() => onDeleteNotify(item.stackId, item.id, index)}
              />
            )}
          </div>
          {index === 0 && <div className="notify__stack-item-second-layer" />}
        </div>
      ))}
    </div>
  ) : null;
};

export const renderNotificationsStack = (componentProps: INotificationsStackComponent, options: ToastOptions = {}) =>
  toast(() => <NotificationsStackComponent {...componentProps} />, {
    ...options,
    toastId: componentProps.data?.id || options.toastId,
    autoClose: false,
    className: `notify__stack ${options.className || ''}`,
  });

export const updateNotificationsStack = (
  id: string,
  componentProps: INotificationsStackComponent,
  options: ToastOptions = {}
) =>
  toast.update(id, {
    render: () => <NotificationsStackComponent {...componentProps} />,
    ...options,
    toastId: componentProps.data?.id || options.toastId,
    autoClose: false,
    className: `notify__stack ${options.className || ''}`,
  });
