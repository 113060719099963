import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IHeaderState {
  title: string;
}

const initialState: IHeaderState = {
  title: '',
};

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setHeaderTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
});

export const { setHeaderTitle } = headerSlice.actions;

export default headerSlice.reducer;
