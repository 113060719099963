import React, { FC, useCallback, useMemo, useState } from 'react';
import { addFlatsUrl, flatsCallBlocksUrl } from '../../../../constants/api';
import { defaultResponseParser, useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { IInputValue, InputStatus, InputType } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import { IAddFlatsModal, NumerationType } from './types';
import { getRequest, postRequest } from '../../../../api';

const AddFlatsModal: FC<IAddFlatsModal> = (props) => {
  const {
    flatsCounter = null,
    systemId = '',
    buildingId = '',
    sections = [],
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    isFiveThousandth,
  } = props;

  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(sections[0].id || '');
  const [numerationType, setNumerationType] = useState<NumerationType>(NumerationType.sequential);
  const [callBlock, setCallBlock] = useState<string>('');
  const [flatNumberFrom, setFlatNumberFrom] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [flatNumberTo, setFlatNumberTo] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [flatNumber, setFlatNumber] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const [floorNumber, setFloorNumber] = useState<IInputValue>({
    value: '',
    status: InputStatus.normal,
    errorText: '',
  });
  const { sendRequest, loading } = useApi(postRequest, defaultResponseParser, false);
  const [globalErrorText, setGlobalErrorText] = useState<string>('');
  const [flatsCounterError, setFlatsCounterError] = useState(false);

  const {
    data: callBlocks,
    sendRequest: getCallBlocks,
    loading: callBlocksLoading,
  } = useApi<{ id: string; name: string }[]>(getRequest);

  const onGetCallBlocks = useCallback(
    (id = selectedSectionId) => {
      if (!isFiveThousandth) getCallBlocks(flatsCallBlocksUrl(systemId, id.toString()));
    },
    [getCallBlocks, isFiveThousandth, selectedSectionId, systemId]
  );

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) => {
      setter({
        value,
        status: InputStatus.normal,
        errorText: '',
      });
      setGlobalErrorText('');
    },
    []
  );

  const resetInputs = useCallback(() => {
    handleOnChangeInput(setFlatNumberFrom, '');
    handleOnChangeInput(setFlatNumberTo, '');
    handleOnChangeInput(setFlatNumber, '');
    handleOnChangeInput(setFloorNumber, '');
    setFlatsCounterError(false);
  }, [handleOnChangeInput]);

  const handleOnChangeSection = useCallback(
    (sectionId: string | number) => {
      setSelectedSectionId(sectionId);
      resetInputs();
      setCallBlock('');
      onGetCallBlocks(sectionId.toString());
    },
    [onGetCallBlocks, resetInputs]
  );

  const handleOnChangeNumerationType = useCallback(
    (numeration: string | number) => {
      setNumerationType(numeration.toString() as NumerationType);
      resetInputs();
    },
    [resetInputs]
  );

  const validateSequentialInputs = useCallback(
    (errorText = '') => {
      let isValid = true;
      if (!flatNumberFrom.value) {
        setFlatNumberFrom({ ...flatNumberFrom, status: InputStatus.error, errorText });
        isValid = false;
      }
      if (!flatNumberTo.value) {
        setFlatNumberTo({ ...flatNumberTo, status: InputStatus.error, errorText });
        isValid = false;
      }
      return isValid;
    },
    [flatNumberFrom, flatNumberTo]
  );

  const validateArbitraryInputs = useCallback(
    (errorText = '') => {
      let isValid = true;
      if (!flatNumber.value) {
        setFlatNumber({ ...flatNumber, status: InputStatus.error, errorText });
        isValid = false;
      }

      return isValid;
    },
    [flatNumber]
  );

  const handleOnClickAddButton = useCallback(async () => {
    if (flatsCounter !== null && flatsCounter !== '') {
      const counter = Number(flatNumberTo.value || 0) - Number(flatNumberFrom.value || 0);
      if ((flatsCounter || 0) < counter) {
        setFlatsCounterError(true);
        setFlatNumberFrom({ ...flatNumberFrom, status: InputStatus.error });
        setFlatNumberTo({ ...flatNumberTo, status: InputStatus.error });
        return;
      }
    }
    if (selectedSectionId) {
      if (numerationType === NumerationType.sequential && validateSequentialInputs('Поле должно быть заполнено')) {
        const resError = await sendRequest(
          addFlatsUrl(systemId, buildingId, selectedSectionId.toString()),
          {
            startNumber: Number(flatNumberFrom.value),
            endNumber: Number(flatNumberTo.value),
            floor: Number(floorNumber.value),
          },
          { params: { callBlockId: callBlock } }
        );
        if (resError?.response?.data) {
          setFlatNumberFrom({ ...flatNumberFrom, status: InputStatus.error });
          setFlatNumberTo({ ...flatNumberTo, status: InputStatus.error });
          setGlobalErrorText(resError.response.data.message);
          return;
        }
      } else if (numerationType === NumerationType.arbitrary && validateArbitraryInputs('Поле должно быть заполнено')) {
        const resError = await sendRequest(
          addFlatsUrl(systemId, buildingId, selectedSectionId.toString()),
          {
            startNumber: Number(flatNumber.value),
            endNumber: Number(flatNumber.value),
            floor: Number(floorNumber.value),
          },
          { params: { callBlockId: callBlock } }
        );
        if (resError?.response?.data) {
          setFlatNumber({ ...flatNumber, status: InputStatus.error });
          setGlobalErrorText(resError.response.data.message);
          return;
        }
      } else {
        return;
      }
      onOk();
      resetInputs();
      setCallBlock('');
      setNumerationType(NumerationType.sequential);
    }
  }, [
    buildingId,
    callBlock,
    flatNumber,
    flatNumberFrom,
    flatNumberTo,
    floorNumber.value,
    numerationType,
    onOk,
    resetInputs,
    selectedSectionId,
    sendRequest,
    systemId,
    validateArbitraryInputs,
    validateSequentialInputs,
  ]);

  const handleOnClose = useCallback(() => {
    onCancel();
    resetInputs();
    setNumerationType(NumerationType.sequential);
    setCallBlock('');
  }, [onCancel, resetInputs]);

  const callBlockOptions = useMemo(
    () =>
      callBlocks?.map<ISelectOption>((item) => ({
        value: item.id || '',
        title: item.name || '',
      })) || [],
    [callBlocks]
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Добавить квартиры"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      footer={
        <div className="add-flats-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={handleOnClose} disabled={loading}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={handleOnClickAddButton} loading={loading}>
            Добавить
          </Button>
        </div>
      }
    >
      <div className="add-flats-modal">
        <Select
          title="Подъезд"
          isRequired
          containerClassName="add-flats-modal__field-wrapper"
          onChange={handleOnChangeSection}
          value={selectedSectionId}
          options={[...sections.map<ISelectOption>((section) => ({ value: section.id, title: section.name }))]}
          disabled={loading}
        />
        <Select
          title="Нумерация"
          isRequired
          containerClassName="add-flats-modal__field-wrapper"
          onChange={handleOnChangeNumerationType}
          value={numerationType}
          options={[
            { value: NumerationType.sequential, title: 'Последовательная нумерация' },
            { value: NumerationType.arbitrary, title: 'Произвольная нумерация' },
          ]}
          disabled={loading}
        />
        {!isFiveThousandth && (
          <Select
            title="Блок вызова"
            containerClassName="add-flats-modal__field-wrapper"
            onChange={(val) => setCallBlock(val.toString())}
            value={callBlock}
            options={callBlockOptions}
            disabled={loading || !selectedSectionId || callBlocksLoading}
            onClick={() => onGetCallBlocks()}
          />
        )}

        <div className="add-flats-modal__field-wrapper">
          {numerationType === NumerationType.sequential && (
            <>
              <span className="add-flats-modal__title">
                Диапазон квартир<span className="add-flats-modal__star">*</span>
              </span>
              <div className="add-flats-modal__input-wrapper">
                <Input
                  placeholder="с"
                  value={flatNumberFrom.value}
                  onChange={(value) => handleOnChangeInput(setFlatNumberFrom, value)}
                  status={flatNumberFrom.status}
                  containerClassName="add-flats-modal__input"
                  errorText={flatNumberFrom.errorText}
                  maxLength={5}
                  inputType={InputType.numbers}
                  disabled={loading}
                />
                <Input
                  placeholder="по"
                  value={flatNumberTo.value}
                  onChange={(value) => handleOnChangeInput(setFlatNumberTo, value)}
                  status={flatNumberTo.status}
                  containerClassName="add-flats-modal__input"
                  errorText={flatNumberTo.errorText}
                  maxLength={5}
                  inputType={InputType.numbers}
                  disabled={loading}
                />
              </div>
            </>
          )}
          {numerationType === NumerationType.arbitrary && (
            <Input
              title="Номер квартиры"
              isRequired
              placeholder="Введите номер квартиры"
              value={flatNumber.value}
              status={flatNumber.status}
              errorText={flatNumber.errorText}
              onChange={(value) => handleOnChangeInput(setFlatNumber, value)}
              maxLength={5}
              inputType={InputType.numbers}
              disabled={loading}
            />
          )}
          {globalErrorText && <span className="add-flats-modal__error">{globalErrorText}</span>}
        </div>
        <div className="add-flats-modal__field-wrapper">
          <Input
            title="Этаж"
            placeholder="Этаж"
            value={floorNumber.value}
            status={floorNumber.status}
            errorText={floorNumber.errorText}
            onChange={(value) => handleOnChangeInput(setFloorNumber, value)}
            maxLength={5}
            inputType={InputType.numbers}
            disabled={loading}
          />
        </div>
        {flatsCounterError && (
          <div className="add-flats-modal__error">
            Вы можете добавить не более {flatsCounter} квартир. Измените количество квартир или обратитесь к
            администратору Eltis для изменения тарифа.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddFlatsModal;
