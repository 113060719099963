import { Group, Layer } from 'paper';
import { IAccessPoint, ICameraPoint, ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { getTreeKey } from '../../../../utils/tree';
import { EPaperGroupTypes, TPaperGroup, TPaperLayer } from '../../types';
import { createBackground, createEntityGroup } from './items';
import { IGroupData, ITreeRenderData, ITreeRenderFunctions } from './types';
import { createClone } from './calculations';
import { EVisualizerModes, ISchemaBackground, ISchemaOpacity } from '../../../systemTabs/schemaTab/types';
import { ISettings } from '../../../../typings/profile';

export const renderBackground = (
  background: ISchemaBackground | null,
  treeRenderFunctions: ITreeRenderFunctions,
  editPermission = true
): { backgroundG: TPaperGroup | null; backgroundL: TPaperLayer | null } => {
  if (background && background.img) {
    const backgroundLayer = new Layer();

    const backgroundGroup = new Group({
      visible: false,
      data: {
        key: TreeNodeType.background,
        parentKey: '',
        type: TreeNodeType.background,
        groupType: EPaperGroupTypes.group,
      } as IGroupData,
    });

    backgroundLayer.addChild(backgroundGroup);

    createBackground(background, backgroundGroup, treeRenderFunctions, editPermission);
    return { backgroundG: backgroundGroup, backgroundL: backgroundLayer };
  }
  return { backgroundG: null, backgroundL: null };
};

const renderTreeNodes = (
  data: ITreeRenderData,
  mode: EVisualizerModes,
  opacityValues: ISchemaOpacity,
  treeRenderFunctions: ITreeRenderFunctions,
  pointsLayer: TPaperLayer,
  camerasLayer: TPaperLayer,
  labelsLayer: TPaperLayer,
  settings: ISettings
) => {
  const { objects, key, parentGroup } = data;

  objects.forEach((object: ITreeNode, index: number) => {
    const objectKey = getTreeKey(key, index, object.type || TreeNodeType.accessPoint);

    const objectGroup = new Group({
      data: {
        key: objectKey,
        parentKey: key,
        type: object.type || TreeNodeType.accessPoint,
        groupType: EPaperGroupTypes.group,
        id: object.id,
      } as IGroupData,
    });

    parentGroup.addChild(objectGroup);

    createEntityGroup({
      mode,
      labelsLayer,
      type: object.type || TreeNodeType.accessPoint,
      renderFunctions: treeRenderFunctions,
      object,
      objectGroup,
      data,
      key: objectKey,
      opacityValues,
    });

    object.accessPoints?.forEach((point: IAccessPoint, i: number) => {
      const pointKey = getTreeKey(objectKey, i, TreeNodeType.accessPoint);
      const pointGroup = new Group({
        data: {
          key: pointKey,
          parentKey: key,
          type: TreeNodeType.accessPoint,
          groupType: EPaperGroupTypes.group,
          id: point.id,
          relatedAccessPoints: point.relatedAccessPoints || [],
          pointType: point.type,
        } as IGroupData,
      });
      createEntityGroup({
        mode,
        labelsLayer,
        opacityValues,
        type: TreeNodeType.accessPoint,
        renderFunctions: treeRenderFunctions,
        object: point,
        objectGroup: pointGroup,
        data,
        key: pointKey,
      });

      createClone(pointGroup, objectGroup, pointsLayer);
    });

    if (settings.camera && mode !== EVisualizerModes.deviceShow) {
      object.cameras?.forEach((camera: ICameraPoint, i: number) => {
        const pointKey = getTreeKey(objectKey, i, TreeNodeType.camera);
        const pointGroup = new Group({
          data: {
            key: pointKey,
            parentKey: key,
            type: TreeNodeType.camera,
            groupType: EPaperGroupTypes.group,
            id: camera.id,
          } as IGroupData,
        });
        createEntityGroup({
          mode,
          labelsLayer,
          opacityValues,
          type: TreeNodeType.camera,
          renderFunctions: treeRenderFunctions,
          object: camera,
          objectGroup: pointGroup,
          data,
          key: pointKey,
        });

        createClone(pointGroup, objectGroup, camerasLayer);
      });
    }

    renderTreeNodes(
      {
        ...data,
        ...{
          objects: object.childItems || [],
          key: objectKey,
          parentGroup: objectGroup,
        },
      },
      mode,
      opacityValues,
      treeRenderFunctions,
      pointsLayer,
      camerasLayer,
      labelsLayer,
      settings
    );
  });
};

export const schemaAdapter = (
  root: ITreeNode[],
  opacityValues: ISchemaOpacity,
  renderFunctions: ITreeRenderFunctions,
  mode: EVisualizerModes,
  editPermission = true,
  settings: ISettings = {}
): {
  schemaL: TPaperLayer;
  schemaG: TPaperGroup;
  pointsL: TPaperLayer;
  labelsL: TPaperLayer;
  camerasL: TPaperLayer;
} => {
  const objectLayer = new Layer();
  const schemaG = new Group({ data: { key: TreeNodeType.object } });
  objectLayer.addChild(schemaG);
  const labelsL = new Layer();
  const pointsL = new Layer();
  const camerasL = new Layer();
  const lastLayer = new Layer();

  renderTreeNodes(
    {
      objects: root,
      parentGroup: schemaG,
      key: '',
      editPermission,
    },
    mode,
    opacityValues,
    renderFunctions,
    pointsL,
    camerasL,
    labelsL,
    settings
  );

  return { schemaL: objectLayer, schemaG, pointsL, labelsL, camerasL };
};
