import React, { FC } from 'react';

const ArrowLeft: FC = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.40705 4.99989L7.70415 1.70279L6.28994 0.288574L0.578613 5.9999L6.28994 11.7112L7.70415 10.297L4.40703 6.99989H12.9999V4.99989H4.40705Z"
      fill="black"
    />
  </svg>
);

export default ArrowLeft;
