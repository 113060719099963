import classNames from 'classnames';
import React, { FC } from 'react';
import ChevronDown from '../../../assets/svg/icons/chevronDown';
import { ISidebarItemComponent } from './types';
import Tooltip from '../../ui/tooltip';

export const SidebarItem: FC<ISidebarItemComponent> = (props) => {
  const {
    item,
    selectedTabId = '',
    isSelected = false,
    isExpanded = false,
    isCategory = false,
    showArrow = false,
    isOpen = false,
    count = 0,
    onClick,
  } = props;

  return (
    <div
      className={classNames('sidebar-item__tab', {
        'sidebar-item__tab_selected': isSelected,
        'sidebar-item__tab_expanded': isExpanded,
        'sidebar-item__tab-category': isCategory,
        'sidebar-item__tab-category_expanded': isCategory && isExpanded,
        'sidebar-item__tab-category_selected': isCategory && item?.id === selectedTabId,
      })}
      onClick={onClick}
      role="presentation"
    >
      {count > 0 && (
        <Tooltip title={item?.countDescription} placement="bottomLeft">
          <div
            className={classNames('sidebar-item__tab-count', {
              'sidebar-item__tab-count_99': count >= 100,
            })}
          >
            {count >= 100 ? (
              <>
                99 <span>+</span>
              </>
            ) : (
              count
            )}
          </div>{' '}
        </Tooltip>
      )}

      {item?.logo && (
        <div className="sidebar-item__tab-logo">
          <item.logo />
        </div>
      )}
      {isExpanded && (
        <div
          className={classNames('sidebar-item__tab-content', {
            'sidebar-item__tab-content_is-category': isCategory,
          })}
        >
          {item?.title}
          {showArrow && (
            <div
              className={classNames('sidebar-item__icon-arrow', {
                'sidebar-item__icon-arrow_up': isOpen,
              })}
            >
              <ChevronDown />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
