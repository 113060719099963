import { AxiosRequestConfig } from 'axios';
import { ITreeNode } from '../../typings/treeNode';
import { axiosGet, axiosPost } from '../axios';
import {
  IHardwareSettings,
  IHardwareSettingsRtspServer,
  IHardwareSettingsSave,
  IHardwareType,
} from '../../typings/systems/hardwareSettings';

export const getHardwareSettingsTree = async (url: string): Promise<ITreeNode> => {
  const result = await axiosGet(url);
  return result.data;
};

export const getHardwareTypes = async (url: string): Promise<IHardwareType[]> => {
  const result = await axiosGet(url);
  return result.data;
};

export const getHardwareSettings = async (url: string, config?: AxiosRequestConfig): Promise<IHardwareSettings> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const getHardwareRtspServers = async (url: string): Promise<IHardwareSettingsRtspServer[]> => {
  const result = await axiosGet(url);
  return result.data;
};

export const setHardwareSettings = async (url: string, data: IHardwareSettingsSave) => {
  const result = await axiosPost(url, data);
  return result.data;
};

export const getHardwareValidation = async (url: string): Promise<[]> => {
  const result = await axiosGet(url);
  return result.data;
};
