import React, { FC } from 'react';
import Button from '../button';
import { ButtonSize, ButtonType } from '../button/types';
import Modal from '../modal';
import { INotificationModal } from './types';

const NotificationModal: FC<INotificationModal> = (props) => {
  const {
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    onClose = onCancel,
    title = 'В редактируемой строке обнаружена ошибка. Выберите действие чтобы продолжить работу.',
    okButtonText = 'К редактированию',
    cancelButtonText = 'Отменить изменения',
    bodyText = '',
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onClose}
      title={title}
      width={416}
      showCloseIcon
      wrapClassName="notification-modal__wrapper"
      centered
      footer={
        <div className="notification-modal__button-container">
          {cancelButtonText && (
            <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={onCancel}>
              {cancelButtonText}
            </Button>
          )}
          {okButtonText && (
            <Button size={ButtonSize.small} onClick={onOk}>
              {okButtonText}
            </Button>
          )}
        </div>
      }
    >
      {bodyText && <div className="notification-modal__body">{bodyText}</div>}
    </Modal>
  );
};

export default NotificationModal;
