import { ETariffVersions } from '../management/tariff';

export enum ESubscriptionsStatus {
  created = 'Created',
  paid = 'Paid',
  canceled = 'Canceled',
  noPaid = 'NoPaid',
}

export const subscriptionsStatusNamesMap = new Map<ESubscriptionsStatus, string>([
  [ESubscriptionsStatus.created, 'Создана'],
  [ESubscriptionsStatus.paid, 'Оплачена'],
  [ESubscriptionsStatus.canceled, 'Отменена'],
  [ESubscriptionsStatus.noPaid, 'Не оплачена'],
]);

export interface ISubscription {
  address: string;
  amount: number;
  companyId: string;
  companyName: string;
  email: string;
  fio: string;
  monthInTariff: number;
  nextPaymentDate: string;
  objectName: string;
  phone: string;
  provider?: string;
  subscriptionCreateDate: string;
  subscriptionUpdateDate: string;
  subscriptionId: string;
  subscriptionStatus: ESubscriptionsStatus;
  tariff: ETariffVersions;
  totalAmount: number;
  totalCompanyAmount: number;
  additionalCompanyPrice: number;
  discount: number;
  discountAmount: number;
  fixCommission: number;
  maintenancePrice: number;
  totalAdditionalCompanyPrice: 0;
  totalDiscountAmount: 0;
  totalFixCommission: 0;
  totalMaintenancePrice: 0;
}
