import React, { FC } from 'react';

const DeviceEventIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6667 7.99996H23.3333C23.7015 7.99996 24 8.29844 24 8.66663V16.6666C24 16.9008 23.8771 17.1178 23.6763 17.2383L20.5545 19.1114L19.9658 20.8774C19.875 21.1497 19.6203 21.3333 19.3333 21.3333H18.6667V28.6666C18.6667 29.0348 18.3682 29.3333 18 29.3333C17.6318 29.3333 17.3333 29.0348 17.3333 28.6666V21.3333H14.6667V28.6666C14.6667 29.0348 14.3682 29.3333 14 29.3333C13.6318 29.3333 13.3333 29.0348 13.3333 28.6666V21.3333H12.6667C12.3797 21.3333 12.125 21.1497 12.0342 20.8774L11.4455 19.1114L8.32367 17.2383C8.12287 17.1178 8 16.9008 8 16.6666V8.66663C8 8.29844 8.29848 7.99996 8.66667 7.99996H9.33333V3.33329C9.33333 2.9651 9.63181 2.66663 10 2.66663H22C22.3682 2.66663 22.6667 2.9651 22.6667 3.33329V7.99996ZM21.3333 7.99996V3.99996H10.6667V7.99996H21.3333ZM19.3675 18.4558L18.8528 20H13.1472L12.6325 18.4558C12.5821 18.3048 12.4795 18.1768 12.343 18.095L9.33333 16.2892V9.33329H22.6667V16.2892L19.657 18.095C19.5205 18.1768 19.4179 18.3048 19.3675 18.4558ZM12 5.33329H13.3333V6.66663H12V5.33329ZM20 5.33329H18.6667V6.66663H20V5.33329Z"
      fill="white"
    />
  </svg>
);

export default DeviceEventIcon;
