import React, { FC } from 'react';

const HideIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.50729 9.57762C6.12396 9.19496 5.89062 8.67496 5.89062 8.09162C5.89062 6.92296 6.83196 5.98096 7.99996 5.98096C8.57796 5.98096 9.10996 6.21496 9.48662 6.59762"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0702 8.46582C9.91557 9.32582 9.23824 10.0045 8.37891 10.1605"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.43634 11.648C3.37834 10.8173 2.48234 9.60399 1.83301 8.09132C2.48901 6.57199 3.39101 5.35199 4.45567 4.51466C5.51367 3.67732 6.73434 3.22266 7.99967 3.22266C9.27234 3.22266 10.4923 3.68399 11.557 4.52732"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9649 5.99365C13.4235 6.60299 13.8269 7.30632 14.1662 8.09099C12.8549 11.129 10.5375 12.959 7.99952 12.959C7.42419 12.959 6.85686 12.8657 6.31152 12.6837"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.2582 2.83301L2.74219 13.349" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default HideIcon;
