import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { IInputData } from './types';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { daDataAddressUrl } from '../../../constants/api';
import { InputStatus, defaultAddressValue } from '../input/types';
import { IDaData } from '../../../typings/DaData';
import Input from '../input';

const InputDaData: FC<IInputData> = (props) => {
  const {
    value,
    onChange = () => {},
    containerClassName = '',
    disabled = false,
    inputClassName,
    saveAfterBlur = () => {},
    daDataSearch = false,
    title = 'Адрес',
    placeholder = 'Адрес объекта',
  } = props;

  const lastCall = useRef<number>();
  const lastCallTimer = useRef<any>();
  const [realValue, setRealValue] = useState(value?.address || value?.searchValue);

  const [isOpen, setIsOpen] = useState(false);
  const isOpenRef = useRef(false);

  const { data: daDataAddresses, sendRequest: getDaDataAddresses } = useApi<IDaData[]>(getRequest);

  const updateOpen = useCallback((val: boolean) => {
    isOpenRef.current = val;
    setIsOpen(val);
  }, []);

  const onChangeInput = useCallback(
    (val: string) => {
      if (value) {
        onChange({
          ...value,
          status: InputStatus.normal,
          errorText: '',
          address: val,
          fullAddress: val,
          shortAddress: val,
          searchValue: val,
          isFinalAddress: true,
          fiasId: '',
        });
        setRealValue(val);
      }
    },
    [onChange, value]
  );

  const onSearch = useCallback(
    (val: string) => {
      if (value) {
        onChange({
          ...value,
          status: InputStatus.normal,
          errorText: '',
          searchValue: val,
          isFinalAddress: false,
        });
      }
      if (val) {
        getDaDataAddresses(daDataAddressUrl(), { params: { text: val } });
      } else {
        updateOpen(false);
      }
    },
    [value, onChange, getDaDataAddresses, updateOpen]
  );

  const debounce = useCallback(
    (callback: (a: string) => void, timeoutMs: number) => (val: string) => {
      const previousCall = lastCall.current;
      setRealValue(val);
      lastCall.current = Date.now();
      if (previousCall && lastCall.current - previousCall <= timeoutMs) {
        clearTimeout(lastCallTimer.current);
      }
      lastCallTimer.current = setTimeout(() => callback(val), timeoutMs);
    },
    []
  );

  const validate = useCallback(() => {
    let isValid = true;
    if (value) {
      if (!realValue) {
        isValid = false;
        onChange({ ...value, status: InputStatus.error, errorText: 'Поле обязательно для заполнения' });
      } else if (daDataSearch) {
        if (!value.fiasId) {
          if (daDataAddresses?.length === 0) {
            isValid = false;
            onChange({ ...value, status: InputStatus.error, errorText: 'По вашему запросу ничего не найдено' });
          } else {
            isValid = false;
            onChange({ ...value, status: InputStatus.error, errorText: 'Выберите значение из списка' });
          }
        } else if (realValue !== value.address) {
          if (daDataAddresses?.length === 0) {
            isValid = false;
            onChange({ ...value, status: InputStatus.error, errorText: 'По вашему запросу ничего не найдено' });
          } else {
            isValid = false;
            onChange({ ...value, status: InputStatus.error, errorText: 'Выберите значение из списка' });
          }
        } else if (!value.isFinalAddress) {
          isValid = false;
          onChange({ ...value, status: InputStatus.error, errorText: 'Введите полный адрес' });
        }
      }
      if (saveAfterBlur && isValid) setTimeout(() => saveAfterBlur(), 100);
    }
  }, [value, realValue, daDataSearch, saveAfterBlur, onChange, daDataAddresses?.length]);

  const onSelect = useCallback(
    (data: IDaData) => () => {
      onChange({
        ...defaultAddressValue,
        searchValue: data.address,
        fiasId: data.fiasId,
        address: data.address,
        fullAddress: data.fullAddress,
        shortAddress: data.shortAddress,
        isFinalAddress: data.isFinalAddress,
      });
      setRealValue(data.address || '');
      getDaDataAddresses(daDataAddressUrl(), { params: { text: data.address } });
    },
    [getDaDataAddresses, onChange]
  );

  useEffect(() => {
    if (realValue && daDataAddresses?.length) {
      if (value?.isFinalAddress) {
        updateOpen(false);
        validate();
      } else if (daDataSearch) {
        updateOpen(true);
      }
    } else {
      updateOpen(false);
    }
  }, [daDataAddresses]);

  const onInputBlur = useCallback(() => {
    if (!isOpenRef.current) {
      validate();
    }
  }, [validate]);

  const onClick = useCallback(() => {
    if (realValue && daDataAddresses?.length && !isOpenRef.current) {
      updateOpen(true);
    }
  }, [daDataAddresses?.length, realValue, updateOpen]);

  const outsideClick = useCallback(() => {
    updateOpen(false);
    validate();
  }, [updateOpen, validate]);

  return (
    <>
      <div onBlur={onInputBlur} className={classNames('input-da-data', containerClassName)}>
        <Input
          onClick={daDataSearch ? onClick : () => {}}
          title={title}
          placeholder={placeholder}
          value={realValue}
          onChange={daDataSearch ? debounce(onSearch, 500) : onChangeInput}
          isRequired={value?.isRequired}
          status={value?.status}
          errorText={value?.errorText}
          disabled={disabled}
          inputClassName={inputClassName}
        />
        {isOpen && (
          <div className="input-da-data__addresses">
            {daDataAddresses?.map((item, index) => (
              <div
                role="presentation"
                onClick={onSelect(item)}
                className="input-da-data__address"
                key={`${item.fiasId}-${index}`}
              >
                {item.address}
              </div>
            ))}
          </div>
        )}
      </div>
      {isOpen && (
        <div
          role="presentation"
          onClick={outsideClick}
          className={classNames('input-da-data__background', {
            'input-da-data__background_visible': isOpen,
          })}
        />
      )}
    </>
  );
};

export default InputDaData;
