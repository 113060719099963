import React, { FC } from 'react';

const ResizeToDefaultIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.1" />
      <path
        d="M16.5799 16C16.4124 16 16.2738 15.9452 16.1643 15.8357C16.0548 15.7262 16 15.5876 16 15.4201V10.7809C16 10.6134 16.0548 10.4749 16.1643 10.3653C16.2738 10.2558 16.4124 10.201 16.5799 10.201C16.7474 10.201 16.886 10.2558 16.9955 10.3653C17.105 10.4749 17.1598 10.6134 17.1598 10.7809V14.0283L22.5142 8.67397C22.6302 8.55799 22.7655 8.5 22.9201 8.5C23.0747 8.5 23.2101 8.55799 23.326 8.67397C23.442 8.78995 23.5 8.92526 23.5 9.0799C23.5 9.23454 23.442 9.36985 23.326 9.48582L17.9716 14.8402H21.2191C21.3866 14.8402 21.5251 14.895 21.6347 15.0045C21.7442 15.114 21.799 15.2526 21.799 15.4201C21.799 15.5876 21.7442 15.7262 21.6347 15.8357C21.5251 15.9452 21.3866 16 21.2191 16H16.5799ZM8.67397 23.326C8.55799 23.2101 8.5 23.0747 8.5 22.9201C8.5 22.7655 8.55799 22.6302 8.67397 22.5142L14.0283 17.1598H10.7809C10.6134 17.1598 10.4749 17.105 10.3653 16.9955C10.2558 16.886 10.201 16.7474 10.201 16.5799C10.201 16.4124 10.2558 16.2738 10.3653 16.1643C10.4749 16.0548 10.6134 16 10.7809 16H15.4201C15.5876 16 15.7262 16.0548 15.8357 16.1643C15.9452 16.2738 16 16.4124 16 16.5799V21.2191C16 21.3866 15.9452 21.5251 15.8357 21.6347C15.7262 21.7442 15.5876 21.799 15.4201 21.799C15.2526 21.799 15.114 21.7442 15.0045 21.6347C14.895 21.5251 14.8402 21.3866 14.8402 21.2191V17.9716L9.48582 23.326C9.36985 23.442 9.23454 23.5 9.0799 23.5C8.92526 23.5 8.78995 23.442 8.67397 23.326Z"
        fill="black"
      />
    </g>
  </svg>
);

export default ResizeToDefaultIcon;
