import React, { FC } from 'react';

const ChevronDoubleLeft: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 17L7 12L12 7" stroke="black" strokeLinecap="round" />
    <path d="M16 17L11 12L16 7" stroke="black" strokeLinecap="round" />
  </svg>
);

export default ChevronDoubleLeft;
