import { paths } from '../../constants/paths';

export enum EMailingStatus {
  sending = 'Sending',
  waiting = 'Waiting',
  draft = 'Draft',
  notSending = 'NotSending',
}

export const mailingStatusNamesMap = new Map<EMailingStatus, string>([
  [EMailingStatus.sending, 'Отправлено'],
  [EMailingStatus.waiting, 'Ждет отправления'],
  [EMailingStatus.draft, 'Черновик'],
  [EMailingStatus.notSending, 'Не отправлено'],
]);

export enum EMailingRecipientsStatus {
  completed = 'Completed',
  failed = 'Failed',
  created = 'Created',
  canceled = 'Canceled',
}

export const mailingRecipientsStatusNamesMap = new Map<EMailingRecipientsStatus, string>([
  [EMailingRecipientsStatus.completed, 'Отправлен'],
  [EMailingRecipientsStatus.failed, 'Не отправлен'],
  [EMailingRecipientsStatus.created, 'В очереди'],
  [EMailingRecipientsStatus.canceled, 'Отменен'],
]);

export interface IMailingFlat {
  address: string;
  buildingAddress: string;
  buildingId: string;
  buildingName: string;
  flatId: string;
  fromDate: string;
  isDisabled: boolean;
  isMain: boolean;
  number: number;
  objectId: string;
  objectName: string;
  sipNumber: number;
  status: string;
  subscriberFlatId: string;
  toDate: string;
}

export interface IMailingSubscriberObject {
  name: string;
  objectId: string;
  flats: IMailingFlat[];
}

export interface IMailingRecipient {
  id: string;
  subscriberId: string;
  objectId: string;
  email: string;
  phone: string;
  status: EMailingRecipientsStatus;
  firstName: string;
  lastName: string;
  middleName: string;
  subscriberObjects: IMailingSubscriberObject[];
}

export interface IMailingRecipientShort {
  id: string;
  name: string;
}

export interface IMailingStatus {
  displayName: string;
  type: string;
}

export interface ISmsInfo {
  isSmsMailingEnabled: boolean;
  remainingSmsCount: number;
}

export interface IMailingInfo {
  id: string;
  recipientObjects: IMailingRecipientShort[];
  createDate: string;
  sendDate: string;
  status: EMailingStatus;
  title: string;
  body: string;
}

export interface IMailingItem {
  id?: string | null;
  title: string;
  body: string;
  type?: EMailingTypes;
  sendDate: string | null;
  status: EMailingStatus;
  copyId?: string | null;
}

export interface IMailingObject {
  objectId: string;
  objectName: string;
}

export interface IMailingSubscriber {
  email: string;
  name: string;
  phone: string;
  subscriberId: string;
}

export enum EMailingTypes {
  email = '0',
  sms = '1',
  push = '2',
}

export const mapPathByMailingType = new Map([
  [EMailingTypes.email, paths.mailingEmail],
  [EMailingTypes.sms, paths.mailingSms],
  [EMailingTypes.push, paths.mailingPush],
]);
