import { Status } from '../status';
import { IAccessPointDevice } from '../treeNode';

export enum SubscriberKeyType {
  object = 'Object',
  mobile = 'Mobile',
}

export interface ISubscriberKey {
  id?: string;
  type?: SubscriberKeyType;
  key?: string;
  keyAlias: string;
  isSelected?: boolean;
}

export interface ISubscriberFlatWithKeys {
  flatId?: string;
  objectId?: string;
  subscriberFlatId?: string;
  name?: string;
  address?: string;
  status?: Status;
  keys?: ISubscriberKey[];
  devices?: IAccessPointDevice[];
}
