import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { addSubscriberEntity, getEntitiesForSubscriber } from '../../../api/subscribers';
import {
  editFlatKeyUrl,
  getCheckHiddenAccessPointsUrl,
  getFlatKeysUrl,
  getFlatsSubscribersUrl,
  getSubscriberFlatEquipmentUrl,
  validateFlatKeyUrl,
} from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { IAccessPoint, ITreeEntityKey, ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import { getRequest } from '../../../api';
import { IFlatTabComponent } from '../types';
import EquipmentTree from '../../ui/equipmentTree';
import { IFlatKey, IFlatSubscriber } from '../../../typings/flat';
import { paths } from '../../../constants/paths';
import AddNodeIcon from '../../../assets/svg/icons/addNote';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { checkIsAdmin, getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import Input from '../../ui/input';
import { IInputValue, InputStatus, defaultNotRequiredValue } from '../../ui/input/types';
import Select from '../../ui/select';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, saveChangesModal } from '../../ui/universalModal/config';
import { ButtonType } from '../../ui/button/types';
import { ESystemTabsIds } from '../../../pages/systems/item/types';
import { SubscriberKeyType } from '../../../typings/subscribers/equipment';
import { findTreeNodeByKey, resolveTreeKey, setDataNodePropsToTreeNodes } from '../../../utils/tree';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';

const FlatKeysTab: FC<IFlatTabComponent> = (props) => {
  const {
    flatId,
    flat,
    tabId,
    activeTabKey,
    clickedTab,
    updateFlats = () => {},
    chancelChangeTab = () => {},
    repeatChangeTab = () => {},
  } = props;

  const subscriberPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));
  const permissionObject = useAppSelector(getProfilePermission(ESidebarItemIds.objects));

  const navigate = useNavigate();

  const { data: keys, sendRequest: sendRequestKeys, loading: keysLoading } = useApi<IFlatKey[]>(getRequest);
  const {
    data: keyEquipmentTree,
    sendRequest: sendRequestKeyEquipmentTree,
    loading: treeLoading,
  } = useApi<ITreeNode[]>(getEntitiesForSubscriber);

  const { data: isHiddenPoints, sendRequest: getHiddenPoints, loading: hiddenPointsLoading } = useApi(getRequest);

  const { sendRequest: sendRequestUpdateEquipment } = useApi(addSubscriberEntity);

  const { sendRequest: validateKey, loading: validateLoading } = useApi(getRequest);

  const {
    data: subscribers,
    sendRequest: getSubscribers,
    loading: subscribersLoading,
  } = useApi<IFlatSubscriber[]>(getRequest);

  const [activeKeyId, setActiveKeyId] = useState<string>('');
  const [tree, setTree] = useState<ITreeNode[]>([]);

  const [name, setName] = useState<IInputValue>(defaultNotRequiredValue);
  const [subscriber, setSubscriber] = useState<IInputValue>(defaultNotRequiredValue);

  const dispatch = useAppDispatch();

  const wasChange = useAppSelector(getWasChange);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    if (clickedTab) {
      chancelChangeTab();
    }
  }, []);

  const [isMainSubscriber, setIsMainSubscriber] = useState(false);

  const isAdmin = useAppSelector(checkIsAdmin);

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const saveEquipment = useCallback(async () => {
    if (activeKeyId && flat?.id && tree.length > 0 && wasChange) {
      const clearedDataNodeTree = setDataNodePropsToTreeNodes(tree);
      return sendRequestUpdateEquipment(editFlatKeyUrl(flat?.id, activeKeyId, subscriber.value), clearedDataNodeTree, {
        params: { type: SubscriberKeyType.object, keyAlias: name.value },
      });
    }

    return false;
  }, [activeKeyId, flat?.id, tree, wasChange, subscriber.value, sendRequestUpdateEquipment, name.value]);

  const onChange = useCallback(
    (setter: (value: IInputValue) => void) => (value: string | number) => {
      setter({ ...defaultNotRequiredValue, value: value?.toString() || '' });
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [setWasChange, wasChange]
  );

  const onBlur = useCallback(() => {
    if (!name.value || !name.value.trim())
      setName({ ...name, status: InputStatus.error, errorText: 'Поле обязательно для заполнения' });
  }, [name]);

  const sortTree = useCallback((node: ITreeNode) => {
    if (node.childItems) {
      node.childItems.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
    if (node.accessPoints) {
      node.accessPoints.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }
    node.childItems?.forEach((child) => sortTree(child));
  }, []);

  const setTreeAndSort = useCallback(
    (data: ITreeNode[]) => {
      data.forEach((node) => sortTree(node));
      setTree(data);
    },
    [sortTree]
  );

  const requestSubscribers = useCallback(() => {
    getSubscribers(getFlatsSubscribersUrl(flatId || ''));
  }, [flatId, getSubscribers]);

  const requestKeyTree = useCallback(
    async (id: string) => {
      await sendRequestKeyEquipmentTree(getSubscriberFlatEquipmentUrl(flatId || '', id));
    },
    [flatId, sendRequestKeyEquipmentTree]
  );

  const requestKeys = useCallback(
    async (id: string) => {
      await sendRequestKeys(getFlatKeysUrl(id));
    },
    [sendRequestKeys]
  );

  const getData = useCallback(() => {
    if (tabId === activeTabKey && flatId) {
      requestKeys(flatId);
      requestSubscribers();
      getHiddenPoints(getCheckHiddenAccessPointsUrl(flatId));
    }
  }, [activeTabKey, flatId, getHiddenPoints, requestKeys, requestSubscribers, tabId]);

  useEffect(() => {
    getData();
  }, [tabId, activeTabKey]);

  const setDefaultName = useCallback(
    (id: string) => {
      if (keys) {
        const result = keys.find((item) => item.id === id);

        if (result) {
          setName({ ...defaultNotRequiredValue, value: result.keyAlias });
          setSubscriber({ ...defaultNotRequiredValue, value: result.subscriberId });
        }
      }
    },
    [keys]
  );

  const updateMainSubscriber = useCallback(
    (activeKey = activeKeyId) => {
      if (keys) {
        const findFlat = keys.find((key) => key.id === activeKey) || keys[0];
        setDefaultName(findFlat.id);
        const sub = subscribers?.find((item) => item.id === findFlat.subscriberId);
        if (findFlat.subscriberId) {
          setSubscriber({ ...defaultNotRequiredValue, value: findFlat.subscriberId });
        }
        setIsMainSubscriber(!!(sub && sub.isMain));
      }
    },
    [activeKeyId, keys, setDefaultName, subscribers]
  );

  useEffect(() => {
    if (activeKeyId) {
      updateMainSubscriber();
    }
  }, [activeKeyId]);

  useEffect(() => {
    if (keys && keys.length > 0) {
      const findFlat = keys.find((key) => key.id === activeKeyId) || keys[0];
      setActiveKeyId(findFlat.id || '');
      setTree([]);
      requestKeyTree(findFlat.id);
    }
  }, [keys]);

  useEffect(() => {
    if (keyEquipmentTree) {
      setTreeAndSort(keyEquipmentTree);
    }
  }, [keyEquipmentTree]);

  const handleOnChangeTree = useCallback(
    (updatedTree: ITreeNode[]) => {
      setWasChange(true);
      setTreeAndSort(updatedTree);
    },
    [setWasChange, setTreeAndSort]
  );

  const handleOnEditTree = useCallback(
    (key: string, updatedNode: IAccessPoint) => {
      if (tree) {
        const resolveKeys: ITreeEntityKey[] = resolveTreeKey(key);
        const updatedTree = [...tree];
        const { parent, nodeIndex } = findTreeNodeByKey(resolveKeys, updatedTree);

        if (parent) {
          const lastKey = resolveKeys[resolveKeys.length - 1];
          if (lastKey.type === TreeNodeType.accessPoint && parent?.accessPoints) {
            parent.accessPoints[nodeIndex] = updatedNode;
          }
        }

        handleOnChangeTree(updatedTree);
      }
    },
    [handleOnChangeTree, tree]
  );

  const saveData = useCallback(async () => {
    closeConfirm();
    await saveEquipment();
    setWasChange(false);
    getData();
    updateFlats();
  }, [closeConfirm, getData, saveEquipment, setWasChange, updateFlats]);

  const tryToSave = useCallback(
    async (callBack = () => {}) => {
      if (subscriber.value) {
        const validate = await validateKey(validateFlatKeyUrl(flat?.id || '', activeKeyId, subscriber.value));
        if (!validate?.response?.data) {
          await saveData();
          callBack();
        } else {
          setConfirmData({
            isOpen: true,
            description:
              'У выбранного абонента уже есть ключ. При назначении нового ключа старый будет откреплён от абонента',
            buttons: [
              {
                label: 'Назначить',
                type: ButtonType.primary,
                onClick: async () => {
                  await saveData();
                  callBack();
                },
              },
              {
                label: 'Отмена',
                type: ButtonType.secondary,
                onClick: () => {
                  closeConfirm();
                  callBack();
                },
              },
            ],
          });
        }
      } else {
        await saveData();
        callBack();
      }
    },
    [activeKeyId, closeConfirm, flat?.id, saveData, subscriber.value, validateKey]
  );

  const checkChanges = useCallback(
    (callBack: () => void) => {
      if (wasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              tryToSave(callBack);
            },
            () => {
              closeConfirm();
              callBack();
              setWasChange(false);
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, setWasChange, tryToSave, wasChange]
  );

  useEffect(() => {
    if (clickedTab && activeTabKey === tabId) {
      checkChanges(() => repeatChangeTab());
    }
  }, [clickedTab]);

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setWasChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const handleOnChangeActiveKeyId = useCallback(
    async (value: string) => {
      checkChanges(() => {
        if (keys) {
          setActiveKeyId(value);
          requestKeyTree(value);
        }
      });
    },
    [checkChanges, keys, requestKeyTree]
  );

  const goToSystemTab = useCallback(
    (tab: ESystemTabsIds) => {
      navigate({
        pathname: `${paths.systems}/${flat?.objectId}`,
        search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: tab })}`,
      });
    },
    [flat?.objectId, navigate]
  );

  const loading = useMemo(
    () => keysLoading || treeLoading || subscribersLoading || hiddenPointsLoading,
    [hiddenPointsLoading, keysLoading, subscribersLoading, treeLoading]
  );

  const typeOptions = useMemo(
    (): any => [
      ...(subscribers?.map((item) => ({
        value: item.id,
        title: `${item.lastName} ${item.firstName} ${item.middleName}`,
      })) || []),
      {
        value: null,
        title: 'Не назначен',
      },
    ],
    [subscribers]
  );

  return (
    <div className="equipment-tab flat-keys-tab">
      {!!keys?.length && !loading && <div className="flat-keys-tab__subtitle">Ключи</div>}
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <TabsCustom
        emptyText="Для выбранной квартиры не добавлены ключи, добавьте их в настройках объекта на вкладке «Ключи объекта»"
        emptyIcon={<AddNodeIcon />}
        activeTabKey={activeKeyId}
        onChangeActiveTab={handleOnChangeActiveKeyId}
        tabPaneContainerClassName="equipment-tab__tab-pane-container"
        tabPaneFieldTitleClassName="equipment-tab__tab-pane-text"
        loading={loading}
        tabs={keys?.map<ICustomTab>((item) => ({
          tabId: item.id || '',
          data: [
            { fieldTitle: 'Уникальный номер', fieldData: item.key },
            { fieldTitle: 'Псевдоним', fieldData: item.keyAlias },
            {
              fieldTitle: 'Абонент',
              fieldData:
                item.lastName || item.firstName || item.middleName
                  ? `${item.lastName || ''} ${item.firstName || ''} ${item.middleName || ''}`
                  : 'Не назначен',
            },
          ],
          children: (
            <>
              <div className="flat-keys-tab__form">
                <Input
                  title="Псевдоним"
                  value={name.value}
                  isRequired
                  placeholder="Псевдоним"
                  onChange={onChange(setName)}
                  maxLength={25}
                  status={name.status}
                  errorText={name.errorText}
                  onBlur={onBlur}
                  disabled={!!isAdmin}
                />
                <Select
                  title="Абонент"
                  placeholder="Абонент"
                  value={subscriber.value}
                  onChange={onChange(setSubscriber)}
                  options={typeOptions}
                  errorText={subscriber.errorText}
                  disabled={!!isAdmin}
                />
              </div>

              {isHiddenPoints &&
                (!permissionObject?.edit ? (
                  <div className="flat-keys-tab__tree-info">
                    Есть несинхронизированное оборудование. Нужно синхронизировать оборудование в объекте.
                  </div>
                ) : (
                  <div className="flat-keys-tab__tree-info">
                    Есть несинхронизированное оборудование. Синхронизируйте оборудование на вкладке{' '}
                    <span
                      role="presentation"
                      onClick={() => goToSystemTab(ESystemTabsIds.configurationFiles)}
                      className="flat-keys-tab__tree-info-link"
                    >
                      «Конфигурирование»
                    </span>{' '}
                    объекта.
                  </div>
                ))}

              <EquipmentTree
                nodeTitle={item.subscriberId ? 'Доступно по ключу и МП' : 'Доступно по ключу'}
                data={tree}
                isEditable={!isMainSubscriber && subscriberPermissions?.edit}
                onEdit={handleOnEditTree}
                permissionObject={subscriberPermissions}
              />
              <TabNavButtons
                buttons={tabNavButtonsDefault(
                  {
                    isHidden: true,
                  },
                  {
                    isHidden: !subscriberPermissions?.edit,
                    text: 'Сохранить',
                    callBack: () => tryToSave(),
                    loading: validateLoading,
                    type: ButtonType.primary,
                    disabled: !wasChange || !name.value.trim() || name.status === InputStatus.error,
                  },
                  {
                    isHidden: true,
                  }
                )}
              />
            </>
          ),
        }))}
      />
    </div>
  );
};

export default FlatKeysTab;
