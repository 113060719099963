import React, { FC, useCallback, useEffect, useState } from 'react';
import { deleteFlat } from '../../../../api/flats';
import { deleteFlatUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Modal from '../../../ui/modal';
import { IDeleteFlatModal } from './types';

const DeleteFlatModal: FC<IDeleteFlatModal> = (props) => {
  const { flatId = '', isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  const { sendRequest } = useApi(deleteFlat);

  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsError(false);
  }, [isOpen]);

  const handleOnClickDelete = useCallback(async () => {
    if (flatId) {
      const error = await sendRequest(deleteFlatUrl(flatId));
      if (!error) {
        onOk();
      } else {
        setIsError(true);
      }
    }
  }, [flatId, onOk, sendRequest]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={
        isError
          ? 'Невозможно удалить квартиру, в ней уже назначены абоненты.'
          : 'Вы действительно хотите удалить запись?'
      }
      width={416}
      showCloseIcon
      wrapClassName="delete-flat-modal__wrapper"
      centered
      footer={
        <div className="delete-flat-modal__button-container">
          {isError ? (
            <Button type={ButtonType.primary} size={ButtonSize.small} onClick={onCancel}>
              Ок
            </Button>
          ) : (
            <>
              <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel}>
                Отмена
              </Button>
              <Button size={ButtonSize.small} onClick={handleOnClickDelete}>
                Удалить
              </Button>
            </>
          )}
        </div>
      }
    />
  );
};

export default DeleteFlatModal;
