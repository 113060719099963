import { Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { ELoaderColor } from '../../../ui/loader/types';
import Loader from '../../../ui/loader';
import Scrollbar from '../../../ui/scrollbar';
import { IDeviceJournalTable } from './types';
import { dateDefaultFormat } from '../../../../constants/date';
import {
  EDispatcherDeviceStatus,
  IDispatcherPanelJournal,
  fromStatusToColor,
  fromStatusToName,
} from '../../../../typings/dispatcherPanel';
import { paths } from '../../../../constants/paths';
import { IApiSortField } from '../../../../typings/api';

const DeviceJournalTable: FC<IDeviceJournalTable> = (props) => {
  const {
    journal = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    isSearch = false,
    deviceId = '',
    sortOrders = [],
    onSort = () => {},
  } = props;

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`${paths.dispatcherPanel}/${deviceId}/${id}`);
    },
    [deviceId, navigate]
  );

  const handleOnChangeTable: TableProps<IDispatcherPanelJournal>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IDispatcherPanelJournal> | SorterResult<IDispatcherPanelJournal>[],
      extra: TableCurrentDataSource<IDispatcherPanelJournal>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IDispatcherPanelJournal>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IDispatcherPanelJournal,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IDispatcherPanelJournal,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IDispatcherPanelJournal> | null) =>
      flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null,
    []
  );

  const columns = useMemo<ColumnsType<IDispatcherPanelJournal>>(
    () => [
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: IDispatcherPanelJournal) => (
          <div className="editable-table__data-container">
            <div
              className={`device-journal-table__status dispatcher-panel__status dispatcher-panel__status_${fromStatusToColor.get(
                record.status
              )}`}
            >
              {fromStatusToName.get(record.status as EDispatcherDeviceStatus)}
            </div>
          </div>
        ),
      },
      {
        title: 'Ошибки',
        dataIndex: 'errors',
        key: 'errors',
        render: (_: any, record: IDispatcherPanelJournal) => (
          <div className="editable-table__data-container">{record.errors.length}</div>
        ),
      },
      {
        title: 'Предупреждения',
        dataIndex: 'warnings',
        key: 'warnings',
        render: (_: any, record: IDispatcherPanelJournal) => (
          <div className="editable-table__data-container">{record.warnings.length}</div>
        ),
      },
      {
        title: 'Дата',
        dataIndex: 'date',
        key: 'date',
        render: (_: any, record: IDispatcherPanelJournal) => (
          <div className="editable-table__data-container">{moment(record.date).format(dateDefaultFormat)}</div>
        ),
        sorter: {
          multiple: 0,
        },
        sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'date') || null) as SortOrder,
      },
    ],
    [getColumnSortOrder, sortOrders]
  );

  const data = useMemo(
    () =>
      journal?.map((item, index) => ({
        ...item,
        key: index,
      })),
    [journal]
  );

  return (
    <div className="device-journal-table editable-table">
      <Scrollbar>
        <Table
          onChange={handleOnChangeTable}
          onRow={(record) => ({
            onClick: () => onRowClick(record.id || ''),
          })}
          columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          scroll={{ x: '100vh' }}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default DeviceJournalTable;
