import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { setHardwareSettings } from '../../../../../api/hardwareSettings';
import { hardwareCameraUrl } from '../../../../../constants/api';
import { useApi } from '../../../../../hooks/useApi';
import { IHardwareCameraSettings } from '../../../../../typings/systems/hardwareSettings';
import Message from '../../../../message';
import TabNavButtons from '../../../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../../../tabs/tabNavButtons/utils';
import Loader from '../../../../ui/loader';
import { ELoaderColor } from '../../../../ui/loader/types';
import UniversalModal from '../../../../ui/universalModal';
import { IConfirmData } from '../../../../ui/universalModal/types';
import { IHardwarePage } from '../../types';
import { defaultConfirm, saveChangesModal } from '../../../../ui/universalModal/config';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/hooks';
import { getClickedSidebarTab } from '../../../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../../../store/slices/sidebar';
import { getRequest } from '../../../../../api';
import {
  findHardwareInInvalidList,
  updateOptionValues,
  validateCameraAdvancedOptions,
  validateOption,
} from '../../calculations';
import CameraSection from './cameraSection';
import { defaultCameraOptions, ICameraOptionsData } from '../../config';
import { ESystemTabErrorCodes } from '../../../../../api/systems/types';
import { InputStatus } from '../../../../ui/input/types';

const HardwareCamera: FC<IHardwarePage> = (props) => {
  const {
    onNextTab = () => {},
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    isWasChange = false,
    setChange = () => {},
    repeatChangeTab = () => {},
    chancelChangeTab = () => {},
    clickedTab = '',
    nextTabIsAvailable,
    permissions = {},
    getSystemData = () => {},
    selectedNode,
    clickedNode,
    repeatSelectNode = () => {},
    activeInvalidHardware,
    invalidHardwareList = [],
    updateAfterSave = () => {},
  } = props;

  const {
    data: cameraSettings,
    sendRequest: requestCameraSettings,
    loading: cameraSettingsLoading,
  } = useApi<IHardwareCameraSettings>(getRequest);

  const { sendRequest: updateCamera } = useApi(setHardwareSettings);

  const [cameraSettingsCopy, setCameraSettingsCopy] = useState<IHardwareCameraSettings | null>(null);

  const [wasSelected, setSelected] = useState<boolean>(false);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [cameraOptions, setCameraOptions] = useState<ICameraOptionsData>({ ...defaultCameraOptions });

  const [isCameraOptionsChange, setCameraOptionsChange] = useState<boolean>(false);

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const dispatch = useAppDispatch();

  const changeSettings = useCallback(
    (data: any, wasChange = true) => {
      if (!isWasChange && wasChange) {
        setChange(true);
      }
      setCameraSettingsCopy(data);
    },
    [isWasChange, setChange]
  );

  const onChangeCameraOptions = useCallback(
    (value: any, fieldName: string) => {
      const [newOptionsCopy, newSettingsCopy] = updateOptionValues(
        [{ value, fieldName }],
        cameraOptions,
        cameraSettingsCopy
      );
      changeSettings(newSettingsCopy, true);
      setCameraOptions(newOptionsCopy);
      setCameraOptionsChange(true);
      setChange(true);
    },
    [cameraOptions, cameraSettingsCopy, changeSettings, setChange]
  );

  const resetChanges = useCallback(() => {
    if (isWasChange) {
      setChange(false);
    }
    if (wasSelected) {
      setSelected(false);
    }
    if (isCameraOptionsChange) {
      setCameraOptionsChange(false);
    }
  }, [isCameraOptionsChange, isWasChange, setChange, wasSelected]);

  useEffect(() => {
    if (tabId === activeTabKey) {
      changeSettings(null, false);
      resetChanges();
    }
  }, [activeTabKey]);

  const getInvalidData = useCallback(
    (id: string) =>
      activeInvalidHardware && id === activeInvalidHardware.id
        ? activeInvalidHardware
        : invalidHardwareList?.length
        ? findHardwareInInvalidList(invalidHardwareList, id)
        : 0,
    [activeInvalidHardware, invalidHardwareList]
  );

  const requestCamera = useCallback(
    async (id = cameraSettingsCopy?.id || selectedNode?.itemId || '') => {
      if (id) {
        await requestCameraSettings(hardwareCameraUrl(id));
      }
    },
    [cameraSettingsCopy?.id, requestCameraSettings, selectedNode?.itemId]
  );

  const checkValidCameraAdvancedOptions = useCallback(() => {
    let isValid = true;
    if (cameraSettingsCopy) {
      isValid = validateCameraAdvancedOptions(cameraSettingsCopy);
      changeSettings({ ...cameraSettingsCopy });
    }
    return isValid;
  }, [cameraSettingsCopy, changeSettings]);

  const validateInputs = useCallback(
    (cOptions = cameraOptions) => {
      let camOptionsValid = true;
      const newCamOptions = { ...cOptions };
      Object.keys(newCamOptions).forEach((name) => {
        camOptionsValid = validateOption(name, newCamOptions) && camOptionsValid;
      });
      setCameraOptions(newCamOptions);

      return checkValidCameraAdvancedOptions() && camOptionsValid;
    },
    [cameraOptions, checkValidCameraAdvancedOptions]
  );

  const handleOnSave = useCallback(
    async (cOptions = cameraOptions) => {
      if (validateInputs(cOptions)) {
        if (selectedNode) {
          const resError = await updateCamera(
            hardwareCameraUrl(cameraSettingsCopy?.id || selectedNode.itemId || ''),
            cameraSettingsCopy
          );
          if (resError?.response?.data) {
            if (resError.response.data.errorCodes === ESystemTabErrorCodes.CameraMacDuplicate) {
              const newOptionsCopy = { ...cameraOptions };
              newOptionsCopy.mac = {
                ...newOptionsCopy.mac,
                errorText: resError.response.data.message || 'Не уникальный мак адрес',
                status: InputStatus.error,
              };
              setCameraOptions(newOptionsCopy);
            }
          } else {
            resetChanges();
            await updateAfterSave(cameraSettingsCopy?.id || '');
            updateAvailableTabs();
            getSystemData();
            return true;
          }
        }
        return false;
      }
      Message.error({
        content: 'Проверьте правильность заполнения полей',
      });
      return false;
    },
    [
      cameraOptions,
      cameraSettingsCopy,
      getSystemData,
      resetChanges,
      selectedNode,
      updateAfterSave,
      updateAvailableTabs,
      updateCamera,
      validateInputs,
    ]
  );

  const handleOnCancel = useCallback(async () => {
    resetChanges();
    await requestCamera();
  }, [requestCamera, resetChanges]);

  const handleOnSaveChanges = useCallback(
    (callback: () => void) => async () => {
      if (await handleOnSave(cameraOptions)) {
        callback();
      } else {
        chancelChangeTab();
      }
      setConfirmData(defaultConfirm);
    },
    [cameraOptions, chancelChangeTab, handleOnSave]
  );

  const handleOnResetChanges = useCallback(
    (callback: () => void) => async () => {
      resetChanges();
      setConfirmData(defaultConfirm);
      callback();
    },
    [resetChanges]
  );

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    if (clickedTab) {
      chancelChangeTab();
    }
  }, [clickedTab, chancelChangeTab]);

  const checkChanges = useCallback(
    async (callback: () => void) => {
      if (isWasChange) {
        setConfirmData(saveChangesModal(handleOnSaveChanges(callback), handleOnResetChanges(callback)));
      } else {
        callback();
      }
    },
    [handleOnResetChanges, handleOnSaveChanges, isWasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  useEffect(() => {
    if (clickedNode) {
      checkChanges(() => repeatSelectNode());
    }
  }, [clickedNode]);

  useEffect(() => {
    if (clickedTab && activeTabKey === tabId) {
      checkChanges(() => repeatChangeTab());
    }
  }, [clickedTab]);

  const selectedNodeWasChanges = useCallback(async () => {
    if (selectedNode) {
      resetChanges();
      requestCamera(selectedNode.itemId);
    }
  }, [requestCamera, resetChanges, selectedNode]);

  useEffect(() => {
    selectedNodeWasChanges();
  }, [selectedNode]);

  return (
    <>
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      {cameraSettingsLoading ? (
        <div className="hardware-settings__content hardware-settings__content_empty">
          <div className="hardware-settings__loader-container">
            <Loader color={ELoaderColor.blue} />
          </div>
        </div>
      ) : (
        <div className="hardware-settings__scroll default-scrollbar-override">
          <div className="hardware-settings__title">Настройки оборудования</div>
          <div className="hardware-settings__content">
            <CameraSection
              isCameraOptionsChange={isCameraOptionsChange}
              permissions={permissions}
              selectedNode={selectedNode}
              cameraSettings={cameraSettings}
              handleOnSave={handleOnSave}
              closeConfirm={closeConfirm}
              requestData={requestCamera}
              getInvalidData={getInvalidData}
              onChangeCameraOptions={onChangeCameraOptions}
              changeSettings={changeSettings}
              cameraSettingsCopy={cameraSettingsCopy}
              setConfirmData={setConfirmData}
              cameraOptions={cameraOptions}
              setCameraOptions={setCameraOptions}
            />
          </div>
        </div>
      )}
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          { callBack: handleOnCancel, isHidden: !isWasChange || !permissions.edit },
          {
            callBack: () => handleOnSave(),
            disabled: !isWasChange,
            isHidden: !permissions.edit,
          },
          { callBack: onNextTab, disabled: !nextTabIsAvailable, isHidden: !permissions.edit }
        )}
      />
    </>
  );
};

export default HardwareCamera;
