import { RootState } from '..';

export const getSelectedTabId = (state: RootState) => state.sidebar.selectedTabId;

export const getTabs = (state: RootState) => state.sidebar.tabs;

export const getClickedSidebarTab = (state: RootState) => state.sidebar.clickedSidebarTab;

export const getSelectedTab = (state: RootState) =>
  state.sidebar.tabs.find((tab) => tab.id === state.sidebar.selectedTabId);

export const getSidebarIsExpand = (state: RootState) => state.sidebar.isExpand;

export const getSidebarCounts = (state: RootState) => state.sidebar.counts;
