import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { useNavigate } from 'react-router';
import CommonHead from '../../../components/commonHead';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setHeaderTitle } from '../../../store/slices/header';
import { useApi } from '../../../hooks/useApi';
import { deleteDeferredTariffs, getTariffs, setTariffs } from '../../../api/tariffs';
import { currentTariffUrl, deferredTariffUrl, dependenciesTariffUrl, resetTariffUrl } from '../../../constants/api';
import { ITariff, EPublicationVersions, ITariffDependencies } from '../../../typings/management/tariff';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { ButtonType } from '../../../components/ui/button/types';
import Button from '../../../components/ui/button';
import Checkbox from '../../../components/ui/checkbox';
import TariffsTable from '../../../components/tariffsTable';
import SingleDatePicker from '../../../components/ui/singleDatePicker';
import { dateFormatNoMinute, dateISOFormatNoTimeZone } from '../../../constants/date';
import UniversalModal from '../../../components/ui/universalModal';
import { defaultConfirm, simpleConfirmModal } from '../../../components/ui/universalModal/config';
import { IConfirmData } from '../../../components/ui/universalModal/types';

import Message from '../../../components/message';
import { IApiError } from '../../../typings/api';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';

const TariffsPage: FC = () => {
  const dispatch = useAppDispatch();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.tariffs));

  const {
    data: dependenciesTariffs,
    sendRequest: getDependenciesTariff,
    loading: dependenciesTariffLoading,
  } = useApi<ITariffDependencies[]>(getTariffs);

  const {
    data: currentTariffs,
    sendRequest: getCurrentTariffs,
    loading: currentTariffsLoading,
  } = useApi<ITariff[]>(getTariffs);
  const { sendRequest: setCurrentTariffs, loading: setCurrentTariffsLoading } = useApi(setTariffs);

  const { sendRequest: resetCurrentTariff, loading: resetCurrentTariffLoading } = useApi(setTariffs);

  const {
    data: deferredTariffs,
    sendRequest: getDeferredTariffsData,
    loading: deferredTariffsLoading,
  } = useApi<ITariff[]>(getTariffs);
  const { sendRequest: setDeferredTariffs, loading: setDeferredTariffsLoading } = useApi(setTariffs);

  const { sendRequest: deleteDeferredTariff, loading: deleteDeferredTariffLoading } = useApi(deleteDeferredTariffs);

  const [tariffsCopy, setTariffsCopy] = useState<ITariff[]>([]);
  const [currentTab, setCurrentTab] = useState<EPublicationVersions>(EPublicationVersions.current);
  const [applyNow, setApplyNow] = useState(true);
  const [currentDate, setCurrentDate] = useState<Moment | null>(null);
  const [dateIsEmpty, setDateIsEmpty] = useState<boolean>(false);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const isDeferredTariffs = useMemo(() => !deferredTariffs || deferredTariffs.length > 0, [deferredTariffs]);
  const newFunctions = false;
  const isDefault = useMemo(() => tariffsCopy.length > 0 && tariffsCopy[0].isDefault, [tariffsCopy]);

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const resetData = useCallback(() => {
    setDateIsEmpty(false);
    setCurrentDate(null);
    setWasChange(false);
    setApplyNow(currentTab === EPublicationVersions.current);
  }, [currentTab, setWasChange]);

  const allRecordsIsCheck = useCallback((tariff: ITariff) => {
    let isFull = true;
    if (tariff) {
      tariff.functions.forEach((func) => (isFull = !!func.enabled && isFull));
    } else {
      isFull = false;
    }
    return isFull;
  }, []);

  const isLiteFull = useMemo(
    () => tariffsCopy[0] && allRecordsIsCheck(tariffsCopy[0]),
    [allRecordsIsCheck, tariffsCopy]
  );

  const changeActiveTab = useCallback(
    (version: string) => {
      setCurrentTab(version as EPublicationVersions);
      if (version === EPublicationVersions.current) {
        setCurrentDate(null);
        setApplyNow(true);
      } else {
        setApplyNow(false);
      }
      setDateIsEmpty(false);
      setWasChange(false);
    },
    [setWasChange]
  );

  useEffect(() => {
    let dataToCopy = currentTab === EPublicationVersions.current ? currentTariffs : deferredTariffs;
    if (!dataToCopy && currentTab !== EPublicationVersions.current) {
      dataToCopy = currentTariffs;
      changeActiveTab(EPublicationVersions.current);
    }
    if (dataToCopy) {
      const tariffsData = JSON.parse(JSON.stringify(dataToCopy));
      tariffsData.forEach((tariff: ITariff) => tariff.functions.sort((a, b) => (a.order || 0) - (b.order || 0)));
      setTariffsCopy(tariffsData);
      if (currentTab === EPublicationVersions.deferred && tariffsData && tariffsData[0]) {
        setCurrentDate(moment(new Date(tariffsData[0].startDate)));
      }
    } else {
      setTariffsCopy([]);
    }
  }, [currentTariffs, deferredTariffs, currentTab]);

  useEffect(() => {
    dispatch(setHeaderTitle('Тарифы'));
  }, []);

  const getData = useCallback(async () => {
    await getCurrentTariffs(currentTariffUrl());
    await getDeferredTariffsData(deferredTariffUrl());
    await getDependenciesTariff(dependenciesTariffUrl());
  }, [getCurrentTariffs, getDeferredTariffsData, getDependenciesTariff]);

  useEffect(() => {
    getData();
  }, []);

  const onChangeTariffData = useCallback(
    (data: ITariff[]) => {
      if (!wasChange) {
        setWasChange(true);
      }
      setTariffsCopy([...data]);
    },
    [setWasChange, wasChange]
  );

  const onChancel = useCallback(() => {
    getData();
    resetData();
  }, [resetData, getData]);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const findEmptyFunction = useCallback(() => {
    const length = tariffsCopy[0].functions?.length || 0;
    let oneIsEmpty = false;
    for (let i = 0; i < length; i++) {
      let isItemEmpty = true;

      for (let j = 0; j < tariffsCopy.length; j++) {
        const func = tariffsCopy[j].functions[i];
        if (func && func.enabled) {
          isItemEmpty = false;
          break;
        }
      }

      if (isItemEmpty) {
        oneIsEmpty = true;
        break;
      }
    }
    return oneIsEmpty;
  }, [tariffsCopy]);

  const checkSaveData = useCallback(
    async (callback: () => void) => {
      if (!applyNow && !currentDate) {
        setDateIsEmpty(true);
        Message.error({
          content: 'Дата/время должны быть обязательно заполнены',
        });
      } else if (isLiteFull) {
        setConfirmData(
          simpleConfirmModal(
            'Вы уверены, что хотите добавить все функции в тариф «Lite»? Остальные тарифы будут выключены.',
            callback,
            closeConfirm
          )
        );
      } else if (findEmptyFunction()) {
        setConfirmData(
          simpleConfirmModal(
            'Есть функции, которые не попадут в тарифы. Вы уверены, что хотите сохранить настройки?',
            callback,
            closeConfirm
          )
        );
      } else {
        callback();
      }
    },
    [applyNow, closeConfirm, currentDate, findEmptyFunction, isLiteFull]
  );

  const onSave = useCallback(async () => {
    let resError = null;
    if (applyNow) {
      resError = await setCurrentTariffs(currentTariffUrl(), tariffsCopy);
    } else {
      const newDeferred = tariffsCopy.map((tariff) => ({
        startDate: moment.utc(currentDate?.startOf('hours')).format(dateISOFormatNoTimeZone),
        version: tariff.version,
        functions: tariff.functions.map((func) => ({
          function: func.function,
          name: func.name,
          description: func.description,
          enabled: func.enabled,
        })),
      }));

      resError = await setDeferredTariffs(deferredTariffUrl(), newDeferred);
    }
    const error = resError?.response?.data as IApiError;
    if (error) {
      Message.error({
        content: error?.message || 'Не удалось сохранить настройки',
      });
    } else {
      Message.success({
        content: 'Настройки сохранены',
      });
      setWasChange(false);
      if (currentTab === EPublicationVersions.current) {
        setApplyNow(true);
      }
      await getData();
    }
  }, [applyNow, currentDate, currentTab, getData, setCurrentTariffs, setDeferredTariffs, setWasChange, tariffsCopy]);

  const handleTryToSave = useCallback(() => {
    checkSaveData(async () => {
      await onSave();
      closeConfirm();
    });
  }, [checkSaveData, closeConfirm, onSave]);

  const handleTtyToChangeVersion = useCallback(
    (callBack: () => void) => {
      if (wasChange) {
        setConfirmData(
          simpleConfirmModal(
            'Вы хотите сохранить изменения?',
            () =>
              checkSaveData(async () => {
                closeConfirm();
                await onSave();
                callBack();
              }),
            () => {
              closeConfirm();
              callBack();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [checkSaveData, closeConfirm, onSave, wasChange]
  );

  useEffect(() => {
    if (clickedSidebarTab) {
      handleTtyToChangeVersion(() => {
        setWasChange(false);
        navigate(clickedSidebarTab);
      });
      dispatch(setClickedSidebarTab(null));
    }
  }, [clickedSidebarTab]);

  const onDelete = useCallback(() => {
    setConfirmData(
      simpleConfirmModal(
        'Вы уверены, что хотите удалить отложенную версию?',
        async () => {
          closeConfirm();
          await deleteDeferredTariff(deferredTariffUrl());
          await getData();
          changeActiveTab(EPublicationVersions.current);
        },
        closeConfirm
      )
    );
  }, [changeActiveTab, closeConfirm, deleteDeferredTariff, getData]);

  const onReset = useCallback(async () => {
    setConfirmData(
      simpleConfirmModal(
        'Вы уверены, что хотите установить настройки по умолчанию?',
        async () => {
          closeConfirm();
          await resetCurrentTariff(resetTariffUrl(), []);
          onChancel();
        },
        closeConfirm
      )
    );
  }, [closeConfirm, onChancel, resetCurrentTariff]);

  const handleOnChangeDate = useCallback(
    (e: Moment | null) => {
      setCurrentDate(e);
      if (dateIsEmpty) {
        setDateIsEmpty(false);
      }
      if (!wasChange) {
        setWasChange(true);
      }
    },
    [dateIsEmpty, setWasChange, wasChange]
  );

  const newFunctionsContent = useMemo(
    () =>
      newFunctions && (
        <div className="tariffs__deffer-new-func">
          <span>Новое:</span>
          добавлены новые функции. Пожалуйста, примите рекомендованные настройки для них, сохранив данную версию, или
          внесите изменения. Без сохранения новые функции не будут опубликованы.
        </div>
      ),
    [newFunctions]
  );

  const tariffTabs = useMemo(
    () => [
      {
        id: EPublicationVersions.current,
        title: 'Текущая версия',
        component: TariffsTable,
        position: 0,
        isDisableAll: isDeferredTariffs,
        additionalContent: (
          <>
            {isDeferredTariffs && (
              <div className="tariffs__notify tariffs__notify_changes">
                Внесение изменений в текущую версию недоступно, пока у вас активна неопубликованная отложенная версия.
              </div>
            )}
            {newFunctionsContent}
          </>
        ),
      },
      {
        id: EPublicationVersions.deferred,
        title: 'Отложенная версия',
        component: TariffsTable,
        position: 1,
        disabled: !isDeferredTariffs,
        notification:
          'Вы можете создать отложенную версию, внеся изменения в текущую, сохранив эти настройки с будущей датой',
        additionalContent: (
          <>
            <div className="tariffs__deffer-info">
              <span className="tariffs__deffer-info-label">
                Дата публикации: {moment(tariffsCopy[0]?.startDate).format(dateFormatNoMinute)}
              </span>
              {permissions?.edit && (
                <Button onClick={onDelete} type={ButtonType.tertiary}>
                  Удалить версию
                </Button>
              )}
            </div>
            {newFunctionsContent}
          </>
        ),
      },
    ],
    [isDeferredTariffs, newFunctionsContent, onDelete, permissions?.edit, tariffsCopy]
  );

  return (
    <>
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <CommonHead seo={{ title: 'Тарифы версий мобильного приложения' }} />
      <div className="tariffs">
        {isDefault && (
          <div className="tariffs__notify tariffs__notify_default">
            Действуют настройки по умолчанию. Вы можете создать свою версию тарифов, для этого нужно внести и сохранить
            изменения в текущей версии.
          </div>
        )}
        {currentTariffsLoading ||
        setCurrentTariffsLoading ||
        deferredTariffsLoading ||
        dependenciesTariffLoading ||
        resetCurrentTariffLoading ||
        setDeferredTariffsLoading ||
        deleteDeferredTariffLoading ||
        setCurrentTariffsLoading ? (
          <div className="device-log__loader-container">
            <Loader color={ELoaderColor.blue} />
          </div>
        ) : (
          <Tabs
            activeTabKey={currentTab}
            onChangeActiveTab={(version: string) => handleTtyToChangeVersion(() => changeActiveTab(version))}
            tabsClassName="subscriber-item__tabs"
            tabBarExtraContent={
              isDefault || isDeferredTariffs || !permissions?.edit ? null : (
                <Button onClick={onReset} type={ButtonType.outline}>
                  Сбросить настройки
                </Button>
              )
            }
            tabs={tariffTabs.map<ITab>((tab) => ({
              ...tab,
              children: (
                <tab.component
                  isDisableAll={tab.isDisableAll}
                  dependenciesTariffs={dependenciesTariffs || []}
                  additionalContent={tab.additionalContent}
                  allRecordsIsCheck={allRecordsIsCheck}
                  onChange={onChangeTariffData}
                  tariffsData={tariffsCopy}
                  permissions={permissions}
                  isLiteFull={isLiteFull}
                />
              ),
            }))}
          />
        )}

        {!(currentTab === EPublicationVersions.current && isDeferredTariffs) && (
          <div className="tariffs__buttons">
            <Button onClick={onChancel} type={ButtonType.tertiary} disabled={!wasChange} hidden={!permissions?.edit}>
              Отмена
            </Button>
            {!applyNow && (
              <SingleDatePicker
                onlyFuture
                isError={dateIsEmpty}
                placeholder="Выберите время и дату"
                dateValue={currentDate}
                onChange={handleOnChangeDate}
              />
            )}
            <Checkbox
              disabled={!wasChange}
              label="Применить сейчас"
              checked={applyNow}
              onChange={(checked) => setApplyNow(checked)}
            />

            <Button
              onClick={handleTryToSave}
              type={ButtonType.primary}
              disabled={!wasChange || !permissions?.edit}
              hidden={!permissions?.edit}
            >
              Сохранить
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default TariffsPage;
