import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import EditIcon from '../../assets/svg/icons/edit';
import { useAppSelector } from '../../hooks/hooks';
import { checkIsAdmin } from '../../store/selectors/profile';
import { ISystem } from '../../typings/systems/system';
import Loader from '../ui/loader';
import { ELoaderColor } from '../ui/loader/types';
import Tooltip from '../ui/tooltip';
import { ISystemTable } from './types';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import SearchFailIcon from '../../assets/svg/icons/searchFail';
import { dateFormatNoTime } from '../../constants/date';

const SystemsTable: FC<ISystemTable> = (props) => {
  const {
    systems = [],
    onClickRow = () => {},
    onEditRow = () => {},
    pagination = false,
    permissions = {},
    loading = false,
  } = props;

  const isAdmin = useAppSelector(checkIsAdmin);

  const columns = useMemo<ColumnsType<ISystem>>(
    () =>
      [
        {
          title: 'Организация',
          dataIndex: 'companyName',
          key: 'companyName',
          hidden: !isAdmin,
          render: (_: any, record: ISystem) => <span role="presentation">{record.companyName}</span>,
        },
        {
          title: 'Краткое наименование',
          dataIndex: 'objectName',
          key: 'objectName',
          render: (_: any, record: ISystem) => (
            <span role="presentation" className="systems-table__text-bold">
              {record.objectName}
            </span>
          ),
        },
        {
          title: 'Строений',
          dataIndex: 'buildingsCount',
          key: 'buildingsCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.buildingsCount}</span>,
        },
        {
          title: 'Подъездов',
          dataIndex: 'sectionsCount',
          key: 'sectionsCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.sectionsCount}</span>,
        },
        {
          title: 'Точки доступа',
          dataIndex: 'accessPointCount',
          key: 'accessPointCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.accessPointCount}</span>,
        },
        {
          title: 'Камеры',
          dataIndex: 'externalCamerasCount',
          key: 'externalCamerasCount',
          render: (_: any, record: ISystem) => <span role="presentation">{record.externalCamerasCount}</span>,
        },
        {
          title: 'Адрес',
          dataIndex: 'address',
          key: 'address',
          render: (_: any, record: ISystem) => <span role="presentation">{record.address}</span>,
        },
        {
          title: 'Создан',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (_: any, record: ISystem) => (
            <span role="presentation">{record.createdAt ? moment(record.createdAt).format(dateFormatNoTime) : ''}</span>
          ),
        },
        {
          className: 'table__icon-cell',
          hidden: !permissions.edit && !permissions.delete,
          render: (_: any, record: ISystem) => (
            <div role="presentation" className="table__icon-container">
              {permissions.edit && (
                <Tooltip title="Редактировать" placement="bottom">
                  <div
                    onClick={() => onEditRow(record.id || '', record)}
                    role="presentation"
                    className="systems-table__icon"
                  >
                    <EditIcon />
                  </div>
                </Tooltip>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [isAdmin, onEditRow, permissions.delete, permissions.edit]
  );

  const data = useMemo(
    () =>
      systems.map((system) => ({
        ...system,
        key: system.id,
      })),
    [systems]
  );

  return (
    <div className="systems-table table">
      <Table
        columns={columns}
        dataSource={data.reverse()}
        showSorterTooltip={false}
        locale={{
          emptyText: (
            <ErrorPlaceholder
              containerClassName="device-event-table__error-wrapper"
              text="По вашему запросу ничего не найдено"
              icon={<SearchFailIcon />}
            />
          ),
        }}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        onRow={(record) => ({
          onClick: () => onClickRow(record.id || '', record),
        })}
        pagination={
          pagination && {
            current: pagination.currentPage + 1,
            pageSize: pagination.pageSize,
            hideOnSinglePage: true,
            total: pagination.total,
            onChange: pagination.onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'table__pagination table__pagination_absolute',
          }
        }
      />
    </div>
  );
};

export default SystemsTable;
