import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { IInformationPanelDropDownItem } from './types';
import ChevronDown from '../../../../../assets/svg/icons/chevronDown';
import { paths } from '../../../../../constants/paths';

const InformationPanelDropDownItem: FC<IInformationPanelDropDownItem> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  return data ? (
    <div className="dispatcher-item-dropdown">
      <div
        role="presentation"
        onClick={() => setIsOpen(!isOpen)}
        className={`dispatcher-item-dropdown__title dispatcher-panel__status dispatcher-panel__status_${data.colorName}`}
      >
        <div className="dispatcher-item-dropdown__title-label">
          {data.value} {data.label}
        </div>
        {!!data.objectNames.length && (
          <div
            className={classNames('dispatcher-item-dropdown__title-arrow', {
              'dispatcher-item-dropdown__title-arrow_open': isOpen,
            })}
          >
            <ChevronDown />
          </div>
        )}
      </div>
      {!!data.objectNames.length && isOpen && (
        <div className="dispatcher-item-dropdown__content">
          {data.objectNames.map((item, index) => (
            <div
              role="presentation"
              onClick={() => navigate(`${paths.dispatcherPanel}/${item.deviceId}?tabId=journal`)}
              className="dispatcher-item-dropdown__item-name"
              key={`name-${index}`}
            >
              {item.deviceName}
            </div>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default InformationPanelDropDownItem;
