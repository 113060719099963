import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import Button from '../ui/button';
import { ButtonSize, ButtonType } from '../ui/button/types';
import Modal from '../ui/modal';
import { postRequest } from '../../api';
import { transferObjectUrl } from '../../constants/api';
import { useApi } from '../../hooks/useApi';
import { ITransferObjectModal } from './types';
import Input from '../ui/input';
import { InputStatus } from '../ui/input/types';
import AuthService from '../../api/authService';
import { ID_REGEX } from '../../constants/regex';

const TransferObjectModal: FC<ITransferObjectModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {} } = props;

  const { sendRequest: sendCode, loading: sendCodeLoading } = useApi(postRequest);

  const [code, setCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const userEmail = AuthService.getUsername();

  useEffect(() => {
    if (errorMessage) setErrorMessage('');
  }, [code]);

  useEffect(() => {
    setIsSuccess(false);
    setCode('');
    setErrorMessage('');
  }, [isOpen]);

  const onSave = useCallback(async () => {
    const resError = (await sendCode(transferObjectUrl(), {
      Code: code,
    })) as AxiosError;
    if (!resError?.response?.data) {
      setIsSuccess(true);
    } else {
      setErrorMessage((resError?.response?.data as any)?.message);
    }
  }, [sendCode, code]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={
        isSuccess
          ? `Процесс переноса объекта запущен,
ожидайте письмо о результате
на ${userEmail}`
          : 'Добавить объект по коду'
      }
      width={416}
      showCloseIcon
      wrapClassName="transfer-object__wrapper"
      centered
      footer={
        <div className="transfer-object__button-container">
          {isSuccess ? (
            <Button size={ButtonSize.small} onClick={onOk}>
              Хорошо
            </Button>
          ) : (
            <>
              <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onCancel} disabled={sendCodeLoading}>
                Отмена
              </Button>
              <Button disabled={!code || !ID_REGEX.test(code)} size={ButtonSize.small} onClick={onSave}>
                Добавить
              </Button>
            </>
          )}
        </div>
      }
    >
      {isSuccess ? null : (
        <>
          <div className="transfer-object__text">
            Введите код, чтобы принять существующий объект от другой организации
          </div>
          <Input
            title="Код для принятия объекта"
            isRequired
            value={code}
            onChange={setCode}
            errorText={errorMessage}
            status={errorMessage ? InputStatus.error : InputStatus.normal}
            placeholder="Код для принятия объекта"
          />
        </>
      )}
    </Modal>
  );
};

export default TransferObjectModal;
