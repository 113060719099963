import { Radio as RadioComponent, RadioChangeEvent, Space } from 'antd';
import classNames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import InfoIcon from '../../../assets/svg/icons/info';
import Radio from '../radio';
import Tooltip from '../tooltip';
import { ERadioGroupDirection, IRadioGroup, IRadioGroupOption } from './types';

const RadioGroup: FC<IRadioGroup> = (props) => {
  const {
    title,
    textInfo,
    isRequired = false,
    value,
    options = [],
    disabled = false,
    onChange = () => {},
    containerClassName = '',
    itemClassName = '',
    titleClassName = '',
    direction = ERadioGroupDirection.vertical,
  } = props;

  const handleOnChange = useCallback(
    (event: RadioChangeEvent) => {
      onChange(event.target.value, event);
    },
    [onChange]
  );

  const spaceGap = useMemo<number>(() => {
    switch (direction) {
      case ERadioGroupDirection.horizontal:
        return 6;
      case ERadioGroupDirection.vertical:
      default:
        return 16;
    }
  }, [direction]);

  return (
    <div className="radio-group">
      {title && (
        <span className={classNames('radio-group__title-container')}>
          <span className={classNames('radio-group__title', titleClassName)}>
            {title}
            {isRequired && <span className="radio-group__title-required">*</span>}
          </span>
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="radio-group__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}
      <RadioComponent.Group onChange={handleOnChange} value={value} disabled={disabled}>
        <Space direction={direction} className={containerClassName} size={spaceGap} wrap>
          {options.map((option: IRadioGroupOption, index) => (
            <Radio
              disabled={disabled}
              key={`radio-group-item-${index}`}
              value={option.value}
              className={classNames('radio-group__item', itemClassName)}
              title={option.title}
            />
          ))}
        </Space>
      </RadioComponent.Group>
    </div>
  );
};

export default RadioGroup;
