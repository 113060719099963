import React, { FC } from 'react';
import { IFeedbacksScore } from './types';
import BurningStartIcon from '../../../assets/svg/icons/burningStar';
import ExtinctStartIcon from '../../../assets/svg/icons/extinctStar';

const FeedbacksScore: FC<IFeedbacksScore> = (props) => {
  const { score = 0, wrapClassName } = props;

  return score ? (
    <div className={`feedback-score ${wrapClassName || ''}`}>
      {[...new Array(score)].map((_, index) => (
        <div className="feedback-score__item" key={`star-${index}`}>
          <BurningStartIcon />
        </div>
      ))}
      {[...new Array(5 - score)].map((_, index) => (
        <div className="feedback-score__item" key={`star-${index}`}>
          <ExtinctStartIcon />
        </div>
      ))}
    </div>
  ) : (
    <>Не оценено</>
  );
};

export default FeedbacksScore;
