import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { IFAQSectionModal } from './types';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import InfoIcon from '../../../../assets/svg/icons/info';
import { useApi } from '../../../../hooks/useApi';
import { postRequest, putRequest } from '../../../../api';
import { modifyFAQSectionUrl } from '../../../../constants/api';

const FaqSectionModal: FC<IFAQSectionModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, editItem = null, isAdmin = false } = props;

  const { sendRequest: createSections, loading: createLoading } = useApi(postRequest);
  const { sendRequest: editSections, loading: editLoading } = useApi(putRequest);

  const [name, setName] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  const onAccess = useCallback(async () => {
    let resError;
    if (editItem) {
      resError = (await editSections(modifyFAQSectionUrl(), { name, id: editItem.id })) as AxiosError;
    } else {
      resError = (await createSections(modifyFAQSectionUrl(), { name })) as AxiosError;
    }
    if (!resError?.response?.data) {
      onOk();
    } else {
      const { message } = resError?.response?.data as any;
      setErrorMessage(message);
    }
  }, [createSections, editItem, editSections, name, onOk]);

  useEffect(() => {
    if (editItem && isOpen) {
      setName(editItem.name);
    } else {
      setName('');
      setErrorMessage('');
    }
  }, [editItem, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title={!editItem ? 'Создание нового раздела' : 'Редактирование раздела'}
      width={410}
      showCloseIcon
      wrapClassName="faq-section-modal__wrapper"
      centered
      footer={
        <div className="faq-section-modal__button-container">
          <Button
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={onCancel}
            disabled={createLoading || editLoading}
          >
            Отмена
          </Button>
          <Button
            size={ButtonSize.small}
            onClick={onAccess}
            disabled={createLoading || editLoading || !name || !name.trim()}
          >
            {!editItem ? 'Сохранить' : 'Редактировать'}
          </Button>
        </div>
      }
    >
      <Input
        title="Название раздела"
        isRequired
        errorText={errorMessage}
        value={name}
        onChange={setName}
        placeholder="Название раздела"
        maxLength={50}
      />
      {isAdmin && (
        <div className="faq-section-modal__notify">
          <div className="faq-section-modal__notify-icon">
            <InfoIcon />
          </div>
          <div className="faq-section-modal__notify-label">
            Раздел будет отображаться для всех объектов и организаций
          </div>
        </div>
      )}
    </Modal>
  );
};

export default FaqSectionModal;
