import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { ISeo } from '../../typings/seo';
import { ICommonHead } from './types';

const CommonHead: FC<ICommonHead> = (props) => {
  const { seo = {} } = props;

  const currentSeo: ISeo = {
    title: 'ELTIS',
    ...seo,
  };

  return (
    <Helmet>
      <title>{currentSeo?.title}</title>
      {!!currentSeo?.description && <meta name="description" content={currentSeo.description} key="description" />}
      {!!currentSeo?.keywords && <meta name="keywords" content={currentSeo.keywords} key="keywords" />}
      {!!currentSeo?.ogTitle && <meta property="og:title" content={currentSeo.ogTitle} />}
      {!!currentSeo?.ogDescription && <meta property="og:description" content={currentSeo.ogDescription} />}
      {!!currentSeo?.ogType && <meta property="og:type" content={currentSeo.ogType} />}
      {!!currentSeo?.ogUrl && <meta property="og:url" content={currentSeo.ogUrl} />}
    </Helmet>
  );
};

export default CommonHead;
