export enum EPublicationVersions {
  deferred = 'deferred',
  current = 'current',
}

export enum ETariffVersions {
  lite = 'Lite',
  medium = 'Medium',
  comfort = 'Comfort',
}

export interface ITariffFunction {
  function?: string;
  order?: number;
  name?: string;
  description?: string;
  enabled?: boolean;
}

export interface ITariff {
  startDate?: string;
  isDefault?: boolean;
  version?: ETariffVersions;
  functions: ITariffFunction[];
}

export interface ITariffDependencies {
  name?: string;
  dependencies?: string[];
}
