import { IProfilePermission } from '../../../../../../typings/profile';
import { IHardwareCameraParam, IHardwareCameraSettings } from '../../../../../../typings/systems/hardwareSettings';

export interface IAdvancedCameraOptions {
  settings: IHardwareCameraSettings;
  changeSettings: (settings: IHardwareCameraSettings) => void;
  permissions?: IProfilePermission;
}

export interface IParamField {
  index: number;
  object: IHardwareCameraParam;
  wasSelected?: boolean;
  changeParam: (index: number, param: IHardwareCameraParam) => void;
  disabled?: boolean;
  isEditEnable?: boolean;
}

export interface IHardwareMapParam {
  object: IHardwareCameraParam;
  index: number;
}

export interface IHardwareMapGroups {
  collection: IHardwareMapParam[];
  position: number;
}

export const emptyFieldErrorText = 'Поле должно быть заполнено';
