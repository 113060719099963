import { IProfilePermission } from '../../../typings/profile';

export interface IDisablingPush {
  permissions?: IProfilePermission;
  children?: React.ReactNode;
  deviceId?: string;
  objectId?: string;
  className?: string;
}

export enum ENotificationsTypes {
  events = 'Events',
  notification = 'Notification',
  all = 'All',
}
