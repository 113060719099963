import { IApiSettings } from '../../typings/api';
import { IObjectKeysFlat } from '../../typings/systems/objectKeys';

export const OBJECT_KEYS_TABLE_COUNT = 10;

export const objectKeysInitApiSettings: IApiSettings<IObjectKeysFlat> = {
  sortFields: [
    {
      field: 'flatNumber',
      descending: false,
    },
  ],
  page: 0,
  count: OBJECT_KEYS_TABLE_COUNT,
  search: '',
};
