import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import moment, { Moment } from 'moment';
import { IAddMessageModal } from './types';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { useApi } from '../../../../hooks/useApi';
import { postRequest } from '../../../../api';
import { dispatcherPanelNotificationUrl } from '../../../../constants/api';
import { EFieldTypes } from '../../../ui/input/types';
import Checkbox from '../../../ui/checkbox';
import SingleDatePicker from '../../../ui/singleDatePicker';
import Message from '../../../message';
import { dateDefaultFormat } from '../../../../constants/date';

const AddMessageModal: FC<IAddMessageModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, deviceId = '', onOk = () => {} } = props;

  const { sendRequest: addMessage, loading: addMessageLoading } = useApi(postRequest);

  const [message, setMessage] = useState('');
  const [withNotification, setWithNotification] = useState(false);
  const [date, setDate] = useState<Moment | null>(null);

  useEffect(() => {
    if (isOpen) {
      setMessage('');
      setWithNotification(false);
      setDate(null);
    }
  }, [isOpen]);

  const onAccess = useCallback(async () => {
    const resError = (await addMessage(dispatcherPanelNotificationUrl(deviceId), {
      deviceId: deviceId,
      text: message,
      date: date || moment(),
      withNotification,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
      Message.success({
        content: `${
          date && withNotification ? 'Напоминание создано.' : 'Комментарий создан.'
        } Для просмотра перейдите на вкладку «Сообщения» `,
      });
    }
  }, [addMessage, date, deviceId, message, onOk, withNotification]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Добавление сообщения"
      width={604}
      showCloseIcon
      wrapClassName="add-message-modal__wrapper"
      centered
      footer={
        <div className="add-message-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel} disabled={addMessageLoading}>
            Отмена
          </Button>
          <Button
            size={ButtonSize.small}
            onClick={onAccess}
            disabled={addMessageLoading || !message || !message.trim() || (withNotification && !date)}
          >
            Отправить
          </Button>
        </div>
      }
    >
      <Input
        title="Текст сообщения"
        value={message}
        onChange={setMessage}
        autoSize={{ minRows: 4, maxRows: 4 }}
        maxLength={600}
        fieldType={EFieldTypes.textArea}
      />
      <div className="add-message-modal__inputs">
        <Checkbox label="Установить напоминание" checked={withNotification} onChange={setWithNotification} />
        <SingleDatePicker
          format={dateDefaultFormat}
          onlyFuture
          isDisabledStyle
          placeholder="Выберите время"
          dateValue={date}
          onChange={setDate}
        />
      </div>
    </Modal>
  );
};

export default AddMessageModal;
