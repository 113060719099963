import React, { FC, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import { AxiosError } from 'axios';
import Input from '../../../ui/input';
import { useApi } from '../../../../hooks/useApi';
import { postRequest } from '../../../../api';
import { dispatcherPanelNotificationUrl } from '../../../../constants/api';
import { IDeviceNotificationsForm } from './types';
import { EFieldTypes } from '../../../ui/input/types';
import Checkbox from '../../../ui/checkbox';
import SingleDatePicker from '../../../ui/singleDatePicker';
import Button from '../../../ui/button';
import { ButtonSize } from '../../../ui/button/types';
import { dateDefaultFormat } from '../../../../constants/date';

const DeviceNotificationsForm: FC<IDeviceNotificationsForm> = ({
  deviceId = '',
  onSuccess = () => {},
  permissions,
  isAdmin = false,
}) => {
  const { sendRequest: addMessage, loading: addMessageLoading } = useApi(postRequest);

  const [message, setMessage] = useState('');
  const [withNotification, setWithNotification] = useState(false);
  const [date, setDate] = useState<Moment | null>(null);

  const onAccess = useCallback(async () => {
    const resError = (await addMessage(dispatcherPanelNotificationUrl(deviceId), {
      deviceId: deviceId,
      text: message,
      date: date || moment(),
      withNotification,
    })) as AxiosError;
    if (!resError?.response?.data) {
      setMessage('');
      setWithNotification(false);
      setDate(null);
      onSuccess();
    }
  }, [addMessage, date, deviceId, message, onSuccess, withNotification]);

  return (
    <div className="device-notifications-form">
      {isAdmin ? null : (
        <>
          <Input
            title="Текст сообщения"
            value={message}
            onChange={setMessage}
            autoSize={{ minRows: 4, maxRows: 4 }}
            maxLength={600}
            fieldType={EFieldTypes.textArea}
            disabled={!permissions?.edit}
          />
          <div className="device-notifications-form__inputs">
            <Checkbox
              disabled={!permissions?.edit}
              label="Установить напоминание"
              checked={withNotification}
              onChange={setWithNotification}
            />
            <SingleDatePicker
              format={dateDefaultFormat}
              onlyFuture
              isDisabledStyle
              placeholder="Выберите время"
              dateValue={date}
              onChange={setDate}
              disabled={!permissions?.edit}
            />
            <Button
              size={ButtonSize.small}
              onClick={onAccess}
              disabled={
                addMessageLoading || !message || !message.trim() || (withNotification && !date) || !permissions?.edit
              }
            >
              Отправить
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceNotificationsForm;
