import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import PlusIcon from '../../../../../../../assets/svg/icons/plus';
import TrashLightIcon from '../../../../../../../assets/svg/icons/trashLight';
import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import { IArrayField, IArrayFieldItemError } from './types';

const ArrayField: FC<IArrayField> = ({
  object,
  changeParam,
  index,
  wasSelected,
  disabled = false,
  isEditEnable = false,
}) => {
  useEffect(() => {
    if (!object.value && !object.wasChange && wasSelected && Array.isArray(object.defaultValue)) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (i: number, value: string) => {
      if (object.errorData) {
        const errorItemIndex = object.errorData.findIndex((item: IArrayFieldItemError) => item.index === i);
        if (errorItemIndex !== -1) {
          object.errorData.splice(errorItemIndex, 1);
        }
        if (!(object.errorData.length > 0)) {
          object.isError = false;
        }
      } else {
        object.isError = false;
      }
      object.errorText = '';
      object.wasChange = true;
      if (object.value) {
        object.value[i] = value === '' ? null : Number(value);
      }
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  const onAdd = useCallback(
    (i: number) => {
      if (disabled || object.readOnly) {
        return;
      }

      if (!object.value) {
        object.value = [];
      }
      if (
        (object.restricts.restricts.maxCount as number) > object.value.length ||
        !object.restricts.restricts.maxCount
      ) {
        object.value.splice(i + 1, 0, null);
        object.wasChange = true;
        changeParam(index, object);
      }
    },
    [changeParam, disabled, index, object]
  );

  const onDelete = useCallback(
    (i: number) => {
      if (disabled || object.readOnly) {
        return;
      }

      object.value?.splice(i, 1);
      object.errorData = null;
      object.isError = false;
      object.errorText = '';
      object.wasChange = true;
      if (object.value?.length === 0) {
        object.value = null;
      }
      changeParam(index, object);
    },
    [changeParam, disabled, index, object]
  );

  const getError = useCallback(
    (i: number) => object.errorData?.find((errorItem: IArrayFieldItemError) => errorItem.index === i),
    [object.errorData]
  );

  const renderInputs = useCallback(
    (collection: Array<number | null>) =>
      collection.map((item, i) => {
        const error = getError(i);
        return (
          <div className="array-field__item" key={`array-${object.id}-field-${i}`}>
            <Input
              disabled={disabled}
              value={item?.toString() || ''}
              onChange={(value) => onChange(i, value)}
              inputType={InputType.numbers}
              maxLength={9}
              status={error ? InputStatus.error : InputStatus.normal}
              errorText={error?.errorText || ''}
              containerClassName={classNames({ 'array-field__input-disabled': object.readOnly })}
              isDisabledStyle={disabled && object.readOnly}
            />
            {isEditEnable && (
              <div className="array-field__item-icons">
                <div
                  className={classNames('array-field__item-icon', {
                    'array-field__item-icon_disabled':
                      object.restricts.restricts.maxCount === object.value?.length || object.readOnly,
                  })}
                  onClick={() => onAdd(i)}
                  role="presentation"
                >
                  <PlusIcon />
                </div>
                <div
                  className={classNames('array-field__item-icon', {
                    'array-field__item-icon_disabled': object.readOnly,
                  })}
                  onClick={() => onDelete(i)}
                  role="presentation"
                >
                  <TrashLightIcon />
                </div>
              </div>
            )}
          </div>
        );
      }),
    [
      disabled,
      getError,
      isEditEnable,
      object.id,
      object.readOnly,
      object.restricts.restricts.maxCount,
      object.value?.length,
      onAdd,
      onChange,
      onDelete,
    ]
  );

  const inputs = useMemo(
    () =>
      object.value && Array.isArray(object.value) && object.value.length ? (
        renderInputs(object.value)
      ) : (
        <div
          className={classNames('array-field__plus', {
            'array-field__plus_disabled': object.readOnly,
          })}
          onClick={() => onAdd(0)}
          role="presentation"
        >
          <PlusIcon />
        </div>
      ),
    [object.readOnly, object.value, onAdd, renderInputs]
  );

  return (
    <div className="array-field">
      <div className={classNames('array-field__content', { 'array-field__content_error': object.isError })}>
        <div
          className={classNames('array-field__title', {
            'array-field__title_disabled': object.readOnly,
          })}
        >
          {object.description}
          {object.isRequired && <span className="input__title-required">*</span>}
        </div>
        <div className="array-field__items">{inputs}</div>
      </div>
      {object.isError && <span className="input__error">{object.errorText || ''}</span>}
    </div>
  );
};

export default ArrayField;
