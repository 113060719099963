import { IFlatsPageFlat } from '../../typings/flat';
import { IApartmentApiSettings } from './types';

export const PAYMENT_TABLE_COUNT = 10;

export const getApartmentApiSettings = (defaultSortField: string): IApartmentApiSettings<IFlatsPageFlat> => ({
  sortFields: defaultSortField
    ? [
        {
          field: defaultSortField as keyof IFlatsPageFlat,
          descending: false,
        },
      ]
    : [],
  search: '',
  count: PAYMENT_TABLE_COUNT,
});
