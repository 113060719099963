import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { InputType } from '../types';
import Input from '..';
import { INumberInput } from './types';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';

const defaultValue = '0.00';

const NumberInput: FC<INumberInput> = (props) => {
  const { onChange = () => {}, value } = props;

  const [isFocused, setIsFocused] = useState(false);
  const [stringValue, setStringValue] = useState(defaultValue);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value && stringValue === defaultValue) {
      setStringValue(value.toFixed(2));
    } else if (value === null || (value === 0 && stringValue !== defaultValue)) {
      setStringValue(defaultValue);
    } else if (value !== Number(stringValue)) {
      setStringValue(value?.toFixed(2) || '');
    }
  }, [value]);

  const clickOutside = useCallback(() => {
    if (isFocused) {
      setIsFocused(false);
      if (stringValue === '0') {
        setStringValue(defaultValue);
      } else {
        setStringValue(Number(stringValue).toFixed(2));
      }
    }
  }, [isFocused, stringValue]);

  useOnClickOutside(inputRef, clickOutside);

  const onChangeInput = useCallback(
    (realString: string, event: ChangeEvent<Element> | undefined) => {
      let resultString = stringValue;
      if (realString === '') {
        resultString = '0';
      } else {
        const realNumber = parseFloat(realString);
        if (Math.abs(realNumber) < 1000000) {
          if (stringValue === '0') {
            resultString = realNumber.toString();
          } else {
            resultString = realString;
          }
          const decimal = resultString.split('.');
          if (decimal[1]) {
            resultString = realNumber.toFixed(decimal[1].length >= 2 ? 2 : decimal[1].length);
          }
        }
      }
      onChange(Number(resultString), event);
      setStringValue(resultString);
    },
    [onChange, stringValue]
  );

  const onClick = useCallback(() => {
    setIsFocused(true);
    if (stringValue === defaultValue) {
      setStringValue('0');
    }
  }, [stringValue]);

  return (
    <div className="number-input" ref={inputRef}>
      <Input {...props} onClick={onClick} inputType={InputType.number} value={stringValue} onChange={onChangeInput} />
    </div>
  );
};

export default NumberInput;
