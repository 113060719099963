import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { getEntitiesForSubscriber } from '../../../api/subscribers';
import { getSubscriberDeviceLogActionTypeListUrl, getSubscriberFlatsWithDeviceUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { IFlatEventActionType } from '../../../typings/subscribers/deviceLog';
import { ISubscriberFlatWithKeys } from '../../../typings/subscribers/equipment';
import { sortFlatWithKeysByAddress } from '../../../utils/subscriber';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import { ISubscriberTabComponent } from '../types';
import DeviceLogData from './deviceLogData';

const DeviceLog: FC<ISubscriberTabComponent> = (props) => {
  const { subscriber } = props;

  const [activeFlatId, setActiveFlatId] = useState<string>('');
  const {
    data: flats,
    sendRequest: sendRequestFlats,
    loading: flatsLoading,
  } = useApi<ISubscriberFlatWithKeys[]>(getEntitiesForSubscriber, sortFlatWithKeysByAddress);
  const {
    data: actionTypeList,
    sendRequest: sendRequestActionTypeList,
    loading: actionTypeListLoading,
  } = useApi<IFlatEventActionType[]>(getEntitiesForSubscriber);

  useEffect(() => {
    if (subscriber?.id) {
      sendRequestFlats(getSubscriberFlatsWithDeviceUrl(subscriber.id), { params: { type: 'All' } });
    }
  }, [subscriber]);

  useEffect(() => {
    if (!actionTypeList) {
      sendRequestActionTypeList(getSubscriberDeviceLogActionTypeListUrl());
    }
  }, [actionTypeList]);

  useEffect(() => {
    if (flats) {
      const activeFlat = flats.length > 0 ? flats[0] : '';
      if (activeFlat) {
        setActiveFlatId(activeFlat.flatId || '');
      }
    }
  }, [flats]);

  const handleOnChangeActiveFlatId = useCallback((value: string) => {
    setActiveFlatId(value);
  }, []);

  const tabs = useMemo(
    () =>
      flats?.map<ICustomTab>((flat) => ({
        tabId: flat.flatId || '',
        data: [
          { fieldTitle: 'Объект', fieldData: flat.name },
          { fieldTitle: 'Адрес', fieldData: flat.address },
        ],
        children: (
          <DeviceLogData
            flatId={flat.subscriberFlatId}
            devices={flat.devices}
            actionTypeList={actionTypeList || []}
            actionTypeListLoading={actionTypeListLoading}
          />
        ),
      })),
    [actionTypeList, actionTypeListLoading, flats]
  );

  return (
    <div className="device-log">
      {flatsLoading ? (
        <div className="device-log__loader-container">
          <Loader color={ELoaderColor.blue} />
        </div>
      ) : (
        <TabsCustom
          activeTabKey={activeFlatId}
          onChangeActiveTab={handleOnChangeActiveFlatId}
          tabPaneContainerClassName="device-log__tab-pane-container"
          tabPaneFieldTitleClassName="device-log__tab-pane-text"
          tabs={tabs}
        />
      )}
    </div>
  );
};

export default DeviceLog;
