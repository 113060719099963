import React, { FC } from 'react';

const CheckListIcon: FC = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="12.8333" y="5.5" width="25.6667" height="33" rx="2" stroke="#1444F2" strokeWidth="2" />
    <rect
      x="3.66675"
      y="25.6667"
      width="14.6667"
      height="14.6667"
      rx="2"
      fill="white"
      stroke="#1444F2"
      strokeWidth="2"
    />
    <path d="M7.33325 32.0833L10.9999 35.7499L14.6666 29.3333" stroke="#1444F2" strokeWidth="2" strokeLinecap="round" />
    <path
      d="M24.75 26.5833C24.75 26.5833 29.0622 26.5833 33 26.5833"
      stroke="#1444F2"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M18.3333 12.8333L32.9999 12.8333" stroke="#1444F2" strokeWidth="2" strokeLinecap="round" />
    <path d="M18.3333 20.1667L32.9999 20.1667" stroke="#1444F2" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default CheckListIcon;
