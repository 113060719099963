import { EDispatcherDeviceStatus } from '../../typings/dispatcherPanel';
import { ENotifyType } from '../../typings/notifications';

export const stackIdToIndex = new Map<string, number>([
  ['status-WithErrors', 0],
  ['status-WithWarning', 1],
  ['status-Lost', 2],
  ['reminder', 4],
]);

export interface INotificationStack {
  id: string;
  type: ENotifyType;
  status?: EDispatcherDeviceStatus | null;
  notifications: INotification[];
}

export interface INotification {
  id: string;
  status: EDispatcherDeviceStatus | null;
  title: string;
  text: string;
  deviceId: string;
  objectId: string;
  ignoredForUsers: string[];
  className?: string;
  isReminder: boolean;
  stackId: string;
  notificationId: string;
  eventId: string;
}
