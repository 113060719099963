import React from 'react';
import './styles/ant-design.less';
import 'overlayscrollbars/overlayscrollbars.css';
import './styles/index.scss';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import { store, history } from './store';
import Root from './pages/root';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Root />
      </Router>
    </Provider>
  );
}

export default App;
