import React, { FC } from 'react';

const Bell: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22.5C13.5011 22.5 14.8136 21.5471 15.3008 20.1667H17.1596C17.5677 20.1667 17.9713 20.081 18.3442 19.9153C19.8162 19.2611 20.4791 17.5374 19.8249 16.0654L19 14.2095V10.8333C19 7.78549 17.0522 5.1926 14.3334 4.23165V3.83333C14.3334 2.54467 13.2887 1.5 12 1.5C10.7114 1.5 9.66672 2.54467 9.66672 3.83333V4.23165C6.94793 5.1926 5.00005 7.78549 5.00005 10.8333V14.2095L4.17521 16.0654C4.00947 16.4384 3.92383 16.8419 3.92383 17.25C3.92383 18.8608 5.22966 20.1667 6.84049 20.1667H8.69923C9.18502 21.5435 10.4957 22.5 12 22.5ZM9.97852 20.167H14.022C13.6144 20.8723 12.8528 21.3337 12.0002 21.3337C11.1461 21.3337 10.3853 20.8709 9.97852 20.167ZM13.1673 3.93044V3.83366C13.1673 3.18933 12.645 2.66699 12.0007 2.66699C11.3563 2.66699 10.834 3.18933 10.834 3.83366V3.93044C11.2134 3.86679 11.6032 3.83366 12.0007 3.83366C12.3981 3.83366 12.7879 3.86679 13.1673 3.93044ZM6.16704 10.8333C6.16704 7.61167 8.77871 5 12.0004 5C15.222 5 17.8337 7.61167 17.8337 10.8333V14.3333L17.884 14.5702L18.7591 16.5393C19.1516 17.4225 18.7539 18.4566 17.8707 18.8492C17.6469 18.9486 17.4048 19 17.1599 19H6.84082C5.87432 19 5.09082 18.2165 5.09082 17.25C5.09082 17.0051 5.1422 16.763 5.24165 16.5393L6.11677 14.5702L6.16704 14.3333V10.8333Z"
      fill="#1444F2"
    />
  </svg>
);

export default Bell;
