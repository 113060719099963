export interface ISelectedNode {
  itemId: string;
  itemType: string;
  originalKey: string;
}

export enum EErrorType {
  needKeys = 'needKeys',
  needPlan = 'needPlan',
  needUpLvl = 'needUpLvl',
  default = 'default',
  search = 'search',
  needFlats = 'needFlats',
}
