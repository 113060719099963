import axios, { AxiosRequestConfig } from 'axios';
import AuthService from '../authService';

const axiosCalculateConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => ({
  ...config,
  headers: {
    'Accept-Language': 'ru-RU',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${AuthService.getToken()}`,
    ...config?.headers,
  },
  params: config?.params,
  paramsSerializer: config?.paramsSerializer,
});

export const axiosGet = async <T = any>(url: string, config?: AxiosRequestConfig) => {
  const composedConfig = axiosCalculateConfig(config);
  const response = await axios.get<T>(url, composedConfig);
  return response;
};

export const axiosPost = async (url: string, data?: any, config?: AxiosRequestConfig) => {
  const composedConfig = axiosCalculateConfig(config);
  const postParams = [data, composedConfig].filter((i) => typeof i !== 'undefined');
  const response = await axios.post(url, ...postParams);
  return response;
};

export const axiosDelete = async (url: string, config?: AxiosRequestConfig) => {
  const composedConfig = axiosCalculateConfig(config);
  const response = await axios.delete(url, composedConfig);
  return response;
};

export const axiosPatch = async (url: string, data?: any, config?: AxiosRequestConfig) => {
  const composedConfig = axiosCalculateConfig(config);
  const response = await axios.patch(url, data, composedConfig);
  return response;
};

export const axiosPut = async (url: string, data?: any, config?: AxiosRequestConfig) => {
  const composedConfig = axiosCalculateConfig(config);
  const response = await axios.put(url, data, composedConfig);
  return response;
};
