import React, { FC } from 'react';

const TrashLightIcon: FC = () => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.325 7.46875C16.325 7.46875 15.782 14.2037 15.467 17.0407C15.317 18.3957 14.48 19.1898 13.109 19.2148C10.5 19.2618 7.88803 19.2648 5.28003 19.2098C3.96103 19.1828 3.13803 18.3788 2.99103 17.0478C2.67403 14.1858 2.13403 7.46875 2.13403 7.46875"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M17.7082 4.24023H0.750244" stroke="#949494" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M14.4406 4.23998C13.6556 4.23998 12.9796 3.68498 12.8256 2.91598L12.5826 1.69998C12.4326 1.13898 11.9246 0.750977 11.3456 0.750977H7.11258C6.53358 0.750977 6.02558 1.13898 5.87558 1.69998L5.63258 2.91598C5.47858 3.68498 4.80258 4.23998 4.01758 4.23998"
      stroke="#949494"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashLightIcon;
