import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import { IDisablingPushForm } from './types';
import Select from '../../../ui/select';
import { defaultResponseParser, useApi } from '../../../../hooks/useApi';
import { getRequest, postRequest } from '../../../../api';
import { dispatcherPanelDevicesUrl, getObjectListUrl, notifyDisabledUrl } from '../../../../constants/api';
import { IOrganizationObject } from '../../../../typings/organization';
import { ISelectOption } from '../../../ui/select/types';
import { ENotificationsTypes } from '../types';
import SelectMultiple from '../../../ui/selectMultiple';
import { ISelectMultipleOption } from '../../../ui/selectMultiple/types';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import { IDispatcherPanelDeviceShort } from '../../../../typings/dispatcherPanel';
import { deviceStatusMap, notifyTypeMap, periodsMap } from '../config';
import Message from '../../../message';
import { selectAllOptionKey } from '../../../../constants/select';

const DisablingPushForm: FC<IDisablingPushForm> = ({ getData = () => {}, defaultDeviceId, defaultObjectId }) => {
  const { data: objects, sendRequest: getObjects, loading: objectsLoading } = useApi<IOrganizationObject[]>(getRequest);
  const {
    data: devicesList,
    sendRequest: getDevices,
    loading: getDevicesLoading,
  } = useApi<IDispatcherPanelDeviceShort[]>(getRequest);
  const { sendRequest: addNewPeriod, loading: addNewPeriodLoading } = useApi(postRequest, defaultResponseParser, false);

  const [objectId, setObjectId] = useState<string | null>();
  const [notifyType, setNotifyType] = useState<string | null>(ENotificationsTypes.all);
  const [notifyStatus, setNotifyStatus] = useState<string[]>([]);
  const [deviceIds, setDeviceIds] = useState<string[]>([]);
  const [period, setPeriod] = useState<string | null>('15');

  useEffect(() => {
    setDeviceIds(defaultDeviceId ? [defaultDeviceId] : []);
  }, [defaultDeviceId]);

  useEffect(() => {
    setObjectId(defaultObjectId || null);
  }, [defaultObjectId]);

  useEffect(() => {
    getObjects(getObjectListUrl(), {
      params: {
        isOnlyIpSeries: true,
      },
    });
  }, []);

  useEffect(() => {
    if (objectId)
      getDevices(dispatcherPanelDevicesUrl(), { params: { objectId: objectId === 'all' ? null : objectId } });
  }, [objectId]);

  const handleOnChangeSelect = useCallback((setter: (val: any) => void) => (val: any) => setter(val), []);

  const objectOptions = useMemo(
    () => [
      {
        value: 'all',
        title: 'Все',
      },
      ...(objects?.map<ISelectOption>((object) => ({
        value: object.id || '',
        title: object.objectName || '',
      })) || []),
    ],
    [objects]
  );

  const optionsInToMap = useCallback((map: Map<any, string>) => {
    const result: ISelectMultipleOption[] = [];
    map.forEach((item, key) =>
      result.push({
        value: key,
        title: item,
      })
    );
    return result;
  }, []);

  const notifyTypeOptions = useMemo(() => optionsInToMap(notifyTypeMap), [optionsInToMap]);

  const statusTypeOptions = useMemo(
    () => [{ value: selectAllOptionKey, title: 'Все' }, ...optionsInToMap(deviceStatusMap)],
    [optionsInToMap]
  );

  const periodOptions = useMemo(() => optionsInToMap(periodsMap), [optionsInToMap]);

  const devicesOptions = useMemo(
    () =>
      devicesList
        ? [
            { value: selectAllOptionKey, title: 'Все' },
            ...devicesList.map<ISelectMultipleOption>((object) => ({
              value: object.id || '',
              title: object.name || '',
            })),
          ]
        : [],
    [devicesList]
  );

  const onAdd = useCallback(async () => {
    const resError = (await addNewPeriod(notifyDisabledUrl(), {
      objectId: objectId === selectAllOptionKey ? null : objectId,
      type: notifyType,
      deviceIds:
        deviceIds[0] === selectAllOptionKey
          ? devicesList?.map((item) => ({ id: item.id }))
          : deviceIds.map((item) => ({ id: item })),
      deviceStatuses:
        deviceIds[0] === selectAllOptionKey
          ? optionsInToMap(deviceStatusMap)?.map((item) => item.value)
          : notifyType === ENotificationsTypes.notification
          ? []
          : notifyStatus,
      minutes: Number(period),
    })) as any;
    if (!resError?.response?.data) {
      getData();
      Message.success({
        content: 'Настройки push-уведомлений успешно применены',
      });
    } else {
      Message.error({
        content: resError?.response?.data?.message || 'Данная настройка является дублирующей',
      });
    }
  }, [addNewPeriod, deviceIds, devicesList, getData, notifyStatus, notifyType, objectId, optionsInToMap, period]);

  return (
    <div className="disabling-push-form">
      <Select
        title="Объект"
        value={objectId || ''}
        onChange={(e) => {
          setObjectId(e.toString());
          setDeviceIds([]);
        }}
        options={objectOptions}
        loading={objectsLoading}
        placeholder="Выберите объект"
      />
      <Select
        title="Тип уведомления"
        value={notifyType || ''}
        onChange={handleOnChangeSelect(setNotifyType)}
        options={notifyTypeOptions}
      />
      {!(notifyType === ENotificationsTypes.notification) && (
        <SelectMultiple
          title="Статус"
          values={notifyStatus}
          options={statusTypeOptions}
          onChange={handleOnChangeSelect(setNotifyStatus)}
          placeholder="Выберите статус"
        />
      )}

      <SelectMultiple
        title="Оборудование"
        values={deviceIds}
        options={devicesOptions}
        onChange={handleOnChangeSelect(setDeviceIds)}
        loading={getDevicesLoading}
        placeholder="Выберите оборудование"
      />
      <Select title="Период" value={period || ''} onChange={handleOnChangeSelect(setPeriod)} options={periodOptions} />
      <Button
        loading={addNewPeriodLoading}
        disabled={
          deviceIds.length === 0 || (!(notifyType === ENotificationsTypes.notification) && notifyStatus.length === 0)
        }
        type={ButtonType.outline}
        size={ButtonSize.small}
        onClick={onAdd}
      >
        Добавить
      </Button>
    </div>
  );
};

export default DisablingPushForm;
