import React, { FC } from 'react';

const PPOIconActive: FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_32381_166036)">
      <circle cx="12" cy="12.75" r="11.6" stroke="#1444F2" strokeWidth="0.8" />
      <g clipPath="url(#clip1_32381_166036)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9767 5.25C14.5216 6.86316 16.8207 7.62634 18.7939 7.44592C19.1385 14.4159 16.5645 18.5321 12.0031 20.25C7.59818 18.6421 4.99271 14.703 5.18595 7.34021C7.50224 7.46143 9.7747 6.96069 11.9767 5.25ZM10.2366 12.0253H10.4121V11.6187C10.4121 11.1683 10.5896 10.7579 10.8756 10.4597C11.1639 10.1592 11.5619 9.97253 12.0004 9.97253C12.4389 9.97253 12.8371 10.1592 13.1252 10.4597C13.4112 10.7579 13.5887 11.1683 13.5887 11.6187V12.0253H13.7642C13.9067 12.0253 14.0234 12.1418 14.0234 12.2844V15.0229C14.0234 15.1655 13.9067 15.2821 13.7642 15.2821H10.2366C10.094 15.2821 9.97746 15.1655 9.97746 15.0229V12.2844C9.97733 12.1418 10.094 12.0253 10.2366 12.0253ZM10.9029 12.0253H13.0978V11.6187C13.0978 11.2985 12.9732 11.0084 12.7724 10.799C12.5738 10.5918 12.3005 10.4633 12.0003 10.4633C11.7002 10.4633 11.4268 10.5919 11.2283 10.799C11.0274 11.0084 10.9029 11.2986 10.9029 11.6187V12.0253ZM11.7876 13.8292L11.508 14.5616H12.4926L12.2336 13.8192C12.398 13.7346 12.5104 13.5632 12.5104 13.3656C12.5104 13.0839 12.282 12.8555 12.0003 12.8555C11.7185 12.8555 11.4903 13.0839 11.4903 13.3656C11.4903 13.5713 11.6121 13.7485 11.7876 13.8292ZM11.9794 6.11121C14.2321 7.53906 16.2673 8.21472 18.0138 8.05493C18.3188 14.2245 16.0404 17.8682 12.003 19.3887C8.1038 17.9655 5.79728 14.4789 5.96842 7.9613C8.01884 8.06873 10.0303 7.62549 11.9794 6.11121Z"
          fill="#1444F2"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_32381_166036">
        <rect width="24" height="24" fill="white" transform="translate(0 0.75)" />
      </clipPath>
      <clipPath id="clip1_32381_166036">
        <rect width="13.6488" height="15" fill="white" transform="translate(5.17578 5.25)" />
      </clipPath>
    </defs>
  </svg>
);

export default PPOIconActive;
