import classNames from 'classnames';
import React, { FC } from 'react';
import Button from '../../ui/button';
import { ITabNavButtons } from './types';

const TabNavButtons: FC<ITabNavButtons> = (props) => {
  const { containerClassName = '', buttons = [] } = props;

  return (
    <div className={classNames('tab-nav-buttons', containerClassName)}>
      {buttons.map((button, index) => (
        <Button
          {...button}
          key={`tab-nav-buttons_${index}`}
          className={classNames(button.className, button.classNameOption)}
          onClick={button.callBack}
          disabled={button.disabled}
          hidden={button.isHidden}
        >
          {button.text}
        </Button>
      ))}
    </div>
  );
};

export default TabNavButtons;
