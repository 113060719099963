import { Dropdown, Menu, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { createSearchParams } from 'react-router-dom';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import SearchFailIcon from '../../assets/svg/icons/searchFail';
import { ELoaderColor } from '../ui/loader/types';
import Loader from '../ui/loader';
import { ISettingColumn, ISubscriptionsTable } from './types';
import { IApiSortField } from '../../typings/api';
import { paths } from '../../constants/paths';
import { ISubscription, subscriptionsStatusNamesMap } from '../../typings/subscriptions';
import SettingsIcon from '../../assets/svg/icons/settings';
import Checkbox from '../ui/checkbox';
import CloseIcon from '../../assets/svg/icons/close';
import Scrollbar from '../ui/scrollbar';
import { dateDefaultFormat } from '../../constants/date';
import { EPageQueryParams } from '../../typings/searchParams';

const SubscriptionsTable: FC<ISubscriptionsTable> = (props) => {
  const {
    path,
    pageId,
    tabId,
    redirectAbility = true,
    subscriptions = [],
    isAdmin = false,
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    showSetting = true,
    availableCols = [
      'companyName',
      'objectName',
      'address',
      'fio',
      'phone',
      'amount',
      'tariff',
      'subscriptionCreateDate',
      'subscriptionStatus',
    ],
  } = props;

  const navigate = useNavigate();

  const [isSettingsOpen, setSettingsOpen] = useState(false);

  const [settingsItems, setSettingsItems] = useState<ISettingColumn[]>();

  const getStatus = useCallback(
    (record: ISubscription) => (
      <div className={`subscriptions-table__status subscriptions-table__status_${record.subscriptionStatus}`}>
        {record.subscriptionStatus && subscriptionsStatusNamesMap.get(record.subscriptionStatus)}
      </div>
    ),
    []
  );

  const adminItems: ISettingColumn[] = [
    {
      getData: (record: ISubscription) => record.companyName,
      show: true,
      key: 'companyName',
      label: 'Организация',
      isSort: true,
      sortIndex: 1,
    },
  ];

  const defaultItems: ISettingColumn[] = [
    {
      getData: (record: ISubscription) => record.objectName,
      show: true,
      isSort: true,
      sortIndex: 2,
      key: 'objectName',
      label: 'Объект',
    },
    {
      getData: (record: ISubscription) => record.address,
      show: true,
      isSort: true,
      sortIndex: 2,
      key: 'address',
      label: 'Адрес',
    },
    {
      getData: (record: ISubscription) => record.fio,
      show: true,
      isSort: true,
      sortIndex: 3,
      key: 'fio',
      label: 'ФИО',
    },
    {
      getData: (record: ISubscription) => record.phone,
      show: true,
      isSort: false,
      key: 'phone',
      label: 'Телефон',
    },
    {
      getData: (record: ISubscription) => record.tariff,
      show: true,
      isSort: true,
      sortIndex: 4,
      key: 'tariff',
      label: 'Тариф',
    },
    {
      getData: (record: ISubscription) => record.amount?.toFixed(2),
      show: true,
      isSort: true,
      sortIndex: 5,
      key: 'amount',
      label: 'Сумма',
    },
    {
      getData: (record: ISubscription) =>
        record.subscriptionCreateDate ? moment(record.subscriptionCreateDate).format(dateDefaultFormat) : '-',
      show: true,
      isSort: true,
      sortIndex: 6,
      key: 'subscriptionCreateDate',
      label: 'Дата',
    },
    {
      getData: (record: ISubscription) => getStatus(record),
      show: true,
      isSort: true,
      sortIndex: 7,
      key: 'subscriptionStatus',
      label: 'Статус',
    },
  ];

  useEffect(() => {
    setSettingsItems(isAdmin ? [...adminItems, ...defaultItems] : defaultItems);
  }, [isAdmin]);

  const handleOnChangeTable: TableProps<ISubscription>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<ISubscription> | SorterResult<ISubscription>[],
      extra: TableCurrentDataSource<ISubscription>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<ISubscription>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof ISubscription,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof ISubscription,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<ISubscription> | null) => (flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null),
    []
  );

  const onRowClick = useCallback(
    (id: string) => {
      if (redirectAbility) {
        navigate({
          pathname: `${paths.mpManagementSubscriptions}/${id}`,
          search: `?${createSearchParams({
            [EPageQueryParams.prevPage]: path || '',
            [EPageQueryParams.prevPageId]: pageId || '',
            [EPageQueryParams.prevTabId]: tabId || '',
          })}`,
        });
      }
    },
    [navigate, pageId, path, redirectAbility, tabId]
  );

  const changeCheckBox = useCallback(
    (index: number) => (e: boolean) => {
      if (settingsItems) {
        const newSettings = [...settingsItems];
        newSettings[index].show = e;
        setSettingsItems(newSettings);
      }
    },
    [settingsItems]
  );

  const menuItems = useMemo<ItemType[]>(
    () =>
      settingsItems?.map((item, index) => ({
        label:
          index === 0 ? (
            <>
              {item.label}
              <span role="presentation" onClick={() => setSettingsOpen(false)} className="close-icon">
                <CloseIcon />
              </span>
            </>
          ) : (
            item.label
          ),
        key: item.key,
        icon: (
          <div>
            <Checkbox checked={item.show} onChange={changeCheckBox(index)} />
          </div>
        ),
      })) || [],
    [changeCheckBox, settingsItems]
  );

  const columns = useMemo<ColumnsType<ISubscription>>(() => {
    const cols: any[] = [];
    settingsItems
      ?.filter((item) => availableCols.includes(item.key))
      ?.forEach((setting) => {
        if (setting.show) {
          cols.push({
            title: setting.label,
            dataIndex: setting.key,
            key: setting.key,
            sorter: setting.isSort
              ? {
                  multiple: setting.sortIndex,
                }
              : null,
            sortOrder: setting.isSort
              ? (getColumnSortOrder(sortOrders.find((item) => item.field === setting.key) || null) as SortOrder)
              : null,
            render: (_: any, record: ISubscription) => (
              <div className="editable-table__data-container">{setting.getData(record)}</div>
            ),
          });
        }
      });
    return cols;
  }, [availableCols, getColumnSortOrder, settingsItems, sortOrders]);

  const data = useMemo(
    () =>
      subscriptions.map((item, index) => ({
        ...item,
        key: index,
      })),
    [subscriptions]
  );

  return (
    <div className="subscriptions-table editable-table">
      <div
        className={classNames('subscriptions-table__settings', {
          'subscriptions-table__settings_active': isSettingsOpen,
        })}
      >
        {showSetting && (
          <Dropdown visible={isSettingsOpen} overlay={<Menu className="subscriptions-table__menu" items={menuItems} />}>
            <span role="presentation" onClick={() => setSettingsOpen(!isSettingsOpen)}>
              <SettingsIcon />
            </span>
          </Dropdown>
        )}
      </div>
      <Scrollbar>
        <Table
          onRow={(record) => ({
            onClick: () => onRowClick(record.subscriptionId || ''),
          })}
          columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          onChange={handleOnChangeTable}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          scroll={{ x: '100vh' }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default SubscriptionsTable;
