import { ITariffCustomSettings, ITariffPeriod } from '../pages/management/tariffsCost/types';
import { ETariffVersions } from '../typings/management/tariff';

export const defaultTariffSetting: ITariffCustomSettings = {
  tariffs: [
    {
      additionalCompanyPrice: 0,
      version: ETariffVersions.lite,
    },
    {
      additionalCompanyPrice: 0,
      version: ETariffVersions.medium,
    },
    {
      additionalCompanyPrice: 0,
      version: ETariffVersions.comfort,
    },
  ],
  periods: [],
};

export const versionPositions = new Map<ETariffVersions, number>([
  [ETariffVersions.lite, 0],
  [ETariffVersions.medium, 1],
  [ETariffVersions.comfort, 2],
]);

export const defaultPeriod: ITariffPeriod = {
  discount: 0,
  monthCount: null,
  isNew: true,
  liteCost: 0,
  mediumCost: 0,
  comfortCost: 0,
  isMountError: false,
  isDiscountError: false,
};

export const months = [
  {
    value: 2,
    title: '2 месяца',
  },
  {
    value: 3,
    title: '3 месяца',
  },
  {
    value: 4,
    title: '4 месяца',
  },
  {
    value: 5,
    title: '5 месяцев',
  },
  {
    value: 6,
    title: '6 месяцев',
  },
];
