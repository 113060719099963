import React, { FC, useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { AxiosError } from 'axios';
import { dateDefaultFormat } from '../../../../../constants/date';
import SingleDatePicker from '../../../../ui/singleDatePicker';
import Button from '../../../../ui/button';
import { ButtonSize } from '../../../../ui/button/types';
import { useApi } from '../../../../../hooks/useApi';
import { deleteRequest } from '../../../../../api';
import { delDispatcherPanelNotificationUrl } from '../../../../../constants/api';
import { INotificationItem } from './types';

const DeviceNotificationsItem: FC<INotificationItem> = (props) => {
  const { item, updateList = () => {}, deviceId = '', permissions, isAdmin = false } = props;

  const { sendRequest: resolveMessage, loading: resolveMessageLoading } = useApi(deleteRequest);

  const [date, setDate] = useState<Moment | null>(null);

  const onResolve = useCallback(async () => {
    const resError = (await resolveMessage(delDispatcherPanelNotificationUrl(deviceId, item?.id || ''), {
      params: { resolveDate: date?.toISOString() },
    })) as AxiosError;
    if (!resError?.response?.data) {
      updateList();
    }
  }, [resolveMessage, deviceId, item?.id, date, updateList]);

  const isUnresolvedNotification = item?.withNotification && !item?.resolveDate;

  return (
    <>
      <div
        className={classNames('device-notifications-list__item', {
          'device-notifications-list__item_unresolved': isUnresolvedNotification,
        })}
      >
        <div className="device-notifications-list__item-title">
          <div className="device-notifications-list__item-create-date">
            {item?.date ? moment(item?.date || '').format(dateDefaultFormat) : ''}
          </div>
          <div className="device-notifications-list__item-type">
            {item?.withNotification ? 'Напоминание' : 'Комментарий'}
          </div>
        </div>
        <div className="device-notifications-list__item-text">{item?.text}</div>
        <div className="device-notifications-list__item-dates">
          {item?.withNotification && (
            <div className="device-notifications-list__item-dates-date">
              Создано: {moment(item?.createDate || '').format(dateDefaultFormat)}
            </div>
          )}
          {item?.withNotification && !isUnresolvedNotification && item?.resolveDate && (
            <div className="device-notifications-list__item-dates-date">
              Решено: {moment(item?.resolveDate || '').format(dateDefaultFormat)}
            </div>
          )}
        </div>

        {item?.withNotification && isUnresolvedNotification ? (
          isAdmin ? null : (
            <div className="device-notifications-list__item-date">
              <SingleDatePicker
                format={dateDefaultFormat}
                setTodayDateWithFirstClick
                containerClassName="device-notifications-list__item-date-picker"
                isDisabledStyle
                placeholder="Выберите время и дату"
                dateValue={date}
                onChange={setDate}
                disabled={!permissions?.edit}
              />
              <Button
                loading={resolveMessageLoading}
                size={ButtonSize.small}
                onClick={onResolve}
                disabled={!date || !permissions?.edit}
              >
                Решено
              </Button>
            </div>
          )
        ) : null}
      </div>
      <div className="device-notifications-list__line" />
    </>
  );
};

export default DeviceNotificationsItem;
