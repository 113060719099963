import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import { IFlatTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { deleteRequest, getRequest, putRequest } from '../../../api';
import {
  apartmentSubscriberUrl,
  getFlatsSubscribersUrl,
  subscriberValidateMainFlatDeleteUrl,
} from '../../../constants/api';
import { paths } from '../../../constants/paths';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import AddNodeIcon from '../../../assets/svg/icons/addNote';
import Scrollbar from '../../ui/scrollbar';
import TrashIcon from '../../../assets/svg/icons/trash';
import Radio from '../../ui/radio';
import { IFlatSubscriber } from '../../../typings/flat';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, deleteModal, deleteModalWithFullName, infoConfirmModal } from '../../ui/universalModal/config';
import { ButtonType } from '../../ui/button/types';
import { useAppSelector } from '../../../hooks/hooks';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { Status } from '../../../typings/status';
import { SubscriberErrorCodes } from '../../../typings/subscribers';

const FlatSubscribersTab: FC<IFlatTabComponent> = (props) => {
  const { flatId = '', tabId, activeTabKey, permissions } = props;

  const subscriberPermissions = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));

  const {
    data: subscribers,
    sendRequest: getSubscribers,
    loading: subscribersLoading,
  } = useApi<IFlatSubscriber[]>(getRequest);
  const { sendRequest: deleteSubscriber, loading: deleteSubscriberLoading } = useApi(deleteRequest);
  const { sendRequest: changeMainSubscriber, loading: changeMainSubscriberLoading } = useApi(putRequest);

  const navigate = useNavigate();

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const { sendRequest: validateDelete } = useApi(getRequest);

  const requestData = useCallback(() => {
    getSubscribers(getFlatsSubscribersUrl(flatId));
  }, [flatId, getSubscribers]);

  useEffect(() => {
    if (tabId === activeTabKey) {
      requestData();
    }
  }, [tabId, activeTabKey]);

  const onDeleteSubscriber = useCallback(
    async (id: string) => {
      closeConfirm();
      await deleteSubscriber(apartmentSubscriberUrl(flatId, id));
      requestData();
    },
    [closeConfirm, deleteSubscriber, flatId, requestData]
  );

  const tryToDelete = useCallback(
    (record: IFlatSubscriber) => async () => {
      if (record.isMain) {
        const resError = (await validateDelete(subscriberValidateMainFlatDeleteUrl(flatId), {
          params: { subscriberId: record?.id },
        })) as AxiosError;
        if (resError?.response?.data) {
          const { errorCodes, message } = resError.response.data as any;
          if (errorCodes === SubscriberErrorCodes.isMainFlatDeleteValidation) {
            setConfirmData(infoConfirmModal(message, closeConfirm));
          } else if (errorCodes === SubscriberErrorCodes.cancelPaidSubscription) {
            setConfirmData(
              deleteModalWithFullName(
                'Вы действительно хотите удалить главного абонента из квартиры? Текущая подписка будет отменена',
                () => onDeleteSubscriber(record.id),
                closeConfirm
              )
            );
          }
        } else {
          setConfirmData(
            deleteModalWithFullName(
              'Вы действительно хотите удалить главного абонента из квартиры?',
              () => onDeleteSubscriber(record.id),
              closeConfirm
            )
          );
        }
      } else {
        setConfirmData(deleteModal('этого абонента из квартиры', () => onDeleteSubscriber(record.id), closeConfirm));
      }
    },
    [closeConfirm, flatId, onDeleteSubscriber, validateDelete]
  );

  const handleOnChangeMainSubscriber = useCallback(
    (record: IFlatSubscriber) => (val: boolean) => {
      if (!record.isMain && val) {
        setConfirmData({
          isOpen: true,
          description: `Вы хотите назначить главным абонентом - ${record.lastName} ${record.firstName} ${record.middleName}? Автопродление подписки будет отменено`,
          buttons: [
            {
              label: 'Назначить',
              type: ButtonType.primary,
              onClick: async () => {
                closeConfirm();
                await changeMainSubscriber(apartmentSubscriberUrl(flatId, record.id));
                requestData();
              },
            },
            {
              label: 'Отмена',
              type: ButtonType.secondary,
              onClick: closeConfirm,
            },
          ],
        });
      }
    },
    [changeMainSubscriber, closeConfirm, flatId, requestData]
  );

  const columns = useMemo<ColumnsType<IFlatSubscriber>>(
    () =>
      [
        {
          title: 'ФИО',
          dataIndex: 'fio',
          key: 'fio',
          render: (_: any, record: IFlatSubscriber) => (
            <div className="editable-table__data-container">
              <div
                className={`flat-subscribers-tab__status flat-subscribers-tab__status_${
                  record.status === 'Active' ? 'active' : 'inactive'
                }`}
              />
              {subscriberPermissions?.view ? (
                <div
                  onClick={() => navigate(`${paths.subscribers}/${record.id}`)}
                  role="presentation"
                  className="flat-subscribers-tab__fio"
                >{`${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`}</div>
              ) : (
                `${record.lastName || ''} ${record.firstName || ''} ${record.middleName || ''}`
              )}
            </div>
          ),
        },
        {
          title: 'Номер телефона',
          dataIndex: 'phone',
          key: 'phone',
          render: (_: any, record: IFlatSubscriber) => (
            <div className="editable-table__data-container">{record.phone}</div>
          ),
        },
        {
          title: 'E-mail',
          dataIndex: 'E-mail',
          key: 'E-mail',
          render: (_: any, record: IFlatSubscriber) => (
            <div className="editable-table__data-container">{record.email}</div>
          ),
        },
        {
          title: 'Главный абонент',
          dataIndex: 'main',
          key: 'main',
          render: (_: any, record: IFlatSubscriber) => (
            <div className="editable-table__data-container">
              <Radio
                disabled={!permissions?.edit || record.status === Status.inactive}
                className="flat-subscribers-tab__radio"
                checked={record.isMain}
                onChange={handleOnChangeMainSubscriber(record)}
              />
            </div>
          ),
        },
        {
          hidden: !permissions?.edit,
          dataIndex: 'delete',
          key: 'delete',
          render: (_: any, record: IFlatSubscriber) =>
            !record.isMain || (record.isMain && subscribers?.length === 1) ? (
              <div className="editable-table__icon-container">
                <div onClick={tryToDelete(record)} role="presentation" className="editable-table__icon">
                  <TrashIcon />
                </div>
              </div>
            ) : null,
        },
      ].filter((item) => !item.hidden),
    [
      handleOnChangeMainSubscriber,
      navigate,
      permissions?.edit,
      subscriberPermissions?.view,
      subscribers?.length,
      tryToDelete,
    ]
  );

  return (
    <div className="flat-subscribers-tab editable-table">
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <Scrollbar>
        <Table
          loading={{
            spinning: subscribersLoading || deleteSubscriberLoading || changeMainSubscriberLoading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          scroll={{ x: '100vh' }}
          columns={columns}
          dataSource={subscribers || []}
          showSorterTooltip={false}
          pagination={false}
          locale={{
            emptyText: (
              <ErrorPlaceholder
                containerClassName="device-event-table__error-wrapper"
                text="В выбранную квартиру не назначены абоненты, добавьте их в разделе «Абоненты»"
                icon={<AddNodeIcon />}
              />
            ),
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default FlatSubscribersTab;
