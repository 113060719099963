import React, { FC, useCallback, useEffect } from 'react';
import { getHardwareRtspServers } from '../../../../../../api/hardwareSettings';
import { getDeviceRtspServers, getDeviceVideoServers } from '../../../../../../constants/api';
import { useApi } from '../../../../../../hooks/useApi';
import {
  IHardwareCameraSettings,
  IHardwareSettingsRtspServer,
  IHardwareSettingsVideoServer,
} from '../../../../../../typings/systems/hardwareSettings';
import { getRequest } from '../../../../../../api';
import CameraOptions from '../cameraOptions';
import { replacedOptionValues, setInvalidParams } from '../../../calculations';
import AdvancedCameraOptions from '../advancedCameraOptions';
import { ICameraSection } from './types';
import { ICameraOptionsData } from '../../../config';

const CameraSection: FC<ICameraSection> = (props) => {
  const {
    permissions = {},
    selectedNode,
    cameraSettings,
    handleOnSave = () => false,
    closeConfirm = () => {},
    requestData = () => {},
    getInvalidData = () => null,
    changeSettings = () => {},
    cameraSettingsCopy = null,
    setConfirmData = () => {},
    cameraOptions,
    setCameraOptions = () => {},
    isCameraOptionsChange = false,
    onChangeCameraOptions,
  } = props;

  const { data: rtspServers, sendRequest: requestRtspServers } =
    useApi<IHardwareSettingsRtspServer[]>(getHardwareRtspServers);
  const { data: videoServers, sendRequest: requestVideoServers } = useApi<IHardwareSettingsVideoServer[]>(getRequest);

  const parseCameraSettings = useCallback(
    (camSettings: IHardwareCameraSettings) => {
      const newCameraSettings = JSON.parse(JSON.stringify(camSettings));
      if (newCameraSettings) {
        requestRtspServers(getDeviceRtspServers());
        requestVideoServers(getDeviceVideoServers());

        const newCameraOptions = { ...cameraOptions } as ICameraOptionsData;
        replacedOptionValues(isCameraOptionsChange, newCameraSettings, newCameraOptions);
        const invalidData = getInvalidData(camSettings.id);
        if (invalidData) setInvalidParams(invalidData, newCameraOptions);
        setCameraOptions(newCameraOptions);
        changeSettings(newCameraSettings, false);
      }
    },
    [
      cameraOptions,
      changeSettings,
      getInvalidData,
      isCameraOptionsChange,
      requestRtspServers,
      requestVideoServers,
      setCameraOptions,
    ]
  );

  useEffect(() => {
    if (cameraSettings) parseCameraSettings(cameraSettings);
  }, [cameraSettings]);

  return (
    <>
      <CameraOptions
        videoServers={videoServers}
        rtspServers={rtspServers}
        settings={cameraSettingsCopy}
        options={cameraOptions}
        onChange={onChangeCameraOptions}
        permissions={permissions}
        selectedNode={selectedNode}
        handleOnSave={handleOnSave}
        closeConfirm={closeConfirm}
        setConfirmData={setConfirmData}
        requestData={requestData}
      />
      {cameraSettingsCopy?.parameters && !!cameraSettingsCopy?.parameters?.length && (
        <AdvancedCameraOptions
          permissions={permissions}
          settings={cameraSettingsCopy}
          changeSettings={changeSettings}
        />
      )}
    </>
  );
};

export default CameraSection;
