import React from 'react';

export enum AlertType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export interface IAlertBlock {
  content?: React.ReactNode | string;
  wrapperClassName?: string;
  alertClassName?: string;
  type?: AlertType;
}
