import React, { FC } from 'react';

const SuccessIcon: FC = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M40.9998 20.9998C40.9998 9.97222 32.0285 1 21 1C9.97157 1 1.00024 9.97222 1.00024 20.9998C1.00024 32.0273 9.97157 40.9996 21 40.9996C32.0285 40.9996 40.9998 32.0273 40.9998 20.9998ZM21 8.20617C28.0546 8.20617 33.7936 13.9455 33.7936 20.9998C33.7936 28.0541 28.0543 33.7934 21 33.7934C13.9457 33.7934 8.20641 28.0541 8.20641 20.9998C8.20641 13.9455 13.9457 8.20617 21 8.20617ZM21.5002 39.9871V34.7813C28.7085 34.5225 34.5223 28.7085 34.7813 21.5002H39.9874C39.7247 31.5787 31.5789 39.725 21.5001 39.9866L21.5002 39.9871ZM39.9866 20.4998H34.7808C34.522 13.2915 28.708 7.47761 21.4997 7.21868V2.01294C31.5782 2.27521 39.7245 10.4211 39.9861 20.4993L39.9866 20.4998ZM20.4993 2.01338V7.21913C13.291 7.47791 7.4771 13.2919 7.21818 20.5002H2.01243C2.2747 10.4218 10.4206 2.27543 20.4988 2.01383L20.4993 2.01338ZM2.01288 21.5007H7.21862C7.47741 28.709 13.2914 34.5228 20.4997 34.7818V39.9879C10.4213 39.7253 2.27493 31.5794 2.01332 21.5006L2.01288 21.5007Z"
      fill="#47C80A"
      stroke="#47C80A"
    />
    <path
      d="M19.3545 27.4109C19.4242 27.4806 19.5142 27.5267 19.6101 27.5455C19.645 27.5528 19.6806 27.5566 19.7162 27.5566C19.8445 27.5566 19.9722 27.5078 20.0698 27.4102L28.9547 18.5256C29.15 18.3303 29.15 18.014 28.9547 17.8187L25.722 14.5859C25.6282 14.4921 25.5012 14.4395 25.3683 14.4395C25.2358 14.4395 25.1085 14.4921 25.0147 14.5859L19.7116 19.889L16.9842 17.1616C16.7889 16.9663 16.4726 16.9663 16.2773 17.1616L13.0446 20.394C12.9507 20.4878 12.8981 20.6148 12.8981 20.7476C12.8981 20.8805 12.9507 21.0075 13.0446 21.1013L19.3545 27.4109ZM16.6306 18.2226L19.358 20.95C19.5533 21.1453 19.8696 21.1453 20.0649 20.95L25.3686 15.6469L27.894 18.1727L19.7115 26.3548L14.1051 20.7476L16.6306 18.2226Z"
      fill="#47C80A"
      stroke="#47C80A"
    />
  </svg>
);

export default SuccessIcon;
