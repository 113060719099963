import React, { FC } from 'react';

const ShapeRectangleR: FC = () => (
  <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 1H55C56.1046 1 57 1.89543 57 3V55C57 56.1046 56.1046 57 55 57H38C36.8954 57 36 56.1046 36 55V27C36 25.8954 35.1046 25 34 25H18.5H3C1.89543 25 1 24.1046 1 23V3C1 1.89543 1.89543 1 3 1Z"
      stroke="#1444F2"
    />
  </svg>
);

export default ShapeRectangleR;
