import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Paper from 'paper';
import { ISchemaOpacity, IVisualizerMode } from '../../../../systemTabs/schemaTab/types';
import { TPaperCircle, TPaperEvent, TPaperGroup, TPaperLayer, TPaperPath } from '../../../types';
import { getGroupCopy, getLayer } from '../../paperItems/items';
import { ECursors } from '../../paperItems/types';
import { TreeNodeType } from '../../../../../typings/treeNode';

export class BaseCreator {
  protected points: TPaperCircle[] = [];

  protected activePoint: TPaperCircle | null = null;

  protected pseudoObject: TPaperPath | null = null;

  protected currentPath: TPaperPath | null = null;

  protected copyActiveGroup: TPaperGroup | null = null;

  protected mode: IVisualizerMode;

  protected group: TPaperGroup;

  protected setCursor: (cursor: ECursors) => void;

  protected setPath: (path: TPaperPath, wasOpen?: boolean) => void;

  protected setMenuItems: React.Dispatch<React.SetStateAction<ItemType[]>>;

  protected wasClosed = false;

  protected layer: TPaperLayer;

  protected canBeCreated = false;

  protected opacityValues: ISchemaOpacity;

  constructor(
    group: TPaperGroup,
    mode: IVisualizerMode,
    opacity: ISchemaOpacity,
    setCursor: (cursor: ECursors) => void,
    setPath: (path: TPaperPath, wasOpen?: boolean) => void,
    setMenuItems: React.Dispatch<React.SetStateAction<ItemType[]>>
  ) {
    this.opacityValues = opacity;
    this.group = group;
    this.mode = mode;
    this.setCursor = setCursor;
    this.setPath = setPath;
    this.setMenuItems = setMenuItems;
    this.layer = getLayer();
  }

  public start = () => {};

  public mouseMove = (event: TPaperEvent) => {};

  protected setCopyActiveGroup = (group = this.group, onClick: (e: TPaperEvent) => void = () => {}) => {
    this.copyActiveGroup = getGroupCopy(group, this.opacityValues, onClick, this.setCursor);
    this.layer.addChild(this.copyActiveGroup);
  };

  public resetProject = () => {
    this.layer.remove();
  };

  public scaleActiveGroup = () => {
    if (this.group.data.key === TreeNodeType.object && this.copyActiveGroup) {
      this.copyActiveGroup.children[0].bounds = Paper.project.view.bounds.clone();
    }
  };
}
