export const paths = {
  default: '/',
  page404: '/404',
  systems: '/systems',
  systemsNew: '/systems/new',
  subscribers: '/subscribers',
  flats: '/flats',
  subscribersNew: '/subscribers/new',
  organizations: '/organizations',
  deviceEvent: '/device-event',
  roles: '/roles',
  users: '/users',
  helpFaq: '/help/faq',
  guideCategory: '/guide/category',
  helpInstructions: '/help/instructions',
  mpManagementTariffs: '/management/tariffs',
  mpManagementTariffsCost: '/management/cost-tariffs',
  mpManagementPayments: '/management/payments',
  mpManagementFAQ: '/management/faq',
  mpManagementSubscriptions: '/management/subscriptions',
  organizationInfo: '/organization/info',
  dispatcherPanel: '/dispatcher-panel',
  mailing: '/mailing',
  mailingEmail: '/mailing/email',
  mailingSms: '/mailing/sms',
  mailingPush: '/mailing/push',
  feedback: '/feedback',
};
