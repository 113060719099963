import { Pagination } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import { IDeviceLog } from '../../../../typings/subscribers/deviceLog';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import { IDeviceLogList } from './types';
import { dateDefaultFormat } from '../../../../constants/date';

const DeviceLogList: FC<IDeviceLogList> = (props) => {
  const { logs = [], loading = false, pagination } = props;

  if (loading) {
    return (
      <div className="device-log-list">
        <div className="device-log-list__loader-container">
          <Loader color={ELoaderColor.blue} />
        </div>
      </div>
    );
  }

  return (
    <div className="device-log-list">
      {logs && logs.length > 0 ? (
        <div className="device-log-list__item-container">
          {logs.map((log: IDeviceLog) => (
            <div key={`device-log-list-item-${log.id}`} className="device-log-list__item">
              <span className="device-log-list__item-name">{log.deviceName}</span>
              <span className="device-log-list__item-date">{moment(log.date || '').format(dateDefaultFormat)}</span>
              <div className="device-log-list__item-action">
                <span>{log.typeDisplayName}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <ErrorPlaceholder
          containerClassName="device-log-list__error"
          text="По вашему запросу ничего не найдено"
          icon={<SearchFailIcon />}
        />
      )}

      {pagination && (
        <Pagination
          current={pagination.currentPage + 1}
          pageSize={pagination.pageSize}
          hideOnSinglePage
          total={pagination.total}
          onChange={pagination.onChangePage}
          showSizeChanger={false}
          showQuickJumper={false}
          prevIcon={<ChevronLeft />}
          nextIcon={<ChevronRight />}
          className="device-log-list__pagination table__pagination table__pagination_absolute"
        />
      )}
    </div>
  );
};

export default DeviceLogList;
