import React, { FC, useCallback, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastOptions, toast } from 'react-toastify';
import { Moment } from 'moment';
import { IRemindStackComponent } from './types';
import { SingleNotificationComponent } from '../singleNotification';
import SingleDatePicker from '../../ui/singleDatePicker';
import { dateDefaultFormat } from '../../../constants/date';
import Button from '../../ui/button';
import { ButtonSize } from '../../ui/button/types';
import NotifyFillIcon from '../../../assets/svg/icons/notifyFill';
import { useApi } from '../../../hooks/useApi';
import { deleteRequest } from '../../../api';
import { delDispatcherPanelNotificationUrl } from '../../../constants/api';

export const ReminderNotificationComponent: FC<IRemindStackComponent> = (props) => {
  const { data, onClickNotify = () => {}, onCloseNotify = () => {}, showClose = true } = props;

  const [date, setDate] = useState<Moment | null>(null);

  const { sendRequest: resolveMessage } = useApi(deleteRequest);

  const onResolve = useCallback(async () => {
    if (data) {
      await resolveMessage(delDispatcherPanelNotificationUrl(data.deviceId, data.notificationId), {
        params: { resolveDate: date?.toISOString() },
      });
      onCloseNotify(data.stackId, data.id, false);
    }
  }, [data, date, onCloseNotify, resolveMessage]);

  return (
    <SingleNotificationComponent
      showClose={showClose}
      data={data}
      onClickNotify={onClickNotify}
      onCloseNotify={onCloseNotify}
    >
      <div className="notify__reminder-icon">
        <NotifyFillIcon />
      </div>
      <div className="notify__reminder-date" role="presentation" onClick={(e) => e.stopPropagation()}>
        <SingleDatePicker
          format={dateDefaultFormat}
          setTodayDateWithFirstClick
          containerClassName="notify__reminder-date-picker"
          isDisabledStyle
          placeholder="Выберите дату"
          dateValue={date}
          onChange={setDate}
        />
        <Button size={ButtonSize.small} onClick={onResolve} disabled={!date}>
          Решено
        </Button>
      </div>
    </SingleNotificationComponent>
  );
};

export const renderReminderNotification = (componentProps: IRemindStackComponent, options: ToastOptions = {}) =>
  toast(() => <ReminderNotificationComponent {...componentProps} />, {
    ...options,
    toastId: options.toastId || componentProps?.data?.id,
    autoClose: false,
    onClose: () => {},
    className: `notify__reminder notify__message ${options.className || ''}`,
  });

export const updateReminderNotification = (
  id: string,
  componentProps: IRemindStackComponent,
  options: ToastOptions = {}
) =>
  toast.update(id, {
    ...options,
    toastId: options.toastId || componentProps?.data?.id,
    autoClose: false,
    className: `notify__reminder notify__message ${options.className || ''}`,
    render: () => <ReminderNotificationComponent {...componentProps} />,
  });
