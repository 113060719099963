import { AxiosRequestConfig } from 'axios';
import { axiosGet, axiosPost } from '../axios';

export const getHelpEntity = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const addHelpEntity = async <T>(url: string, data: T, config?: AxiosRequestConfig) => {
  const result = await axiosPost(url, data, {
    ...config,
  });
  return result.data;
};

export const getHelpFile = async (url: string, config?: AxiosRequestConfig) => {
  const result = await axiosGet(url, {
    ...config,
    responseType: 'blob',
  });
  return result;
};
