import classNames from 'classnames';
import React, { FC, useCallback, useEffect } from 'react';
import { getHelpFile } from '../../../api/help';
import DownloadIcon from '../../../assets/svg/icons/download';
import { useApi } from '../../../hooks/useApi';
import Button from '../button';
import { ButtonSize, ButtonType } from '../button/types';
import { IButtonDownload } from './types';

const ButtonDownload: FC<IButtonDownload> = (props) => {
  const {
    downloadUrl = '',
    size = ButtonSize.small,
    type = ButtonType.outline,
    onClick = () => {},
    className = '',
    children,
    loading = false,
    ...buttonProps
  } = props;

  const { data, sendRequest, loading: requestLoading } = useApi(getHelpFile);

  useEffect(() => {
    if (data) {
      const filename = data.headers['content-disposition']
        .split(';')
        // eslint-disable-next-line
        .find((n) => n.includes(`filename*=UTF-8''`))
        // eslint-disable-next-line
        ?.replace(`filename*=UTF-8''`, '')
        .trim();

      if (filename) {
        const href = URL.createObjectURL(data.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', decodeURIComponent(filename));
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    }
  }, [data]);

  const handleOnClick = useCallback(async () => {
    await sendRequest(downloadUrl);
    onClick();
  }, [downloadUrl, onClick, sendRequest]);

  return (
    <Button
      loading={loading || requestLoading}
      size={size}
      type={type}
      className={classNames(className, 'button-download__button')}
      rightIcon={<DownloadIcon />}
      onClick={handleOnClick}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default ButtonDownload;
