import { RadioChangeEvent } from 'antd';
import React from 'react';

export interface IRadioGroupOption {
  title: string;
  value: any;
}

export enum ERadioGroupDirection {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface IRadioGroup {
  title?: string | React.ReactNode;
  textInfo?: string | React.ReactNode;
  isRequired?: boolean;
  value?: any;
  options?: IRadioGroupOption[];
  disabled?: boolean;
  onChange?: (value: any, event: RadioChangeEvent) => void;
  containerClassName?: string;
  itemClassName?: string;
  titleClassName?: string;
  direction?: ERadioGroupDirection;
}
