import { IAccessGroupsApiSettings } from './types';

export const ACCESS_GROUPS_TABLE_COUNT = 10;

export const ACCESS_GROUPS_TREE_COUNT = 25;

export const accessGroupInitApiSettings: IAccessGroupsApiSettings = {
  page: 0,
  count: ACCESS_GROUPS_TABLE_COUNT,
  search: '',
  type: '',
  hideAccessPoints: false,
  hideCameras: false,
};
