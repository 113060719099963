import React, { FC } from 'react';

const EltisLogo: FC = () => (
  <svg width="116" height="33" viewBox="0 0 116 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7861 26.4477H7.70064L9.38313 18.9145H22.6489L23.7059 13.785H10.4401L12.0363 6.55231H26.1002L27.265 1.01509H6.62212L0 31.9849H20.6429L21.7861 26.4477Z" />
    <path d="M47.6687 26.3404H33.8637L39.2994 1.01509H32.742L26.1199 31.9849H46.5039L47.6687 26.3404Z" />
    <path d="M60.5659 31.9849L66.0233 6.55231H75.3417L76.4849 1.01509H51.2907L50.1474 6.55231H59.4658L54.0085 31.9849H60.5659Z" />
    <path d="M80.9481 31.9849L87.5702 1.01509H81.0128L74.3907 31.9849H80.9481Z" />
    <path d="M87.8271 22.8635C87.9566 28.9373 93.1119 32.5 100.554 32.5C108.341 32.5 113.065 28.1432 113.065 22.3699C113.065 18.1419 110.541 15.7166 105.148 14.107L102.409 13.27C99.281 12.3471 98.0084 11.2096 98.0084 9.4497C98.0084 7.47519 99.9066 5.75822 103.336 5.75822C106.809 5.75822 108.988 7.45372 109.096 9.92186H115.2C115.178 4.04125 110.433 0.5 103.25 0.5C96.3043 0.5 91.3647 4.38464 91.3647 10.2223C91.3647 14.4504 93.9963 17.0902 99.1732 18.6784L101.848 19.5154C105.234 20.5456 106.356 21.5543 106.356 23.4215C106.356 25.8038 103.983 27.2203 100.662 27.2203C97.0161 27.2203 94.4493 25.4175 94.1041 22.8635H87.8271Z" />
  </svg>
);

export default EltisLogo;
