import React, { FC } from 'react';

const OneWayOutputIcon: FC = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33366 0.999959V8.19522L3.23603 6.09759C3.10585 5.96741 2.8948 5.96741 2.76462 6.09759C2.63445 6.22776 2.63445 6.43882 2.76462 6.56899L5.43129 9.23566C5.56146 9.36584 5.77252 9.36584 5.90269 9.23566L8.56936 6.56899C8.69954 6.43882 8.69954 6.22776 8.56936 6.09759C8.43919 5.96741 8.22813 5.96741 8.09796 6.09759L6.00033 8.19522V0.999959C6.00033 0.815864 5.85109 0.666626 5.66699 0.666626C5.4829 0.666626 5.33366 0.815864 5.33366 0.999959ZM0.666992 11C0.666992 11.1841 0.816231 11.3333 1.00033 11.3333H10.3337C10.5178 11.3333 10.667 11.1841 10.667 11C10.667 10.8159 10.5178 10.6666 10.3337 10.6666H1.00033C0.816231 10.6666 0.666992 10.8159 0.666992 11Z"
      fill="black"
    />
  </svg>
);

export default OneWayOutputIcon;
