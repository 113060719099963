import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { IContactsModal } from './types';
import Modal from '../../../ui/modal';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { useApi } from '../../../../hooks/useApi';
import { postRequest } from '../../../../api';
import { dispatcherPanelCommunicationMethodUrl } from '../../../../constants/api';
import { EFieldTypes } from '../../../ui/input/types';

const ContactsModal: FC<IContactsModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, deviceId = '', onOk = () => {} } = props;

  const { sendRequest: updateContact, loading: updateLoading } = useApi(postRequest);

  const [contacts, setContacts] = useState('');

  useEffect(() => {
    if (isOpen) {
      setContacts('');
    }
  }, [isOpen]);

  const onAccess = useCallback(async () => {
    const resError = (await updateContact(dispatcherPanelCommunicationMethodUrl(deviceId), {
      communicationMethod: contacts,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    }
  }, [updateContact, deviceId, contacts, onOk]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Редактирование способа связи"
      width={410}
      showCloseIcon
      wrapClassName="add-message-modal__wrapper"
      centered
      footer={
        <div className="add-message-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancel} disabled={updateLoading}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={onAccess} disabled={updateLoading || !contacts || !contacts.trim()}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Input
        title="Описание способа связи"
        value={contacts}
        onChange={setContacts}
        autoSize={{ minRows: 4, maxRows: 4 }}
        maxLength={300}
        fieldType={EFieldTypes.textArea}
      />
    </Modal>
  );
};

export default ContactsModal;
