import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { IApiExtendSetting } from '../../typings/api';
import { ISubscriber } from '../../typings/subscribers';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../axios';

export const getSubscribers = async <T>(
  url: string,
  settings: IApiExtendSetting<T>,
  config?: AxiosRequestConfig
): Promise<T> => {
  const result = await axiosPost(url, settings, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const addSubscriber = async (url: string, data: ISubscriber, config?: AxiosRequestConfig) => {
  const result = await axiosPost(url, data, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const editSubscriber = async (url: string, data: ISubscriber, config?: AxiosRequestConfig) => {
  const result = await axiosPut(url, data, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const deleteSubscriber = async (url: string) => {
  const result = await axiosDelete(url);
  return result.data;
};

export const getSubscriber = async <T>(url: string): Promise<T> => {
  const result = await axiosGet(url);
  return result.data;
};

export const getEntitiesForSubscriber = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params, ...(config?.params?.sort ? { sort: JSON.stringify(config.params.sort) } : {}) },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return result.data;
};

export const addSubscriberEntity = async <T>(url: string, data: T, config?: AxiosRequestConfig) => {
  const result = await axiosPost(url, data, {
    ...config,
  });
  return result.data;
};
