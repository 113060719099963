import FlatBaseTab from '../../../components/flatTabs/baseTab';
import FlatJournalTab from '../../../components/flatTabs/journal';
import FlatKeysTab from '../../../components/flatTabs/keysTab';
import FlatPaymentsTab from '../../../components/flatTabs/payments';
import FlatSubscribersTab from '../../../components/flatTabs/subscribers';
import FlatSubscriptionsTab from '../../../components/flatTabs/subscriptions';
import { EFlatTabsIds } from './types';

export const flatsTabs = [
  {
    id: EFlatTabsIds.basic,
    position: 0,
    title: 'Основное',
    component: FlatBaseTab,
  },
  {
    id: EFlatTabsIds.subscribers,
    position: 0,
    title: 'Абоненты',
    component: FlatSubscribersTab,
  },
  {
    id: EFlatTabsIds.keys,
    position: 0,
    title: 'Ключи',
    component: FlatKeysTab,
  },
  {
    id: EFlatTabsIds.journal,
    position: 0,
    title: 'Журнал',
    component: FlatJournalTab,
  },
  {
    id: EFlatTabsIds.subscriptions,
    position: 0,
    title: 'Подписки',
    component: FlatSubscriptionsTab,
  },
  {
    id: EFlatTabsIds.payments,
    position: 0,
    title: 'Платежи',
    component: FlatPaymentsTab,
  },
];
