import { RootState } from '..';
import { ESidebarItemIds } from '../../typings/sidebar';
import { profileNameToShortString } from '../../utils/profile';

export const getProfileInStore = (state: RootState) => state.profile.profile;

export const getSettingsInStore = (state: RootState) => state.profile.settings;

export const checkIsAdmin = (state: RootState) => {
  const { roles } = state.profile.profile;

  if (!roles) return null;

  const res = roles?.filter((role) => role.name?.includes('Eltis-admin:'));

  return !!res?.length;
};

export const getProfilePermission = (permissionId: ESidebarItemIds) => (state: RootState) =>
  state.profile.profile.permissions?.find((item) => item.name === permissionId);

export const getProfileShortNameString = (state: RootState) =>
  profileNameToShortString({
    lastName: state.profile.profile.lastName,
    firstName: state.profile.profile.firstName,
    middleName: state.profile.profile.middleName,
  });
