import { IProfilePermission } from '../typings/profile';
import { ESidebarItemIds, ISidebarItem } from '../typings/sidebar';

export const openSidebarItems = (items: ISidebarItem[], categoryId: ESidebarItemIds, isOpen: boolean): boolean => {
  for (const item of items) {
    if (item.id === categoryId) {
      item.isOpen = isOpen;
      return true;
    }

    if (item.categoryItems && item.categoryItems.length > 0) {
      const isOpenChild = openSidebarItems(item.categoryItems, categoryId, isOpen);
      if (isOpenChild) {
        item.isOpen = true;
        return true;
      }
    }
  }

  return false;
};

export const findSidebarItemById = (items: ISidebarItem[], id: ESidebarItemIds): ISidebarItem | null => {
  let result: ISidebarItem | null = null;

  for (const item of items) {
    if (item.id === id) {
      result = item;
      break;
    } else if (item.categoryItems && item.categoryItems.length > 0) {
      const findResult = findSidebarItemById(item.categoryItems, id);
      if (findResult) {
        result = findResult;
        break;
      }
    }
  }

  return result;
};

export const findSelectedSidebarItemIdByPathname = (
  items: ISidebarItem[],
  locationPathname: string
): ESidebarItemIds => {
  let resultId = ESidebarItemIds.none;

  for (const item of items) {
    if (item.categoryItems && item.categoryItems.length > 0) {
      const findResult = findSelectedSidebarItemIdByPathname(item.categoryItems, locationPathname);
      if (findResult !== ESidebarItemIds.none) {
        resultId = findResult;
        break;
      }
    } else if (item.link) {
      if (locationPathname.includes(item.link)) {
        resultId = item.id;
        break;
      }
    }
  }

  return resultId;
};

export const filterEnableSidebarItemsByProfilePermissions = (
  items: ISidebarItem[],
  permissions: IProfilePermission[]
): ISidebarItem[] => {
  const result: ISidebarItem[] = [];

  items.forEach((item) => {
    const profilePermission = permissions?.find((permission) => permission.name === item.id);
    if (profilePermission && profilePermission.view) {
      result.push(item);
    } else if (item.categoryItems && item.categoryItems.length > 0) {
      const categoryItems = filterEnableSidebarItemsByProfilePermissions(item.categoryItems, permissions);
      if (categoryItems.length) {
        result.push({ ...item, categoryItems });
      }
    }
  });

  return result;
};
