import React, { FC } from 'react';

const CustomersIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8503 14.5288C25.7103 14.2675 27.1423 12.6728 27.1463 10.7408C27.1463 8.83681 25.7583 7.25814 23.9383 6.95947"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.3047 19.0005C28.106 19.2698 29.3634 19.9005 29.3634 21.2005C29.3634 22.0952 28.7714 22.6765 27.814 23.0418"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8489 19.5518C11.5636 19.5518 7.90356 20.2011 7.90356 22.7944C7.90356 25.3864 11.5409 26.0544 15.8489 26.0544C20.1342 26.0544 23.7929 25.4118 23.7929 22.8171C23.7929 20.2224 20.1569 19.5518 15.8489 19.5518Z"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8489 15.8505C18.6609 15.8505 20.9409 13.5718 20.9409 10.7585C20.9409 7.9465 18.6609 5.6665 15.8489 5.6665C13.0369 5.6665 10.7569 7.9465 10.7569 10.7585C10.7462 13.5612 13.0089 15.8412 15.8115 15.8505H15.8489Z"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.84654 14.5288C5.9852 14.2675 4.55454 12.6728 4.55054 10.7408C4.55054 8.83681 5.93854 7.25814 7.75854 6.95947"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.39192 19.0005C3.59059 19.2698 2.33325 19.9005 2.33325 21.2005C2.33325 22.0952 2.92525 22.6765 3.88259 23.0418"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CustomersIcon;
