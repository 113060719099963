import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';
import SearchFailIcon from '../../assets/svg/icons/searchFail';
import { IDeviceEvent } from '../../typings/deviceEvent';
import ErrorPlaceholder from '../ui/errorPlaceholder';
import Loader from '../ui/loader';
import { ELoaderColor } from '../ui/loader/types';
import { IDeviceEventTable } from './types';
import { dateDefaultFormat } from '../../constants/date';

const DeviceEventTable: FC<IDeviceEventTable> = (props) => {
  const { events = [], pagination = null, loading = false } = props;

  const columns: ColumnsType<IDeviceEvent> = [
    {
      width: '15%',
      title: 'Объект',
      dataIndex: 'intercomComplexName',
      key: 'intercomComplexName',
      render: (_, record: IDeviceEvent) => (
        <span className="device-event-table__text device-event-table__text_bold">{record.intercomComplexName}</span>
      ),
    },
    {
      width: '15%',
      title: 'Точка доступа',
      dataIndex: 'accessPointName',
      key: 'accessPointName',
      render: (_, record: IDeviceEvent) => <span className="device-event-table__text">{record.accessPointName}</span>,
    },
    {
      width: '15%',
      title: 'Оборудование',
      dataIndex: 'deviceName',
      key: 'deviceName',
      render: (_, record: IDeviceEvent) => <span className="device-event-table__text">{record.deviceName}</span>,
    },
    {
      width: '15%',
      title: 'Дата',
      dataIndex: 'eventDate',
      key: 'eventDate',
      render: (_, record: IDeviceEvent) => (
        <span className="device-event-table__text">{moment(record.eventDate || '').format(dateDefaultFormat)}</span>
      ),
    },
    {
      width: '15%',
      title: 'Сообщение',
      dataIndex: 'message',
      key: 'message',
      render: (_, record: IDeviceEvent) => <span className="device-event-table__text">{record.message}</span>,
    },
    {
      width: '15%',
      title: 'Информация',
      dataIndex: 'additionalInfo',
      key: 'additionalInfo',
      render: (_, record: IDeviceEvent) => <span className="device-event-table__text">{record.additionalInfo}</span>,
    },
    {
      width: '10%',
      title: 'Код',
      dataIndex: 'eventCode',
      key: 'eventCode',
      render: (_, record: IDeviceEvent) => <span className="device-event-table__text">{record.eventCode}</span>,
    },
  ];

  const data = useMemo(
    () =>
      events.map((event) => ({
        ...event,
        key: event.id,
      })),
    [events]
  );

  return (
    <div
      className={classNames('device-event-table table', {
        'table__empty-body-transparent': !data || !(data.length > 0),
      })}
    >
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        locale={{
          emptyText: (
            <ErrorPlaceholder
              containerClassName="device-event-table__error-wrapper"
              text="По вашему запросу ничего не найдено"
              icon={<SearchFailIcon />}
            />
          ),
        }}
        pagination={
          pagination
            ? {
                current: pagination.currentPage + 1,
                pageSize: pagination.pageSize,
                hideOnSinglePage: true,
                total: pagination.total,
                onChange: pagination.onChangePage,
                showSizeChanger: false,
                showQuickJumper: false,
                prevIcon: <ChevronLeft />,
                nextIcon: <ChevronRight />,
                className: 'table__pagination table__pagination_absolute',
              }
            : false
        }
      />
    </div>
  );
};

export default DeviceEventTable;
