import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import CommonHead from '../../../components/commonHead';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import ButtonLink from '../../../components/ui/buttonLink';
import {
  mailingCancelUrl,
  mailingDeleteRecipientsUrl,
  mailingDeleteRecipientUrl,
  mailingItemRecipientsUrl,
  mailingItemUrl,
  mailingSmsInfoUrl,
  mailingUrl,
} from '../../../constants/api';
import { paths } from '../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { getProfilePermission } from '../../../store/selectors/profile';
import { setHeaderTitle } from '../../../store/slices/header';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { defaultMailingItem } from './config';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import Button from '../../../components/ui/button';
import {
  EMailingStatus,
  EMailingTypes,
  IMailingItem,
  IMailingRecipient,
  ISmsInfo,
  mapPathByMailingType,
} from '../../../typings/mailing';
import { deleteRequest, getRequest, postRequest } from '../../../api';
import Message from '../../../components/message';
import { ButtonType } from '../../../components/ui/button/types';
import UpArrow from '../../../assets/svg/icons/upArrow';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import UniversalModal from '../../../components/ui/universalModal';
import { IConfirmData } from '../../../components/ui/universalModal/types';
import { defaultConfirm, saveChangesModal } from '../../../components/ui/universalModal/config';
import { IEmailEditorTabsIds } from './types';
import MailingRecipientsTab from '../../../components/mailing/mailingTabs/recipients';
import MailingSendDateModal from '../../../components/mailing/mailingSendDateModal';
import { getMailingRecipientsApiSettings } from '../../../api/mailingRecipients/config';
import { IMailingRecipientApiSettings, IMailingRecipientsApiParams } from '../../../api/mailingRecipients/types';
import { IApiResponse } from '../../../typings/api';
import { selectAllOptionKey } from '../../../constants/select';
import MailingEditorTab from '../../../components/mailing/mailingTabs/editor';
import { VALIDATE_CYRILLIC_REGEX } from '../../../constants/regex';

const MailingPageItem: FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.mailing));

  const { data: smsInfo, sendRequest: getSmsInfo } = useApi<ISmsInfo>(getRequest);

  const { sendRequest, loading } = useApi(postRequest);

  const { sendRequest: getMailing } = useApi(getRequest);

  const { data: recipientsList, sendRequest: getRecipients } = useApi<IApiResponse<IMailingRecipient>>(postRequest);

  const { sendRequest: cancelMailing } = useApi(postRequest);

  const [removeRecipients, setRemoveRecipients] = useState<string[]>([]);

  const [mailingData, setMailingData] = useState<IMailingItem>(defaultMailingItem);

  const [isNew, setIsNew] = useState(true);

  const [mailingType, setMailingType] = useState<EMailingTypes>(EMailingTypes.email);

  const location = useLocation();

  const emailEditorTabs = useMemo(
    () => [
      {
        id: IEmailEditorTabsIds.basic,
        position: 0,
        title: 'Основное',
        component: MailingEditorTab,
      },
      {
        id: IEmailEditorTabsIds.recipients,
        position: 1,
        title: 'Получатели',
        component: MailingRecipientsTab,
        disabled: isNew,
        notification: 'Для перехода на вкладку «Получатели» сохраните черновик',
      },
    ],
    [isNew]
  );

  const [activeTab, setActiveTab] = useState<ITab>(emailEditorTabs[0]);
  const [clickedTab, setClickerTab] = useState<ITab | null>(null);

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const [mailingModalOpen, setMailingModalOpen] = useState(false);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [apiSettings, setApiSettings] = useState<IMailingRecipientApiSettings<IMailingRecipient>>(
    getMailingRecipientsApiSettings('')
  );
  const [eventsApiParams, setEventsApiParams] = useState<IMailingRecipientsApiParams>({});

  const { sendRequest: deleteRecipients, loading: deleteRecipientsLoading } = useApi(deleteRequest);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const onlyView = useMemo(() => mailingData.status !== EMailingStatus.draft, [mailingData.status]);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), [setConfirmData]);

  const onChangeMailingData = useCallback(
    (key: string, value: any) => {
      if (!wasChange) {
        setWasChange(true);
      }
      setMailingData({ ...mailingData, [key]: value });
    },
    [mailingData, setWasChange, wasChange]
  );

  const requestRecipients = useCallback(
    async (
      reqSettings: IMailingRecipientApiSettings<IMailingRecipient> = apiSettings,
      newApiParams: IMailingRecipientsApiParams = eventsApiParams
    ) => {
      setApiSettings(reqSettings);
      setEventsApiParams(newApiParams);
      if (params.itemId)
        await getRecipients(mailingItemRecipientsUrl(params.itemId), reqSettings, { params: newApiParams });
    },
    [apiSettings, eventsApiParams, getRecipients, params.itemId]
  );

  const getData = useCallback(async () => {
    const currentType = location.pathname.includes(paths.mailingPush)
      ? EMailingTypes.push
      : location.pathname.includes(paths.mailingSms)
      ? EMailingTypes.sms
      : EMailingTypes.email;
    setMailingType(currentType);
    if (currentType === EMailingTypes.sms) getSmsInfo(mailingSmsInfoUrl());
    if (params.itemId) {
      if (params.itemId === 'new' || params.itemId === 'copy') {
        setIsNew(true);
        if (params.itemId === 'copy') setWasChange(true);
      } else {
        const resMailing = await getMailing(mailingItemUrl(params.itemId), { params: { type: currentType } });
        if (!resMailing?.response?.data) {
          setMailingData(resMailing);
          requestRecipients();
          setIsNew(false);
        } else if (resMailing?.response?.data.errorCodes === 'RecipientNotConsent') {
          setConfirmData({
            isOpen: true,
            description: resMailing?.response?.data.message,
            buttons: [
              {
                label: 'Ок',
                type: ButtonType.primary,
                onClick: () => {
                  closeConfirm();
                  getData();
                },
              },
            ],
          });
        }
      }
    }
  }, [closeConfirm, getMailing, getSmsInfo, location.pathname, params.itemId, requestRecipients, setWasChange]);

  useEffect(() => {
    getData();
  }, [params.itemId]);

  useEffect(() => {
    dispatch(
      setHeaderTitle(
        mailingData.id
          ? mailingData.title || mailingData.body.substring(0, 40)
          : mailingType === EMailingTypes.sms
          ? 'Новая SMS-рассылка'
          : mailingType === EMailingTypes.push
          ? 'Новая push-рассылка'
          : 'Новая E-mail рассылка'
      )
    );
  }, [mailingType, mailingData]);

  const onCreateCopy = useCallback(() => {
    setMailingData({
      id: null,
      title: mailingData.title,
      body: mailingData.body,
      type: mailingData.type || mailingType,
      sendDate: null,
      status: EMailingStatus.draft,
      copyId: mailingData.id,
    });
    navigate(`${mapPathByMailingType.get(mailingType) || ''}/copy`);
  }, [mailingData, navigate, mailingType]);

  const smsCount = useMemo(() => {
    const length = mailingData?.body.length || 0;
    const body = mailingData?.body || '';
    let count = 0;
    if (length && VALIDATE_CYRILLIC_REGEX.test(body)) {
      count = Math.ceil(length / 70);
    } else {
      count = Math.ceil(length / 162);
    }
    return count;
  }, [mailingData?.body]);

  const handleOnSave = useCallback(
    async (isImmediately: boolean, isDraft = false, data = mailingData) => {
      if (
        !isDraft &&
        mailingType === EMailingTypes.sms &&
        smsCount * (recipientsList?.totalCount || 0) > (smsInfo?.remainingSmsCount || 0)
      ) {
        return setConfirmData({
          isOpen: true,
          text: (
            <span className="email-page__bold">
              Измените количество отправляемых SMS или обратитесь к администратору Eltis для увеличения лимита
              сообщений.
            </span>
          ),
          description: `Вам доступно ${
            (smsInfo?.remainingSmsCount || 0) <= 0 ? 0 : smsInfo?.remainingSmsCount
          } SMS. Вы пытаетесь отправить ${smsCount * (recipientsList?.totalCount || 0)} SMS (${smsCount} SMS/${
            recipientsList?.totalCount || 0
          } абонентам).`,
          buttons: [
            {
              label: 'Хорошо',
              type: ButtonType.primary,
              onClick: closeConfirm,
            },
          ],
        });
      }

      const paramsObject: any = { isImmediately };
      if (mailingData.copyId) paramsObject.copyId = mailingData.copyId;
      mailingData.type = mailingType;
      const resError = await sendRequest(mailingUrl(), data, { params: paramsObject });
      if (!resError?.response?.data) {
        if (isNew) navigate(`${mapPathByMailingType.get(mailingType) || ''}/${resError}`);
        setWasChange(false);
        Message.success({
          content: isImmediately
            ? mailingType === EMailingTypes.email
              ? 'Письмо успешно отправлено'
              : mailingType === EMailingTypes.sms
              ? 'SMS успешно отправлено'
              : 'Push уведомление успешно отправлено'
            : 'Данные сохранены',
        });
        getData();
      } else {
        if (mailingType === EMailingTypes.sms) getSmsInfo(mailingSmsInfoUrl());
        Message.error({
          content:
            resError?.response?.data.message ||
            `Не удалось отправить ${
              mailingType === EMailingTypes.email ? 'письмо' : EMailingTypes.sms ? 'SMS сообщение' : 'Push уведомление'
            }`,
        });
      }
    },
    [
      closeConfirm,
      getData,
      getSmsInfo,
      isNew,
      mailingData,
      mailingType,
      navigate,
      recipientsList?.totalCount,
      sendRequest,
      setWasChange,
      smsCount,
      smsInfo?.remainingSmsCount,
    ]
  );

  const checkChanges = useCallback(
    (callBack: () => void) => {
      if (wasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              await handleOnSave(false, true);
              callBack();
            },
            () => {
              getData();
              closeConfirm();
              callBack();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, getData, handleOnSave, wasChange]
  );

  const repeatChangeTab = useCallback(
    (tab = clickedTab) => {
      if (tab) {
        setActiveTab(tab);
        setWasChange(false);
        setClickerTab(null);
      }
    },
    [clickedTab, setWasChange]
  );

  const handleOnChangeTab = useCallback(
    (key: string) => {
      const newActiveTab = emailEditorTabs.find((tab) => tab.id === key) || emailEditorTabs[0];
      if (wasChange) {
        setClickerTab(newActiveTab);
        checkChanges(() => repeatChangeTab(newActiveTab));
      } else {
        setActiveTab(newActiveTab);
        searchParams.set(EPageQueryParams.tabId, newActiveTab.id);
        setSearchParams(searchParams);
      }
    },
    [checkChanges, emailEditorTabs, repeatChangeTab, searchParams, setSearchParams, wasChange]
  );

  const onClickedSidebarTabChange = useCallback(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setWasChange(false);
        navigate(clickedSidebarTab);
        dispatch(setClickedSidebarTab(null));
      });
    }
  }, [checkChanges, clickedSidebarTab, dispatch, navigate, setWasChange]);

  useEffect(() => {
    onClickedSidebarTabChange();
  }, [clickedSidebarTab]);

  const goBack = useCallback(() => {
    if (wasChange) {
      dispatch(setClickedSidebarTab(paths.mailing));
    } else {
      navigate(paths.mailing);
    }
  }, [dispatch, navigate, wasChange]);

  const onSave = useCallback(() => handleOnSave(false, true), [handleOnSave]);

  const onSend = useCallback(() => handleOnSave(true), [handleOnSave]);

  const setDateAndSend = useCallback(
    (date: string) => {
      const newMailingData = { ...mailingData, sendDate: date };
      setMailingData(newMailingData);
      handleOnSave(false, false, newMailingData);
    },
    [handleOnSave, mailingData]
  );

  const onChancelSend = useCallback(() => {
    setConfirmData({
      isOpen: true,
      description: 'Вы уверены, что хотите отменить отправку данной рассылки?',
      buttons: [
        {
          label: 'Да',
          type: ButtonType.primary,
          onClick: async () => {
            closeConfirm();
            await cancelMailing(mailingCancelUrl(mailingData.id || ''), {});
            getData();
          },
        },
        {
          label: 'Нет',
          type: ButtonType.secondary,
          onClick: closeConfirm,
        },
      ],
    });
  }, [cancelMailing, closeConfirm, getData, mailingData.id]);

  const onDeleteRecipients = useCallback(
    (list = removeRecipients) => {
      setConfirmData({
        isOpen: true,
        description:
          list[0] === selectAllOptionKey
            ? 'Вы действительно хотите удалить всех получателей?'
            : list.length === 1
            ? 'Вы действительно хотите удалить получателя?'
            : 'Вы действительно хотите удалить выбранных получателей?',
        buttons: [
          {
            label: 'Да',
            type: ButtonType.primary,
            onClick: async () => {
              closeConfirm();
              if (list[0] === selectAllOptionKey) {
                await deleteRecipients(mailingDeleteRecipientsUrl(mailingData.id || '', eventsApiParams.objectId), {
                  data: {
                    selectAll: true,
                    search: apiSettings.search || '',
                  },
                });
              } else if (list.length === 1) {
                await deleteRecipients(mailingDeleteRecipientUrl(list[0]));
              } else {
                await deleteRecipients(mailingDeleteRecipientsUrl(mailingData.id || '', eventsApiParams.objectId), {
                  data: {
                    selectAll: false,
                    selectedIds: list,
                    search: apiSettings.search || '',
                  },
                });
              }
              setRemoveRecipients([]);
              requestRecipients();
            },
          },
          {
            label: 'Нет',
            type: ButtonType.secondary,
            onClick: () => {
              closeConfirm();
              setRemoveRecipients([]);
            },
          },
        ],
      });
    },
    [
      apiSettings.search,
      closeConfirm,
      deleteRecipients,
      eventsApiParams.objectId,
      mailingData.id,
      removeRecipients,
      requestRecipients,
    ]
  );

  const haveEmptyFields = useMemo(
    () => (mailingType === EMailingTypes.sms ? false : !mailingData.title) || !mailingData.body,
    [mailingData.body, mailingData.title, mailingType]
  );

  const renderRangesMenu = useCallback(
    () => (
      <Menu
        className="subscribers__range-dropdown"
        items={[
          {
            label: 'Отправить сейчас',
            key: 'send-now',
            className: 'subscribers__range-dropdown-item',
            onClick: onSend,
          },
          {
            label: 'Отправить позже',
            key: 'send-later',
            className: 'subscribers__range-dropdown-item',
            onClick: () => setMailingModalOpen(true),
          },
        ]}
      />
    ),
    [onSend]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Новая E-mail рассылка' }} />
      <MailingSendDateModal
        isOpen={mailingModalOpen}
        onCancel={() => setMailingModalOpen(false)}
        onOk={setDateAndSend}
      />
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <div className="email-page">
        <div className="email-page__button-wrapper">
          <ButtonLink withCallback onClick={goBack} leftIcon={<ArrowBackIcon />} content="Все рассылки" />
        </div>
        <div className="email-page__content">
          <Tabs
            tabBarExtraContent={
              !isNew &&
              permissions?.create &&
              activeTab.id !== IEmailEditorTabsIds.recipients && (
                <Button type={ButtonType.outline} onClick={onCreateCopy} disabled={!permissions?.create}>
                  Создать копию
                </Button>
              )
            }
            activeTabKey={activeTab.id}
            onChangeActiveTab={handleOnChangeTab}
            tabsClassName="email-page__tabs"
            tabs={emailEditorTabs.map<ITab>((tab) => ({
              ...tab,
              children: (
                <tab.component
                  mailingType={mailingType}
                  onlyView={onlyView}
                  onDeleteRecipients={onDeleteRecipients}
                  setRemoveRecipients={setRemoveRecipients}
                  removeRecipients={removeRecipients}
                  recipientsList={recipientsList}
                  requestRecipients={requestRecipients}
                  apiSettings={apiSettings}
                  eventsApiParams={eventsApiParams}
                  haveEmptyFields={haveEmptyFields}
                  mailingData={mailingData}
                  onChangeMailingData={onChangeMailingData}
                  wasChange={wasChange}
                  setWasChange={setWasChange}
                  isActiveTab={activeTab.id === tab.id}
                  permissions={permissions}
                  smsCount={smsCount}
                />
              ),
            }))}
          />
        </div>
      </div>
      {!onlyView ? (
        <div className="tab-nav-buttons email-page__buttons">
          <Button
            className="tab-nav-buttons__button tab-nav-buttons__button-cancel"
            type={ButtonType.tertiary}
            onClick={() => onDeleteRecipients()}
            hidden={
              activeTab.id !== IEmailEditorTabsIds.recipients || !permissions?.edit || removeRecipients.length === 0
            }
          >
            {removeRecipients[0] === selectAllOptionKey
              ? 'Удалить всех получателей'
              : `Удалить получателей (${removeRecipients.length})`}
          </Button>
          <Button
            className="tab-nav-buttons__button tab-nav-buttons__button-save"
            type={ButtonType.outline}
            onClick={onSave}
            disabled={loading || !wasChange || haveEmptyFields}
            hidden={!permissions?.edit || activeTab.id !== IEmailEditorTabsIds.basic}
          >
            Сохранить черновик
          </Button>
          <Dropdown
            disabled={
              loading ||
              haveEmptyFields ||
              isNew ||
              (recipientsList?.items.length === 0 && !apiSettings?.search && !eventsApiParams?.objectId) ||
              (mailingType === EMailingTypes.sms && (smsInfo?.remainingSmsCount || 0) <= 0)
            }
            overlay={renderRangesMenu}
            trigger={['click']}
          >
            <Button
              className={classNames('tab-nav-buttons__button tab-nav-buttons__button-next', {
                'tab-nav-buttons__button-next_left': activeTab.id === IEmailEditorTabsIds.recipients,
              })}
              hidden={!permissions?.edit || (mailingType === EMailingTypes.sms && !smsInfo?.isSmsMailingEnabled)}
              rightIcon={
                <span className="email-page__button-icon">
                  <UpArrow />
                </span>
              }
            >
              Отправить
            </Button>
          </Dropdown>
        </div>
      ) : mailingData.status === EMailingStatus.waiting ? (
        <div className="tab-nav-buttons email-page__buttons">
          <Button
            className="tab-nav-buttons__button tab-nav-buttons__button-save"
            type={ButtonType.tertiary}
            onClick={onChancelSend}
            loading={deleteRecipientsLoading}
          >
            Отменить отправку
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default MailingPageItem;
