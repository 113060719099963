import React, { FC } from 'react';

const FaqInfoIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66669 16C2.66669 23.3638 8.63622 29.3333 16 29.3333C23.3638 29.3333 29.3334 23.3638 29.3334 16C29.3334 8.6362 23.3638 2.66666 16 2.66666C8.63622 2.66666 2.66669 8.6362 2.66669 16ZM19.3334 21.3333H17.3334V14C17.3334 13.6318 17.0349 13.3333 16.6667 13.3333H14C13.6318 13.3333 13.3334 13.6318 13.3334 14C13.3334 14.3682 13.6318 14.6667 14 14.6667H16V21.3333H14C13.6318 21.3333 13.3334 21.6318 13.3334 22C13.3334 22.3682 13.6318 22.6667 14 22.6667H19.3334C19.7015 22.6667 20 22.3682 20 22C20 21.6318 19.7015 21.3333 19.3334 21.3333ZM16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.3726 4 4.00002 9.37258 4.00002 16C4.00002 22.6274 9.3726 28 16 28ZM17.3334 10C17.3334 9.63181 17.0349 9.33333 16.6667 9.33333H15.3334C14.9652 9.33333 14.6667 9.63181 14.6667 10V11.3333C14.6667 11.7015 14.9652 12 15.3334 12H16.6667C17.0349 12 17.3334 11.7015 17.3334 11.3333V10Z"
    />
  </svg>
);

export default FaqInfoIcon;
