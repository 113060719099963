import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from '../../../ui/button';
import Modal from '../../../ui/modal';
import { ButtonType } from '../../../ui/button/types';
import { IBackgroundModal } from './types';
import FileUploader from '../../../ui/fileUploader';
import { onlyImgTypes } from '../../../../constants/fileTypes';

const limit = 1048576;

const BackgroundModal: FC<IBackgroundModal> = (props) => {
  const { isOpen = false, onCreate = () => {}, onCancel = () => {} } = props;

  const [img, setImg] = useState<File | null>(null);

  useEffect(() => {
    if (isOpen) {
      setImg(null);
    }
  }, [isOpen]);

  const handleOnCreate = useCallback(() => {
    if (img) {
      onCreate(URL.createObjectURL(img));
      onCancel();
    }
  }, [img, onCancel, onCreate]);

  return (
    <Modal
      isOpen={isOpen}
      title="Добавление подложки"
      onCancel={onCancel}
      width={400}
      wrapClassName="schema-modal__wrapper"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={handleOnCreate}>Добавить</Button>
          <Button type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="schema-modal">
        <FileUploader acceptFileTypes={onlyImgTypes} limit={limit} data={img} setImg={setImg} />
      </div>
    </Modal>
  );
};

export default BackgroundModal;
