import React, { FC, useCallback, useEffect } from 'react';
import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import { IStringField } from './types';

const StringField: FC<IStringField> = ({ object, changeParam, index, wasSelected, disabled = false }) => {
  useEffect(() => {
    if (!object.value && !object.wasChange && wasSelected) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (value: string) => {
      object.isError = false;
      object.errorText = '';
      object.wasChange = true;
      object.value = value;
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  return (
    <Input
      title={object.description || ''}
      placeholder={object.description || ''}
      isRequired={object.isRequired}
      value={object.value?.toString()}
      onChange={onChange}
      status={object.isError ? InputStatus.error : InputStatus.normal}
      errorText={object.isError ? object.errorText : ''}
      maxLength={
        object.restricts.restricts.maxLength
          ? parseInt(object.restricts.restricts.maxLength?.toString(), 10)
          : undefined
      }
      minLength={
        object.restricts.restricts.minLength
          ? parseInt(object.restricts.restricts.minLength?.toString(), 10)
          : undefined
      }
      inputType={InputType.text}
      disabled={disabled}
      isDisabledStyle={disabled && object.readOnly}
    />
  );
};

export default StringField;
