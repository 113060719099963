import { IFeedback } from '../../typings/feedback';
import { IFeedbackApiSettings } from './types';

export const FEEDBACK_TABLE_COUNT = 10;

export const getFeedbackApiSettings = (defaultSortField: string): IFeedbackApiSettings<IFeedback> => ({
  sortFields: defaultSortField
    ? [
        {
          field: defaultSortField as keyof IFeedback,
          descending: true,
        },
      ]
    : [],
  page: 0,
  pageSize: FEEDBACK_TABLE_COUNT,
  search: '',
});
