import React, { FC } from 'react';

const FaqPlusIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.95403 11.75C1.53982 11.75 1.20403 12.0858 1.20403 12.5C1.20403 12.9142 1.53982 13.25 1.95403 13.25L10.75 13.25L10.75 22.0459C10.75 22.4602 11.0858 22.7959 11.5 22.7959C11.9142 22.7959 12.25 22.4602 12.25 22.0459L12.25 13.25H21.0459C21.4601 13.25 21.7959 12.9142 21.7959 12.5C21.7959 12.0858 21.4601 11.75 21.0459 11.75H12.25L12.25 2.95406C12.25 2.53985 11.9142 2.20406 11.5 2.20406C11.0858 2.20406 10.75 2.53985 10.75 2.95406L10.75 11.75L1.95403 11.75Z"
    />
  </svg>
);

export default FaqPlusIcon;
