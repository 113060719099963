import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getHelpEntity } from '../../../api/help';
import { IFaqChaptersApiParams } from '../../../api/help/types';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import CommonHead from '../../../components/commonHead';
import InstructionsChapter from '../../../components/instructionsChapter';
import AskHelpQuestion from '../../../components/ui/askHelpQuestion';
import ErrorPlaceholder from '../../../components/ui/errorPlaceholder';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import SectionFlatsTools from '../../../components/ui/sectionFlatsTools';
import { ISelectOption } from '../../../components/ui/select/types';
import {
  createHelpInstructionUserQuestion,
  getHelpInstructionsChapters,
  getHelpInstructionsChaptersList,
} from '../../../constants/api';
import { selectAllOptionKey } from '../../../constants/select';
import { useAppDispatch } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { setHeaderTitle } from '../../../store/slices/header';
import { IInstructionsChapter, IInstructionsChapterListItem } from '../../../typings/help/instructions';
import { helpFaqSearchLengthStart } from '../faq/config';
import { EHelpInstructionsQueryParams } from './config';

const getSearchValueParam = (value?: string | null): string =>
  (value?.length || 0) >= helpFaqSearchLengthStart ? value || '' : '';

const InstructionsPage: FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    data: chapters,
    sendRequest: requestChapters,
    loading: chaptersLoading,
  } = useApi<IInstructionsChapter[]>(getHelpEntity);

  const {
    data: chaptersList,
    sendRequest: requestChaptersList,
    loading: chaptersListLoading,
  } = useApi<IInstructionsChapterListItem[]>(getHelpEntity);

  const [chaptersApiParams, setChaptersApiParams] = useState<IFaqChaptersApiParams>({
    search: getSearchValueParam(searchParams.get(EHelpInstructionsQueryParams.search)),
  });

  const [selectedChapterId, setSelectedChapterId] = useState<string>(
    searchParams.get(EHelpInstructionsQueryParams.chapterId) || selectAllOptionKey
  );
  const [searchValue, setSearchValue] = useState<string>(searchParams.get(EHelpInstructionsQueryParams.search) || '');

  useEffect(() => {
    dispatch(setHeaderTitle('Инструкции'));
  }, [dispatch]);

  useEffect(() => {
    requestChaptersList(getHelpInstructionsChaptersList());
    requestChapters(getHelpInstructionsChapters(), { params: chaptersApiParams });
  }, []);

  const handleOnChangeChapter = useCallback(
    (chapterId: string | number) => {
      const newChapterId = chapterId === selectAllOptionKey ? '' : chapterId.toString();
      searchParams.set(EHelpInstructionsQueryParams.chapterId, newChapterId);
      setSearchParams(searchParams);

      setSelectedChapterId(chapterId.toString());
    },
    [searchParams, setSearchParams]
  );

  const handleOnChangeSearchInput = useCallback(
    async (value: string) => {
      setSearchValue(value);
      searchParams.set(EHelpInstructionsQueryParams.search, value);
      setSearchParams(searchParams);

      const searchParam = getSearchValueParam(value);
      const newChaptersApiParams = { ...chaptersApiParams, search: searchParam };

      if (searchParam || getSearchValueParam(chaptersApiParams.search)) {
        await requestChapters(getHelpInstructionsChapters(), { params: newChaptersApiParams });
      }
      setChaptersApiParams(newChaptersApiParams);
    },
    [chaptersApiParams, requestChapters, searchParams, setSearchParams]
  );

  const handleOnClearSearch = useCallback(async () => {
    setSearchValue('');
    searchParams.set(EHelpInstructionsQueryParams.search, '');
    setSearchParams(searchParams);

    const newChaptersApiParams = { ...chaptersApiParams, search: '' };
    await requestChapters(getHelpInstructionsChapters(), { params: newChaptersApiParams });
    setChaptersApiParams(newChaptersApiParams);
  }, [chaptersApiParams, requestChapters, searchParams, setSearchParams]);

  const notEmptyChaptersList = useMemo(
    () => chaptersList?.filter((item: IInstructionsChapterListItem) => (item.instructionsNumber || 0) > 0),
    [chaptersList]
  );

  const notEmptyChapters = useMemo(
    () => chapters?.filter((item: IInstructionsChapter) => (item.instruction?.length || 0) > 0),
    [chapters]
  );

  const filteredChapters = useMemo(
    () =>
      notEmptyChapters?.filter((item: IInstructionsChapter) =>
        selectedChapterId !== selectAllOptionKey ? item.id === selectedChapterId : true
      ),
    [notEmptyChapters, selectedChapterId]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Инструкции' }} />
      <div className="help-instructions">
        <SectionFlatsTools
          selects={[
            {
              title: 'Раздел',
              onChange: handleOnChangeChapter,
              value: selectedChapterId,
              containerClassName: 'help-instructions__select-wrapper',
              options: [
                { value: selectAllOptionKey, data: 'Все', title: 'Все' },
                ...(notEmptyChaptersList?.map<ISelectOption>((item) => ({
                  value: item.id || '',
                  title: item.name || '',
                })) || []),
              ],
            },
          ]}
          inputsSearch={[
            {
              showClear: true,
              onClear: handleOnClearSearch,
              placeholder: 'Поиск по тексту инструкции',
              value: searchValue,
              onSearch: handleOnChangeSearchInput,
            },
          ]}
        />
        {chaptersLoading || chaptersListLoading ? (
          <div className="help-instructions__loader-wrapper">
            <Loader color={ELoaderColor.blue} />
          </div>
        ) : searchValue && filteredChapters?.length === 0 ? (
          <ErrorPlaceholder
            containerClassName="help-instructions__search-error-wrapper"
            icon={<SearchFailIcon />}
            text="По вашему запросу ничего не найдено"
          />
        ) : (
          <div className="help-instructions__content">
            {filteredChapters?.map((item) => (
              <div key={`help-instructions-chapter-${item.id}`} className="help-instructions__chapters">
                <InstructionsChapter title={item.name} instructions={item.instruction} />
              </div>
            ))}
            <div className="help-instructions__chapters">
              <AskHelpQuestion chapters={chaptersList} url={createHelpInstructionUserQuestion()} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InstructionsPage;
