import React, { FC } from 'react';

const ChevronDoubleRight: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 17L17 12L12 7" stroke="black" strokeLinecap="round" />
    <path d="M8 17L13 12L8 7" stroke="black" strokeLinecap="round" />
  </svg>
);

export default ChevronDoubleRight;
