import React, { FC } from 'react';

const ApartmentsIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2098 27.6952V23.6062C12.2098 22.5662 13.0577 21.7211 14.1082 21.7141H17.9563C19.0118 21.7141 19.8675 22.5613 19.8675 23.6062V23.6062V27.7079C19.8673 28.591 20.5792 29.3127 21.4709 29.3334H24.0363C26.5937 29.3334 28.6668 27.2809 28.6668 24.7491V24.7491V13.1171C28.6532 12.1211 28.1808 11.1858 27.3841 10.5774L18.6105 3.58042C17.0734 2.36211 14.8884 2.36211 13.3514 3.58042L4.6162 10.5901C3.81651 11.1961 3.34335 12.1329 3.3335 13.1298V24.7491C3.3335 27.2809 5.40666 29.3334 7.96404 29.3334H10.5294C11.4433 29.3334 12.1841 28.5999 12.1841 27.6952V27.6952"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6663 17.9992C15.1337 17.9992 12.3335 15.7321 12.3335 13.3611C12.3335 11.505 13.8254 10 15.6663 10C17.5072 10 18.9995 11.505 18.9995 13.3611C18.9995 15.7321 16.1989 17.9992 15.6663 17.9992Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ApartmentsIcon;
