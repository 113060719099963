import Paper from 'paper';
import { Point } from 'paper/dist/paper-core';
import { TreeNodeType } from '../../typings/treeNode';
import {
  EEditTypes,
  EPaperGroupTypes,
  IPathExtremes,
  TPaperEvent,
  TPaperGroup,
  TPaperPath,
  TPaperPoint,
  TPaperRectangle,
} from './types';
import { checkIntersection, findExtremes, findObjectInGroups } from './paper/common';
import { ECursors } from './paper/paperItems/types';
import { maxZoom, minZoom, zoomStep } from './zoomConfig';
import { ISchemaOpacity } from '../systemTabs/schemaTab/types';

export const convertOpacityValue = (val = 100) => val / 100;

export const changeSelected = (group: TPaperGroup, selected = false) => {
  if (group) {
    if (group.data.groupType === EPaperGroupTypes.object && group.children[0]) {
      group.children[0].selected = selected ? group.opacity !== 0 : false;
    } else {
      group.children?.forEach((child: TPaperGroup) => changeSelected(child, selected));
    }
  }
};

export const findGroupById = (group: TPaperGroup, id: string): TPaperGroup | null => {
  let foundGroup = null;
  if (group.data && group.data.key === id) {
    foundGroup = group;
  }
  if (!foundGroup && group.children) {
    for (const child of group.children) {
      const result = findGroupById(child, id);
      if (result) {
        foundGroup = result;
        break;
      }
    }
  }
  return foundGroup;
};

export const getCenter = (max: number, min: number) => min + (max - min) / 2;

export const getGroupExtremes = (group: TPaperGroup): IPathExtremes | null => {
  let extremes: IPathExtremes | null = null;
  if (group.data.key === TreeNodeType.object) {
    group.children.forEach((child) => {
      const result = findObjectInGroups(child);
      if (result) {
        const childExtremes = findExtremes(result.children[0] as TPaperPath);
        if (extremes) {
          extremes.maxX = Math.max(extremes.maxX, childExtremes.maxX);
          extremes.maxY = Math.max(extremes.maxY, childExtremes.maxY);
          extremes.minX = Math.min(extremes.minX, childExtremes.minX);
          extremes.minY = Math.min(extremes.minY, childExtremes.minY);
        } else {
          extremes = childExtremes;
        }
      }
    });
  } else {
    const result = findObjectInGroups(group);
    if (result) {
      extremes = findExtremes(result.children[0] as TPaperPath);
    }
  }
  return extremes;
};

export const rescale = (group: TPaperGroup) => {
  const { height, width } = Paper.view.viewSize;
  const extremes = getGroupExtremes(group);

  if (extremes) {
    const { maxX, minX, maxY, minY } = extremes;
    const lengthX = maxX - minX + 100;
    const zoomX = lengthX > height ? height / lengthX : height / lengthX;
    const lengthY = maxY - minY + 100;
    const zoomY = lengthY > width ? width / lengthY : width / lengthY;

    Paper.view.zoom = Math.min(zoomX, zoomY);
    Paper.view.center = new Point(getCenter(maxX, minX), getCenter(maxY, minY));
  }
};

export const onWheel =
  (setCursor: (cursor: ECursors) => void = () => {}, resizeObjects = (newZoom: number) => {}) =>
  (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const oldZoom = Paper.view.zoom;
    const delta = event.deltaY;
    const newZoom = delta < 0 ? oldZoom * zoomStep : delta > 0 ? oldZoom / zoomStep : oldZoom;
    const beta = oldZoom / newZoom;
    const point = Paper.view.viewToProject(new Paper.Point(event.offsetX, event.offsetY));
    const offset = point.subtract(point.subtract(Paper.view.center).multiply(beta)).subtract(Paper.view.center);

    if (newZoom > minZoom && newZoom < maxZoom) {
      setCursor(delta < 0 ? ECursors.zoomIn : ECursors.zoomOut);
      setTimeout(() => setCursor(ECursors.default), 500);

      Paper.view.zoom = newZoom;
      Paper.view.center = Paper.view.center.add(offset);
      resizeObjects(newZoom);
    }
    return false;
  };

export const onDragCenter = (event: TPaperEvent, setCursor: (cursor: ECursors) => void) => {
  setCursor(ECursors.grabbing);
  const offset = new Paper.Point(-event.delta.x * 2, -event.delta.y * 2);
  Paper.view.center = Paper.view.center.add(offset);
};

export const correctChildAfterRotate = (group: TPaperGroup, angle: number) => {
  if (group.data.groupType === EPaperGroupTypes.object) {
    if (group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera) {
      group.rotate(-angle);
      group.data.updateObjectData();
    } else if (group.children[1]) {
      group.children[1].rotation = 0;
      const coleLabel = Paper.project.getItem({ data: { key: `${group.data.key}-label` } });
      if (coleLabel) {
        coleLabel.position = group.children[1].position.clone();
      }
    }
  } else if (group.children) {
    group.children.forEach((child) => correctChildAfterRotate(child, angle));
  }
};

export const correctChildAfterResize = (group: TPaperGroup, scaleX: number, scaleY: number) => {
  if (group.data.groupType === EPaperGroupTypes.object) {
    if (
      (group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera) &&
      group.data.bounds
    ) {
      const { position } = group;
      group.bounds = group.data.bounds.clone();
      group.position = position;
      group.data.updateObjectData();
    } else if (group.children[1]?.bounds) {
      group.children[1].scale(scaleX, scaleY);
      const { position } = group.children[1];
      group.children[1].bounds = group.children[1].data.bounds.clone();
      group.children[1].position = position;
      const coleLabel = Paper.project.getItem({ data: { key: `${group.data.key}-label` } });
      if (coleLabel) {
        coleLabel.position = group.children[1].position.clone();
      }
    }
  } else if (group.children) {
    group.children.forEach((child) => correctChildAfterResize(child, scaleX, scaleY));
  }
};

export const getPointsGroup = (group: TPaperGroup, type?: TreeNodeType): TPaperPoint[] => {
  const points: { x: number; y: number }[] = [];
  const path = findObjectInGroups(group)?.children[0] as TPaperPath | null;
  if (path) {
    path.segments.forEach((segment) => points.push({ x: segment.point.x, y: segment.point.y }));
  }

  if (type) {
    group.children.forEach((child) => {
      if (child.data.type === type) {
        const childPath = findObjectInGroups(child)?.children[0] as TPaperPath | null;
        if (childPath && childPath.segments) {
          childPath.segments.forEach((segment) => points.push({ x: segment.point.x, y: segment.point.y }));
        }
      }
    });
  }

  return Array.from(new Set(points)).map((item) => new Point(item.x, item.y));
};

export const changeVisibleGroup = (
  group: TPaperGroup,
  isVisible: boolean,
  opacityValues: ISchemaOpacity,
  needHide = false
) => {
  if (group) {
    group.locked = !isVisible;
    const opacityValue = convertOpacityValue(isVisible ? opacityValues.active : needHide ? 0 : opacityValues.inactive);
    group.opacity = opacityValue;
    group.selected = false;
    if (
      (group.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera) &&
      group.data.groupType === EPaperGroupTypes.object
    ) {
      changeVisibleGroup(group.parent.data.clone, isVisible, opacityValues);
    } else if (group.data.groupType === EPaperGroupTypes.object) {
      changeVisibleGroup(Paper.project.getItem({ data: { key: `${group.data.key}-label` } }), isVisible, opacityValues);
      group.children[0].opacity = opacityValue;
    }
  }
};

export const showOnlyOneChild = (
  group: TPaperGroup,
  parentType: string,
  opacityValues: ISchemaOpacity,
  childIsFound = false,
  hideAll = false
): boolean => {
  let isFound = childIsFound;

  const checkNeedHide = (object: TPaperGroup) => {
    if (
      !isFound ||
      group.data.type === TreeNodeType.building ||
      (group.data.type === TreeNodeType.restrictedArea && parentType === TreeNodeType.restrictedArea)
    ) {
      changeVisibleGroup(object, !hideAll, opacityValues);
      isFound = true;
    } else {
      changeVisibleGroup(
        object,
        !hideAll && (object.data.type === TreeNodeType.accessPoint || group.data.type === TreeNodeType.camera),
        opacityValues
      );
    }
  };

  if (group.data.groupType === EPaperGroupTypes.object) {
    checkNeedHide(group);
  } else {
    for (const child of group.children) {
      if (child.data.groupType === EPaperGroupTypes.object) {
        checkNeedHide(child);
      } else {
        const result = showOnlyOneChild(child, group.data.type, opacityValues, isFound, hideAll);
        if (!isFound && result) {
          isFound = true;
        }
      }
    }
  }

  return isFound;
};

export const showChildren = (group: TPaperGroup, opacityValues: ISchemaOpacity, hideAll = false, needResize = true) => {
  group.children.forEach((child) => {
    showOnlyOneChild(child, group.data.key, opacityValues, false, hideAll);
  });
  if (needResize) {
    rescale(group);
  }
};

export const showOnlyOneGroup = (
  currentGroup: TPaperGroup,
  key: string,
  opacityValues: ISchemaOpacity,
  hideAll = false,
  needResize = true
) => {
  if (currentGroup && currentGroup.children) {
    if (currentGroup.data.key === key) {
      showChildren(currentGroup, opacityValues, hideAll, needResize);
    } else {
      currentGroup.children.forEach((child) => {
        if (child.data.key === key) {
          showChildren(child, opacityValues, hideAll, needResize);
        } else if (child.data.groupType === EPaperGroupTypes.object) {
          changeVisibleGroup(child, false, opacityValues);
        } else {
          showOnlyOneGroup(child, key, opacityValues, hideAll, needResize);
        }
      });
    }
  }
};

export const moveControls = (group: TPaperGroup | null, controls: [TPaperPath, TPaperPath[], TPaperGroup] | null) => {
  if (group && controls) {
    const [path, boxes, rotate] = controls;

    boxes[0].position = group.bounds.topLeft;
    boxes[1].position = group.bounds.topRight;
    boxes[2].position = group.bounds.bottomRight;
    boxes[3].position = group.bounds.bottomLeft;

    path.bounds = group.bounds;

    rotate.position = new Point(
      group.bounds.bottomCenter.x,
      group.bounds.bottomCenter.y + 15 * (rotate.data.scale || 1)
    );
  }
};

export const getNewBoundsByIndex = (group: TPaperGroup, index: number, point: TPaperPoint): TPaperRectangle => {
  let bounds;
  const { topLeft, bottomRight } = group.bounds;
  switch (index) {
    case 0: {
      bounds = new Paper.Rectangle(new Paper.Point(point.x, point.y), new Paper.Point(bottomRight.x, bottomRight.y));
      break;
    }
    case 1: {
      bounds = new Paper.Rectangle(new Paper.Point(topLeft.x, point.y), new Paper.Point(point.x, bottomRight.y));
      break;
    }
    case 2: {
      bounds = new Paper.Rectangle(new Paper.Point(topLeft.x, topLeft.y), new Paper.Point(point.x, point.y));
      break;
    }
    case 3: {
      bounds = new Paper.Rectangle(new Paper.Point(point.x, topLeft.y), new Paper.Point(bottomRight.x, point.y));
      break;
    }
    default: {
      bounds = new Paper.Rectangle([0, 0]);
      break;
    }
  }
  return bounds;
};

export const mouseDragTool = (e: any, moveMode: EEditTypes | null, group: TPaperGroup, data?: any) => {
  if (moveMode === EEditTypes.rotate) {
    const { center } = group.children[0].bounds;
    const baseVec = center.subtract(e.lastPoint);
    const nowVec = center.subtract(e.point);
    const angle = Math.round(nowVec.angle - baseVec.angle);
    if (angle) {
      const clone = group.clone();
      clone.rotate(angle);
      if (
        clone.parent.data.key === TreeNodeType.object ||
        clone.data.type === TreeNodeType.background ||
        !checkIntersection(group.parent, clone)
      ) {
        group.rotate(angle);
        correctChildAfterRotate(group, angle);
      }
      clone.remove();
    }
  } else if (moveMode === EEditTypes.rescale) {
    const { baseBounds, baseCorner, cornerIndex } = data;
    const point = e.point.subtract(baseBounds.center);
    const scaleX = point.x / baseCorner.x;
    const scaleY = point.y / baseCorner.y;
    if (scaleX > 0.2 && scaleY > 0.2) {
      if (e.event.shiftKey) {
        const tlVec = baseBounds.topLeft.subtract(baseBounds.center).multiply(scaleX, scaleY);
        const brVec = baseBounds.bottomRight.subtract(baseBounds.center).multiply(scaleX, scaleY);
        const newBounds = new Paper.Rectangle(tlVec.add(baseBounds.center), brVec.add(baseBounds.center));
        const clone = group.clone();
        clone.bounds = newBounds;
        if (
          group.parent.data.key === TreeNodeType.object ||
          group.data.type === TreeNodeType.background ||
          (!clone.intersects(group.parent) && !checkIntersection(group.parent, clone))
        ) {
          group.bounds = newBounds;
          correctChildAfterResize(group, scaleX, scaleY);
        }
        clone.remove();
      } else {
        const clone = group.clone();
        clone.bounds = getNewBoundsByIndex(clone, cornerIndex, e.point);
        if (
          group.parent.data.key === TreeNodeType.object ||
          group.data.type === TreeNodeType.background ||
          (!clone.intersects(group.parent) && !checkIntersection(group.parent, clone))
        ) {
          group.bounds = getNewBoundsByIndex(group, cornerIndex, e.point);
          correctChildAfterResize(group, scaleX, scaleY);
        }
        clone.remove();
      }
    }
  }
};

// const changeOpacity = (group: TPaperGroup, opacityValue: ISchemaOpacity, hideAll = false) => {
//   if (group.data.type === TreeNodeType.object) {
//     if (hideAll) {
//       group.opacity = opacityValue.inactive;
//       changeVisibleGroup(Paper.project.getItem({ data: { key: `${group.data.key}-label` } }), hideAll, opacityValue);
//     } else {}
//   } else if (group.children) {
//     group.children.forEach((item) => {
//       changeOpacity(item, opacityValue, hideAll);
//     }, []);
//   }
// };
