import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';
import { IRoleTabComponent } from '../types';
import { useApi } from '../../../hooks/useApi';
import { deleteRequest, getRequest } from '../../../api';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import { roleUserUrl, roleUsersListUrl } from '../../../constants/api';
import TrashIcon from '../../../assets/svg/icons/trash';
import PlusIcon from '../../../assets/svg/icons/plus';
import { IRoleUser } from '../../../typings/roles';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, infoConfirmModal } from '../../ui/universalModal/config';

import { ButtonType } from '../../ui/button/types';
import AvailableUsersForRoleModal from './availableUsersForRole';
import { paths } from '../../../constants/paths';

const RoleUsersTab: FC<IRoleTabComponent> = (props) => {
  const { role = null, permissions = {} } = props;

  const navigate = useNavigate();

  const { data: users, sendRequest: getUsers, loading } = useApi<IRoleUser[]>(getRequest);
  const { sendRequest: deleteUser } = useApi<[]>(deleteRequest);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);
  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const [modelIsOpen, setModalOpen] = useState(false);

  const getData = useCallback(async () => {
    if (role) {
      await getUsers(roleUsersListUrl(role.id));
    }
  }, [getUsers, role]);

  useEffect(() => {
    getData();
  }, [role]);

  const getName = useCallback(
    (user: IRoleUser) =>
      user.lastName || user.firstName || user.middleName
        ? `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`
        : '-',
    []
  );

  const onSave = useCallback(() => {
    getData();
  }, [getData]);

  const onDeleteUser = useCallback(
    async (id: string) => {
      const resError = (await deleteUser(roleUserUrl(role?.id || '', id))) as any;
      getData();
      if (resError?.response?.data && resError?.response?.data?.errorCodes === 'LastRole') {
        setConfirmData(
          infoConfirmModal(
            'Вы не можете удалить назначение роли, так как это единственная роль данного пользователя. Задайте этому пользователю другую роль, чтобы открепить от текущей.',
            closeConfirm
          )
        );
      }
    },
    [closeConfirm, deleteUser, getData, role?.id]
  );

  const handleOnDeleteUser = useCallback(
    (id: string) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setConfirmData({
        isOpen: true,
        description: 'Вы действительно хотите удалить назначение этого пользователя данной роли?',
        buttons: [
          {
            label: 'Удалить',
            type: ButtonType.primary,
            onClick: async () => {
              closeConfirm();
              onDeleteUser(id);
            },
          },
          {
            label: 'Отмена',
            type: ButtonType.secondary,
            onClick: closeConfirm,
          },
        ],
      });
    },
    [closeConfirm, onDeleteUser]
  );

  const columns = useMemo<ColumnsType<IRoleUser>>(
    () =>
      [
        {
          title: 'ФИО',
          dataIndex: 'fio',
          key: 'fio',
          render: (_: any, record: IRoleUser) => (
            <div
              className="editable-table__data-container role-users-tab__fio"
              role="presentation"
              onClick={() => navigate(`${paths.users}/${record.id}`)}
            >
              <p>{getName(record)}</p>
            </div>
          ),
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          key: 'email',
          render: (_: any, record: IRoleUser) => <div className="editable-table__data-container">{record.email}</div>,
        },
        {
          hidden: !(permissions.delete || permissions.create),
          title: permissions.create ? (
            <div className="role-users-tab__header-icon" role="presentation" onClick={() => setModalOpen(true)}>
              <PlusIcon />
            </div>
          ) : null,
          render: (_: any, record: IRoleUser) =>
            permissions.delete ? (
              <div className="editable-table__icon-container">
                <div onClick={handleOnDeleteUser(record.id)} role="presentation" className="editable-table__icon">
                  <TrashIcon />
                </div>
              </div>
            ) : null,
        },
      ].filter((item) => !item.hidden),
    [getName, handleOnDeleteUser, navigate, permissions.create, permissions.delete]
  );

  const data = useMemo(
    () =>
      users?.map((event) => ({
        ...event,
        key: event.id,
      })) || [],
    [users]
  );

  return (
    <div className="role-users-tab editable-table">
      <UniversalModal data={confirmData} onClose={closeConfirm} />
      <AvailableUsersForRoleModal
        getName={getName}
        isOpen={modelIsOpen}
        onCancel={() => setModalOpen(false)}
        role={role}
        onSave={onSave}
      />
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        pagination={false}
      />
    </div>
  );
};

export default RoleUsersTab;
