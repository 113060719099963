import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IChangeCategoryModal } from './types';
import { ISelectOption } from '../../ui/select/types';
import { IFeedbackCategory, IFeedbackSubject } from '../../../typings/feedback';
import Select from '../../ui/select';
import { getRequest, postRequest } from '../../../api';
import { feedbackCategoryUrl, feedbackSubjectsListUrl, getFeedbacksCategoryListUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';

const ChangeCategoryModal: FC<IChangeCategoryModal> = (props) => {
  const {
    isOpen = false,
    onCancel = () => {},
    defaultCategory = '',
    defaultSubject = '',
    onOk = () => {},
    feedbackId,
  } = props;

  const {
    data: categories,
    sendRequest: sendCategoriesRequest,
    loading: categoryLoading,
  } = useApi<IFeedbackCategory[]>(getRequest);

  const { sendRequest: changeCategory, loading: changeCategoryLoading } = useApi(postRequest);

  const { data: subjects, sendRequest: getSubjects } = useApi<IFeedbackSubject[]>(getRequest);

  const [category, setCategory] = useState<string>(defaultCategory);

  const [subject, setSubject] = useState<string>(defaultSubject);

  const requestSubjects = useCallback(
    async (categoryId: string) => {
      await getSubjects(feedbackSubjectsListUrl(categoryId));
    },
    [getSubjects]
  );

  useEffect(() => {
    if (category) {
      requestSubjects(category);
    }
  }, [category]);

  useEffect(() => {
    if (isOpen) {
      sendCategoriesRequest(getFeedbacksCategoryListUrl());
    }
  }, [isOpen]);

  useEffect(() => {
    setCategory(defaultCategory);
  }, [defaultCategory]);

  useEffect(() => {
    setSubject(defaultSubject);
  }, [defaultSubject]);

  const onSave = useCallback(async () => {
    const resError = (await changeCategory(feedbackCategoryUrl(feedbackId || ''), {
      categoryId: category,
      subjectId: subject,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    }
  }, [changeCategory, feedbackId, category, subject, onOk]);

  const categorySelectItems = useMemo(
    () =>
      categories?.map<ISelectOption>((item) => ({
        value: item.id,
        title: item.name,
      })),
    [categories]
  );

  const subjectSelectItems = useMemo(
    () =>
      subjects?.map<ISelectOption>((item) => ({
        value: item.id,
        title: item.name,
      })),
    [subjects]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Изменение категории"
      width={416}
      showCloseIcon
      wrapClassName="change-priority__wrapper"
      centered
      footer={
        <div className="change-priority__button-container">
          <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onCancel} disabled={changeCategoryLoading}>
            Отмена
          </Button>
          <Button disabled={!category || !subject || changeCategoryLoading} size={ButtonSize.small} onClick={onSave}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Select
        loading={categoryLoading}
        title="Категория"
        value={category}
        onChange={(e) => {
          setCategory(e.toString());
          setSubject('');
        }}
        options={categorySelectItems}
      />
      <Select
        title="Тема обращения"
        value={subject}
        onChange={(e) => setSubject(e.toString())}
        options={subjectSelectItems}
      />
    </Modal>
  );
};

export default ChangeCategoryModal;
