import { Table } from 'antd';
import { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/es/table/interface';
import classNames from 'classnames';
import React, { FC, useCallback, useState, useMemo, useEffect } from 'react';
import lodash from 'lodash';
import { editFlat } from '../../../../api/flats';
import AddNodeIcon from '../../../../assets/svg/icons/addNote';
import ChevronLeft from '../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../assets/svg/icons/chevronRight';
import EditIcon from '../../../../assets/svg/icons/edit';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import TrashIcon from '../../../../assets/svg/icons/trash';
import { editFlatUrl, flatsCallBlocksUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { IApiSortField } from '../../../../typings/api';
import { IFlat } from '../../../../typings/systems/flat';
import Checkbox from '../../../ui/checkbox';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Input from '../../../ui/input';
import { InputStatus, InputType } from '../../../ui/input/types';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import NotificationModal from '../../../ui/notificationModal';
import { ISectionFlatTable, ISectionFlatTableSelection } from './types';
import { getRequest } from '../../../../api';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import EditSquareIcon from '../../../../assets/svg/icons/editSquare';
import GroupEditModal from '../groupEditModal';
import Tooltip from '../../../ui/tooltip';

const SectionFlatTable: FC<ISectionFlatTable> = (props) => {
  const {
    flats = [],
    systemId = '',
    loading = false,
    pageSize = 10,
    total = 0,
    onDeleteFlat = () => {},
    onChangePage = () => {},
    currentPage = 0,
    buildingId = '',
    onEditFlat = () => {},
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    permissions = {},
    tableSelection = {},
    onChangeTableSelection = () => {},
    sections = [],
    handleOnOkModal = () => {},
    isFiveThousandth,
  } = props;

  const { sendRequest } = useApi(editFlat);
  const [numberErrorText, setNumberErrorText] = useState<string>('');
  const [skinErrorText, setSkinErrorText] = useState<string>('');
  const [editingRecord, setEditingRecord] = useState<IFlat | null>(null);
  const [notificationFlatModalIsOpen, setNotificationFlatModalIsOpen] = useState<boolean>(false);
  const [clickNumber, setClickNumber] = useState<number>(0);
  const [selection, setSelection] = useState<ISectionFlatTableSelection>(tableSelection);

  const [groupModalOpen, setGroupModalOpen] = useState(false);

  const {
    data: callBlocks,
    sendRequest: getCallBlocks,
    loading: callBlocksLoading,
  } = useApi<{ id: string; name: string }[]>(getRequest);

  useEffect(() => {
    setSelection(tableSelection);
  }, [tableSelection]);

  const handleOnChangeSelectAll = useCallback(
    (value: boolean) => {
      onChangeTableSelection({
        selectAll: value,
        selectedIds: value ? flats.map((flat) => flat.id) : [],
        excludedIds: [],
      });
    },
    [flats, onChangeTableSelection]
  );

  const handleOnChangeSelection = useCallback(
    (value: boolean, flatId: string) => {
      let updatedSelectionSelectedIds = [...(selection.selectedIds || [])];
      let updatedSelectionExcludedIds = [...(selection.excludedIds || [])];

      if (value) {
        updatedSelectionSelectedIds.push(flatId);

        if (selection.selectAll) {
          updatedSelectionExcludedIds = updatedSelectionExcludedIds.filter((item) => item !== flatId);
        }
      } else {
        updatedSelectionSelectedIds = updatedSelectionSelectedIds.filter((item) => item !== flatId);

        if (selection.selectAll) {
          updatedSelectionExcludedIds.push(flatId);
        }
      }

      let updatedSelectionSelectAll = selection.selectAll;

      if (updatedSelectionSelectedIds.length === total) {
        updatedSelectionSelectAll = true;
        updatedSelectionExcludedIds = [];
        updatedSelectionSelectedIds = flats.map<string>((flat) => flat.id);
      } else if (updatedSelectionExcludedIds.length === total) {
        updatedSelectionSelectAll = false;
        updatedSelectionExcludedIds = [];
        updatedSelectionSelectedIds = [];
      }

      const updatedSelection = {
        ...selection,
        selectAll: updatedSelectionSelectAll,
        selectedIds: updatedSelectionSelectedIds,
        excludedIds: updatedSelectionExcludedIds,
      };
      onChangeTableSelection(updatedSelection);
    },
    [flats, onChangeTableSelection, selection, total]
  );

  useEffect(() => {
    if (selection.selectAll && tableSelection.selectAll) {
      const updatedSelectionSelectedId: string[] = [];
      flats.forEach((flat) => {
        if (!selection.excludedIds?.includes(flat.id)) {
          updatedSelectionSelectedId.push(flat.id);
        }
      });
      onChangeTableSelection({ ...selection, selectedIds: updatedSelectionSelectedId });
    }
  }, [flats]);

  const handleOnChangeEditingRecord = useCallback(
    (value: any, fieldName: keyof IFlat) => {
      const updatedEditingRecord = { ...editingRecord };
      if (fieldName === 'number') {
        setNumberErrorText('');
        updatedEditingRecord[fieldName] = Number(value);
      } else if (fieldName === 'sinkIndex') {
        setSkinErrorText('');
        updatedEditingRecord[fieldName] = Number(value);
      } else if (fieldName === 'keyNumbers') {
        updatedEditingRecord[fieldName] = value ? Number(value) : null;
      } else if (fieldName === 'floor') {
        if (value === '') {
          (updatedEditingRecord[fieldName] as any) = null;
        } else {
          updatedEditingRecord[fieldName] = Number(value);
        }
      } else {
        updatedEditingRecord[fieldName] = value;
      }
      setEditingRecord(updatedEditingRecord as IFlat);
    },
    [editingRecord]
  );

  const isEditing = useCallback((record: IFlat) => record.id === editingRecord?.id, [editingRecord?.id]);

  const handleOnClickEdit = useCallback(
    (record: IFlat) => {
      if (!editingRecord) {
        setEditingRecord(record);
        getCallBlocks(flatsCallBlocksUrl(systemId, record.sectionId));
      }
    },
    [editingRecord, getCallBlocks, systemId]
  );

  const resetEdit = useCallback(() => {
    setEditingRecord(null);
    setClickNumber(0);
  }, []);

  const handleOnSaveEdit = useCallback(async () => {
    if (editingRecord) {
      const flat = flats.find((item) => item.id === editingRecord.id);
      if (flat && !lodash.isEqual({ ...flat, key: editingRecord.key }, editingRecord)) {
        const resError = await sendRequest(editFlatUrl(buildingId, editingRecord.sectionId), editingRecord);
        if (!resError) {
          onEditFlat();
          resetEdit();
          return true;
        }
        if (resError.response.data.errorCodes === 'SinkIndexExist') setSkinErrorText(resError.response.data.message);
        else if (resError.response.data.errorCodes !== 'ObjectIsTransferred')
          setNumberErrorText(resError.response.data.message);
        setClickNumber(clickNumber + 1);
      } else {
        resetEdit();
        return true;
      }
    }

    return false;
  }, [buildingId, clickNumber, editingRecord, flats, onEditFlat, resetEdit, sendRequest]);

  const handleOnDeleteFlat = useCallback(
    (recordId: string) => {
      if (recordId === editingRecord?.id) {
        setEditingRecord(null);
      }
      onDeleteFlat(recordId);
    },
    [editingRecord?.id, onDeleteFlat]
  );

  const handleOnChangeTable: TableProps<IFlat>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IFlat> | SorterResult<IFlat>[],
      extra: TableCurrentDataSource<IFlat>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IFlat>[] = [];

        const addSortItemToSortResult = (item: SorterResult<IFlat>) => {
          if (item.order) {
            sortResults.push({
              field: item.field as keyof IFlat,
              descending: item.order === 'descend',
            });
          }
        };

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            addSortItemToSortResult(item);
          });
        } else {
          addSortItemToSortResult(sorter);
        }
        onSort(sortResults);
      }
    },
    [onSort]
  );

  const handleOnClickOutsideEditableRow = useCallback(async () => {
    const isSuccessEdit = await handleOnSaveEdit();
    if (!isSuccessEdit) {
      setNotificationFlatModalIsOpen(!!clickNumber);
    }
  }, [clickNumber, handleOnSaveEdit]);

  const handleOnClickOkInModal = useCallback(() => {
    setNotificationFlatModalIsOpen(false);
  }, []);

  const handleOnClickCancelInModal = useCallback(() => {
    setEditingRecord(null);
    setClickNumber(0);
    setNumberErrorText('');
    setSkinErrorText('');
    setNotificationFlatModalIsOpen(false);
  }, []);

  const getColumnSortOrder = useCallback((flatSort: IApiSortField<IFlat> | null) => {
    if (flatSort) {
      return flatSort.descending ? 'descend' : 'ascend';
    }

    return null;
  }, []);

  const callBlockOptions = useMemo(
    () =>
      callBlocks?.map<ISelectOption>((item) => ({
        value: item.id || '',
        title: item.name || '',
      })) || [],
    [callBlocks]
  );

  const columns = useMemo<ColumnsType<IFlat>>(
    () =>
      [
        {
          title: 'Подъезд',
          dataIndex: 'sectionName',
          key: 'sectionName',
          sorter: {
            multiple: 2,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'sectionName') || null) as SortOrder,
          render: (_: any, record: IFlat) => <div className="editable-table__data-container">{record.sectionName}</div>,
        },
        {
          title: '№ кв',
          dataIndex: 'number',
          key: 'number',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'number') || null) as SortOrder,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Input
                  value={editingRecord.number.toString()}
                  containerClassName="editable-table__input-container"
                  inputClassName="editable-table__input"
                  errorTextClassName="editable-table__input-error"
                  onChange={(value) => handleOnChangeEditingRecord(value, 'number')}
                  maxLength={5}
                  status={numberErrorText ? InputStatus.error : InputStatus.normal}
                  errorText={numberErrorText}
                  inputType={InputType.numbers}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.number}</div>
            ),
        },
        {
          title: 'Этаж',
          dataIndex: 'floor',
          key: 'floor',
          sorter: {
            multiple: 8,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'floor') || null) as SortOrder,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Input
                  value={editingRecord.floor?.toString() || ''}
                  containerClassName="editable-table__input-container"
                  inputClassName="editable-table__input"
                  errorTextClassName="editable-table__input-error"
                  onChange={(value) => handleOnChangeEditingRecord(value, 'floor')}
                  maxLength={5}
                  inputType={InputType.numbers}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.floor}</div>
            ),
        },
        {
          title: 'Ключей',
          dataIndex: 'keyNumbers',
          key: 'keyNumbers',
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Input
                  value={editingRecord.keyNumbers?.toString() || ''}
                  containerClassName="editable-table__input-container"
                  inputClassName="editable-table__input"
                  errorTextClassName="editable-table__input-error"
                  onChange={(value) => handleOnChangeEditingRecord(value, 'keyNumbers')}
                  maxLength={5}
                  inputType={InputType.numbers}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.keyNumbers}</div>
            ),
        },
        {
          title: 'Клемма',
          dataIndex: 'sinkIndex',
          key: 'sinkIndex',
          hidden: !isFiveThousandth,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Input
                  value={editingRecord.sinkIndex?.toString() || ''}
                  containerClassName="editable-table__input-container"
                  inputClassName="editable-table__input"
                  errorTextClassName="editable-table__input-error"
                  onChange={(value) => handleOnChangeEditingRecord(value, 'sinkIndex')}
                  maxLength={5}
                  status={skinErrorText ? InputStatus.error : InputStatus.normal}
                  errorText={skinErrorText}
                  inputType={InputType.numbers}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.sinkIndex}</div>
            ),
        },
        {
          title: 'БВ',
          dataIndex: 'callBlockId',
          key: 'callBlockId',
          hidden: isFiveThousandth,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container editable-table__data-container_input">
                <Select
                  loading={callBlocksLoading}
                  value={editingRecord.callBlockId || ''}
                  lengthLimit={false}
                  containerClassName="editable-table__select-container"
                  options={callBlockOptions}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'callBlockId')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container">{record.callBlockName}</div>
            ),
        },
        {
          title: 'Прямой вызов',
          dataIndex: 'isDirectCall',
          key: 'isDirectCall',
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container">
                <Checkbox
                  checked={editingRecord.isDirectCall}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'isDirectCall')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_disabled">
                <Checkbox checked={record.isDirectCall} />
              </div>
            ),
        },
        {
          title: 'Отзвонка',
          dataIndex: 'isNotificationCallBack',
          key: 'isNotificationCallBack',
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container">
                <Checkbox
                  checked={editingRecord.isNotificationCallBack}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'isNotificationCallBack')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_disabled">
                <Checkbox checked={record.isNotificationCallBack} />
              </div>
            ),
        },
        {
          title: 'Видео',
          dataIndex: 'isVideo',
          key: 'isVideo',
          hidden: !isFiveThousandth,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container">
                <Checkbox
                  checked={editingRecord.isVideo}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'isVideo')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_disabled">
                <Checkbox checked={record.isVideo} />
              </div>
            ),
        },
        {
          title: 'Блок',
          dataIndex: 'isBlocked',
          key: 'isBlocked',
          hidden: !isFiveThousandth,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container">
                <Checkbox
                  checked={editingRecord.isBlocked}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'isBlocked')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_disabled">
                <Checkbox checked={record.isBlocked} />
              </div>
            ),
        },
        {
          title: 'Обратный вызов',
          dataIndex: 'isCallback',
          key: 'isCallback',
          hidden: !isFiveThousandth,
          render: (_: any, record: IFlat) =>
            isEditing(record) && editingRecord ? (
              <div className="editable-table__data-container">
                <Checkbox
                  checked={editingRecord.isCallback}
                  onChange={(value) => handleOnChangeEditingRecord(value, 'isCallback')}
                />
              </div>
            ) : (
              <div className="editable-table__data-container editable-table__data-container_disabled">
                <Checkbox checked={record.isCallback} />
              </div>
            ),
        },
        {
          title:
            permissions.edit && flats.length ? (
              <div className="editable-table__title-icon" role="presentation" onClick={() => setGroupModalOpen(true)}>
                <Tooltip title="Редактировать группу" placement="bottom">
                  <div>
                    <EditSquareIcon />
                  </div>
                </Tooltip>
              </div>
            ) : (
              ''
            ),
          hidden: !permissions.edit,
          render: (record: IFlat) => (
            <div className="editable-table__icon-container">
              {permissions.edit && (
                <>
                  {isEditing(record) ? (
                    <div
                      onClick={() => handleOnSaveEdit()}
                      role="presentation"
                      className="editable-table__icon editable-table__icon-active"
                    >
                      <EditIcon />
                    </div>
                  ) : (
                    <div onClick={() => handleOnClickEdit(record)} role="presentation" className="editable-table__icon">
                      <EditIcon />
                    </div>
                  )}
                  <div
                    onClick={() => handleOnDeleteFlat(record.id)}
                    role="presentation"
                    className="editable-table__icon"
                  >
                    <TrashIcon />
                  </div>
                </>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [
      getColumnSortOrder,
      sortOrders,
      isFiveThousandth,
      permissions.edit,
      flats.length,
      isEditing,
      editingRecord,
      numberErrorText,
      handleOnChangeEditingRecord,
      skinErrorText,
      callBlocksLoading,
      callBlockOptions,
      handleOnSaveEdit,
      handleOnClickEdit,
      handleOnDeleteFlat,
    ]
  );

  const data = useMemo(
    () =>
      flats.map((flat) => ({
        ...flat,
        key: flat.id,
      })),
    [flats]
  );

  return (
    <div className="editable-table">
      <GroupEditModal
        isFiveThousandth={isFiveThousandth}
        isOpen={groupModalOpen}
        onOk={() => handleOnOkModal(setGroupModalOpen)}
        onCancel={() => setGroupModalOpen(false)}
        sections={sections}
        systemId={systemId}
        buildingId={buildingId}
      />
      <NotificationModal
        isOpen={notificationFlatModalIsOpen}
        onCancel={handleOnClickCancelInModal}
        onOk={handleOnClickOkInModal}
        onClose={handleOnClickOkInModal}
      />
      <Table
        columns={columns.map((column) => ({ ...column, width: `${100 / columns.length}%` }))}
        dataSource={data}
        rowSelection={{
          type: 'checkbox',
          columnTitle: (
            <Checkbox disabled={!permissions.edit} checked={selection.selectAll} onChange={handleOnChangeSelectAll} />
          ),
          selectedRowKeys: selection.selectedIds || [],
          renderCell: (value: boolean, record: IFlat) => (
            <Checkbox
              disabled={!permissions.edit}
              checked={value}
              onChange={(checked) => handleOnChangeSelection(checked, record.id)}
            />
          ),
        }}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        onChange={handleOnChangeTable}
        showSorterTooltip={false}
        rowClassName={(record) => (record.id === editingRecord?.id ? 'editable-table__row' : '')}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder
              text={
                <>
                  <span>Добавьте квартиры, чтобы сформировать</span>
                  <span>таблицу с настройками</span>
                </>
              }
              icon={<AddNodeIcon />}
            />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination',
        }}
      />
      <div
        role="presentation"
        onClick={handleOnClickOutsideEditableRow}
        className={classNames('editable-table__row-selected-drop', {
          'editable-table__row-selected-drop_visible': !!editingRecord,
        })}
      />
    </div>
  );
};

export default SectionFlatTable;
