import React, { FC, useCallback, useEffect, useState } from 'react';
import Button from '../../../ui/button';
import { IInputValue, InputStatus, defaultNotRequiredValue } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import { ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { ITreeNodeModal } from '../types';
import Input from '../../../ui/input';
import { ButtonType } from '../../../ui/button/types';
import { TreeNodeNamesMap } from '../constans';

const AccessZoneModal: FC<ITreeNodeModal> = (props) => {
  const {
    isOpen = false,
    onCreate = () => {},
    onCancel = () => {},
    editObject,
    onEdit = () => {},
    getLayout = () => '',
    path = null,
    parent = null,
  } = props;

  const [name, setName] = useState<IInputValue>({ ...defaultNotRequiredValue });
  const [shortName, setShortName] = useState<IInputValue>({ ...defaultNotRequiredValue });

  const handleOnChangeInput = useCallback(
    (setter: React.Dispatch<React.SetStateAction<IInputValue>>, value: string) =>
      setter({ ...defaultNotRequiredValue, value }),
    []
  );

  const validateInputs = useCallback(() => {
    let isValid = true;

    if (!name.value || !name.value.trim()) {
      setName({ ...name, status: InputStatus.error });
      isValid = false;
    }

    if (!shortName.value || !shortName.value.trim()) {
      setShortName({ value: shortName.value, status: InputStatus.error });
      isValid = false;
    }

    return isValid;
  }, [name, shortName]);

  const handleOnCreate = useCallback(() => {
    if (validateInputs()) {
      const newAccessZone: ITreeNode = {
        name: name.value,
        address: '',
        shortName: shortName.value,
        prefix: null,
        layout: path ? getLayout(path, shortName.value || name.value) : editObject ? editObject.layout : '',
        type: TreeNodeType.restrictedArea,
        childItems: editObject ? editObject.childItems : [],
        accessPoints: editObject ? editObject.accessPoints : [],
        cameras: editObject ? editObject.cameras : [],
      };

      if (editObject) {
        newAccessZone.id = editObject.id;
        onEdit(newAccessZone);
      } else {
        onCreate(newAccessZone);
      }

      onCancel();
    }
  }, [validateInputs, name, shortName, editObject, path, getLayout, onCancel, onEdit, onCreate]);

  useEffect(() => {
    if (!editObject) {
      setName({ ...defaultNotRequiredValue });
      setShortName({ ...defaultNotRequiredValue });
    }
  }, [isOpen]);

  useEffect(() => {
    if (editObject) {
      setName({ ...defaultNotRequiredValue, value: editObject.name || '' });
      setShortName({ ...defaultNotRequiredValue, value: editObject.shortName || '' });
    }
  }, [editObject]);

  return (
    <Modal
      isOpen={isOpen}
      title={editObject ? 'Редактирование зоны доступа' : 'Создание зоны доступа'}
      onCancel={onCancel}
      width={400}
      wrapClassName="schema-modal__wrapper"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={handleOnCreate}>{editObject ? 'Редактировать' : 'Создать'}</Button>
          <Button type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="schema-modal">
        {!editObject && parent?.type && TreeNodeNamesMap.get(parent.type) && (
          <div className="schema-modal__parent">
            {TreeNodeNamesMap.get(parent.type)}. {parent.object?.name}
          </div>
        )}
        <div className="schema-modal__input-wrapper">
          <Input
            title="Название"
            maxLength={100}
            value={name.value}
            status={name.status}
            onChange={(value) => handleOnChangeInput(setName, value)}
            placeholder="Название зоны доступа"
            isRequired
            errorText={name.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
          />
        </div>

        <div className="schema-modal__input-wrapper">
          <Input
            title="Сокращенное название"
            maxLength={10}
            value={shortName.value}
            status={shortName.status}
            onChange={(value) => handleOnChangeInput(setShortName, value)}
            placeholder="Название зоны доступа"
            description={shortName.status === InputStatus.error ? '' : 'Будет отображаться на плане'}
            isRequired
            errorText={shortName.status === InputStatus.error ? 'Поле обязательно для заполнения' : ''}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccessZoneModal;
