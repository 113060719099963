import React, { FC } from 'react';
import { Layout as LayoutComponent } from 'antd';
import classNames from 'classnames';
import { useAppSelector } from '../../hooks/hooks';
import { getContentLayoutIsFullScreen } from '../../store/selectors/layout';
import { getSidebarIsExpand } from '../../store/selectors/sidebar';
import Header from '../header';
import Sidebar from '../sidebar';
import Scrollbar from '../ui/scrollbar';
import { ILayout } from './types';

const Layout: FC<ILayout> = ({ children }) => {
  const { Header: HeaderComponent, Content } = LayoutComponent;

  const sidebarIsExpanded = useAppSelector(getSidebarIsExpand);
  const contentLayoutIsFullScreen = useAppSelector(getContentLayoutIsFullScreen);

  return (
    <LayoutComponent className="layout" hasSider>
      <div className="layout__sidebar">
        <Sidebar />
      </div>
      <div
        className={classNames('layout__content-wrapper', {
          'layout__content-wrapper_sidebar-expanded': sidebarIsExpanded,
        })}
      >
        <HeaderComponent className="layout__header">
          <Header />
        </HeaderComponent>
        <Scrollbar
          className={classNames('layout__scrollbar', {
            'layout__scrollbar_full-screen': contentLayoutIsFullScreen,
          })}
        >
          <Content className="layout__content">
            <div className="layout__content-container">{children}</div>
          </Content>
        </Scrollbar>
      </div>
    </LayoutComponent>
  );
};

export default Layout;
