import { axiosDelete, axiosGet, axiosPost } from '../axios';

export const addKeyInObject = async (url: string, count = 1) => {
  const result = await axiosPost(url, count);
  return result.data;
};

export const removeKeyInObject = async (url: string) => {
  const result = await axiosDelete(url);
  return result.data;
};

export const checkSecurityKeysInObject = async (url: string) => {
  const result = await axiosGet(url);
  return result.data;
};

export const generateSecurityKeysInObject = async (url: string) => {
  const result = await axiosGet(url);
  return result.data;
};
