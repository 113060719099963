import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import { Radio as RadioComponent, RadioChangeEvent } from 'antd';
import { IRadio } from './types';

const Radio: FC<IRadio> = (props) => {
  const {
    checked = false,
    defaultChecked = false,
    value = null,
    title = '',
    disabled = false,
    onChange = () => {},
    className = '',
  } = props;

  const handleOnChange = useCallback(
    (event: RadioChangeEvent) => {
      onChange(event.target.checked, event);
    },
    [onChange]
  );

  return (
    <div className={classNames('radio', className)}>
      <RadioComponent
        checked={checked}
        defaultChecked={defaultChecked}
        value={value}
        disabled={disabled}
        onChange={handleOnChange}
      >
        {title && <span className="radio__title">{title}</span>}
      </RadioComponent>
    </div>
  );
};

export default Radio;
