import React from 'react';

export enum ESidebarItemIds {
  none = '',
  objects = 'Objects',
  subscribers = 'Subscribers',
  flatInfo = 'FlatInfo',
  logs = 'Logs',
  guide = 'Guide',
  feedbackCategory = 'FeedbackCategory',
  help = 'Help',
  helpFaq = 'Faq',
  helpInstructions = 'Instructions',
  mpManagement = 'mpManagement',
  tariffs = 'Tariff',
  tariffCost = 'TariffCost',
  payments = 'Payment',
  subscriberFAQ = 'SubscriberFaq',
  organization = 'Organization',
  organizationInfo = 'CompanyInfo',
  roles = 'Roles',
  users = 'Users',
  dispatcherPanel = 'DispatcherPanel',
  feedback = 'Feedback',
  mailing = 'Mailing',
  objectTransfer = 'ObjectTransfer',
  objectReceive = 'ObjectReceive',
}

export interface ISidebarItem {
  id: ESidebarItemIds;
  link?: string;
  logo?: React.FC;
  title: string;
  isOpen?: boolean;
  categoryItems?: ISidebarItem[];
  countField?: string;
  countDescription?: string;
}
