import React, { FC } from 'react';

const AddNodeIcon: FC = () => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5 33.9167C27.5 35.4354 26.2688 36.6667 24.75 36.6667H11.9167C10.3979 36.6667 9.16671 35.4354 9.16671 33.9167V10.0833C9.16671 8.56455 10.3979 7.33333 11.9167 7.33333H22.9167C23.423 7.33333 23.8334 6.92293 23.8334 6.41667C23.8334 5.91041 23.423 5.5 22.9167 5.5H11.9167C9.3854 5.5 7.33337 7.55203 7.33337 10.0833V33.9167C7.33337 36.448 9.3854 38.5 11.9167 38.5H24.75C31.3314 38.5 36.6667 33.1647 36.6667 26.5833V21.0833C36.6667 20.5771 36.2563 20.1667 35.75 20.1667C35.2438 20.1667 34.8334 20.5771 34.8334 21.0833V26.5833C34.8334 28.1021 33.6022 29.3333 32.0834 29.3333C29.5521 29.3333 27.5 31.3854 27.5 33.9167ZM33.9193 30.7841C32.9131 32.9768 31.1435 34.7464 28.9508 35.7526C29.1969 35.1905 29.3334 34.5695 29.3334 33.9167C29.3334 32.3979 30.5646 31.1667 32.0834 31.1667C32.7362 31.1667 33.3572 31.0302 33.9193 30.7841ZM33 11H37.5834C38.0896 11 38.5 11.4104 38.5 11.9167C38.5 12.4229 38.0896 12.8333 37.5834 12.8333H33V17.4167C33 17.9229 32.5896 18.3333 32.0834 18.3333C31.5771 18.3333 31.1667 17.9229 31.1667 17.4167V12.8333H26.5834C26.0771 12.8333 25.6667 12.4229 25.6667 11.9167C25.6667 11.4104 26.0771 11 26.5834 11H31.1667V6.41667C31.1667 5.91041 31.5771 5.5 32.0834 5.5C32.5896 5.5 33 5.91041 33 6.41667V11ZM14.6667 15.5833C14.6667 16.0896 15.0771 16.5 15.5834 16.5H22.9167C23.423 16.5 23.8334 16.0896 23.8334 15.5833C23.8334 15.0771 23.423 14.6667 22.9167 14.6667H15.5834C15.0771 14.6667 14.6667 15.0771 14.6667 15.5833ZM15.5834 22C15.0771 22 14.6667 21.5896 14.6667 21.0833C14.6667 20.5771 15.0771 20.1667 15.5834 20.1667H28.4167C28.923 20.1667 29.3334 20.5771 29.3334 21.0833C29.3334 21.5896 28.923 22 28.4167 22H15.5834ZM14.6667 26.5833C14.6667 27.0896 15.0771 27.5 15.5834 27.5H24.75C25.2563 27.5 25.6667 27.0896 25.6667 26.5833C25.6667 26.0771 25.2563 25.6667 24.75 25.6667H15.5834C15.0771 25.6667 14.6667 26.0771 14.6667 26.5833Z"
    />
  </svg>
);

export default AddNodeIcon;
