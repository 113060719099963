import React from 'react';
import { AccessPointType, CameraIconType } from '../../../typings/treeNode';
import AccessDoor from '../../../assets/svg/icons/accessDoor';
import AccessWicket from '../../../assets/svg/icons/accessGates';
import OneWayOutputIcon from '../../../assets/svg/icons/oneWayOutput';
import TwoWayOutputIcon from '../../../assets/svg/icons/twoWayOutput';
import CameraIcon from '../../../assets/svg/icons/camera';

export const pointIconsMap = new Map<AccessPointType, { element: JSX.Element; description: string }>([
  [AccessPointType.door, { element: <AccessDoor key="door" />, description: 'Дверь' }],
  [AccessPointType.wicket, { element: <AccessWicket key="wicket" />, description: 'Калитка' }],
]);

export const cameraIconsMap = new Map<CameraIconType, { element: JSX.Element; description: string }>([
  [CameraIconType.default, { element: <CameraIcon key="camera" />, description: 'Камера' }],
]);

export const getExitIcon = (isNotCustomOutputDevice: boolean) =>
  isNotCustomOutputDevice ? <OneWayOutputIcon /> : <TwoWayOutputIcon />;
