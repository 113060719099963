import React, { FC, useCallback, useEffect, useState } from 'react';
import { ISubscribersUploadModal } from './types';
import Modal from '../../ui/modal';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import { useApi } from '../../../hooks/useApi';
import { postRequest } from '../../../api';
import { subscriberExcelImportUrl } from '../../../constants/api';
import FileUploader from '../../ui/fileUploader';
import { excelTypes } from '../../../constants/fileTypes';

const SubscribersUploadModal: FC<ISubscribersUploadModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, afterUpload = () => {}, setErrorsData = () => {} } = props;

  const [file, setFile] = useState<File | null>(null);

  const [customError, setCustomError] = useState('');

  const { sendRequest: sendExcelFile, loading: loadingSendExcelFile } = useApi(postRequest);

  useEffect(() => {
    if (isOpen) {
      setFile(null);
      setCustomError('');
    }
  }, [isOpen]);

  const handleOnClose = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const onSend = useCallback(async () => {
    if (file) {
      const data = new FormData();
      data.append('file', file);
      const resError = await sendExcelFile(subscriberExcelImportUrl(), data);
      if (resError?.response?.data) {
        setCustomError(resError?.response?.data.message);
      } else {
        setErrorsData(resError);
        afterUpload();
      }
    }
  }, [file, sendExcelFile, setErrorsData, afterUpload]);

  return (
    <Modal
      isOpen={isOpen}
      title="Загрузить файл с абонентами"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      centered
      wrapClassName="upload-subscribers__wrapper"
      footer={
        <div className="upload-subscribers__button-container">
          <Button
            loading={loadingSendExcelFile}
            type={ButtonType.secondary}
            size={ButtonSize.small}
            onClick={handleOnClose}
          >
            Отмена
          </Button>
          <Button loading={loadingSendExcelFile} disabled={!file} size={ButtonSize.small} onClick={onSend}>
            Загрузить
          </Button>
        </div>
      }
    >
      <div className="upload-subscribers">
        <FileUploader
          customError={customError}
          showPreview={false}
          altInfoText={
            <>
              <div className="file-uploader__error">{customError}</div>
              <div className="file-uploader__info">
                Формат файла XLS, XLSX. <br />
                Количество строк в файле не больше 1000
              </div>
            </>
          }
          acceptFileTypes={excelTypes}
          data={file}
          setImg={setFile}
        />
      </div>
    </Modal>
  );
};

export default SubscribersUploadModal;
