import React, { FC, useCallback, useEffect, useState } from 'react';
import { IOrganizationMainInfo } from './types';
import OrganizationDefaultLogo from '../../../assets/svg/icons/organiztationDefaultLogo';
import Button from '../../ui/button';
import { ButtonType } from '../../ui/button/types';
import ChangeMainInfoModal from '../changeInfoModal';
import ChangeLogoModal from '../changeLogoModal';
import CloseCircleIcon from '../../../assets/svg/icons/closeCIrcle';
import CheckedIcon from '../../../assets/svg/icons/checkedIcon';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { companyTariffVersionUrl } from '../../../constants/api';
import { ICompanyTariff } from '../../../typings/tariffs';

const OrganizationMainInfo: FC<IOrganizationMainInfo> = (props) => {
  const { permissions = {}, company = null, requestCompany = () => {}, isAdmin = true } = props;

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [logoModalOpen, setLogoModalOpen] = useState(false);

  const { data: tariffInfo, sendRequest: getTariffInfo } = useApi<ICompanyTariff>(getRequest);

  useEffect(() => {
    if (!isAdmin) getTariffInfo(companyTariffVersionUrl());
  }, [isAdmin]);

  const closeInfoModal = useCallback(() => {
    setInfoModalOpen(false);
  }, []);

  const closeLogoModal = useCallback(() => {
    setLogoModalOpen(false);
  }, []);

  return (
    <div className="organization-main-info">
      <ChangeMainInfoModal company={company} isOpen={infoModalOpen} onCancel={closeInfoModal} />
      <ChangeLogoModal requestCompany={requestCompany} isOpen={logoModalOpen} onCancel={closeLogoModal} />
      <div className="organization-main-info__company">
        <div className="organization-main-info__logo-container">
          <div className="organization-main-info__logo">
            {company?.logoImage ? (
              <div className="organization-main-info__logo-img">
                <img alt="Logo" src={company?.logoImage} />
              </div>
            ) : (
              <OrganizationDefaultLogo />
            )}
          </div>
          {permissions.edit && (
            <div
              className="organization-main-info__logo-edit"
              onClick={() => setLogoModalOpen(true)}
              role="presentation"
            >
              Редактировать
            </div>
          )}
        </div>
        <div className="organization-main-info__company-info">
          <div className="organization-main-info__company-name">{company?.companyName}</div>
          <div className="organization-main-info__company-address">{company?.address}</div>
          {company?.isConnectedToPaymentGateway ? (
            <div className="organization-main-info__company-integration organization-main-info__company-integration_success">
              <div className="organization-main-info__company-integration-icon">
                <CheckedIcon />
              </div>
              Интеграция с Тинькофф
            </div>
          ) : (
            <div className="organization-main-info__company-integration organization-main-info__company-integration_fail">
              <div className="organization-main-info__company-integration-icon">
                <CloseCircleIcon />
              </div>
              Интеграция с Тинькофф не выполнена
            </div>
          )}
        </div>
        {permissions.edit && (
          <Button onClick={() => setInfoModalOpen(true)} type={ButtonType.outline}>
            Редактировать данные
          </Button>
        )}
      </div>
      <div className="organization-main-info__contacts-container">
        <div className="organization-main-info__contact">
          <div className="organization-main-info__contact-title">Контактное лицо</div>
          <div className="organization-main-info__contact-card">
            <div className="organization-main-info__contact-item">
              <div className="organization-main-info__contact-label">ФИО</div>
              <div className="organization-main-info__contact-value">
                {company?.managerLastName} {company?.managerFirstName} {company?.managerSurname}
              </div>
            </div>
            <div className="organization-main-info__contact-item">
              <div className="organization-main-info__contact-label">Номер телефона</div>
              <div className="organization-main-info__contact-value">{company?.phone}</div>
            </div>
            <div className="organization-main-info__contact-item">
              <div className="organization-main-info__contact-label">Email</div>
              <div className="organization-main-info__contact-value">{company?.email}</div>
            </div>
          </div>
        </div>
        {tariffInfo && (
          <div className="organization-main-info__contact">
            <div className="organization-main-info__contact-title">Тариф организации «{tariffInfo?.version}»</div>
            <div className="organization-main-info__contact-card">
              <div className="organization-main-info__contact-item">
                <div className="organization-main-info__contact-label">Количество объектов</div>
                <div className="organization-main-info__contact-value">{tariffInfo.objectCount || 'Не ограничено'}</div>
              </div>
              <div className="organization-main-info__contact-item">
                <div className="organization-main-info__contact-label">Количество квартир</div>
                <div className="organization-main-info__contact-value">{tariffInfo.flatCount || 'Не ограничено'}</div>
              </div>
              <div className="organization-main-info__contact-item">
                <div className="organization-main-info__contact-label">
                  Количество активных сотрудников (доступ к конструктору ролей)
                </div>
                <div className="organization-main-info__contact-value">
                  {tariffInfo.activeUserCount || 'Не ограничено'}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrganizationMainInfo;
