import React, { FC, useCallback, useEffect } from 'react';
import Input from '../../../../../../ui/input';
import { InputStatus, InputType } from '../../../../../../ui/input/types';
import { IParamField } from '../types';

const NumberField: FC<IParamField> = ({ object, changeParam, index, disabled = false }) => {
  useEffect(() => {
    if (!object.value && !object.wasChange) {
      object.value = object.defaultValue;
      changeParam(index, object);
    }
  }, [object]);

  const onChange = useCallback(
    (value: string) => {
      object.isError = false;
      object.errorText = '';
      object.wasChange = true;
      object.value = value;
      changeParam(index, object);
    },
    [changeParam, index, object]
  );

  return (
    <Input
      title={object.name || ''}
      placeholder={object.description || ''}
      isRequired={object.isRequired}
      value={object.value?.toString()}
      onChange={onChange}
      status={object.isError ? InputStatus.error : InputStatus.normal}
      errorText={object.isError ? object.errorText : ''}
      inputType={InputType.numbers}
      maxLength={9}
      disabled={disabled}
      isDisabledStyle={disabled}
    />
  );
};

export default NumberField;
