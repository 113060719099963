import { ButtonType } from '../button/types';
import { IConfirmData } from './types';

export const defaultConfirm: IConfirmData = { isOpen: false, buttons: [] };

export const saveChangesModal = (yesFunction = () => {}, noFunction = () => {}): IConfirmData => ({
  isOpen: true,
  description: 'Вы хотите сохранить внесенные изменения?',
  buttons: [
    {
      label: 'Да',
      type: ButtonType.primary,
      onClick: yesFunction,
    },
    {
      label: 'Нет',
      type: ButtonType.secondary,
      onClick: noFunction,
    },
  ],
});

export const chancelChangesModal = (yesFunction = () => {}, noFunction = () => {}): IConfirmData => ({
  isOpen: true,
  description: 'Вы действительно хотите отменить изменения?',
  buttons: [
    {
      label: 'Да',
      type: ButtonType.primary,
      onClick: yesFunction,
    },
    {
      label: 'Нет',
      type: ButtonType.secondary,
      onClick: noFunction,
    },
  ],
});

export const editChoiceModal = (
  firstFunction = () => {},
  secondFunction = () => {},
  thirdFunction = () => {}
): IConfirmData => ({
  isOpen: true,
  description: 'Что вы хотите редактировать?',
  buttons: [
    {
      label: 'Только форму',
      type: ButtonType.secondary,
      onClick: firstFunction,
    },
    {
      label: 'Только данные',
      type: ButtonType.secondary,
      onClick: secondFunction,
    },
    {
      label: 'Все',
      type: ButtonType.secondary,
      onClick: thirdFunction,
    },
  ],
});

export const deleteModal = (name: string, firstFunction = () => {}, secondFunction = () => {}): IConfirmData => ({
  isOpen: true,
  description: `Вы действительно хотите удалить ${name}?`,
  buttons: [
    {
      label: 'Удалить',
      type: ButtonType.primary,
      onClick: firstFunction,
    },
    {
      label: 'Отмена',
      type: ButtonType.secondary,
      onClick: secondFunction,
    },
  ],
});

export const deleteModalWithFullName = (
  name: string,
  firstFunction = () => {},
  secondFunction = () => {}
): IConfirmData => ({
  isOpen: true,
  description: name,
  buttons: [
    {
      label: 'Удалить',
      type: ButtonType.primary,
      onClick: firstFunction,
    },
    {
      label: 'Отмена',
      type: ButtonType.secondary,
      onClick: secondFunction,
    },
  ],
});

export const simpleConfirmModal = (description: string, yesFunction = () => {}, noFunction = () => {}) => ({
  isOpen: true,
  description,
  buttons: [
    {
      label: 'Да',
      type: ButtonType.primary,
      onClick: yesFunction,
    },
    {
      label: 'Нет',
      type: ButtonType.secondary,
      onClick: noFunction,
    },
  ],
});

export const infoConfirmModal = (description: string, onClick = () => {}) => ({
  isOpen: true,
  description,
  buttons: [
    {
      label: 'Ок',
      type: ButtonType.primary,
      onClick,
    },
  ],
});
