import React, { FC } from 'react';
import { IDeviceNotificationsList } from './types';
import DeviceNotificationsItem from './item';

const DeviceNotificationsList: FC<IDeviceNotificationsList> = (props) => {
  const { notifications = [], updateList = () => {}, deviceId = '', permissions, isAdmin = false } = props;

  return (
    <div className="device-notifications-list default-scrollbar-override">
      {notifications.length ? (
        [...notifications]
          .reverse()
          .map((item, index) => (
            <DeviceNotificationsItem
              isAdmin={isAdmin}
              permissions={permissions}
              item={item}
              updateList={updateList}
              deviceId={deviceId}
              key={`${item?.id}-${index}-notify`}
            />
          ))
      ) : (
        <>
          <div className="device-notifications-list__empty">Пока нет ни одного сообщения</div>
          <div className="device-notifications-list__line" />
        </>
      )}
    </div>
  );
};

export default DeviceNotificationsList;
