import { AxiosRequestConfig } from 'axios';
import { axiosDelete, axiosGet, axiosPost } from '../axios';

export const getTariffs = async <T,>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const setTariffs = async (url: string, data?: any) => {
  const result = await axiosPost(url, data);
  return result.data;
};

export const deleteDeferredTariffs = async (url: string, config?: AxiosRequestConfig) => {
  const result = await axiosDelete(url, config);
  return result.data;
};
