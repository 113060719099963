export interface IDeviceErrorsTable {
  errors?: string[];
  warnings?: string[];
  emptyText?: string;
}

export enum EDeviceErrorsStatus {
  error = 'error',
  warning = 'warning',
}

export interface IJournalError {
  text: string;
  type?: EDeviceErrorsStatus;
}
