import ShapeCircle from '../../../assets/svg/icons/shapeCircle';
import ShapeRectangle from '../../../assets/svg/icons/shapeRectangle';
import ShapeRectangleC from '../../../assets/svg/icons/shapeRectangleC';
import ShapeRectangleE from '../../../assets/svg/icons/shapeRectangleE';
import ShapeRectangleR from '../../../assets/svg/icons/shapeRectangleR';
import { shapeE, shapeR, shapeRectangle, shapeRectangleC } from '../../../constants/paperFigures';
import { ISettings } from '../../../typings/profile';
import { TreeNodeType } from '../../../typings/treeNode';
import {
  ESchemaButtonTypes,
  ESchemaModals,
  ETypeShapes,
  EVisualizerModes,
  IButtonData,
  ISchemaBackground,
  ISchemaOpacity,
  IShape,
  IVisualizerMode,
} from './types';

export const defaultDisplayedObjects: TreeNodeType[] = [
  TreeNodeType.restrictedArea,
  TreeNodeType.section,
  TreeNodeType.accessPoint,
  TreeNodeType.camera,
];

export const TreeNodeNamesMap = new Map([
  ['Object', 'Объект'],
  ['RestrictedArea', 'Зона доступа'],
  ['Building', 'Строение'],
  ['Section', 'Подъезд'],
]);

export const paperBaseShapes: IShape[] = [
  { element: ShapeRectangle({}), type: ETypeShapes.path, points: shapeRectangle },
  { element: ShapeCircle({}), type: ETypeShapes.circle, points: [] },
];

export const paperBuildingShapes: IShape[] = [
  { element: ShapeRectangle({}), type: ETypeShapes.path, points: shapeRectangle },
  { element: ShapeCircle({}), type: ETypeShapes.circle, points: [] },
  { element: ShapeRectangleC({}), type: ETypeShapes.path, points: shapeRectangleC },
  { element: ShapeRectangleR({}), type: ETypeShapes.path, points: shapeR },
  { element: ShapeRectangleE({}), type: ETypeShapes.path, points: shapeE },
];

export const allowedShapesMap = new Map<TreeNodeType, IShape[]>([
  [TreeNodeType.restrictedArea, paperBaseShapes],
  [TreeNodeType.building, paperBuildingShapes],
  [TreeNodeType.section, [paperBaseShapes[0]]],
  [TreeNodeType.accessPoint, []],
]);

export const typeDependencies = new Map<TreeNodeType, TreeNodeType[]>([
  [
    TreeNodeType.restrictedArea,
    [TreeNodeType.restrictedArea, TreeNodeType.building, TreeNodeType.accessPoint, TreeNodeType.camera],
  ],
  [TreeNodeType.building, [TreeNodeType.restrictedArea, TreeNodeType.section, TreeNodeType.camera]],
  [TreeNodeType.section, [TreeNodeType.restrictedArea, TreeNodeType.accessPoint, TreeNodeType.camera]],
  [TreeNodeType.accessPoint, []],
  [TreeNodeType.undefined, [TreeNodeType.restrictedArea, TreeNodeType.building]],
  [TreeNodeType.background, []],
]);

export const initSelectObject = {
  originalKey: '',
  object: null,
  parent: null,
  parents: [],
  type: null,
  nodeIndex: 0,
};

export const defaultMode: IVisualizerMode = {
  status: EVisualizerModes.default,
  nodeType: null,
  typeOfCreation: null,
  shape: null,
  editType: null,
};

export const defaultButton: IButtonData = {
  id: '' as TreeNodeType,
  label: '',
  type: ESchemaButtonTypes.default,
  key: '',
  onClick: () => {},
  onOpenModal: () => {},
  onStartCreate: () => {},
};

export const defaultBackground: ISchemaBackground = {
  img: '',
  layout: '',
  isRescaled: false,
};

export const treeNodeButtons = (
  handleOnOpenModal: (modal: ESchemaModals | null) => void,
  setMode: () => void,
  settings: ISettings
) => {
  const map = new Map<TreeNodeType, IButtonData>([
    [
      TreeNodeType.restrictedArea,
      {
        id: TreeNodeType.restrictedArea,
        key: 'add-access-zone',
        label: 'Добавить зону доступа',
        type: ESchemaButtonTypes.extendedCreation,
        onOpenModal: () => handleOnOpenModal(ESchemaModals.accessZone),
        onStartCreate: setMode,
      } as IButtonData,
    ],
    [
      TreeNodeType.building,
      {
        id: TreeNodeType.building,
        key: 'add-building',
        label: 'Добавить строение',
        type: ESchemaButtonTypes.extendedCreation,
        onOpenModal: () => handleOnOpenModal(ESchemaModals.building),
        onStartCreate: setMode,
      } as IButtonData,
    ],
    [
      TreeNodeType.section,
      {
        id: TreeNodeType.section,
        key: 'add-section',
        label: 'Добавить подъезд',
        type: ESchemaButtonTypes.extendedCreation,
        onOpenModal: () => handleOnOpenModal(ESchemaModals.section),
        onStartCreate: setMode,
      } as IButtonData,
    ],
    [
      TreeNodeType.accessPoint,
      {
        id: TreeNodeType.accessPoint,
        key: 'add-access-point',
        label: 'Добавить точку доступа',
        type: ESchemaButtonTypes.default,
        onClick: () => handleOnOpenModal(ESchemaModals.accessPoint),
        onOpenModal: () => handleOnOpenModal(ESchemaModals.accessPoint),
      } as IButtonData,
    ],
  ]);

  if (settings.camera) {
    map.set(TreeNodeType.camera, {
      id: TreeNodeType.accessPoint,
      key: 'add-camera',
      label: 'Добавить камеру',
      type: ESchemaButtonTypes.default,
      onClick: () => handleOnOpenModal(ESchemaModals.camera),
      onOpenModal: () => handleOnOpenModal(ESchemaModals.camera),
    } as IButtonData);
  }

  return map;
};

export const getCreateModeButtons = (onSave: () => void, onChancel: () => void) => [
  {
    ...defaultButton,
    key: 'save-changes',
    label: 'Сохранить',
    type: ESchemaButtonTypes.default,
    onClick: onSave,
  },
  {
    ...defaultButton,
    key: 'chancel-changes',
    label: 'Отменить',
    type: ESchemaButtonTypes.default,
    onClick: onChancel,
  },
];

export const getBackgroundButtons = (
  background: ISchemaBackground,
  opacity: number,
  openModal: () => void,
  onDeleteBackground: () => void,
  onChange: (val: any) => void
) => [
  {
    ...defaultButton,
    key: 'add-background',
    label: background.img ? 'Изменить подложку' : 'Добавить подложку',
    type: ESchemaButtonTypes.default,
    onClick: openModal,
  },
  ...(background.img
    ? [
        {
          ...defaultButton,
          key: 'delete-background',
          label: 'Удалить подложку',
          type: ESchemaButtonTypes.default,
          onClick: onDeleteBackground,
        },
        {
          ...defaultButton,
          key: 'change-opacity',
          label: 'Прозрачность',
          type: ESchemaButtonTypes.range,
          value: opacity,
          onChange,
        },
      ]
    : []),
];

export const getOpacityButton = (opacityValues: ISchemaOpacity, onChange: (val: ISchemaOpacity) => void) => ({
  ...defaultButton,
  key: 'opacity-values',
  label: 'Прозрачность',
  type: ESchemaButtonTypes.extendedRanges,
  value: opacityValues,
  onChange: onChange,
});

export const defaultSchemaOpacity: ISchemaOpacity = {
  background: 20,
  active: 95,
  inactive: 20,
};
