import classNames from 'classnames';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import React, { FC } from 'react';
import { IScrollbar } from './types';

const Scrollbar: FC<IScrollbar> = (props) => {
  const { children, disableIndent = false, className = '', autoHide = false } = props;

  return (
    <OverlayScrollbarsComponent
      className={classNames('scrollbar', className, {
        'scrollbar_disable-indent': disableIndent,
      })}
      defer
      options={{ scrollbars: { theme: '', autoHide: autoHide ? 'scroll' : 'never', autoHideDelay: 2000 } }}
    >
      {children}
    </OverlayScrollbarsComponent>
  );
};

export default Scrollbar;
