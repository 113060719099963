import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Key } from 'antd/lib/table/interface';
import { DirectoryTreeProps } from 'antd/lib/tree';
import classNames from 'classnames';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { createSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import CommonHead from '../../../components/commonHead';
import ButtonLink from '../../../components/ui/buttonLink';
import { paths } from '../../../constants/paths';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import { setHeaderTitle } from '../../../store/slices/header';
import SchemaTree from '../../../components/systemTabs/schemaTab/schemaTree';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import SchemaCanvas from '../../../components/schemaCanvas';
import { setContentLayoutIsFullScreen } from '../../../store/slices/layout';
import { onSelectedTreeNode, sortNodeChildren } from '../../../components/systemTabs/schemaTab/calculations';
import { EVisualizerModes, ISelectedSchemaEntity } from '../../../components/systemTabs/schemaTab/types';
import { IAccessPoint, IAccessPointDevice, ITreeNode, TreeNodeType } from '../../../typings/treeNode';
import { getSystem } from '../../../api/systems';
import {
  defaultDisplayedObjects,
  defaultMode,
  defaultSchemaOpacity,
  initSelectObject,
} from '../../../components/systemTabs/schemaTab/constans';
import { getObjectBaseUrl, getObjectPlanUrl } from '../../../constants/api';
import StatusPopUpComponent from '../../../components/ui/statusPopUp';
import { EDispatcherDeviceStatus, fromStatusToName } from '../../../typings/dispatcherPanel';
import { statusToTitle } from './config';
import { ISystemBase } from '../../../typings/systems/base';
import { dateDefaultFormat } from '../../../constants/date';
import { IStatusPopUpData } from '../../../components/ui/statusPopUp/types';
import SchemaButtons from '../../../components/systemTabs/schemaTab/schemaButtons';
import SelectMultiple from '../../../components/ui/selectMultiple';
import { ISelectMultipleOption } from '../../../components/ui/selectMultiple/types';
import { EPageQueryParams } from '../../../typings/searchParams';
import { EDeviceTabsIds } from '../item/types';

const DispatcherPanelPlanItem: FC = () => {
  const params = useParams();

  const dispatch = useAppDispatch();

  const [selectedNode, setSelected] = useState<ISelectedSchemaEntity>({ ...initSelectObject });
  const selectedNodeCopy = useRef<ISelectedSchemaEntity>(selectedNode);

  const [localSchema, setLocalSchema] = useState<ITreeNode[]>([]);
  const editableSchema = useRef<ITreeNode[]>([]);

  const { data, sendRequest, loading: treeLoading } = useApi<ITreeNode[]>(getSystem);

  const { data: systemData, sendRequest: getSystemData } = useApi<ISystemBase>(getSystem);

  const fullScreenElementRef = useRef<HTMLDivElement | null>(null);
  const [isFullScreen, setFullScreen] = useState<boolean>(false);

  const [statusPopUpData, setStatusPopUpData] = useState<IStatusPopUpData[] | null>(null);

  const schemaRef = useRef<HTMLDivElement>(null);

  const [clickedCoordinates, setClickedCoordinates] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const navigate = useNavigate();

  const [displayedObjects, setDisplayedObjects] = useState<TreeNodeType[]>(defaultDisplayedObjects);

  const [statuses, setStatuses] = useState<string[]>([]);

  const setSelectedNode = useCallback((state: ISelectedSchemaEntity) => {
    setSelected(state);
    selectedNodeCopy.current = state;
  }, []);

  const filterSchema = useCallback((node: ITreeNode, filters: string[]) => {
    if (node.accessPoints) {
      node.accessPoints = node.accessPoints.filter(
        (item) =>
          (item.inputDevice &&
            filters.includes(item.inputDevice?.status?.status || EDispatcherDeviceStatus.withoutEvents)) ||
          (item.outputDevice &&
            filters.includes(item.outputDevice?.status?.status || EDispatcherDeviceStatus.withoutEvents))
      );
    }
    node.childItems?.forEach((item) => filterSchema(item, filters));
  }, []);

  const setSchemas = useCallback(
    (schema: ITreeNode[], statusesList: string[] = statuses) => {
      schema.forEach((node) => sortNodeChildren(node));
      editableSchema.current = JSON.parse(JSON.stringify(schema));
      if (statusesList.length) {
        editableSchema.current.forEach((item) => filterSchema(item, statusesList));
      }
      setLocalSchema(schema);
    },
    [filterSchema, statuses]
  );

  const title = useMemo(() => `План объекта: ${systemData?.objectName}`, [systemData?.objectName]);

  useEffect(() => {
    dispatch(setHeaderTitle(title));
  }, [title]);

  const getPlan = useCallback(() => {
    sendRequest(getObjectPlanUrl(params.deviceId || ''));
  }, []);

  useEffect(() => {
    if (params.deviceId) {
      getPlan();
      const interval = window.setInterval(() => {
        getPlan();
      }, 30000);

      getSystemData(getObjectBaseUrl(params.deviceId));

      return () => {
        clearInterval(interval);
      };
    }
  }, [params.deviceId]);

  useEffect(() => {
    if (data) {
      setSchemas(data);
    }
  }, [data]);

  useEffect(() => {
    if (schemaRef.current) {
      schemaRef.current.onclick = (e) => {
        setClickedCoordinates({ x: e.offsetX, y: e.offsetY });
      };
    }
  }, [statusPopUpData]);

  const getPopUpData = useCallback(
    (object: IAccessPoint, field: string): IStatusPopUpData => {
      const device = object[field as keyof IAccessPoint] as IAccessPointDevice;
      const status = (device.status?.status || EDispatcherDeviceStatus.withoutEvents) as EDispatcherDeviceStatus;
      return {
        text: `${statusToTitle.get(status)}: ${object.name}${field === 'inputDevice' ? '_вход' : '_выход'} в ${
          systemData?.shortName || systemData?.objectName || ''
        }, серийный номер: ${device.serialNumber}, время: ${moment(device.status?.date).format(dateDefaultFormat)}`,
        title: fromStatusToName.get(status) || '',
        status,
        id: device.id || '',
      };
    },
    [systemData]
  );

  const handleOnSelectedTreeNode = useCallback(
    (originalKey: string): ISelectedSchemaEntity => {
      let key = originalKey;
      let isAccessPoint = false;
      let isClickOnPlan = true;

      if (originalKey.includes(TreeNodeType.accessPoint)) {
        isAccessPoint = true;
        key = originalKey.split('__')[0] as string;
        isClickOnPlan = key === originalKey;
      }
      const state = onSelectedTreeNode(key, editableSchema.current);
      setSelectedNode(state);

      if (isAccessPoint && isClickOnPlan && state.object) {
        const result: IStatusPopUpData[] = [];
        const accessPoint = state.object as IAccessPoint;

        if (accessPoint.inputDevice) {
          result.push(getPopUpData(accessPoint, 'inputDevice'));
        }
        if (accessPoint.outputDevice) {
          result.push(getPopUpData(accessPoint, 'outputDevice'));
        }
        setStatusPopUpData(result);
      }

      return state;
    },
    [getPopUpData, setSelectedNode]
  );

  const handleTreeOnSelect: DirectoryTreeProps['onSelect'] = useCallback(
    (keys: Key[]) => handleOnSelectedTreeNode(keys[0] as string),
    [handleOnSelectedTreeNode]
  );

  const onResizeTab = useCallback(() => {
    if (fullScreenElementRef.current) {
      setFullScreen((flag: boolean) => {
        const newFlag = !flag;
        dispatch(setContentLayoutIsFullScreen(newFlag));

        return newFlag;
      });
    }
  }, [dispatch]);

  const goToDevicePage = useCallback(
    (id: string) => {
      navigate({
        pathname: `${paths.dispatcherPanel}/${id}`,
        search: `?${createSearchParams({
          [EPageQueryParams.tabId]: EDeviceTabsIds.journal,
          [EPageQueryParams.prevPage]: `${paths.dispatcherPanel}/${params.deviceId}/plan`,
        })}`,
      });
    },
    [navigate, params.deviceId]
  );

  const onClickPopUp = useCallback(
    (index: number) => {
      if (statusPopUpData) {
        goToDevicePage(statusPopUpData[index].id);
      }
      setStatusPopUpData(null);
    },
    [goToDevicePage, statusPopUpData]
  );

  const onClosePopUp = useCallback(() => {
    setStatusPopUpData(null);
  }, []);

  const handleOnChangeSelect = useCallback(
    (val: string[]) => {
      setStatuses(val);
      setSchemas([...localSchema], val);
    },
    [localSchema, setSchemas]
  );

  const checkboxes = useMemo(
    () =>
      selectedNode.type === TreeNodeType.building ||
      (selectedNode.type === TreeNodeType.restrictedArea && selectedNode.parents.length === 0)
        ? [
            { label: 'Отображать подъезды строения', value: TreeNodeType.section },
            { label: 'Отображать зоны доступа строения', value: TreeNodeType.restrictedArea },
          ]
        : [],
    [selectedNode.parents.length, selectedNode.type]
  );

  const objectTypesItems = useMemo(() => {
    const res: ISelectMultipleOption[] = [];
    for (const [key, value] of fromStatusToName as any) {
      if (key) {
        res.push({
          value: key,
          title: value,
        });
      }
    }
    return res;
  }, []);

  return (
    <>
      <CommonHead seo={{ title: title }} />
      <div className="plan-item-page">
        <div className="plan-item-page__button-wrapper">
          <ButtonLink url={paths.dispatcherPanel} leftIcon={<ArrowBackIcon />} content="Панель диспетчера" />
        </div>

        <div
          className={classNames('system-schema-tab', {
            'system-schema-tab_fullscreen': isFullScreen,
          })}
          ref={fullScreenElementRef}
        >
          <div className="system-schema-tab__left-block">
            <SchemaTree
              goToDevicePage={goToDevicePage}
              mode={EVisualizerModes.deviceShow}
              schema={editableSchema.current}
              selectedKeys={selectedNode.originalKey ? [selectedNode.originalKey] : []}
              parents={selectedNode.parents}
              onSelect={handleTreeOnSelect}
            />
          </div>
          <div className="system-schema-tab__right-block">
            <div className="system-schema-tab__schema-wrapper">
              <SchemaButtons
                displayedObjects={displayedObjects}
                checkboxes={checkboxes}
                inOneLine
                onChangeCheckbox={(e: CheckboxValueType[]) => setDisplayedObjects(e as TreeNodeType[])}
              >
                <SelectMultiple
                  showClear
                  isSelectedAllDefault
                  title="Статус"
                  values={statuses}
                  options={objectTypesItems}
                  onChange={handleOnChangeSelect}
                />
              </SchemaButtons>
              <div className="system-schema-tab__schema" ref={schemaRef}>
                <StatusPopUpComponent
                  position={clickedCoordinates}
                  onClickPopUp={onClickPopUp}
                  onClosePopUp={onClosePopUp}
                  data={statusPopUpData}
                />
                <SchemaCanvas
                  permissions={{ edit: false }}
                  opacityValues={defaultSchemaOpacity}
                  schema={editableSchema.current}
                  onSelectNode={handleOnSelectedTreeNode}
                  selectedNode={selectedNode}
                  currentMode={{
                    ...defaultMode,
                    status: EVisualizerModes.deviceShow,
                  }}
                  isFullScreen={isFullScreen}
                  onResizeTab={onResizeTab}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DispatcherPanelPlanItem;
