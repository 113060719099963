import React, { FC } from 'react';

const TrashIcon: FC = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3249 9.96777C19.3249 9.96777 18.7819 16.7028 18.4669 19.5398C18.3169 20.8948 17.4799 21.6888 16.1089 21.7138C13.4999 21.7608 10.8879 21.7638 8.27991 21.7088C6.96091 21.6818 6.13791 20.8778 5.99091 19.5468C5.67391 16.6848 5.13391 9.96777 5.13391 9.96777"
      stroke="#949494"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7082 6.73926H3.75018"
      stroke="#949494"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.4406 6.73949C16.6556 6.73949 15.9796 6.18449 15.8256 5.41549L15.5826 4.19949C15.4326 3.63849 14.9246 3.25049 14.3456 3.25049H10.1126C9.53358 3.25049 9.02558 3.63849 8.87558 4.19949L8.63258 5.41549C8.47858 6.18449 7.80258 6.73949 7.01758 6.73949"
      stroke="#949494"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrashIcon;
