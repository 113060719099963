import React from 'react';
import { InputStatus } from '../types';

export interface IMaskInput {
  title?: string | React.ReactNode;
  placeholder?: string;
  value?: string;
  status?: InputStatus;
  disabled?: boolean;
  onChange?: (value: string, event?: React.ChangeEvent) => void;
  containerClassName?: string;
  inputClassName?: string;
  titleClassName?: string;
  errorText?: string;
  errorTextClassName?: string;
  description?: string;
  textInfo?: string | React.ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  isRequired?: boolean;
  isDisabledStyle?: boolean;
  mask?: string | Array<string | RegExp>;
}

export const phoneNumberMask = '+7 (999) 999 99 99';
