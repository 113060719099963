export interface IConfigurationData {
  additionalInformation: {
    callCodesErrors?: number;
    isNotSecurityKeys?: boolean;
    deviceSettingsErrors?: number;
    isSynchronizationAvailable?: boolean;
    allFlatsHaveCallBlockId?: boolean;
  };
  additionalInformationJSON?: string;
  data?: {};
  errorCodes?: string;
  hResult?: number;
  helpLink?: string;
  innerException?: string;
  message?: string;
  source?: string;
  stackTrace?: string;
  targetSite?: string;
  isSuccess?: boolean;
}

export interface IValidateConfiguration {
  callCodesErrors: number;
  deviceSettingsErrors: number;
  hasArchiveDeviceVersion: boolean;
  isNotSecurityKeys: boolean;
  isSynchronizationAvailable: boolean;
  allFlatsHaveCallBlockId: boolean;
}

export enum EConfigurationStates {
  default = 'default',
  success = 'success',
  objectError = 'objectError',
  defaultError = 'defaultError',
}
