import { AxiosRequestConfig } from 'axios';
import { ITreeNode } from '../../typings/treeNode';
import { axiosGet, axiosPost } from '../axios';
import { ISelectedAccessGroup } from './types';

export const getAccessGroupsTree = async (url: string, config?: AxiosRequestConfig): Promise<ITreeNode> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const getAccessGroupsTable = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const addAccessGroup = async (url: string, data: ISelectedAccessGroup, config?: AxiosRequestConfig) => {
  const result = await axiosPost(url, data, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};
