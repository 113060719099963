import classNames from 'classnames';
import moment, { Moment } from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { getEntitiesForSubscriber } from '../../../../api/subscribers';
import { getSubscriberBuildingsUrl, getSubscriberFlatsUrl } from '../../../../constants/api';
import { useApi } from '../../../../hooks/useApi';
import { IApiParams, IApiResponse } from '../../../../typings/api';
import { Status } from '../../../../typings/status';
import { ISubscriberApartment, ISubscriberBuilding, ISubscriberObject } from '../../../../typings/subscribers';
import StatusSelect from '../../../statusSelect';
import { StatusIconColor } from '../../../statusSelect/types';
import Button from '../../../ui/button';
import { ButtonType } from '../../../ui/button/types';
import Checkbox from '../../../ui/checkbox';
import PeriodDatePicker from '../../../ui/periodDatePicker';
import ErrorPlaceholder from '../../../ui/errorPlaceholder';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import { SubscriberEditMode } from '../../types';
import { ISubscriberObjectAddress } from './types';
import { dateDefaultFormat, dateFormatNoTime } from '../../../../constants/date';
import { paths } from '../../../../constants/paths';
import { useAppSelector } from '../../../../hooks/hooks';
import { getProfilePermission } from '../../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../../typings/sidebar';
import { EPageQueryParams } from '../../../../typings/searchParams';

const SubscriberObjectAddress: FC<ISubscriberObjectAddress> = (props) => {
  const {
    subscriberId = '',
    selectObjects = [],
    flat = {},
    allFlats = [],
    editMode = SubscriberEditMode.viewing,
    onChangeAddress = () => {},
    onDeleteAddress = () => {},
    order = 1,
    subscription = null,
    isError = false,
    isMainSubscriberError = false,
    deleteLoading = false,
    isStatusError = false,
  } = props;

  const permission = useAppSelector(getProfilePermission(ESidebarItemIds.payments));

  const [currentSelectedObject, setCurrentSelectedObject] = useState<Omit<ISubscriberObject, 'flats'> | null>(
    flat.objectId ? { objectId: flat.objectId } : null
  );
  const [buildingSearch, setBuildingSearch] = useState<string>('');
  const [flatSearch, setFlatSearch] = useState<string>('');

  const {
    data: buildings,
    sendRequest: sendRequestBuildings,
    loading: buildingsLoading,
  } = useApi<IApiResponse<ISubscriberBuilding>>(getEntitiesForSubscriber);

  const [buildingApiParams, setBuildingApiParams] = useState<{ objectId: string } & IApiParams<ISubscriberBuilding>>({
    sort: [{ name: 'address' }],
    search: '',
    page: 0,
    count: 0,
    objectId: '',
  });

  const {
    data: flatsNumbers,
    sendRequest: sendRequestFlatsNumbers,
    loading: flatsNumbersLoading,
  } = useApi<IApiResponse<ISubscriberApartment>>(getEntitiesForSubscriber);
  const [flatNumbersApiParams, setFlatNumbersApiParams] = useState<
    { buildingId: string } & IApiParams<ISubscriberApartment>
  >({
    sort: [{ name: 'number' }],
    search: '',
    page: 0,
    count: 0,
    buildingId: '',
  });

  const navigate = useNavigate();

  const requestBuildings = useCallback(
    async (objectId: string) => {
      const newBuildingApiParams = { ...buildingApiParams, objectId: objectId };
      await sendRequestBuildings(getSubscriberBuildingsUrl(), { params: newBuildingApiParams });
      setBuildingApiParams(newBuildingApiParams);
    },
    [buildingApiParams, sendRequestBuildings]
  );

  const requestFlatsNumbers = useCallback(
    async (buildingId: string) => {
      const updatedFlatNumbersApiParams = { ...flatNumbersApiParams, buildingId: buildingId };
      await sendRequestFlatsNumbers(getSubscriberFlatsUrl(), { params: updatedFlatNumbersApiParams });
      setFlatNumbersApiParams(updatedFlatNumbersApiParams);
    },
    [flatNumbersApiParams, sendRequestFlatsNumbers]
  );

  useEffect(() => {
    const newSelectedObject = flat.objectId ? { objectId: flat.objectId } : null;
    if (currentSelectedObject?.objectId !== newSelectedObject?.objectId) {
      setCurrentSelectedObject(newSelectedObject);
    }
  }, [flat]);

  const selectDisabled = useMemo(() => editMode === SubscriberEditMode.viewing, [editMode]);

  const handleOnChangeSelectedObject = useCallback(
    async (value: string | number) => {
      const newSelectedObjectId = value.toString();
      if (newSelectedObjectId) {
        await requestBuildings(newSelectedObjectId);
        onChangeAddress({
          objectId: newSelectedObjectId,
        });
      } else {
        onChangeAddress({});
      }
    },
    [onChangeAddress, requestBuildings]
  );

  const handleOnSearchBuilding = useCallback((value: string) => {
    setBuildingSearch(value);
  }, []);

  const handleOnChangeBuilding = useCallback(
    async (buildingId: string | number) => {
      if (buildingId && currentSelectedObject && buildings?.items) {
        setBuildingSearch('');
        const newBuildingId = buildingId.toString();
        await requestFlatsNumbers(newBuildingId);
        const building = buildings?.items.find((item) => item.buildingId === buildingId);
        onChangeAddress({
          objectId: currentSelectedObject.objectId,
          buildingId: newBuildingId,
          buildingAddress: building?.address,
        });
      }
    },
    [buildings?.items, currentSelectedObject, onChangeAddress, requestFlatsNumbers]
  );

  const handleOnSearchFlatsNumbers = useCallback((value: string) => {
    setFlatSearch(value);
  }, []);

  const handleOnChangeFlatNumber = useCallback(
    (flatId: string | number) => {
      if (flatId) {
        const findFlat = flatsNumbers?.items.find((currFlat) => currFlat.flatId === flatId);
        if (findFlat) {
          setFlatSearch('');
          onChangeAddress({ ...flat, isMain: false, status: Status.active, sipNumber: '', ...findFlat });
        }
      }
    },
    [flat, flatsNumbers?.items, onChangeAddress]
  );

  const handleOnChangeDate = useCallback(
    (firstDate?: Moment, secondDate?: Moment) => {
      onChangeAddress({
        ...flat,
        fromDate: firstDate?.startOf('day').toISOString() || null,
        toDate: secondDate?.endOf('day').toISOString() || null,
      });
    },
    [flat, onChangeAddress]
  );

  const onTariffClick = useCallback(() => {
    navigate({
      pathname: `${paths.mpManagementSubscriptions}/${subscription?.subscriptionId}`,
      search: `?${createSearchParams({
        [EPageQueryParams.prevPage]: paths.subscribers || '',
        [EPageQueryParams.prevPageId]: subscriberId || '',
      })}`,
    });
  }, [navigate, subscriberId, subscription?.subscriptionId]);

  const onAddressClick = useCallback(() => {
    if (!buildings) {
      requestBuildings(flat.objectId || '');
    }
  }, [buildings, flat.objectId, requestBuildings]);

  const onNumberClick = useCallback(() => {
    if (!flatsNumbers) {
      requestFlatsNumbers(flat.buildingId || '');
    }
  }, [flat.buildingId, flatsNumbers, requestFlatsNumbers]);

  const filteredFlats = useMemo<ISubscriberApartment[]>(
    () =>
      flatsNumbers
        ? flatsNumbers?.items.reduce((result: ISubscriberApartment[], flatNumber: ISubscriberApartment) => {
            if (
              allFlats.find(
                (subscriberExistFlat) =>
                  subscriberExistFlat.flatId === flatNumber.flatId && flatNumber.flatId !== flat.flatId
              )
            ) {
              return result;
            }
            return [...result, flatNumber];
          }, []) || []
        : [
            {
              ...flat,
            } as ISubscriberApartment,
          ],
    [allFlats, flat, flatsNumbers]
  );

  const addressOptions = useMemo(
    () =>
      buildings
        ? buildings?.items
            ?.filter((building) => building.address?.toLowerCase().includes(buildingSearch.toLowerCase()))
            .map<ISelectOption>((building) => ({
              value: building.buildingId || '',
              title: building.address || '',
            }))
        : [
            {
              value: flat.buildingId || '',
              title: flat.buildingAddress || '',
            },
          ],
    [buildingSearch, buildings, flat.buildingAddress, flat.buildingId]
  );

  return (
    <div className="subscriber-object-address">
      <div className="subscriber-object-address__object-select-container">
        <span className="subscriber-object-address__order">{order}.</span>
        <Select
          isError={isError && !currentSelectedObject?.objectId}
          containerClassName="subscriber-object-address__object-select"
          isRequired
          title="Краткое наименование объекта"
          disabled={selectDisabled || flat.isDisabled}
          value={currentSelectedObject?.objectId || ''}
          onChange={handleOnChangeSelectedObject}
          options={selectObjects.map<ISelectOption>((object) => ({
            value: object.objectId || '',
            title: object.name || '',
          }))}
          isDisabledStyle
        />
      </div>
      <div className="subscriber-object-address__row subscriber-object-address__address">
        <Select
          isError={isError && !flat?.buildingId}
          title="Адрес"
          showArrow={false}
          showSearch
          isRequired
          loading={buildingsLoading}
          onSearch={handleOnSearchBuilding}
          searchValue={buildingSearch}
          placeholder="Адрес строения"
          value={flat?.buildingId}
          onChange={handleOnChangeBuilding}
          disabled={editMode === SubscriberEditMode.viewing || !flat.objectId || flat.isDisabled}
          lengthLimit={false}
          containerClassName="subscriber-object-address__building-select"
          onClick={onAddressClick}
          options={addressOptions}
          notFoundContent={
            <ErrorPlaceholder
              text="По вашему запросу ничего не найдено"
              containerClassName="subscriber-object-address__not-found"
              textContainerClassName="subscriber-object-address__not-found-text"
            />
          }
          isDisabledStyle
        />
        <Select
          isError={isError && !flat?.flatId}
          title="Квартира"
          isRequired
          showArrow={false}
          placeholder="номер"
          showSearch
          onSearch={handleOnSearchFlatsNumbers}
          searchValue={flatSearch}
          value={flat?.flatId}
          loading={flatsNumbersLoading}
          onClick={onNumberClick}
          onChange={handleOnChangeFlatNumber}
          containerClassName="subscriber-object-address__flat-select"
          options={filteredFlats
            ?.filter((currFlat) => currFlat.number?.toString().toLowerCase().includes(flatSearch.toLowerCase()))
            .map<ISelectOption>((currFlat) => ({
              value: currFlat.flatId || '',
              title: currFlat.number?.toString() || '',
            }))}
          disabled={editMode === SubscriberEditMode.viewing || !flat.buildingId || flat.isDisabled}
          isDisabledStyle
          lengthLimit={false}
          notFoundContent={
            <ErrorPlaceholder
              text="По вашему запросу ничего не найдено"
              containerClassName="subscriber-object-address__not-found"
              textContainerClassName="subscriber-object-address__not-found-text"
            />
          }
        />
      </div>
      <div
        className={classNames('subscriber-object-address__row subscriber-object-address__property', {
          'subscriber-object-address__property_create-mode': editMode === SubscriberEditMode.create,
        })}
      >
        <div
          className={classNames('subscriber-object-address__checkbox-wrapper', {
            'subscriber-object-address__checkbox-wrapper_mt': isStatusError,
          })}
        >
          <div className="subscriber-object-address__checkbox">
            <Checkbox
              label="Главный абонент"
              checked={flat.isMain}
              disabled={editMode === SubscriberEditMode.viewing || flat.isDisabled}
              onChange={(checked) =>
                onChangeAddress({
                  ...flat,
                  isMain: checked,
                  isConfirm: checked,
                  status: Status.active,
                  fromDate: null,
                  toDate: null,
                })
              }
              error={isMainSubscriberError}
            />
          </div>
        </div>
        {editMode !== SubscriberEditMode.create && (
          <StatusSelect
            title="Статус"
            value={flat.status as Status}
            onChange={(value) => onChangeAddress({ ...flat, status: value })}
            disabled={editMode === SubscriberEditMode.viewing || flat.isDisabled || flat.isMain}
            isDisabledStyle
            options={[
              { value: Status.active, title: 'Активен', color: StatusIconColor.green },
              { value: Status.inactive, title: 'Неактивен', color: StatusIconColor.red },
            ]}
          />
        )}
        <PeriodDatePicker
          isCanUnlimited
          title="Период действия"
          firstDate={flat.isMain ? '' : flat.fromDate || ''}
          secondDate={flat.isMain ? '' : flat.toDate || ''}
          format={dateFormatNoTime}
          onChange={handleOnChangeDate}
          disabled={editMode === SubscriberEditMode.viewing || flat.isMain}
          placeholder="Не ограничен"
          isDisabledStyle
          isError={isStatusError}
          errorText={isStatusError ? 'Период действия не соответствует статусу абонента' : ''}
        />
        {editMode !== SubscriberEditMode.viewing && (
          <div className="subscriber-object-address__button-container">
            <Button
              loading={deleteLoading}
              type={ButtonType.tertiary}
              className="subscriber-object-address__button"
              onClick={onDeleteAddress}
            >
              Удалить адрес
            </Button>
          </div>
        )}
      </div>
      {subscription && subscription.tariff && (
        <div className="subscriber-object-address__row subscriber-object-address__subscription">
          Текущий тариф:
          {permission?.view ? (
            <span className="subscriber-object-address__subscription-type" role="presentation" onClick={onTariffClick}>
              {subscription.tariff}
            </span>
          ) : (
            ` ${subscription.tariff}`
          )}
          <br />
          Следующая дата оплаты:
          {subscription.nextPaymentDate ? moment(subscription.nextPaymentDate).format(dateDefaultFormat) : '-'}
        </div>
      )}
    </div>
  );
};

export default SubscriberObjectAddress;
