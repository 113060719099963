import moment from 'moment';
import { IDisablePushPeriod } from '../typings/dispatcherPanel';
import { IQueryParamsObject } from '../typings/searchParams';

export const getFullPath = (data: IQueryParamsObject) =>
  `${data.prevPage}${data.prevPageId ? `/${data.prevPageId}` : ''}${data.prevTabId ? `?tabId=${data.prevTabId}` : ''}`;

export const calculateNextEvent = (data: IDisablePushPeriod[]) => {
  let nextEvent = null;
  if (data) {
    [...data].sort((a, b) => moment(a.endDate).unix() - moment(b.endDate).unix());
    nextEvent = -1 * moment().diff(data[0]?.endDate);
  }
  return nextEvent;
};
