import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { deleteFlat } from '../../../api/flats';
import { getSystem } from '../../../api/systems';
import { deleteFlatsFewUrl, getObjectBuildingsWithSectionsUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { ISystemBuilding } from '../../../typings/systems/building';
import { IFlatDeleteApiSettings } from '../../../typings/systems/flat';
import { sortBuildingsByName } from '../../../utils/object';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import TabsCustom from '../../tabsCustom';
import { ICustomTab } from '../../tabsCustom/types';
import NotificationModal from '../../ui/notificationModal';
import { ISystemTabComponent } from '../types';
import SectionFlats from './sectionFlats';
import UniversalModal from '../../ui/universalModal';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, infoConfirmModal } from '../../ui/universalModal/config';
import { ButtonType } from '../../ui/button/types';
import { EFlatsStatus } from './types';

const SystemSectionStructureTab: FC<ISystemTabComponent> = (props) => {
  const {
    systemId,
    onNextTab,
    tabId = '',
    activeTabKey = '',
    updateAvailableTabs = () => {},
    nextTabIsAvailable,
    permissions = {},
    isFiveThousandth,
  } = props;
  const { data: buildings, sendRequest, loading } = useApi<ISystemBuilding[]>(getSystem, sortBuildingsByName);

  const [activeTabId, setActiveTabId] = useState<string>('');
  const [deleteFlatModalIsOpen, setDeleteFlatModalIsOpen] = useState<boolean>(false);
  const [flatDeleteApiSettings, setFlatDeleteApiSettings] = useState<IFlatDeleteApiSettings>({});
  const [flatsStatus, setFlatsStatus] = useState<EFlatsStatus>(EFlatsStatus.default);
  const { sendRequest: sendRequestDeleteFlats, loading: flatsDeleteLoading } = useApi(deleteFlat);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const closeConfirm = useCallback(() => setConfirmData(defaultConfirm), []);

  const updateBuildings = useCallback(() => {
    if (systemId) {
      sendRequest(getObjectBuildingsWithSectionsUrl(systemId));
    }
  }, [sendRequest, systemId]);

  const resetActiveTabId = useCallback((currBuildings: ISystemBuilding[]) => {
    setActiveTabId(currBuildings.find((item) => item.sectionCount > 0)?.id || '');
  }, []);

  useEffect(() => {
    if (tabId === activeTabKey) {
      updateBuildings();
    }
  }, [activeTabKey, tabId]);

  useEffect(() => {
    if (buildings) {
      if (!activeTabId || (activeTabId && (buildings.find((item) => item.id === activeTabId)?.sectionCount || 0) < 1)) {
        resetActiveTabId(buildings);
      }
    }
  }, [buildings]);

  const validateFlatDeleteApiSettings = useMemo<boolean>(
    () =>
      Boolean(
        flatDeleteApiSettings.buildingId &&
          ((flatDeleteApiSettings.body?.selectAll && (flatDeleteApiSettings.total || 0) > 0) ||
            (!flatDeleteApiSettings.body?.selectAll &&
              flatDeleteApiSettings.body?.selectedIds &&
              flatDeleteApiSettings.body?.selectedIds?.length > 0))
      ),
    [
      flatDeleteApiSettings.body?.selectAll,
      flatDeleteApiSettings.body?.selectedIds,
      flatDeleteApiSettings.buildingId,
      flatDeleteApiSettings.total,
    ]
  );

  const resetDelete = useCallback(() => {
    closeConfirm();
    setFlatsStatus(EFlatsStatus.needReset);
  }, [closeConfirm]);

  const handleOnDeleteFlats = useCallback(async () => {
    setDeleteFlatModalIsOpen(false);

    if (validateFlatDeleteApiSettings && flatDeleteApiSettings.buildingId) {
      const resError = await sendRequestDeleteFlats(
        deleteFlatsFewUrl(flatDeleteApiSettings.buildingId, flatDeleteApiSettings.sectionId),
        {
          data: {
            selectAll: Boolean(flatDeleteApiSettings.body?.selectAll),
            selectedIds: flatDeleteApiSettings.body?.selectedIds || [],
            search: flatDeleteApiSettings.body?.search || '',
          },
        }
      );

      if (!resError?.response?.data) {
        setFlatsStatus(EFlatsStatus.needUpdate);
      } else if (resError?.response?.data.additionalInformation.flatCount === 0) {
        setConfirmData(infoConfirmModal('Невозможно удалить квартиры, в них уже назначены абоненты', resetDelete));
      } else if (resError?.response?.data.additionalInformation.flatCount !== 0) {
        setConfirmData({
          isOpen: true,
          description: resError?.response?.data.message,
          buttons: [
            {
              label: `Удалить квартиры (${resError?.response?.data.additionalInformation.flatCount})`,
              type: ButtonType.primary,
              onClick: async () => {
                await sendRequestDeleteFlats(
                  deleteFlatsFewUrl(flatDeleteApiSettings.buildingId || '', flatDeleteApiSettings.sectionId),
                  {
                    data: {
                      selectAll: false,
                      selectedIds: resError?.response?.data.additionalInformation.flatIds,
                      search: flatDeleteApiSettings.body?.search || '',
                    },
                  }
                );
                setFlatsStatus(EFlatsStatus.needUpdate);
                closeConfirm();
              },
            },
            {
              label: 'Отмена',
              type: ButtonType.secondary,
              onClick: resetDelete,
            },
          ],
        });
      }
    }
    updateBuildings();
  }, [
    closeConfirm,
    flatDeleteApiSettings,
    resetDelete,
    sendRequestDeleteFlats,
    updateBuildings,
    validateFlatDeleteApiSettings,
  ]);

  return (
    <div className="system-section-structure-tab">
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      <NotificationModal
        title="Вы действительно хотите удалить выбранные квартиры?"
        okButtonText="Удалить"
        cancelButtonText="Отмена"
        isOpen={deleteFlatModalIsOpen}
        onOk={handleOnDeleteFlats}
        onCancel={() => setDeleteFlatModalIsOpen(false)}
      />
      {tabId === activeTabKey && (
        <TabsCustom
          activeTabKey={activeTabId}
          loading={loading}
          onChangeActiveTab={setActiveTabId}
          tabs={buildings?.map<ICustomTab>((building) => ({
            tabId: building.id,
            disabled: !(building.sections.length > 0) || flatsDeleteLoading,
            data: [
              { fieldTitle: 'Строение', fieldData: building.name },
              { fieldTitle: 'Код строения', fieldData: building.prefix },
              { fieldTitle: 'Кол-во подъездов', fieldData: building.sectionCount || '–' },
              { fieldTitle: 'Кол-во квартир', fieldData: building.flatCount || '–' },
            ],
            children:
              building.sections.length > 0 ? (
                <SectionFlats
                  tabId={tabId}
                  isFiveThousandth={isFiveThousandth}
                  systemId={systemId}
                  buildingId={building.id}
                  sections={building.sections}
                  updateBuildings={updateBuildings}
                  updateAvailableTabs={updateAvailableTabs}
                  permissions={permissions}
                  isActive={activeTabId === building.id}
                  onChangeFlatApiSettings={setFlatDeleteApiSettings}
                  flatsStatus={flatsStatus}
                  onChangeFlatsStatus={setFlatsStatus}
                />
              ) : null,
          }))}
        />
      )}
      <TabNavButtons
        buttons={tabNavButtonsDefault(
          {
            isHidden: !validateFlatDeleteApiSettings,
            text: `Удалить квартиры (${
              flatDeleteApiSettings.body?.selectAll
                ? (flatDeleteApiSettings.total || 0) - (flatDeleteApiSettings.body.selectedIds?.length || 0)
                : flatDeleteApiSettings.body?.selectedIds?.length
            })`,
            callBack: () => setDeleteFlatModalIsOpen(true),
            loading: flatsDeleteLoading,
          },
          { isHidden: true },
          {
            isHidden: !permissions.edit,
            callBack: onNextTab,
            classNameOption: 'system-section-structure-tab__button-next',
            disabled: !nextTabIsAvailable || flatsDeleteLoading,
          }
        )}
      />
    </div>
  );
};

export default SystemSectionStructureTab;
