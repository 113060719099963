import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getSubscriber } from '../../../api/subscribers';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import CommonHead from '../../../components/commonHead';
import { SubscriberEditMode } from '../../../components/subscribersTabs/types';
import Tabs from '../../../components/tabs';
import { ITab } from '../../../components/tabs/types';
import ButtonLink from '../../../components/ui/buttonLink';
import Loader from '../../../components/ui/loader';
import { ELoaderColor } from '../../../components/ui/loader/types';
import { getSubscriberByIdUrl } from '../../../constants/api';
import { paths } from '../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { getProfilePermission } from '../../../store/selectors/profile';
import { setHeaderTitle } from '../../../store/slices/header';
import { ESidebarItemIds } from '../../../typings/sidebar';
import { ISubscriber } from '../../../typings/subscribers';
import { subscribersTabs } from '../config';
import { ESubscribersTabsIds } from '../types';
import { EPageQueryParams } from '../../../typings/searchParams';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';

const SubscriberItem: FC = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const permissionSubscribers = useAppSelector(getProfilePermission(ESidebarItemIds.subscribers));

  const { data: subscriber, sendRequest, loading } = useApi<ISubscriber>(getSubscriber);

  const [title, setTitle] = useState<string>('');
  const [editMode, setEditMode] = useState<SubscriberEditMode>(
    permissionSubscribers?.edit ? SubscriberEditMode.edit : SubscriberEditMode.viewing
  );
  const [nextTabId, setNextTabId] = useState<string>('');

  const [activeTab, setActiveTab] = useState<ITab>(
    subscribersTabs.find((tab) => tab.id === searchParams.get(EPageQueryParams.tabId)) || subscribersTabs[0]
  );
  const [disabledAllTabs, setDisabledAllTabs] = useState<boolean>(false);

  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  useEffect(() => {
    sendRequest(getSubscriberByIdUrl(params.subscriberId || ''));
    if (!searchParams.get(EPageQueryParams.tabId)) {
      searchParams.set(EPageQueryParams.tabId, ESubscribersTabsIds.basic);
      setSearchParams(searchParams);
    }
  }, [params.subscriberId]);

  const calculateTabDisabled = useCallback(
    (tabId: string) => {
      switch (tabId) {
        case ESubscribersTabsIds.equipment:
          return !subscriber?.hasActiveApartment;
        case ESubscribersTabsIds.accessPointLog:
          return !(subscriber?.subscriberObjects && subscriber.subscriberObjects.length > 0);
        default:
          return false;
      }
    },
    [subscriber]
  );

  const handleOnChangeTab = useCallback(
    (key: string, isLock = false) => {
      if (isLock) {
        setNextTabId(key);
      } else {
        const newActiveTab = subscribersTabs.find((tab) => tab.id === key) || subscribersTabs[0];
        setActiveTab(newActiveTab);
        searchParams.set(EPageQueryParams.tabId, newActiveTab.id);
        setSearchParams(searchParams);
        setNextTabId('');
        setWasChange(false);
      }
    },
    [searchParams, setSearchParams, setWasChange]
  );

  useEffect(() => {
    if (subscriber) {
      setTitle(`${subscriber.lastName || ''} ${subscriber.firstName || ''} ${subscriber.middleName || ''}`);
      if (calculateTabDisabled(activeTab.id)) {
        handleOnChangeTab(ESubscribersTabsIds.basic);
      }
    }
  }, [subscriber]);

  useEffect(() => {
    if (title) {
      dispatch(setHeaderTitle(title));
    }
  }, [dispatch, title]);

  useEffect(() => {
    setEditMode(permissionSubscribers?.edit ? SubscriberEditMode.edit : SubscriberEditMode.viewing);
  }, [permissionSubscribers]);

  const handleOnChangeDisableAllTabs = useCallback((disabled: boolean) => setDisabledAllTabs(disabled), []);

  const handleOnChangeNextTab = useCallback(
    (tabId = '', isLock = false) => {
      if (tabId) {
        handleOnChangeTab(tabId, isLock);
      } else {
        const newTabPosition = activeTab.position + 1;
        const newTabId = subscribersTabs.find((tab) => tab.position === newTabPosition)?.id;
        handleOnChangeTab(newTabId || '', isLock);
      }
    },
    [activeTab.position, handleOnChangeTab]
  );

  const handleOnSave = useCallback(
    async (updatedSubscriber: ISubscriber) => {
      if (updatedSubscriber.id) {
        await sendRequest(getSubscriberByIdUrl(updatedSubscriber.id));
      }
    },
    [sendRequest]
  );

  const goBack = useCallback(() => {
    if (wasChange) {
      dispatch(setClickedSidebarTab(paths.subscribers));
    } else {
      navigate(paths.subscribers);
    }
  }, [dispatch, navigate, wasChange]);

  const buttonNextText = useMemo(() => {
    switch (editMode) {
      case SubscriberEditMode.viewing:
        return 'Редактировать';
      case SubscriberEditMode.edit:
        return 'Сохранить';
      default:
        return 'Далее';
    }
  }, [editMode]);

  const handleResetNextTabId = useCallback(() => {
    setNextTabId('');
  }, []);

  return (
    <>
      <CommonHead seo={{ title: `Абонент: ${title}` }} />
      <div className="subscriber-item">
        <div className="subscriber-item__button-wrapper">
          <ButtonLink withCallback onClick={goBack} leftIcon={<ArrowBackIcon />} content="Все Абоненты" />
        </div>
        <div className="subscriber-item__content">
          {!loading && subscriber && (
            <Tabs
              activeTabKey={activeTab.id}
              onChangeActiveTab={(key) => handleOnChangeTab(key, wasChange)}
              tabsClassName="subscriber-item__tabs"
              tabs={subscribersTabs.map<ITab>((tab) => ({
                ...tab,
                disabled: disabledAllTabs ? tab.id !== activeTab.id : calculateTabDisabled(tab.id),
                children: (
                  <tab.component
                    subscriber={subscriber}
                    editMode={editMode}
                    onSave={handleOnSave}
                    nextButtonText={buttonNextText}
                    onNextTab={handleOnChangeNextTab}
                    wasChange={wasChange}
                    setWasChange={setWasChange}
                    nextTabId={nextTabId}
                    resetNextTabId={handleResetNextTabId}
                    isActiveTab={activeTab.id === tab.id}
                    requestSaveError={{}}
                    permissions={permissionSubscribers}
                    onChangeDisableAllTabs={handleOnChangeDisableAllTabs}
                  />
                ),
              }))}
            />
          )}
          {loading && (
            <div className="subscriber-item__loader-wrapper">
              <Loader color={ELoaderColor.blue} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscriberItem;
