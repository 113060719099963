import React, { FC } from 'react';

const UnionItemsIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33301 12.6666C4.52356 12.0584 4 11.0903 4 9.99992V8.66658C4 6.82564 5.49238 5.33325 7.33333 5.33325H24.6765C26.5174 5.33325 28.0098 6.82564 28.0098 8.66658V9.99992C28.0098 11.0903 27.4862 12.0584 26.6768 12.6666C27.4862 13.2747 28.0098 14.2429 28.0098 15.3333V16.6666C28.0098 17.757 27.4862 18.7251 26.6768 19.3333C27.4862 19.9414 28.0098 20.9095 28.0098 21.9999V23.3333C28.0098 25.1742 26.5174 26.6666 24.6765 26.6666H7.33333C5.49238 26.6666 4 25.1742 4 23.3333V21.9999C4 20.9095 4.52356 19.9414 5.33301 19.3333C4.52356 18.7251 4 17.757 4 16.6666V15.3333C4 14.2429 4.52356 13.2747 5.33301 12.6666ZM26.6765 16.6666C26.6765 17.7712 25.781 18.6666 24.6667 18.6666H7.33333C6.22876 18.6666 5.33333 17.7712 5.33333 16.6666V15.3333C5.33333 14.2287 6.22876 13.3333 7.33333 13.3333H24.6765C25.781 13.3333 26.6765 14.2287 26.6765 15.3333V16.6666ZM26.6765 9.99992C26.6765 11.1045 25.781 11.9999 24.6667 11.9999H7.33333C6.22876 11.9999 5.33333 11.1045 5.33333 9.99992V8.66658C5.33333 7.56202 6.22876 6.66658 7.33333 6.66658H24.6765C25.781 6.66658 26.6765 7.56202 26.6765 8.66658V9.99992ZM5.33333 21.9999V23.3333C5.33333 24.4378 6.22876 25.3333 7.33333 25.3333H24.6765C25.781 25.3333 26.6765 24.4378 26.6765 23.3333V21.9999C26.6765 20.8953 25.781 19.9999 24.6765 19.9999H7.33333C6.22876 19.9999 5.33333 20.8953 5.33333 21.9999ZM6.66667 9.99992C6.66667 10.3681 6.96514 10.6666 7.33333 10.6666H10C10.3682 10.6666 10.6667 10.3681 10.6667 9.99992C10.6667 9.63173 10.3682 9.33325 10 9.33325H7.33333C6.96514 9.33325 6.66667 9.63173 6.66667 9.99992ZM7.33333 17.3333C6.96514 17.3333 6.66667 17.0348 6.66667 16.6666C6.66667 16.2984 6.96514 15.9999 7.33333 15.9999H10C10.3682 15.9999 10.6667 16.2984 10.6667 16.6666C10.6667 17.0348 10.3682 17.3333 10 17.3333H7.33333ZM6.66667 23.3333C6.66667 23.7014 6.96514 23.9999 7.33333 23.9999H10C10.3682 23.9999 10.6667 23.7014 10.6667 23.3333C10.6667 22.9651 10.3682 22.6666 10 22.6666H7.33333C6.96514 22.6666 6.66667 22.9651 6.66667 23.3333Z"
      fill="white"
    />
  </svg>
);

export default UnionItemsIcon;
