import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { YMapLocationRequest } from '@yandex/ymaps3-types';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { useApi } from '../../hooks/useApi';
import { getRequest, postRequestWithArrayInParams } from '../../api';
import { setHeaderTitle } from '../../store/slices/header';
import CommonHead from '../../components/commonHead';
import InputSearch from '../../components/ui/inputSearch';
import {
  companyUrl,
  dispatcherPanelDevicesListUrl,
  getObjectSystemIdUrl,
  getObjectSystemsListUrl,
} from '../../constants/api';
import { IApiResponse, IApiSortField } from '../../typings/api';
import { ISelectOption } from '../../components/ui/select/types';
import { ISystem } from '../../typings/systems/system';
import InformationPanel from '../../components/dispatcherPanel/informationPanel';
import { IDispatcherPanelDevice, fromStatusToName } from '../../typings/dispatcherPanel';
import { IDispatcherPanelApiParams, IDispatcherPanelApiSettings } from '../../api/dispatcherPanel/types';
import { getDispatcherPanelApiSettings } from '../../api/dispatcherPanel/config';
import { getSystem } from '../../api/systems';
import DispatcherTable from '../../components/dispatcherPanel/dispatcherTable';
import { checkIsAdmin, getProfilePermission } from '../../store/selectors/profile';
import { ESidebarItemIds } from '../../typings/sidebar';
import { selectAllOptionKey } from '../../constants/select';
import { ISelectMultipleOption } from '../../components/ui/selectMultiple/types';
import DisablingPush from '../../components/dispatcherPanel/disablingPush';
import { IOrganization } from '../../typings/organization';
import Tabs from '../../components/tabs';
import { EDispatcherPanelFilters, EDispatcherPanelTabs } from './types';
import DispatcherPanelMap from '../../components/dispatcherPanel/mapTab';
import { defaultLocation } from '../../components/yandexMap/const';
import FiltersTool from '../../components/ui/filtersTool';
import { EFilterTool } from '../../typings/filtersTool';

const DispatcherPanel: FC = () => {
  const dispatch = useAppDispatch();

  const {
    data: systems,
    sendRequest: sendSystemRequest,
    loading: systemsLoading,
  } = useApi<IApiResponse<ISystem>>(getSystem);

  const { data: companies, sendRequest: getCompanies, loading: companiesLoading } = useApi<IOrganization[]>(getRequest);

  const permissions = useAppSelector(getProfilePermission(ESidebarItemIds.dispatcherPanel));

  const isAdmin = useAppSelector(checkIsAdmin);

  const [activeTab, setActiveTab] = useState<string>(EDispatcherPanelTabs.table);

  const {
    data: devices,
    sendRequest: getDevices,
    loading: devicesLoading,
  } = useApi<IApiResponse<IDispatcherPanelDevice>>(postRequestWithArrayInParams);

  const [apiSettings, setApiSettings] = useState<IDispatcherPanelApiSettings<IDispatcherPanelDevice>>(
    getDispatcherPanelApiSettings()
  );
  const [dispatcherPanelApiParams, setDispatcherPanelApiParams] = useState<IDispatcherPanelApiParams>({
    statuses: [selectAllOptionKey],
  });
  const dispatcherPanelApiParamsRef = useRef<IDispatcherPanelApiParams>({
    statuses: [selectAllOptionKey],
  });

  const locationRef = useRef<YMapLocationRequest>(defaultLocation);

  const updateLocation = useCallback((newLocation: YMapLocationRequest) => {
    locationRef.current = newLocation;
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const updateDispatcherPanelApiParams = useCallback((apiParams = dispatcherPanelApiParamsRef.current) => {
    setDispatcherPanelApiParams(apiParams);
    dispatcherPanelApiParamsRef.current = apiParams;
  }, []);

  const requestData = useCallback(
    async (
      reqSettings: IDispatcherPanelApiSettings<IDispatcherPanelDevice> = apiSettings,
      apiParams = JSON.parse(JSON.stringify(dispatcherPanelApiParamsRef.current))
    ) => {
      setApiSettings(reqSettings);
      updateDispatcherPanelApiParams(apiParams);
      await getDevices(dispatcherPanelDevicesListUrl(), reqSettings, {
        params: {
          ...apiParams,
          statuses: apiParams?.statuses
            ? apiParams?.statuses[0] === selectAllOptionKey
              ? []
              : apiParams.statuses
            : [],
        },
      });
    },
    [apiSettings, getDevices, updateDispatcherPanelApiParams]
  );

  useEffect(() => {
    if (isAdmin) {
      getCompanies(companyUrl());
    } else {
      requestData();
      sendSystemRequest(getObjectSystemsListUrl(), { params: { count: 0, isOnlyIpSeries: true } });
    }
  }, [isAdmin]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      requestData();
    }, 30000);

    dispatch(setHeaderTitle('Ситуационная панель диспетчера'));

    return () => {
      clearInterval(interval);
    };
  }, []);

  const updateApiParams = useCallback(
    (data: IDispatcherPanelApiParams) => {
      requestData(apiSettings, data);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSearch = useCallback(
    (withSearchParams: boolean) => async (value: string) => {
      if (withSearchParams) {
        searchParams.set(EDispatcherPanelFilters.search, value);
        setSearchParams(searchParams);
      }
      const newApiSettings: IDispatcherPanelApiSettings<IDispatcherPanelDevice> = {
        ...apiSettings,
        page: 0,
      };
      const newApiParams: IDispatcherPanelApiParams = { ...dispatcherPanelApiParams, search: value };
      requestData(newApiSettings, newApiParams);
    },
    [apiSettings, dispatcherPanelApiParams, requestData, searchParams, setSearchParams]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IDispatcherPanelDevice>[]) => {
      const newApiSettings: IDispatcherPanelApiSettings<IDispatcherPanelDevice> = {
        ...apiSettings,
        sortFields: sortResults,
      };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnChangeSelect = useCallback(
    (name: string) => async (val: any) => {
      requestData(apiSettings, { ...dispatcherPanelApiParams, [name]: val });
    },
    [apiSettings, dispatcherPanelApiParams, requestData]
  );

  useEffect(() => {
    if (isAdmin) {
      if (dispatcherPanelApiParams?.companyId) {
        sendSystemRequest(getObjectSystemIdUrl(dispatcherPanelApiParams?.companyId), {
          params: { count: 0, isOnlyIpSeries: true },
        });
      }
    } else {
      sendSystemRequest(getObjectSystemsListUrl(), { params: { count: 0, isOnlyIpSeries: true } });
    }
  }, [dispatcherPanelApiParams?.companyId]);

  useEffect(() => {
    if (companies && companies[0]) {
      handleOnChangeSelect('companyId')(companies[0].id);
    }
  }, [companies]);

  const setValuesFromUrl = useCallback(
    (values: any) => {
      const newApiSettings: IDispatcherPanelApiSettings<IDispatcherPanelDevice> = {
        ...apiSettings,
        page: 0,
      };
      const statuses = (values[EDispatcherPanelFilters.statuses] || '')
        .split(',')
        .filter((item: string) => item !== ',');
      const newApiParams: IDispatcherPanelApiParams = {
        ...dispatcherPanelApiParams,
        search: values[EDispatcherPanelFilters.search] || '',
        objectId: values[EDispatcherPanelFilters.objectId] || '',
        statuses: statuses[0] === '' ? [selectAllOptionKey] : statuses,
      };
      if (values[EDispatcherPanelFilters.companyId]) {
        newApiParams.companyId = values[EDispatcherPanelFilters.companyId];
      }
      requestData(newApiSettings, newApiParams);
    },
    [apiSettings, dispatcherPanelApiParams, requestData]
  );

  const objectSelectItems = useMemo(
    () =>
      systems?.items?.map<ISelectOption>((system) => ({
        value: system.id || '',
        title: system.shortName || system.objectName || '',
      })),
    [systems?.items]
  );

  const objectTypesItems = useMemo(() => {
    const res: ISelectMultipleOption[] = [];
    for (const [key, value] of fromStatusToName as any) {
      if (key) {
        res.push({
          value: key,
          title: value,
        });
      }
    }
    res.push({ value: selectAllOptionKey, title: 'Все' });
    return res;
  }, []);

  const organizationOptions = useMemo(
    () =>
      isAdmin
        ? companies?.map<ISelectOption>((object) => ({
            value: object.id || '',
            title: object.companyName || '',
          }))
        : [],
    [companies, isAdmin]
  );

  const tabs = useMemo(
    () => (
      <Tabs
        activeTabKey={activeTab}
        onChangeActiveTab={setActiveTab}
        tabsClassName="dispatcher-panel__tabs"
        tabs={[
          {
            id: EDispatcherPanelTabs.table,
            title: 'Таблица',
            position: 0,
          },
          {
            id: EDispatcherPanelTabs.map,
            title: 'На карте',
            position: 1,
          },
        ]}
      />
    ),
    [activeTab]
  );

  const mainFilters = useMemo(
    () => [
      {
        type: EFilterTool.select,
        id: EDispatcherPanelFilters.objectId,
        props: {
          showClear: true,
          loading: systemsLoading,
          isAllOption: true,
          title: 'Объект',
          value: dispatcherPanelApiParams.objectId,
          onChange: handleOnChangeSelect('objectId'),
          options: objectSelectItems,
        },
      },
      {
        type: EFilterTool.multiSelect,
        id: EDispatcherPanelFilters.type,
        props: {
          isDisabledCheckboxes: true,
          isSelectedAllDefault: true,
          title: 'Тип оборудования',
          values: ['ip'],
          options: [
            {
              value: 'ip',
              title: 'IP',
            },
          ],
        },
      },
      {
        type: EFilterTool.multiSelect,
        id: EDispatcherPanelFilters.statuses,
        props: {
          isSelectedAllDefault: true,
          containerClassName: 'dispatcher-panel__multiselect',
          title: 'Статус',
          values: dispatcherPanelApiParams.statuses,
          options: objectTypesItems,
          onChange: handleOnChangeSelect('statuses'),
        },
      },
    ],
    [
      dispatcherPanelApiParams.objectId,
      dispatcherPanelApiParams.statuses,
      handleOnChangeSelect,
      objectSelectItems,
      objectTypesItems,
      systemsLoading,
    ]
  );

  const inputSearch = useMemo(
    () => ({
      type: EFilterTool.search,
      id: EDispatcherPanelFilters.search,
      props: {
        containerClassName: 'dispatcher-panel__search',
        placeholder: 'Поиск по объекту, оборудованию, серийному номеру',
        value: apiSettings.search,
        onSearch: handleOnSearch(false),
      },
    }),
    [apiSettings.search, handleOnSearch]
  );

  const orgSelect = useMemo(
    () => ({
      type: EFilterTool.select,
      id: EDispatcherPanelFilters.companyId,
      props: {
        showClear: true,
        title: 'Организация',
        value: dispatcherPanelApiParams?.companyId || '',
        onChange: handleOnChangeSelect('companyId'),
        options: organizationOptions,
        loading: companiesLoading,
      },
    }),
    [companiesLoading, dispatcherPanelApiParams?.companyId, handleOnChangeSelect, organizationOptions]
  );

  const renderContent = useCallback(() => {
    let result = null;
    switch (activeTab) {
      case EDispatcherPanelTabs.table: {
        result = (
          <div className="dispatcher-panel">
            <FiltersTool
              setValuesFromUrl={setValuesFromUrl}
              wrapperClassName="dispatcher-panel__tools"
              rows={[
                isAdmin
                  ? [
                      {
                        type: EFilterTool.extraContent,
                        props: {
                          children: tabs,
                        },
                      },
                      inputSearch,
                      orgSelect,
                    ]
                  : [{ ...inputSearch, hidden: true }],
                [
                  {
                    type: EFilterTool.extraContent,
                    hidden: !!isAdmin,
                    props: {
                      children: tabs,
                    },
                  },
                  ...mainFilters,
                ],
              ]}
            />
            <div className="dispatcher-panel__tools">
              {!isAdmin && (
                <DisablingPush permissions={permissions} className="dispatcher-panel__disable-push">
                  <InputSearch
                    containerClassName="dispatcher-panel__search"
                    placeholder="Поиск по объекту, оборудованию, серийному номеру"
                    value={dispatcherPanelApiParams.search}
                    onSearch={handleOnSearch(true)}
                  />
                </DisablingPush>
              )}
            </div>
            <div className="dispatcher-panel__content">
              <InformationPanel
                isAdmin={isAdmin}
                dispatcherPanelApiParams={dispatcherPanelApiParams}
                setDispatcherPanelApiParams={updateApiParams}
              />
              <DispatcherTable
                onSort={handleOnSort}
                sortOrders={apiSettings.sortFields}
                permissions={permissions}
                devices={devices?.items || []}
                loading={devicesLoading}
                pageSize={devices?.pageSize}
                total={devices?.totalCount}
                currentPage={devices?.page}
                onChangePage={handleOnChangeTablePage}
                isSearch={!!apiSettings.search}
                requestData={requestData}
              />
            </div>
          </div>
        );
        break;
      }
      case EDispatcherPanelTabs.map: {
        result = (
          <div className="dispatcher-panel">
            {!isAdmin && (
              <DisablingPush
                permissions={permissions}
                className="dispatcher-panel__disable-push dispatcher-panel__disable-push_mt"
              >
                {tabs}
              </DisablingPush>
            )}
            <FiltersTool
              setValuesFromUrl={setValuesFromUrl}
              wrapperClassName="dispatcher-panel__tools"
              rows={[
                isAdmin
                  ? [
                      {
                        type: EFilterTool.extraContent,
                        props: {
                          children: tabs,
                        },
                      },
                      orgSelect,
                    ]
                  : [],
                mainFilters,
              ]}
            />
            <div className="dispatcher-panel__content">
              <DispatcherPanelMap
                locationRef={locationRef.current}
                updateLocationRef={updateLocation}
                isAdmin={isAdmin}
                dispatcherPanelApiParams={dispatcherPanelApiParams}
                setDispatcherPanelApiParams={updateApiParams}
              />
            </div>
          </div>
        );
        break;
      }
      default: {
        break;
      }
    }

    return result;
  }, [
    activeTab,
    apiSettings.search,
    apiSettings.sortFields,
    devices,
    devicesLoading,
    dispatcherPanelApiParams,
    handleOnChangeTablePage,
    handleOnSearch,
    handleOnSort,
    inputSearch,
    isAdmin,
    mainFilters,
    orgSelect,
    permissions,
    tabs,
    requestData,
    setValuesFromUrl,
    updateApiParams,
    updateLocation,
  ]);

  return (
    <>
      <CommonHead seo={{ title: 'Ситуационная панель диспетчера' }} />
      {renderContent()}
    </>
  );
};

export default DispatcherPanel;
