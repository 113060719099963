import { Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { FC, useCallback } from 'react';
import { IContextMenu } from './types';

const ContextMenu: FC<IContextMenu> = ({ items = [], children, buttons, resetItems = () => {} }) => {
  const defaultItems: ItemType[] = [
    {
      key: 'add-children',
      label: 'Редактировать',
      children: [
        {
          key: 'edit-data',
          label: 'Редактировать данные объекта',
          onClick: () => console.error('edit'),
        },
        {
          key: 'edit-object-form',
          label: 'Редактировать форму объекта',
          onClick: () => console.error('edit-form'),
        },
      ],
    },
    {
      key: 'delete-object',
      label: 'Удалить объект',
      onClick: () => console.error('delete'),
    },
    {
      key: 'add-menu',
      label: 'Добавить',
      children: buttons,
    },
  ];

  const onChange = useCallback(
    (isShow: boolean) => {
      if (!isShow) {
        resetItems();
      }
    },
    [resetItems]
  );

  return (
    <Dropdown
      openClassName="schema-context-menu"
      className="schema-context-menu"
      overlayClassName="schema-context-menu"
      onVisibleChange={onChange}
      overlay={<Menu items={items} />}
      trigger={['contextMenu']}
    >
      {children}
    </Dropdown>
  );
};

export default ContextMenu;
