import { ICallCode } from '../../typings/systems/callCode';
import { axiosGet, axiosPost } from '../axios';
import { ICallCodeClearData, ICallCodeEditFlatsData } from './types';

export const editCallCode = async (url: string, data: ICallCode | ICallCodeEditFlatsData) => {
  const result = await axiosPost(url, data);
  return result.data;
};

export const clearCallCodes = async (url: string, data: ICallCodeClearData) => {
  const result = await axiosPost(url, data);
  return result.data;
};

export const getCallCodesErrors = async (url: string) => {
  const result = await axiosGet(url);
  return result.data;
};
