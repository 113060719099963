import { ETypeShapes } from '../../../../../systemTabs/schemaTab/types';
import { TPaperCircle, TPaperEvent } from '../../../../types';
import { createCircles, getPseudoCircle, getPseudoPath } from '../../../paperItems/items';
import { BaseCreator } from '..';
import { ECursors } from '../../../paperItems/types';
import { checkIntersectionForPath } from '../../../common';
import { pseudoPathStyle, pseudoPathStyleError } from '../../../../../../constants/paper';
import { TreeNodeType } from '../../../../../../typings/treeNode';

export class FinishedShape extends BaseCreator {
  override start = () => {
    this.setCopyActiveGroup(this.group, this.figureClickOnCopyObject);
  };

  protected onFigurePointDrag = (point: TPaperCircle) => (e: TPaperEvent) => {
    e.stopPropagation();
    point.position = point.position.add(e.delta);
  };

  protected figureClickOnCopyObject = (e: TPaperEvent) => {
    if (this.activePoint) {
      this.onClickPseudoObject();
    } else {
      const circle = createCircles([e.point], {
        onMouseEnter: () => {
          if (this.copyActiveGroup) {
            this.copyActiveGroup.data.blocked = true;
          }
          this.setCursor(ECursors.pointer);
        },
        onMouseLeave: () => {
          if (this.copyActiveGroup) {
            this.copyActiveGroup.data.blocked = false;
          }
          this.setCursor(ECursors.default);
        },
      })[0];
      circle.onMouseDrag = this.onFigurePointDrag(circle);
      this.points.push(circle);
      this.activePoint = circle;
    }
  };

  protected onClickPseudoObject = () => {
    if (this.pseudoObject && this.canBeCreated) {
      this.setPath(this.pseudoObject, this.wasClosed);
      this.activePoint = null;
    }
  };

  override mouseMove = (event: TPaperEvent) => {
    if (this.activePoint) {
      if (this.pseudoObject) {
        this.pseudoObject.remove();
      }
      if (this.copyActiveGroup && !this.copyActiveGroup.data.blocked) {
        if (this.mode.shape?.type === ETypeShapes.path) {
          this.pseudoObject = getPseudoPath(
            this.activePoint.position,
            event.point,
            this.mode.shape.points,
            {
              onClick: this.onClickPseudoObject,
              onMouseEnter: () => this.setCursor(ECursors.crosshair),
            },
            pseudoPathStyleError
          );
        } else {
          this.pseudoObject = getPseudoCircle(
            this.activePoint.position,
            event.point,
            {
              onClick: this.onClickPseudoObject,
              onMouseEnter: () => this.setCursor(ECursors.crosshair),
            },
            pseudoPathStyleError
          );
        }

        if (
          this.copyActiveGroup.data.key !== TreeNodeType.object &&
          (checkIntersectionForPath(this.copyActiveGroup, this.pseudoObject) ||
            this.pseudoObject.area >= this.copyActiveGroup.bounds.area)
        ) {
          this.canBeCreated = false;
        } else {
          this.canBeCreated = true;
          this.pseudoObject.set(pseudoPathStyle);
        }
      }
    }
  };
}
