import { Color, Point } from 'paper';
import {
  ICircleParams,
  ILabelPosition,
  IPolygonParams,
  IRectangleParams,
  TPaperStyle,
} from '../components/schemaCanvas/paper/paperItems/types';

export const rotateIcon = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
<path fill="#0023a2" d="M16 7v-4l-1.1 1.1c-1.3-2.5-3.9-4.1-6.9-4.1-4.4 0-8 3.6-8 8s3.6 8 8 8c2.4 0 4.6-1.1 6-2.8l-1.5-1.3c-1.1 1.3-2.7 2.1-4.5 2.1-3.3 0-6-2.7-6-6s2.7-6 6-6c2.4 0 4.5 1.5 5.5 3.5l-1.5 1.5h4z"></path>
</svg>`;

export enum EColors {
  lightBlue = '#EFF3FF',
  blue = '#94a6e7',
  darkBlue = '#1444f2',
  blackBlue = '#0023a2',
  black = '#000000',
  white = '#FFFFFF',
  red = '#F00000',
  lightGreen = '#e7ffe7',
  yellow = '#FFB800',
  gray = '#949494',
  green = '#48c80b',
}

export const paperColorToValue = new Map<string, EColors>([
  ['red', EColors.red],
  ['green', EColors.green],
  ['yellow', EColors.yellow],
  ['blue', EColors.darkBlue],
  ['gray', EColors.gray],
]);

export const polygonParams: IPolygonParams = {
  title: '',
  needLabel: true,
  labelOffset: {
    x: -50,
    y: 50,
  },
};

export const accessZoneParams: IRectangleParams = {
  from: new Point(0, 0),
  to: new Point(700, 700),
  title: '',
  needLabel: true,
  labelOffset: {
    x: -50,
    y: 50,
  },
};

export const buildingParams: IRectangleParams = {
  from: new Point(0, 0),
  to: new Point(50, 50),
  title: '',
  needLabel: true,
  labelOffset: {
    x: -20,
    y: 30,
  },
};

export const accessPointParams: ICircleParams = {
  center: new Point(0, 0),
  radius: 20,
  title: '',
  needLabel: true,
  labelOffset: {
    x: 0,
    y: 4,
  },
};

export const defaultLabelOffset: ILabelPosition = { x: 0, y: 0 };

export const evenStyle: TPaperStyle = {
  strokeColor: new Color(EColors.blue),
  fillColor: new Color(EColors.lightBlue),
  strokeWidth: 1,
  dashArray: [],
};

export const oddStyle: TPaperStyle = {
  strokeColor: new Color(EColors.blue),
  fillColor: new Color(EColors.white),
  strokeWidth: 1,
  dashArray: [],
};

export const circleStyle: TPaperStyle = {
  strokeColor: new Color(EColors.darkBlue),
  fillColor: new Color(EColors.white),
  strokeWidth: 2,
};

export const circleBlueStyle: TPaperStyle = {
  strokeColor: new Color(EColors.darkBlue),
};

export const circleNullStyle: TPaperStyle = {
  strokeColor: null,
};

export const circleGreenStyle: TPaperStyle = {
  strokeColor: new Color(EColors.green),
};

export const sectionStyle: TPaperStyle = { ...oddStyle, fillColor: new Color(EColors.lightGreen) };

export const defaultTextStyles: TPaperStyle = {
  fillColor: new Color(EColors.black),
  fontFamily: 'SF Pro Display',
  fontWeight: 400,
  fontSize: 14,
  justification: 'left',
};

export const defaultAccessPointTextStyles: TPaperStyle = {
  ...defaultTextStyles,
  justification: 'center',
};

export const defaultPointStyle: TPaperStyle = {
  strokeColor: new Color(EColors.black),
  fillColor: new Color(EColors.white),
  strokeWidth: 1,
};

export const activePointStyle: TPaperStyle = {
  strokeColor: new Color(EColors.darkBlue),
  fillColor: new Color(EColors.darkBlue),
};

export const pointInPathStyle: TPaperStyle = {
  strokeColor: new Color(EColors.darkBlue),
  fillColor: new Color(EColors.white),
};

export const closedPathStyle: TPaperStyle = {
  fillColor: new Color(EColors.lightBlue),
};

export const pathStyle: TPaperStyle = {
  strokeWidth: 2,
  strokeColor: new Color(EColors.darkBlue),
};

export const pseudoPathStyle: TPaperStyle = {
  strokeWidth: 2,
  strokeColor: new Color(EColors.darkBlue),
  dashArray: [5, 5],
};

export const pseudoPathStyleError: TPaperStyle = {
  ...pseudoPathStyle,
  strokeColor: new Color(EColors.red),
};

export const editPathStyle: TPaperStyle = {
  strokeWidth: 3,
  strokeColor: new Color(EColors.darkBlue),
  fillColor: new Color(EColors.lightBlue),
};

export const selectedPathStyle: TPaperStyle = {
  strokeWidth: 1,
  strokeColor: new Color(EColors.blackBlue),
};

export const selectedCircleStyle: TPaperStyle = {
  fillColor: new Color(EColors.blackBlue),
};

export const pseudoChildStyle: TPaperStyle = {
  fillColor: new Color(EColors.red),
  strokeColor: new Color(EColors.red),
  strokeWidth: 0,
};

export const pseudoCloneStyle: TPaperStyle = {
  fillColor: new Color(EColors.red),
  strokeColor: new Color(EColors.red),
  strokeWidth: 0.1,
};
