import React, { FC } from 'react';

const AccessDoor: FC = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.5714 25H21V0.571408C21 0.314265 20.8286 0.142853 20.5715 0.142853H3.42854C3.1714 0.142853 2.99999 0.314265 2.99999 0.571408V25H0.428555C0.171412 25 0 25.1714 0 25.4286C0 25.6857 0.171412 25.8572 0.428555 25.8572H3.42854H5.14281H18.8571H20.5714H23.5714C23.8286 25.8572 24 25.6858 24 25.4286C24 25.1715 23.8286 25 23.5714 25ZM5.57142 25V2.71429H18.4286V25H5.57142ZM19.2857 25V2.28573C19.2857 2.02859 19.1143 1.85717 18.8572 1.85717H5.14286C4.88572 1.85717 4.71431 2.02859 4.71431 2.28573V25.0001H3.85715V1.00001H20.1429V25.0001H19.2857V25Z"
      fill="#1444F2"
    />
    <path
      d="M17.1424 12.5713H15.4281C15.171 12.5713 14.9996 12.7427 14.9996 12.9998V13.4284H14.1424C13.8853 13.4284 13.7139 13.5998 13.7139 13.857C13.7139 14.1141 13.8853 14.2855 14.1424 14.2855H14.9996V14.7141C14.9996 14.9712 15.171 15.1426 15.4281 15.1426H17.1424C17.3996 15.1426 17.571 14.9712 17.571 14.7141V12.9998C17.571 12.7427 17.3996 12.5713 17.1424 12.5713ZM16.7139 14.2856H15.8567V13.4284H16.7139V14.2856Z"
      fill="#1444F2"
    />
  </svg>
);

export default AccessDoor;
