export const profileNameToShortString = (name: {
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
}) =>
  [
    name.lastName || '',
    name.firstName ? `${name.firstName.charAt(0).toUpperCase()}.` : '',
    name.middleName ? `${name.middleName?.charAt(0).toUpperCase()}.` : '',
  ]
    .filter(Boolean)
    .join(' ');
