import { IApiError } from '../../typings/api';
import { IProfilePermission } from '../../typings/profile';
import { ISubscriber } from '../../typings/subscribers';

export enum SubscriberEditMode {
  viewing = 'viewing',
  edit = 'edit',
  create = 'create',
}

export interface ISubscriberTabComponent {
  subscriber?: ISubscriber;
  onChangeTab?: (id: string) => void;
  editMode: SubscriberEditMode;
  onNextTab?: (tabId?: string) => void;
  onSave?: (subscriber: ISubscriber, tabId?: string) => void;
  onDone?: () => void;
  nextButtonText?: string;
  nextTabId?: string;
  resetNextTabId?: () => void;
  wasChange?: boolean;
  setWasChange?: (value: boolean) => void;
  isActiveTab?: boolean;
  requestSaveLoading?: boolean;
  requestSaveError?: IApiError;
  permissions?: IProfilePermission;
  onChangeDisableAllTabs?: (disabled: boolean) => void;
}
