import { ISettings } from '../../../../typings/profile';
import { ESystemBaseDeviceSeries } from '../../../../typings/systems/base';
import { IAccessPoint, AccessPointType, ITreeNode } from '../../../../typings/treeNode';
import { ISelectedSchemaEntity } from '../types';

export interface IAccessPointModal {
  settings?: ISettings;
  schema?: ITreeNode[];
  parent?: ISelectedSchemaEntity;
  isOpen?: boolean;
  editObject?: null | IAccessPoint;
  getLayout?: (newPoint: IAccessPoint, oldLayout?: string | null) => string;
  onEdit?: (point: IAccessPoint) => void;
  onCreate?: (point: IAccessPoint) => void;
  onCancel?: () => void;
  objectBaseDeviceSeries?: ESystemBaseDeviceSeries;
  isFiveThousandth?: boolean;
}

export interface IPointIcon {
  type: AccessPointType;
  icon: JSX.Element | null;
}

export enum EAccessPointDeviceCategory {
  callBlock = 'b923fe31-a23b-4731-8aaf-2e9875eb6f00',
  keyDevice = 'b923fe31-a23b-4731-8aaf-2e9875eb6f01',
}
