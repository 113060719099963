import { Table, TableProps } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import classNames from 'classnames';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import { paths } from '../../../constants/paths';
import { IDispatcherPanelTable } from './types';
import { IDispatcherPanelDevice, fromStatusToColor } from '../../../typings/dispatcherPanel';
import { IApiSortField } from '../../../typings/api';
import Tooltip from '../../ui/tooltip';
import InfoIcon from '../../../assets/svg/icons/info';
import NotifyIcon from '../../../assets/svg/icons/notify';

const DispatcherTable: FC<IDispatcherPanelTable> = (props) => {
  const {
    onSort = () => {},
    devices = [],
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    isSearch = false,
    sortOrders = [],
  } = props;

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id: string) => {
      navigate(`${paths.dispatcherPanel}/${id}`);
    },
    [navigate]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IDispatcherPanelDevice> | null) =>
      flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null,
    []
  );

  const handleOnChangeTable: TableProps<IDispatcherPanelDevice>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IDispatcherPanelDevice> | SorterResult<IDispatcherPanelDevice>[],
      extra: TableCurrentDataSource<IDispatcherPanelDevice>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IDispatcherPanelDevice>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IDispatcherPanelDevice,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IDispatcherPanelDevice,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const columns = useMemo<ColumnsType<IDispatcherPanelDevice>>(
    () => [
      {
        title: 'Объект',
        dataIndex: 'object',
        key: 'object',
        render: (_: any, record: IDispatcherPanelDevice) => (
          <div className="editable-table__data-container">{record.objectName}</div>
        ),
      },
      {
        title: 'Оборудование',
        dataIndex: 'deviceName',
        key: 'deviceName',
        sorter: {
          multiple: 0,
        },
        sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'deviceName') || null) as SortOrder,
        render: (_: any, record: IDispatcherPanelDevice) => (
          <div className="dispatcher-panel-table__device-name">
            <div
              className={`dispatcher-panel-table__device-name-label dispatcher-panel__status dispatcher-panel__status_${fromStatusToColor.get(
                record.status
              )}`}
            >
              {record.deviceName}
            </div>
            <Tooltip title={record.address} placement="bottom">
              <div className="dispatcher-panel-table__device-name-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        title: 'Тип',
        dataIndex: 'type',
        key: 'type',
        render: () => <div className="editable-table__data-container">IP</div>,
      },
      {
        title: 'Ошибки',
        dataIndex: 'errors',
        key: 'errors',
        render: (_: any, record: IDispatcherPanelDevice) => (
          <div className="editable-table__data-container">{record.errors}</div>
        ),
      },
      {
        title: 'Предупреждения',
        dataIndex: 'warnings',
        key: 'warnings',
        render: (_: any, record: IDispatcherPanelDevice) => (
          <div className="editable-table__data-container">{record.warnings}</div>
        ),
      },
      {
        render: (_: any, record: IDispatcherPanelDevice) => (
          <div className="editable-table__icon-container">
            <div
              className={classNames('editable-table__icon dispatcher-panel-table__notify', {
                'dispatcher-panel-table__notify_active': record.hasNotification,
                'dispatcher-panel-table__notify_error': record.notificationIsExpired,
              })}
            >
              <Tooltip
                title={
                  record.hasNotification && record.notificationIsExpired
                    ? 'Срок напоминания прошел, статус: не решено'
                    : record.hasNotification
                    ? 'Напоминание назначено, статус: не решено'
                    : ''
                }
                placement="rightBottom"
              >
                <div>
                  <NotifyIcon />
                </div>
              </Tooltip>
            </div>
          </div>
        ),
      },
    ],
    [getColumnSortOrder, sortOrders]
  );

  const data = useMemo(
    () =>
      devices.map((item, index) => ({
        ...item,
        key: index,
      })),
    [devices]
  );

  return (
    <div className="dispatcher-panel-table editable-table">
      <Table
        onChange={handleOnChangeTable}
        onRow={(record) => ({
          onClick: () => onRowClick(record.deviceId || ''),
        })}
        columns={columns}
        dataSource={data}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination dispatcher-panel-table_absolute',
        }}
      />
    </div>
  );
};

export default DispatcherTable;
