import { AxiosRequestConfig } from 'axios';
import { axiosGet } from '../axios';

export const getDeviceEventEntity = async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};
