import { IApiSettings } from '../../typings/api';
import { IFlat } from '../../typings/systems/flat';

export const FLATS_TABLE_COUNT = 10;

export const sectionFlatsInitApiSettings: IApiSettings<IFlat> = {
  sortFields: [
    {
      field: 'number',
      descending: false,
    },
  ],
  page: 0,
  count: FLATS_TABLE_COUNT,
  search: '',
};
