import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Tabs as TabsComponent } from 'antd';
import { ITab, ITabs } from './types';
import Tooltip from '../ui/tooltip';
import ChevronLeft from '../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../assets/svg/icons/chevronRight';

const defaultOnChangeActiveTab = (key: string, tabs: ITab[]): ITab => tabs.find((tab) => tab.id === key) || tabs[0];

const Tabs: FC<ITabs> = (props) => {
  const {
    tabs,
    activeTabKey = tabs[0].id,
    onChangeActiveTab,
    containerClassName = '',
    tabsClassName = '',
    tabsPaneClassName = '',
    showTabNumber = false,
    tabBarExtraContent = null,
  } = props;
  const [activeKey, setActiveKey] = useState<string>(activeTabKey);
  const tabsRef = useRef<HTMLDivElement | null>(null);
  const scrollElementRef = useRef<HTMLDivElement | null>(null);

  const chevronLeftRef = useRef<HTMLDivElement | null>(null);
  const chevronLefIsHidden = useRef<boolean>(true);
  const chevronRightRef = useRef<HTMLDivElement | null>(null);
  const chevronRightIsHidden = useRef<boolean>(true);

  const showButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && ref.current) {
      element.classList.remove('tabs__tabs-button_hide');
      ref.current = false;
    }
  }, []);

  const hideButton = useCallback((element: HTMLDivElement | null, ref: React.MutableRefObject<boolean>) => {
    if (element && !ref.current) {
      element.classList.add('tabs__tabs-button_hide');
      ref.current = true;
    }
  }, []);

  useEffect(() => {
    setActiveKey(activeTabKey);
  }, [activeTabKey]);

  useEffect(() => {
    if (!scrollElementRef.current && tabsRef.current) {
      const element = tabsRef.current.getElementsByClassName('ant-tabs-nav-list')[0];
      if (element) {
        scrollElementRef.current = element as HTMLDivElement;
        if (tabsRef.current.offsetWidth < element.scrollWidth && chevronRightRef) {
          showButton(chevronRightRef.current, chevronRightIsHidden);
        }
      }
    }
  }, [tabsRef, tabsRef.current]);

  const handleOnChangeTab = useCallback(
    (key: string) => {
      if (onChangeActiveTab) {
        onChangeActiveTab(key);
      } else {
        setActiveKey(defaultOnChangeActiveTab(key, tabs).id);
      }
    },
    [onChangeActiveTab, tabs]
  );

  const getTabTitle = useCallback(
    (title: string, tabNumber: number) => (showTabNumber ? `${tabNumber}. ${title}` : title),
    [showTabNumber]
  );

  const getOffsetValue = useCallback((): number => {
    if (scrollElementRef.current) {
      const style = window.getComputedStyle(scrollElementRef.current);
      const matrix = new DOMMatrixReadOnly(style.transform);
      return matrix.m41;
    }
    return 0;
  }, [scrollElementRef]);

  const onScroll = useCallback(() => {
    if (scrollElementRef.current && tabsRef.current) {
      const offset = getOffsetValue();
      const gap = tabsRef.current.scrollWidth - scrollElementRef.current.scrollWidth;
      if (offset > -20) {
        showButton(chevronRightRef.current, chevronRightIsHidden);
        hideButton(chevronLeftRef.current, chevronLefIsHidden);
      } else if (offset - 50 < gap) {
        hideButton(chevronRightRef.current, chevronRightIsHidden);
        showButton(chevronLeftRef.current, chevronLefIsHidden);
      } else {
        showButton(chevronRightRef.current, chevronRightIsHidden);
        showButton(chevronLeftRef.current, chevronLefIsHidden);
      }
    }
  }, [getOffsetValue, hideButton, showButton]);

  const scrollTo = useCallback(
    (toRight = true) =>
      (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (scrollElementRef.current && tabsRef.current) {
          const gap = tabsRef.current.scrollWidth - scrollElementRef.current.scrollWidth;
          const offset = getOffsetValue();
          let translate = offset + 150 * (toRight ? -1 : 1);

          if (toRight) {
            if (translate < gap) {
              translate = gap;
              hideButton(chevronRightRef.current, chevronRightIsHidden);
              showButton(chevronLeftRef.current, chevronLefIsHidden);
            } else {
              showButton(chevronLeftRef.current, chevronLefIsHidden);
            }
          } else if (!toRight) {
            if (translate >= 0) {
              translate = 0;
              hideButton(chevronLeftRef.current, chevronLefIsHidden);
              showButton(chevronRightRef.current, chevronRightIsHidden);
            } else {
              showButton(chevronRightRef.current, chevronRightIsHidden);
            }
          }

          scrollElementRef.current.style.transform = `translate(${translate}px, 0px)`;
        }
      },
    [getOffsetValue, hideButton, showButton]
  );

  const OperationsSlot = {
    left: (
      <div
        ref={chevronLeftRef}
        role="presentation"
        onClick={scrollTo(false)}
        className="tabs__tabs-button tabs__tabs-button_left tabs__tabs-button_hide"
      >
        <ChevronLeft />
      </div>
    ),
    right: (
      <div
        ref={chevronRightRef}
        role="presentation"
        onClick={scrollTo()}
        className="tabs__tabs-button tabs__tabs-button_right tabs__tabs-button tabs__tabs-button_hide"
      >
        <ChevronRight />
      </div>
    ),
  };

  return (
    <div ref={tabsRef} className={classNames('tabs', containerClassName)}>
      <TabsComponent
        tabBarExtraContent={tabBarExtraContent || OperationsSlot}
        activeKey={activeKey}
        onChange={handleOnChangeTab}
        tabBarGutter={0}
        className={classNames('tabs__tabs', tabsClassName)}
        onTabScroll={onScroll}
      >
        {tabs.map((tab, index) => (
          <TabsComponent.TabPane
            key={tab.id}
            className={classNames('tabs__tab', tabsPaneClassName)}
            tab={
              <Tooltip title={tab.disabled ? tab.notification : ''} placement="bottom" {...tab.tooltipProps}>
                <span className="tabs__tab-title">{getTabTitle(tab.title, index + 1)}</span>
              </Tooltip>
            }
            disabled={tab.disabled}
          >
            {tab.children}
          </TabsComponent.TabPane>
        ))}
      </TabsComponent>
    </div>
  );
};

export default Tabs;
