import { Status } from '../../typings/status';

export enum StatusIconColor {
  green = 'green',
  red = 'red',
  gray = 'gray',
  yellow = 'yellow',
}

export interface IStatusSelectOption {
  value: Status | string;
  title: string;
  color?: StatusIconColor;
}

export interface IStatusSelect {
  isAllOption?: boolean;
  title?: string;
  value?: any;
  onChange?: (value: Status | string) => void;
  options?: IStatusSelectOption[];
  containerClassName?: string;
  contentClassName?: string;
  iconClassName?: string;
  textClassName?: string;
  disabled?: boolean;
  isDisabledStyle?: boolean;
}
