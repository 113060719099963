import React, { FC } from 'react';

const BellOff: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1659 3.83333V4.23165C7.44707 5.1926 5.49919 7.78549 5.49919 10.8333V14.2095L4.76624 15.8587C4.6354 16.1531 4.76799 16.4978 5.06239 16.6286C5.35678 16.7595 5.70151 16.6269 5.83236 16.3325L6.61558 14.5702L6.66585 14.3333V10.8333C6.66585 7.61167 9.27752 5 12.4992 5C13.4323 5 14.3327 5.21884 15.145 5.63298C15.432 5.77933 15.7833 5.66529 15.9296 5.37828C16.076 5.09127 15.9619 4.73997 15.6749 4.59363C15.4019 4.45445 15.1206 4.33362 14.8325 4.23178V3.83333C14.8325 2.54467 13.7879 1.5 12.4992 1.5C11.2105 1.5 10.1659 2.54467 10.1659 3.83333ZM15.8004 20.1669C15.3132 21.5473 14.0007 22.5003 12.4997 22.5003C10.9954 22.5003 9.68465 21.5438 9.19886 20.1669H8.41634C8.09417 20.1669 7.83301 19.9058 7.83301 19.5836C7.83301 19.2614 8.09417 19.0003 8.41634 19.0003H17.6592C17.9041 19.0003 18.1462 18.9489 18.37 18.8494C19.2532 18.4569 19.6509 17.4227 19.2584 16.5395L18.3833 14.5705L18.333 14.3336V10.8336C18.333 10.2996 18.2614 9.77567 18.1217 9.27153C18.0356 8.96107 18.2175 8.63963 18.528 8.55357C18.8384 8.46751 19.1599 8.64943 19.2459 8.95989C19.4137 9.56529 19.4997 10.194 19.4997 10.8336V14.2098L20.3245 16.0657C20.9787 17.5377 20.3158 19.2613 18.8438 19.9156C18.4709 20.0813 18.0673 20.1669 17.6592 20.1669H15.8004ZM14.521 20.167H10.4775C10.8844 20.8709 11.6452 21.3337 12.4993 21.3337C13.3519 21.3337 14.1135 20.8723 14.521 20.167ZM13.6663 3.93053V3.83366C13.6663 3.18933 13.144 2.66699 12.4997 2.66699C11.8553 2.66699 11.333 3.18933 11.333 3.83366V3.93044C11.7124 3.86679 12.1022 3.83366 12.4997 3.83366C12.8941 3.83366 13.284 3.86637 13.6663 3.93053ZM22.8291 1.67085C22.6013 1.44305 22.232 1.44305 22.0042 1.67085L2.17085 21.5042C1.94305 21.732 1.94305 22.1013 2.17085 22.3291C2.39866 22.557 2.76801 22.557 2.99581 22.3291L22.8291 2.49581C23.057 2.26801 23.057 1.89866 22.8291 1.67085Z"
      fill="#949494"
    />
  </svg>
);

export default BellOff;
