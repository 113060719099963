import React, { FC } from 'react';

const CloseBigIcon: FC = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39052 4.89052C4.91122 4.36983 5.75544 4.36983 6.27614 4.89052L16 14.6144L25.7239 4.89052C26.2446 4.36983 27.0888 4.36983 27.6095 4.89052C28.1302 5.41122 28.1302 6.25544 27.6095 6.77614L17.8856 16.5L27.6095 26.2239C28.1302 26.7446 28.1302 27.5888 27.6095 28.1095C27.0888 28.6302 26.2446 28.6302 25.7239 28.1095L16 18.3856L6.27614 28.1095C5.75544 28.6302 4.91122 28.6302 4.39052 28.1095C3.86983 27.5888 3.86983 26.7446 4.39052 26.2239L14.1144 16.5L4.39052 6.77614C3.86983 6.25544 3.86983 5.41122 4.39052 4.89052Z"
    />
  </svg>
);

export default CloseBigIcon;
