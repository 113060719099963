import { Status } from '../../typings/status';
import { IStatusSelectOption, StatusIconColor } from './types';

export const defaultSystemStatuses: IStatusSelectOption[] = [
  {
    value: Status.active,
    title: 'Активен',
    color: StatusIconColor.green,
  },
  {
    value: Status.inactive,
    title: 'Неактивен',
    color: StatusIconColor.red,
  },
  {
    value: Status.inProgress,
    title: 'Настройка',
    color: StatusIconColor.yellow,
  },
  {
    value: Status.draft,
    title: 'Черновик',
    color: StatusIconColor.gray,
  },
];
