import React, { FC } from 'react';
import Input from '../../../ui/input';
import { InputType } from '../../../ui/input/types';
import { SubscriberEditMode } from '../../types';
import { ISubscriberData } from './types';
import MaskInput from '../../../ui/input/maskInput';

const SubscriberData: FC<ISubscriberData> = (props) => {
  const {
    editMode = SubscriberEditMode.viewing,
    firstName,
    lastName,
    middleName,
    shortName,
    phoneNumber,
    email,
    disabled = false,
  } = props;

  return (
    <div className="subscriber-data">
      {editMode !== SubscriberEditMode.viewing && (
        <>
          <Input
            title="Фамилия"
            placeholder="Фамилия абонента"
            isRequired
            value={lastName?.value}
            onChange={lastName?.onChange}
            maxLength={100}
            inputClassName="subscriber-data__input"
            status={lastName?.status}
            errorText={lastName?.errorText}
            disabled={disabled}
          />
          <Input
            title="Форма обращения"
            placeholder="Как обращаться к абоненту"
            value={shortName?.value}
            onChange={shortName?.onChange}
            maxLength={20}
            inputClassName="subscriber-data__input"
            disabled={disabled}
          />
          <Input
            title="Имя"
            placeholder="Имя абонента"
            isRequired
            value={firstName?.value}
            onChange={firstName?.onChange}
            maxLength={100}
            inputClassName="subscriber-data__input"
            status={firstName?.status}
            errorText={firstName?.errorText}
            disabled={disabled}
          />
          <MaskInput
            title="Телефон"
            placeholder="+7 (123) 456 78 89"
            isRequired
            value={phoneNumber?.value}
            onChange={phoneNumber?.onChange}
            inputClassName="subscriber-data__input"
            status={phoneNumber?.status}
            errorText={phoneNumber?.errorText}
            disabled={disabled}
          />
          <Input
            title="Отчество"
            placeholder="Отчество абонента"
            value={middleName?.value}
            onChange={middleName?.onChange}
            maxLength={100}
            inputClassName="subscriber-data__input"
            disabled={disabled}
          />
          <Input
            title="E–mail"
            placeholder="yourname@mail.ru"
            isRequired
            value={email?.value}
            onChange={email?.onChange}
            maxLength={100}
            inputClassName="subscriber-data__input"
            inputType={InputType.email}
            status={email?.status}
            errorText={email?.errorText}
            disabled={disabled}
          />
        </>
      )}
      {editMode === SubscriberEditMode.viewing && (
        <>
          <Input
            title="ФИО"
            placeholder="ФИО"
            value={`${lastName?.value} ${firstName?.value} ${middleName?.value}`}
            inputClassName="subscriber-data__input"
            disabled
          />
          <MaskInput
            title="Телефон"
            placeholder="+7 (123) 456 78 89"
            value={phoneNumber?.value}
            inputClassName="subscriber-data__input"
            disabled
          />
          <Input
            title="Форма обращения"
            placeholder="Как обращаться к абоненту"
            value={shortName?.value}
            inputClassName="subscriber-data__input"
            disabled
          />
          <Input
            title="E–mail"
            placeholder="yourname@mail.ru"
            value={email?.value}
            inputClassName="subscriber-data__input"
            disabled
          />
        </>
      )}
    </div>
  );
};

export default SubscriberData;
