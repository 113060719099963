import { IDispatcherPanelApiParams } from '../../../api/dispatcherPanel/types';
import { selectAllOptionKey } from '../../../constants/select';
import { IDispatcherPanelAccessPoint, IDispatcherPanelObject } from '../../../typings/dispatcherPanel';
import { objectInfoDefaultStatuses } from './config';
import { IInformationPanelStatus } from './types';

export const getDevicesNumber = (statuses: IInformationPanelStatus[]) =>
  statuses?.reduce((sum, current) => sum + current.value, 0) || 0;

export const getPercent = (val = 0, number = 1): number => (100 * val) / number;

export const updateStatuses = (object: IDispatcherPanelObject, apiParams: IDispatcherPanelApiParams) => {
  const copyStatuses: IInformationPanelStatus[] = JSON.parse(JSON.stringify(objectInfoDefaultStatuses));

  const newStatuses =
    !apiParams?.statuses || apiParams?.statuses?.length === 0 || apiParams?.statuses[0] === selectAllOptionKey
      ? copyStatuses
      : copyStatuses.filter((elem) => apiParams?.statuses?.includes(elem.id));

  newStatuses.forEach((element) => {
    element.value = +object[element.fieldName as keyof IDispatcherPanelObject] || 0;
    element.objectNames = (object[element.objectNamesField as keyof IDispatcherPanelObject] ||
      []) as IDispatcherPanelAccessPoint[];
  });

  return newStatuses;
};
