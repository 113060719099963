import React, { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { checkIsAdmin } from '../../store/selectors/profile';
import { useApi } from '../../hooks/useApi';
import { postRequest } from '../../api';
import { setHeaderTitle } from '../../store/slices/header';
import CommonHead from '../../components/commonHead';
import { getFlatsUrl } from '../../constants/api';
import { IApiResponse, IApiSortField } from '../../typings/api';
import { IApartmentApiParams, IApartmentApiSettings } from '../../api/apartments/types';
import { getApartmentApiSettings } from '../../api/apartments/config';
import { IFlatsPageFlat } from '../../typings/flat';
import FlatsPageFilters from '../../components/flats/flatsFilters';
import FlatsPageTable from '../../components/flats/flatsTable';
import { EFlatPaymentStatus } from './types';

const FlatsPage: FC = () => {
  const dispatch = useAppDispatch();

  const isAdmin = useAppSelector(checkIsAdmin);

  const {
    data: flats,
    sendRequest: getFlats,
    loading: flatsLoading,
  } = useApi<IApiResponse<IFlatsPageFlat>>(postRequest);

  const [flatsArr, setFlatsArr] = useState<IFlatsPageFlat[]>();

  const [apiSettings, setApiSettings] = useState<IApartmentApiSettings<IFlatsPageFlat>>(
    getApartmentApiSettings('number')
  );
  const [eventsApiParams, setEventsApiParams] = useState<IApartmentApiParams>({ payStatus: EFlatPaymentStatus.all });

  useEffect(() => {
    setFlatsArr(flats?.items || []);
  }, [flats]);

  const requestData = useCallback(
    async (settings = apiSettings, events = eventsApiParams) => {
      setApiSettings(settings);
      setEventsApiParams(events);
      if (events.objectId) {
        await getFlats(getFlatsUrl(events.objectId || ''), settings, { params: events });
      }
    },
    [apiSettings, eventsApiParams, getFlats]
  );

  useEffect(() => {
    dispatch(setHeaderTitle('Квартиры'));
  }, []);

  const handleOnChangeTablePage = useCallback(
    async (page: number) => {
      const newApiSettings = { ...apiSettings, page: page - 1 };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  const handleOnSort = useCallback(
    async (sortResults: IApiSortField<IFlatsPageFlat>[]) => {
      const newApiSettings: IApartmentApiSettings<IFlatsPageFlat> = { ...apiSettings, sortFields: sortResults };
      await requestData(newApiSettings);
    },
    [apiSettings, requestData]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Квартиры' }} />
      <div className="flats-list">
        <FlatsPageFilters
          isAdmin={isAdmin}
          requestData={requestData}
          apiSettings={apiSettings}
          eventsApiParams={eventsApiParams}
          setEventsApiParams={setEventsApiParams}
          setFlatsArr={setFlatsArr}
        />
        <FlatsPageTable
          onSort={handleOnSort}
          sortOrders={apiSettings.sortFields}
          flats={flatsArr}
          loading={flatsLoading}
          pageSize={flats?.pageSize}
          total={flatsArr?.length === 0 ? 0 : flats?.totalCount}
          currentPage={flats?.page}
          onChangePage={handleOnChangeTablePage}
          isSearch={!!apiSettings.search}
          eventsApiParams={eventsApiParams}
        />
      </div>
    </>
  );
};

export default FlatsPage;
