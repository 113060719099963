import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosError } from 'axios';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IChangePriorityModal } from './types';
import { ISelectOption } from '../../ui/select/types';
import { mapFeedbackPriorityToText } from '../../../typings/feedback';
import Select from '../../ui/select';
import { postRequest } from '../../../api';
import { feedbackPriorityUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';

const ChangePriorityModal: FC<IChangePriorityModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, defaultPriority = '', onOk = () => {}, feedbackId } = props;

  const { sendRequest: changePriority, loading: changePriorityLoading } = useApi(postRequest);

  const [priority, setPriority] = useState<string>(defaultPriority);

  useEffect(() => {
    setPriority(defaultPriority);
  }, [defaultPriority]);

  const onSave = useCallback(async () => {
    const resError = (await changePriority(feedbackPriorityUrl(feedbackId || ''), {
      priority,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    }
  }, [changePriority, feedbackId, onOk, priority]);

  const priorityOptions = useMemo(() => {
    const result: ISelectOption[] = [];
    mapFeedbackPriorityToText.forEach((item, key) =>
      result.push({
        value: key,
        title: item,
      })
    );
    return result;
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Изменение приоритета"
      width={416}
      showCloseIcon
      wrapClassName="change-priority__wrapper"
      centered
      footer={
        <div className="change-priority__button-container">
          <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onCancel} disabled={changePriorityLoading}>
            Отмена
          </Button>
          <Button disabled={!priority || changePriorityLoading} size={ButtonSize.small} onClick={onSave}>
            Сохранить
          </Button>
        </div>
      }
    >
      <Select
        title="Приоритет"
        value={priority}
        onChange={(e) => setPriority(e.toString())}
        options={priorityOptions}
      />
    </Modal>
  );
};

export default ChangePriorityModal;
