import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getHelpEntity } from '../../../../api/help';
import ArrowBackIcon from '../../../../assets/svg/icons/arrowBack';
import SearchFailIcon from '../../../../assets/svg/icons/searchFail';
import CommonHead from '../../../../components/commonHead';
import ButtonDownload from '../../../../components/ui/buttonDownload';
import ButtonLink from '../../../../components/ui/buttonLink';
import ErrorPlaceholder from '../../../../components/ui/errorPlaceholder';
import Loader from '../../../../components/ui/loader';
import { ELoaderColor } from '../../../../components/ui/loader/types';
import { getHelpInstructionFile, getHelpInstructionsQuestion } from '../../../../constants/api';
import { paths } from '../../../../constants/paths';
import { useAppDispatch } from '../../../../hooks/hooks';
import { useApi } from '../../../../hooks/useApi';
import { setHeaderTitle } from '../../../../store/slices/header';
import { IInstructionsItem } from '../../../../typings/help/instructions';
import { dateDefaultFormat } from '../../../../constants/date';

const InstructionsPageItem: FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { data: instruction, sendRequest: requestInstruction, loading } = useApi<IInstructionsItem>(getHelpEntity);

  useEffect(() => {
    dispatch(setHeaderTitle('Инструкции'));
  }, [dispatch]);

  useEffect(() => {
    const { instructionId } = params;
    if (instructionId) {
      requestInstruction(getHelpInstructionsQuestion(instructionId));
    }
  }, []);

  return (
    <>
      <CommonHead seo={{ title: 'Инструкции' }} />
      <div className="help-instructions-item">
        <div className="help-instructions-item__back-button-wrapper">
          <ButtonLink url={paths.helpInstructions} leftIcon={<ArrowBackIcon />} content="Список инструкций" />
        </div>
        {loading ? (
          <div className="help-instructions-item__loader-wrapper">
            <Loader color={ELoaderColor.blue} />
          </div>
        ) : instruction ? (
          <>
            <span className="help-instructions-item__title">{instruction?.question}</span>
            <div className="help-instructions-item__content">
              <div
                className="help-instructions-item__body"
                dangerouslySetInnerHTML={{ __html: instruction?.answer || '' }}
              />
              <div className="help-instructions-item__toolbox">
                {instruction.updateDate && (
                  <span className="help-instructions-item__date">
                    {moment(instruction.updateDate || '').format(dateDefaultFormat)}
                  </span>
                )}
                {instruction.id && instruction.fileIsAvailable && (
                  <div className="help-instructions-item__button">
                    <ButtonDownload downloadUrl={getHelpInstructionFile(instruction.id)}>Скачать файл</ButtonDownload>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <ErrorPlaceholder
            containerClassName="help-instructions-item__error-wrapper"
            icon={<SearchFailIcon />}
            text="Вопрос не найден"
          />
        )}
      </div>
    </>
  );
};

export default InstructionsPageItem;
