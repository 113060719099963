import React, { FC, useCallback, useState } from 'react';
import { clearCallCodes } from '../../../../api/callCode';
import { ICallCodeClearData } from '../../../../api/callCode/types';
import { clearCallCodesUrl } from '../../../../constants/api';
import { selectAllOptionKey } from '../../../../constants/select';
import { useApi } from '../../../../hooks/useApi';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Checkbox from '../../../ui/checkbox';
import AlertBlock from '../../../ui/alertBlock';
import Modal from '../../../ui/modal';
import Select from '../../../ui/select';
import { ISelectOption } from '../../../ui/select/types';
import { IClearCallCodesModal } from './types';

const ClearCallCodesModal: FC<IClearCallCodesModal> = (props) => {
  const {
    systemId = '',
    buildingId = '',
    sections = [],
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    isFiveThousandth,
  } = props;

  const { sendRequest } = useApi(clearCallCodes);
  const [selectedSectionId, setSelectedSectionId] = useState<string | number>(selectAllOptionKey);
  const [clearInBuildingCodes, setClearInBuildingCodes] = useState<boolean>(false);
  const [clearOutOfBuildingCodes, setClearOutOfBuildingCodes] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');

  const resetAll = useCallback(() => {
    setClearInBuildingCodes(false);
    setClearOutOfBuildingCodes(false);
    setErrorText('');
  }, []);

  const validateCheckbox = useCallback(() => {
    if (!clearInBuildingCodes && !clearOutOfBuildingCodes) {
      setErrorText('Выберите коды вызова для очистки');
      return false;
    }

    return true;
  }, [clearInBuildingCodes, clearOutOfBuildingCodes]);

  const handleOnClose = useCallback(() => {
    onCancel();
    setSelectedSectionId(selectAllOptionKey);
    resetAll();
  }, [onCancel, resetAll]);

  const handleOnClickOkButton = useCallback(async () => {
    if (selectedSectionId && validateCheckbox()) {
      const apiConfig: ICallCodeClearData = {
        ...(selectedSectionId === selectAllOptionKey ? {} : { sectionId: selectedSectionId.toString() }),
        clearInBuildingCodes,
        clearOutOfBuildingCodes,
      };

      const resError = await sendRequest(clearCallCodesUrl(systemId, buildingId), apiConfig);

      if (resError) {
        setErrorText(resError.response.data.message);
        return;
      }
      onOk();
      resetAll();
    }
  }, [
    buildingId,
    clearInBuildingCodes,
    clearOutOfBuildingCodes,
    onOk,
    resetAll,
    selectedSectionId,
    sendRequest,
    systemId,
    validateCheckbox,
  ]);

  const handleOnChangeSection = useCallback((sectionId: string | number) => {
    setSelectedSectionId(sectionId);
  }, []);

  const handleOnChangeCheckbox = useCallback(
    (setter: React.Dispatch<React.SetStateAction<boolean>>, value: boolean) => {
      setter(value);
      setErrorText('');
    },
    []
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Очистить коды вызова"
      width={416}
      onCancel={handleOnClose}
      showCloseIcon
      wrapClassName="clear-call-codes-modal__wrapper"
      footer={
        <div className="clear-call-codes-modal__button-container">
          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={handleOnClose}>
            Отмена
          </Button>
          <Button size={ButtonSize.small} onClick={handleOnClickOkButton}>
            Очистить
          </Button>
        </div>
      }
    >
      <div className="clear-call-codes-modal">
        <Select
          title="Подъезд"
          containerClassName="clear-call-codes-modal__field-wrapper"
          onChange={handleOnChangeSection}
          value={selectedSectionId}
          options={[
            { value: selectAllOptionKey, data: 'Все', title: 'Все' },
            ...sections.map<ISelectOption>((section) => ({ value: section.id, title: section.name })),
          ]}
        />
        <div className="clear-call-codes-modal__field-wrapper">
          <Checkbox
            checked={clearInBuildingCodes}
            defaultChecked={false}
            label="Коды вызова подъезда"
            onChange={(value) => handleOnChangeCheckbox(setClearInBuildingCodes, value)}
          />
        </div>
        {isFiveThousandth && (
          <div className="clear-call-codes-modal__field-wrapper">
            <Checkbox
              checked={clearOutOfBuildingCodes}
              defaultChecked={false}
              label="Коды вызова калитки"
              onChange={(value) => handleOnChangeCheckbox(setClearOutOfBuildingCodes, value)}
            />
          </div>
        )}
        <div className="clear-call-codes-modal__field-wrapper clear-call-codes-modal__error-wrapper">
          {errorText && <AlertBlock content={errorText} />}
        </div>
      </div>
    </Modal>
  );
};

export default ClearCallCodesModal;
