import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../../ui/button';
import { ButtonSize, ButtonType } from '../../../ui/button/types';
import Input from '../../../ui/input';
import { InputStatus } from '../../../ui/input/types';
import Modal from '../../../ui/modal';
import { INonUniqueMacAddressModal } from './types';
import Scrollbar from '../../../ui/scrollbar';
import { ICameraPoint, ITreeNode } from '../../../../typings/treeNode';
import MaskMacAddressInput from '../../../ui/input/maskInput/maskMacAddressInput';
import { MAC_ADDRESS_VALIDATE_REGEX } from '../../../../constants/regex';

const NonUniqueMacAddressModal: FC<INonUniqueMacAddressModal> = (props) => {
  const { data = [], isOpen = false, onOk = () => {}, onCancel = () => {}, editableSchema, setSchemas } = props;

  const [serialNumbersEditData, setSerialNumbersEditData] = useState<ICameraPoint[]>([]);

  const [errors, setErrors] = useState<{ id: string; errorText: string }[]>([]);

  useEffect(() => {
    setSerialNumbersEditData(data);
  }, [data]);

  const nonUniqueMacAddressDictionary = useMemo(
    () => (serialNumbersEditData ? new Map(serialNumbersEditData.map((n: ICameraPoint) => [n.id, n.mac])) : new Map()),
    [serialNumbersEditData]
  );

  const onBlur = useCallback(
    (camera: ICameraPoint) => () => {
      const id = camera.id as string;
      const val = nonUniqueMacAddressDictionary.get(id);
      const index = errors.findIndex((item) => item.id === id);
      const newErrors = [...errors];
      if (val && val.trim() && MAC_ADDRESS_VALIDATE_REGEX.test(val)) {
        if (index !== -1) {
          newErrors.splice(index, 1);
        }
      } else if (index === -1) {
        newErrors.push({ id, errorText: 'Введите адрес в формате FF:FF:FF:FF:FF:FF' });
      }
      setErrors(newErrors);
    },
    [errors, nonUniqueMacAddressDictionary]
  );

  const replaceFunction = useCallback(
    (schemaData: ITreeNode[]) => {
      for (let j = 0; j < schemaData.length; j++) {
        const schemaItem = schemaData[j];
        if (schemaItem.cameras && schemaItem.cameras.length) {
          for (let i = 0; i < schemaItem.cameras.length; i++) {
            const camera: ICameraPoint = schemaItem.cameras[i];
            if (nonUniqueMacAddressDictionary.has(camera.id)) {
              schemaItem.cameras[i].mac = nonUniqueMacAddressDictionary.get(camera.id) as string;
            }
          }
        }
        if (schemaItem.childItems) {
          replaceFunction(schemaItem.childItems);
        }
      }
    },
    [nonUniqueMacAddressDictionary]
  );

  const handleChange = useCallback(
    (setter: (value: any) => void, newValue: string, index: number, keyName: string): void => {
      const editedData = serialNumbersEditData.map((_item: ICameraPoint, _index: number) =>
        index === _index ? { ..._item, [keyName]: newValue } : _item
      );
      return setter(editedData);
    },
    [serialNumbersEditData]
  );

  const handleOnClickOkButton = useCallback(() => {
    replaceFunction(editableSchema.current);
    setSchemas(editableSchema.current);
    onOk();
  }, [editableSchema, onOk, replaceFunction, setSchemas]);

  const renderUniqueSerials = useCallback(
    () => (
      <Scrollbar>
        <div className="serial-numbers-modal__hardwares">
          {serialNumbersEditData.map((item: ICameraPoint, index: number) => {
            const errorVal = errors.find((error) => error.id === item.id);
            return (
              <div key={index} className="serial-numbers-modal__hardware">
                <div className="serial-numbers-modal__hardware-title">
                  {item.address}, {item.name}
                </div>
                <div className="serial-numbers-modal__hardware-inputs">
                  <Input
                    title="Название оборудования"
                    value={item.name}
                    status={InputStatus.normal}
                    disabled
                    isDisabledStyle
                  />
                  <MaskMacAddressInput
                    onBlur={onBlur(item)}
                    title="MAC-адрес"
                    placeholder="MAC-адрес"
                    value={item.mac || ''}
                    onChange={(value) => handleChange(setSerialNumbersEditData, value, index, 'mac')}
                    status={errorVal ? InputStatus.error : InputStatus.normal}
                    errorText={errorVal?.errorText || ''}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Scrollbar>
    ),
    [errors, handleChange, onBlur, serialNumbersEditData]
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Замените неуникальные MAC-адреса"
      onCancel={onCancel}
      width={700}
      wrapClassName="serial-numbers-modal"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button disabled={errors.length !== 0} onClick={handleOnClickOkButton} size={ButtonSize.small}>
            Сохранить
          </Button>
          <Button type={ButtonType.secondary} onClick={onCancel} size={ButtonSize.small}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="serial-numbers-modal__content">
        <div className="serial-numbers-modal__notification-info">
          MAC-адрес должен быть уникальным в рамках всей платформы
        </div>
        {serialNumbersEditData && renderUniqueSerials()}
      </div>
    </Modal>
  );
};

export default NonUniqueMacAddressModal;
