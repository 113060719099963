import { IHardwareParamString } from '../../../../../../../typings/systems/hardwareSettings';
import { emptyFieldErrorText } from '../types';

export const getStringFieldError = (stringFieldValue: Omit<IHardwareParamString, 'defaultValue'>): string => {
  if (stringFieldValue.isRequired && (!stringFieldValue.value || !stringFieldValue.value.trim())) {
    return emptyFieldErrorText;
  }

  const { value } = stringFieldValue;

  if (value) {
    if (parseInt(stringFieldValue.restricts.restricts.minLength?.toString(), 10) > value.length) {
      return `Введите мин. количество символов: ${stringFieldValue.restricts.restricts.minLength}`;
    }
    if (parseInt(stringFieldValue.restricts.restricts.maxLength?.toString(), 10) < value.length) {
      return `Введено макс. количество символов: ${stringFieldValue.restricts.restricts.maxLength}`;
    }
  }
  return '';
};
