export interface IChangeLogoModal {
  requestCompany?: () => void;
  isOpen?: boolean;
  onCancel?: () => void;
}

export enum ELogoUploadErrors {
  default = 'default',
  size = 'size',
  type = 'type',
  length = 'length',
}
