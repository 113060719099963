/* eslint no-use-before-define: */
import React from 'react';
import ReactDOM from 'react-dom';

const ymaps3Reactify = await ymaps3.import('@yandex/ymaps3-reactify');
const reactify = ymaps3Reactify.reactify.bindTo(React, ReactDOM);
export const {
  YMap,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapMarker,
  YMapFeatureDataSource,
  YMapLayer,
  YMapControls,
  YMapControl,
  geolocation,
  YMapListener,
} = reactify.module(ymaps3);

export const yMaps = await ymaps3.ready;

export const { YMapZoomControl } = reactify.module(await ymaps3.import('@yandex/ymaps3-controls@0.0.1'));

export const { YMapClusterer, clusterByGrid } = reactify.module(await ymaps3.import('@yandex/ymaps3-clusterer@0.0.1'));
