import React, { FC } from 'react';

const DoorCircleIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_26670_66516)">
      <circle cx="12" cy="12" r="11.6" fill="white" stroke="#949494" strokeWidth="0.8" />
      <path
        d="M18.7113 18.8941H17.2344V4.86333C17.2344 4.71564 17.1359 4.61719 16.9882 4.61719H7.14206C6.99437 4.61719 6.89592 4.71564 6.89592 4.86333V18.8941H5.419C5.2713 18.8941 5.17285 18.9926 5.17285 19.1402C5.17285 19.2879 5.2713 19.3864 5.419 19.3864H7.14206H8.12667H16.0036H16.9882H18.7113C18.859 19.3864 18.9574 19.288 18.9574 19.1403C18.9574 18.9926 18.859 18.8941 18.7113 18.8941ZM8.37284 18.8941V6.09411H15.7575V18.8941H8.37284ZM16.2498 18.8941V5.84797C16.2498 5.70027 16.1513 5.60182 16.0036 5.60182H8.1267C7.979 5.60182 7.88055 5.70027 7.88055 5.84797V18.8941H7.38824V5.1095H16.7421V18.8941H16.2498V18.8941Z"
        fill="#949494"
      />
      <path
        d="M15.019 11.7539H14.0344C13.8867 11.7539 13.7883 11.8524 13.7883 12.0001V12.2462H13.2959C13.1483 12.2462 13.0498 12.3446 13.0498 12.4923C13.0498 12.64 13.1483 12.7385 13.2959 12.7385H13.7883V12.9846C13.7883 13.1323 13.8867 13.2308 14.0344 13.2308H15.019C15.1667 13.2308 15.2652 13.1323 15.2652 12.9846V12.0001C15.2652 11.8524 15.1667 11.7539 15.019 11.7539ZM14.7729 12.7385H14.2806V12.2462H14.7729V12.7385Z"
        fill="#949494"
      />
    </g>
    <defs>
      <clipPath id="clip0_26670_66516">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DoorCircleIcon;
