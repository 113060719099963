import { IMailingInfo } from '../../typings/mailing';
import { IMailingApiSettings } from './types';

export const MAILING_TABLE_COUNT = 10;

export const getMailingApiSettings = (defaultSortField: string): IMailingApiSettings<IMailingInfo> => ({
  sortFields: defaultSortField
    ? [
        {
          field: defaultSortField as keyof IMailingInfo,
          descending: true,
        },
      ]
    : [],
  page: 0,
  pageSize: MAILING_TABLE_COUNT,
  search: '',
});
