import Paper, { Group, Path, Layer, Point, PointText, Color, Raster } from 'paper';
import {
  EPaperGroupTypes,
  TPaperGroup,
  TPaperPoint,
  TPaperCircle,
  TPaperPath,
  TPaperEvent,
  TPaperText,
  EEditTypes,
} from '../../types';
import {
  calculateLabelPositionCorner,
  checkJSON,
  getLineLength,
  getStringFromJSX,
  onMouseDown,
  onMouseDrag,
} from './calculations';
import {
  AccessPointType,
  CameraIconType,
  IAccessPoint,
  IAccessPointDevice,
  IRelatedAccessPoint,
  TreeNodeType,
} from '../../../../typings/treeNode';
import {
  circleBlueStyle,
  circleGreenStyle,
  circleNullStyle,
  circleStyle,
  defaultAccessPointTextStyles,
  defaultLabelOffset,
  defaultPointStyle,
  defaultTextStyles,
  EColors,
  evenStyle,
  oddStyle,
  paperColorToValue,
  pathStyle,
  polygonParams,
  pseudoPathStyle,
  rotateIcon,
  sectionStyle,
  selectedCircleStyle,
  selectedPathStyle,
} from '../../../../constants/paper';
import {
  ETypeShapes,
  EVisualizerModes,
  ISchemaBackground,
  ISchemaOpacity,
  IVisualizerMode,
} from '../../../systemTabs/schemaTab/types';
import {
  downScaleNumber,
  findExtremes,
  findObjectInGroups,
  getConvertedValue,
  setAccessPointPosition,
} from '../common';

import {
  ECursors,
  ICircleParams,
  IEntityRenderData,
  ILabelPosition,
  IPaperHandlers,
  IPolygonParams,
  ITreeRenderFunctions,
  TPaperStyle,
} from './types';
import { changeVisibleGroup } from '../../calculations';
import {
  cornerDownSizeValue,
  cornerDownSizeMin,
  cornerUpSizeValue,
  cornerUpSizeMax,
  rotateDownSizeValue,
  rotateDownSizeMin,
  rotateUpSizeValue,
  rotateUpSizeMax,
} from '../../zoomConfig';
import { EDispatcherDeviceStatus, fromStatusToColor } from '../../../../typings/dispatcherPanel';
import { cameraIconsMap, getExitIcon, pointIconsMap } from '../../../systemTabs/schemaTab/templates';

export const createCircle = (
  { center, radius }: ICircleParams,
  handlers: IPaperHandlers,
  pointStyle: TPaperStyle = defaultPointStyle
) =>
  new Path.Circle({
    x: center.x,
    y: center.y,
    radius,
    ...handlers,
    ...pointStyle,
  });

export const createCircles = (points: TPaperPoint[], handlers: IPaperHandlers): TPaperCircle[] =>
  points.map((point) => {
    const circle = createCircle({ center: new Point(point.x, point.y), radius: 3, needLabel: false }, handlers);
    circle.bringToFront();
    return circle;
  });

export const renderText = (
  title = '',
  position: ILabelPosition = defaultLabelOffset,
  textStyles: TPaperStyle = defaultTextStyles
) => new PointText({ point: new Point(position), content: title, ...textStyles });

export const createPolygon = (
  mode: IVisualizerMode,
  { path = new Path(), title, needLabel, labelOffset }: IPolygonParams = polygonParams,
  objectStyles?: TPaperStyle,
  textStyles: TPaperStyle = defaultTextStyles
) => {
  const group = new Group([
    mode.shape?.type === ETypeShapes.circle
      ? path.clone()
      : new Path({ segments: path.segments, closed: true, ...objectStyles }),
  ]);

  if (needLabel && labelOffset) {
    const label = renderText(title, { x: 0, y: 0 }, textStyles);
    if (mode.shape?.type === ETypeShapes.circle) {
      label.position = path.bounds.center;
    } else {
      calculateLabelPositionCorner(path, label, group);
    }
    group.addChild(label);
  }
  return group;
};

export const createBackground = (
  background: ISchemaBackground,
  objectGroup: TPaperGroup,
  treeRenderFunctions: ITreeRenderFunctions,
  editPermission: boolean
): TPaperGroup => {
  const { setActiveGroup, setCursor, haveBeenChanges, moveControls } = treeRenderFunctions;
  let entity: TPaperGroup | null = null;

  if (background.layout && checkJSON(background.layout)) {
    entity = new Group().importJSON(background.layout);
  } else {
    const raster = new Raster({
      source: background.img,
    });
    const rasterGroup = new Group().addChild(raster);
    entity = new Group();
    entity.addChild(rasterGroup);
  }

  objectGroup.addChild(entity);

  const updateObjectData = (wasChange = true) => {
    if (entity) {
      if (wasChange) {
        haveBeenChanges();
      }
      background.layout = entity.exportJSON();
    }
  };

  entity.set({
    onMouseDown: onMouseDown(objectGroup, setActiveGroup),
    onMouseDrag: editPermission ? onMouseDrag(objectGroup, setCursor, moveControls) : null,
    onMouseEnter: () => setCursor(ECursors.pointer),
    onMouseLeave: () => setCursor(ECursors.default),
    selectedColor: new Color(EColors.darkBlue),
    selected: false,
    data: {
      width: entity.bounds.width,
      key: `${TreeNodeType.background}-img`,
      groupType: EPaperGroupTypes.object,
      type: TreeNodeType.background,
      updateObjectData,
      bounds: entity.bounds.clone(),
    },
  });

  return entity;
};

const getDeviceColor = (data: IAccessPointDevice) =>
  paperColorToValue.get(
    data.status?.status ? fromStatusToColor.get(data.status.status as EDispatcherDeviceStatus) || 'blue' : 'blue'
  );

export const getDeviceLayout = (object: IAccessPoint, oldElement: TPaperGroup): TPaperGroup => {
  const exitIcon = getExitIcon(!(object as IAccessPoint).outputDevice);
  const position = oldElement.position.clone();
  const group = new Group([
    new Path.Circle({ center: position, radius: 20, ...circleStyle, strokeWidth: 1 }),
    renderText(object.shortName, { x: position.x, y: position.y + 35 }, defaultAccessPointTextStyles),
    new Group().importSVG(getStringFromJSX(exitIcon), (item: TPaperGroup) => {
      item.position = position.add(new Point(28, -12));
    }),
  ]);

  if (object.inputDevice && object.outputDevice) {
    const circle = new Path.Circle({ center: position, radius: 11, fillColor: 'white' });
    const rectangle1 = new Path.Rectangle({
      point: position.subtract(new Paper.Point(0, 11)),
      size: [12, 22],
      fillColor: getDeviceColor(object.inputDevice),
    });
    const rectangle2 = new Path.Rectangle({
      point: position.subtract(new Paper.Point(11, 11)),
      size: [12, 22],
      fillColor: getDeviceColor(object.outputDevice),
    });
    const result1 = rectangle1.intersect(circle);
    const result2 = rectangle2.intersect(circle);
    rectangle1.visible = false;
    rectangle2.visible = false;

    const newGroup = new Group([circle, rectangle1, rectangle2, result1, result2]);

    group.addChild(newGroup);
  } else {
    const data = object.inputDevice || object.outputDevice;
    if (data) {
      const circle = new Path.Circle({
        center: position,
        radius: 11,
        fillColor: getDeviceColor(data),
      });
      group.addChild(circle);
    }
  }

  oldElement.remove();
  return group;
};

export const createEntityGroup = ({
  object,
  objectGroup,
  renderFunctions,
  data,
  key,
  type,
  labelsLayer,
  opacityValues,
  mode,
}: IEntityRenderData) => {
  const { setActiveGroup, setCursor, doubleClickGroup, haveBeenChanges, moveControls } = renderFunctions;
  let entity: TPaperGroup | null = null;

  if (object.layout && checkJSON(object.layout)) {
    entity = new Group().importJSON(object.layout);

    [...entity.children, entity].forEach((child) => {
      child.visible = true;
      changeVisibleGroup(child, true, opacityValues);
    });

    const child = 'children' in entity && entity.children.length ? entity.children[0] : entity;

    switch (type) {
      case TreeNodeType.restrictedArea: {
        child.set({ style: oddStyle });
        break;
      }
      case TreeNodeType.section: {
        child.set({ style: sectionStyle });
        break;
      }
      case TreeNodeType.building: {
        child.set({ style: evenStyle });
        break;
      }
      case TreeNodeType.accessPoint: {
        if (mode === EVisualizerModes.deviceShow) {
          entity = getDeviceLayout(object as IAccessPoint, entity);
        }
        child.set({ style: circleStyle });
        break;
      }
      default: {
        break;
      }
    }

    objectGroup.addChild(entity);

    const label = entity.children[1] as TPaperText;
    if (label) {
      label.content = object.shortName || object.name || '';
      label.data.bounds = label.bounds.clone();
      label.data.type = 'label';
      if (type !== TreeNodeType.accessPoint && type !== TreeNodeType.camera) {
        calculateLabelPositionCorner(entity.children[0] as TPaperPath, label, entity);

        const clone = label.clone();
        entity.addChild(clone);
        clone.visible = false;

        labelsLayer.addChild(label);
        label.data = { key: `${key}-label`, clone };
        label.onMouseDown = onMouseDown(objectGroup, setActiveGroup);
      }
    }

    const updateObjectData = () => {
      if (entity) {
        haveBeenChanges();
        if (objectGroup.data.type === TreeNodeType.accessPoint || objectGroup.data.type === TreeNodeType.camera) {
          setAccessPointPosition(objectGroup);
          object.layout = objectGroup.data.clone.children[0].exportJSON();
        } else {
          object.layout = entity.exportJSON();
          label.position = entity.children[1].position;
        }
      }
    };

    entity.set({
      onMouseDown: onMouseDown(objectGroup, setActiveGroup),
      onMouseDrag: data.editPermission ? onMouseDrag(objectGroup, setCursor, moveControls) : null,
      onMouseEnter: () => setCursor(ECursors.pointer),
      onMouseLeave: () => setCursor(ECursors.default),
      onDoubleClick: () => doubleClickGroup(objectGroup),
      selectedColor: new Color(EColors.darkBlue),
      selected: false,
      data: {
        isNew: !!entity.data.isNew,
        key,
        groupType: EPaperGroupTypes.object,
        type,
        updateObjectData,
        bounds: entity.bounds.clone(),
      },
    });
  }
};

export const getPath = (handlers: IPaperHandlers = {}, style: TPaperStyle = pathStyle) =>
  new Path({ ...style, ...handlers });

export const getDefaultPath = () => new Path();

export const getLayer = () => new Layer();

export const getGroup = () => new Group();

export const getLayout =
  (mode: IVisualizerMode) =>
  (path: TPaperPath, title = ''): string => {
    const group = createPolygon(mode, { ...polygonParams, path, title });
    group.data.isNew = true;
    const layout = group.exportJSON();
    group.remove();
    return layout;
  };

export const getPointLayout = (newPoint: IAccessPoint, oldLayout: string | null = null, isCamera = false): string => {
  let group: TPaperGroup | null = null;
  const position = (
    Paper.project.activeLayer.data.activeGroup
      ? Paper.project.activeLayer.data.activeGroup.bounds.center
      : Paper.project.view.center
  ).clone();

  const svg = isCamera
    ? cameraIconsMap.get(CameraIconType.default)?.element
    : pointIconsMap.get(newPoint.deviceType as AccessPointType)?.element;
  group = new Group([
    new Path.Circle({ center: position, radius: 20, ...circleStyle }),
    renderText(newPoint.shortName, { x: position.x, y: position.y + 35 }, defaultAccessPointTextStyles),
    new Group().importSVG(svg ? getStringFromJSX(svg) : '', (item: TPaperGroup) => {
      item.position = position;
    }),
  ]);

  if (!isCamera) {
    const exitIcon = getExitIcon(!newPoint.outputDevice);

    if (exitIcon) {
      group.addChild(
        new Group().importSVG(getStringFromJSX(exitIcon), (item: TPaperGroup) => {
          item.position = position.add(new Point(28, -12));
        })
      );
    }
  }

  if (oldLayout && checkJSON(oldLayout)) {
    const oldLayoutGroup = new Group().importJSON(oldLayout);
    group.position = oldLayoutGroup.position;
  }

  const layout = group.exportJSON();
  group.remove();
  return layout;
};

export const showAllChildren = (group: TPaperGroup) => {
  group.visible = true;
  group.children?.forEach((child) => {
    showAllChildren(child);
  });
};

export const getGroupCopy = (
  group: TPaperGroup,
  opacityValues: ISchemaOpacity,
  onClick: (e: TPaperEvent) => void,
  setCursor: (cursor: ECursors) => void
): TPaperGroup => {
  const copyGroup = group.clone();
  if (group.data.key === TreeNodeType.object) {
    const { topLeft, bottomRight } = Paper.project.view.bounds;
    const background = new Group([
      new Path.Rectangle({
        from: topLeft,
        to: bottomRight,
        ...oddStyle,
      }),
    ]);
    background.opacity = 0.01;
    background.data.key = 'background';
    copyGroup.addChild(background);
    background.sendToBack();
  }

  copyGroup.onClick = onClick;
  copyGroup.onMouseEnter = () => setCursor(ECursors.crosshair);
  copyGroup.onMouseLeave = () => setCursor(ECursors.default);
  showAllChildren(copyGroup);
  changeVisibleGroup(copyGroup, true, opacityValues);
  return copyGroup;
};

export const getPseudoPath = (
  from: TPaperPoint,
  to: TPaperPoint,
  points: number[][],
  handlers: IPaperHandlers = {},
  style = pseudoPathStyle
): TPaperPath => {
  const finalPath = new Path.Rectangle({ ...style, ...handlers, closed: true });
  points.forEach((point) => finalPath && finalPath.add(new Point(point[0], point[1])));

  const { maxX, minX, maxY, minY } = findExtremes(finalPath);
  const lengthFigureX = maxX - minX;
  const lengthFigureY = maxY - minY;
  const lengthDeltaX = from.x - to.x;
  const lengthDeltaY = from.y - to.y;
  const position = new Point(Math.round(from.x - lengthFigureX / 2), Math.round(from.y - lengthFigureY / 2));
  const scaleX = +(lengthDeltaX / lengthFigureX).toFixed(3);
  const scaleY = +(lengthDeltaY / lengthFigureY).toFixed(3);

  if (scaleX && scaleY) {
    finalPath.position = position;
    finalPath.scale(scaleX, scaleY, from);
  }

  return finalPath;
};

export const getPseudoLine = (
  from: TPaperPoint,
  to: TPaperPoint,
  handlers: IPaperHandlers = {},
  style = pseudoPathStyle
): TPaperPath => {
  const path = new Path();
  path.add(from);
  path.add(to);
  path.set({ ...style, ...handlers });
  return path;
};

export const getPseudoCircle = (
  from: TPaperPoint,
  to: TPaperPoint,
  handlers: IPaperHandlers = {},
  style = pseudoPathStyle
): TPaperPath => new Path.Circle({ center: from, radius: getLineLength(from, to), ...handlers, ...style });

export const getCorners = (
  group: TPaperGroup,
  setCursor: (cursor: ECursors) => void,
  setMode: (mode?: EEditTypes | null, data?: any) => void,
  zoom: number
): TPaperPath[] => {
  const cursors = [ECursors.nwResize, ECursors.neResize, ECursors.seResize, ECursors.swResize];
  const positions = [group.bounds.topLeft, group.bounds.topRight, group.bounds.bottomRight, group.bounds.bottomLeft];
  return positions.map(
    (corner, i) =>
      new Path.Rectangle({
        ...selectedCircleStyle,
        center: corner,
        size: getConvertedValue(zoom, cornerDownSizeValue, cornerDownSizeMin, cornerUpSizeValue, cornerUpSizeMax),
        onMouseEnter: () => setCursor(cursors[i]),
        onMouseLeave: () => setCursor(ECursors.default),
        onMouseDrag: (event: TPaperEvent) => event.stopPropagation(),
        onMouseDown: (event: TPaperEvent) => {
          event.stopPropagation();
          setMode(EEditTypes.rescale, {
            baseBounds: group.bounds.clone(),
            baseCorner: event.point.subtract(group.bounds.center),
            cornerIndex: i,
          });
        },
        onMouseUp: () => {
          setMode();
          group.data.mode = null;
        },
        data: {
          type: 'corner',
        },
      })
  );
};

export const getRotateIcon = (
  group: TPaperGroup,
  setCursor: (cursor: ECursors) => void,
  setMode: (mode?: EEditTypes | null, data?: any) => void,
  zoom: number
) => {
  const rotate = new Group([
    new Path.Rectangle({
      size: 20,
      fillColor: 'white',
      blendMode: 'color-burn',
      onMouseEnter: () => setCursor(ECursors.pointer),
      onMouseLeave: () => setCursor(ECursors.default),
      onMouseDrag: (event: TPaperEvent) => event.stopPropagation(),
      onMouseDown: (event: TPaperEvent) => {
        event.stopPropagation();
        setMode(EEditTypes.rotate, { center: group.bounds.center });
      },
      onMouseUp: () => setMode(),
    }),
    new Group().importSVG(rotateIcon),
  ]);

  const scale = getConvertedValue(zoom, rotateDownSizeValue, rotateDownSizeMin, rotateUpSizeValue, rotateUpSizeMax);

  rotate.scale(scale);
  rotate.position = new Point(group.bounds.bottomCenter.x, group.bounds.bottomCenter.y + 15 * scale);
  rotate.data.scale = scale;

  return rotate;
};

export const getControls = (
  group: TPaperGroup,
  setCursor: (cursor: ECursors) => void,
  setMode: (mode?: EEditTypes | null, data?: any) => void,
  zoom = Paper.view.zoom
): [TPaperPath, TPaperPath[], TPaperGroup] | null => {
  const foundGroup = findObjectInGroups(group);
  if (foundGroup) {
    foundGroup.selected = false;

    return [
      new Path.Rectangle({ rectangle: group.bounds, ...selectedPathStyle, strokeWidth: downScaleNumber(1, zoom) }),
      getCorners(group, setCursor, setMode, zoom),
      getRotateIcon(group, setCursor, setMode, zoom),
    ];
  }

  return null;
};

export const getPoint = (x: number, y: number) => new Point(x, y);

// export const repaintAccessPoints = (showConnections: boolean, ids: string[]) => {
//   ids.forEach((id) => {
//     const findGroup = Paper.project.getItem({ data: { id } });
//     if (findGroup) {
//       findGroup.strokeColor = new Color(EColors.green);
//     }
//   });
// };

export const repaintAccessPoints = (showConnections: boolean, points: IRelatedAccessPoint[]) => {
  points.forEach((point) => {
    const findGroup: TPaperGroup = Paper.project.getItem({ data: { id: point.accessPointId } });
    if (findGroup) {
      // eslint-disable-next-line prefer-destructuring
      const clone: TPaperGroup = findGroup.data.clone;
      if (clone) {
        if (clone.data.pointType === TreeNodeType.accessPoint) {
          clone.children[0].children[0].set({ style: showConnections ? circleGreenStyle : circleNullStyle });
        } else {
          clone.children[0].children[0].set({ style: showConnections ? circleGreenStyle : circleBlueStyle });
          clone.children[0].children[2].set({ style: showConnections ? circleGreenStyle : circleNullStyle });
        }
      }
    }
  });
};
