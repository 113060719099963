import { ISystemSection } from '../../../../typings/systems/section';

export interface IAddCallCodesModal {
  systemId?: string;
  buildingId?: string;
  sections?: ISystemSection[];
  isOpen?: boolean;
  isFiveThousandth?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}

export enum CallCodeType {
  inBuilding = 'inBuilding',
  outOfBuilding = 'outOfBuilding',
}

export enum CallCodeFillingType {
  flatNumberInCallCode = 'flatNumberInCallCode',
  callCodeRage = 'callCodeRage',
}

export enum CallCodesAction {
  rewrite = 'rewrite',
  skip = 'skip',
}
