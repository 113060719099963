import React, { FC } from 'react';

const HomeIcon: FC = () => (
  <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.2095 26.695V22.6061C10.2095 21.566 11.0574 20.7209 12.1079 20.714H15.956C17.0116 20.714 17.8673 21.5611 17.8673 22.6061V22.6061V26.7077C17.867 27.5908 18.5789 28.3125 19.4706 28.3332H22.036C24.5934 28.3332 26.6666 26.2807 26.6666 23.7489V23.7489V12.117C26.6529 11.1209 26.1806 10.1856 25.3839 9.57721L16.6102 2.58024C15.0732 1.36193 12.8882 1.36193 11.3511 2.58024L2.61595 9.58991C1.81626 10.1959 1.3431 11.1327 1.33325 12.1296V23.7489C1.33325 26.2807 3.40642 28.3332 5.9638 28.3332H8.5292C9.44306 28.3332 10.1839 27.5998 10.1839 26.695V26.695"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeIcon;
