import { Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SortOrder, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import ChevronLeft from '../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../assets/svg/icons/chevronRight';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import SearchFailIcon from '../../../assets/svg/icons/searchFail';
import { ELoaderColor } from '../../ui/loader/types';
import Loader from '../../ui/loader';
import { IApiSortField } from '../../../typings/api';
import Scrollbar from '../../ui/scrollbar';
import { dateDefaultFormat } from '../../../constants/date';
import { IMailingTable } from './types';
import { EMailingTypes, IMailingInfo, mailingStatusNamesMap, mapPathByMailingType } from '../../../typings/mailing';

const MailingTable: FC<IMailingTable> = (props) => {
  const {
    loading = false,
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    mailingList = [],
    activeTab,
  } = props;

  const navigate = useNavigate();

  const getStatus = useCallback(
    (record: IMailingInfo) => (
      <div className={`mailing__status mailing__status_${record.status}`}>
        {record.status && mailingStatusNamesMap.get(record.status)}
      </div>
    ),
    []
  );

  const handleOnChangeTable: TableProps<IMailingInfo>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IMailingInfo> | SorterResult<IMailingInfo>[],
      extra: TableCurrentDataSource<IMailingInfo>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IMailingInfo>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IMailingInfo,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IMailingInfo,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IMailingInfo> | null) => (flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null),
    []
  );

  const onRowClick = useCallback(
    (id: string) => {
      if (activeTab)
        navigate({
          pathname: `${mapPathByMailingType.get(activeTab) || ''}/${id}`,
        });
    },
    [activeTab, navigate]
  );

  const trimString = useCallback(
    (str = '', length = 55) => (str?.length >= length ? str.substring(0, length).concat('...') : str),
    []
  );

  const columns = useMemo<ColumnsType<IMailingInfo>>(
    () => [
      {
        title: activeTab === EMailingTypes.sms ? 'Текст' : activeTab === EMailingTypes.push ? 'Заголовок' : 'Тема',
        dataIndex: 'title',
        key: 'title',
        render: (_: any, record: IMailingInfo) => (
          <div className="editable-table__data-container">
            {activeTab === EMailingTypes.sms ? trimString(record.body, 40) : record.title}
          </div>
        ),
        width: '30%',
      },
      {
        title: 'Получатели',
        dataIndex: 'recipientObjects',
        key: 'recipientObjects',
        width: '30%',
        render: (_: any, record: IMailingInfo) => (
          <div className="editable-table__data-container">
            {trimString(
              record.recipientObjects
                .map((item) => item.name)
                .toString()
                .split(',')
                .join(', ')
            )}
          </div>
        ),
      },
      {
        title: 'Дата создания',
        dataIndex: 'createDate',
        key: 'createDate',
        width: '15%',
        sorter: {
          multiple: 0,
        },
        sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'createDate') || null) as SortOrder,
        render: (_: any, record: IMailingInfo) => (
          <div className="editable-table__data-container">
            {record.createDate ? moment(record.createDate || '').format(dateDefaultFormat) : '-'}
          </div>
        ),
      },
      {
        title: 'Дата отправления',
        dataIndex: 'sendDate',
        key: 'sendDate',
        width: '15%',
        sorter: {
          multiple: 1,
        },
        sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'sendDate') || null) as SortOrder,
        render: (_: any, record: IMailingInfo) => (
          <div className="editable-table__data-container">
            {record.sendDate ? moment(record.sendDate || '').format(dateDefaultFormat) : '-'}
          </div>
        ),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        render: (_: any, record: IMailingInfo) => (
          <div className="editable-table__data-container">{getStatus(record)}</div>
        ),
        width: '10%',
      },
    ],
    [activeTab, getColumnSortOrder, getStatus, sortOrders, trimString]
  );

  const data = useMemo(
    () =>
      mailingList.map((item, index) => ({
        ...item,
        key: index,
      })),
    [mailingList]
  );

  return (
    <div className="mailing-table editable-table">
      <Scrollbar>
        <Table
          onRow={(record) => ({
            onClick: () => onRowClick(record.id || ''),
          })}
          columns={columns}
          dataSource={data}
          loading={{
            spinning: loading,
            indicator: <Loader color={ELoaderColor.blue} />,
          }}
          onChange={handleOnChangeTable}
          showSorterTooltip={false}
          locale={{
            emptyText: isSearch ? (
              <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
            ) : (
              <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
            ),
          }}
          pagination={{
            current: currentPage + 1,
            pageSize,
            hideOnSinglePage: true,
            total,
            onChange: onChangePage,
            showSizeChanger: false,
            showQuickJumper: false,
            prevIcon: <ChevronLeft />,
            nextIcon: <ChevronRight />,
            className: 'editable-table__pagination',
          }}
        />
      </Scrollbar>
    </div>
  );
};

export default MailingTable;
