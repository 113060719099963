import React, { FC } from 'react';

const TwoWayOutputIcon: FC = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.333496 0.333333C0.333496 0.517428 0.482735 0.666667 0.666829 0.666667H10.0002C10.1843 0.666667 10.3335 0.517428 10.3335 0.333333C10.3335 0.149238 10.1843 0 10.0002 0H0.666829C0.482735 0 0.333496 0.149238 0.333496 0.333333ZM5.00016 2.4714L2.90253 4.56904C2.77236 4.69921 2.5613 4.69921 2.43113 4.56904C2.30095 4.43886 2.30095 4.22781 2.43113 4.09763L5.09779 1.43096C5.22797 1.30079 5.43902 1.30079 5.5692 1.43096L8.23587 4.09763C8.36604 4.22781 8.36604 4.43886 8.23587 4.56904C8.10569 4.69921 7.89463 4.69921 7.76446 4.56904L5.66683 2.4714V9.5286L7.76446 7.43096C7.89463 7.30079 8.10569 7.30079 8.23587 7.43096C8.36604 7.56114 8.36604 7.77219 8.23587 7.90237L5.5692 10.569C5.43902 10.6992 5.22797 10.6992 5.09779 10.569L2.43113 7.90237C2.30095 7.77219 2.30095 7.56114 2.43113 7.43096C2.5613 7.30079 2.77236 7.30079 2.90253 7.43096L5.00016 9.5286V2.4714ZM0.666829 12C0.482735 12 0.333496 11.8508 0.333496 11.6667C0.333496 11.4826 0.482735 11.3333 0.666829 11.3333H10.0002C10.1843 11.3333 10.3335 11.4826 10.3335 11.6667C10.3335 11.8508 10.1843 12 10.0002 12H0.666829Z"
      fill="black"
    />
  </svg>
);

export default TwoWayOutputIcon;
