import React, { FC, useCallback, useMemo } from 'react';
import { ITariffsPeriods } from './types';
import { ITariffCustomSettings, ITariffPeriod } from '../../../pages/management/tariffsCost/types';
import { months } from '../../../constants/tariffs';
import Select from '../../ui/select';
import Input from '../../ui/input';
import { InputStatus, InputType } from '../../ui/input/types';
import CloseIcon from '../../../assets/svg/icons/close';
import Button from '../../ui/button';
import { ButtonType } from '../../ui/button/types';
import SortIcon from '../../../assets/svg/icons/sort';
import { ITariffSettingsDiscount } from '../../../typings/systems/tariff';
import Message from '../../message';
import NumberInput from '../../ui/input/numberInput';

const TariffsPeriods: FC<ITariffsPeriods> = ({
  settings = null,
  maintenancePrice = 0,
  commission = [],
  onChange = () => {},
  getNewDiscountItem = () => {},
  permissions = {},
  onDeletePeriod = () => () => {},
  addPeriod = () => {},
}) => {
  const onChangeInput = useCallback(
    (name: string, index: number) => (value: string | number) => {
      if (settings) {
        const newSettings: any = { ...settings };
        const element = newSettings.periods[index];
        if (element) {
          element[name as keyof ITariffPeriod] = value === '' ? 0 : Number(value);
          newSettings.periods[index] = getNewDiscountItem(element, settings.tariffs);
          onChange(newSettings);
        }
      }
    },
    [getNewDiscountItem, onChange, settings]
  );

  const options = useMemo(
    () => months.filter((item) => !settings?.periods.find((period) => period.monthCount === item.value)),
    [settings]
  );

  const getOptions = useCallback(
    (period: ITariffPeriod) => {
      if (period.monthCount) {
        const result = months.find((element) => element.value === period.monthCount);
        if (result) {
          return [...options, result];
        }
      }
      return options;
    },
    [options]
  );

  const sortPeriods = useCallback(() => {
    if (settings) {
      const newSettings: ITariffCustomSettings = { ...settings };
      newSettings.periods.sort(
        (a: ITariffSettingsDiscount, b: ITariffSettingsDiscount) => (a.monthCount || 0) - (b.monthCount || 0)
      );
      Message.success({
        content: 'Периоды отсортированы',
      });
      onChange(newSettings);
    }
  }, [onChange, settings]);

  const getPriceWithoutSale = useCallback(
    (tariffIndex: number, monthCount: number) =>
      (
        (maintenancePrice +
          commission[tariffIndex].fixCommission +
          (settings?.tariffs[tariffIndex].additionalCompanyPrice || 0)) *
        monthCount
      ).toFixed(2),
    [commission, maintenancePrice, settings?.tariffs]
  );

  return (
    <div className="tariff-periods">
      <div className="tariff-periods__title">
        <div className="tariff-periods__title-label">Периоды и скидки</div>
        <div className="tariff-periods__title-buttons">
          {(settings?.periods.length || 0) > 1 && (
            <Button
              className="tariff-periods__title-buttons-sort"
              onClick={sortPeriods}
              type={ButtonType.outline}
              disabled={!permissions.edit}
            >
              <SortIcon />
            </Button>
          )}
          {!(!permissions.edit || settings?.periods.length === 5) && (
            <Button onClick={addPeriod} type={ButtonType.outline}>
              Добавить период
            </Button>
          )}
        </div>
      </div>
      {settings?.periods.map((item, index) => (
        <div key={`period-${index}`} className="tariff-periods__item">
          <div className="tariff-periods__item-options">
            <Select
              title="Период тарифа"
              onChange={onChangeInput('monthCount', index)}
              isRequired
              isError={item.isMountError}
              errorText={item.isMountError ? 'Поле обязательно для заполнения' : ''}
              value={item.monthCount || undefined}
              options={getOptions(item)}
              disabled={!permissions.edit}
              isDisabledStyle
            />
            <Input
              title="Размер скидки (%)"
              inputType={InputType.numbers}
              placeholder="0"
              status={item.isDiscountError ? InputStatus.error : InputStatus.normal}
              errorText={item.isDiscountError ? 'Слишком большая скидка' : ''}
              maxLength={2}
              value={item.discount?.toString()}
              onChange={onChangeInput('discount', index)}
              disabled={!permissions.edit}
              isDisabledStyle
            />
            {item.isNew ? (
              <div className="close-icon" role="presentation" onClick={onDeletePeriod(index)}>
                <CloseIcon />
              </div>
            ) : (
              <Button disabled={!permissions.edit} onClick={onDeletePeriod(index)} type={ButtonType.tertiary}>
                Удалить период
              </Button>
            )}
          </div>
          <div className="tariff-periods__item-costs">
            <NumberInput
              title="Стоимость тарифа «Lite» (₽)"
              placeholder="0,00"
              value={item.liteCost}
              disabled
              isDisabledStyle
              description={`Стоимость тарифа «Lite» без скидки = ${getPriceWithoutSale(0, item.monthCount || 0)}`}
            />
            <NumberInput
              title="Стоимость тарифа «Medium» (₽)"
              placeholder="0,00"
              value={item.mediumCost}
              disabled
              isDisabledStyle
              description={`Стоимость тарифа «Medium» без скидки = ${getPriceWithoutSale(1, item.monthCount || 0)}`}
            />
            <NumberInput
              title="Стоимость тарифа «Comfort» (₽)"
              placeholder="0,00"
              value={item.comfortCost}
              disabled
              isDisabledStyle
              description={`Стоимость тарифа «Comfort» без скидки = ${getPriceWithoutSale(2, item.monthCount || 0)}`}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default TariffsPeriods;
