import React, { FC } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ToastContainer, cssTransition } from 'react-toastify';

const bounce = cssTransition({
  enter: 'swirl-in-fwd',
  exit: 'swirl-out-bck',
});

const ToastComponent: FC = () => (
  <ToastContainer
    closeOnClick={false}
    closeButton={false}
    className="default-scrollbar-override notify"
    transition={bounce}
    draggable={false}
  />
);

export default ToastComponent;
