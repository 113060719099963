import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IInformationPanelStatus } from '../types';
import PieChart from '../../../ui/pieChart';
import { objectInfoDefaultStatuses } from '../config';
import { getDevicesNumber, getPercent, updateStatuses } from '../functions';
import { YMapMarker } from '../../../yandexMap';
import { IObjectPlaceMark } from './types';
import InfoPopUpComponent from './infoPopUp';

const ObjectPlaceMark: FC<IObjectPlaceMark> = ({ item, dispatcherPanelApiParams, onClick, activePlaceMarkId }) => {
  const [statuses, setStatuses] = useState<IInformationPanelStatus[]>([...objectInfoDefaultStatuses]);

  const [showBalloon, setShowBallon] = useState(false);

  useEffect(() => {
    if (item) {
      setStatuses(updateStatuses(item, dispatcherPanelApiParams || {}));
    }
  }, [dispatcherPanelApiParams]);

  const onMouseEnter = useCallback(() => {
    setShowBallon(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    if (showBalloon) {
      setShowBallon(false);
    }
  }, [showBalloon]);

  const devicesNumber = useMemo(() => getDevicesNumber(statuses), [statuses]);

  return item ? (
    <YMapMarker coordinates={[item.longitude, item.latitude]} draggable>
      <PieChart
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        label={devicesNumber}
        segments={statuses.map((element) => ({
          color: element.colorCode,
          value: getPercent(element?.value, devicesNumber),
        }))}
        className={`object-place-mark__pie-chart ${
          activePlaceMarkId
            ? activePlaceMarkId === item.objectId
              ? 'object-place-mark__pie-chart_active'
              : 'object-place-mark__pie-chart_inactive'
            : ''
        }`}
      />
      {showBalloon && <InfoPopUpComponent statuses={statuses} data={item} />}
    </YMapMarker>
  ) : null;
};

export default ObjectPlaceMark;
