import { IAccessPoint, ITreeNode, TreeNodeType } from '../../../../typings/treeNode';
import { EVisualizerModes, ISchemaOpacity } from '../../../systemTabs/schemaTab/types';
import { EPaperGroupTypes, TPaperEvent, TPaperGroup, TPaperLayer, TPaperPath, TPaperPoint } from '../../types';

export enum ECursors {
  default = 'default',
  pointer = 'pointer',
  crosshair = 'crosshair',
  nwResize = 'nw-resize',
  neResize = 'ne-resize',
  swResize = 'sw-resize',
  seResize = 'se-resize',
  move = 'move',
  grabbing = 'grabbing',
  grab = 'grab',
  zoomIn = 'zoom-in',
  zoomOut = 'zoom-out',
}

export interface IGroupData {
  key: string;
  parentKey: string;
  type: TreeNodeType;
  groupType: EPaperGroupTypes;
}

export interface ITreeRenderFunctions {
  setActiveGroup: (group: TPaperGroup, e: TPaperEvent) => void;
  doubleClickGroup: (group: TPaperGroup) => void;
  setCursor: (cursor: ECursors) => void;
  haveBeenChanges: () => void;
  moveControls: () => void;
}

export interface ITreeRenderData {
  objects: ITreeNode[];
  parentGroup: TPaperGroup;
  key: string;
  editPermission: boolean;
}

export interface IEntityRenderData {
  mode: EVisualizerModes;
  opacityValues: ISchemaOpacity;
  type: TreeNodeType;
  object: ITreeNode | IAccessPoint;
  objectGroup: TPaperGroup;
  data: ITreeRenderData;
  renderFunctions: ITreeRenderFunctions;
  key: string;
  labelsLayer: TPaperLayer;
}

export interface ICircleParams {
  center: TPaperPoint;
  radius: number;
  needLabel: boolean;
  title?: string;
  labelOffset?: ILabelPosition;
}

export interface IPaperHandlers {
  onFrame?: (e: TPaperEvent) => void;
  onMouseDown?: (e: TPaperEvent) => void;
  onMouseDrag?: (e: TPaperEvent) => void;
  onMouseUp?: (e: TPaperEvent) => void;
  onClick?: (e: TPaperEvent) => void;
  onDoubleClick?: (e: TPaperEvent) => void;
  onMouseMove?: (e: TPaperEvent) => void;
  onMouseEnter?: (e: TPaperEvent) => void;
  onMouseLeave?: (e: TPaperEvent) => void;
}

export interface ILabelPosition {
  x: number;
  y: number;
}

export interface IPolygonParams {
  path?: TPaperPath;
  title: string;
  needLabel: boolean;
  labelOffset?: ILabelPosition;
}

export interface IRectangleParams {
  from: TPaperPoint;
  to: TPaperPoint;
  title: string;
  needLabel: boolean;
  labelOffset?: ILabelPosition;
}

export type TPaperStyle = {
  strokeColor?: paper.Color | null;
  strokeWidth?: number;
  strokeCap?: string;
  strokeJoin?: string;
  strokeScaling?: boolean;
  dashOffset?: number;
  dashArray?: number[];
  miterLimit?: number;
  fillColor?: paper.Color | null;
  fillRule?: string;
  shadowColor?: paper.Color | null;
  shadowBlur?: number;
  shadowOffset?: paper.Point;
  selectedColor?: paper.Color | null;
  fontFamily?: string;
  fontWeight?: string | number;
  fontSize?: number | string;
  leading?: number | string;
  justification?: string;
};
