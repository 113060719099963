import classNames from 'classnames';
import React, { FC } from 'react';
import { IErrorPlaceholder } from './types';

const ErrorPlaceholder: FC<IErrorPlaceholder> = (props) => {
  const {
    text,
    icon,
    containerClassName = '',
    iconContainerClassName = '',
    textContainerClassName = '',
    children,
  } = props;

  return (
    <div className={classNames('error-placeholder', containerClassName)}>
      {icon && <div className={classNames('error-placeholder__icon-container', iconContainerClassName)}>{icon}</div>}
      {text && <span className={classNames('error-placeholder__text', textContainerClassName)}>{text}</span>}
      {children}
    </div>
  );
};

export default ErrorPlaceholder;
