import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from './axios';

export const getRequest = async (url: string, config?: AxiosRequestConfig): Promise<any> => {
  const result = await axiosGet(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const getRequestWithArrayInParams = async <T,>(url: string, config?: AxiosRequestConfig): Promise<T> => {
  const result = await axiosGet(url, {
    ...config,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return result.data;
};

export const postRequest = async (url: string, data: any, config?: AxiosRequestConfig) => {
  const result = await axiosPost(url, data, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const postRequestWithArrayInParams = async <T,>(
  url: string,
  data: any,
  config?: AxiosRequestConfig
): Promise<T> => {
  const result = await axiosPost(url, data, {
    ...config,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return result.data;
};

export const putRequest = async (url: string, data: any, config?: AxiosRequestConfig) => {
  const result = await axiosPut(url, data, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const deleteRequest = async (url: string, config?: AxiosRequestConfig) => {
  const result = await axiosDelete(url, {
    ...config,
    params: { ...config?.params },
  });
  return result.data;
};

export const getFileRequest = async (url: string, config?: AxiosRequestConfig): Promise<any> => {
  const result = await axiosGet(url, {
    ...config,
    responseType: 'blob',
    params: { ...config?.params },
  });
  return result;
};

export const postFileRequest = async (url: string, config?: AxiosRequestConfig): Promise<any> => {
  const result = await axiosPost(
    url,
    {},
    {
      ...config,
      params: { ...config?.params },
      responseType: 'blob',
    }
  );
  return result;
};
