import { ButtonType } from '../../ui/button/types';
import { ITabNavButton } from './types';

export const tabNavButtonsDefault = (
  cancelButton: ITabNavButton = {},
  saveButton: ITabNavButton = {},
  nextButton: ITabNavButton = {}
): ITabNavButton[] => [
  {
    text: 'Отмена',
    type: ButtonType.tertiary,
    className: 'tab-nav-buttons__button tab-nav-buttons__button-cancel',
    ...cancelButton,
  },
  {
    text: 'Сохранить',
    type: ButtonType.secondary,
    className: 'tab-nav-buttons__button tab-nav-buttons__button-save',
    ...saveButton,
  },
  {
    text: 'Далее',
    type: ButtonType.primary,
    className: 'tab-nav-buttons__button tab-nav-buttons__button-next',
    ...nextButton,
  },
];
