import React, { FC } from 'react';

const UsersIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7887 20.2756C17.7073 20.2756 21.9113 21.021 21.9113 23.9983C21.9113 26.9756 17.7353 27.7423 12.7887 27.7423C7.86868 27.7423 3.66602 27.0036 3.66602 24.025C3.66602 21.0463 7.84068 20.2756 12.7887 20.2756Z"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7889 16.0263C9.55959 16.0263 6.94092 13.409 6.94092 10.1797C6.94092 6.95034 9.55959 4.33301 12.7889 4.33301C16.0169 4.33301 18.6356 6.95034 18.6356 10.1797C18.6476 13.397 16.0476 16.0143 12.8303 16.0263H12.7889Z"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.9775 14.5088C24.1122 14.2088 25.7562 12.3768 25.7602 10.1594C25.7602 7.97412 24.1669 6.16078 22.0775 5.81812"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7939 19.6428C26.8619 19.9508 28.3059 20.6762 28.3059 22.1695C28.3059 23.1975 27.6259 23.8642 26.5273 24.2815"
      stroke="#FAFAFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UsersIcon;
