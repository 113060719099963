import React, { FC } from 'react';

const MailingIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0013 6.66699H6.0013C4.16035 6.66699 2.66797 8.15938 2.66797 10.0003V22.0131C2.66797 23.8541 4.16035 25.3465 6.0013 25.3465H26.0013C27.8423 25.3465 29.3346 23.8541 29.3346 22.0131V10.0003C29.3346 8.15938 27.8423 6.66699 26.0013 6.66699ZM26.8605 8.19379C26.6002 8.06975 26.3089 8.00033 26.0013 8.00033H6.0013C5.69374 8.00033 5.40239 8.06975 5.14207 8.19379L16.0013 17.1367L26.8605 8.19379ZM4.1921 22.8668C4.19827 22.8605 4.20459 22.8543 4.21106 22.8482L11.9709 15.5448L4.24417 9.18161C4.22732 9.16774 4.21133 9.15321 4.1962 9.13809C4.07126 9.39917 4.0013 9.69158 4.0013 10.0003V22.0131C4.0013 22.3185 4.06973 22.6079 4.1921 22.8668ZM5.1303 23.814C5.39359 23.9416 5.68909 24.0131 6.0013 24.0131H26.0013C26.3135 24.0131 26.609 23.9416 26.8723 23.814L18.9941 16.3993L16.4251 18.5149C16.1789 18.7177 15.8237 18.7177 15.5775 18.5149L13.0085 16.3993L5.1303 23.814ZM28.0013 22.0131C28.0013 22.3185 27.9329 22.6079 27.8105 22.8668C27.8043 22.8605 27.798 22.8543 27.7915 22.8482L20.0317 15.5448L27.7584 9.18161C27.7753 9.16774 27.7913 9.15321 27.8064 9.13809C27.9313 9.39917 28.0013 9.69158 28.0013 10.0003V22.0131Z"
      fill="white"
    />
  </svg>
);

export default MailingIcon;
