import React, { FC, useCallback } from 'react';
import Button from '../ui/button';
import { ButtonSize, ButtonType } from '../ui/button/types';
import Modal from '../ui/modal';
import { IChangeMainSubscriberModal } from './types';
import Input from '../ui/input';
import { ISubscriber } from '../../typings/subscribers';
import Checkbox from '../ui/checkbox';
import Scrollbar from '../ui/scrollbar';

const ChangeMainSubscriberModal: FC<IChangeMainSubscriberModal> = (props) => {
  const {
    isOpen = false,
    onCancel = () => {},
    onOk = () => {},
    subscriberFlats = [],
    newMainSubscriber = '',
    data = [],
    onChange = () => {},
  } = props;

  const getFlatIndex = useCallback(
    (id: string) => subscriberFlats.findIndex((item) => item.flatId === id),
    [subscriberFlats]
  );

  const getFullName = useCallback(
    (subscriber: ISubscriber) => `${subscriber.lastName} ${subscriber.firstName} ${subscriber.middleName} `,
    []
  );

  const onCheck = useCallback(
    (flatIndex: number) => (e: boolean) => {
      onChange(flatIndex, { ...subscriberFlats[flatIndex], isConfirm: e });
    },
    [onChange, subscriberFlats]
  );

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Подтвердите замену главного абонента"
      width={768}
      showCloseIcon
      wrapClassName="change-main-subscriber__wrapper"
      centered
      footer={
        <div className="change-main-subscriber__button-container">
          <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onCancel}>
            Отменить
          </Button>
          <Button size={ButtonSize.small} onClick={onOk}>
            Подтвердить
          </Button>
        </div>
      }
    >
      <Scrollbar>
        <div className="change-main-subscriber__content">
          {data.map((item) => {
            const flatIndex = getFlatIndex(item.flatId);
            const flat = subscriberFlats[flatIndex];
            const oldSubscriberName = getFullName(item.subscriber);
            return flat ? (
              <div className="change-main-subscriber__flat" key={item.flatId}>
                <div className="change-main-subscriber__flat-address">
                  {item.address || flat.address}, кв. {item.flatNumber}
                </div>
                <div className="change-main-subscriber__flat-inputs">
                  <Input title="Старый главный абонент" value={oldSubscriberName} disabled isDisabledStyle />
                  <Input title="Новый главный абонент" value={newMainSubscriber} disabled isDisabledStyle />
                  <Checkbox checked={flat.isConfirm} onChange={onCheck(flatIndex)} />
                </div>
                {item.message && <div className="change-main-subscriber__flat-message">{item.message}</div>}
              </div>
            ) : null;
          })}
        </div>
      </Scrollbar>
    </Modal>
  );
};

export default ChangeMainSubscriberModal;
