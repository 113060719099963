import { AntTreeNodeProps, DataNode } from 'antd/lib/tree';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Tree } from 'antd';
import { Key } from 'antd/es/table/interface';
import Loader from '../loader';
import { ELoaderColor } from '../loader/types';
import Scrollbar from '../scrollbar';
import { IDirectoryTree } from './types';
import ChevronDown from '../../../assets/svg/icons/chevronDown';

const DirectoryTree: FC<IDirectoryTree> = (props) => {
  const {
    treeData = [],
    virtual = true,
    defaultExpandAll = false,
    selectedKeys = [],
    onSelect = () => {},
    parents = [],
    expandDefault = () => [],
    loading = false,
    needScrollToView = false,
  } = props;
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const wasDefaultExpanded = useRef<boolean>(false);

  const treeRef = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = (id: string) => {
    if (treeRef.current && id) {
      const element = document.getElementById(id);
      if (element) {
        element.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
    }
  };

  useEffect(() => {
    if (needScrollToView && selectedKeys.length) {
      scrollIntoView(selectedKeys[0]);
    }
  }, [selectedKeys]);

  useEffect(() => {
    if (treeData.length && !wasDefaultExpanded.current) {
      setExpandedKeys(expandDefault(treeData));
      wasDefaultExpanded.current = true;
    }
  }, [treeData]);

  useEffect(() => {
    if (parents.length) {
      const result = Array.from(new Set([...parents, ...expandedKeys]));
      setExpandedKeys(result);
    }
  }, [parents]);

  const getAllKeys = useCallback((data: DataNode[], keys: string[]) => {
    data.forEach((item) => {
      keys.push(item.key.toString());
      getAllKeys(item.children || [], keys);
    });
  }, []);

  useEffect(() => {
    if (defaultExpandAll) {
      const keys: string[] = [];
      getAllKeys(treeData, keys);
      setExpandedKeys(keys);
    }
  }, [defaultExpandAll]);

  const clickOnTree = useCallback(
    (data: AntTreeNodeProps) => () => {
      const dataKey = data.eventKey?.toString() || '';
      setExpandedKeys(data.expanded ? expandedKeys.filter((key) => key !== dataKey) : [...expandedKeys, dataKey]);
    },
    [expandedKeys]
  );

  const renderIcon = useCallback(
    (data: AntTreeNodeProps) => (
      <span className="directory-tree__switcher" role="presentation" onClick={clickOnTree(data)}>
        <ChevronDown />
      </span>
    ),
    [clickOnTree]
  );

  return (
    <div ref={treeRef} className="directory-tree">
      <Scrollbar disableIndent>
        {loading ? (
          <div className="directory-tree__loader-container">
            <Loader color={ELoaderColor.blue} />
          </div>
        ) : (
          <div className="directory-tree__content-wrapper">
            <Tree.DirectoryTree
              treeData={treeData}
              virtual={virtual}
              onSelect={onSelect}
              defaultExpandAll={defaultExpandAll}
              showIcon={false}
              switcherIcon={renderIcon}
              selectedKeys={selectedKeys}
              expandedKeys={expandedKeys}
            />
          </div>
        )}
      </Scrollbar>
    </div>
  );
};

export default DirectoryTree;
