import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Slider } from 'antd';
import { EFieldTypes, InputStatus } from '../input/types';
import { ISliderInput } from './types';
import Tooltip from '../tooltip';
import InfoIcon from '../../../assets/svg/icons/info';

const InputSlider: FC<ISliderInput> = (props) => {
  const {
    title,
    value = '',
    status = InputStatus.normal,
    disabled = false,
    onChange = () => {},
    containerClassName = '',
    inputClassName = '',
    titleClassName = '',
    maxLength,
    minLength,
    errorText = '',
    errorTextClassName = '',
    description = '',
    fieldType = EFieldTypes.default,
    onFocus = () => {},
    onClick = () => {},
    onBlur = () => {},
    isRequired = false,
    isDisabledStyle = false,
    textInfo,
    range = false,
    defaultValue,
    marks,
    min = 0,
    max = 100,
  } = props;

  const handleOnChange = useCallback(
    (currentValue: any) => {
      onChange(currentValue);
    },
    [onChange]
  );

  const inputProps = useMemo(
    () => ({
      className: classNames('input__input default-scrollbar-override', inputClassName, {
        'input__input_default-disabled': isDisabledStyle,
      }),
      value,
      status,
      disabled,
      onChange: handleOnChange,
      onWheel: (e: any) => e.target.blur(),
      maxLength,
      minLength,
      onFocus,
      onClick,
      onBlur,
      fieldType,
      range,
      defaultValue,
      marks,
      min,
      max,
    }),
    [
      defaultValue,
      disabled,
      fieldType,
      handleOnChange,
      inputClassName,
      isDisabledStyle,
      marks,
      max,
      maxLength,
      min,
      minLength,
      onBlur,
      onClick,
      onFocus,
      range,
      status,
      value,
    ]
  );

  return (
    <div className={classNames('input input-slider', containerClassName)}>
      {title && (
        <span className={classNames('input__title-container')}>
          <span className={classNames('input__title', titleClassName)}>
            {title}
            {isRequired && <span className="input__title-required">*</span>}
          </span>
          {textInfo && (
            <Tooltip title={textInfo} placement="bottom">
              <div className="input__title-info-icon">
                <InfoIcon />
              </div>
            </Tooltip>
          )}
        </span>
      )}
      <Slider {...inputProps} />
      {description && <div className="input-description">{description}</div>}
      {errorText && <span className={classNames('input__error', errorTextClassName)}>{errorText}</span>}
    </div>
  );
};

export default InputSlider;
