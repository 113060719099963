import React from 'react';

export enum EFieldTypes {
  default = 'default',
  textArea = 'textArea',
  password = 'password',
  range = 'range',
}

export enum InputStatus {
  normal = '',
  error = 'error',
  warning = 'warning',
}

export enum InputType {
  number = 'number',
  numbers = 'numbers',
  text = 'text',
  email = 'email',
}

export interface IInput {
  title?: string | React.ReactNode;
  placeholder?: string;
  value?: string;
  status?: InputStatus;
  disabled?: boolean;
  onChange?: (value: string, event?: React.ChangeEvent) => void;
  onClick?: () => void;
  containerClassName?: string;
  inputClassName?: string;
  titleClassName?: string;
  maxLength?: number;
  minLength?: number;
  maxValue?: number;
  minValue?: number;
  errorText?: string;
  errorTextClassName?: string;
  inputType?: InputType;
  description?: string;
  textInfo?: string | React.ReactNode;
  suffix?: string | React.ReactNode;
  prefix?: string | React.ReactNode;
  fieldType?: EFieldTypes;
  autoSize?: { minRows: number; maxRows: number };
  onFocus?: () => void;
  onBlur?: () => void;
  isRequired?: boolean;
  isDisabledStyle?: boolean;
}

export interface IInputValue {
  value: any;
  status: InputStatus;
  errorText?: string;
  isRequired?: boolean;
}

export interface IInputTValue<T> {
  value: T;
  status: InputStatus;
  errorText?: string;
  isRequired?: boolean;
}

export interface IInputTAdressValue {
  searchValue: string;
  fiasId: string;
  address: string;
  fullAddress: string;
  shortAddress: string;
  isFinalAddress: boolean;
  status: InputStatus;
  errorText?: string;
  isRequired?: boolean;
}

export const defaultAddressValue: IInputTAdressValue = {
  searchValue: '',
  fiasId: '',
  fullAddress: '',
  shortAddress: '',
  isFinalAddress: false,
  address: '',
  status: InputStatus.normal,
  errorText: '',
  isRequired: true,
};

export const defaultNotRequiredValue = {
  value: '',
  status: InputStatus.normal,
  errorText: '',
  isRequired: false,
};

export const defaultRequiredValue = {
  ...defaultNotRequiredValue,
  isRequired: true,
};
