import React, { FC } from 'react';

const DrawContour: FC = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 17C7.5 14.5 13 14.5 13 14.5L15 8L9.5 2.5L3 5C3 5 3 11 1 17ZM1 17L8 10M13 0.5L17.5 5"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DrawContour;
