import React, { FC, useCallback, useEffect } from 'react';
import { useApi } from '../../../hooks/useApi';
import { getRequest } from '../../../api';
import { getKeyWriterInfoUrl, getKeyWriterUrl } from '../../../constants/api';
import { IKeysUtilityData } from '../../../typings/keysUtility';
import Tooltip from '../tooltip';
import InfoIcon from '../../../assets/svg/icons/info';
import { EPagesWithKeys, IKeysUtility } from './types';
import Button from '../button';
import { ButtonSize, ButtonType } from '../button/types';
import DownloadIcon from '../../../assets/svg/icons/download';

const KeysUtility: FC<IKeysUtility> = ({ pageStyle }) => {
  const { data: info, sendRequest: getInfo } = useApi<IKeysUtilityData>(getRequest);
  const { data: url, sendRequest: getUrl } = useApi<string>(getRequest);

  useEffect(() => {
    getInfo(getKeyWriterInfoUrl());
  }, []);

  useEffect(() => {
    if (url || info?.url) {
      window.open(url || info?.url);
    }
  }, [url]);

  const onClick = useCallback(() => {
    getUrl(getKeyWriterUrl());
  }, [getUrl]);

  return info ? (
    pageStyle === EPagesWithKeys.mainPage ? (
      <div className="keys-utility__main-page" role="presentation" onClick={onClick}>
        <div className="keys-utility__main-page-info">Утилита ({info?.version || '1.2.0'})</div>
        <div className="keys-utility__main-page-download">
          <DownloadIcon />
        </div>
        <Tooltip
          title={
            <div>
              Утилита для работы <br /> с ключами – версия {info?.version || '1.2.0'}
            </div>
          }
          placement="bottomRight"
        >
          <span className="keys-utility__main-page-tooltip">
            <InfoIcon />
          </span>
        </Tooltip>
      </div>
    ) : pageStyle === EPagesWithKeys.keysPage ? (
      <div className="keys-utility__keys-page">
        <div className="keys-utility__keys-page-info">Утилита для работы с ключами – версия {info?.version}</div>
        <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onClick}>
          Скачать <DownloadIcon />
        </Button>
      </div>
    ) : null
  ) : null;
};

export default KeysUtility;
