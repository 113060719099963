import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IChangeStatusModal } from './types';
import { ISelectOption } from '../../ui/select/types';
import { EFeedbackStatus, mapFeedbackSequenceStatuses, mapFeedbackStatusToText } from '../../../typings/feedback';
import Select from '../../ui/select';
import { postRequest } from '../../../api';
import { feedbackHistoryMediaUrl, feedbackStatusUrl, getUsersListUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import { IApiResponse } from '../../../typings/api';
import { IUser } from '../../../typings/users';
import Input from '../../ui/input';
import { EFieldTypes } from '../../ui/input/types';
import FileUploader from '../../ui/fileUploader';
import { videoAndImgTypes } from '../../../constants/fileTypes';

const limit = 5242880;

const ChangeStatusModal: FC<IChangeStatusModal> = (props) => {
  const {
    isOpen = false,
    onCancel = () => {},
    defaultStatus = '',
    onOk = () => {},
    feedbackId,
    assignedUserEmailDefault = '',
    assignedUserFioDefault = '',
    assignedUserIdDefault = '',
  } = props;

  const [imgList, setImgList] = useState<File[] | null>([]);

  const { sendRequest: changeStatus, loading: changeStatusLoading } = useApi(postRequest);

  const { sendRequest: addMediaInHistory, loading: addMediaInHistoryLoading } = useApi(postRequest);

  const { data: users, sendRequest: getUsersList, loading: usersLoading } = useApi<IApiResponse<IUser>>(postRequest);

  const [status, setStatus] = useState<string>('');

  const [assignedUserEmail, setAssignedUserEmail] = useState<string>(assignedUserEmailDefault);

  const [assignedUserFio, setAssignedUserFio] = useState<string>(assignedUserFioDefault);

  const [assignedUserId, setAssignedUserId] = useState<string>(assignedUserIdDefault);

  const [comment, setComment] = useState<string>('');

  const getUsers = useCallback(() => {
    getUsersList(getUsersListUrl(), { page: 0, pageSize: -1 }, { params: { count: 0 } });
  }, [getUsersList]);

  useEffect(() => {
    if (status === EFeedbackStatus.assigned) {
      getUsers();
    }
  }, [status]);

  useEffect(() => {
    setStatus(defaultStatus);
  }, [defaultStatus]);

  useEffect(() => {
    setAssignedUserId(assignedUserIdDefault);
  }, [assignedUserIdDefault]);

  useEffect(() => {
    if (isOpen) {
      setImgList([]);
      setStatus('');
      setAssignedUserEmail(assignedUserEmailDefault);
      setAssignedUserFio(assignedUserFioDefault);
      setAssignedUserId(assignedUserIdDefault);
      setComment('');
    }
  }, [isOpen]);

  const onSave = useCallback(async () => {
    const res = await changeStatus(feedbackStatusUrl(feedbackId || ''), {
      status,
      comment,
      assignedUserEmail,
      assignedUserFio,
      assignedUserId,
    });
    if (!res?.response?.data) {
      if (imgList?.length) {
        const data = new FormData();
        imgList?.forEach((item, index) => data.append(`file${index + 1}`, item));
        await addMediaInHistory(feedbackHistoryMediaUrl(res as string), data);
      }
      onOk();
    }
  }, [
    addMediaInHistory,
    assignedUserEmail,
    assignedUserFio,
    assignedUserId,
    changeStatus,
    comment,
    feedbackId,
    imgList,
    onOk,
    status,
  ]);

  const setUserData = useCallback(
    (id: string) => {
      setAssignedUserId(id);
      const user = users?.items?.find((item) => item.id === id);
      if (user) {
        setAssignedUserEmail(user.email);
        setAssignedUserFio(`${user.middleName} ${user.firstName} ${user.lastName}`);
      }
    },
    [users?.items]
  );

  const handleOnChangeUser = useCallback(
    (e: string | number) => {
      setUserData(e.toString());
    },
    [setUserData]
  );

  const priorityOptions = useMemo(() => {
    const result: ISelectOption[] = [];
    if (defaultStatus) {
      const statuses = mapFeedbackSequenceStatuses.get(defaultStatus as EFeedbackStatus);
      statuses?.forEach((item) =>
        result.push({
          value: item,
          title: mapFeedbackStatusToText.get(item) || '',
        })
      );
    }
    return result;
  }, [defaultStatus]);

  const usersSelectItems = useMemo(
    () =>
      users?.items?.map<ISelectOption>((item) => ({
        value: item.id,
        title: `${item.lastName} ${item.firstName}`,
      })),
    [users?.items]
  );

  const renderAdditionalFields = useMemo(() => {
    let content = null;

    switch (status) {
      case EFeedbackStatus.assigned: {
        content = (
          <>
            <Select
              loading={usersLoading}
              title="Исполнитель"
              value={assignedUserId}
              onChange={handleOnChangeUser}
              options={usersSelectItems}
            />
            <Input
              title="Комментарий для исполнителя"
              placeholder="Оставьте комментарий"
              value={comment}
              onChange={setComment}
              autoSize={{ minRows: 4, maxRows: 4 }}
              maxLength={500}
              fieldType={EFieldTypes.textArea}
            />
          </>
        );
        break;
      }
      case EFeedbackStatus.finished: {
        content = (
          <>
            <Input
              title="Комментарий для диспетчера"
              placeholder="Оставьте комментарий"
              value={comment}
              onChange={setComment}
              autoSize={{ minRows: 4, maxRows: 4 }}
              maxLength={500}
              fieldType={EFieldTypes.textArea}
              isRequired
            />
            <FileUploader
              quantityLimit={5}
              limit={limit}
              isSingleFile={false}
              acceptFileTypes={videoAndImgTypes}
              data={imgList}
              setImgs={setImgList}
            />
          </>
        );
        break;
      }
      case EFeedbackStatus.cancelled:
      case EFeedbackStatus.done: {
        content = (
          <>
            <Input
              title="Комментарий для абонента"
              placeholder="Оставьте комментарий"
              value={comment}
              onChange={setComment}
              autoSize={{ minRows: 4, maxRows: 4 }}
              maxLength={500}
              fieldType={EFieldTypes.textArea}
              isRequired
            />
            <FileUploader
              quantityLimit={5}
              limit={limit}
              isSingleFile={false}
              acceptFileTypes={videoAndImgTypes}
              data={imgList}
              setImgs={setImgList}
            />
          </>
        );
        break;
      }
      default: {
        break;
      }
    }

    return content;
  }, [assignedUserId, comment, handleOnChangeUser, imgList, status, usersLoading, usersSelectItems]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Изменение статуса"
      width={416}
      showCloseIcon
      wrapClassName="change-priority__wrapper"
      centered
      footer={
        <div className="change-priority__button-container">
          <Button
            type={ButtonType.outline}
            size={ButtonSize.small}
            onClick={onCancel}
            disabled={changeStatusLoading || addMediaInHistoryLoading}
          >
            Отмена
          </Button>
          <Button
            disabled={
              !status ||
              changeStatusLoading ||
              addMediaInHistoryLoading ||
              ((status === EFeedbackStatus.cancelled ||
                status === EFeedbackStatus.done ||
                status === EFeedbackStatus.finished) &&
                !comment)
            }
            size={ButtonSize.small}
            onClick={onSave}
          >
            Сохранить
          </Button>
        </div>
      }
    >
      <Select title="Статус" value={status} onChange={(e) => setStatus(e.toString())} options={priorityOptions} />
      {renderAdditionalFields}
    </Modal>
  );
};

export default ChangeStatusModal;
