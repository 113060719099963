import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getHelpInstructionFile } from '../../../constants/api';
import { paths } from '../../../constants/paths';
import { ellipsisStringByLimit } from '../../../utils/common';
import ButtonDownload from '../buttonDownload';
import { IInstructionQuestionComponent } from './types';
import { dateDefaultFormat } from '../../../constants/date';

const InstructionQuestion: FC<IInstructionQuestionComponent> = (props) => {
  const { instruction, answerLength = 250 } = props;

  if (!instruction || !instruction.question || !instruction.answer) {
    return null;
  }

  return (
    <div className="instruction-question">
      <div className="instruction-question__header">
        <span className="instruction-question__title instruction-question__ellipsis-2-line">
          {instruction.question}
        </span>
      </div>
      <div className="instruction-question__body">
        <Link className="instruction-question__text" to={`${paths.helpInstructions}/${instruction.id}`}>
          <div dangerouslySetInnerHTML={{ __html: ellipsisStringByLimit(instruction.answer, answerLength) }} />
        </Link>
        <div className="instruction-question__toolbox">
          <Link className="instruction-question__link" to={`${paths.helpInstructions}/${instruction.id}`}>
            Подробнее
          </Link>
          <div className="instruction-question__toolbox-items">
            {instruction.updateDate && (
              <span className="instruction-question__date">
                {moment(instruction.updateDate || '').format(dateDefaultFormat)}
              </span>
            )}
            {instruction.id && instruction.fileIsAvailable && (
              <ButtonDownload downloadUrl={getHelpInstructionFile(instruction.id)}>Скачать файл</ButtonDownload>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstructionQuestion;
