import React, { FC, useCallback } from 'react';
import moment from 'moment';
import { IRoleTabComponent } from '../types';
import RoleCustomizationTable from '../../roles/roleCustomizationTable';
import { dateDefaultFormat } from '../../../constants/date';
import { IRole } from '../../../typings/roles';

const RoleBaseTab: FC<IRoleTabComponent> = (props) => {
  const {
    role = null,
    isCreate,
    permissions = {},
    wasChange = false,
    setWasChange = () => {},
    updateRole = () => {},
    nextTabId = '',
    resetNextTabId = () => {},
  } = props;

  const onSaved = useCallback(
    (data: IRole) => {
      updateRole(data);
      resetNextTabId();
    },
    [resetNextTabId, updateRole]
  );

  return (
    <div className="role-base-tab">
      {!role?.isReadOnly && !isCreate && (
        <div className="role-base-tab__about">
          <div className="role-base-tab__about-item">
            <div className="role-base-tab__about-item-label">Автор</div>
            <div className="role-base-tab__about-item-value">{role?.creatorName || '-'}</div>
          </div>
          <div className="role-base-tab__about-item">
            <div className="role-base-tab__about-item-label">Дата создания</div>
            <div className="role-base-tab__about-item-value">
              {role?.createDate ? moment(role?.createDate).format(dateDefaultFormat) : '-'}
            </div>
          </div>
          <div className="role-base-tab__about-item">
            <div className="role-base-tab__about-item-label">Автор изменения</div>
            <div className="role-base-tab__about-item-value">{role?.updaterName || '-'}</div>
          </div>
          <div className="role-base-tab__about-item">
            <div className="role-base-tab__about-item-label">Дата изменения</div>
            <div className="role-base-tab__about-item-value">
              {role?.createDate ? moment(role?.updateDate).format(dateDefaultFormat) : '-'}
            </div>
          </div>
        </div>
      )}
      <RoleCustomizationTable
        wasChange={wasChange}
        setWasChange={setWasChange}
        onSaved={onSaved}
        permissions={permissions}
        role={role}
        nextTabId={nextTabId}
        resetNextTabId={resetNextTabId}
      />
    </div>
  );
};

export default RoleBaseTab;
