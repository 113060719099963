import React, { FC, useCallback, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Modal from '../../ui/modal';
import { IHistoryCommentModal } from './types';
import { postRequest } from '../../../api';
import { feedbackHistoryCommentUrl } from '../../../constants/api';
import { useApi } from '../../../hooks/useApi';
import Input from '../../ui/input';
import { EFieldTypes } from '../../ui/input/types';

const HistoryCommentModal: FC<IHistoryCommentModal> = (props) => {
  const { isOpen = false, onCancel = () => {}, onOk = () => {}, feedbackId } = props;

  const { sendRequest: sendComment, loading: sendCommentLoading } = useApi(postRequest);

  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (isOpen) {
      setComment('');
    }
  }, [isOpen]);

  const onSave = useCallback(async () => {
    const resError = (await sendComment(feedbackHistoryCommentUrl(feedbackId || ''), {
      comment,
    })) as AxiosError;
    if (!resError?.response?.data) {
      onOk();
    }
  }, [sendComment, feedbackId, comment, onOk]);

  return (
    <Modal
      isOpen={isOpen}
      onCancel={onCancel}
      title="Добавление комментария"
      width={416}
      showCloseIcon
      wrapClassName="change-priority__wrapper"
      centered
      footer={
        <div className="change-priority__button-container">
          <Button type={ButtonType.outline} size={ButtonSize.small} onClick={onCancel} disabled={sendCommentLoading}>
            Отмена
          </Button>
          <Button disabled={!comment || sendCommentLoading} size={ButtonSize.small} onClick={onSave}>
            Отправить
          </Button>
        </div>
      }
    >
      <Input
        title="Внутренний комментарий"
        placeholder="Оставьте комментарий"
        value={comment}
        onChange={setComment}
        autoSize={{ minRows: 4, maxRows: 4 }}
        maxLength={500}
        fieldType={EFieldTypes.textArea}
      />
    </Modal>
  );
};

export default HistoryCommentModal;
