import { Table, TableProps } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { FilterValue, SorterResult, SortOrder, TableCurrentDataSource } from 'antd/lib/table/interface';
import { IRecipientsTable } from './types';
import { IMailingRecipient, mailingRecipientsStatusNamesMap } from '../../../../../typings/mailing';
import Loader from '../../../../ui/loader';
import { ELoaderColor } from '../../../../ui/loader/types';
import ErrorPlaceholder from '../../../../ui/errorPlaceholder';
import SearchFailIcon from '../../../../../assets/svg/icons/searchFail';
import TrashIcon from '../../../../../assets/svg/icons/trash';
import Checkbox from '../../../../ui/checkbox';
import { selectAllOptionKey } from '../../../../../constants/select';
import { IApiSortField } from '../../../../../typings/api';
import ChevronLeft from '../../../../../assets/svg/icons/chevronLeft';
import ChevronRight from '../../../../../assets/svg/icons/chevronRight';

const RecipientsTable: FC<IRecipientsTable> = (props) => {
  const {
    recipients = [],
    loading = false,
    permission = {},
    removeRecipients = [],
    setRemoveRecipients = () => {},
    onDeleteRecipients = () => {},
    pageSize = 10,
    total = 0,
    onChangePage = () => {},
    currentPage = 0,
    onSort = () => {},
    sortOrders = [],
    isSearch = false,
    onlyView,
  } = props;

  const onDeleteRecord = useCallback(
    (record: IMailingRecipient) => () => onDeleteRecipients([record.id]),
    [onDeleteRecipients]
  );

  const addToDeleteList = useCallback(
    (id: string) => (val: boolean) => {
      if (removeRecipients) {
        if (removeRecipients[0] === selectAllOptionKey)
          setRemoveRecipients(recipients.map((item) => item.id).filter((item) => item !== id));
        else setRemoveRecipients(val ? [...removeRecipients, id] : [...removeRecipients.filter((item) => item !== id)]);
      }
    },
    [recipients, removeRecipients, setRemoveRecipients]
  );

  const deleteAll = useCallback(
    (val: boolean) => setRemoveRecipients(val ? [selectAllOptionKey] : []),
    [setRemoveRecipients]
  );

  const handleOnChangeTable: TableProps<IMailingRecipient>['onChange'] = useCallback(
    (
      pagination: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IMailingRecipient> | SorterResult<IMailingRecipient>[],
      extra: TableCurrentDataSource<IMailingRecipient>
    ) => {
      if (extra.action === 'sort') {
        const sortResults: IApiSortField<IMailingRecipient>[] = [];

        if (Array.isArray(sorter)) {
          sorter.forEach((item) => {
            const prevIsDescend = sortOrders.find((elem) => elem.field === item.field)?.descending;
            const isDescend = item.order === 'descend';
            if (isDescend !== prevIsDescend) {
              sortResults.push({
                field: item.field as keyof IMailingRecipient,
                descending: isDescend,
              });
            }
          });
        } else if (sorter.order) {
          sortResults.push({
            field: sorter.field as keyof IMailingRecipient,
            descending: sorter.order === 'descend',
          });
        }
        onSort(sortResults);
      }
    },
    [onSort, sortOrders]
  );

  const getColumnSortOrder = useCallback(
    (flatSort: IApiSortField<IMailingRecipient> | null) =>
      flatSort ? (flatSort.descending ? 'descend' : 'ascend') : null,
    []
  );

  const getStatus = useCallback(
    (record: IMailingRecipient) => (
      <div className={`mailing__status mailing__status_${record.status}`}>
        {record.status && mailingRecipientsStatusNamesMap.get(record.status)}
      </div>
    ),
    []
  );

  const renderNameOrAddress = useCallback((record: IMailingRecipient, renderName: boolean) => {
    const addKey = renderName ? 'name' : 'address';

    return (
      <div role="presentation" className="subscribers-table__object-data-container">
        {record.subscriberObjects?.map((object) => {
          let isNameLonger = false;
          if (object.flats && object.flats.length <= 1 && object.flats[0]) {
            const flat = object.flats[0];
            const address = `${flat.buildingAddress || flat.address}${flat.number ? `, кв. ${flat.number}` : ''}`;
            isNameLonger = (object.name?.length || 0) > address.length;
          }
          return (
            <div
              className="subscribers-table__info-object"
              key={`subscribers-table-object-${object.objectId}-${addKey}`}
            >
              <div
                className={classNames('subscribers-table__info-object-name', {
                  'subscribers-table__info-object-name_hide': !renderName,
                  'subscribers-table__info-object-name_name-longer': isNameLonger,
                })}
              >
                {object.name}
              </div>
              <div
                className={classNames('subscribers-table__info-object-flats', {
                  'subscribers-table__info-object-flats_hide': renderName,
                  'subscribers-table__info-object-flats_name-longer': isNameLonger,
                })}
              >
                {object.flats?.map((flat) => (
                  <span key={`subscribers-table-object-apartment-${flat.flatId}-${addKey}`}>
                    {flat.buildingAddress || flat.address}
                    {flat.number ? `, кв. ${flat.number}` : ''}
                  </span>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  }, []);

  const columns = useMemo<ColumnsType<IMailingRecipient>>(
    () =>
      [
        {
          width: '5%',
          title: <Checkbox checked={removeRecipients[0] === selectAllOptionKey} onChange={deleteAll} />,
          hidden: !permission.edit || onlyView,
          render: (record: IMailingRecipient) => (
            <Checkbox
              checked={
                removeRecipients[0] === selectAllOptionKey || !!removeRecipients?.find((item) => item === record.id)
              }
              onChange={addToDeleteList(record.id)}
            />
          ),
        },
        {
          width: '18%',
          title: 'ФИО',
          dataIndex: 'lastName',
          key: 'lastName',
          sorter: {
            multiple: 1,
          },
          sortOrder: getColumnSortOrder(sortOrders.find((item) => item.field === 'lastName') || null) as SortOrder,
          render: (_: any, record: IMailingRecipient) => (
            <span>
              {record.middleName} {record.firstName} {record.lastName}
            </span>
          ),
        },
        {
          width: '18%',
          title: 'Номер телефона',
          dataIndex: 'phone',
          key: 'phone',
          render: (_: any, record: IMailingRecipient) => <span>+{record.phone}</span>,
        },
        {
          width: '18%',
          title: 'E–mail',
          dataIndex: 'email',
          key: 'email',
          render: (_: any, record: IMailingRecipient) => <span>{record.email}</span>,
        },
        {
          width: '18%',
          title: 'Объект',
          dataIndex: 'subscriberObjects',
          key: 'subscriberObjects',
          render: (_: any, record: IMailingRecipient) => renderNameOrAddress(record, true),
        },
        {
          width: '18%',
          title: 'Адрес',
          dataIndex: 'address',
          key: 'address',
          render: (_: any, record: IMailingRecipient) => renderNameOrAddress(record, false),
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
          render: (_: any, record: IMailingRecipient) => (
            <div className="editable-table__data-container">{getStatus(record)}</div>
          ),
          width: '10%',
          hidden: !onlyView,
        },
        {
          width: '5%',
          className: 'table__icon-cell',
          hidden: !permission.edit || onlyView,
          render: (record: IMailingRecipient) => (
            <div
              role="presentation"
              className={classNames('table__icon-container', {
                'recipients-table__icon-hide': removeRecipients.length !== 0,
              })}
            >
              {permission.edit && (
                <div
                  onClick={onDeleteRecord(record)}
                  role="presentation"
                  className="faq-section__item-icon faq-section__item-icon_trash"
                >
                  <TrashIcon />
                </div>
              )}
            </div>
          ),
        },
      ].filter((item) => !item.hidden),
    [
      removeRecipients,
      deleteAll,
      permission.edit,
      onlyView,
      getColumnSortOrder,
      sortOrders,
      addToDeleteList,
      renderNameOrAddress,
      getStatus,
      onDeleteRecord,
    ]
  );

  const data = useMemo(
    () =>
      recipients.map((subscriber) => ({
        ...subscriber,
        key: subscriber.subscriberId,
      })),
    [recipients]
  );

  return (
    <div className="recipients-table subscribers-table table">
      <Table
        columns={columns}
        dataSource={data}
        onChange={handleOnChangeTable}
        loading={{
          spinning: loading,
          indicator: <Loader color={ELoaderColor.blue} />,
        }}
        showSorterTooltip={false}
        locale={{
          emptyText: isSearch ? (
            <ErrorPlaceholder text="По вашему запросу ничего не найдено" icon={<SearchFailIcon />} />
          ) : (
            <ErrorPlaceholder text={<span>Ничего не найдено</span>} icon={<SearchFailIcon />} />
          ),
        }}
        pagination={{
          current: currentPage + 1,
          pageSize,
          hideOnSinglePage: true,
          total,
          onChange: onChangePage,
          showSizeChanger: false,
          showQuickJumper: false,
          prevIcon: <ChevronLeft />,
          nextIcon: <ChevronRight />,
          className: 'editable-table__pagination',
        }}
      />
    </div>
  );
};

export default RecipientsTable;
