import React, { FC } from 'react';

const DownloadIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.08146 10.2907L8.08146 2.2634" stroke="#1444F2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.0255 8.33893L8.08145 10.2909L6.13745 8.33893"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.17 5.41867H11.792C13.1487 5.41867 14.248 6.518 14.248 7.87534L14.248 11.1313C14.248 12.4847 13.1513 13.5813 11.798 13.5813L4.37132 13.5813C3.01465 13.5813 1.91465 12.4813 1.91465 11.1247L1.91465 7.868C1.91465 6.51534 3.01199 5.41867 4.36465 5.41867H4.99265"
      stroke="#1444F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadIcon;
