import { message } from 'antd';
import { ArgsProps, ConfigOnClose, MessageType } from 'antd/lib/message';

const initMessage = () =>
  message.config({
    duration: 3,
    maxCount: 3,
  });

const success = (content: ArgsProps, onClose?: ConfigOnClose, duration?: number | (() => void)): MessageType =>
  message.success({ ...content, className: 'message__success' }, duration, onClose);

const info = (content: ArgsProps, onClose?: ConfigOnClose, duration?: number | (() => void)): MessageType =>
  message.info({ ...content, className: 'message__info' }, duration, onClose);

const error = (content: ArgsProps, onClose?: ConfigOnClose, duration?: number | (() => void)): MessageType =>
  message.error({ ...content, className: 'message__error' }, duration, onClose);

const warning = (content: ArgsProps, onClose?: ConfigOnClose, duration?: number | (() => void)): MessageType =>
  message.warning({ ...content, className: 'message__warning' }, duration, onClose);

const loading = (content: ArgsProps, onClose?: ConfigOnClose, duration?: number | (() => void)): MessageType =>
  message.loading({ ...content, className: 'message__loading' }, duration, onClose);

const Message = {
  initMessage,
  success,
  info,
  error,
  warning,
  loading,
};

export default Message;
