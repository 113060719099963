import React, { FC, useCallback, useMemo } from 'react';
import Table, { ColumnType } from 'antd/lib/table';
import classNames from 'classnames';
import { ITariffTable } from './types';
import { ITariff, ITariffFunction } from '../../typings/management/tariff';
import Checkbox from '../ui/checkbox';
import TariffsTableRecord from './tariffTableRecord';
import Scrollbar from '../ui/scrollbar';
import { versionPositions } from '../../constants/tariffs';

const TariffsTable: FC<ITariffTable> = (props) => {
  const {
    permissions = {},
    tariffsData = [],
    dependenciesTariffs = [],
    onChange = () => {},
    allRecordsIsCheck = () => false,
    isLiteFull = false,
    additionalContent = null,
    isDisableAll = false,
  } = props;

  const onCheckDependencies = useCallback(
    (newTariffsData: ITariff[], tariffIndex: number, funcIndex: number, value: boolean) => {
      const currentTariff = newTariffsData[tariffIndex];
      const currentFunc = currentTariff.functions[funcIndex];

      if (value) {
        const dependency =
          dependenciesTariffs[funcIndex]?.name === currentFunc.function
            ? dependenciesTariffs[funcIndex]
            : dependenciesTariffs.find((item) => item.name === currentFunc.function);

        if (dependency) {
          dependency.dependencies?.forEach((name) => {
            const element = tariffsData[tariffIndex].functions?.find((item) => item.function === name);
            if (element) {
              element.enabled = true;
            }
          });
        }
      } else {
        const dependentValues: string[] = [];

        dependenciesTariffs.forEach((item) => {
          item.dependencies?.forEach((dep) => dep === currentFunc.function && dependentValues.push(item.name || ''));
        });

        dependentValues.forEach((dep) => {
          const func = currentTariff.functions.find((item) => item.function === dep);
          if (func) {
            func.enabled = false;
          }
        });
      }
    },
    [dependenciesTariffs, tariffsData]
  );

  const onCheckFunction = useCallback(
    (tariff: ITariff, index: number, value: boolean, needCheckDependency = true) => {
      const newTariffsData = [...tariffsData];
      const position = tariff.version ? versionPositions.get(tariff.version) || 0 : 0;

      if (value) {
        for (let i = position; i < tariffsData.length; i++) {
          tariffsData[i].functions[index].enabled = value;
          if (needCheckDependency) {
            onCheckDependencies(newTariffsData, i, index, value);
          }
        }
      }

      if (!value) {
        for (let i = position; i >= 0; i--) {
          tariffsData[i].functions[index].enabled = value;
          if (needCheckDependency) {
            onCheckDependencies(newTariffsData, i, index, value);
          }
        }
      }

      onChange(newTariffsData);
    },
    [onChange, onCheckDependencies, tariffsData]
  );

  const onCheck = useCallback(
    (tariff: ITariff, index: number) => (value: boolean) => onCheckFunction(tariff, index, value),
    [onCheckFunction]
  );

  const onHeaderCheck = useCallback(
    (tariff: ITariff) => (value: boolean) =>
      tariff.functions?.forEach((item, index) => onCheckFunction(tariff, index, value, false)),
    [onCheckFunction]
  );

  const needToDisableColumns = useMemo(
    () => isDisableAll || isLiteFull || !permissions.edit,
    [permissions, isDisableAll, isLiteFull]
  );

  const needToDisableFirstColumn = useMemo(() => isDisableAll || !permissions.edit, [permissions, isDisableAll]);

  const getColumns = useCallback(
    (): ColumnType<ITariffFunction>[] => [
      {
        title: <div className="tariffs-table__header">Функции</div>,
        dataIndex: 'function',
        key: 'function',
        fixed: 'left',
        width: '50%',
        render: (_, record: ITariffFunction) => (
          <TariffsTableRecord name={record.name} description={record.description} />
        ),
      },
      ...tariffsData.map((tariff, index) => {
        const isDisabled = index === 0 ? needToDisableFirstColumn : needToDisableColumns;
        return {
          title: (
            <div className="tariffs-table__header">
              <Checkbox checked={allRecordsIsCheck(tariff)} onChange={onHeaderCheck(tariff)} disabled={isDisabled} />
              <div
                className={classNames('tariffs-table__header-name', {
                  'tariffs-table__header-name_disabled': isDisabled,
                })}
              >
                {tariff.version}
              </div>
            </div>
          ),
          dataIndex: 'lite-functions',
          key: 'lite-functions',
          width: '16%',
          render: (data: ITariffFunction[], record: ITariffFunction, functionIndex: number) => (
            <div className="tariffs-table__checkbox">
              <Checkbox
                checked={tariff.functions && tariff.functions[functionIndex].enabled}
                onChange={onCheck(tariff, functionIndex)}
                disabled={isDisabled}
              />
            </div>
          ),
        };
      }),
    ],
    [allRecordsIsCheck, needToDisableColumns, needToDisableFirstColumn, onCheck, onHeaderCheck, tariffsData]
  );

  const columns = useMemo(() => getColumns(), [getColumns]);

  const dataSource = useMemo(
    () =>
      tariffsData[0]
        ? tariffsData[0].functions?.map((item, index: number) => ({
            ...item,
            key: `${item.function}-${index}`,
          }))
        : [],
    [tariffsData]
  );

  return (
    <Scrollbar>
      {additionalContent}
      <Table
        columns={columns}
        dataSource={dataSource}
        className="tariffs-table"
        showSorterTooltip={false}
        pagination={false}
      />
    </Scrollbar>
  );
};

export default TariffsTable;
