import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile, ISettings } from '../../typings/profile';

interface IProfileState {
  profile: IProfile;
  settings: ISettings;
}

const initialState: IProfileState = {
  profile: {},
  settings: {},
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<IProfile>) => {
      state.profile = action.payload;
    },
    setSettings: (state, action: PayloadAction<ISettings>) => {
      state.settings = action.payload;
    },
  },
});

export const { setProfile, setSettings } = profileSlice.actions;

export default profileSlice.reducer;
