import React, { FC, useCallback, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '../../../assets/svg/icons/arrowBack';
import CommonHead from '../../../components/commonHead';
import { SubscriberEditMode } from '../../../components/subscribersTabs/types';
import ButtonLink from '../../../components/ui/buttonLink';
import { paths } from '../../../constants/paths';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { setHeaderTitle } from '../../../store/slices/header';
import { ISubscriber } from '../../../typings/subscribers';
import { ESubscribersTabsIds } from '../types';
import { EPageQueryParams } from '../../../typings/searchParams';
import SubscribersBaseTab from '../../../components/subscribersTabs/baseTab';
import { getWasChange } from '../../../store/selectors/changes';
import { setChange } from '../../../store/slices/changes';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';

const SubscribersNew: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const wasChange = useAppSelector(getWasChange);

  const setWasChange = useCallback(
    (value: boolean) => {
      dispatch(setChange(value));
    },
    [dispatch]
  );

  const goBack = useCallback(() => {
    if (wasChange) {
      dispatch(setClickedSidebarTab(paths.subscribers));
    } else {
      navigate(paths.subscribers);
    }
  }, [dispatch, navigate, wasChange]);

  useEffect(() => {
    dispatch(setHeaderTitle('Создание абонента'));
  }, [dispatch]);

  const handleOnSave = useCallback(
    async (newSubscriber: ISubscriber) => {
      if (newSubscriber.id) {
        navigate({
          pathname: `${paths.subscribers}/${newSubscriber.id}`,
          search: `?${createSearchParams({ [`${EPageQueryParams.tabId}`]: ESubscribersTabsIds.basic })}`,
        });
      }
    },
    [navigate]
  );

  return (
    <>
      <CommonHead seo={{ title: 'Создание абонента' }} />
      <div className="subscribers-new">
        <div className="subscribers-new__button-wrapper">
          <ButtonLink withCallback onClick={goBack} leftIcon={<ArrowBackIcon />} content="Все Абоненты" />
        </div>
        <div className="subscribers-new__content">
          <SubscribersBaseTab
            editMode={SubscriberEditMode.create}
            nextButtonText="Создать"
            wasChange={wasChange}
            setWasChange={setWasChange}
            onSave={handleOnSave}
            isActiveTab
          />
        </div>
      </div>
    </>
  );
};

export default SubscribersNew;
