import { IDispatcherPanelJournal } from '../../typings/dispatcherPanel';
import { IDeviceJournalApiSettings } from './types';

export const DEVICE_JOURNAL_TABLE_COUNT = 10;

export const getDevicePageApiSettings = (): IDeviceJournalApiSettings<IDispatcherPanelJournal> => ({
  sortFields: [],
  page: 0,
  pageSize: DEVICE_JOURNAL_TABLE_COUNT,
  search: '',
});
