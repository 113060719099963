import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import CircleIcon from '../../assets/svg/icons/circle';
import { Status } from '../../typings/status';
import Select from '../ui/select';
import { defaultSystemStatuses } from './config';
import { IStatusSelect, StatusIconColor } from './types';

const allOption: any = {
  value: '',
  title: 'Все',
};

const StatusSelect: FC<IStatusSelect> = (props) => {
  const {
    title = 'Статус',
    value = Status.active,
    onChange = () => {},
    options = defaultSystemStatuses,
    containerClassName = '',
    contentClassName = '',
    iconClassName = '',
    textClassName = '',
    disabled = false,
    isDisabledStyle = false,
    isAllOption = false,
  } = props;

  const optionsData = useMemo(() => (isAllOption ? [...options, allOption] : options), [isAllOption, options]);

  return (
    <Select
      containerClassName={classNames('status-select__select-container', containerClassName)}
      selectClassName="status-select__select"
      title={title}
      value={value}
      labelInValue
      disabled={disabled}
      isDisabledStyle={isDisabledStyle}
      onChange={(selectedValue) => onChange(selectedValue.toString() as Status)}
      options={optionsData.map((option) => ({
        value: option.value,
        title: option.title,
        data: (
          <div className={classNames('status-select__content', contentClassName)}>
            <div
              className={classNames('status-select__icon', iconClassName, {
                'status-select__icon_green': option.color === StatusIconColor.green,
                'status-select__icon_red': option.color === StatusIconColor.red,
                'status-select__icon_yellow': option.color === StatusIconColor.yellow,
                'status-select__icon_gray': option.color === StatusIconColor.gray,
              })}
            >
              <CircleIcon />
            </div>

            <span className={classNames(textClassName)}>{option.title}</span>
          </div>
        ),
      }))}
    />
  );
};

export default StatusSelect;
