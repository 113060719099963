import Paper from 'paper';
// eslint-disable-next-line import/no-extraneous-dependencies
import generatePDF, { Resolution, Margin, Options } from 'react-to-pdf';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../ui/button';
import Modal from '../../../ui/modal';
import { ButtonType } from '../../../ui/button/types';
import { IBackgroundModal } from './types';
import Loader from '../../../ui/loader';
import { ELoaderColor } from '../../../ui/loader/types';
import SchemaTree from '../schemaTree';

const SchemaPDFModal: FC<IBackgroundModal> = (props) => {
  const { isOpen = false, localSchema = [], onCancel = () => {}, system, settings } = props;

  const [img, setImg] = useState<any>();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const options: Options = useMemo(
    () => ({
      filename: `${system?.objectName}.pdf`,
      method: 'save',
      resolution: Resolution.HIGH,
      page: {
        margin: Margin.SMALL,
      },
    }),
    [system?.objectName]
  );

  useEffect(() => {
    if (isOpen) {
      setTimeout(
        () => Paper.view.element.toBlob((blob: Blob | null) => blob && setImg(URL.createObjectURL(blob))),
        500
      );
    } else {
      setImg(null);
    }
  }, [isOpen]);

  const calculateMargins = useCallback((list: NodeListOf<any>) => {
    const height = 1100;
    let currHeight = height;
    list.forEach((item, index) => {
      currHeight -= item.offsetHeight;
      if (currHeight <= 0) {
        currHeight = height;
        if (index !== list.length - 1) item.style.marginBottom = '20px';
      }
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (img && containerRef.current) {
        const list = containerRef.current.getElementsByClassName('ant-tree-list-holder-inner')[0];
        if (list.childNodes) calculateMargins(list.childNodes);
      }
    }, 500);
  }, [img]);

  const downloadPdf = useCallback(() => {
    generatePDF(() => containerRef.current, options);
    onCancel();
  }, [onCancel, options]);

  return (
    <Modal
      isOpen={isOpen}
      title="Предварительный просмотр"
      onCancel={onCancel}
      width={900}
      centered
      wrapClassName="schema-modal__wrapper schema-pdf-modal__wrapper"
      footer={
        <div className="ant-modal-footer__buttons">
          <Button onClick={downloadPdf}>Скачать</Button>
          <Button type={ButtonType.secondary} onClick={onCancel}>
            Отмена
          </Button>
        </div>
      }
    >
      <div className="schema-modal schema-pdf-modal default-scrollbar-override">
        {img ? (
          <div className="schema-pdf-modal__content" ref={containerRef}>
            <div className="schema-pdf-modal__title">План объекта {system?.objectName}</div>
            <div className="schema-pdf-modal__img-block">
              <img className="schema-pdf-modal__img" src={img} alt="fdsf" />
            </div>
            <SchemaTree
              settings={settings}
              titleName="Дерево объектов"
              showBackground={false}
              defaultExpandAll
              schema={localSchema}
            />
          </div>
        ) : (
          <div className="schema-pdf-modal__loader">
            <Loader color={ELoaderColor.blue} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SchemaPDFModal;
