import classNames from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import FaqPlusIcon from '../../../assets/svg/icons/faqPlus';
import { paths } from '../../../constants/paths';
import { ellipsisStringByLimit } from '../../../utils/common';
import { IFaqQuestionComponent } from './types';
import CloseBigIcon from '../../../assets/svg/icons/closeBig';

const FaqQuestion: FC<IFaqQuestionComponent> = (props) => {
  const { question, answerLength = 500, defaultOpen = false, onChangeOpen = () => {} } = props;

  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const handleOnChangeOpen = useCallback(
    (newOpen: boolean) => {
      setIsOpen(newOpen);
      if (question?.id) {
        onChangeOpen(question.id, newOpen);
      }
    },
    [onChangeOpen, question]
  );

  if (!question || !question.question || !question.answer) {
    return null;
  }

  return (
    <div className="faq-question">
      <div
        role="presentation"
        className={classNames('faq-question__header', {
          'faq-question__header_expanded': isOpen,
        })}
        onClick={() => handleOnChangeOpen(!isOpen)}
      >
        <span className="faq-question__title">{question.question}</span>
        <div className="faq-question__icon">{isOpen ? <CloseBigIcon /> : <FaqPlusIcon />}</div>
      </div>
      {isOpen && (
        <div className="faq-question__body">
          <Link className="faq-question__text" to={`${paths.helpFaq}/${question.id}`}>
            <div dangerouslySetInnerHTML={{ __html: ellipsisStringByLimit(question.answer, answerLength) }} />
          </Link>
          <Link className="faq-question__link" to={`${paths.helpFaq}/${question.id}`}>
            Подробнее
          </Link>
        </div>
      )}
    </div>
  );
};

export default FaqQuestion;
